import { companySettingsSSOByNamespaceFetchRoutine } from 'actions/routines/sso';

import { gatherReducerCasesFromRoutines } from 'helpers/routine';

import { createIsFetchingReducer } from 'store/redux';

const { failureCases, successCases } = gatherReducerCasesFromRoutines([companySettingsSSOByNamespaceFetchRoutine]);

export const finishCases = [...failureCases, ...successCases];

export const requestCases = [companySettingsSSOByNamespaceFetchRoutine.TRIGGER];

export default createIsFetchingReducer(finishCases, requestCases);
