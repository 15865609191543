import React from 'react';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

const SamePartnerNameWarningHint: React.VFC<React.HTMLAttributes<HTMLElement>> = ({ className }) => (
  <BaseHint
    className={className}
    intent={Intent.WARNING}
    text={`A company with that display name already exists.
      You can use the same display name again, but we recommend using a different one.`}
    title="Heads up!"
  />
);

export default SamePartnerNameWarningHint;
