import classNames from 'classnames';
import React from 'react';

import { Text } from 'components';

import type { TooltipEntryProps } from './TooltipEntry.types';

import './TooltipEntry.scss';

/**
 * Component rendering a single entry of label and value
 */
const TooltipEntry: React.FC<TooltipEntryProps> = ({ isRow, label, value }) => (
  <div
    className={classNames('tooltip-entry', {
      'is-row': !!isRow,
    })}
  >
    <Text.Regular>{label}</Text.Regular>
    <Text.Bold>{value}</Text.Bold>
  </div>
);

export default TooltipEntry;
