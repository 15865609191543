import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { redirectToTeamMembersPage } from 'helpers/navigation';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import RestrictedPermissionTooltip from './RestrictedPermissionTooltip';

/**
 * Returns the RestrictedPermissionTooltip with the onNavigate prop
 * @param {ComponentProps} props
 * @param {object} props.history
 * @return {StatelessComponent}
 */
const RestrictedPermissionTooltipContainer = (props) => {
  const { history } = props;

  const onNavigate = React.useCallback(() => {
    redirectToTeamMembersPage(history);
  }, [history]);

  return <RestrictedPermissionTooltip onNavigate={onNavigate} {...props} />;
};

RestrictedPermissionTooltipContainer.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default withRouter(RestrictedPermissionTooltipContainer);
