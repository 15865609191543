import { History } from 'history';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { startIsSubmitting, endIsSubmitting, splitBillIsSubmittingSelector } from 'ducks/itemSplitBill';

import { PAYMENTS_LIST_FILTERS, PAYMENTS_LIST_TABS, PAYMENTS_ROUTE } from 'constants/routes';

import { confirmAlert } from 'helpers/confirmAlert';
import errorAlert from 'helpers/errorAlert';
import { safeNavigate } from 'helpers/navigation';
import { getJoinedPath } from 'helpers/routeHelpers';

import { ReduxState } from 'interfaces/redux';

import { billAttachmentsSelector, selectedBillAttachmentSelector } from 'queries/billAttachmentsSelectors';

import { itemIdSelector } from 'selectors/itemsSelectors';

import { postItemSplitBill } from './requests';

interface UseSplitBillReturn {
  onSplitBill(): void;
  isSubmitting: boolean;
  splitBillDisabled: boolean;
}

export function getSplitBillFunction(dispatch: Dispatch<any>, history: History) {
  return async (itemId: string, attachmentId: string): Promise<any> => {
    const confirmSubmit = await confirmAlert(
      `Creating a new bill from the scanned attachment will remove this attachment from the original bill. Additionally, you may also keep any supporting attachments with the newly created bill. This includes the original email and any non-bill attachments.`,
      `Create new bill from attachment`,
      {
        buttonsConfig: {
          confirmButtonText: 'Confirm',
        },
      },
    );

    if (!confirmSubmit) {
      return;
    }

    dispatch(startIsSubmitting());

    const response = await postItemSplitBill(itemId, attachmentId);

    if (!response.ok) {
      await errorAlert(`Something went wrong trying to split the bill.`, response.headers['request-id'], undefined, {
        buttonsConfig: {
          confirmButtonText: 'Confirm',
          showCancel: false,
        },
      });
    } else if (response.status === 201) {
      const newItemId = Object.keys(response.data.item)[0];
      const queryParams = new URLSearchParams(history.location.search.replace('?', ''));
      queryParams.set('id', newItemId);
      // @ts-ignore
      safeNavigate(history, `${history.location.pathname}?${queryParams.toString()}`);
    } else if (response.status === 202) {
      await confirmAlert(
        `Splitting the bill is taking longer than usual, you are going to be redirected to the inbox list.`,
        `Create new bill from attachment`,
        {
          buttonsConfig: {
            confirmButtonText: 'Confirm',
            showCancel: false,
          },
        },
      );
      // @ts-ignore
      safeNavigate(history, getJoinedPath(PAYMENTS_ROUTE, PAYMENTS_LIST_TABS.INBOX, PAYMENTS_LIST_FILTERS.ALL));
    }

    dispatch(endIsSubmitting());
  };
}

export function useSplitBill(): UseSplitBillReturn {
  const dispatch = useDispatch();
  const history = useHistory();
  const splitBill = useCallback(getSplitBillFunction(dispatch, history), [dispatch, history]);
  const itemId = useSelector<ReduxState, string>((state) => itemIdSelector(state, undefined));
  const attachments = useSelector(billAttachmentsSelector);
  const selectedAttachment = useSelector(selectedBillAttachmentSelector);
  const selectedAttachmentId = selectedAttachment?.id;
  const splitBillDisabled = selectedAttachment?.primary || attachments.length <= 1;

  const onSplitBill = useCallback(() => {
    splitBill(itemId, selectedAttachmentId);
  }, [itemId, selectedAttachmentId, splitBill]);

  return {
    onSplitBill,
    splitBillDisabled,
    isSubmitting: useSelector(splitBillIsSubmittingSelector),
  };
}
