import { connect } from 'react-redux';
import {
  formValueSelector,
  getFormValues,
  getFormInitialValues,
  getFormSubmitErrors,
  getFormError,
  isDirty,
  isPristine,
  isValid,
  isInvalid,
  isSubmitting,
  getFormNames,
} from 'redux-form';

import { createItemFormFields } from 'constants/formFields';

import { createItemFormSelector } from 'selectors/forms';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';

import Provenance from './Provenance';

const mapStateToProps = (state, ownProps) => {
  let { form } = ownProps;
  if (!form || form?.toString().trim() === '_empty') {
    form = getFormNames()(state)?.[0];
  }

  // This is for the item details' page where we are not connected to a form
  if (!form) {
    return {};
  }

  const formSelector = formValueSelector(form);

  return {
    item: getFormValues(form)(state),
    initialItem: getFormInitialValues(form)(state),
    submitErrors: getFormSubmitErrors(form)(state),
    formError: getFormError(form)(state),
    formUI: formSelector(state, 'ui'),
    dirty: isDirty(form)(state),
    pristine: isPristine(form)(state),
    valid: isValid(form)(state),
    invalid: isInvalid(form)(state),
    submitting: isSubmitting(form)(state),
    ledger: ledgerIntegrationSelector(state),
    formNullName: getFormNames()(state)?.[0],
    isBillView: createItemFormSelector(state, createItemFormFields.UI_IS_BILL_VIEW),
  };
};

export default connect(mapStateToProps)(Provenance);
