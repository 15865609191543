import _map from 'lodash/map';
import { useSelector } from 'react-redux';

import { uniqueArray } from 'helpers/utility';

import { usePartnershipPaymentCurrencies } from 'hooks';

import type { CurrencyCode } from 'interfaces/currency';
import type { Partnership } from 'interfaces/partnerships';

import { useVendorReceivingCurrency } from 'modules/dashboard/vendorReceivingCurrency/hooks';

import { createItemBillCurrencySelector } from 'queries/createItemFormSelectors';

/**
 * Given a partnership, returns an unique list of every possible currency code receiver based on the
 * partnership payment possibilities
 */
export const useGetAllAvailableCurrenciesForFundingAccounts = (partnershipId: string): CurrencyCode[] => {
  const { data } = usePartnershipPaymentCurrencies(partnershipId);

  const { currencyCodesBillSelectable, routesPayable } = data || {};

  const options =
    currencyCodesBillSelectable?.reduce(
      (currencyCodes, billCurrency) => [
        ...currencyCodes,
        ..._map(routesPayable[billCurrency.toLowerCase()], 'currencyCodeReceiver'),
      ],
      [],
    ) || [];

  return uniqueArray(options);
};

/**
 * Given a partnership, returns a list of available currencies to be used on adding funding account form
 * The list is filtered by the selected currency code if there's on selected on the create item form
 * Otherwise it returns every possible option independently of the bill currency
 */
export const useAvailableCurrenciesForFundingAccounts = (partnership: Partnership): CurrencyCode[] => {
  // All options - used when RCTM is adding a funding account via vendor's profile
  const allAvailableCurrenciesForFundingAccounts = useGetAllAvailableCurrenciesForFundingAccounts(partnership.id);

  // options filtered by bill currency - used when RCTM is adding a funding account via create item page
  const itemBillCurrency = useSelector(createItemBillCurrencySelector);
  const vendorReceivingData = useVendorReceivingCurrency(
    () => partnership,
    () => itemBillCurrency,
  );

  // user is in create item page, hence return the filtered options
  if (itemBillCurrency) {
    return vendorReceivingData.options.map(({ id }) => id);
  }

  return allAvailableCurrenciesForFundingAccounts;
};
