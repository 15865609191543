import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { logoutRequest } from 'actions/auth';

import Logout from './Logout';

export const mapDispatchToProps = {
  onLogoutUser: logoutRequest,
};

const enhance = compose(withRouter, connect(null, mapDispatchToProps));

export default enhance(Logout);
