import React from 'react';

import { IconNames } from 'components/icon';

import { getDisplayName } from 'helpers/hoc';
import { ternary } from 'helpers/utility';

import { useToggle } from 'hooks';

import PaymentMethodDetailItemAction from '../../PaymentMethodDetailItemAction';
import { PaymentMethodDetailAction } from '../enums';
import type { PaymentMethodDetailItemProps } from '../PaymentMethodDetailItem.types';

/**
 * HOC that is meant to add reveal functionality and icon to PaymentMethodDetailItem
 */
const withRevealAction = (
  PaymentMethodDetailItem: React.FC<PaymentMethodDetailItemProps>,
): React.FC<PaymentMethodDetailItemProps> => {
  const Component = (props: PaymentMethodDetailItemProps) => {
    const newProps = { ...props };
    const { actions, actionsElements = [], revealedValue, value } = props;
    const [revealed, toggleRevealed] = useToggle(false);
    const isRevealable = actions.includes(PaymentMethodDetailAction.REVEAL);

    if (isRevealable) {
      newProps.value = ternary(revealed, revealedValue, value);
      const icon = ternary(revealed, IconNames.EYE_OFF, IconNames.EYE_OPEN);
      const tooltipTitle = ternary(revealed, 'Hide', 'Show');

      newProps.actionsElements = [
        ...actionsElements,
        <PaymentMethodDetailItemAction
          icon={icon}
          key="reveal-action"
          onClick={toggleRevealed}
          tooltipTitle={tooltipTitle}
        />,
      ];
    }

    return <PaymentMethodDetailItem {...newProps} />;
  };

  Component.displayName = `${getDisplayName(PaymentMethodDetailItem)}WithRevealAction`;
  return Component;
};

export default withRevealAction;
