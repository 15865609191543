import { ManualApproverPlacement } from 'interfaces/manualApprover';

export const APPROVAL_LEVEL_ERROR_TITLE = 'Please review your approval selection';

export const APPROVAL_LEVEL_HINT_TEXT =
  'Approval rules for this item may have changed. Please review your approval selection.';

export const APPROVER_FIELD_PREFIX = 'approvers-';

export const SECTION_PATHS = {
  ITEM_LINE_ITEMS: 'item.lineItems',
};

export const ManualApproverPlacementMap: Record<'FIRST' | 'LAST', ManualApproverPlacement> = {
  FIRST: 'first',
  LAST: 'last',
};
