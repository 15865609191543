import React from 'react';
import { Control, useController } from 'react-hook-form';

import confirmAlert from 'helpers/confirmAlert';

import ToggleSwitch from './ToggleSwitch';

type ToggleSwitchHookFormProps = {
  children?: React.ReactNode;
  confirmationConfig?: {
    text: string;
    title: string;
    shouldPrompt: (currentValue: boolean) => boolean;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  id?: string;
  isDisabled?: boolean;
  name: string;
  testid?: string;
};

/**
 * Toggle switch form input component compatible with react-hook-form APIs
 * TODO: Make this more generic / not react-hook-form agnostic
 * @param props - Component props
 */
const ToggleSwitchHookForm = ({
  children,
  confirmationConfig,
  id,
  isDisabled,
  testid,
  ...controlProps
}: ToggleSwitchHookFormProps): JSX.Element => {
  const { field } = useController(controlProps);
  const { value, ...fieldProps } = field;

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    if (confirmationConfig && confirmationConfig.shouldPrompt(value)) {
      const hasConfirmation = await confirmAlert(confirmationConfig.text, confirmationConfig.title);

      if (!hasConfirmation) {
        return;
      }
    }

    fieldProps.onChange({ ...e, target: { ...e.target, value: !value } });
  };

  return (
    <ToggleSwitch
      data-testid={testid}
      id={id || controlProps.name}
      isChecked={!!value}
      isDisabled={isDisabled}
      name={fieldProps.name}
      onBlur={fieldProps.onBlur}
      onChange={handleChange}
    >
      {children}
    </ToggleSwitch>
  );
};

export default ToggleSwitchHookForm;
