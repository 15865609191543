import React from 'react';

import { useSubmitTransactionPaymentConfirmationMutation } from 'ducks/routableApi/transactionEndpoints';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { ButtonV2 } from 'components';

import { ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';

import { Transaction } from 'interfaces/transactions';

interface GeneratePaymentConfirmationProps {
  transaction: Transaction;
}

const GeneratePaymentConfirmation: React.FC<GeneratePaymentConfirmationProps> = ({ transaction }) => {
  const [onSubmitTransactionPaymentConfirmation, apiCall] = useSubmitTransactionPaymentConfirmationMutation();

  return (
    <ButtonV2
      intent={Intent.NEUTRAL}
      isLoading={apiCall.isLoading}
      onClick={() => onSubmitTransactionPaymentConfirmation(transaction)}
      size={ButtonSize.SMALL}
      type="button"
    >
      Generate PDF
    </ButtonV2>
  );
};

export default GeneratePaymentConfirmation;
