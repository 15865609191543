import React from 'react';

import { Text } from 'components/text';

import { typography } from 'constants/styles';

import type { AddPaymentMethodDropdownOptionProps } from './AddPaymentMethodDropdownOption.types';

import './AddPaymentMethodDropdownOption.scss';

/**
 * Component rendering single AddPaymentMethodDropdown option
 * @see {AddPaymentMethodDropdownButton}
 * @param {IDropdownMenuOptionProps} props
 * @return {StatelessComponent}
 */
const AddPaymentMethodDropdownOption: React.FC<AddPaymentMethodDropdownOptionProps> = ({
  description,
  onClick,
  onCloseMenu,
  title,
}) => {
  const handleOnClick = React.useCallback(
    (evt) => {
      onClick();
      onCloseMenu(evt);
    },
    [onClick, onCloseMenu],
  );

  return (
    <li className="add-payment-method-dropdown-option">
      <a
        className="remove-hover-underline"
        onClick={handleOnClick}
        role="presentation"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        type="button"
      >
        <Text.Semibold color={typography.TextColor.BLUE_DARK}>{title}</Text.Semibold>
        <Text.Regular>{description}</Text.Regular>
      </a>
    </li>
  );
};

export default AddPaymentMethodDropdownOption;
