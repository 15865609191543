/* eslint-disable camelcase */

import { useRoutableParams } from 'hooks/useRoutableParams';

import { type IdDParams } from './useLoginIdP.types';

/**
 * @description these are the values we return from a IdV login via Auth0
 */

export const useLoginIdp = (): IdDParams => {
  const { code, connection, company_id } = useRoutableParams();
  if (connection !== undefined) {
    return {
      idp: true,
      code,
      connection,
      companyId: company_id,
    };
  }

  return {
    idp: false,
    code: undefined,
    connection: undefined,
    companyId: undefined,
  };
};
