import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { partnershipFromCreateItemFormSelector } from 'queries/partnershipCompoundSelectors';

import { currentCompanySettingsIntegrationSelector } from 'selectors/currentCompanySelectors';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';

import CurrencyCodeBlockField from './CurrencyCodeBlockField';
import { StateProps } from './CurrencyCodeBlockField.types';

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  companyIntegrationSettings: currentCompanySettingsIntegrationSelector,
  ledger: ledgerIntegrationSelector,
  partnership: partnershipFromCreateItemFormSelector,
});

export default connect(mapStateToProps)(CurrencyCodeBlockField);
