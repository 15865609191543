import React from 'react';
import { Link } from 'react-router-dom';

import { Text, WhiteSpace } from 'components/text';

import { getCompanyRouteByPartnershipId } from 'helpers/routeHelpers';

type Props = {
  partnershipId: string;
};

const CantRemoveVendorIndividualTooltipContent = ({ partnershipId }: Props): JSX.Element => (
  <Text.Regular>
    {`This contact can't be removed since the vendor is an individual. Instead you can`}
    <WhiteSpace />
    <Link className="primary" to={{ pathname: getCompanyRouteByPartnershipId(partnershipId) }}>
      edit the contact
    </Link>
    .
  </Text.Regular>
);

export default CantRemoveVendorIndividualTooltipContent;
