import { ssoDisableRoutine } from 'actions/routines/sso';

import { SSOActionType } from 'enums/sso';

import type { Action } from 'interfaces/actions';

import { getModalReducerOpenAction } from 'store/redux';

import type { ModalsReducerProps } from './modalsReducer.types';

export const initialState: ModalsReducerProps = {
  open: false,
};

/**
 * Modal reducer for SSO Setup Form
 */
const modalSSODisableReducer = (state = initialState, action: Action): ModalsReducerProps => {
  switch (action.type) {
    case SSOActionType.CLOSE_DISABLE_MODAL:
    case ssoDisableRoutine.SUCCESS:
    case ssoDisableRoutine.FAILURE:
      return initialState;

    case SSOActionType.OPEN_DISABLE_MODAL:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default modalSSODisableReducer;
