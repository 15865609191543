import { IconNames } from 'components';

import {
  getFormattedDetailsOfFundingInfoAddressWithAddress,
  getFundingAccountMask,
  getFundingInfoAddressDisplayName,
  isFundingAccountBalance,
  isFundingAccountBankAccount,
  isFundingAccountDeleted,
  isFundingAccountDisabled,
  isFundingAccountValid,
  isFundingSourceInvalid,
  isFundingSourceInvalidMicroDepositsFailed,
  isFundingSourceInvalidMicroDepositsFailedVerification,
  isFundingSourceInvalidMicroDepositsPending,
  isFundingSourceInvalidMicroDepositsProcessed,
  isFundingSourceValid,
} from 'helpers/funding';

import type { FundingAccount } from 'interfaces/fundingAccount';

import { PaymentMethodStatusType } from './enums';
import type { PaymentMethodHeading, PaymentMethodHeadingIconProperties } from './PaymentMethod.types';

/**
 * Derives payment method heading from given fundingInfoAddress
 */
export const getPaymentMethodHeaderCheck = (fundingInfoAddress = {} as FundingInfoAddress): PaymentMethodHeading => {
  const title = getFundingInfoAddressDisplayName(fundingInfoAddress);
  const subtitle = getFormattedDetailsOfFundingInfoAddressWithAddress(fundingInfoAddress);

  return { title, subtitle };
};

/**
 * Derives payment method heading from given bank fundingAccount
 */
export const getPaymentMethodHeaderBank = (
  fundingAccount: FundingAccount,
  fundingInfoBankAccount: FundingInfoBankAccount,
): PaymentMethodHeading => {
  const { accountNumber = '' } = Object(fundingInfoBankAccount);
  const subtitle = getFundingAccountMask(accountNumber);
  const { name: title = '' } = Object(fundingAccount);

  return { title, subtitle };
};

/**
 * Derives payment method heading from given balance fundingAccount
 * @param fundingAccount
 */
export const getPaymentMethodHeaderBalance = (fundingAccount: FundingAccount): PaymentMethodHeading => {
  const { name: title = '' } = Object(fundingAccount);

  return { title };
};

/**
 * Derives payment method heading from given fundingAccount and fundingInfoAddress
 */
export const getPaymentMethodHeader = ({
  fundingAccount,
  fundingInfoAddress,
  fundingInfoBankAccount,
}: {
  fundingAccount: FundingAccount;
  fundingInfoAddress: FundingInfoAddress;
  fundingInfoBankAccount: FundingInfoBankAccount;
}): PaymentMethodHeading => {
  if (isFundingAccountBalance(fundingAccount)) {
    return getPaymentMethodHeaderBalance(fundingAccount);
  }

  if (isFundingAccountBankAccount(fundingAccount)) {
    return getPaymentMethodHeaderBank(fundingAccount, fundingInfoBankAccount);
  }

  return getPaymentMethodHeaderCheck(fundingInfoAddress);
};

/**
 * Derives payment method status from given funding account and funding source
 */
export const getPaymentMethodStatus = ({
  fundingAccount,
  fundingSources = [],
  isPartnerAccountInDashboard,
  partnershipFundingAccount,
}: {
  fundingAccount: FundingAccount;
  fundingSources: FundingSource[];
  isPartnerAccountInDashboard?: boolean;
  partnershipFundingAccount: PartnershipFundingAccount;
}): PaymentMethodStatusType | undefined => {
  if (partnershipFundingAccount?.isPrimary) {
    return PaymentMethodStatusType.PRIMARY;
  }

  if (fundingSources.some(isFundingSourceInvalidMicroDepositsPending)) {
    return PaymentMethodStatusType.PENDING_MICRO_DEPOSITS;
  }

  if (fundingSources.some(isFundingSourceInvalidMicroDepositsProcessed)) {
    return PaymentMethodStatusType.PENDING_VERIFICATION;
  }

  if (
    fundingSources.some(
      (source) =>
        isFundingSourceInvalid(source) ||
        isFundingSourceInvalidMicroDepositsFailed(source) ||
        isFundingSourceInvalidMicroDepositsFailedVerification(source),
    )
  ) {
    return PaymentMethodStatusType.ERROR;
  }

  if (isFundingAccountDisabled(fundingAccount) || isFundingAccountDeleted(fundingAccount)) {
    return PaymentMethodStatusType.DISABLED;
  }

  if (
    // Only valid partner FundingAccounts will appear in the Thread.
    isPartnerAccountInDashboard ||
    isFundingAccountValid(fundingAccount) ||
    fundingSources.some(isFundingSourceValid)
  ) {
    return PaymentMethodStatusType.ACTIVE;
  }

  return undefined;
};

/**
 * Derives bank or mail icon from fundingAccount
 */
export const getPaymentMethodIcon = (fundingAccount: FundingAccount): PaymentMethodHeadingIconProperties => {
  if (isFundingAccountBalance(fundingAccount)) {
    return { icon: IconNames.BANK_ACCOUNT };
  }

  // @ts-expect-error Using super legacy icons here, types don't match
  return isFundingAccountBankAccount(fundingAccount)
    ? { isCustomIcon: true, icon: 'icon-ic-bank' }
    : { isCustomIcon: true, icon: 'icon-ic-mail' };
};
