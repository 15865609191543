import { createSelector } from 'reselect';

import { allValues } from 'helpers/utility';

import { ReduxState } from 'interfaces/redux';
import { SSOSettingsState } from 'interfaces/sso';

const getState = (state: ReduxState) => state.sso;

/// SSO Setup Selectors
export const ssoSetupFormSelector = createSelector([getState], (sso) => sso.setupForm);

export const ssoSetupFormProviderSelector = createSelector(
  [ssoSetupFormSelector],
  (state: SSOSettingsState) => state.ssoProvider,
);

/// Current company SSO Settings Selectors
export const ssoSettingsSelector = createSelector([getState], (sso) => sso.currentCompanySettings);

export const ssoSettingsByIdSelector = createSelector([ssoSettingsSelector], (ssoSettings) => ssoSettings.byId);

export const allSSOSettingsSelector = createSelector([ssoSettingsByIdSelector], (ssoSettingsById) =>
  allValues(ssoSettingsById),
);

// returns the first element of the company settings, assuming only one sso settings per company
export const currentSSOSettingsSelector = createSelector([allSSOSettingsSelector], (allSSOSettings) =>
  allSSOSettings?.shift?.(),
);

// returns 1st ssoSettings with sso enabled, currently assuming only one provider can be enabled at one time
export const enabledSSOSettingsSelector = createSelector([allSSOSettingsSelector], (allSSOSettings) =>
  allSSOSettings.find((ssoSettings) => ssoSettings.enableSso === true),
);

export const ssoSettingsIsDisablingSelector = createSelector(
  [ssoSettingsSelector],
  (ssoSettings) => ssoSettings.isDisabling,
);

export const ssoSettingsIsFetchingSelector = createSelector(
  [ssoSettingsSelector],
  (ssoSettings) => ssoSettings.isFetching,
);
