import { Validator } from 'redux-form';

import { hintErrors } from 'components/passwordInput/components/PasswordHints/constants/hintErrors';
import { availableRequirementValidators } from 'components/passwordInput/constants/availableRequirementValidators';
import { MINIMUM_LENGTH } from 'components/passwordInput/constants/passwordRequirementParameters';

export const hasMinimumLengthValidator: Validator = (value) => {
  if (value && value.length >= MINIMUM_LENGTH) {
    return undefined;
  }

  return [hintErrors[availableRequirementValidators.HAS_MINIMUM_LENGTH]];
};
