import { webhooksFetchRoutine } from 'actions/routines/webhooks';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [webhooksFetchRoutine.FAILURE, webhooksFetchRoutine.SUCCESS];
export const requestCases = [webhooksFetchRoutine.REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;
