import { IconNames } from 'components/icon';

import { PaymentMethodStatusType } from 'modules/fundingAccount/PaymentMethod/enums';
import type { PaymentMethodStatusMapEntry } from 'modules/fundingAccount/PaymentMethod/PaymentMethod.types';

/**
 * Mapping of status text, theme, and icon info for PaymentMethodStatusType component
 */
export const PaymentMethodStatusMap: Record<PaymentMethodStatusType, PaymentMethodStatusMapEntry> = {
  [PaymentMethodStatusType.PRIMARY]: {
    icon: IconNames.STAR,
    text: 'Primary',
    theme: 'primary',
  },
  [PaymentMethodStatusType.ACTIVE]: {
    icon: IconNames.TICK_CIRCLE,
    text: 'Active',
    theme: 'active',
  },
  [PaymentMethodStatusType.PENDING_VERIFICATION]: {
    icon: IconNames.BADGE,
    text: 'Pending verification',
    theme: 'warning',
  },
  [PaymentMethodStatusType.PENDING_MICRO_DEPOSITS]: {
    icon: IconNames.BADGE,
    text: 'Verify this account',
    theme: 'warning',
  },
  [PaymentMethodStatusType.ERROR]: {
    icon: IconNames.ERROR,
    text: 'Failed',
    theme: 'error',
  },
  [PaymentMethodStatusType.DISABLED]: {
    icon: IconNames.DISABLE,
    text: 'Disabled',
    theme: 'disabled',
  },
};
