import { AxiosRequestHeaders } from 'axios';

import { getPaymentsEndpoint } from 'services/api/paymentsEndpoint';

import { FailureType, PaymentFailure, PaymentSuccess, PaymentTrigger, SuccessType, TriggerType } from './payment.types';

const TRIGGER: TriggerType = `PAYMENT/TRIGGER`;
const SUCCESS: SuccessType = `PAYMENT/SUCCESS`;
const FAILURE: FailureType = `PAYMENT/FAILURE`;

const success: PaymentSuccess = (data) => ({ type: SUCCESS, payload: data });

const failure: PaymentFailure = (data) => ({ type: FAILURE, error: data });

const trigger: PaymentTrigger = ({ id, partnershipId }) => ({
  type: TRIGGER,
  payload: {
    id,
    endpoint: getPaymentsEndpoint({ partnershipId, paymentId: id }),
    method: 'GET',
    requireAuth: true,
    onSuccess: success,
    onFailure: failure,
    headers: {} as AxiosRequestHeaders,
  },
});

export const paymentRoutine = {
  TRIGGER,
  trigger,
  SUCCESS,
  success,
  FAILURE,
  failure,
};
