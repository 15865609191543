import React from 'react';

import { ConditionalWrapperProps } from './ConditionalWrapper.types';

/**
 * Conditionally wrap some JSX without duplicating code in a ternary or similar
 */
const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export default ConditionalWrapper;
