/**
 * @fileOverview Defines helpers related to user authentication.
 * @module helpers/auth
 */
import { AuthTokenComponent } from 'constants/auth';

import { identifyUserByMembershipId } from 'helpers/eventTracking';

import { reportError } from 'sagas/errors/api';

import { Auth0Client } from 'services/auth0';
import FetchService from 'services/fetch';

import { AuthMetaResponse } from './auth.types';
import * as lsHelpers from './localStorage';
import { getQueryParamValueFromUrl } from './queryParams';

/**
 * Get the token from auth0 if is authenticated and we have the current membership id
 * in local storage. If the token is expired it will renew and return a valid token
 */
export const getAuth0Token = async (): Promise<null | ReturnType<typeof Auth0Client.getAccessTokenSilently>> => {
  if (Auth0Client.isAuthenticated && lsHelpers.getCurrentMembershipId()) {
    try {
      return await Auth0Client.getAccessTokenSilently();
    } catch (e) {
      reportError(e, {
        context: 'src/helpers/auth.ts - getAuth0Token',
        device: { browser: navigator.userAgent },
        user: { membershipId: lsHelpers.getCurrentMembershipId() },
        severity: 'warning',
      });
      return null;
    }
  }
  return null;
};

/**
 * Get the auth token from the current membership data in localstorage if available
 */
export const getMembershipAuthTokenFromLocalStorage = (): string | null => {
  const membershipData = lsHelpers.getCurrentMembershipData();
  return membershipData.authToken;
};

/**
 * Get the auth token from the url query params if available
 */
export const getURLToken = (): string | undefined => getQueryParamValueFromUrl('token');

/**
 * Gets the auth token stored in local storage, if one exists.
 */
export const getAuthToken = async (): Promise<string | null> => {
  const localStorageToken = getMembershipAuthTokenFromLocalStorage();

  if (localStorageToken) {
    return `${AuthTokenComponent.TOKEN} ${localStorageToken}`;
  }

  const URLToken = getURLToken();

  if (URLToken) {
    return `${AuthTokenComponent.JWT} ${URLToken}`;
  }

  const auth0Token = await getAuth0Token();

  if (auth0Token) {
    return `${AuthTokenComponent.JWT_AUTH} ${auth0Token}`;
  }

  return null;
};

/**
 * Sets the fetch header values needed for authenticating requests.
 * @param {Object} response
 */
export const handleAddAuthTokenAndCurrentIds = async (response: AuthMetaResponse): Promise<void> => {
  if (!response || !response.membershipId) {
    return;
  }

  // Append new data to current memberships if exist
  const currentMembershipsData = lsHelpers.getMembershipsDataFromLocalStorage() || {};
  const newMembershipId = response.membershipId;
  const newMembershipData = {
    [newMembershipId]: {
      authToken: response.userAuthToken,
      companyId: response.companyId,
      userId: response.userId,
    },
  };

  const mergedMembershipsData = Object.assign(currentMembershipsData, newMembershipData);

  lsHelpers.localStorageSet('currentMembershipId', newMembershipId);
  lsHelpers.localStorageSet('memberships', JSON.stringify(mergedMembershipsData));

  // Set the Authorization header for fetching
  const authToken = await getAuthToken();
  if (authToken) {
    FetchService.setAuthHeader(authToken);
  }

  identifyUserByMembershipId(newMembershipId, {
    companyId: mergedMembershipsData[newMembershipId].companyId,
    userId: mergedMembershipsData[newMembershipId].userId,
  });
};

/**
 * Returns whether an auth token exists in local storage and checks if it's authenticated through auht0.
 * Auth0Client could be authenticated but if there's not a reference to the company_id it means that is in the process of authenticating or
 * it has an invalid auth, so it will return false.
 */
export const hasAuthToken = (): boolean => {
  const localStorageToken = getMembershipAuthTokenFromLocalStorage();

  return Boolean(localStorageToken || (Auth0Client.isAuthenticated && lsHelpers.getCurrentCompanyId()));
};
