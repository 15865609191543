import { change } from 'redux-form';

import { applyPayablesTableFilters, applyReceivablesTableFilters } from 'actions/tables';

import { createItemFormFields } from 'constants/formFields';
import { formNamesItem } from 'constants/forms';
import {
  amountDueFieldOperatorOptions,
  bankAccountFieldOperatorOptions,
  dateFieldOperatorsOptions,
  invoiceNumbersFieldOperatorOptions,
} from 'constants/itemFilters';

import { ItemFilterLabels } from 'enums/itemFilters';

import { isCurrentPathCreateItemStateAwaitingPayment, isCurrentPathCreateItemStateNew } from 'helpers/items';
import { getQueryParamsFromUrl } from 'helpers/queryParams';
import { isTabReceivables } from 'helpers/urls';
import { and, hasLength, isArray, isEqual, or, ternary } from 'helpers/utility';

import {
  FilterOperator,
  UIItemFilter,
  ItemFilterOperatorOption,
  ItemFilter,
  IdFilter,
  InvoiceNumbersFilter,
} from 'interfaces/itemFilters';
import type { onApplyFiltersActionType } from 'interfaces/table.types';

import { createFilterListFromParams } from 'modules/itemFilters/createFilters';
import { filterToUrlParams } from 'modules/itemFilters/filterToUrlParams';

/**
 * Get correct applyFilters action based on current tab/route
 */
export const getApplyFiltersAction = (tab: string): onApplyFiltersActionType => {
  if (isCurrentPathCreateItemStateAwaitingPayment() || isCurrentPathCreateItemStateNew()) {
    return (filters) => change(formNamesItem.CREATE_ITEM, createItemFormFields.UI_FILTERS, filters);
  }

  return isTabReceivables(tab) ? applyReceivablesTableFilters : applyPayablesTableFilters;
};

export const isFilterAmountDue = (label: ItemFilterLabels): boolean => isEqual(label, ItemFilterLabels.AMOUNT_DUE);

export const isFilterBankAccount = (label: ItemFilterLabels): boolean => isEqual(label, ItemFilterLabels.BANK_ACCOUNT);

export const isFilterCreatedDate = (label: ItemFilterLabels): boolean => isEqual(label, ItemFilterLabels.CREATED_DATE);

export const isFilterCurrentApprover = (label: ItemFilterLabels): boolean =>
  isEqual(label, ItemFilterLabels.CURRENT_APPROVER);

export const isFilterDueDate = (label: ItemFilterLabels): boolean => isEqual(label, ItemFilterLabels.DUE_DATE);

export const isFilterDateRelated = (label: ItemFilterLabels): boolean =>
  or(isFilterCreatedDate(label), isFilterDueDate(label));

export const isFilterInvoiceNumbers = (filter: Pick<ItemFilter, 'label'>): filter is InvoiceNumbersFilter =>
  isEqual(filter.label, ItemFilterLabels.INVOICE_NUMBERS);

export const isFilterOperatorBetween = (operator: FilterOperator): boolean => isEqual(operator, FilterOperator.BETWEEN);

export const isFilterOperatorEqual = (operator: FilterOperator): boolean => isEqual(operator, FilterOperator.EQUALS);

export const isFilterOperatorGreaterOrEqual = (operator: FilterOperator): boolean =>
  isEqual(operator, FilterOperator.GREATER_THAN_OR_EQUAL);

export const isFilterOperatorInSet = (operator: FilterOperator): boolean => isEqual(operator, FilterOperator.IS_IN_SET);

export const isFilterOperatorLessOrEqual = (operator: FilterOperator): boolean =>
  isEqual(operator, FilterOperator.LESS_THAN_OR_EQUAL);

export const getFilterOperatorOptionsForFilter = (label: ItemFilterLabels): ItemFilterOperatorOption[] => {
  if (isFilterAmountDue(label)) {
    return amountDueFieldOperatorOptions;
  }

  if (isFilterDateRelated(label)) {
    return dateFieldOperatorsOptions;
  }

  if (isFilterInvoiceNumbers({ label })) {
    return invoiceNumbersFieldOperatorOptions;
  }

  return bankAccountFieldOperatorOptions;
};

/**
 * Given an array of filters, checks if at least one is completely filled out
 */
export const doesAtLeastOneFilterHaveValue = (filters: UIItemFilter[]): boolean =>
  filters.some((filter) => {
    // range / set
    if (isArray(filter.value)) {
      // if filter is bank account, at least one value should be selected
      // otherwise selected range (for date/amount) should be valid
      return ternary(
        isFilterBankAccount(filter.label) || isFilterCurrentApprover(filter.label),
        hasLength(filter.value),
        /* @ts-ignore TODO: type checking on index 0 and 1 fails */
        and(filter.value[0], filter.value[1]),
      );
    }

    // single value
    return Boolean(filter.value);
  });

/**
 * Given an array of filters, checks if at least one is not filled out
 */
export const doesAtLeastOneFilterNotHaveValue = (filters: UIItemFilter[]): boolean =>
  filters.some((filter) => {
    // range / set
    if (isArray(filter.value)) {
      // if filter is bank account, at least one value should be selected
      // otherwise selected range (for date/amount) should be valid
      return ternary(
        isFilterBankAccount(filter.label) || isFilterCurrentApprover(filter.label),
        !hasLength(filter.value),
        /* @ts-ignore TODO: type checking on index 0 and 1 fails */
        or(!filter.value[0], !filter.value[1]),
      );
    }

    // single value
    return !filter.value;
  });

export const isItemIdFilter = (filter: ItemFilter): filter is IdFilter => filter.label === ItemFilterLabels.ITEM_ID;

/**
 * Return a list of filters parsed from the URL
 */
export const parseFiltersFromUrl = (): UIItemFilter[] => {
  const params = getQueryParamsFromUrl();

  return createFilterListFromParams(params);
};

/**
 * Transforms given filter list to key value pairs expected by the backend
 * @example transformFiltersListToParamsObject(filters) = { filter_one: 'filterValue', filter_two: 'filterValue' }
 */
export const transformFiltersListToParamsObject = (filters: UIItemFilter[]): Record<string, unknown> =>
  filters.reduce((agg, f) => ({ ...agg, ...filterToUrlParams(f) }), {});
