import React from 'react';

import { ItemKinds } from 'enums/items';

import { ExternalReceiptBill, ExternalReceiptPayment } from './components';
import { ExternalReceiptSingleItemProps } from './ExternalReceiptSingleItem.types';

/**
 * Component rendering bill or payment in the scope of External Receipt component.
 */
const ExternalReceiptSingleItem: React.FC<ExternalReceiptSingleItemProps> = ({
  attachments,
  canNextPage,
  canPreviousPage,
  enableLineItems,
  item,
  payment,
  onGoToPage,
  onViewBills,
  onViewLineItems,
  pageCount,
  pageIndex,
  partnershipCountryCode,
}) => {
  if (item.kind === ItemKinds.PAYABLE) {
    return (
      <ExternalReceiptBill attachments={attachments} item={item} partnershipCountryCode={partnershipCountryCode} />
    );
  }

  return (
    <ExternalReceiptPayment
      amount={item.amountPartner}
      attachments={attachments}
      canNextPage={canNextPage}
      canPreviousPage={canPreviousPage}
      currencyCode={item.currencyCodeReceiver}
      enableLineItems={enableLineItems}
      item={item}
      onGoToPage={onGoToPage}
      onViewBills={onViewBills}
      onViewLineItems={onViewLineItems}
      pageCount={pageCount}
      pageIndex={pageIndex}
      payment={payment}
    />
  );
};

export default ExternalReceiptSingleItem;
