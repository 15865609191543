import { FieldMappingData } from '@routable/tablematic';
import _set from 'lodash/set';

import { OverlayData } from './overlays.models';

/**
 * Flattens overlay data into an object format based on a specified source.
 *
 * This function takes Overlay data and extracts values from overlays matching a specified source (default 'ocr').
 * For a special key 'lineItems', it recursively processes each item to extract and flatten the overlay data.
 *
 * Note: This is only using `ocr` as source, it can change eventually if we're interested in processing multiple sources
 */
export const flattenOverlayDataBySource = (overlaysData: OverlayData, source = 'ocr') => {
  return Object.entries(overlaysData || {}).reduce((acc, [key, value]) => {
    if (key === 'lineItems') {
      const transformedLineItems = overlaysData.lineItems?.map((lineItem) => flattenOverlayDataBySource(lineItem));

      acc.lineItems = transformedLineItems;
      return acc;
    }

    const ocrValueFromOverlay = value.find((overlay) => overlay.source === source)?.value;

    return {
      ...acc,
      [key]: ocrValueFromOverlay,
    };
  }, {} as Record<string, unknown>);
};

export const transformOverlayDataWithFieldMapping = (fieldMapping: FieldMappingData, overlaysData: OverlayData) => {
  if (!overlaysData || !fieldMapping) {
    return {};
  }

  // Extract field mapping labels for line items
  // This removes the prefix from line_items.account -> account
  const extractLineItemLabels = (mapping: FieldMappingData) => {
    return Object.entries(mapping).reduce((acc, [key, fieldPath]) => {
      const [, lineItemValueKey] = (fieldPath as string).split('.');
      acc[key] = lineItemValueKey;
      return acc;
    }, {});
  };

  return Object.entries(fieldMapping).reduce((acc, [fieldKey, fieldPath]) => {
    // lineItems field key is a special case
    if (fieldKey === 'lineItem') {
      const lineItemMappingLabels = extractLineItemLabels(fieldPath as FieldMappingData);
      const transformedLineItems = overlaysData.lineItems?.map((lineItem) =>
        transformOverlayDataWithFieldMapping(lineItemMappingLabels, lineItem),
      );

      acc.overlayLineItems = transformedLineItems;
      return acc;
    }

    // TODO: We're intentionally using only OCR as source
    // This will change in the future when we want to support multiple sources
    const ocrValueFromOverlay = overlaysData[fieldKey]?.find((overlay) => overlay.source === 'ocr')?.value;

    if (ocrValueFromOverlay) {
      _set(acc, fieldPath as string, ocrValueFromOverlay);
    }

    return acc;
  }, {} as Record<string, unknown>);
};
