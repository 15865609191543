import { useMemo } from 'react';

import { flattenOverlayDataBySource } from './helpers';
import { useItemOverlays } from './useItemOverlays';

type UseOcrDataFromOverlaysOptions = {
  enabled?: boolean;
  itemId: string;
};

/**
 * Retrieves OCR data from item overlays.
 *
 * This hook extracts OCR data from Overlays, flattening the data structure
 * from an array to a key-value object. Each key in the returned object corresponds to an overlay key, with its value
 * set to the OCR value, if available.
 *
 * Note: This hook does not map item initial values from field mapping like `useItemInitialDataFromOverlays`. It only extracts OCR values.
 */
export const useOcrDataFromOverlays = ({ enabled = true, itemId }: UseOcrDataFromOverlaysOptions) => {
  const isEnabled = enabled && Boolean(itemId);
  const { data: itemOverlaysData, isFetching } = useItemOverlays({ enabled: isEnabled, itemId });

  const data = useMemo(
    () => (!isEnabled || isFetching ? {} : flattenOverlayDataBySource(itemOverlaysData)),
    [isEnabled, itemOverlaysData, isFetching],
  );

  return { data, isLoading: isFetching };
};
