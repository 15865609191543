import { WebhookTestData } from 'interfaces/webhooks';

import * as types from 'types/webhooks';

/**
 * Redux action for closing webhook test modal
 */
export const closeWebhookTestModal = (): ReduxAction => ({
  type: types.CLOSE_WEBHOOKS_TEST_MODAL,
});

/**
 * Redux action for opening webhook test modal
 */
export const openWebhookTestModal = (): ReduxAction => ({
  type: types.OPEN_WEBHOOKS_TEST_MODAL,
});

/**
 * Redux action for updating webhook test
 */
export const updateWebhookTest = (payload: WebhookTestData): ReduxAction => ({
  payload,
  type: types.UPDATE_WEBHOOKS_TEST,
});

/**
 * Redux action for removing webhook test
 */
export const removeWebhookTest = (payload: string): ReduxAction => ({
  payload,
  type: types.REMOVE_WEBHOOKS_TEST,
});
