import { Environment } from 'constants/env';
import {
  ACTIVE_EXTERNAL_ROUTES,
  COMPANIES_LIST_FILTERS,
  CONTACTS,
  CONTACTS_TABS,
  DASHBOARD,
  ENVIRONMENT_DOMAINS,
  ERROR,
  EXTERNAL,
  EXTERNAL_ZENDESK_ROUTES,
  INTEGRATIONS_TABS,
  ITEM_DETAILS_FILTERS,
  MEGABOX_DOMAIN,
  PAYABLES_ROUTE,
  PAYMENTS_LIST_FILTERS,
  PAYMENTS_LIST_TABS,
  PAYMENTS,
  RECEIVABLES_ROUTE,
  SETTINGS_ACCOUNT_FILTERS,
  TEAM_MEGABOX_URLS,
} from 'constants/routes';

import { isNodeEnvProduction } from 'helpers/env';
import { isKindPayable } from 'helpers/items';
import { getQueryParam, getQueryString } from 'helpers/queryParams';
import { getJoinedPath } from 'helpers/routeHelpers';
import {
  allValues,
  and,
  isEqual,
  isIncluded,
  isValueEmpty,
  lastElementIn,
  secondLastElementIn,
  getValidValueOrDefault,
} from 'helpers/utility';

import { getEnvironment, getBaseWebsiteUrl } from 'services/api';

// *************************************
// Base URL helpers
// *************************************
/**
 * Returns window.location.
 * Primarily for testability of code.
 * @return {Location}
 */
export const getCurrentLocation = () => window.location;

/**
 * If href exists, redirects to it.
 * @param {StringMaybe} href
 * @return {void}
 */
export const redirectTo = (href) => {
  if (href) {
    getCurrentLocation().assign(href);
  }
};

export const getCurrentUrlPath = () => {
  let url = getCurrentLocation().pathname;

  // Remove last forward slash
  if (url.slice(-1) === '/') {
    url = url.substring(0, url.length - 1);
  }
  return url;
};

/**
 * Re-build a URL without query params or hashes
 * e.g. http://google.com/#page?q=a => http://google.com/
 * @param {Object} location
 * @returns {string}
 */
export const getUrlWithoutParams = (location = window.location) => {
  const { hostname, pathname, protocol } = location;
  return `${protocol}//${hostname}${pathname}`;
};

export const getDashboardURLParams = () => {
  /* Assuming format:
    /dashboard/[SECTION]/[TAB]/[FILTER]/[ITEM]
    or
    /dashboard/[SECTION]/[TAB]/[FILTER]
  */

  let url = getCurrentUrlPath();

  // Replacing /dashboard/
  url = url.replace(`/${DASHBOARD}/`, '');

  // Replacing /dashboard if it exists
  url = url.replace(`/${DASHBOARD}`, '');

  // Resulting in [SECTION]/[TAB]/[FILTER]/[ITEM] or [SECTION]/[TAB]/[FILTER]
  const paramsArr = url.split('/');
  const listFilters = paramsArr[0] === CONTACTS ? COMPANIES_LIST_FILTERS : PAYMENTS_LIST_FILTERS;

  return {
    section: paramsArr[0] || PAYMENTS,
    tab: paramsArr[1] || PAYMENTS_LIST_TABS.INBOX,
    filter: getValidValueOrDefault(paramsArr[2], listFilters, PAYMENTS_LIST_FILTERS.ALL),
    itemId: paramsArr[3] || null,
  };
};

/**
 * Returns a full url, minus the origin portion.
 * (Including the origin would cause a full-page reload if used with react-router.)
 * @param location {Location} Browser location object.
 * @returns {string} An app-routing-friendly url string.
 * @example
 * // assuming window.location.href === 'https://routable.com/some/path?query=true'
 * getUrlWithoutOrigin(window.location) // >> '/some/path?query=true'
 * // assuming window.location.href === 'https://routable.com'
 * getUrlWithoutOrigin(window.location) // >> '/'
 */
export const getUrlWithoutOrigin = (location = getCurrentLocation()) => {
  const { pathname, search } = location;
  return `${pathname}${search}`;
};

/**
 * Helper to build URL with or without query parameters
 * @param {string} url
 * @param {?Object} queryParams
 * @return {string}
 */
export const getURLWithQueryParams = (url, queryParams = undefined) => {
  if (isValueEmpty(queryParams)) {
    return url;
  }

  return `${url}?${getQueryString(queryParams)}`;
};

/**
 * Returns the base route for the current url
 * @returns {string}
 */
export const getBaseRoute = () => {
  const currentURL = getCurrentUrlPath();
  return currentURL.split('/')[1] || '';
};

// *************************************
// Route specific URL helpers
// *************************************
export const isFilterAll = (filter) => isEqual(filter, PAYMENTS_LIST_FILTERS.ALL);
export const isFilterNeedsApproval = (filter) => isEqual(filter, PAYMENTS_LIST_FILTERS.NEEDS_APPROVAL);
export const isFilterNeedsMyApproval = (filter) => isEqual(filter, PAYMENTS_LIST_FILTERS.MY_APPROVAL);
export const isFilterNeedsOtherApproval = (filter) => isEqual(filter, PAYMENTS_LIST_FILTERS.TEAM_APPROVAL);

export const isFilterOneOfNeedsApprovalFilters = (filter) =>
  isFilterNeedsApproval(filter) || isFilterNeedsMyApproval(filter) || isFilterNeedsOtherApproval(filter);

export const isTabAnIntegration = (tab) => isIncluded(allValues(INTEGRATIONS_TABS), tab);

export const isTabCompanies = (tab) => isEqual(tab, CONTACTS_TABS.COMPANIES);

export const isTabError = (tab) => isEqual(tab, ERROR);

export const isTabInbox = (tab) => isEqual(tab, PAYMENTS_LIST_TABS.INBOX);

export const isTabPayables = (tab) => isEqual(tab, PAYMENTS_LIST_TABS.PAYABLES);

export const isTabReceivables = (tab) => isEqual(tab, PAYMENTS_LIST_TABS.RECEIVABLES);

export const isTabRecent = (tab) => isEqual(tab, PAYMENTS_LIST_TABS.RECENT);

export const isTabVendors = (tab) => isEqual(tab, COMPANIES_LIST_FILTERS.VENDORS);

/**
 * @param {*} tab
 * @returns {boolean}
 */
export const isTabTeamMembers = (tab) => isEqual(tab, SETTINGS_ACCOUNT_FILTERS.TEAM_MEMBERS);

/**
 * @param {*} tab
 * @returns {boolean}
 */
export const isTabInviteTeamMembers = (tab) => isEqual(tab, SETTINGS_ACCOUNT_FILTERS.INVITE_TEAM_MEMBER);

export const isTabInvited = (tab) => isEqual(tab, SETTINGS_ACCOUNT_FILTERS.INVITED);

export const isTabInactive = (tab) => isEqual(tab, SETTINGS_ACCOUNT_FILTERS.INACTIVE);

/**
 * The end of the team member profile route is a string ID, so the best we can do is to check if it's NOT the other
 * routes for team member
 * @param {*} tab
 * @returns {boolean}
 */
export const isTabTeamMemberProfile = (tab) =>
  and(!isTabTeamMembers(tab), !isTabInviteTeamMembers(tab), !isTabInvited(tab), !isTabInactive(tab));

/**
 * @param {*} tab
 * @returns {boolean}
 */
export const isTabRoles = (tab) => tab === SETTINGS_ACCOUNT_FILTERS.ROLES;

/**
 * @param {*} tab
 * @returns {boolean}
 */
export const isTabCreateRole = (tab) => tab === SETTINGS_ACCOUNT_FILTERS.CREATE_ROLE;

/**
 * The end of the role route is a string ID, so the best we can do is to check if it's NOT the other
 * routes for roles
 * @param {*} tab
 * @returns {boolean}
 */
export const isTabRole = (tab) => tab && !isTabRoles(tab) && !isTabCreateRole(tab);

/**
 * Helper to create a base path URL for an item list page, with the item kind
 * @param {ItemKind} itemKind
 * @returns {string}
 */
export const getItemListPageUrlFromItemKind = (itemKind) => {
  const basePath = isKindPayable(itemKind) ? PAYABLES_ROUTE : RECEIVABLES_ROUTE;
  return getJoinedPath(basePath, PAYMENTS_LIST_FILTERS.ALL);
};

/**
 * Helper to create a base path URL for an item list page, with the item object
 * @param {Item} item
 * @returns {string}
 */
export const getItemListPageUrlFromItem = (item) => getItemListPageUrlFromItemKind(item.kind);

/**
 * Helper to create a base path URL for an item page
 * @param item
 * @returns {string}
 */
export const getItemPageUrlFromItem = (item) => {
  const itemListUrl = getItemListPageUrlFromItem(item);
  return getJoinedPath(itemListUrl, item.id, ITEM_DETAILS_FILTERS.ITEM);
};

// *************************************
// Generic URL helpers
// *************************************

/**
 * Creates a mailto url
 * @param {string} email
 * @param {ObjectMaybe} options
 * @return {string}
 */
export const getMailtoUrl = (email, options = undefined) => {
  let mailTo = `mailto:${email}`;

  if (options) {
    const queries = getQueryString(options);
    mailTo = `${mailTo}?${queries}`;
  }

  return mailTo;
};

/**
 * Returns top-level domain url, without 'app.' as prefix in production env
 * @return {string}
 */
export const getTopLevelDomain = () => {
  // We get the base website URL
  const tld = new URL(getBaseWebsiteUrl());

  // [DEV-1591] In production, we want to remove app. prefix from the host
  if (isNodeEnvProduction()) {
    tld.host = tld.host.replace(/^app\./g, '');
  }

  // We return the URL as a string
  return tld.toString();
};

/**
 * Make a new, similar URL by replace the end of the route with a new filter, ID, etc.
 * @example
 * // currentPath = 'dashboard/payments/payables/all/94c62ddd-7758-4937-926c-e0c9ebb68547/item'
 * const matchUrl = makeSimilarUrl(ITEM_DETAILS_FILTERS.PARTNER);
 * // dashboard/payments/payables/all/94c62ddd-7758-4937-926c-e0c9ebb68547/partner
 * <Route path={matchUrl} component={ThreadDetailsPartnerTabView} />
 * @function
 * @param {string} replacement - A filter or ID
 * @returns {string} - Current path with a new ending
 */
export const makeSimilarUrl = (replacement) => {
  const currentPathParts = getCurrentUrlPath().split('/');
  // replace with new URL part
  currentPathParts.splice(currentPathParts.length - 1, 1, replacement);
  return currentPathParts.join('/');
};

/**
 * Helper primarily for testing purposes. Returns the result of URL.createObjectURL.
 * @param {Blob} blob
 * @return {string}
 */
export const createObjectUrl = (blob) => URL.createObjectURL(blob);

/**
 * Helper to check whether the URL is a full path URL
 * @param {string} url
 * @returns {boolean}
 */
export const isUrlFullPathUrl = (url) => Boolean(url.startsWith('https://') || url.startsWith('http://'));

/**
 * isPathLogin
 * Returns boolean based on whether path is login page
 * @param location
 * @return {boolean}
 */
export const isPathLogin = (location) => location.pathname.includes('/login');

/**
 * Returns the last path segment of the current location
 * @param {Location} [location=getCurrentLocation()]
 * @return {string}
 */
export const getLastPathSegment = (location = getCurrentLocation()) => {
  const { pathname } = location;
  const pathSegments = pathname.split('/');

  return lastElementIn(pathSegments);
};

/**
 * Returns the second last path segment of the current location
 * @param {Location} [location=getCurrentLocation()]
 * @return {string}
 */
export const getSecondLastPathSegment = (location = getCurrentLocation()) => {
  const { pathname } = location;
  const pathSegments = pathname.split('/');

  return secondLastElementIn(pathSegments);
};

/**
 * Update url queryParams with the ones provided and return the url string
 * @param {string} url
 * @param {Object} queryParams
 * @returns
 */
export const updateUrlQueryParams = (url, queryParams = {}) => {
  const newUrl = new URL(url);

  Object.entries(queryParams).forEach(([key, value]) => {
    newUrl.searchParams.set(key, value);
  });

  return newUrl.toString();
};

/**
 * Returns megabox's name from the URL
 * @param {string} subdomain
 * @returns {string}
 */
export const getMegaboxNameFromSubdomain = (subdomain) => {
  // check if we are on a team's megabox
  let megaboxName = TEAM_MEGABOX_URLS.find((url) => subdomain.includes(url));

  // this means we are on a specific user's megabox
  if (!megaboxName) {
    [megaboxName] = subdomain.split('-');
  }

  return megaboxName;
};

/**
 * Returns company namespace from the URL
 * @returns {string}
 */
export const getCompanyNamespaceFromUrl = (location = window.location) => {
  const { host } = location;
  const domainParts = host.split('.');
  const reservedSubdomains = ['app', 'www'];

  if (domainParts.length <= 2) {
    // Apex domains such as routablehq.com will never have a subdomain,
    // But they might have a namespace query param.
    return getQueryParam('namespace');
  }

  const [subdomain] = domainParts;
  let companyNamespace = '';

  // Megabox urls include the box's name upfront
  if (host.includes(MEGABOX_DOMAIN)) {
    const megaboxName = getMegaboxNameFromSubdomain(subdomain);

    // extract the company namespace from the url by removing the first instance of the megaboxName
    companyNamespace = subdomain
      .replace(megaboxName, '') // remove first instance of megaboxName
      .replace(/^-/, ''); // remove a possible leading '-'
  } else if (!reservedSubdomains.includes(subdomain)) {
    // Check if namespace exists on non-megabox urls
    companyNamespace = subdomain;
  }

  // Check if namespace exists in the query if not in the subdomain
  if (!companyNamespace) {
    companyNamespace = getQueryParam('namespace');
  }

  return companyNamespace;
};

/**
 * Returns the domain we are currently on
 * @returns {string}
 */
export const getDomainOnly = () => {
  const { host } = window.location;
  const domains = ENVIRONMENT_DOMAINS[getEnvironment()];

  if (host.includes(MEGABOX_DOMAIN)) {
    return MEGABOX_DOMAIN;
  }

  if (host.includes(domains.brandedWorkspaces)) {
    return domains.brandedWorkspaces;
  }
  return domains.topLevel;
};

/**
 * Returns domain URL with or without company namespace (if given or not)
 * @param {string} [companyNamespace]
 * @returns {string}
 */
export const getDomainWithOrWithoutCompanyNamespace = (
  companyNamespace,
  includePathname = false,
  includeSearch = false,
) => {
  const { protocol, host } = window.location;
  const pathname = includePathname ? window.location.pathname : '';
  const search = includeSearch ? window.location.search : '';
  const [brandedSubdomain, ...restOfURL] = host.split('.');
  const environment = getEnvironment();
  const domains = ENVIRONMENT_DOMAINS[environment];

  if (Environment.DEVELOPMENT === environment && host.includes(MEGABOX_DOMAIN)) {
    const megaboxName = getMegaboxNameFromSubdomain(brandedSubdomain);
    const subdomain = companyNamespace ? `${megaboxName}-${companyNamespace}` : `${megaboxName}`;
    return `${protocol}//${subdomain}.${restOfURL.join('.')}${pathname}${search}`;
  }

  if (!companyNamespace) {
    return `${protocol}//${domains.brandedWorkspaces}${pathname}${search}`;
  }
  return `${protocol}//${companyNamespace}.${domains.brandedWorkspaces}${pathname}${search}`;
};

/**
 * Returns the branded workspaces domain for the given environment
 *
 * Note: Megabox are not considered in this display/label function and will default to routable.cloud
 */
export const getBrandedWorkspacesDisplayDomain = () => ENVIRONMENT_DOMAINS[getEnvironment()].brandedWorkspaces;

/**
 * Returns true if the current location.host includes path
 * @param path
 * @param {Location} [location]
 */
export const isHostIncludingPath = (path, location = getCurrentLocation()) => location.host.includes(path);

/**
 * Returns true if the current location.host is ending with passed ending
 * @param {string} ending
 * @param {Location} [location]
 */
export const isHostEndingWith = (ending, location = getCurrentLocation()) => location.host.endsWith(ending);

/**
 * Returns true if the current location.host is a topLevel domain
 * @param {Location} [location]
 */
export const isTopLevelDomain = (location = getCurrentLocation()) =>
  location.host === ENVIRONMENT_DOMAINS[getEnvironment()].topLevel;

/**
 * Returns true if we are in the external flow using branded subdomains.
 * e. g. external.routablehq.com
 * @returns {boolean}
 */
export const isSubdomainNamespaceExternal = (location = window.location) =>
  getCompanyNamespaceFromUrl(location) === EXTERNAL;

/**
 * Returns true if we should use external_zendesk_widget_id for given location
 * @return {boolean}
 */
export const isExternalZendeskRoute = (location = window.location) => {
  const baseRoute = getBaseRoute();

  if (EXTERNAL_ZENDESK_ROUTES.includes(baseRoute)) {
    return true;
  }

  if (
    isSubdomainNamespaceExternal(location) &&
    ACTIVE_EXTERNAL_ROUTES.map((route) => route.split('/')[0]).includes(baseRoute)
  ) {
    return true;
  }

  return false;
};
