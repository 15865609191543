/**
 * Bank account view type
 */
export enum BankAccountViewType {
  ADDRESS = 'ADDRESS',
  FULL = 'FULL',
  NEW_PARTNER = 'NEW_PARTNER',
  PARTNER_ACH = 'PARTNER_ACH',
  PARTNER_ADDRESS = 'PARTNER_ADDRESS',
  SIMPLE = 'SIMPLE',
  SIMPLE_VERIFY = 'SIMPLE_VERIFY',
  THREAD = 'THREAD',
  THREAD_PARTNER_ACH = 'THREAD_PARTNER_ACH',
  THREAD_PARTNER_ADDRESS = 'THREAD_PARTNER_ADDRESS',
}

/**
 * Multipurpose UI status enum (generic Intent).
 */
export enum GenericDisplayStatus {
  ACTION_MISSED = 'ACTION_MISSED',
  ACTION_NEEDED = 'ACTION_NEEDED',
  ARCHIVED = 'ARCHIVED',
  AVAILABLE = 'AVAILABLE',
  CAUTION = 'CAUTION',
  COMPLETE = 'COMPLETE',
  COMPLIANCE_HOLD = 'COMPLIANCE_HOLD',
  DANGER = 'DANGER',
  DEFAULT = 'DEFAULT',
  NEUTRAL = 'NEUTRAL',
  NOT_STARTED = 'NOT_STARTED',
  POST_DANGER = 'POST_DANGER',
  POST_STARTED = 'POST_STARTED',
  READY = 'READY',
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
  TEMPORAL = 'TEMPORAL',
  QUEUED = 'QUEUED',
  WARN = 'WARN',
}

export enum PartnershipListActionType {
  ADD = 'Add',
  INVITE = 'Invite',
}

export enum CompanyAddressSideSheetAction {
  ADD = 'Add',
  UPDATE = 'Update',
}

/**
 * Possible types of roles.
 * @enum {string}
 */
export enum RoleTypes {
  ADMINISTRATOR = 'administrator',
  APPROVER = 'approver',
  COLLABORATOR = 'collaborator',
  CREATOR = 'creator',
  DEVELOPER = 'developer',
  IT_ADMIN = 'it_admin',
  VENDOR_AND_CUSTOMER_MANAGER = 'vendor_and_customer_manager',
}

export enum Position {
  BOTTOM = 'bottom',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
}
