import classNames from 'classnames';
import React from 'react';

import { ButtonV2, FormControl, IconNames, Image } from 'components';

import { ButtonSize } from 'constants/button';
import { formNamesAuth } from 'constants/forms';
import { googleLetterLogoMap } from 'constants/images';
import { sizes } from 'constants/styles';

import { isValueEmpty } from 'helpers/utility';

import { getSubmitButtonText } from 'modules/auth/AcceptMembershipInvite/helpers/ui';

import type { AcceptMembershipInviteFormActionButtonsProps } from './AcceptMembershipInviteFormActionButtons.types';

/**
 * AcceptMembershipInviteFormActionButtons
 */
const AcceptMembershipInviteFormActionButtons: React.FC<AcceptMembershipInviteFormActionButtonsProps> = ({
  canManageSsoSettings,
  companySSOSettings,
  isSubmitting,
  loginWithPassword,
  setLoginWithPassword,
}) => {
  const isSSOTurnedOn = !isValueEmpty(companySSOSettings);
  const isSSOTurnedOnAndOptional = isSSOTurnedOn && !companySSOSettings.ssoRequired;

  return (
    <>
      <FormControl
        className={classNames({
          'remove-margin-bottom': canManageSsoSettings || loginWithPassword || !isSSOTurnedOnAndOptional,
        })}
      >
        <ButtonV2
          htmlFor={formNamesAuth.ACCEPT_MEMBERSHIP_INVITE}
          id="auth_acceptMembership_btn"
          isLoading={isSubmitting}
          rightIconName={IconNames.ARROW_RIGHT}
          rightIconProps={{ style: { marginLeft: 'auto' } }}
          rightIconSize={sizes.iconSizes.LARGE}
          size={ButtonSize.LARGE}
          type="submit"
        >
          {!canManageSsoSettings && !loginWithPassword && isSSOTurnedOn && (
            <Image alt="Google logo" className="google-btn--logo margin-right--m" src={googleLetterLogoMap.dark} />
          )}
          {getSubmitButtonText(companySSOSettings, loginWithPassword, canManageSsoSettings)}
        </ButtonV2>
      </FormControl>

      {!canManageSsoSettings && !loginWithPassword && isSSOTurnedOnAndOptional && (
        <FormControl className="remove-margin-bottom">
          <a
            className="font-color--dark-jordan font-size--sm font-weight--bold"
            onClick={setLoginWithPassword}
            role="presentation"
          >
            Log in with password
          </a>
        </FormControl>
      )}
    </>
  );
};

export default AcceptMembershipInviteFormActionButtons;
