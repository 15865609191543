import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderNumericInput } from 'components/form';

import * as propTypes from 'constants/propTypes';
import { field } from 'constants/styles/formStyles';

import { numberOnly } from 'helpers/fieldNormalizers';
import { getCurrencySymbol } from 'helpers/i18n';

import { useMemoizedConditionalValidate } from 'hooks';

import { useDynamicFieldRequiredValidators } from 'modules/table/hooks';

/**
 * Renders a text field formatted for currency to be rendered in a block section
 * @param {ComponentProps} props
 * @param {Object} props.formValues
 * @param {function} props.getIsDisabled
 * @param {string} props.name
 * @param {string} props.placeholder
 * @param {Function} props.validate
 * @returns {StatelessComponent}
 */
const CurrencyBlockField = (props) => {
  const { decimalScale, fixedDecimalScale, formValues, getIsDisabled, name, placeholder } = props;

  const { ui } = formValues;

  const { isRequired, validators } = useDynamicFieldRequiredValidators(props);
  const cachedValidators = useMemoizedConditionalValidate(!ui?.blockDetailsSections, validators);

  return (
    <Field
      allowNegative
      className={field.xl.full}
      component={ReduxFormFieldRenderNumericInput}
      currency={getCurrencySymbol()}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      isDebounced
      isDisabled={getIsDisabled(formValues)}
      isRequired={isRequired}
      name={name}
      normalize={numberOnly}
      placeholder={placeholder}
      type="text"
      validate={cachedValidators}
    />
  );
};

CurrencyBlockField.propTypes = {
  ...propTypes.baseFieldSharedPropTypes,
  decimalScale: PropTypes.number.isRequired,
  fixedDecimalScale: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
};

CurrencyBlockField.defaultProps = {
  ...propTypes.baseFieldSharedDefaultProps,
  fixedDecimalScale: undefined,
};

export default CurrencyBlockField;
