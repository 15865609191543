import React from 'react';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderInput } from 'components/input';

import { requiredValidator } from 'helpers/fieldValidation';
import { or } from 'helpers/utility';

import { useCountryCodesContext } from 'hooks';

import { LockedCountryInputTooltipContent } from './components';
import { createLockedCountryInputFormatter } from './helpers';
import { LockedCountryInputProps } from './LockedCountryInput.types';

/**
 * Component rendering locked country input field. Meant to be used
 * inside a Redux Form container.
 * @param {LockedCountryInputProps} props
 * @returns {StatelessComponent}
 */
const LockedCountryInput: React.FC<LockedCountryInputProps> = ({ label, reason, dataTestId, ...rest }) => {
  const countryCodeMap = useCountryCodesContext();
  const formatter = createLockedCountryInputFormatter(countryCodeMap);

  return (
    <Field
      {...rest}
      component={ReduxFormFieldRenderInput}
      dataTestId={dataTestId}
      format={formatter}
      inputClassName="padding-right--m-large"
      isDisabled
      isLocked
      label={or(label, 'Country')}
      tooltipContent={() => <LockedCountryInputTooltipContent content={reason} />}
      validate={[requiredValidator]}
    />
  );
};

export default LockedCountryInput;
