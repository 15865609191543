import React from 'react';

import { ContactTitleWithSubtitle } from 'complexComponents/ContactTitleWithSubtitle';

import { ContactCellsProps } from '../ContactCellsTypes.types';

/**
 * Shows the contact's name and email in the contact info column of the company contact table
 */
const ContactDetailsCell = ({ rowData }: ContactCellsProps): JSX.Element => (
  <ContactTitleWithSubtitle dataFullStory partnershipMember={rowData} />
);

export default ContactDetailsCell;
