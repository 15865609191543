import React from 'react';

import { Text } from 'components';

import { TextSize } from 'constants/styles/typography';

import type { Nullable } from 'interfaces/global';

import type { Props } from './Tooltip.types';

/**
 * Tooltip content conditionally rendered based on showTooltip prop
 */
const Tooltip = ({ tooltipText, showTooltip }: Props): Nullable<JSX.Element> => {
  if (!showTooltip) {
    return null;
  }

  return <Text.Regular size={TextSize.LEVEL_100}>{tooltipText}</Text.Regular>;
};

export default Tooltip;
