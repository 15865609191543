/* eslint-disable */
// Disable rules for this file some of the new eslint rules have issues with this file
// because some of ts vs js rules

import _get from 'lodash/get';
import _includes from 'lodash/includes';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { formValueSelector } from 'redux-form';
import styled from 'styled-components';

import { formNamesItem } from 'constants/forms';

import { formatDateString } from 'helpers/date';
import { useIsOcrEnabled } from 'helpers/ocr';
import { fieldMappings } from 'helpers/ocr/constants';

import { storeAccessor as store } from 'store/accessor';
import { useItemFieldMapping } from '@routable/tablematic';
import { ItemKinds } from 'constants/item';

export interface ProvenanceProps {
  children?: string;
  className?: string;
  conditional?: boolean;
  isBillView?: any;
  annotationValue?: any;
  watchProp?: string;
  watchInclude?: boolean;

  dirty?: boolean;
  formError?: unknown;
  form?: string;
  formUI?: any;
  initialItem?: unknown;
  item?: unknown;
  invalid?: boolean;
  ledger?: unknown;
  pristine?: boolean;
  submitting?: boolean;
  submitErrors?: unknown;
  valid?: boolean;
  watch?: unknown;
}

// Method to check if the item has ocr values pre-filled.
const hasOCRValues = (
  isBillView?: boolean,
  prop?: string,
  watchInclude?: boolean,
  conditional?: boolean,
  itemFieldMapping: any = fieldMappings,
): boolean => {
  if (!prop && !watchInclude) {
    return false;
  }

  if (!isBillView) {
    return false;
  }

  if (watchInclude && conditional) {
    return true;
  }

  return _includes(itemFieldMapping, prop);
};

// Wrapper for provenance fields.
const ProvenanceWrapper = styled.div`
  // WRAPPER STYLE
  position: relative;
  width: 100%;

  // BEGIN SELECT/INPUT PROVENANCE (Lightning Icon)
  &[data-ocr='true'][data-edit='false'] .select--label:before,
  &[data-ocr='true'][data-edit='false'] .input-container label:before {
    display: inline-block;
    content: '';
    width: 12px;
    height: 12px;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23a8378f'  %3E%3Cpath d='M9 11H6a1 1 0 01-1-1L5.91.9a1 1 0 01.995-.9h6.256a.839.839 0 01.779 1.15L11.2 8h2.978a.822.822 0 01.748 1.162l-4.764 10.481A.608.608 0 019 19.392V11z' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center;
    position: relative;
    top: 2px;
    margin-right: 6px;
  }
  // END SELECT/INPUT PROVENANCE (Lightning Icon)

  // BEGIN LEDGER FORM FIELD
  & .column-content {
    position: relative;
    overflow: hidden;
  }

  &[data-ocr='true'][data-edit='false'] .column-content:after {
    display: inline-block;
    content: '';
    left: 0;
    top: 0;
    border-width: 12px 12px 0 0;
    border-color: #ed7cd4 transparent transparent transparent;
    border-style: solid;
    position: absolute;
    z-index: 2;
  }
  // END LEDGER FORM FIELD
`;

/**
 * Renders Provenance wrapper to auto filled ocr'd items.
 * @return {FunctionComponent}
 */
const Provenance: React.FC<ProvenanceProps> = ({
  children,
  annotationValue,
  watch,
  watchProp = '',
  className = '',
  watchInclude = false,
  isBillView = false,
  conditional = false,
}) => {
  // TODO: This is a temporary fix to get this to not break in tests
  const reduxState: any = store?.getState?.();
  const selector = formValueSelector(formNamesItem.CREATE_ITEM);

  const [ocrEnabled, setOCREnabled] = useState<boolean>(false);
  const [fieldChanged, setFieldChanged] = useState<boolean>(false);
  const [fieldValue, setFieldValue] = useState<any>(); // Use any because we don't know what the type is and we want to be able to access properties.

  // Disabled in v1 this is for undo/redo stuff.
  // const [fieldStateValue, setFieldStateValue] = useState<any>(null); // Use any because we don't know what the type is and we want to be able to access properties.
  const [changeCount, setChangeCount] = useState<number>(0);

  const thisRef = useRef(null);

  let baseFieldValue: any = null;

  if (watchProp) {
    baseFieldValue = selector(reduxState, watchProp);
  }
  const { isOcrEnabled, isInboxBillView } = useIsOcrEnabled();
  const { data: itemFieldMapping } = useItemFieldMapping({ kind: ItemKinds.PAYABLE });

  // We use (useEffect) here because we "only" want to do
  // this when the component is mounted we do not want to observe this
  // because the user can make changes and override the ocr'd pre-filled values.
  useEffect(() => {
    const propsBeingWatched =
      isOcrEnabled &&
      baseFieldValue &&
      hasOCRValues(isBillView, watchProp, watchInclude, conditional, itemFieldMapping);
    if (propsBeingWatched) {
      setFieldChanged(false);
      setOCREnabled(propsBeingWatched);
      const childType: any = _get(children, 'props'); // Set any to avoid ts error below.
      if (childType) {
        // [NOTE] This used to read childType?.dateFormat and it caused issues.
        // We're trying to assign null to an instance, for whatever reason bracket notation negates this error.
        if (childType?.['dateFormat'] !== '') {
          baseFieldValue = formatDateString(baseFieldValue);
        }

        setFieldValue(baseFieldValue);
        setChangeCount(1);
      }
    }

    if (annotationValue) {
      setOCREnabled(true);
    }
  }, [annotationValue, itemFieldMapping]);

  // Watch the values in the state to see if they have been changed.
  // We use useMemo here because we are watching for underlying changes
  // to form fields that this wrapper is holding v2 will add in support for undo/redo so
  // this is where that will be handled in updating the form state for children.
  useMemo(() => {
    if (ocrEnabled) {
      if (fieldValue && changeCount > 1) {
        setFieldChanged(true);
      } else {
        setChangeCount(changeCount + 1);
      }
    }
  }, [baseFieldValue]); // Es-lint rule for including all values being used inside of a memo is wrong in this case, edgeCase where we are comparing before and current values but only trigger on prop values.

  const shouldUseValueMatchesAnnotation = Boolean(annotationValue);
  const valueMatchesAnnotation = annotationValue === watch;

  if (!ocrEnabled || !isInboxBillView || (shouldUseValueMatchesAnnotation && !valueMatchesAnnotation)) {
    return <>{children}</>;
  }

  return (
    <ProvenanceWrapper className={className} data-edit={fieldChanged} data-ocr={ocrEnabled} ref={thisRef}>
      {children}
    </ProvenanceWrapper>
  );
};

export default Provenance;
