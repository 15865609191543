import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';

import { useGetRouteQuery } from 'ducks/routableApi';

import { createPartnershipFormFields } from 'constants/formFields';

import { CurrentCompanySettingsIntegration } from 'interfaces/company';
import { EmptyObject } from 'interfaces/global';
import { Ledger } from 'interfaces/integrations';

import VendorReceivingCurrency from 'modules/dashboard/vendorReceivingCurrency/VendorReceivingCurrency';

import { currentCompanySettingsIntegrationSelector } from 'selectors/currentCompanySelectors';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';

import { PartnerCurrencyFieldProps } from './PartnerCurrencyField.types';

/**
 * Field to select vendor's ledger currency
 */
export const PartnerCurrencyField = ({
  countryCode,
  className,
  formName,
  ...rest
}: PartnerCurrencyFieldProps): React.ReactElement => {
  const dispatch = useDispatch();
  const response = useGetRouteQuery({
    field: 'receiver_currency',
    receiver_country: countryCode,
  });
  const companyIntegrationSettings: CurrentCompanySettingsIntegration = useSelector(
    currentCompanySettingsIntegrationSelector,
  );
  const ledger: EmptyObject | Ledger = useSelector(ledgerIntegrationSelector);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!response?.data?.data) {
      setOptions([]);
    }

    const arr = [];
    response?.data?.data.forEach((currency) => {
      arr.push({ id: currency, value: currency, text: currency });
    });

    setOptions(arr);

    // automatically select if only one option
    if (!companyIntegrationSettings.enableBaseCurrencyMode && arr.length === 1) {
      dispatch(change(formName, createPartnershipFormFields.PARTNER_LEDGER_CURRENCY_CODE, arr[0].id));
    }
    // eslint-disable-next-line
  }, [dispatch, formName, response?.data?.data]);

  useEffect(() => {
    // automatically select BCC if baseCurrencyMode is enabled
    if (companyIntegrationSettings.enableBaseCurrencyMode) {
      dispatch(change(formName, createPartnershipFormFields.PARTNER_LEDGER_CURRENCY_CODE, ledger.baseCurrencyCode));
    }
  }, [companyIntegrationSettings.enableBaseCurrencyMode, dispatch, formName, ledger.baseCurrencyCode]);

  return (
    <div className={clsx('form-control', className)}>
      <VendorReceivingCurrency
        className="w-full"
        fieldLabel={`Assigned currency in ${ledger.name}`}
        fieldName={createPartnershipFormFields.PARTNER_LEDGER_CURRENCY_CODE}
        isLocked={
          response.isFetching ||
          companyIntegrationSettings.enableBaseCurrencyMode ||
          !countryCode ||
          options.length <= 1
        }
        options={options}
        tooltipText={undefined}
        {...rest}
      />
    </div>
  );
};
