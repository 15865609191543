import React from 'react';

import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';

import { CurrencyCodeUsd } from 'constants/currency';
import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import type { MergeOnlyDomesticPartnershipsCrossBorderDisabledTooltipProps } from './MergeOnlyDomesticPartnershipsCrossBorderDisabledTooltip.types';

/**
 * Tooltip notifying the user that merging international vendors may cause inconsistencies,
 * and is therefore disallowed
 */
const MergeOnlyDomesticPartnershipsCrossBorderDisabledTooltip = ({
  children,
  isShown,
  ledgerName,
  placement = TooltipPlacement.RIGHT,
}: MergeOnlyDomesticPartnershipsCrossBorderDisabledTooltipProps): React.ReactElement => (
  <TooltipMUIConditionalWrapper
    tooltipProps={
      isShown
        ? {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            placement,
            title: (
              <Text.Regular
                color={typography.TextColor.GREY_X_DARK}
                lineHeight={typography.TextLineHeight.MEDIUM}
                size={typography.TextSize.LEVEL_100}
              >
                {`You haven't yet enabled payments to vendors using currencies other than ${CurrencyCodeUsd}. `}
                Merging international vendors with customers can cause inconsistencies with your
                {` ${ledgerName} data, so we have limited this action to domestic vendors only. `}
                Please speak to a Routable Customer Success Manager to enable this feature.
              </Text.Regular>
            ),
          }
        : {}
    }
  >
    {children}
  </TooltipMUIConditionalWrapper>
);

export default MergeOnlyDomesticPartnershipsCrossBorderDisabledTooltip;
