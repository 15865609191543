import React from 'react';
import { useHistory } from 'react-router-dom';

import { isCompanyTypeBusiness } from 'helpers/currentCompany';
import { isFn } from 'helpers/utility';

import { ContactRequiredHintTextProps } from '../../ContactRequiredToAllowSelectPaymentMethodHint.types';
import { CreateEditPaymentHintTextBusinessComponent } from '../CreateEditPaymentHintTextBusinessComponent';
import { CreateEditPaymentHintTextPersonalComponent } from '../CreateEditPaymentHintTextPersonalComponent';
import { FinalizePaymentHintTextComponent } from '../FinalizePaymentHintTextComponent';

/**
 * Hint text element specific for the create/edit payable flow
 */
const ContactRequiredHintText = ({
  closeCreatePartnershipModal,
  isInviting,
  onCloseExistingItemSidePanel,
  onOpenContactSidePanel,
  selectedCompany,
}: ContactRequiredHintTextProps): JSX.Element => {
  const history = useHistory();
  const isBusinessCompany = isCompanyTypeBusiness(selectedCompany?.partner?.companyType);

  const isInCreateEditPaymentOrCreatePartnership = isFn(onOpenContactSidePanel);

  if (isInCreateEditPaymentOrCreatePartnership && isBusinessCompany) {
    return (
      <CreateEditPaymentHintTextBusinessComponent
        closeCreatePartnershipModal={closeCreatePartnershipModal}
        history={history}
        isInviting={isInviting}
        onOpenContactSidePanel={onOpenContactSidePanel}
        selectedCompany={selectedCompany}
      />
    );
  }
  if (isInCreateEditPaymentOrCreatePartnership && !isBusinessCompany) {
    return (
      <CreateEditPaymentHintTextPersonalComponent
        isInviting={isInviting}
        onOpenContactSidePanel={onOpenContactSidePanel}
        selectedCompany={selectedCompany}
      />
    );
  }
  return (
    <FinalizePaymentHintTextComponent history={history} onCloseExistingItemSidePanel={onCloseExistingItemSidePanel} />
  );
};

export default ContactRequiredHintText;
