import React from 'react';
import { Field } from 'redux-form';

import { BoxV2, ButtonV2, IconNames, ReduxFormFieldRenderInput } from 'components';

import { ButtonSize } from 'constants/button';
import { formNamesSignup } from 'constants/forms';
import { formStyles, sizes } from 'constants/styles';

import { minCharacterLengthValidator, requiredValidator, subdomainValidator } from 'helpers/fieldValidation';
import { getBrandedWorkspacesDisplayDomain } from 'helpers/urls';

import { submit } from './helpers/submit';
import type { SelectWorkspaceProps } from './SelectWorkspace.types';

const minLengthValidator = minCharacterLengthValidator(4);

/**
 * Allows the user to specify a namespace for their company
 */
const SelectWorkspace: React.FC<SelectWorkspaceProps> = ({ handleSubmit, ...props }) => {
  const { isSubmitting } = props;

  return (
    <div className="branded--contents">
      <BoxV2
        subtitle="The name of your workspace will appear throughout Routable. It’s also the beginning of the URL your members will use to access Routable. You can change your workspace name at any time by contacting support."
        title="Create a workspace name"
      >
        <form
          className="form"
          id={formNamesSignup.SELECT_NAMESPACE}
          onSubmit={handleSubmit((values) => submit(values, props))}
        >
          <div className="form-control">
            <Field
              className={`${formStyles.field.xl.full} namespace`}
              component={ReduxFormFieldRenderInput}
              dataFullStory
              isDisabled={Boolean(isSubmitting)}
              name="company.namespace"
              placeholder="workspace-name"
              type="text"
              validate={[requiredValidator, minLengthValidator, subdomainValidator]}
            >
              <div className="inline-label inline-right workspace-label">
                <span>{`.${getBrandedWorkspacesDisplayDomain()}`}</span>
              </div>
            </Field>
          </div>

          <div className="form-control remove-margin-bottom">
            <ButtonV2
              htmlFor={formNamesSignup.SELECT_NAMESPACE}
              isLoading={Boolean(isSubmitting)}
              rightIconName={IconNames.ARROW_RIGHT}
              rightIconProps={{ style: { marginLeft: 'auto' } }}
              rightIconSize={sizes.iconSizes.LARGE}
              size={ButtonSize.LARGE}
              type="submit"
            >
              Confirm workspace name
            </ButtonV2>
          </div>
        </form>
      </BoxV2>
    </div>
  );
};

export default SelectWorkspace;
