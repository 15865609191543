import React from 'react';

import { Image } from 'components';

import { ACCOUNT_CREATION_HERO } from 'constants/images';

const SimpleSideImage: React.FC = () => (
  <Image alt="Create new account" className="signup-hero" src={ACCOUNT_CREATION_HERO} />
);

export default SimpleSideImage;
