import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { companySettingsSSOByNamespaceFetchRoutine } from 'actions/routines/sso';

import { isSSORedirectingSelector, isSSOFetchingSelector } from 'selectors/authSelectors';
import { authNamespaceHasSubmitSucceededSelector } from 'selectors/forms';
import { currentSSOSettingsSelector } from 'selectors/ssoSelectors';

import Login from './Login';

export const mapStateToProps = createStructuredSelector({
  authNamespaceHasSubmitSucceeded: authNamespaceHasSubmitSucceededSelector,
  currentSSOSettings: currentSSOSettingsSelector,
  isSSOFetching: isSSOFetchingSelector,
  isSSORedirecting: isSSORedirectingSelector,
});

export const mapDispatchToProps = {
  onFetchCompanySettingsSSOByNamespace: companySettingsSSOByNamespaceFetchRoutine.trigger,
};

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhance(Login);
