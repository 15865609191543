import { CountryCode } from 'interfaces/countries';

/**
 * Two-letter country codes that follow the ISO 3166-1 alpha-2.
 * We mostly use country codes from state now, but these need to be kept around for equality helpers
 */
export const StaticCountryCode = {
  CA: 'CA', // Canada
  CN: 'CN', // China
  IN: 'IN', // India
  MX: 'MX', // Mexico
  US: 'US', // United States of America
} as const;

/**
 * United States and its territories are all treated as domestic
 */
export const DomesticCountryCodes: Array<CountryCode> = [
  'AS', // American Samoa
  'GU', // Guam
  'MP', // Northern Mariana Islands
  'PR', // Puerto Rico
  'VI', // Virgin Islands
  'UM', // United States Minor Outlying Islands
  'US', // United States of America
];
