import { CheckmarkFilled } from '@carbon/icons-react';
import React from 'react';

import { CornerDialogCloseOnly } from 'components';

import { CornerDialogPartnerAlreadyAcceptedProps } from './CornerDialogPartnerAlreadyAccepted.types';

/**
 * CornerDialog with close button show let users know that the partnership invite they received has already been
 * submitted by another contact.
 */
const CornerDialogPartnerAlreadyAccepted = ({
  isShown,
  onClose,
  partnershipType,
}: CornerDialogPartnerAlreadyAcceptedProps) => (
  <CornerDialogCloseOnly
    isShown={isShown}
    onClose={onClose}
    title={
      <div className="inline-flex items-center">
        <CheckmarkFilled className="fill-green-40 mr-3" size={16} />
        <span className="font-medium text-grey-70 text-base">No further action is needed at this time.</span>
      </div>
    }
  >
    {`Your ${partnershipType} registration was already confirmed and completed.`}
  </CornerDialogCloseOnly>
);

export default CornerDialogPartnerAlreadyAccepted;
