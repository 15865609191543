import React from 'react';

import type { LineItemDiscrepancyStatusProps } from './LineItemDiscrepancyStatus.types';
import { LineItemDiscrepancyStatusForCreateEditItem } from './LineItemDiscrepancyStatusForCreateEditItem';
import { LineItemDiscrepancyStatusForItemDetails } from './LineItemDiscrepancyStatusForItemDetails';

export const LineItemDiscrepancyStatus = ({ isOnDetailsPage, ...rest }: LineItemDiscrepancyStatusProps) => {
  return isOnDetailsPage ? (
    <LineItemDiscrepancyStatusForItemDetails {...rest} />
  ) : (
    <LineItemDiscrepancyStatusForCreateEditItem {...rest} />
  );
};
