import classNames from 'classnames';
import React from 'react';

import { CheckListProps } from './CheckList.types';
import { CheckListInnerList, CheckListItem } from './components';

import './CheckList.scss';

/**
 * Renders an unordered list with items that display as complete or incomplete.
 *
 * Note:
 * The CheckListInnerList component, which renders a <ul />, will be rendered only
 * at top-level if no title is provided. If a title *is* provided, there will
 * an additional CheckListInnerList containing the provided children, nested
 * inside of the top-level list.
 *
 * Explanation:
 * HTML spec says we should render a <ul /> list's title as the first list item,
 * and nest original list items inside that <li />, in a new <ul />.
 */
const CheckList: React.FC<CheckListProps> = ({ children, className, title }) => {
  const itemList = <CheckListInnerList className="check-list--nested-list">{children}</CheckListInnerList>;

  return (
    <div
      className={classNames({
        'check-list': true,
        [className]: !!className,
      })}
    >
      {!!title && (
        <CheckListInnerList>
          <CheckListItem className="check-list--title" isTitle>
            {title}

            {itemList}
          </CheckListItem>
        </CheckListInnerList>
      )}

      {!title && itemList}
    </div>
  );
};

export default CheckList;
