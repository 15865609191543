import React from 'react';

import { Col } from 'components/layout';
import { Text, WhiteSpace } from 'components/text';

import { typography } from 'constants/styles';

interface ContentBlockSimpleTitleProps {
  title?: string;
  titleLight?: string;
}

/**
 * Conditionally renders the `title` and/or `titleLight` props passed into `ContentBlockSimple` component
 */
const ContentBlockSimpleTitle = ({ title, titleLight }: ContentBlockSimpleTitleProps): React.ReactElement => {
  if (!title) {
    return null;
  }

  return (
    <Col>
      <Text.Regular
        color={typography.TextColor.BLACK}
        lineHeight={typography.TextLineHeight.EXTRA_EXTRA_EXTRA_LARGE}
        size={typography.TextSize.LEVEL_600}
      >
        {title}
      </Text.Regular>
      {titleLight && (
        <>
          <WhiteSpace />
          <Text.Regular
            color={typography.TextColor.GREY_DARK}
            lineHeight={typography.TextLineHeight.EXTRA_EXTRA_EXTRA_LARGE}
            size={typography.TextSize.LEVEL_600}
          >
            {titleLight}
          </Text.Regular>
        </>
      )}
    </Col>
  );
};

export default ContentBlockSimpleTitle;
