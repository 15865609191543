import * as routines from 'actions/routines/funding';

import { countryPaymentOption } from 'constants/relationships';

import { reduceKeys } from 'helpers/utility';

import { Action } from 'interfaces/actions';
import type {
  CountryWithPaymentOptions,
  SupportedCountryItem,
  SupportedCountryItemOptions,
  SupportedCountryResultRecord,
} from 'interfaces/fundingSupportedCountries';
import { Reducer } from 'interfaces/reducers';

interface State {
  [key: string]: CountryWithPaymentOptions;
}

interface SuccessAction extends Action {
  payload: SupportedCountryResultRecord;
}

const getCountryPaymentOptionsByCode = (supportedCountries: SupportedCountryItemOptions) => {
  if (!supportedCountries) {
    return {};
  }

  const result = reduceKeys(
    supportedCountries,
    (obj: SupportedCountryItem, countryCodeId: string) => {
      const { attributes } = supportedCountries[countryCodeId];

      return {
        ...obj,
        [attributes.countryCode]: {
          ...attributes,
          id: countryCodeId,
        },
      };
    },
    {},
  );

  // [srajchevski] map US config for empty-string key ('')
  // because of legacy Canadian vendors that do not have countryCodePartner set
  return {
    '': result.US,
    ...result,
  };
};

export const initialState = {};

const byCountryCodeReducer: Reducer<State, SuccessAction> = (state = initialState, action): State => {
  switch (action.type) {
    case routines.fetchSupportedCountriesRoutine.SUCCESS:
      return getCountryPaymentOptionsByCode(action.payload[countryPaymentOption]);

    default:
      return state;
  }
};

export default byCountryCodeReducer;
