import React from 'react';

import { Text, ButtonV2 } from 'components';

import { ButtonSize } from 'constants/button';
import { TextColor } from 'constants/styles/typography';
import { Intent } from 'constants/ui';

import type { Payment } from 'interfaces/redux';
import type { TableCellProps } from 'interfaces/table.types';

import { externalPaymentsClickContext } from '../../context';

import './Invoices.scss';

const Invoices = ({ value, row: { original: payment } }: TableCellProps<Payment>): JSX.Element => {
  const onClick = React.useContext(externalPaymentsClickContext);
  const showViewButton = payment.type === 'Payment';

  return (
    <div className="invoices">
      <Text.Regular className="circle" color={TextColor.BLUE_BOLD}>
        {value || '--'}
      </Text.Regular>
      {showViewButton && (
        <ButtonV2
          intent={Intent.NEUTRAL}
          onClick={() => onClick({ type: 'view-details', payload: payment })}
          size={ButtonSize.SMALL}
          type="button"
        >
          View
        </ButtonV2>
      )}
    </div>
  );
};

export default Invoices;
