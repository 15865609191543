import React from 'react';

import { hasConversion as hasConversionHelper, getConversionCurrency } from 'helpers/internationalItems';

import { CurrencyConversionRateTooltip } from '../sharedComponents';

import type { InternationalItemConversionRateTooltipProps } from './InternationalItemConversionRateTooltip.types';

/**
 * Component rendering tooltip with information of the international item's conversion rates
 */
const InternationalItemConversionRateTooltip: React.FC<InternationalItemConversionRateTooltipProps> = ({
  conversionAmountKey,
  item,
  partnership,
}) => {
  /**
   * Determine if item has a conversion and which is the estimated side of the conversion
   */
  const hasConversion = hasConversionHelper(item, partnership);
  const conversionCurrency = getConversionCurrency(item, partnership);

  return (
    <CurrencyConversionRateTooltip
      conversionCurrency={conversionCurrency}
      country={partnership.countryCodePartner}
      currencyCodeReceiver={item.currencyCodeReceiver}
      exchangeRate={item.exchangeRate}
      hasConversion={hasConversion}
      isExchangeRateLocked={item.isExchangeRateLocked}
      plainAmount={item[conversionAmountKey]}
    />
  );
};

export default InternationalItemConversionRateTooltip;
