import type { RoutableObject } from '@routable/types';
import _groupBy from 'lodash/groupBy';

import { LineItemStyles } from 'constants/lineItems';

import {
  LINE_ITEMS_SUBSECTIONS,
  LINE_ITEMS_BLOCKS,
} from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/constants';

/**
 * Fill target bill line item, with properties from PO line item, skipping fields which are already defined
 */
export const fillLineItemWithPropertiesFromPoLineItem = ({
  targetLineItem,
  poLineItem,
  isExtended,
  isOverride,
}: {
  targetLineItem: RoutableObject;
  poLineItem: RoutableObject;
  isExtended?: boolean;
  isOverride?: boolean;
}) => {
  if (isOverride) {
    return {
      ...targetLineItem,
      ...poLineItem,
    };
  }

  let result = { ...targetLineItem };

  Object.keys(poLineItem).forEach((key) => {
    if (key === 'extended') {
      result = fillLineItemWithPropertiesFromPoLineItem({
        targetLineItem: result,
        poLineItem: poLineItem.extended,
        isExtended: true,
      });
      return;
    }

    const keyPath = isExtended ? `extended_${key}` : key;

    if (!targetLineItem[keyPath]) {
      result[keyPath] = poLineItem[key];
    }
  });

  return result;
};

/**
 * Formats PO line items to bill line items
 */
export const formatLineItemsFromPurchaseOrder = ({ purchaseOrderLineItems, defaultLineItems }) => {
  const groupedByStyle = _groupBy(
    purchaseOrderLineItems?.map((poLineItem) => {
      const subsection = LINE_ITEMS_SUBSECTIONS[poLineItem.style.toUpperCase()];
      const block = LINE_ITEMS_BLOCKS[poLineItem.style.toUpperCase()];

      return fillLineItemWithPropertiesFromPoLineItem({
        targetLineItem: defaultLineItems?.[block]?.[subsection]?.[0] || {},
        poLineItem,
        isOverride: true,
      });
    }),
    'style',
  );

  return {
    [LINE_ITEMS_BLOCKS.ACCOUNT]: {
      [LINE_ITEMS_SUBSECTIONS.ACCOUNT]:
        groupedByStyle?.[LineItemStyles.ACCOUNT] ||
        defaultLineItems?.[LINE_ITEMS_BLOCKS.ACCOUNT]?.[LINE_ITEMS_SUBSECTIONS.ACCOUNT],
    },
    [LINE_ITEMS_BLOCKS.ITEM]: {
      [LINE_ITEMS_SUBSECTIONS.ITEM]:
        groupedByStyle?.[LineItemStyles.ITEM] ||
        defaultLineItems?.[LINE_ITEMS_BLOCKS.ITEM]?.[LINE_ITEMS_SUBSECTIONS.ITEM],
    },
  };
};
