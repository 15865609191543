import React from 'react';

import ExternalBlock from '../ExternalBlock';

import { ExternalContentProps } from './ExternalContent.types';

/**
 * ExternalContent returns blocks of columns along with stepper.
 */
const ExternalContent = ({ currentStep = {}, ...rest }: ExternalContentProps) => (
  <>
    {currentStep.blocks?.map((block, idx) => (
      <ExternalBlock key={`block-${idx + 1}`} {...block} {...rest} />
    ))}
  </>
);

export default ExternalContent;
