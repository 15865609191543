import React from 'react';

import { NoRegisteredAddressesAddedHint } from 'components';

import CompanyAddressDataCard from '../CompanyAddressDataCard';

import { CompanyAddressSectionProps } from './CompanyAddressSection.types';

import './CompanyAddressSection.scss';

/**
 * Component rendering Address Section on the Company About page
 * @see {CompanyAbout}
 */
const CompanyAddressSection = ({
  address,
  disableManageActions,
  disabledTooltipProps,
  isEdittingDisabled,
  isSubmitting,
  onAddRegisteredAddress,
  onEditRegisteredAddress,
  isInternational = false,
}: CompanyAddressSectionProps): JSX.Element => {
  if (address) {
    return (
      <div className="company-address-section">
        <CompanyAddressDataCard
          address={address}
          disabledTooltipProps={disabledTooltipProps}
          disableManageActions={disableManageActions}
          isEdittingDisabled={isEdittingDisabled}
          isSubmitting={isSubmitting}
          onEdit={onEditRegisteredAddress}
        />
      </div>
    );
  }

  return (
    <div className="company-address-section">
      <NoRegisteredAddressesAddedHint isInternational={isInternational} onClick={onAddRegisteredAddress} />
    </div>
  );
};

export default CompanyAddressSection;
