import React from 'react';

import { LinkNewPage, SwalWrapperV2 } from 'components';

import { ButtonText } from 'constants/button';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { Intent } from 'constants/ui';

import helpDocs from 'helpers/helpDocs';

import { EnableInternationalPaymentsSwalProps } from './EnableInternationalPaymentsSwal.types';

/**
 * Confirm swal when user tries to enable international payments
 */
const EnableInternationalPaymentsSwal = ({ onConfirm }: EnableInternationalPaymentsSwalProps): JSX.Element => (
  <SwalWrapperV2
    intent={Intent.DANGER}
    leftButtonProps={{
      children: ButtonText.CANCEL,
      className: 'hover--danger',
    }}
    rightButtonProps={{
      children: ButtonText.CONFIRM,
      intent: Intent.PRIMARY,
      onClick: onConfirm,
    }}
    title="Are you sure you want to enable international payments?"
  >
    {`You will be able to pay international and domestic vendors. Learn more about `}
    <LinkNewPage className="primary" href={helpDocs.SENDING_XB_PAYMENTS_TO_INTERNATIONAL_VENDORS}>
      {`${PLATFORM_DISPLAY_SHORT_NAME}'s International Payments offering ↗`}
    </LinkNewPage>
  </SwalWrapperV2>
);

export default EnableInternationalPaymentsSwal;
