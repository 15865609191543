import { BaseMessage } from 'milton/logging';

import { Context } from './addContext';

export enum LogLevel {
  TRACE = 'TRACE',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
  FATAL = 'FATAL',
}

export interface NormalMessage extends BaseMessage, Context {
  level: Exclude<ValueOf<LogLevel>, LogLevel.ERROR | LogLevel.FATAL>;
  message?: string;
  errorCode?: string;
}

export interface ErrorMessage extends BaseMessage, Context {
  level: ValueOf<LogLevel.ERROR> | ValueOf<LogLevel.FATAL>;
  message?: string;
  error: unknown;
}

export type RoutableLogMessage = ErrorMessage | NormalMessage;
