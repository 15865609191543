import queryString, { ParsedQuery, ParsedUrl } from 'query-string';

import { allKeys, isObject } from 'helpers/utility';

import type { GetQueryStringOptions, ParsedQueryValue } from './queryParams.types';

/**
 * Helper to parse query parameters from location.search
 */
export const getQueryParam = (paramName: string, location: Location = window.location): ParsedQueryValue => {
  const queryParams = queryString.parse(location.search);
  return queryParams[paramName];
};

/**
 * Parse the query parameters from a URL string
 */
export const getQueryParamsFromUrl = (query: string = window.location.search): ParsedQuery => queryString.parse(query);

/**
 * Given a URL, return a mapping of its constituent parts, the url and the query
 *
 * @param {string} url
 * @returns {ParsedUrl}
 */
export const parseUrl = (url: string): ParsedUrl => queryString.parseUrl(url);

/**
 * Parse the query parameters from a URL string, guaranteeing that either a string or undefined gets returned.
 *
 * Note: Many of our functions throughout the application that use `getQueryParam` can't handle the fact that
 * `getQueryParam` doesn't always return a string. For example, sometimes it returns an array. The function signature
 * for `getQueryParam` has been updated with types from the query-string package, but we needed another way to get QS
 * params and guarantee a string return (or undefined). That's this new function, getQueryParamValueFromUrl.
 *
 * @param {string} queryKey
 * @param {string} urlString?
 */
export const getQueryParamValueFromUrl = (
  queryKey: string,
  urlString: string = window.location.search,
): string | undefined => {
  const queryParams = getQueryParamsFromUrl(urlString);
  const queryValue = queryParams[queryKey];

  if (typeof queryValue === 'string' || typeof queryValue === 'undefined') {
    return queryValue;
  }

  throw new Error('This function only works to extract querystrings which are string, not arrays or null');
};

/**
 * Serializes params object into query string
 */

export const getQueryString = (params: unknown, options: GetQueryStringOptions = {}): string => {
  const result = [];

  allKeys(params).forEach((key) => {
    if (!params[key]) {
      return;
    }

    const { prefix } = options;

    const value = params[key];
    const formattedKey = prefix ? `${prefix}[${key}]` : key;
    const encodedValue = isObject(value)
      ? getQueryString(value, { prefix: formattedKey })
      : queryString.stringify({ [formattedKey]: value });

    result.push(encodedValue);
  });

  return result.join('&');
};
