export const deactivateConfirmation = {
  getTitle: (firstName, lastName): string => `Deactivate ${firstName} ${lastName}?`,
  text: 'Deactivating this account will immediately remove their access to Routable.',
  button: 'Deactivate',
};

export const teamMembers = {
  all: {
    title: 'Team members',
    description:
      "Team members will have access to all the information except for other representatives' personal data.",
  },
  invites: {
    title: 'Invites',
    description: 'Invite team members who can help you fill out the information.',
  },
};
