import React from 'react';

import ExternalColumn from 'complexComponents/ExternalColumn';

import { FlexRow } from 'components';

import { hasZeroLength } from 'helpers/utility';

import { ExternalBlockProps } from './ExternalBlock.types';

import './ExternalBlock.scss';

/**
 * ExternalBlock returns a list of columns with applied layoutType.
 * @param props - Component props
 */
const ExternalBlock = ({ columns = [], layoutType, ...rest }: ExternalBlockProps): JSX.Element => {
  if (hasZeroLength(columns)) {
    return null;
  }

  return (
    <FlexRow className={`external-block ${layoutType}`}>
      {columns.map((col, index) => (
        <ExternalColumn className={`col-${index + 1}`} key={`col-${index + 1}`} {...col} {...rest} />
      ))}
    </FlexRow>
  );
};

export default ExternalBlock;
