import React from 'react';

import DynamicTextBrowser from '../components/DynamicTextBrowser';
import SimpleImage from '../components/SimpleImage';
import { auxiliaryComponents } from '../constants/auxiliaryComponents';

export const componentSwitcher = (component: string): JSX.Element => {
  switch (component) {
    case auxiliaryComponents.BROWSER:
      return <DynamicTextBrowser />;
    case auxiliaryComponents.IMAGE:
      return <SimpleImage />;
    default:
      return <SimpleImage />;
  }
};
