export enum ButtonAppearance {
  DEFAULT = 'default',
  BORDERLESS = 'borderless',
}

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonType {
  CANCEL = 'cancel',
  CONFIRM = 'confirm',
  PRIMARY = 'primary',
}

export enum ButtonText {
  CANCEL = 'Cancel',
  CLOSE = 'Close',
  CONFIRM = 'Confirm',
  CONTACT_SUPPORT = 'Contact support',
}
