import React from 'react';

import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';

import { typography } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';

import type { MatchOnlyDomesticVendorsWithCustomersTooltipProps } from './MatchOnlyDomesticVendorsWithCustomersTooltip.types';

/**
 * Tooltip notifying the user that matching international vendors with customers may cause inconsistencies,
 * and is therefore disallowed
 */
const MatchOnlyDomesticVendorsWithCustomersTooltip = ({
  children,
  isShown,
  ledgerName,
}: MatchOnlyDomesticVendorsWithCustomersTooltipProps): React.ReactElement => (
  <TooltipMUIConditionalWrapper
    tooltipProps={
      isShown
        ? {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            title: (
              <Text.Regular
                color={typography.TextColor.GREY_X_DARK}
                lineHeight={typography.TextLineHeight.MEDIUM}
                size={typography.TextSize.LEVEL_100}
              >
                Matching international vendors with customers can cause inconsistencies with your
                {` ${ledgerName} `}
                data, so we have limited this action to domestic vendors only.
              </Text.Regular>
            ),
          }
        : {}
    }
  >
    {children}
  </TooltipMUIConditionalWrapper>
);

export default MatchOnlyDomesticVendorsWithCustomersTooltip;
