import React from 'react';

import BaseHint from 'components/hintTypes/BaseHint';
import { textHelpers } from 'components/hintTypes/helpers';
import { WhiteSpace } from 'components/text';

import { Intent } from 'constants/ui';

import { getVendorOrCustomerTitleFromItemKind } from 'helpers/partnerships';

import { CannotReplyNoReachableContactsHintProps } from './CannotReplyNoReachableContactsHint.types';

/**
 * Hint describes that the RCTM may not reply to a thread because there are no reachable contacts. "Not Reachable" means either
 * that all of the ItemMembers have been removed from the Item or the remaining ItemMembers don't have an email.
 */
const CannotReplyNoReachableContactsHint: React.FC<CannotReplyNoReachableContactsHintProps> = ({ item }) => {
  const vendorOrCustomer = getVendorOrCustomerTitleFromItemKind(item.kind);
  const hintText = textHelpers.getCannotReplyNoReachableContactsHintText(vendorOrCustomer);

  return (
    <BaseHint
      intent={Intent.WARNING}
      title={
        <>
          {hintText}
          <WhiteSpace />
          {/* TODO: Wait for https://warrenpay.atlassian.net/browse/DEV-8122 to implement the desired behavior here */}
          {/* <Link className="bold primary" to={makeSimilarUrl(ITEM_DETAILS_FILTERS.PARTNER)}>
            Add an email
          </Link> */}
        </>
      }
      titleProps={{ marginBottom: false }}
    />
  );
};

export default CannotReplyNoReachableContactsHint;
