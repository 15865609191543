import { z } from 'zod';

export const DiscrepancyDetailsModel = z.object({
  bill_value: z.string().or(z.number()),
  discrepancy: z.object({ value: z.string(), percentage: z.string() }),
  purchase_order_value: z.string().or(z.number()),
});

export const DiscrepancyModel = z.object({
  amount: DiscrepancyDetailsModel.nullish(),
  bill_line_item_id: z.string().uuid(),
  purchase_order_line_item_id: z.string().nullish(),
  rate: DiscrepancyDetailsModel.nullish(),
  type: z.enum(['expense_match', 'missing_po_item', 'value_match']),
  quantity: DiscrepancyDetailsModel.nullish(),
});

export const DiscrepancyDataModel = z.array(DiscrepancyModel);

export const DiscrepanciesApiResponseModel = z.object({
  data: DiscrepancyDataModel,
});

export type Discrepancy = z.infer<typeof DiscrepancyModel>;
export type DiscrepancyData = z.infer<typeof DiscrepancyDataModel>;
export type DiscrepanciesApiResponse = z.infer<typeof DiscrepanciesApiResponseModel>;
