import React from 'react';

import { auxiliaryComponents } from '../components/SignUpVisualAid/constants/auxiliaryComponents';
import { formModules } from '../constants/formModules';
import ModuleWrapper from '../ModuleWrapper/ModuleWrapper';

export const CreateAccountTemplate: React.FC = () => (
  <ModuleWrapper formModule={formModules.CREATE_ACCOUNT} helperModule={auxiliaryComponents.IMAGE} />
);

export const CreateNamespaceTemplate: React.FC = () => (
  <ModuleWrapper formModule={formModules.SELECT_NAMESPACE} helperModule={auxiliaryComponents.BROWSER} />
);

export const VerifyEmailTemplate: React.FC = () => (
  <ModuleWrapper formModule={formModules.VERIFY_EMAIL} helperModule={auxiliaryComponents.IMAGE} />
);
