import React from 'react';

import { BaseHint } from 'components/hintTypes';

import { Intent } from 'constants/ui';

import EnterExistingPasswordErrorContent from '../EnterExistingPasswordErrorContent';

/**
 * Accept Membership Invite error hint for when user
 * is part of two companies and is not entering the existing password. */
const EnterExistingPasswordError = ({ errors }: { errors: unknown[] }): JSX.Element => {
  const isSingleError = errors.length === 1;

  // When multiple error messages are returned from the backened,
  // they will be merged into one hint and seperated by a bullet point.
  //
  // To avoid double wrapping of hint component,
  // we only wrap with a hint when it is a single error message.
  if (isSingleError) {
    return (
      <BaseHint
        className="padding--xm--important"
        intent={Intent.DANGER}
        text={<EnterExistingPasswordErrorContent />}
      />
    );
  }

  return <EnterExistingPasswordErrorContent />;
};

export default EnterExistingPasswordError;
