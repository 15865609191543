import { cva, VariantProps } from 'class-variance-authority';

export const helpStyles = cva(['cursor-pointer'], {
  variants: {
    variant: {
      'floating-icon': [
        'fixed',
        'right-[20px]',
        'bottom-[20px]',
        'z-[140]',
        'w-[36px]',
        'h-[36px]',
        'rounded-full',
        'bg-grey-70',
        'flex',
        'items-center',
        'justify-center',
        'shadow-md',
      ],
      'sidebar-item': ['w-full'],
      'actionbar-item': [''],
    },
  },
  defaultVariants: {
    variant: 'floating-icon',
  },
});

export type HelpVariantProps = VariantProps<typeof helpStyles>;

export const helpPopoverStyles = cva([], {
  variants: {
    variant: {
      'floating-icon': [],
      'sidebar-item': ['!left-[7px]'],
      'actionbar-item': [],
    },
  },
  defaultVariants: {
    variant: 'floating-icon',
  },
});

export const helpPopoverMenuStyles = cva([], {
  variants: {
    variant: {
      'floating-icon': ['min-w-[200px]'],
      'sidebar-item': ['w-[216px]'],
      'actionbar-item': ['min-w-[200px]'],
    },
  },
  defaultVariants: {
    variant: 'floating-icon',
  },
});
