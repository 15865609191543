import React from 'react';
import { useMedia } from 'react-use';

import DropdownMenuButton from 'complexComponents/DropdownMenuButton';

import { Intent } from 'constants/ui';

import { isEqual } from 'helpers/utility';

import ConditionalRestrictedPermissionTooltip from 'modules/auth/ConditionalRestrictedPermissionTooltip';

import { TableToolbarActionsMenuOption } from './components';
import { TableToolbarActionsMenuProps } from './TableToolbarActionsMenu.types';

/**
 * Renders a dropdown menu button whose options are bulk actions that can be taken.
 */
const TableToolbarActionsMenu: React.FC<TableToolbarActionsMenuProps> = ({ isDisabled, options, ...rest }) => {
  const queryMatches = useMedia('(min-width: 768px)');
  const isAnyOptionActionable = options.find((option) => Boolean(option.actionableCount));

  if (isEqual(isDisabled, false)) {
    return (
      <ConditionalRestrictedPermissionTooltip useTooltip={isDisabled}>
        <DropdownMenuButton
          intent={Intent.NEUTRAL}
          menuAlign="right"
          options={options}
          {...rest}
          isDisabled={isDisabled}
          upwards={!queryMatches}
        />
      </ConditionalRestrictedPermissionTooltip>
    );
  }

  return (
    <ConditionalRestrictedPermissionTooltip useTooltip={isDisabled}>
      <DropdownMenuButton
        intent={Intent.NEUTRAL}
        menuAlign="right"
        options={options}
        {...rest}
        isDisabled={!isAnyOptionActionable || isDisabled}
        upwards={!queryMatches}
      />
    </ConditionalRestrictedPermissionTooltip>
  );
};

TableToolbarActionsMenu.defaultProps = {
  isDisabled: undefined,
  OptionComponent: TableToolbarActionsMenuOption,
};

export default TableToolbarActionsMenu;
