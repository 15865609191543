/**
 * @fileOverview Defines the API calls related to feature flags.
 * @module sagas/featureFlags/api
 */

import { AxiosRequestHeaders } from 'axios';

import { Options } from 'actions/routines/createApiRoutine';

import { parseFeatureFlags } from 'data/parse';

import { FetchService } from 'services';
import { featureFlagsEndpoint } from 'services/api/configEndpoints';

/**
 * Fetch active/enabled feature flags.
 */
export const getFetchFeatureFlagsApiConfig = (): Options => {
  const responseTransformers = [
    parseFeatureFlags.transform.attachIdToFlagResponseObjects,
    ...FetchService.getTransformers({}),
    parseFeatureFlags.transform.flagsResponseToEnabledKeys,
  ];

  return {
    endpoint: featureFlagsEndpoint,
    method: 'GET',
    requireAuth: true,
    transformers: responseTransformers,
    headers: {} as AxiosRequestHeaders,
  };
};
