import React from 'react';

import { ItemWithoutContactsHint } from 'components/hintTypes';
import {
  getNextPartnerMembersForGroupedContactSelects,
  shouldDisplayOptionInContactSelectMenu,
} from 'components/selectTypes/helpers';
import { useGroupedContactSelectsData } from 'components/selectTypes/hooks';
import { partnershipMemberSelectContactText, partnershipMemberSelectReadOnlyText } from 'components/selectTypes/text';

import { createItemFormFields } from 'constants/formFields';
import { PartnershipMemberAccess } from 'constants/partnershipMember';

import { PartnershipMemberProps } from 'data/resources/member';

import { onBlurForGroupedContactSelects } from 'helpers/selects';
import { capitalize } from 'helpers/stringHelpers';
import { getClassNames } from 'helpers/ui';
import { hasLength } from 'helpers/utility';

import { SelectOption } from '../components';
// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import TagMultiSelect from '../TagMultiSelect';

import type { GroupedContactSelectsProps } from './GroupedContactSelects.types';

/**
 * Renders a select for each type of partner member you can set as a contact, e.g. on an item.
 *
 * Accepts all selected members as a value (in input.value), of all access types, and manages
 * feeding the members for each access level (e.g. read-only) into the appropriate select.
 *
 * Also manages updating the access property on each member as they are added to, or removed
 * from, any of the select fields.
 */
const GroupedContactSelects = (props: GroupedContactSelectsProps): JSX.Element => {
  const {
    accessProperty = PartnershipMemberProps.defaultGeneral,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    className,
    components = {},
    errors,
    input,
    isVendorContactOptional,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    members,
    onCreate,
    valueKey = 'data',
    ...rest
  } = props;

  const { name, onChange } = input;

  const { actionMembers, options, readOnlyMembers } = useGroupedContactSelectsData(props);

  const handleSelectionsChanged = React.useCallback(
    (params) => {
      const { action } = params;
      const nextValue = getNextPartnerMembersForGroupedContactSelects(accessProperty, params);
      onChange(nextValue);

      // Deselect field when clearing an input, this helps fix odd behavior in the UI
      // solution source: https://github.com/JedWatson/react-select/issues/3104#issuecomment-748429970
      if (action === 'remove-value') {
        requestAnimationFrame(() => {
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
        });
      }
    },
    [accessProperty, onChange],
  );

  const selectConfigs = [
    {
      access: PartnershipMemberAccess.ACTIONABLE,
      secondaryMembers: readOnlyMembers,
      targetMembers: actionMembers,
      text: partnershipMemberSelectContactText,
    },
    {
      access: PartnershipMemberAccess.READ_ONLY,
      secondaryMembers: actionMembers,
      targetMembers: readOnlyMembers,
      text: partnershipMemberSelectReadOnlyText,
    },
  ];

  const isOnCreateItemForm = name === createItemFormFields.ITEM_MEMBERS;

  const selectedMembers = actionMembers.concat(readOnlyMembers);
  const hasValue = hasLength(selectedMembers);
  const isRequired = !isVendorContactOptional && !hasValue;
  const showItemWithoutContactsHint = !hasValue && isVendorContactOptional && isOnCreateItemForm; // only show hint on the createItem page

  return (
    <div
      className={getClassNames(props, {
        'grouped-selects': true,
        'grouped-selects--error': !!errors,
      })}
    >
      {selectConfigs.map((config, index) => {
        const value = hasLength(config.targetMembers) ? config.targetMembers : null;
        const isLast = index === selectConfigs.length - 1;

        return (
          <TagMultiSelect
            {...rest}
            components={{
              ...components,
              Option: SelectOption,
            }}
            dataFullStory
            filterOption={shouldDisplayOptionInContactSelectMenu}
            hideErrors={!isLast}
            idPrefix={config.access}
            input={{
              ...input,
              name: name.concat(capitalize(config.access)),
              // override onBlur so that it triggers validation without losing value
              onBlur: () => onBlurForGroupedContactSelects(input, config),
              onChange: (selectValue, { action }) => {
                handleSelectionsChanged({
                  access: config.access,
                  action,
                  currentSecondaryMembers: config.secondaryMembers,
                  currentTargetMembers: config.targetMembers,
                  selectValue,
                });
              },
              value,
            }}
            isRequired={isRequired}
            key={config.access}
            label={config.text}
            onCreate={(inpt) => onCreate(inpt, config.access)}
            options={options}
            placeholder={config.text}
            valueKey={valueKey}
          />
        );
      })}

      {showItemWithoutContactsHint && (
        <div className="margin-top--m">
          <ItemWithoutContactsHint />
        </div>
      )}
    </div>
  );
};

export default GroupedContactSelects;
