import { fetchSingleItemSuccess, fetchSingleItemRequest } from 'actions/item';
import { fetchItemsRoutine } from 'actions/routines/item';

import { RoutableApiResponse } from 'interfaces/routableApiResponse';

import { routableApi, RoutableTags } from './routableApi';
import { ItemQueryParameters } from './routableApi.types';

const extendedItemApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    /** Get data based on the specific id */
    getItemById: builder.query<RoutableApiResponse, { id: string }>({
      query: ({ id }) => ({ url: `/items/${id}/` }),
      providesTags: [RoutableTags.Items],
      async onCacheEntryAdded(queryArgs, api) {
        // this code used to loop back and trigger the existing code
        api.dispatch(fetchSingleItemRequest(queryArgs?.id));
        const payload = await api.cacheDataLoaded;
        api.dispatch(fetchSingleItemSuccess(payload.data));
      },
    }),
    getItemList: builder.query<RoutableApiResponse, { parnership?: string; params: ItemQueryParameters }>({
      query: ({ params }) => ({ url: `/items/`, params }),
      providesTags: [RoutableTags.Items],
      async onCacheEntryAdded(_response, api) {
        // this code used to loop back and trigger the existing code
        const payload = await api.cacheDataLoaded;
        api.dispatch(fetchItemsRoutine.success(payload.data));
      },
    }),
  }),
});

export const { useGetItemByIdQuery, useGetItemListQuery, useLazyGetItemByIdQuery, useLazyGetItemListQuery } =
  extendedItemApi;

export type UseLazyGetItemByIdQuery = typeof useLazyGetItemByIdQuery;
export type UseLazyGetItemListQuery = typeof useLazyGetItemListQuery;
