import React from 'react';

export const Promo = ({ children }): JSX.Element => <div className="text-center">{children}</div>;

Promo.Title = ({ children }): JSX.Element => <span className="text-lg leading-6 text-black block">{children}</span>;

Promo.Description = ({ children }): JSX.Element => (
  <span className="leading-7 text-sm text-grey-60 block">{children}</span>
);

Promo.Actions = ({ children }): JSX.Element => <div className="my-6">{children}</div>;

Promo.Image = (props: JSX.IntrinsicElements['img']): JSX.Element => <img alt="" className="mx-auto mb-5" {...props} />;
