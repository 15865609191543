import {
  webhookSettingsFetchRoutine,
  webhookSettingsSubmitRoutine,
  webhookSettingsUpdateRoutine,
} from 'actions/routines/webhooks';

import { tagLinkRelationships as relationships } from 'constants/relationships';

import { createByIdReducer } from 'store/redux';

const byIdReducer = createByIdReducer({
  key: 'webhookSettings',
  types: [
    webhookSettingsFetchRoutine.SUCCESS,
    webhookSettingsSubmitRoutine.SUCCESS,
    webhookSettingsUpdateRoutine.SUCCESS,
  ],
  relationships,
  replaceState: true,
});

export default byIdReducer;
