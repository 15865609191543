import React from 'react';
import ReactModal from 'react-modal';

import ModalBody from 'components/modal/components/body/ModalBody';
import ModalHeader from 'components/modal/components/header/ModalHeader';

import { colors } from 'constants/styles';
import { ZIndexLayers } from 'constants/ui';

import { getZIndex } from 'helpers/zIndex';

import type { ModalProps } from './Modal.types';
import './Modal.scss';

// Set the app element that React Modal expects for tests
ReactModal.setAppElement('body');

/**
 * Modal
 */
const Modal = ({
  body,
  hasInlineFooter,
  hideHeader,
  modalHeader = '',
  modalState,
  onCloseModal,
  overrideStyle = {},
  testId,
  ...rest
}: ModalProps): JSX.Element => (
  <ReactModal
    contentLabel="Modal"
    isOpen={modalState}
    onRequestClose={onCloseModal}
    style={{
      ...overrideStyle,
      content: {
        backgroundColor: colors.colorBlueLightHex,
        ...overrideStyle.content,
      },
      overlay: {
        backgroundColor: 'rgba(76, 84, 103, 0.3)',
        zIndex: getZIndex(ZIndexLayers.MODAL.OVERLAY),
        ...overrideStyle.overlay,
      },
    }}
    {...rest}
    {...(testId ? { data: { testid: testId } } : {})}
  >
    {!hideHeader && (
      <ModalHeader>
        <h3>{modalHeader}</h3>
        <a className="modal-header--close remove-hover-underline" onClick={onCloseModal} role="presentation">
          <span className="icon-ic-x-out" />
        </a>
      </ModalHeader>
    )}

    <ModalBody hasInlineFooter={hasInlineFooter}>{body}</ModalBody>
  </ReactModal>
);

export default Modal;
