import React from 'react';

import { BoxV2, ButtonV2, FormControl, FormFieldLabel, IconNames, InputField, SelectFieldV2 } from 'components';

import { ButtonSize } from 'constants/button';
import { companySizeList } from 'constants/company';
import { createCompanyFields } from 'constants/formFields';
import { formNamesSignup } from 'constants/forms';
import { accountingSoftwareList } from 'constants/ledger';
import { formStyles, sizes } from 'constants/styles';

import { CreateAccountCompanyProps } from './CreateAccountCompany.types';

const CreateAccountCompany: React.FC<CreateAccountCompanyProps> = ({
  errors,
  formState,
  onIncrementStep,
  onInputChange,
  onSelectChange,
}) => (
  <div className="branded--contents">
    <BoxV2 title="Create your account">
      <form className="form" id={formNamesSignup.CREATE_ACCOUNT_COMPANY}>
        <FormFieldLabel>Company information</FormFieldLabel>

        <FormControl>
          <InputField
            className={formStyles.field.xl.full}
            errors={errors}
            name={createCompanyFields.COMPANY_NAME}
            onChange={(event) => onInputChange(event, 'form.company')}
            placeholder="Company name"
            setIsRequired
            type="text"
            value={formState.company[createCompanyFields.COMPANY_NAME]}
          />
        </FormControl>

        <FormControl>
          <SelectFieldV2
            errors={errors}
            input={{
              name: createCompanyFields.COMPANY_SIZE,
              onChange: (option) => onSelectChange(createCompanyFields.COMPANY_SIZE, option, 'form.company'),
              value: formState.company[createCompanyFields.COMPANY_SIZE],
            }}
            label="Company size"
            options={companySizeList}
          />
        </FormControl>

        <FormControl>
          <SelectFieldV2
            errors={errors}
            input={{
              name: createCompanyFields.COMPANY_ACCOUNTING_SOFTWARE,
              onChange: (option) =>
                onSelectChange(createCompanyFields.COMPANY_ACCOUNTING_SOFTWARE, option, 'form.company'),
              value: formState.company[createCompanyFields.COMPANY_ACCOUNTING_SOFTWARE],
            }}
            label="Current accounting software"
            options={accountingSoftwareList}
            placeholder="Select accounting software"
          />
        </FormControl>

        <FormControl className="remove-margin-bottom">
          <ButtonV2
            onClick={(e) => {
              e.preventDefault();
              onIncrementStep();
            }}
            rightIconName={IconNames.ARROW_RIGHT}
            rightIconProps={{ style: { marginLeft: 'auto' } }}
            rightIconSize={sizes.iconSizes.LARGE}
            size={ButtonSize.LARGE}
          >
            Continue
          </ButtonV2>
        </FormControl>
      </form>
    </BoxV2>
  </div>
);

CreateAccountCompany.defaultProps = {
  errors: {},
};

export default CreateAccountCompany;
