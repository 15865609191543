import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { formNamesFunding } from 'constants/forms';

import { openManuallyAddBankAccountSidePanel } from 'helpers/sidePanels';

import { EmptyObject } from 'interfaces/global';

import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { createMergeProps } from 'store/redux';

import AddBankAccountManuallyButton from './AddBankAccountManuallyButton';
import type {
  DispatchProps,
  MergedProps,
  StateProps,
  MergePropsReturnType,
} from './AddBankAccountManuallyButton.types';

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  partnership: partnershipFromQuerySelector,
});

export const mapDispatchToProps: DispatchProps = {
  onOpenSidePanel: openManuallyAddBankAccountSidePanel,
};

export const mergeProps = createMergeProps(
  (stateProps: StateProps, dispatchProps: DispatchProps): MergePropsReturnType => ({
    onClick: () => {
      const { partnership } = stateProps;
      const { onOpenSidePanel } = dispatchProps;

      onOpenSidePanel({
        formContext: formNamesFunding.ADD_MANUAL_BANK,
        partnershipId: partnership?.id,
      });
    },
  }),
);

export default connect<StateProps, DispatchProps, EmptyObject, MergedProps>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AddBankAccountManuallyButton);
