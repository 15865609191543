import React from 'react';
import { useFormContext } from 'react-hook-form';

import { W8FormModel } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';

import { BaseHint } from 'components';

import { Intent } from 'constants/ui';

import RenderTextInput from '../RenderTextInput';
import { Group, GroupSlot } from '../styles';

const FieldGroupTaxPayerIdentificationNumber = (): JSX.Element => {
  const { watch } = useFormContext<W8FormModel>();

  // If the user is claiming treaty benefits, either TIN or FTIN is required. Since
  // that is not a field-specific error (it could be either), we want to show a hint
  // bellow the fields if neither has a value
  const [claimingTreatyBenefits, tin, ftin] = watch(['claimingTreatyBenefits', 'tin', 'ftin']);
  const shouldShowHint = claimingTreatyBenefits && !(tin || ftin);

  return (
    <Group>
      <GroupSlot size={3}>
        <RenderTextInput name="tin" placeholder="U.S. Taxpayer ID Number" />
      </GroupSlot>
      <GroupSlot size={3}>
        <RenderTextInput name="ftin" placeholder="Foreign Tax ID Number" />
      </GroupSlot>
      {shouldShowHint && (
        <GroupSlot size={6}>
          <BaseHint intent={Intent.WARNING} title="Either TIN or FTIN is required when claiming treaty benefits" />
        </GroupSlot>
      )}
    </Group>
  );
};

export default FieldGroupTaxPayerIdentificationNumber;
