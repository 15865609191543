import styled from 'styled-components';

import BaseHint from 'components/hintTypes/BaseHint';

import { containerWidthMap } from 'modules/signup-v3/constants/container';

export const Container = styled.div`
  max-width: ${containerWidthMap.medium};
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const UploadFileHint = styled(BaseHint)`
  margin-bottom: 21px;
`;
