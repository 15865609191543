import { ssoLoginRoutine, ssoHandleRedirectRoutine } from 'actions/routines/auth';

import { createIsDoingReducer } from 'store/redux';

export const finishCases = [
  ssoLoginRoutine.SUCCESS,
  ssoHandleRedirectRoutine.FAILURE,
  ssoHandleRedirectRoutine.SUCCESS,
];

export const requestCases = [ssoHandleRedirectRoutine.TRIGGER];

const isSSORedirectingReducer = createIsDoingReducer(finishCases, requestCases);

export default isSSORedirectingReducer;
