import { WarningAltFilled } from '@carbon/icons-react';
import { Badge } from '@routable/gross-ds';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ExistingItem } from 'context';

import { isItemStatusCanceled, isItemStatusCompleted, isItemStatusExternallyPaid } from 'helpers/items';
import { hasLength } from 'helpers/utility';

import type { EmptyObject } from 'interfaces/global';
import type { Ledger } from 'interfaces/integrations';
import type { Item } from 'interfaces/item';

import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';

import { getFieldProps } from './helpers';
import type { PresentationalFieldProps } from './PresentationalField.types';
import RenderValue from './RenderValue';

/**
 * Renders values in plain text mode
 */
const PresentationalField = ({ fieldType, options, placeholder, ...props }: PresentationalFieldProps): JSX.Element => {
  const item = useContext<Item>(ExistingItem);
  const ledger: Ledger | EmptyObject = useSelector(ledgerIntegrationSelector);

  const { fieldPath, isInTabSection, value } = getFieldProps({
    item,
    ledger,
    options,
    ...props,
  });

  // do not render "Ledger status field" if not connected to ledger or ledger status is undefined
  if (fieldPath === 'ledgerStatus' && !value) {
    return null;
  }

  // do not render Tags field if no tags exist for this item
  if (fieldPath === 'tagLinks' && !hasLength(value)) {
    return null;
  }

  const showOverdueBadge =
    fieldPath === 'dateDue' &&
    item.daysUntilDue < 0 &&
    !isItemStatusCanceled(item) &&
    !isItemStatusCompleted(item) &&
    !isItemStatusExternallyPaid(item);

  return (
    <div
      className={clsx('flex', {
        'flex-col': !isInTabSection,
        'border-b': isInTabSection,
        'border-grey-10': isInTabSection,
        'justify-between': isInTabSection,
        'items-center': isInTabSection,
        'py-4': isInTabSection,
      })}
    >
      <span className="text-sm text-grey-60">
        {fieldPath === 'ledgerStatus' ? `${ledger?.name} status` : placeholder}
      </span>

      <div
        className={clsx('flex flex-wrap', {
          'max-w-[70%]': isInTabSection,
        })}
      >
        <span
          className={clsx('text-sm max-w-full', {
            'leading-6': !isInTabSection,
            'text-black': !!value,
            'text-grey-50': !value,
            'w-full': options?.length === 0,
          })}
        >
          <RenderValue fieldPath={fieldPath} fieldType={fieldType} item={item} options={options} value={value} />
        </span>

        {showOverdueBadge && (
          <Badge intent="warning">
            <WarningAltFilled className="w-3 h-3 mr-2" />
            Overdue
          </Badge>
        )}
      </div>
    </div>
  );
};

export default PresentationalField;
