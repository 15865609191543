import { Auth0ContextInterface } from '@auth0/auth0-react';

/**
 * Stub for initial state of Auht0Client methods
 *
 * If Auth0Client gets used without being initialized it will throw an error.
 */
const stub = (): never => {
  throw new Error('In order to use Auth0Client you need to call initAuth0Client with the auth0 context.');
};

/**
 * Auth0Client
 *
 * A copy of the auth0Context outside of the React Context, allowing us
 * to use the `Auth0Client` in sagas, helpers, etc.
 *
 * E.g.:
 *      ```
 *      import { Auth0Client } from 'services/auth0';
 *
 *      const auth0Token = Auth0Client.getAccessTokenSilently(...options);
 *      ```
 *
 * As of this writing the @auth0/auth0-react sdk don't expose the auth0Context outside of the React Context, making it
 * tightly coupled to be used inside React components.
 */
export const Auth0Client: Record<string, () => never | unknown> | Auth0ContextInterface = {
  buildAuthorizeUrl: stub,
  buildLogoutUrl: stub,
  getAccessTokenSilently: stub,
  getAccessTokenWithPopup: stub,
  getIdTokenClaims: stub,
  handleRedirectCallback: stub,
  isAuthenticated: undefined,
  loginWithPopup: stub,
  loginWithRedirect: stub,
  logout: stub,
  user: undefined,
};

/**
 * initAuth0Client
 *
 * This will copy over the desired attributes/functions from the auth0Context received, allowing us
 * to use the `Auth0Client` in sagas, helpers, etc.
 *
 * E.g.:
 *      ```
 *      import { Auth0Client } from 'services/auth0';
 *
 *      const auth0Token = Auth0Client.getAccessTokenSilently(...options);
 *      ```
 *
 * As of this writing the @auth0/auth0-react sdk don't expose the auth0Context outside of the React Context, making it
 * tightly coupled to be used inside React components.
 * @param auth0Context {Auth0ContextInterface}
 */
export const initAuth0Client = (auth0Context: Auth0ContextInterface): void => {
  Object.entries(Auth0Client).forEach(([key]) => {
    Auth0Client[key] = auth0Context[key];
  });
};
