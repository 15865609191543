import React from 'react';

import { Text } from 'components/text';

import { typography } from 'constants/styles';

import { LockedCountryInputTooltipContentProps } from './LockedCountryInputTooltipContent.types';

/**
 * Component rendering Locked Country Input tooltip content
 * @see {LockedCountryInput}
 * @param {LockedCountryInputTooltipContentProps} props
 * @returns {StatelessComponent}
 */
const LockedCountryInputTooltipContent: React.FC<LockedCountryInputTooltipContentProps> = ({ content }) => (
  <Text.Regular size={typography.TextSize.LEVEL_100}>{content}</Text.Regular>
);

export default LockedCountryInputTooltipContent;
