import classNames from 'classnames';
import React from 'react';

import { Image } from 'components/media';
import { Text } from 'components/text';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { typography } from 'constants/styles';

import { LOGO } from 'global/images/platform';

import { BoxV2Props } from './BoxV2.types';

import './BoxV2.scss';

/**
 * Box component used in signup / login flows
 */
const BoxV2: React.FC<BoxV2Props> = ({ children, className, subtitle, title }) => (
  <div
    className={classNames({
      'box-v2--container': true,
      [className]: Boolean(className),
    })}
  >
    <div className="box-v2--header">
      <Image alt={PLATFORM_DISPLAY_SHORT_NAME} className="box-v2--header__logo margin-bottom--m" src={LOGO.NAVY} />

      <Text.Regular
        className="text-align--center"
        color={typography.TextColor.BLACK}
        lineHeight={typography.TextLineHeight.EXTRA_EXTRA_EXTRA_LARGE}
        size={typography.TextSize.LEVEL_600}
      >
        {title}
      </Text.Regular>

      {subtitle && (
        <Text.Regular
          className="display--block margin-top--sm text-align--center"
          color={typography.TextColor.GREY_XX_DARK}
          lineHeight={typography.TextLineHeight.LARGE}
          size={typography.TextSize.LEVEL_200}
        >
          {subtitle}
        </Text.Regular>
      )}
    </div>

    <div className="box-v2--body">{children}</div>
  </div>
);

export default BoxV2;
