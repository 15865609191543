import { BreadcrumbsTextVariant } from 'constants/breadcrumbs';
import {
  COMPANIES_ROUTE,
  FILTER_LABELS,
  GUIDE_ROUTE,
  PAYMENTS_LIST_FILTERS,
  SETTINGS_ACCOUNT_BALANCE_ROUTE,
  SETTINGS_ACCOUNT_FILTERS,
  SETTINGS_ACCOUNT_INTEGRATIONS_ROUTE,
  SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE,
  SETTINGS_ADDITIONAL_FILTER_LABELS,
  SETTINGS_ACCOUNT_ROLES_ROUTE,
  CONTACTS_TABS,
} from 'constants/routes';
import { TextSize } from 'constants/styles/typography';

import { isItemCreatorCurrentCompany } from 'helpers/items';
import { findOneMembershipById, getMembershipNameOrEmail, isMembershipDisabled } from 'helpers/memberships';
import { getContactsOrPaymentsRouteBasedOnTab, getJoinedPath } from 'helpers/routeHelpers';
import { capitalize } from 'helpers/stringHelpers';
import { getItemThreadTitle } from 'helpers/thread';
import {
  isTabAnIntegration,
  isTabError,
  isTabPayables,
  isTabInviteTeamMembers,
  isTabTeamMembers,
  isTabTeamMemberProfile,
  isTabRoles,
  isTabCreateRole,
  isTabRole,
} from 'helpers/urls';
import {
  hasZeroLength,
  isDefined,
  isEqual,
  isGreaterThan,
  isUndef,
  lastElementIn,
  lengthOf,
  secondLastElementIn,
} from 'helpers/utility';

/**
 * Get label for Breadcrumbs based on filter (and item.kind if needed)
 * @param {string} filter
 * @param {string} tab
 * @return {string|undefined}
 */
export const getFilterLabelForBreadcrumbs = (filter, tab = '') => {
  if (isDefined(FILTER_LABELS[filter])) {
    return FILTER_LABELS[filter];
  }

  // Label which is dependent on tab (payables/receivables)
  switch (filter) {
    case PAYMENTS_LIST_FILTERS.WAITING_FOR_ME:
      return isTabPayables(tab) ? FILTER_LABELS.WAITING_FOR_ME_PAYABLES : FILTER_LABELS.WAITING_FOR_ME_RECEIVABLES;

    case PAYMENTS_LIST_FILTERS.WAITING_FOR_OTHERS:
      return isTabPayables(tab)
        ? FILTER_LABELS.WAITING_FOR_OTHERS_PAYABLES
        : FILTER_LABELS.WAITING_FOR_OTHERS_RECEIVABLES;

    default:
      return undefined;
  }
};
/**
 * Get label for Breadcrumbs based on filter (and item.kind if needed)
 * @param {string} filter
 * @param {string} tab
 * @return {string|undefined}
 */

export const getFilterLabelForBreadcrumbsV2 = (filter, tab = '') => {
  if (
    [
      PAYMENTS_LIST_FILTERS.MY_APPROVAL,
      PAYMENTS_LIST_FILTERS.NEEDS_APPROVAL,
      PAYMENTS_LIST_FILTERS.TEAM_APPROVAL,
    ].includes(filter)
  ) {
    return 'Needs approval';
  }

  if (isDefined(FILTER_LABELS[filter])) {
    return FILTER_LABELS[filter];
  }

  // Label which is dependent on tab (payables/receivables)
  switch (filter) {
    case PAYMENTS_LIST_FILTERS.WAITING_FOR_ME:
      return isTabPayables(tab) ? FILTER_LABELS.WAITING_FOR_ME_PAYABLES : FILTER_LABELS.WAITING_FOR_ME_RECEIVABLES;

    case PAYMENTS_LIST_FILTERS.WAITING_FOR_OTHERS:
      return isTabPayables(tab)
        ? FILTER_LABELS.WAITING_FOR_OTHERS_PAYABLES
        : FILTER_LABELS.WAITING_FOR_OTHERS_RECEIVABLES;

    default:
      return undefined;
  }
};

/**
 * Get initial breadcrumbs for ItemDetails or CompanyDetails.
 * @param {string} filter
 * @param {string} tab
 * @return {BreadcrumbLink[]}
 */
export const getItemOrCompanyDetailsInitialBreadcrumbs = (filter, tab) => {
  const links = [];
  const route = getContactsOrPaymentsRouteBasedOnTab(tab);

  if (tab !== CONTACTS_TABS.COMPANIES) {
    links.push({
      label: capitalize(tab),
      url: getJoinedPath(route, tab, PAYMENTS_LIST_FILTERS.ALL),
    });
  }

  // check if filter should be shown in breadcrumbs
  const filterLabel = getFilterLabelForBreadcrumbs(filter, tab);
  if (isDefined(filterLabel)) {
    links.push({
      label: filterLabel,
      url: getJoinedPath(route, tab, filter),
    });
  }

  return links;
};

/**
 * Get breadcrumbs for CompanyDetails page
 * @param {Item} item
 * @param {Location} location
 * @param {Object} match
 * @param {Partnership} partnership
 * @return {BreadcrumbLink[]}
 */
export const getCompanyDetailsBreadcrumbs = ({ item, location, match, partnership }) => {
  const { params } = match;
  const { filter, tab } = params;

  let links = [];
  // set item as first element if it exists
  // (redirected from item details page)
  if (item) {
    links.push({
      label: getItemThreadTitle(item, partnership),
      url: location.state.previous,
      state: { previous: location.pathname },
    });
  }

  if (hasZeroLength(links)) {
    links = getItemOrCompanyDetailsInitialBreadcrumbs(filter, tab);
  }

  // push company name as last element
  links.push({
    label: partnership.name,
  });

  return links;
};

/**
 * Get breadcrumbs for ItemDetails page
 * @param {Company} currentCompany
 * @param {Item} item
 * @param {Location} location
 * @param {Object} match
 * @param {Partnership} partnership
 * @return {BreadcrumbLink[]}
 */
export const getItemDetailsBreadcrumbs = ({ currentCompany, item, location, match, partnership }) => {
  const { params } = match;
  const { filter, tab } = params;

  let links = [];
  // check if landed from the companies page
  if (location.state?.previous) {
    const { previous: previousUrl } = location.state;

    if (previousUrl.startsWith(COMPANIES_ROUTE)) {
      links.push({
        label: partnership.name,
        url: previousUrl,
        state: { previous: location.pathname },
      });
    } else if (previousUrl.startsWith(SETTINGS_ACCOUNT_BALANCE_ROUTE)) {
      links.push({
        label: 'Account Balance',
        url: previousUrl,
      });
    }
  }

  if (hasZeroLength(links)) {
    links = getItemOrCompanyDetailsInitialBreadcrumbs(filter, tab);
  }

  const itemCreator = isItemCreatorCurrentCompany(item) ? currentCompany : partnership;
  // push item's title as last element
  links.push({
    label: getItemThreadTitle(item, itemCreator),
  });

  return links;
};

/**
 * Get breadcrumbs for Guide pages.
 * @param {Location} location
 * @return {BreadcrumbLink[]}
 */
export const getGuideBreadcrumbs = (location) => {
  const [page] = location.pathname.split('/')?.reverse?.();

  const label = getFilterLabelForBreadcrumbs(page);
  // do not show breadcrumbs on non-supported pages
  if (isUndef(label)) {
    return [];
  }

  return [
    {
      label: 'Guide',
      url: GUIDE_ROUTE,
    },
    {
      label: FILTER_LABELS[page],
    },
  ];
};

/**
 * For the Team Members settings pages, determine the breadcrumbs.
 * @param {OptionsArg} options
 * @param {MembershipWithUserData[]} options.currentCompanyMembers
 * @param {string} options.lastTab
 * @returns {BreadcrumbLink[]}
 */
export const getTeamMemberSettingsBreadcrumbs = ({ currentCompanyMembers, lastTab }) => {
  // Always start with 'Team members'
  const crumbs = [
    {
      label: FILTER_LABELS[SETTINGS_ACCOUNT_FILTERS.TEAM_MEMBERS],
    },
  ];

  // If we're inviting
  if (isTabInviteTeamMembers(lastTab)) {
    // the breadcrumb will be Team Members / Invite a new team member
    crumbs.push({
      label: SETTINGS_ADDITIONAL_FILTER_LABELS[SETTINGS_ACCOUNT_FILTERS.INVITE_TEAM_MEMBER],
    });
  }

  // If we're viewing a specific team member's profile
  if (isTabTeamMemberProfile(lastTab)) {
    const member = findOneMembershipById(currentCompanyMembers, lastTab);
    const isInactive = isMembershipDisabled(member);
    const label = getMembershipNameOrEmail(member);

    // In the case that the team member is inactive
    if (isInactive) {
      // the breadcrumb will be Team Members / Inactive
      crumbs.push({
        label: SETTINGS_ADDITIONAL_FILTER_LABELS[SETTINGS_ACCOUNT_FILTERS.INACTIVE_TEAM_MEMBER],
      });
    }

    // Always end the team member profile section with the name/email of the team member. So either
    // 1. Team Member / {Name|Email}
    // 2. Team Member / Inactive / {Name|Email}
    crumbs.push({ label });
  }

  // If we're deeper than Team Member, such as Team Member / {Name|Email}, make Team Member a link
  if (isGreaterThan(lengthOf(crumbs), 1)) {
    crumbs[0].url = SETTINGS_ACCOUNT_TEAM_MEMBERS_ROUTE;
  }

  return crumbs;
};

/**
 * For the Roles settings pages, determine the breadcrumbs.
 * @param {OptionsArg} options
 * @param {Roles[]} options.roles
 * @param {string} options.lastTab
 * @returns {BreadcrumbLink[]}
 */
export const getRolesSettingsBreadcrumbs = ({ lastTab, roles }) => {
  const rolesLabel = FILTER_LABELS[SETTINGS_ACCOUNT_FILTERS.ROLES];

  if (isTabCreateRole(lastTab)) {
    return [
      {
        label: rolesLabel,
        url: SETTINGS_ACCOUNT_ROLES_ROUTE,
      },
      {
        label: SETTINGS_ADDITIONAL_FILTER_LABELS[SETTINGS_ACCOUNT_FILTERS.CREATE_ROLE],
      },
    ];
  }

  if (isTabRole(lastTab) && roles?.[lastTab]?.attributes.displayName) {
    const {
      attributes: { displayName },
    } = roles?.[lastTab];
    return [
      {
        label: rolesLabel,
        url: SETTINGS_ACCOUNT_ROLES_ROUTE,
      },
      {
        label: displayName,
      },
    ];
  }

  return [{ label: rolesLabel }];
};

/**
 * Returns multi level breadcrumbs for certain settings pages
 * @param {OptionsArg} options
 * @param {MembershipWithUserData[]} options.currentCompanyMembers
 * @param {Location} options.location
 * @return {BreadcrumbLink[]}
 */
export const getMultiLevelBreadcrumbsForSettings = ({ currentCompanyMembers, location, roles }) => {
  const tabs = location.pathname.split('/');
  const lastTab = lastElementIn(tabs);
  const secondLastTab = secondLastElementIn(tabs);

  // inside integrations
  if (isTabAnIntegration(lastTab)) {
    let lastBreadcrumb = lastTab;
    // check if we are on the error page
    if (isTabError(secondLastTab)) {
      // change the last breadcrumb label
      lastBreadcrumb = secondLastTab;
    }

    return [
      // first link would be 'Integrations' >
      {
        label: getFilterLabelForBreadcrumbs(SETTINGS_ACCOUNT_FILTERS.INTEGRATIONS),
        url: SETTINGS_ACCOUNT_INTEGRATIONS_ROUTE,
      },
      // second would be 'Manage ${integration}' or 'Connection error'
      {
        label: SETTINGS_ADDITIONAL_FILTER_LABELS[lastBreadcrumb],
      },
    ];
  }

  if (isTabTeamMembers(secondLastTab)) {
    return getTeamMemberSettingsBreadcrumbs({ currentCompanyMembers, lastTab });
  }

  if (isTabRoles(secondLastTab)) {
    return getRolesSettingsBreadcrumbs({ lastTab, roles });
  }

  if (secondLastTab === SETTINGS_ACCOUNT_FILTERS.COMPANY_REP_INFO && lastTab === SETTINGS_ACCOUNT_FILTERS.REP_INFO) {
    return [{ label: 'Business Representative' }, { label: 'My representative info' }];
  }

  return [];
};

/**
 * Get breadcrumbs for Settings pages
 * @param {OptionsArg} options
 * @param {MembershipWithUserData[]} options.currentCompanyMembers
 * @param {Location} options.location
 * @return {BreadcrumbLink[]}
 */
export const getSettingsBreadcrumbs = ({ currentCompanyMembers, location, roles }) => {
  const tabs = location.pathname.split('/');
  const lastTab = lastElementIn(tabs);

  const label = getFilterLabelForBreadcrumbs(lastTab);

  // this means it has only one level
  // ex.: Account information
  if (isDefined(label)) {
    return [{ label }];
  }

  // this means it has 2+ levels
  // ex.: Integrations > Manage ${integration}
  return getMultiLevelBreadcrumbsForSettings({
    currentCompanyMembers,
    location,
    roles,
  });
};

/**
 * Check if given text variant is large
 * @param {string} variant
 * @return {boolean}
 */
export const isBreadcrumbsTextVariantLarge = (variant) => isEqual(variant, BreadcrumbsTextVariant.LARGE);

/**
 * Get breadcrumb text size based on variant
 * @param {String} variant
 * @return {TextSize}
 */
export const getBreadcrumbTextSize = (variant) => {
  if (isBreadcrumbsTextVariantLarge(variant)) {
    return TextSize.LEVEL_400;
  }

  return TextSize.LEVEL_300;
};

/**
 * Get breadcrumb text line height based on variant
 * @param {String} variant
 * @return {String}
 */
export const getBreadcrumbLineHeight = (variant) => {
  if (isBreadcrumbsTextVariantLarge(variant)) {
    return 'line-height--large--important';
  }

  return 'line-height--xm--important';
};
