import React from 'react';

import { BaseHint } from 'components';

import { Intent } from 'constants/ui';

import type { ManuallyAddBankAccountErrorHintProps } from './ManuallyAddBankAccountErrorHint.types';

import './ManuallyAddBankAccountErrorHint.scss';

/**
 * Component rendering generic error hint inside of a Manually Add
 * Bank Account Side Sheet.
 * @param {ManuallyAddBankAccountErrorHintProps} props
 * @returns {StatelessComponent}
 */
const ManuallyAddBankAccountErrorHint: React.FC<ManuallyAddBankAccountErrorHintProps> = ({ company }) => (
  <div className="manually-add-bank-account-error-hint">
    <BaseHint
      intent={Intent.DANGER}
      text={`An error occurred while trying to add your payment method. Please reach out to ${company.name} to resolve the issue with your payment.`}
      title="Error"
      titleProps={{ className: 'font-color--red--bold--important' }}
    />
  </div>
);

export default ManuallyAddBankAccountErrorHint;
