import { MouseEvent } from 'react';

/**
 * Returns a non-capturing/non-bubbling mouse event
 */
export const noPropagationMouseEvent = (event: MouseEvent): MouseEvent => {
  event?.stopPropagation?.();

  return event;
};
