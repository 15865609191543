import classNames from 'classnames';
import debounce from 'es6-promise-debounce';
import React, { useCallback, useState } from 'react';
import './PasswordInput.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import { triggerPasswordStrengthRequest } from 'actions/passwordInput';

import { FormControl, IconNames, ReduxFormFieldRenderInput, TooltipIcon } from 'components';
import GenericFormError from 'components/GenericFormError/GenericFormError';

import { field } from 'constants/styles/formStyles';

import EnterExistingPasswordErrorContent from 'modules/auth/AcceptMembershipInvite/components/AcceptMembershipInviteForm/components/ReduxFormFieldRenderInputWithUpdatedErrors/components/EnterExistingPasswordErrorContent';

import { getPasswordScore, getAreAllRequirementsMet, getPasswordFeedback } from 'selectors/passwordInputSelectors';
import { signupCreateAccountPasswordErrorSelector } from 'selectors/signupSelectors';

import PasswordHints from './components/PasswordHints';
import {
  strengthNeedsFeedback,
  strengthScores,
  strengthTypes,
} from './components/PasswordHints/constants/strengthScores';
import { ACCOUNT_ALREADY_EXISTS_ERROR } from './constants/passwordErrorMessages';
import { errorStyles } from './constants/passwordRequirementParameters';
import { standardPasswordValidators } from './constants/standardPasswordValidators';
import { checkAllRequirements } from './helpers/checkAllRequirements';
import { PasswordInputProps, PasswordFormState } from './types/PasswordInput.types';

const PasswordInput: React.FC<PasswordInputProps> = ({ name, userAttemptedSubmission, formName }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showStrength, setShowStrength] = useState(false);

  const targetIcon = showPassword ? (
    <TooltipIcon content="Hide" icon={IconNames.EYE_OFF} iconProps={{ size: 14 }} />
  ) : (
    <TooltipIcon content="Show" icon={IconNames.EYE_OPEN} iconProps={{ size: 14 }} />
  );

  const dispatch = useDispatch();

  const submissionPasswordErrors = useSelector(signupCreateAccountPasswordErrorSelector);
  const passwordFeedback = useSelector(getPasswordFeedback);

  const formValues: PasswordFormState = useSelector(getFormValues(formName));
  const passwordScore = useSelector(getPasswordScore);
  const formErrors = useSelector((state: ReduxState) => state.form[formName]?.syncErrors?.user?.password);
  const requirementsAreMet = useSelector(getAreAllRequirementsMet);

  const currentPassword = formValues.user?.password;
  const strength = strengthScores[passwordScore];

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  // We check whether all requirements are filled before making a call to the backend
  // In the meantime, we provide the user with a fake weak password
  // Once all checks have passed, the API call is made to get the actual score
  const handleChange = (event) => {
    if (checkAllRequirements(dispatch, event.target.value)) {
      dispatch(triggerPasswordStrengthRequest(event.target.value));
    }
  };

  const handleBlur = () => {
    if (strength === strengthTypes.WEAK && !currentPassword) {
      setShowStrength(false);
    }
  };
  const handleFocus = () => setShowStrength(true);

  const debouncedChange = useCallback(debounce(handleChange, 500), []);

  const passwordError =
    submissionPasswordErrors === ACCOUNT_ALREADY_EXISTS_ERROR ? (
      <GenericFormError>
        <EnterExistingPasswordErrorContent />
      </GenericFormError>
    ) : (
      <GenericFormError errorMessage={submissionPasswordErrors} />
    );

  // Input field type is interchangeable so that the toggle to
  // display/hide password works. Password fields are never revealed.
  return (
    <div className="password-entry-container">
      <FormControl className="password-entry-control">
        <Field
          className={classNames('password-entry-field', field.xl.full)}
          component={ReduxFormFieldRenderInput}
          dataFullStory
          dataTestId="password"
          isRequired
          name={name}
          onBlur={handleBlur}
          onChange={debouncedChange}
          onFocus={handleFocus}
          placeholder="Password"
          type={showPassword ? 'text' : 'password'}
          validate={standardPasswordValidators}
        >
          <button className="toggle-password-reveal" onClick={handlePasswordVisibility} type="button">
            {targetIcon}
          </button>
        </Field>

        {userAttemptedSubmission && submissionPasswordErrors && passwordError}

        {strengthNeedsFeedback[strength] && !formErrors && !submissionPasswordErrors && (
          <GenericFormError errorMessage={passwordFeedback} />
        )}
      </FormControl>
      {!submissionPasswordErrors && (
        <PasswordHints
          formName={formName}
          requirementsAreMet={requirementsAreMet}
          showStrength={showStrength}
          styling={userAttemptedSubmission ? errorStyles.HIGHLIGHTED : errorStyles.MUTED}
        />
      )}
    </div>
  );
};

export default PasswordInput;
