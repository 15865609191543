import { InvoiceTypes } from 'constants/invoice';
import { ItemKinds } from 'constants/item';
import { CONTACTS_TABS, PAYMENTS_LIST_TABS } from 'constants/routes';

import { getPayablesOrReceivablesTextForItemCount } from 'helpers/items';
import { isFilterAll, isFilterNeedsApproval, isTabCompanies, isTabInbox } from 'helpers/urls';
import { and, hasLength } from 'helpers/utility';

/**
 * Returns item kind based on tab
 * @param {String} tab
 * @return {ItemKinds|null}
 */
export const getItemKindByTab = (tab) => {
  switch (tab) {
    case PAYMENTS_LIST_TABS.PAYABLES:
      return ItemKinds.PAYABLE;

    case PAYMENTS_LIST_TABS.RECEIVABLES:
      return ItemKinds.RECEIVABLE;

    default:
      return null;
  }
};

export const getItemTextByTabSingular = (tab) => {
  switch (tab) {
    case PAYMENTS_LIST_TABS.PAYABLES:
      return InvoiceTypes[ItemKinds.PAYABLE];

    case PAYMENTS_LIST_TABS.RECEIVABLES:
      return InvoiceTypes[ItemKinds.RECEIVABLE];

    default:
      return `${InvoiceTypes[ItemKinds.PAYABLE]} or ${InvoiceTypes[ItemKinds.RECEIVABLE]}`;
  }
};

export const getItemTextByTabPlural = (tab) => {
  switch (tab) {
    case PAYMENTS_LIST_TABS.PAYABLES:
    case PAYMENTS_LIST_TABS.RECEIVABLES:
      return tab;

    case CONTACTS_TABS.COMPANIES:
      return 'items';

    default:
      return 'payables or receivables';
  }
};

/**
 * Returns emoji and message for empty table state based on given props
 * @param {Object} options
 * @param {string} filter
 * @param {string} [firstName]
 * @param {ItemKinds} [kind]
 * @param {boolean} requiresApproval
 * @param {UIFilterList} selectedFilters
 * @param {string} searchQuery
 * @param {string} tab
 * @return {{ emoji: string, message: string }}
 */
export const getMessageAndEmojiForEmptyState = ({
  filter,
  firstName,
  kind,
  requiresApproval,
  selectedFilters,
  searchQuery,
  tab,
}) => {
  let emoji;
  let message;

  const areFiltersApplied = hasLength(selectedFilters);
  const isSearchApplied = Boolean(searchQuery);

  if (areFiltersApplied) {
    message = `No ${getPayablesOrReceivablesTextForItemCount(kind, 2)} match your selected filters`;
  } else if (isTabInbox(tab)) {
    message = 'No bills awaiting data entry';
    emoji = '👍';
  } else if (and(isFilterNeedsApproval(filter), !requiresApproval)) {
    message = 'Approvals are not turned on';
  } else if (and(!isTabCompanies(tab), isFilterAll(filter), !isSearchApplied)) {
    message = `Welcome, ${firstName}`;
    emoji = '👋';
  } else {
    message = 'Nothing to see here';
    emoji = '🔭';
  }

  return { emoji, message };
};
