import classNames from 'classnames';
import React from 'react';

import { Text } from 'components';

import { typography } from 'constants/styles';

import ManuallyAddBankAccountField from '../ManuallyAddBankAccountField';

import AccountCurrencyCodeField from './components/AccountCurrencyCodeField';
import type { ManuallyAddBankAccountBankDetailsProps } from './ManuallyAddBankAccountBankDetails.types';

/**
 * Component rendering Bank Account Details section in the Manually Add Bank Account
 * Side Sheet
 * @param props - Component props
 */
const ManuallyAddBankAccountBankDetails = ({
  availableCurrencies,
  fields,
  includeMarginTop,
  isInternational,
}: ManuallyAddBankAccountBankDetailsProps) => {
  const containerClassNames = classNames('manually-add-bank-account--bank-details', {
    'margin-top--x-large': includeMarginTop,
  });

  return (
    <div className={containerClassNames}>
      {isInternational && (
        <>
          <Text.Bold color={typography.TextColor.BLUE_DARK} size={typography.TextSize.LEVEL_475}>
            Bank account details
          </Text.Bold>
          <AccountCurrencyCodeField availableCurrencies={availableCurrencies} />
        </>
      )}
      {fields?.map((fieldInfo) => (
        <ManuallyAddBankAccountField fieldInfo={fieldInfo} isInternational={isInternational} key={fieldInfo.field} />
      ))}
    </div>
  );
};

export default ManuallyAddBankAccountBankDetails;
