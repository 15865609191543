import { lineItemModel, offsetPaginationModel } from '@routable/shared';
import { z } from 'zod';

import { NETSUITE } from 'constants/integration';

import { parseCurrency } from 'helpers/numbers';

export const purchaseOrderModel = z.object({
  amount: z.coerce.number(),
  created: z.string(),
  currencyCode: z.string(),
  isBillable: z.boolean(),
  ledgerLink: z.string(),
  ledgerRef: z.string(),
  purchaseOrderNumber: z.string().nullish(),
  status: z.enum(['Open', 'Closed', 'Unapproved']),
  statusDescription: z.string().nullish(),
  statusLedger: z.string().nullish(),
});

export const purchaseOrderOptionModel = purchaseOrderModel.transform((purchaseOrder) => ({
  currencyCode: purchaseOrder.currencyCode,
  description: `Total amount: ${parseCurrency(purchaseOrder.amount, { symbol: '' })} ${
    purchaseOrder.currencyCode
  } Created: ${purchaseOrder.created}`,
  isDisabled: Boolean(purchaseOrder.statusDescription),
  key: purchaseOrder.ledgerRef,
  label: purchaseOrder.purchaseOrderNumber || `${NETSUITE} ID: ${purchaseOrder.ledgerRef}`,
  ledgerLink: purchaseOrder.ledgerLink,
  rightAddons: null,
  status: purchaseOrder.status,
  statusLedger: purchaseOrder.statusLedger,
  tooltip: purchaseOrder.statusDescription,
  value: purchaseOrder?.ledgerRef,
}));

export type PurchaseOrder = z.infer<typeof purchaseOrderModel>;
export type PurchaseOrderOption = z.infer<typeof purchaseOrderOptionModel>;

export const purchaseOrdersByBillSchema = z.object({
  data: z.object({
    bills: z
      .object({
        ledgerRef: z.string(),
        purchaseOrders: z.array(purchaseOrderModel),
      })
      .array(),
  }),
});

export type PurchaseOrdersByBillResponse = z.infer<typeof purchaseOrdersByBillSchema>;

export const purchaseOrderSchema = z.object({
  data: z.object({
    purchaseOrders: purchaseOrderModel.array(),
  }),
  pagination: offsetPaginationModel,
});

export type PurchaseOrderResponse = z.infer<typeof purchaseOrderSchema>;

export const singlePurchaseOrderModel = z.object({
  data: purchaseOrderModel.extend({
    lineItems: lineItemModel.array(),
  }),
});

export type SinglePurchaseOrderResponse = z.infer<typeof singlePurchaseOrderModel>;

export const prefilledItemSchema = z.object({
  data: z.object({
    attributes: z.object({
      line_items: lineItemModel.array(),
    }),
  }),
});

export type PrefilledItemResponse = z.infer<typeof prefilledItemSchema>;
