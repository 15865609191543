import type { FeatureFlagName } from 'interfaces/featureFlags';

import useFeatureFlags from './useFeatureFlags';

/**
 * Provides access to the current state of the provided feature flag. Utilizes
 * useFeatureFlags hook to retrieve currently active feature flags.
 * @see {useFeatureFlags}
 * @param featureFlagName
 */
const useFeatureFlag = (featureFlagName: ValueOf<FeatureFlagName>): boolean => {
  const { [featureFlagName]: value } = useFeatureFlags();

  return !!value;
};

export default useFeatureFlag;
