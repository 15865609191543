import React from 'react';

import { AddressFormat } from 'enums/addresses';

import { getAddressAsString } from 'helpers/addressHelpers';
import {
  isFundingAccountBankAccount,
  isFundingAccountNumberMasked,
  isFundingAccountUSD,
  getFundingAccountMask,
  isFundingAccountBalance,
} from 'helpers/funding';
import { capitalize } from 'helpers/stringHelpers';

import type { FundingInfoInternational } from 'interfaces/fundingInfoInternational';

import { AccountAddress } from 'modules/fundingAccount/bankAccount/components';

import {
  PaymentMethodDetailAction,
  PaymentMethodDetailKey,
  PaymentMethodDetailsCreatorField,
  PaymentMethodDetailsCountryCurrencyField,
} from './components';
import type { PaymentMethodDetailItemProps } from './components';
import {
  PaymentMethodAccountNumberLockedTooltipContent,
  PaymentMethodFundingAccountAddedOnDate,
  PaymentMethodRoutingNumberLockedTooltipContent,
} from './components/tooltips';

import type { PaymentMethodDetailsProps } from '.';

/**
 * Create standard PaymentMethodDetailItem props to help avoid type errors
 */
export const createPaymentMethodDetailItemProps = ({
  actions = [],
  actionsElements = [],
  clipboardValue,
  key,
  label,
  value,
  revealedValue,
  tooltipContent,
}: Partial<PaymentMethodDetailItemProps>): PaymentMethodDetailItemProps => ({
  actions,
  actionsElements,
  clipboardValue,
  key,
  label,
  value,
  revealedValue,
  tooltipContent,
});

/**
 * Generates an array of PaymentMethodDetailItem props
 * containing funding details like account type and institution name
 */
export const getFundingInfoDomesticAccountTypeAndInstitutionDetailsItems = (
  props: PaymentMethodDetailsProps,
): PaymentMethodDetailItemProps[] => {
  const { fundingInfoBankAccount } = props;
  const paymentDetails: PaymentMethodDetailItemProps[] = [];
  const { accountType, institutionName } = Object(fundingInfoBankAccount);

  if (institutionName) {
    paymentDetails.push(
      createPaymentMethodDetailItemProps({
        key: PaymentMethodDetailKey.BANK,
        label: 'Bank',
        value: institutionName,
      }),
    );
  }

  if (accountType) {
    paymentDetails.push(
      createPaymentMethodDetailItemProps({
        key: PaymentMethodDetailKey.ACCOUNT_TYPE,
        label: 'Account type',
        value: capitalize(accountType),
      }),
    );
  }

  return paymentDetails;
};

/**
 * Creates PaymentMethodDetailItemProps for masked values
 */
export const createMaskedValueDetailItemProp = (props: PaymentMethodDetailItemProps): PaymentMethodDetailItemProps => {
  const { value: originalValue } = props;
  const isMasked = isFundingAccountNumberMasked(originalValue);
  const maskedDetailItemProps = {
    ...props,
    actions: [PaymentMethodDetailAction.COPY, PaymentMethodDetailAction.REVEAL],
    revealedValue: originalValue,
    value: getFundingAccountMask(originalValue),
  };

  // Value might be masked when a user doesn't have correct permissions.
  // In that case, we don't show REVEAL or COPY actions.
  const detailItemProps = isMasked ? props : maskedDetailItemProps;

  return createPaymentMethodDetailItemProps(detailItemProps);
};

/**
 * Predicate function that determines if VAN tooltip should be shown
 */
export const shouldShowVANTooltip = ({
  fundingInfoBankAccount,
  isPartnerAccountInDashboard,
}: PaymentMethodDetailsProps): boolean =>
  Boolean(
    // Only show tooltip for partner funding accounts with virtual numbers while in the Dashboard.
    fundingInfoBankAccount?.isVirtualAccountNumber && isPartnerAccountInDashboard,
  );

/**
 * Gets DetailItem props for a domestic bank accounts number
 */
export const getDomesticAccountNumberDetailItemProps = (
  props: PaymentMethodDetailsProps,
): PaymentMethodDetailItemProps => {
  const { fundingInfoBankAccount, partnership } = props;
  const { accountNumber } = Object(fundingInfoBankAccount);
  const options = {
    key: PaymentMethodDetailKey.ACCOUNT_NUMBER,
    label: 'Account number',
    value: accountNumber,
  };

  if (shouldShowVANTooltip(props)) {
    // When funding account is VirtualAccountNumber,
    // then Plaid will mask the account numbers themselves.
    return createPaymentMethodDetailItemProps({
      ...options,
      actions: [PaymentMethodDetailAction.LOCKED],
      tooltipContent: <PaymentMethodAccountNumberLockedTooltipContent partnership={partnership} />,
    });
  }

  return createMaskedValueDetailItemProp(options);
};

export const getBankNumbersDomesticVANRoutingNumberOverrides = (
  props: PaymentMethodDetailsProps,
): Partial<PaymentMethodDetailItemProps> => {
  const { partnership } = props;

  if (!shouldShowVANTooltip(props)) {
    return undefined;
  }

  return {
    actions: [PaymentMethodDetailAction.LOCKED],
    tooltipContent: <PaymentMethodRoutingNumberLockedTooltipContent partnership={partnership} />,
  };
};

/**
 * Generates an array of PaymentMethodDetailItem props
 * containing account number and routing number details
 */
export const getFundingInfoDomesticAccountAndRoutingNumberDetailsItems = (
  props: PaymentMethodDetailsProps,
): PaymentMethodDetailItemProps[] => {
  const paymentDetails: PaymentMethodDetailItemProps[] = [];
  const { fundingInfoBankAccount } = props;
  const { accountNumber, routingNumber } = Object(fundingInfoBankAccount);

  if (accountNumber) {
    paymentDetails.push(getDomesticAccountNumberDetailItemProps(props));
  }

  if (routingNumber) {
    paymentDetails.push(
      createPaymentMethodDetailItemProps({
        actions: [PaymentMethodDetailAction.COPY],
        key: PaymentMethodDetailKey.ROUTING_NUMBER,
        label: 'Routing number',
        tooltipContent: undefined,
        value: routingNumber,
        ...getBankNumbersDomesticVANRoutingNumberOverrides(props),
      }),
    );
  }

  return paymentDetails;
};

/**
 * Generates an array of PaymentMethodDetailItem props
 * containing funding details like account number, routing number, account type, and institution name
 */
export const getFundingInfoDomesticDetailsItems = (
  props: PaymentMethodDetailsProps,
): PaymentMethodDetailItemProps[] => [
  ...getFundingInfoDomesticAccountTypeAndInstitutionDetailsItems(props),
  ...getFundingInfoDomesticAccountAndRoutingNumberDetailsItems(props),
];

/**
 * Generates an array of PaymentMethodDetailItem props
 * containing international funding details like iban, branchCode, bicSwift, etc.
 */
export const getFundingInfoInternationalDetailsItems = (
  fundingInfoInternational: FundingInfoInternational,
): PaymentMethodDetailItemProps[] => {
  const paymentDetails: PaymentMethodDetailItemProps[] = [];
  const { accountNumber, bankCode, bicSwift, branchCode, bsbCode, clabe, cnaps, iban, ifsc, sortCode } =
    Object(fundingInfoInternational);

  if (accountNumber) {
    paymentDetails.push(
      createMaskedValueDetailItemProp({
        value: accountNumber,
        key: PaymentMethodDetailKey.ACCOUNT_NUMBER,
        label: 'Account number',
      }),
    );
  }

  if (bsbCode) {
    paymentDetails.push(
      createPaymentMethodDetailItemProps({
        actions: [PaymentMethodDetailAction.COPY],
        key: PaymentMethodDetailKey.BSB_CODE,
        label: 'Bank state branch (BSB)',
        value: bsbCode,
      }),
    );
  }

  if (bankCode) {
    paymentDetails.push(
      createPaymentMethodDetailItemProps({
        actions: [PaymentMethodDetailAction.COPY],
        key: PaymentMethodDetailKey.BANK_CODE,
        label: 'Bank code',
        value: bankCode,
      }),
    );
  }

  if (branchCode) {
    paymentDetails.push(
      createPaymentMethodDetailItemProps({
        actions: [PaymentMethodDetailAction.COPY],
        key: PaymentMethodDetailKey.BRANCH_CODE,
        label: 'Branch code',
        value: branchCode,
      }),
    );
  }

  if (sortCode) {
    paymentDetails.push(
      createPaymentMethodDetailItemProps({
        actions: [PaymentMethodDetailAction.COPY],
        key: PaymentMethodDetailKey.SORT_CODE,
        label: 'Sort code',
        value: sortCode,
      }),
    );
  }

  if (bicSwift) {
    paymentDetails.push(
      createMaskedValueDetailItemProp({
        value: bicSwift,
        key: PaymentMethodDetailKey.BIC_SWIFT,
        label: 'Bank identifier code (BIC)',
      }),
    );
  }

  if (iban) {
    paymentDetails.push(
      createMaskedValueDetailItemProp({
        value: iban,
        key: PaymentMethodDetailKey.IBAN,
        label: 'International bank account number (IBAN)',
      }),
    );
  }

  if (clabe) {
    paymentDetails.push(
      createMaskedValueDetailItemProp({
        value: clabe,
        key: PaymentMethodDetailKey.CLABE,
        label: 'CLABE',
      }),
    );
  }

  if (cnaps) {
    paymentDetails.push(
      createMaskedValueDetailItemProp({
        value: cnaps,
        key: PaymentMethodDetailKey.CNAPS,
        label: 'CNAPS',
      }),
    );
  }

  if (ifsc) {
    paymentDetails.push(
      createPaymentMethodDetailItemProps({
        key: PaymentMethodDetailKey.IFSC,
        label: 'IFSC',
        value: ifsc,
      }),
    );
  }

  return paymentDetails;
};

/**
 * Generates an array of PaymentMethodDetailItem props
 * containing funding details like account numbers, routing numbers, and other international attributes
 */
export const getFundingInfoDetailsItems = (props: PaymentMethodDetailsProps): PaymentMethodDetailItemProps[] => {
  const { fundingAccount, fundingInfoInternational } = props;

  // If the funding account's currency is USD, it could be a SWIFT USD account
  // so we need to check that there is no bicSwift to confirm that this really
  // is a domestic account.
  if (isFundingAccountUSD(fundingAccount) && typeof fundingInfoInternational?.bicSwift !== 'string') {
    return getFundingInfoDomesticDetailsItems(props);
  }

  return getFundingInfoInternationalDetailsItems(fundingInfoInternational);
};

/**
 * Get detail items props for timestamp tooltip if created date is available
 */
export const getAddedByTimestampProps = ({
  fundingAccount,
  partnershipFundingAccount,
}: Partial<PaymentMethodDetailsProps>): Partial<PaymentMethodDetailItemProps> => {
  // RCTM Bank/Balance accounts only have fundingAccount.created dates.
  // While (most) partner funding accounts only have partnershipFundingAccount?.created.
  const createdDate = fundingAccount.created || partnershipFundingAccount?.created;

  // For some reason customer-added bank accounts is the only exemption,
  // since we can't determine the created date for them we don't show timestamp tooltip.
  if (!createdDate) {
    return undefined;
  }

  return {
    actions: [PaymentMethodDetailAction.TOOLTIP],
    tooltipContent: <PaymentMethodFundingAccountAddedOnDate createdDate={createdDate} />,
  };
};

/**
 * Helper that determines if a funding account was added by your team.
 * This helper also works for both External flow and Dashboard users
 */
export const isFundingAccountAddedByYourTeam = ({
  fundingAccount,
  partnershipFundingAccount,
}: Partial<PaymentMethodDetailsProps>): boolean => {
  // Only check isCreatedByPartner if partnershipFundingAccount exist
  if (partnershipFundingAccount) {
    return partnershipFundingAccount.isCreatedByPartner === false;
  }

  // Since not every funding account has a partnershipFundingAccount relationship,
  // we also to check the fundingAccount.created attribute.
  // When looking at your own bank/balance funding account as an RCTM or external user,
  // the fundingAccount will have a created timestamp.
  // This is enough to determine that funding account is indeed created by your team.
  return Boolean(fundingAccount?.created);
};

/**
 * Creates the added-by detail item that helps communicate which user or company created the funding account.
 */
export const createPaymentMethodDetailItemPropsAddedBy = (
  props: Partial<PaymentMethodDetailsProps>,
): PaymentMethodDetailItemProps =>
  createPaymentMethodDetailItemProps({
    ...getAddedByTimestampProps(props),
    key: PaymentMethodDetailKey.ADDED_BY,
    label: 'Added by',
    value: (
      <PaymentMethodDetailsCreatorField
        companyName={props.partnership?.name}
        creator={props.creator}
        isYourTeam={isFundingAccountAddedByYourTeam(props)}
      />
    ),
  });

/**
 * Generates an array of prop inputs for PaymentMethodDetailItem from bank funding information
 */
export const getBankDetailItems = (props: PaymentMethodDetailsProps): PaymentMethodDetailItemProps[] => {
  const { fundingAccount, fundingInfoInternational, showFundingAccountId } = props;
  const paymentDetails: PaymentMethodDetailItemProps[] = [];

  paymentDetails.push(...getFundingInfoDetailsItems(props));

  paymentDetails.push(
    createPaymentMethodDetailItemProps({
      key: PaymentMethodDetailKey.COUNTRY_CURRENCY,
      label: 'Country / Currency',
      value: (
        <PaymentMethodDetailsCountryCurrencyField
          fundingAccount={fundingAccount}
          fundingInfoInternational={fundingInfoInternational}
        />
      ),
    }),
  );

  paymentDetails.push(createPaymentMethodDetailItemPropsAddedBy(props));

  if (showFundingAccountId) {
    paymentDetails.push(
      createPaymentMethodDetailItemProps({
        actions: [PaymentMethodDetailAction.COPY],
        key: PaymentMethodDetailKey.BANK_ACCOUNT_ID,
        label: 'Routable bank account ID',
        value: fundingAccount.id,
      }),
    );
  }

  return paymentDetails;
};

/**
 * Generates an array of prop inputs for PaymentMethodDetailItem from address funding information
 */
export const getAddressDetailItems = (props: PaymentMethodDetailsProps): PaymentMethodDetailItemProps[] => {
  const { fundingInfoAddress } = props;
  const paymentDetails: PaymentMethodDetailItemProps[] = [];

  const accountAddressDetailItem = createPaymentMethodDetailItemProps({
    actions: [PaymentMethodDetailAction.COPY],
    key: PaymentMethodDetailKey.ADDRESS,
    label: 'Mailing address',
    clipboardValue: getAddressAsString(fundingInfoAddress, {
      format: AddressFormat.FULL_COUNTRY,
    }),
    value: (
      <AccountAddress
        {...fundingInfoAddress}
        // hide company & name since they're in the header already
        printCompany={undefined}
        printName={undefined}
      />
    ),
  });

  const addedByDetailItem = createPaymentMethodDetailItemPropsAddedBy(props);

  return paymentDetails.concat(accountAddressDetailItem, addedByDetailItem);
};

/**
 * Generates an array of prop inputs for PaymentMethodDetailItem from balance funding information
 */
export const getBalanceDetailItems = (props: PaymentMethodDetailsProps): PaymentMethodDetailItemProps[] => {
  const { fundingAccount } = props;
  const paymentDetails: PaymentMethodDetailItemProps[] = [];

  paymentDetails.push(
    createPaymentMethodDetailItemProps({
      key: PaymentMethodDetailKey.COUNTRY_CURRENCY,
      label: 'Country / Currency',
      value: <PaymentMethodDetailsCountryCurrencyField fundingAccount={fundingAccount} />,
    }),
  );

  paymentDetails.push(
    createPaymentMethodDetailItemProps({
      actions: [PaymentMethodDetailAction.COPY],
      key: PaymentMethodDetailKey.BALANCE_ID,
      label: 'Balance ID',
      value: fundingAccount.balance,
    }),
  );

  paymentDetails.push(createPaymentMethodDetailItemPropsAddedBy(props));

  return paymentDetails;
};

/**
 * Generates an array of prop inputs for PaymentMethodDetailItem from funding information
 */
export const getItems = (props: PaymentMethodDetailsProps): PaymentMethodDetailItemProps[] => {
  if (isFundingAccountBalance(props.fundingAccount)) {
    return getBalanceDetailItems(props);
  }

  if (isFundingAccountBankAccount(props.fundingAccount)) {
    return getBankDetailItems(props);
  }

  return getAddressDetailItems(props);
};
