import { Action } from 'redux';

import { createItemFormFields } from 'constants/formFields';

import { Attachment } from 'interfaces/attachment';

import { formActionsCreateItem } from './forms';

export const handleUpdateBillViewAttachments = (itemAttachments: Attachment): Action =>
  formActionsCreateItem.change(createItemFormFields.ITEM_BILLS, itemAttachments);

export const removeCreateItemBill = (index: number, field = createItemFormFields.ITEM_BILLS): Action =>
  formActionsCreateItem.arraySplice(field, index, 1);
