import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';

import { ButtonSize } from 'constants/button';

import { ternary } from 'helpers/utility';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import ConditionalRestrictedPermissionTooltip from 'modules/auth/ConditionalRestrictedPermissionTooltip';
import { text } from 'modules/dashboard/settings/account/balance/constants';

/**
 * Button which helps a user fix a negative account balance.
 *
 * @param {ComponentProps} props
 * @param {boolean} [props.isDisabled]
 * @param {function} [props.onFixNegative]
 * @returns {StatelessComponent}
 */
const NegativeBalanceAmountButton = ({ isDisabled, onFixNegative }) => {
  const tooltipProps = ternary(
    isDisabled,
    {},
    {
      arrow: true,
      title: text.balanceAmountBlock.addFundsTooltip,
    },
  );

  return (
    <ConditionalRestrictedPermissionTooltip useTooltip={isDisabled}>
      <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
        <ButtonV2
          isDisabled={isDisabled}
          leftIconClassName="margin-right--xm"
          leftIconName={IconNames.PLUS}
          onClick={onFixNegative}
          size={ButtonSize.MEDIUM}
        >
          {text.balanceAmountBlock.fixNegativeButton}
        </ButtonV2>
      </TooltipMUIConditionalWrapper>
    </ConditionalRestrictedPermissionTooltip>
  );
};

NegativeBalanceAmountButton.propTypes = {
  isDisabled: PropTypes.bool,
  onFixNegative: PropTypes.func,
};

NegativeBalanceAmountButton.defaultProps = {
  isDisabled: undefined,
  onFixNegative: undefined,
};

export default NegativeBalanceAmountButton;
