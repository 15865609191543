import React, { useContext, useEffect } from 'react';

import { ExistingItem } from 'context';

import { LineItemStyles } from 'constants/lineItems';

import { useDiscrepancyContext, useRefreshDiscrepanciesContext } from 'hooks/context';
import { useGetBillDiscrepancies } from 'hooks/purchaseOrders';

import type { LineItemDiscrepancyStatusProps } from './LineItemDiscrepancyStatus.types';
import { LineItemDiscrepancyStatusPresenter } from './LineItemDiscrepancyStatusPresenter';

export const LineItemDiscrepancyStatusForItemDetails = ({
  index: lineItemIndex,
  sectionPath,
}: LineItemDiscrepancyStatusProps) => {
  const item = useContext<Item>(ExistingItem);
  const lineItemStyle = sectionPath?.includes('account') ? LineItemStyles.ACCOUNT : LineItemStyles.ITEM;

  const currentSectionLineItems: LineItem[] = item.lineItems.filter((lineItem) => lineItem.style === lineItemStyle);
  const activeLineItem = currentSectionLineItems[lineItemIndex];
  const lineItemId = activeLineItem?.id;

  const { getDiscrepancyByLineItemId, setDiscrepancies } = useDiscrepancyContext();
  const { isRefreshingDiscrepancies } = useRefreshDiscrepanciesContext();

  const { data: discrepanciesData, isFetching: isFetchingBillDiscrepancies } = useGetBillDiscrepancies({
    id: item.id,
  });
  useEffect(() => {
    if (discrepanciesData?.data) {
      setDiscrepancies(discrepanciesData.data);
    }
  }, [discrepanciesData, setDiscrepancies]);

  const discrepancy = getDiscrepancyByLineItemId(lineItemId);

  return (
    <LineItemDiscrepancyStatusPresenter
      discrepancy={discrepancy}
      isLoading={isFetchingBillDiscrepancies || isRefreshingDiscrepancies}
      purchaseOrderLineItemId={activeLineItem?.purchaseOrderLineItemId}
    />
  );
};
