import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import { CREATE_ADDRESS, UPDATE_ADDRESS } from 'types/addresses';

/**
 * Routine which is used to submit a company address
 */
export const createAddressRoutine = createRoutine(CREATE_ADDRESS);

/**
 * Bound routine which we use to submit the form for adding a company address
 */
export const createAddressHandler = bindRoutineToReduxForm(createAddressRoutine);

/**
 * Routine which is used to update a company's registered address
 */
export const updateAddressRoutine = createRoutine(UPDATE_ADDRESS);

/**
 * Bound routine which we use to submit the form for adding a company address
 */
export const updateAddressHandler = bindRoutineToReduxForm(updateAddressRoutine);
