import { PartnershipRequestTypes } from 'constants/partnershipRequest';

import { isExternalFlowStepNameConfirmation } from 'helpers/external';

export const shouldRedirectToConfirmationUrl = ({
  currentStep,
  isLoading,
  partnershipFundingStateHasDateCompleted,
  redirectUrl,
  requestType,
}: {
  currentStep: ExternalStep;
  isLoading?: boolean;
  partnershipFundingStateHasDateCompleted?: boolean;
  redirectUrl?: string;
  requestType?: ValueOf<typeof PartnershipRequestTypes>;
}): boolean => {
  const isConfirmationStep = isExternalFlowStepNameConfirmation(currentStep);

  // If we're loading, we don't have redirectUrl, the current step
  // is not confirmation step, or we don't have an info about the
  // requestType, we know for a fact that we don't want to redirect
  // and we can exist out early
  if (isLoading || !redirectUrl || !isConfirmationStep || !requestType) {
    return false;
  }

  // If partnership request type is either request W8 or request W9 form,
  // we want to redirect at this point.
  // For any other request type, we only want to redirect if we have a
  // value fo the partnership's funding state date completed property.
  const isRequestTypeTaxForm =
    requestType === PartnershipRequestTypes.REQUEST_W8_FORM || requestType === PartnershipRequestTypes.REQUEST_W9_FORM;
  return isRequestTypeTaxForm ? true : !!partnershipFundingStateHasDateCompleted;
};
