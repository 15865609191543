import React from 'react';
import { useMedia } from 'react-use';

import { Table } from 'complexComponents';

import { Modal, ModalBodyContent, ModalFooter, ModalFooterButtonCancel } from 'components';

import { ButtonSize } from 'constants/button';
import { LineItemStyles } from 'constants/lineItems';
import { mobileMediaQuery } from 'constants/mediaQuery';
import { width } from 'constants/styles';
import { ZIndexLayers } from 'constants/ui';

import { getZIndex } from 'helpers/zIndex';

import {
  InvoicesTableConfig,
  LineItemsExpensesTableConfig,
  LineItemsItemsTableConfig,
} from '../../constants/tableConfig';

import { ExternalReceiptPaymentLineItemsModalProps } from './ExternalReceiptPaymentLineItemsModal.types';

import './ExternalReceiptPaymentLineItemsModal.scss';

const baseOverlay = {
  overlay: { zIndex: getZIndex(ZIndexLayers.MODAL.OVERLAY, 5) },
};
const fullModalOverride = {
  content: { width: width.contentWidth.FULL },
  ...baseOverlay,
};

/**
 * Modal component rendering bill details.
 */
const ExternalReceiptPaymentLineItemsModal = ({
  item,
  modalState,
  onCloseModal,
}: ExternalReceiptPaymentLineItemsModalProps) => {
  const isMobile = useMedia(mobileMediaQuery);
  const overrideStyle = isMobile ? baseOverlay : fullModalOverride;
  const lineItemsWithCurrencyCode =
    item?.lineItems.map((lineItem) => ({
      ...lineItem,
      currencyCode: item.currencyCode,
    })) || [];
  const lineItemsItems = lineItemsWithCurrencyCode.filter(({ style }) => style === LineItemStyles.ITEM);
  const lineItemsExpenses = lineItemsWithCurrencyCode.filter(({ style }) => style === LineItemStyles.ACCOUNT);

  return (
    <Modal
      body={
        <>
          <ModalBodyContent>
            <Table columns={InvoicesTableConfig({ itemKind: item?.kind })} data={item ? [item] : []} />
            {!!lineItemsExpenses.length && (
              <>
                <span className="text-black text-lg font-medium leading-5 pt-7 pb-5 inline-block">Expenses</span>
                <Table columns={LineItemsItemsTableConfig} data={lineItemsExpenses} hasPagination />
              </>
            )}
            {!!lineItemsItems.length && (
              <>
                <span className="text-black text-lg font-medium leading-5 pt-7 pb-5 inline-block">Items</span>
                <Table columns={LineItemsExpensesTableConfig} data={lineItemsItems} hasPagination />
              </>
            )}
          </ModalBodyContent>
          <ModalFooter>
            <div className="width--100-percent content--right">
              <ModalFooterButtonCancel buttonSize={ButtonSize.MEDIUM} buttonText="Close" onCloseModal={onCloseModal} />
            </div>
          </ModalFooter>
        </>
      }
      className="external-receipt-payment-line-items-modal"
      hasInlineFooter
      modalHeader="Details"
      modalState={modalState}
      onCloseModal={onCloseModal}
      overrideStyle={overrideStyle}
    />
  );
};

export default ExternalReceiptPaymentLineItemsModal;
