import React from 'react';

import { LabelLink } from 'components/link/LabelLink/LabelLink.styles';
import { Text, WhiteSpace } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';

import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { getCompanyRouteByPartnershipId } from 'helpers/routeHelpers';

import type { PreventIncompleteContactsCustomerMatchTooltipProps } from './PreventIncompleteContactsCustomerMatchTooltip.types';

/**
 * Tooltip notifying the user that matching international customers is not supported. Link click opens company in new
 * window.
 */
const PreventIncompleteContactsCustomerMatchTooltip = ({
  children,
  isShown,
  onCloseModal,
  partnership,
  placement = TooltipPlacement.RIGHT,
}: PreventIncompleteContactsCustomerMatchTooltipProps): React.ReactElement => {
  const linkToPartnerCompanyPage = getCompanyRouteByPartnershipId(partnership?.id);

  return (
    <TooltipMUIConditionalWrapper
      tooltipProps={
        isShown
          ? {
              arrow: true,
              padding: TooltipPadding.SKINNY,
              placement,
              title: (
                <Text.Regular
                  color={typography.TextColor.GREY_X_DARK}
                  lineHeight={typography.TextLineHeight.MEDIUM}
                  size={typography.TextSize.LEVEL_100}
                >
                  To match a customer, all company contact fields must be complete.
                  {partnership?.id && (
                    <>
                      <WhiteSpace />
                      <LabelLink
                        onClick={() => {
                          onCloseModal();
                          window.open(linkToPartnerCompanyPage, '_blank');
                        }}
                      >
                        Edit company contacts ↗
                      </LabelLink>
                    </>
                  )}
                </Text.Regular>
              ),
            }
          : {}
      }
    >
      {children}
    </TooltipMUIConditionalWrapper>
  );
};

export default PreventIncompleteContactsCustomerMatchTooltip;
