import { useQuery } from '@tanstack/react-query';
import { queryContext } from 'milton/components';

import { purchaseOrderService } from '../purchaseOrder.service';

export const useGetBillDiscrepancies = ({ id }: { id?: string }) =>
  useQuery({
    queryKey: ['purchaseOrders', 'discrepancies', id],
    queryFn: () => purchaseOrderService.getDiscrepancies({ id }),
    enabled: !!id,
    context: queryContext,
  });
