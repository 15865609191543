import React from 'react';
import { useSelector } from 'react-redux';

import { ButtonV2 } from 'components';

import { ButtonSize } from 'constants/button';

import { isPartnershipTypeCustomer } from 'helpers/partnerships';

import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';

import type { AddInternationalPaymentMethodButtonProps } from './AddInternationalPaymentMethodButton.types';

/**
 * Component rendering the Add International Payment Method button.
 * This component is hidden if the partnership type is customer. If the
 * crossborder_create_items_enabled feature flag is turned off, the button
 * is disabled.
 * @param props - Component props
 */
const AddInternationalPaymentMethodButton: React.VFC<AddInternationalPaymentMethodButtonProps> = ({
  onClick,
  partnershipType,
  isDisabled,
}) => {
  const isInternationalPaymentsEnabled = useSelector(currentCompanySettingsIsInternationalPaymentsEnabledSelector);

  const isCustomer = isPartnershipTypeCustomer(partnershipType);

  // We don't want to display the button if the partnership type is customer
  if (isCustomer) {
    return null;
  }

  return (
    <ButtonV2 isDisabled={!isInternationalPaymentsEnabled || isDisabled} onClick={onClick} size={ButtonSize.SMALL}>
      Add a payment method
    </ButtonV2>
  );
};

export default AddInternationalPaymentMethodButton;
