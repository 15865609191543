import { CheckmarkFilled, Undefined, WarningAltFilled } from '@carbon/icons-react';
import { Spinner, Tooltip } from '@routable/gross-ds';
import pluralize from 'pluralize';
import React from 'react';

import type { LineItemDiscrepancyStatusPresenterProps } from './LineItemDiscrepancyStatus.types';

/**
 * Render logic for discrepancy icon component
 */
export const LineItemDiscrepancyStatusPresenter = ({
  discrepancy,
  isLoading,
  isSimilarToDefaultLineItem,
  purchaseOrderLineItemId,
}: LineItemDiscrepancyStatusPresenterProps): JSX.Element => {
  if (isSimilarToDefaultLineItem) {
    return (
      <div>
        <Undefined className="w-5 h-5 text-grey-50" title="none" />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Spinner size={3} />
      </div>
    );
  }

  const hasNoDiscrepancy = !discrepancy && purchaseOrderLineItemId;
  const numOfDiscrepancies = Object.keys(discrepancy || {}).filter(
    (key) => !['bill_line_item_id', 'purchase_order_line_item_id', 'type'].includes(key),
  ).length;

  let tooltip = 'Not found on PO';
  if (hasNoDiscrepancy) {
    tooltip = 'No discrepancies detected';
  } else if (numOfDiscrepancies) {
    tooltip = `${pluralize('Discrepancy', numOfDiscrepancies)} detected`;
  }

  return (
    <Tooltip data-testid="discrepancy-status-tooltip" tooltip={tooltip} variant="light">
      {hasNoDiscrepancy ? (
        <CheckmarkFilled className="w-5 h-5 text-green-40" title="success" />
      ) : (
        <WarningAltFilled className="w-5 h-5 text-gold-40" title="warning" />
      )}
    </Tooltip>
  );
};
