import { getMembershipNameOrEmail } from 'helpers/memberships';
import { capitalize } from 'helpers/stringHelpers';

import { YOUR_TEAM } from '../constants';
import type { PaymentMethodDetailsCreatorFieldProps } from '../PaymentMethodDetailsCreatorField.types';

/**
 * Gets text that communicates who created the payment method
 */
export const getCreatorOrCompanyNameText = ({
  companyName,
  creator,
  isYourTeam,
}: PaymentMethodDetailsCreatorFieldProps): string => {
  const creatorName = getMembershipNameOrEmail(creator);

  if (creatorName) {
    return creatorName;
  }

  if (isYourTeam) {
    return capitalize(YOUR_TEAM);
  }

  return companyName;
};

/**
 * Gets helper text that communicates what team the creator membership belongs to.
 */
export const getTeamRelationText = ({
  companyName,
  creator,
  isYourTeam,
}: PaymentMethodDetailsCreatorFieldProps): string => {
  // We only show helper text if there's a creator,
  // since the helper text clarifies the user's team
  if (!creator) {
    return '';
  }

  if (isYourTeam) {
    return YOUR_TEAM;
  }

  return companyName;
};
