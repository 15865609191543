import React from 'react';

import { BaseHint } from 'components';

import { Intent } from 'constants/ui';

import { InvalidLoginCredentialsHintProps } from './InvalidLoginCredentialsHint.types';

/**
 * Hint to notify the user about invalid login credentials.
 */
const InvalidLoginCredentialsHint: React.FC<InvalidLoginCredentialsHintProps> = ({ errors }) => {
  if (!errors) {
    return null;
  }

  return (
    <BaseHint intent={Intent.DANGER} multiline title="The account details you entered did not match our records" />
  );
};

export default InvalidLoginCredentialsHint;
