import classNames from 'classnames';
import React from 'react';

import { Hint } from 'components/hint';
import { Text, WhiteSpace } from 'components/text';

import { Intent } from 'constants/ui';

import type { InternationalPaymentApprovalsNotSupportedHintProps } from './InternationalPaymentApprovalsNotSupportedHint.types';

/**
 * Component rendering International Payment Approvals not supported Hint
 */
const InternationalPaymentApprovalsNotSupportedHint: React.FC<InternationalPaymentApprovalsNotSupportedHintProps> = ({
  className,
}) => (
  <Hint className={classNames({ [className]: !!className })} intent={Intent.WARNING} multiline>
    <Text.Bold>You can&apos;t approve international payments in Routable (yet)</Text.Bold>
    <Text.Regular>
      Conversion rates fluctuate, so international payment amounts change too.
      <WhiteSpace />
      That means Routable&apos;s approval rules don&apos;t work like they do domestically—but we&apos;re working on it!
    </Text.Regular>
  </Hint>
);

export default InternationalPaymentApprovalsNotSupportedHint;
