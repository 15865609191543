import React from 'react';

import { ButtonV2, FlexRow, LinkNewPage, Text } from 'components';

import { typography } from 'constants/styles';

import { isExternalItemActionable, isExternalItemClickable } from 'helpers/items';
import { getExternalV2ItemSimilarRoute } from 'helpers/routeHelpers';
import { ternary } from 'helpers/utility';

import { Payment } from 'interfaces/redux';
import { TableCellProps } from 'interfaces/table.types';

import './AcceptPaymentTableCell.scss';
import { GenerateAcceptPaymentTableCell } from './AcceptPaymentTableCell.types';

/**
 * Component rendering Invoice Number in External Items Table
 */
export const generateAcceptPaymentTableCell =
  ({ fallbackText, buttonText }: GenerateAcceptPaymentTableCell): React.FC<TableCellProps<Payment>> =>
  ({ row: { original: payment }, value }) => {
    const isPayment = payment.type === 'Payment';
    const url = getExternalV2ItemSimilarRoute(payment, isPayment ? 'payment' : 'item_id');

    const textChildren = (
      <Text.Semibold
        color={ternary(value, typography.TextColor.BLUE_DARK, typography.TextColor.GREY_DARK)}
        size={typography.TextSize.LEVEL_100}
      >
        {value || fallbackText}
      </Text.Semibold>
    );

    return (
      <FlexRow className="align-items--center flex--1 justify-content--space-between" stackOnMobile={false}>
        {isExternalItemClickable(payment) ? (
          <LinkNewPage href={url}>{textChildren}</LinkNewPage>
        ) : (
          <span>{textChildren}</span>
        )}

        {isExternalItemActionable(payment) && (
          <LinkNewPage className="enable-pointer-events" href={url}>
            <ButtonV2>{buttonText}</ButtonV2>
          </LinkNewPage>
        )}
      </FlexRow>
    );
  };

export const AcceptPaymentTableCell = generateAcceptPaymentTableCell({
  fallbackText: 'Not Provided',
  buttonText: 'Accept',
});

AcceptPaymentTableCell.displayName = 'AcceptPaymentTableCell';
