export const LINE_ITEM_MAX_COUNT = 30;
export const LINE_ITEMS_SECTION_NAME = 'line_items';
export const LINE_ITEMS_BLOCKS = {
  ACCOUNT: 'line_items_account_block',
  ITEM: 'line_items_item_block',
} as const;
export const LINE_ITEMS_SUBSECTIONS = {
  ACCOUNT: 'line_items_account',
  ITEM: 'line_items_item',
} as const;

export const INDIVIDUAL_LINE_ITEMS_DISABLED_TOOLTIP_TEXT = `Individual line items are not available for this invoice because it has more than ${LINE_ITEM_MAX_COUNT} line items.\nYou can still add more line items manually.`;
export const INDIVIDUAL_LINE_ITEMS_DISABLED_TOOLTIP_TEXT_SHORT = `Individual line items are not available for this invoice.`;
export const SEPARATE_DISABLED_NO_LINE_ITEMS_TEXT = 'There are no line items to separate.';
export const SUMMARIZE_DISABLED_FOR_LINKED_PO_TOOLTIP_TEXT = `You can't summarize line items when bill lines are linked to PO lines.`;
