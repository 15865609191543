import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { Icon, IconNames } from 'components';
import Spinner from 'components/buttonV2/components/Spinner';

import { DocumentStatuses } from 'constants/document';
import { colors } from 'constants/styles';

import { documentsIsDeleting } from 'selectors/documentsSelectors';

import { File as FileType } from '../FilesList.types';

import { FileItemContainer, FileInput, FileIcon } from './FileItem.styles';
import { FileItemProps } from './FileItem.types';

interface FileProps {
  input?: {
    value: FileType;
  };
  label?: string;
  remove?: (index: number, id?: string) => void;
  index?: number;
  isUploading?: boolean;
  isDisabled?: boolean;
}

export const File: React.FC<FileProps> = ({ input, label, remove, index, isUploading, isDisabled }) => {
  const { isDeleting } = useSelector(documentsIsDeleting);
  const [isDeletingThisDoc, setIsDeletingThisDoc] = React.useState(isDeleting);

  React.useEffect(() => {
    if (!isDeleting) {
      setIsDeletingThisDoc(false);
    }
  }, [isDeleting]);

  const {
    value: {
      id = undefined,
      status = '',
      filename = '',
      // Documents.canDelete = in the context of KYC/KYB (sign up flow), this is when a document has been uploaded to Dwolla
      canDelete = undefined,
    } = {},
  } = input || {};

  const handleClickRemove = () => {
    remove(index, id);
    setIsDeletingThisDoc(true);
  };

  return (
    <>
      {(canDelete || isUploading) && (
        <FileItemContainer uploaded={status === DocumentStatuses.PENDING} uploading={isUploading}>
          <FileInput
            {...input}
            data-fullstory
            placeholder={label}
            readOnly={isUploading}
            uploaded={canDelete || status === DocumentStatuses.PENDING}
            uploading={isUploading}
            value={filename}
          />
          {isUploading && (
            <FileIcon
              color={colors.colorWhiteHex}
              icon={IconNames.TICK_CIRCLE}
              isDeleting={isDeletingThisDoc}
              size={14}
            />
          )}
          {isDeletingThisDoc && <Spinner useBlueVariant />}
          {!isUploading && !isDeletingThisDoc && (
            <button aria-label="remove" disabled={isDisabled} onClick={handleClickRemove} type="button">
              <Icon
                color={isDisabled ? colors.colorGreyMedHex : colors.colorBlueBoldHex}
                icon={IconNames.TRASH}
                size={12}
              />
            </button>
          )}
        </FileItemContainer>
      )}
    </>
  );
};

const FileItem: React.FC<FileItemProps> = ({ remove, file, index, isDisabled }) => (
  <Field
    component={File}
    dataFullStory
    index={index}
    isDisabled={isDisabled}
    name={`${file}`}
    remove={remove}
    type="file"
  />
);

export default FileItem;
