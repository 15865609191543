import React from 'react';

import CreateAccount from 'modules/signup-accountCreation/CreateAccount';
import SelectWorkspace from 'modules/signup-accountCreation/SelectWorkspace';
import VerifyEmail from 'modules/signup-accountCreation/VerifyEmail';

import { formModules } from '../../constants/formModules';

export const formModuleSelector = (formModule: string): JSX.Element => {
  switch (formModule) {
    case formModules.CREATE_ACCOUNT:
      return <CreateAccount />;
    case formModules.SELECT_NAMESPACE:
      return <SelectWorkspace />;
    case formModules.VERIFY_EMAIL:
      return <VerifyEmail />;
    default:
      return <CreateAccount />;
  }
};
