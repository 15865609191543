import { DayjsPrecisionValues } from '@routable/shared';

import { webhookSettingsSubmitRoutine, webhookSettingsTestRoutine } from 'actions/routines/webhooks';

import { isDateBefore } from 'helpers/date';
import { deepMergeWithArrayReplacement } from 'helpers/transform';

import { EmptyObject } from 'interfaces/global';
import { Reducer } from 'interfaces/reducers';
import { WebhookTestData, WebhookTestDataResponse } from 'interfaces/webhooks';

import * as types from 'types/webhooks';

import { ActionProps, GetWebhookTestByIdReturnType, StateProps } from './byIdReducer.types';

/**
 * Reformat results
 */
export const getWebhookTestById = (
  state: StateProps,
  webhookTestData: WebhookTestDataResponse,
): GetWebhookTestByIdReturnType | EmptyObject => {
  if (!webhookTestData.webhookSettingsId) {
    return {};
  }

  const testDataFromState: WebhookTestData = state[webhookTestData.webhookSettingsId];

  // do not update state if the returned results are older than the ones in state
  if (
    testDataFromState &&
    isDateBefore(webhookTestData.executedOn, testDataFromState.executedOn, DayjsPrecisionValues.SECOND)
  ) {
    return {};
  }

  return {
    [webhookTestData.webhookSettingsId]: {
      executedOn: webhookTestData.executedOn,
      id: webhookTestData.webhookSettingsId,
      results: webhookTestData.results,
      status: webhookTestData.status,
    },
  };
};

const byIdReducer: Reducer<StateProps, ActionProps> = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_WEBHOOKS_TEST:
      return deepMergeWithArrayReplacement(state, getWebhookTestById(state, action.payload as WebhookTestDataResponse));

    case types.REMOVE_WEBHOOKS_TEST:
    case webhookSettingsSubmitRoutine.SUCCESS:
    case webhookSettingsTestRoutine.REQUEST:
      return {};

    default:
      return state;
  }
};

export default byIdReducer;
