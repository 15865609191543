import { StaticCountryCode } from 'constants/countries';

import { getCountryNameFromCode } from 'helpers/countries';
import { isFundingAccountUSD } from 'helpers/funding';

import { CountryCode, CountryCodeMap, CountryName } from 'interfaces/countries';
import type { FundingAccount } from 'interfaces/fundingAccount';
import type { FundingInfoInternational } from 'interfaces/fundingInfoInternational';

/**
 * Gets country code from fundingInfoInternational or defaults to the US if domestic account.
 */
export const getInferredCountryCodeFromFundingData = (
  fundingAccount: FundingAccount,
  fundingInfoInternational: FundingInfoInternational,
): CountryCode => {
  if (isFundingAccountUSD(fundingAccount) && !fundingInfoInternational) {
    // RCTM funding accounts don't have a fundingInfoInternational associated with them, so we infer that the country is US.
    return StaticCountryCode.US;
  }

  return fundingInfoInternational?.countryCode;
};

interface PaymentMethodCountryOptions {
  countryCodeMap: CountryCodeMap;
  fundingAccount: FundingAccount;
  fundingInfoInternational?: FundingInfoInternational;
}

/**
 * Given funding account inputs, returns country name.
 */
export const getInferredCountryNameFromFundingData = ({
  countryCodeMap,
  fundingAccount,
  fundingInfoInternational,
}: PaymentMethodCountryOptions): CountryName => {
  const inferredCountryCode = getInferredCountryCodeFromFundingData(fundingAccount, fundingInfoInternational);
  return getCountryNameFromCode(countryCodeMap, inferredCountryCode);
};

/**
 * Given funding account inputs, returns display country and currency text
 */
export const getCountryAndCurrencyText = ({
  countryCodeMap,
  fundingAccount,
  fundingInfoInternational,
}: PaymentMethodCountryOptions): string => {
  const { currencyCode } = Object(fundingAccount);
  const countryName = getInferredCountryNameFromFundingData({
    countryCodeMap,
    fundingAccount,
    fundingInfoInternational,
  });

  return `${countryName} / ${currencyCode}`;
};
