import { connect } from 'react-redux';

import { externalOnboardingDecrementStep } from 'actions/externalOnboarding';

import ExternalBackButton from './ExternalBackButton';

export const mapDispatchToProps = {
  onDecrementStep: externalOnboardingDecrementStep,
};

export default connect(null, mapDispatchToProps)(ExternalBackButton);
