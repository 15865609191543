import {
  requiredValidator,
  hasNumbersValidator,
  hasLettersValidator,
  minCharacterLengthValidator,
} from 'helpers/fieldValidation';

import { MINIMUM_LENGTH } from './passwordRequirementParameters';

export const standardPasswordValidators = [
  requiredValidator,
  hasLettersValidator,
  hasNumbersValidator,
  minCharacterLengthValidator(MINIMUM_LENGTH),
];
