import React from 'react';

import { LEGAL_TOS_URL } from 'constants/platform';

import LinkNewPage from '../LinkNewPage';

interface TermsOfServiceLinkProps {
  className?: string;
  [rest: string]: unknown;
}

const TermsOfServiceLink: React.FC<TermsOfServiceLinkProps> = ({ className, ...rest }) => (
  <LinkNewPage className={className} href={LEGAL_TOS_URL} {...rest}>
    Terms of service
  </LinkNewPage>
);

TermsOfServiceLink.defaultProps = {
  className: undefined,
};

export { TermsOfServiceLinkProps };
export default TermsOfServiceLink;
