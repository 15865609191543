/* eslint-disable camelcase */
import { useEventSync } from '@routable/framework';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce, usePrevious } from 'react-use';

import { PurchaseOrderOption } from 'hooks/purchaseOrders/purchaseOrder.models';

import { FEATURE_FLAG_PO_MATCHING_SHOW_DISCREPANCIES } from 'constants/featureFlags';
import { LineItemStyles } from 'constants/lineItems';

import { getDefaultLineItem, getLineItemsPathFromStyle, isLineItemStyleAccount } from 'helpers/lineItems';
import { differenceWith } from 'helpers/utility';

import { useDiscrepancyContext } from 'hooks/context';
import useFeatureFlag from 'hooks/useFeatureFlag';

import { isLineItemSimilarToDefault } from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/summarizeAndTransfer.helpers';

import { createItemsTableViewModelManagerForKindSelector } from 'queries/tableCompoundSelectors';

import { currentCompanyIsPoMatchingEnabledSelector } from 'selectors/currentCompanySelectors';
import {
  createItemFormAccountLineItemsSelector,
  createItemFormItemLineItemsSelector,
  createItemFormPurchaseOrderSelector,
} from 'selectors/forms';

import { usePurchaseOrderItemData } from '../usePurchaseOrderItemData.hook';

import { useDiscrepancies } from './useDiscrepancies.hook';

// This component will watch any changes to line items and trigger the discrepancy calculation
export const useCalculateDiscrepancies = () => {
  const { setDiscrepancies, setIdsInLoading } = useDiscrepancyContext();
  const { mutate: calculateDiscrepancies } = useDiscrepancies();
  const isPoDiscrepancyEnabled = useFeatureFlag(FEATURE_FLAG_PO_MATCHING_SHOW_DISCREPANCIES);
  const isPoMatchingEnabled = useSelector(currentCompanyIsPoMatchingEnabledSelector);

  const accountLineItems = useSelector(createItemFormAccountLineItemsSelector) || [];
  const itemLineItems = useSelector(createItemFormItemLineItemsSelector) || [];
  const allLineItems = [...accountLineItems, ...itemLineItems];

  const viewModelManager = useSelector(createItemsTableViewModelManagerForKindSelector);
  const accountDefaultLineItem = getDefaultLineItem(
    viewModelManager,
    getLineItemsPathFromStyle(LineItemStyles.ACCOUNT),
  );
  const itemDefaultLineItem = getDefaultLineItem(viewModelManager, getLineItemsPathFromStyle(LineItemStyles.ITEM));
  // filter out empty line items and line items not connected to a po line item
  const nonEmptyLineItems = allLineItems.filter(
    (lineItem) =>
      !isLineItemSimilarToDefault(
        lineItem,
        isLineItemStyleAccount(lineItem) ? accountDefaultLineItem : itemDefaultLineItem,
      ) && !!lineItem.purchase_order_line_item_id,
  );
  const previousLineItems = usePrevious(nonEmptyLineItems);

  const purchaseOrder = useSelector<ReduxFormState, PurchaseOrderOption>(createItemFormPurchaseOrderSelector);

  const results = usePurchaseOrderItemData(purchaseOrder?.value);
  const poLineItems = results?.data?.data?.attributes?.line_items || [];
  const previousPoLineItems = usePrevious(poLineItems);

  const calculateBillDiscrepancies = useCallback(() => {
    const convertedBillLineItems = nonEmptyLineItems.map(
      ({ amount, description, id, purchase_order_line_item_id, quantity, style, unit_price }) => ({
        amount: amount || 0,
        description,
        id: id || purchase_order_line_item_id,
        purchase_order_line_item_id,
        style,
        rate: unit_price || 0,
        quantity: quantity || 0,
      }),
    );

    // TODO [srajchevski]: update conversion here to only fields that are needed
    const convertedPoLineItems = poLineItems.map((poLine) => ({
      ...poLine,
      unbilledAmount: poLine.amount,
      unbilledQuantity: poLine.quantity,
      id: poLine.purchase_order_line_item_id,
      rate: poLine.unit_price,
    }));

    const updatedLineItemsIds = differenceWith(nonEmptyLineItems, previousLineItems).map(
      ({ id, purchase_order_line_item_id }) => id || purchase_order_line_item_id,
    );
    setIdsInLoading(updatedLineItemsIds);

    calculateDiscrepancies(
      {
        // id fields are optional and we do not need them for now
        bill: { id: '', lineItems: convertedBillLineItems },
        purchaseOrder: { id: '', lineItems: convertedPoLineItems },
      },
      {
        onSuccess: (data) => {
          setDiscrepancies(data?.data);
        },
        onSettled: () => {
          setIdsInLoading([]);
        },
      },
    );
  }, [nonEmptyLineItems, poLineItems, previousLineItems, setIdsInLoading, calculateDiscrepancies, setDiscrepancies]);

  const poFields = ['amount', 'purchase_order_line_item_id', 'purchase_order_id', 'quantity', 'unit_price'];

  useEventSync('line:items:transferred', () => {
    calculateBillDiscrepancies();
  });

  useDebounce(
    () => {
      if (!isPoDiscrepancyEnabled || !isPoMatchingEnabled || !nonEmptyLineItems.length || !poLineItems.length) {
        return;
      }

      const hasLengthChanged = nonEmptyLineItems.length !== previousLineItems?.length;
      const hasPoChanged = poLineItems.length !== previousPoLineItems?.length;

      const hasPoFieldChanged = nonEmptyLineItems.some((lineItem) => {
        const previousLineItem = (previousLineItems || []).find(
          (prev) =>
            (prev.id || prev.purchase_order_line_item_id) === (lineItem.id || lineItem.purchase_order_line_item_id),
        );

        if (!previousLineItem) {
          return true;
        }

        return poFields.some((fieldKey) => fieldKey in lineItem && lineItem[fieldKey] !== previousLineItem[fieldKey]);
      });

      if (hasLengthChanged || hasPoChanged || hasPoFieldChanged) {
        calculateBillDiscrepancies();
      }
    },
    300,
    [nonEmptyLineItems, poLineItems],
  );
};
