import clsx from 'clsx';
import React from 'react';

import { EditButtonInput } from 'components';

import { TooltipPadding } from 'constants/tooltip';

import { PartnershipNameSettingsFormFieldProps } from './PartnershipNameSettingsFormProps.types';

const PartnershipNameSettingsFormField: React.VFC<PartnershipNameSettingsFormFieldProps> = ({
  fieldClassName = '',
  isInitialValueLocked = false,
  label,
  name,
  value,
  validate,
}) => {
  const [isFieldDisabled, setIsFieldDisabled] = React.useState(isInitialValueLocked);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onEditButtonClick = () => {
    setIsFieldDisabled((previousValue) => !previousValue);
  };

  React.useEffect(() => {
    if (!isFieldDisabled && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [isFieldDisabled]);

  return (
    <EditButtonInput
      editAllowed
      editField={!isFieldDisabled}
      fieldClassNames={clsx({
        'margin-top--m': true,
        [fieldClassName]: true,
      })}
      isDisabled={isFieldDisabled}
      label={label}
      name={name}
      onEdit={onEditButtonClick}
      setRef={inputRef}
      showField
      staticValue={value}
      tooltipProps={{
        padding: TooltipPadding.SKINNY,
      }}
      type="text"
      validate={validate}
    />
  );
};

export default PartnershipNameSettingsFormField;
