import React from 'react';

import SignUpVisualAid from '../components/SignUpVisualAid';

import { formModuleSelector } from './helpers/formModuleSelector';
import { ModuleWrapperContainer, FormModule, HelperModule } from './ModuleWrapper.styles';
import { ModuleWrapperProps } from './types/ModuleWrapper.types';

const ModuleWrapper: React.VFC<ModuleWrapperProps> = ({ helperModule, formModule }) => (
  <ModuleWrapperContainer>
    <HelperModule>
      <SignUpVisualAid component={helperModule} />
    </HelperModule>
    <FormModule>{formModuleSelector(formModule)}</FormModule>
  </ModuleWrapperContainer>
);

export default ModuleWrapper;
