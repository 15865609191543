import React from 'react';
import { useFormContext } from 'react-hook-form';

import { type TaxFormModel } from '../../TaxFormRenderer.models';

import { fieldGroupMap } from './RenderSection.constants';
import { FieldsSection, FieldsSectionGroups, FieldsSectionTitle } from './RenderSection.styles';
import type { RenderSectionProps } from './RenderSection.types';

const RenderSection = ({ fieldGroups, getTitle, title }: RenderSectionProps) => {
  const { watch } = useFormContext<TaxFormModel>();
  const companyType = watch('companyType');

  const titleText = title || getTitle?.({ companyType });

  return (
    <FieldsSection>
      {!!titleText && <FieldsSectionTitle>{titleText}</FieldsSectionTitle>}
      <FieldsSectionGroups>
        {fieldGroups.map((groupId) => {
          const Component = fieldGroupMap[groupId];

          if (!Component) {
            return null;
          }

          return <Component key={groupId} />;
        })}
      </FieldsSectionGroups>
    </FieldsSection>
  );
};

export default RenderSection;
