import { JsonApiNetwork, Network } from '@routable/core/networking';
import { queryClient } from 'milton/components';
import { all, call, put, select, spawn, take } from 'redux-saga/effects';

import * as actions from 'actions/auth';
import { handleRequestErrors } from 'actions/errors';
import { formActionsChangePassword } from 'actions/forms';
import * as navActions from 'actions/navigation';
import {
  changePasswordRoutine,
  convertExternalRoutine,
  loginRoutine,
  resetPasswordRoutine,
  ssoHandleRedirectRoutine,
  ssoLoginRoutine,
} from 'actions/routines/auth';
import { membershipInviteAcceptV2Routine } from 'actions/routines/inviteTeamMember';
import { disconnectSocket } from 'actions/socket';
import { openNotificationBar } from 'actions/ui';

import { IconNames } from 'components';
import { showSuccessIndicator } from 'components/form/helpers/indicators';

import { AuthTokenComponent } from 'constants/auth';
import { formNamesAuth } from 'constants/forms';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { DASHBOARD, LOGIN } from 'constants/routes';
import { INITIAL_ROUTE_KEY } from 'constants/sessionStorage';
import { Intent, SuccessIndicatorMessages } from 'constants/ui';

import { AuthActionType } from 'enums/auth';
import { AutoLogoutTypes } from 'enums/autoLogout';

import { handleAddAuthTokenAndCurrentIds } from 'helpers/auth';
import { getRequestErrorAction, parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { getCurrentMembershipId, getCurrentUserId, handleClearLocalStorage } from 'helpers/localStorage';
import { getQueryParam } from 'helpers/queryParams';
import { triggerOtherTabsToRefresh } from 'helpers/refreshTabs';
import { asPath, routeAllowedForForwarding } from 'helpers/routeHelpers';
import { storeRouteAsForwardingUrl } from 'helpers/sessionStorage';
import { isObject } from 'helpers/utility';

import { clearLocalStorageOnLogout } from 'modules/auth/Logout/helpers';
import { CustomerServiceHelper } from 'modules/customerSupport/CustomerService';

import { reportError } from 'sagas/errors/api';
import { fetchCurrentUser } from 'sagas/user/api';

import { currentSSOSettingsSelector } from 'selectors/ssoSelectors';

import { FetchService } from 'services';
import { Auth0Client } from 'services/auth0';

import * as api from './api';

/**
 * Accept a membership invite (v2)
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* acceptMembershipInviteV2(action) {
  const { payload } = action;

  yield put(membershipInviteAcceptV2Routine.request());

  let submitErrors = {};

  try {
    const { meta, form } = payload;

    const companySSOSettings = yield select(currentSSOSettingsSelector);

    const { companyId, membershipInviteId, membershipInviteToken } = meta;

    const response = yield call(
      api.acceptMembershipInvite,
      companyId,
      membershipInviteId,
      membershipInviteToken,
      form,
      companySSOSettings,
    );

    if (response.ok) {
      if (isObject(companySSOSettings) && !form.password) {
        yield put(
          ssoLoginRoutine.trigger({
            companyId: companySSOSettings.company,
            ssoProvider: companySSOSettings.ssoProvider,
            ssoProviderConnection: companySSOSettings.ssoProviderConnection,
          }),
        );
      } else {
        yield put(membershipInviteAcceptV2Routine.success(response.data));
      }
      return;
    }

    submitErrors = parseErrorResponse(response, { formPath: 'form' });
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(membershipInviteAcceptV2Routine.failure, submitErrors));
}

/**
 * Convert an external user to a registered dashboard user.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* convertExternalToRegistered(action) {
  const { payload } = action;

  yield put(convertExternalRoutine.request());

  let submitErrors = {};

  try {
    const response = yield call(api.convertExternalToRegistered, payload.values);

    if (response.ok) {
      yield put(convertExternalRoutine.success(response.data));
      return;
    }

    submitErrors = parseErrorResponse(response);
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(convertExternalRoutine.failure, submitErrors));
}

/**
 * User login.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* login(action) {
  const { payload } = action;

  yield put(loginRoutine.request());

  let submitErrors = {};

  try {
    const response = yield call(api.login, payload.form);

    if (response.ok) {
      yield put(loginRoutine.success(response.data));
      return;
    }

    submitErrors = parseErrorResponse(response, {
      formPath: 'form',
      muteAlerts: true,
    });
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(loginRoutine.failure, submitErrors));
}

/**
 * SSO User login.
 * This will call Auth0's loginWithRedirect sending the user to the IdP and redirecting back to Routable once
 * the user logs in through the IdP
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* ssoLogin({ payload }) {
  yield put(ssoLoginRoutine.request());

  let submitErrors = {};
  const { userEmail, companyId, companyName, ssoProviderConnection, isAuthenticatedWithSSO, shouldRefreshOtherTabs } =
    payload;

  // List of login options can be found here: https://auth0.github.io/auth0-spa-js/interfaces/redirectloginoptions.html
  const auth0Props = isAuthenticatedWithSSO ? { login_hint: userEmail } : { prompt: 'login', login_from: 'ui' };

  // Adding custom parameter `company_id`, which is used for creating AuthEventLog records in the BE
  auth0Props.company_id = companyId;

  if (shouldRefreshOtherTabs) {
    yield call(triggerOtherTabsToRefresh);
  }

  try {
    yield Auth0Client.loginWithRedirect({
      appState: {
        companyId,
        companyName,
      },
      authorizationParams: {
        connection: ssoProviderConnection,
        ...auth0Props,
      },
    });
    return;
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(ssoLoginRoutine.failure, submitErrors));
}

/**
 * SSO handle redirect from auth0.
 * Ensures that errors are handled and we hydrate the app with the necessary info
 * after the user comes back from the auth0 flow.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* ssoHandleRedirect({ payload }) {
  yield put(ssoHandleRedirectRoutine.request());

  let submitErrors = {};
  let response;
  const options = payload?.options || {};

  try {
    const accessToken = yield Auth0Client.getAccessTokenSilently(options);
    response = yield call(fetchCurrentUser, {
      overrideHeaders: {
        'X-Company-Id': payload.companyId,
        Authorization: `${AuthTokenComponent.JWT_AUTH} ${accessToken}`,
      },
    });

    if (response.ok) {
      yield put(ssoHandleRedirectRoutine.success(response.data));
      return;
    }
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(ssoHandleRedirectRoutine.failure, submitErrors));

  let errorNotificationText = 'Something went wrong. Please try again.';
  if (response && FetchService.isResponseNotAuthorized(response)) {
    errorNotificationText = `${
      Auth0Client?.user?.email || 'The provided email address'
    } is not associated with this workspace. Please contact your ${PLATFORM_DISPLAY_SHORT_NAME} admin.`;
  }
  // open notification bar after dispatching errorAction because action:HANDLE_REQUEST_ERRORS closes notifcation bar
  yield put(
    openNotificationBar(errorNotificationText, {
      icon: IconNames.INFO_SIGN,
      intent: Intent.DANGER,
    }),
  );

  clearLocalStorageOnLogout();

  // User could be authenticated in auth0 with their google account. So logging out here to make sure we clear their auth0 credentials
  Auth0Client.logout({ openUrl: false });
  // removes auth0 query params
  window.history.replaceState({}, document.title, window.location.pathname);
}

/**
 * Handle successful authentication.
 * @param {Object} action
 * @param {boolean} useNextUrl
 * @return {IterableIterator<*>}
 */
export function* handleAuthSuccess(action, useNextUrl) {
  const {
    payload: { meta },
  } = action;

  yield call(handleAddAuthTokenAndCurrentIds, meta);

  let nextUrl = asPath(DASHBOARD);
  if (useNextUrl && window.sessionStorage) {
    // grab the initially loaded route from session storage
    const initRoute = sessionStorage.getItem(INITIAL_ROUTE_KEY);

    // set next url to the stored value, if it is an allowed route for forwarding
    if (initRoute && routeAllowedForForwarding(initRoute)) {
      nextUrl = initRoute;
    }
    // remove the next route item
    sessionStorage.removeItem(INITIAL_ROUTE_KEY);
  }

  yield put(ssoLoginRoutine.success());
  // redirect to next URL
  yield put(navActions.pushHistory(decodeURIComponent(nextUrl)));
}

/**
 * Cleanup after user logout.
 * @return {IterableIterator<*>}
 */
export function* logoutCleanup() {
  yield call(CustomerServiceHelper.logout);
  yield put(disconnectSocket());
  yield call(handleClearLocalStorage);
  yield call(() => queryClient.clear());

  // since we just cleared local storage, should save the next route if it exists
  const nextRoute = getQueryParam('next');
  if (nextRoute) {
    storeRouteAsForwardingUrl(nextRoute);
  }

  Network.refreshToken();
  JsonApiNetwork.refreshToken();
}

const getForcedLogoutMessage = (reason) => {
  if (reason === AutoLogoutTypes.INACTIVITY) {
    return 'You have been logged out due to inactivity.';
  }

  return 'For security reasons, you have been automatically logged out.';
};

/**
 * User logout.
 * @return {IterableIterator<*>}
 */
export function* logout() {
  const isForcedLogout = localStorage.getItem('forced-logout');
  const forcedLogoutReason = localStorage.getItem('forced-logout-reason');
  try {
    if (!isForcedLogout || (isForcedLogout && isForcedLogout === 'false')) {
      const response = yield call(api.logout);
      if (!response.ok) {
        yield put(handleRequestErrors(actions.logoutFailure, parseErrorResponse(response)));
        yield call(
          reportError,
          {
            name: 'State Error',
            message: 'Unexpected error response from server',
          },
          {
            context: 'sagas/auth/sagas.js - logout',
            device: { browser: navigator.userAgent },
            user: { membershipId: getCurrentMembershipId() },
            severity: 'warning',
          },
        );
      }
    }
  } catch (error) {
    yield put(handleRequestErrors(actions.logoutFailure, parseCaughtError(error)));
  } finally {
    yield put(actions.logoutComplete());
    yield* logoutCleanup();
    // Yes we have to check both js sagas are stupid and casting will throw an error.
    if (isForcedLogout && isForcedLogout === 'true') {
      const message = getForcedLogoutMessage(forcedLogoutReason);

      yield put(
        openNotificationBar(message, {
          icon: IconNames.WARNING_SIGN,
          intent: Intent.WARNING,
        }),
      );
    } else {
      yield put(
        openNotificationBar(`You've successfully logged out.`, {
          icon: IconNames.TICK_CIRCLE,
          intent: Intent.SUCCESS,
        }),
      );
    }
    yield put(navActions.pushHistory(asPath(LOGIN)));
  }
}

/**
 * User logout from Auth0 SSO provider by clearing local Auth0 session.
 * @return {IterableIterator<*>}
 */
export function* ssoLogout() {
  const isForcedLogout = localStorage.getItem('forced-logout');
  const forcedLogoutReason = localStorage.getItem('forced-logout-reason');
  try {
    yield Auth0Client.logout({ openUrl: false });
    yield put(navActions.pushHistory(asPath(LOGIN)));
    yield put(actions.logoutComplete());

    if (isForcedLogout === 'true') {
      const message = getForcedLogoutMessage(forcedLogoutReason);

      yield put(
        openNotificationBar(message, {
          icon: IconNames.WARNING_SIGN,
          intent: Intent.WARNING,
        }),
      );
    } else {
      yield put(
        openNotificationBar(`You've successfully logged out.`, {
          icon: IconNames.TICK_CIRCLE,
          intent: Intent.SUCCESS,
        }),
      );
    }
  } catch (error) {
    yield put(actions.logoutComplete());
    yield put(handleRequestErrors(actions.logoutFailure, parseCaughtError(error)));
  } finally {
    yield* logoutCleanup();
  }
}

/**
 * Determines if logout is through Auth0 or backend
 * @return {IterableIterator<*>}
 */
export function* logoutHandler() {
  if (Auth0Client?.isAuthenticated) {
    yield call(ssoLogout);
  } else {
    yield call(logout);
  }
}

/**
 * Reset password.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* resetPassword(action) {
  const { payload } = action;

  yield put(resetPasswordRoutine.request());

  let submitErrors = {};

  try {
    const response = yield call(api.resetPassword, payload.form);

    if (response.ok) {
      yield put(resetPasswordRoutine.success(response.data));
      yield put(navActions.pushHistory(asPath(LOGIN)));
      yield put(
        openNotificationBar(
          `Thanks! If a Routable account exists for ${payload.form.email}, we'll send you an email on how to reset your password.`,
          {
            icon: IconNames.TICK_CIRCLE,
            intent: Intent.SUCCESS,
          },
        ),
      );

      return;
    }

    submitErrors = parseErrorResponse(response, { formPath: 'form' });
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(resetPasswordRoutine.failure, submitErrors));
}

/**
 * Reset password.
 * @param {Object} action
 * @return {IterableIterator<*>}
 */
export function* changePassword(action) {
  const { payload } = action;

  yield put(changePasswordRoutine.request());

  let submitErrors = {};

  try {
    const userId = getCurrentUserId();
    const { form, meta } = payload;

    const { removeAuthToken, shouldRedirectOnSuccess, showIndicatorOnSuccess, showNotificationBarOnSuccess } = meta;

    const response = yield call(api.changePassword, userId, form, {
      removeAuthToken,
    });

    if (response.ok) {
      yield all([put(changePasswordRoutine.success(response.data)), put(formActionsChangePassword.reset())]);

      // this is used when we change the password from user settings
      if (showIndicatorOnSuccess) {
        showSuccessIndicator(SuccessIndicatorMessages.PASSWORD_CHANGE_SUCCESS, {
          id: formNamesAuth.CHANGE_PASSWORD,
        });
      }

      // this is used when we reset the password from the forgot your password screen
      if (showNotificationBarOnSuccess) {
        yield put(
          openNotificationBar(SuccessIndicatorMessages.PASSWORD_RESET_SUCCESS, {
            icon: IconNames.TICK_CIRCLE,
            intent: Intent.SUCCESS,
          }),
        );
      }

      if (shouldRedirectOnSuccess) {
        yield put(navActions.pushHistory(asPath(LOGIN)));
      }

      return;
    }

    submitErrors = parseErrorResponse(response, { formPath: 'form' });
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(changePasswordRoutine.failure, submitErrors));
}

/**
 * Listens for redux actions related to authentication.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([
      changePasswordRoutine.TRIGGER,
      convertExternalRoutine.TRIGGER,
      convertExternalRoutine.SUCCESS,
      loginRoutine.SUCCESS,
      loginRoutine.TRIGGER,
      ssoLoginRoutine.TRIGGER,
      membershipInviteAcceptV2Routine.TRIGGER,
      membershipInviteAcceptV2Routine.SUCCESS,
      resetPasswordRoutine.TRIGGER,
      AuthActionType.SUBMIT_LOGOUT_REQUEST,
      AuthActionType.SUBMIT_LOGOUT_CLEANUP,
      ssoHandleRedirectRoutine.TRIGGER,
      ssoHandleRedirectRoutine.SUCCESS,
    ]);

    switch (action.type) {
      case changePasswordRoutine.TRIGGER:
        yield spawn(changePassword, action);
        break;

      case convertExternalRoutine.TRIGGER:
        yield spawn(convertExternalToRegistered, action);
        break;

      case loginRoutine.TRIGGER:
        yield spawn(login, action);
        break;

      case loginRoutine.SUCCESS:
      case ssoHandleRedirectRoutine.SUCCESS:
        yield spawn(handleAuthSuccess, action, true);
        break;

      case ssoLoginRoutine.TRIGGER:
        yield spawn(ssoLogin, action);
        break;

      case membershipInviteAcceptV2Routine.TRIGGER:
        yield spawn(acceptMembershipInviteV2, action);
        break;

      case convertExternalRoutine.SUCCESS:
      case membershipInviteAcceptV2Routine.SUCCESS:
        yield spawn(handleAuthSuccess, action, false);
        break;

      case resetPasswordRoutine.TRIGGER:
        yield spawn(resetPassword, action);
        break;

      case AuthActionType.SUBMIT_LOGOUT_REQUEST:
        yield spawn(logoutHandler);
        break;

      case AuthActionType.SUBMIT_LOGOUT_CLEANUP:
        yield spawn(logoutCleanup);
        break;

      case ssoHandleRedirectRoutine.TRIGGER:
        yield spawn(ssoHandleRedirect, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root auth saga.
 * @return {IterableIterator<*>}
 */
export default function* auth() {
  yield watch();
}
