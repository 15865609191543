import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';

import { fetchSingleItemRequest } from 'actions/item';
import { fetchItemsRoutine } from 'actions/routines/item';

import { TABLE_DEFAULT_PAGE_SIZE } from 'constants/tables';

import { getApplyFiltersAction, isItemIdFilter, parseFiltersFromUrl } from 'helpers/itemFilters';
import { LogLevel, systemLogger } from 'helpers/systemLogger';

import { UIFilterList } from 'interfaces/itemFilters';

import {
  isFetchingItemSelector,
  itemsArraySelector,
  itemsErrorsSelector,
  itemsPaginationSelector,
} from 'selectors/itemsSelectors';

import { SetActivePage, SetPageSize } from 'types/UseApi';

import { BillsResultSet, UseBills } from './useBills.types';

// this is done like this to help make it easy to detect no changes have happened
const EMPTY_ARRAY = [];

export const generateUseBills =
  (getFilters: typeof parseFiltersFromUrl): UseBills =>
  ({ uniqueId, defaultPageSize = TABLE_DEFAULT_PAGE_SIZE, defaultFilters = EMPTY_ARRAY, skipInitialCall }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(isFetchingItemSelector);
    const error = useSelector(itemsErrorsSelector);
    const data = useSelector(itemsArraySelector);
    const { current, next, prev, count, offset, page, pages, pageSize, limit } = useSelector(itemsPaginationSelector);

    const [storedPageSize, setStoredPageSize] = useState(defaultPageSize);

    const loadPage = useCallback(
      (number, size) => {
        systemLogger.log({
          level: LogLevel.DEBUG,
          message: 'useBill loadPage',
          pageNumber: number,
          pageSize: size,
        });
        dispatch(
          fetchItemsRoutine.trigger({
            page: {
              number,
              size,
            },
          }),
        );
      },
      [dispatch],
    );

    const gotoPage: SetActivePage = (destinationPage) => {
      switch (destinationPage) {
        case 'PREV':
          dispatch(fetchItemsRoutine.trigger({ url: prev }));
          break;
        case 'NEXT':
          dispatch(fetchItemsRoutine.trigger({ url: next }));
          break;
        default:
          loadPage(destinationPage, storedPageSize);
      }
    };

    const setFilter: BillsResultSet['setFilter'] = useCallback(
      ({ filters, ...params }) => {
        const singleIdFilter = filters.find(isItemIdFilter);
        systemLogger.log({
          level: LogLevel.DEBUG,
          message: 'Bill Filter updated',
          filters,
          params,
        });
        if (singleIdFilter) {
          // if the filters are for a single item, we will call the single item endpoint, and skip other filters
          dispatch(fetchSingleItemRequest(singleIdFilter.value));
        } else {
          // uses filter call to set initial call for the call.
          // linked with uniqueId to be able to still change if a new unique Id is set
          dispatch(
            getApplyFiltersAction(uniqueId)(filters as UIFilterList, {
              page: { size: storedPageSize },
              ...params,
            }),
          );
        }
      },
      [storedPageSize, dispatch, uniqueId],
    );

    const refresh = () => {
      dispatch(fetchItemsRoutine.trigger({ url: current }));
    };

    const setPageSize: SetPageSize = (size) => {
      setStoredPageSize(size);
      loadPage(1, size);
    };

    // gets the unique set of filters from arguments and from external source
    const activeFilters = Array.from(new Set([...defaultFilters, ...getFilters()]));

    useEffectOnce(() => {
      // uses filter call to set initial call for the call.
      // linked with uniqueId to be able to still change if a new unique Id is set
      if (!skipInitialCall) {
        setFilter({ filters: activeFilters });
      }
    });

    return {
      isLoading,
      isError: !!error,
      error,
      data,
      pagination: {
        count,
        offset,
        page,
        pages,
        pageSize,
        limit,
      },
      gotoPage,
      setPageSize,
      refresh,
      activeFilters,
      setFilter,
    };
  };

export const useBills = generateUseBills(parseFiltersFromUrl);
