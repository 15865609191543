import React from 'react';

import { CountryFlag } from 'components';

import { getCountryNameFromCode } from 'helpers/countries';

import { useCountryCodesContext } from 'hooks';

import TableCellText from '../TableCellText';

import { CountryCellProps } from './CountryCell.types';

import './CountryCell.scss';

/**
 * Renders table cell with country flag and full country name
 */
const CountryCell: React.FC<CountryCellProps> = ({ rowData }) => {
  const countryCodeMap = useCountryCodesContext();

  return (
    <div className="display--flex align-items--center width--100-percent">
      <CountryFlag className="country-cell--flag" countryCode={rowData.country} />

      <TableCellText className="truncate width--100-percent" dataTestId="country-cell--name">
        {getCountryNameFromCode(countryCodeMap, rowData.country)}
      </TableCellText>
    </div>
  );
};

export default CountryCell;
