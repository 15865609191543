import React from 'react';

import { QuickswitchActionCreator, Membership } from 'types/quickswitch';

import { colors } from '../../constants/styles';
import IsLoading from '../isLoading/IsLoading';

import { membershipListIsEmpty, removeCurrentMembershipFromList } from './helpers';
import QuickswitchMembershipList from './QuickswitchMembershipList';
import QuickswitchModalEmptySearchResults, {
  QuickswitchModalEmptySearchResultsProps,
} from './QuickswitchModalEmptySearchResults/QuickswitchModalEmptySearchResults';

export interface QuickswitchModalContentStateProps {
  memberships: Membership[];
  loadingMembershipsList: boolean;
}

export interface QuickswitchModalContentDispatchProps {
  executeSwitchMembership: (membershipId: string) => QuickswitchActionCreator;
}

type QuickswitchModalContentProps = QuickswitchModalContentStateProps &
  QuickswitchModalContentDispatchProps &
  QuickswitchModalEmptySearchResultsProps;

const QuickswitchModalContent: React.FC<QuickswitchModalContentProps> = ({
  loadingMembershipsList,
  memberships,
  executeSwitchMembership,
  onUndoSearchClick,
}) => {
  const membershipData = removeCurrentMembershipFromList(memberships);
  if (loadingMembershipsList) {
    return (
      <div className="margin-bottom--m margin-top--m">
        <IsLoading color={colors.colorBlueBoldHex} />
      </div>
    );
  }
  if (membershipListIsEmpty(membershipData)) {
    return <QuickswitchModalEmptySearchResults onUndoSearchClick={onUndoSearchClick} />;
  }
  return <QuickswitchMembershipList memberships={membershipData} onSwitchMembership={executeSwitchMembership} />;
};

export default QuickswitchModalContent;
