import React from 'react';
import { useSelector } from 'react-redux';

import DashboardToDashboardRestrictionTooltipContent from 'components/dashboardToDashboardRestrictionTooltipContent';

import { isPartnershipMemberStatusArchived } from 'helpers/contacts';
import {
  getPartnershipPermissionResourceForArchiveAction,
  getPartnershipPermissionResourceForEditAction,
} from 'helpers/partnerships';

import { useGrossDsPermissions } from 'hooks';

import { partnershipFromPropsSelector } from 'selectors/partnershipsSelectors';

import { ContactCellsProps } from '../ContactCellsTypes.types';

import {
  ActiveContactActionsMenu,
  ArchivedContactActionsMenu,
} from './components/ContactActionsCellMenuItems/components';

/**
 * Menu popover dots containing actions the user can take on a contact. The actions differ based on the contact's
 * status—archived, active, self-managed etc.
 */
const ContactActionsCell = ({ rowData }: ContactCellsProps) => {
  const { managed, status, ...rest } = rowData;
  const partnership = useSelector(partnershipFromPropsSelector);
  const archivePermissions = [getPartnershipPermissionResourceForArchiveAction(partnership)];
  const canArchive = useGrossDsPermissions(archivePermissions);
  const editPermissions = [getPartnershipPermissionResourceForEditAction(partnership)];
  const canEdit = useGrossDsPermissions(editPermissions);

  if (managed) {
    const tooltipProps = {
      hasPermission: false,
      tooltipProps: {
        tooltip: <DashboardToDashboardRestrictionTooltipContent />,
        'data-testid': 'tooltip--dashboard-to-dashboard-restriction',
      },
    };

    return <ActiveContactActionsMenu {...rowData} canArchive={tooltipProps} canEdit={tooltipProps} />;
  }

  if (isPartnershipMemberStatusArchived(status)) {
    return <ArchivedContactActionsMenu {...rest} canArchive={canArchive} />;
  }

  return <ActiveContactActionsMenu {...rowData} canArchive={canArchive} canEdit={canEdit} />;
};

export default ContactActionsCell;
