import React from 'react';

import CrossBorderPaymentsNotEnabledTooltip from 'complexComponents/tooltipTypes/CrossBorderPaymentsNotEnabledTooltip';
import CurrencyNotEnabledOnLedgerTooltip from 'complexComponents/tooltipTypes/CurrencyNotEnabledOnLedgerTooltip';
import CurrencyNotSupportedByPlatformTooltip from 'complexComponents/tooltipTypes/CurrencyNotSupportedByPlatformTooltip';
import LimitedMatchingToBaseCurrencyTooltip from 'complexComponents/tooltipTypes/LimitedMatchingToBaseCurrencyTooltip';
import MatchOnlyDomesticVendorsWithCustomersTooltip from 'complexComponents/tooltipTypes/MatchOnlyDomesticVendorsWithCustomersTooltip';
import PreventInternationalCustomerMatchTooltip from 'complexComponents/tooltipTypes/PreventInternationalCustomerMatchTooltip';

import { MultiLineOptionForSearchCompanies } from 'components/selectV2/components';

import { getCustomerOrVendorOrBothTextByPartnership, getPartnershipType } from 'helpers/partnerships';

import { checkPartnershipEligibilityForMatchCompanies } from '../../helpers/logic';
import { getDetailsTextForMatchCompanies } from '../../helpers/text';

import type { MultiLineOptionWithTooltipForMatchCompaniesProps } from './MultiLineOptionWithTooltipForMatchCompanies.types';

/**
 * Renders multi line option for match companies select wrapped in a conditional tooltip
 */
const MultiLineOptionWithTooltipForMatchCompanies = ({
  companyIntegrationSettings,
  companySettings,
  data,
  ledger,
  platformPartnership,
  supportedCurrencies,
  ...rest
}: MultiLineOptionWithTooltipForMatchCompaniesProps): React.ReactElement => {
  const {
    isCrossBorderDisabledTooltipIsShown,
    isCurrencyMismatchTooltipShown,
    isCurrencyNotSupportedByPlatformTooltipShown,
    isInternationalCustomerTooltipShown,
    isLimitedMatchingTooltipShown,
    isMatchDomesticPartnershipsTooltipShown,
  } = checkPartnershipEligibilityForMatchCompanies({
    companyIntegrationSettings,
    companySettings,
    ledger,
    partnership: data,
    platformPartnership,
    supportedCurrencies,
  });

  const ledgerName = ledger?.name;

  const customerOrVendorText = getCustomerOrVendorOrBothTextByPartnership(data);
  const detailsText = getDetailsTextForMatchCompanies({
    isCrossBorderDisabledTooltipIsShown,
    isCurrencyMismatchTooltipShown,
    isCurrencyNotSupportedByPlatformTooltipShown,
    isInternationalCustomerTooltipShown,
    isLimitedMatchingTooltipShown,
    isMatchDomesticPartnershipsTooltipShown,
    ledger,
    partnership: data,
    platformPartnershipCurrency: platformPartnership.currencyCodePartner,
  });

  return (
    <LimitedMatchingToBaseCurrencyTooltip isShown={isLimitedMatchingTooltipShown} ledger={ledger}>
      <PreventInternationalCustomerMatchTooltip isShown={isInternationalCustomerTooltipShown}>
        <CrossBorderPaymentsNotEnabledTooltip isShown={isCrossBorderDisabledTooltipIsShown}>
          <MatchOnlyDomesticVendorsWithCustomersTooltip
            isShown={isMatchDomesticPartnershipsTooltipShown}
            ledgerName={ledger.name}
          >
            <CurrencyNotSupportedByPlatformTooltip
              currencies={data.ledgerCurrencies}
              isShown={isCurrencyNotSupportedByPlatformTooltipShown}
              partnershipType={getPartnershipType(data)}
            >
              <CurrencyNotEnabledOnLedgerTooltip
                currencyCode={platformPartnership.currencyCodePartner}
                isRelatedToPartnership
                isShown={isCurrencyMismatchTooltipShown}
                ledgerName={ledgerName}
                partnershipType={customerOrVendorText}
              >
                <MultiLineOptionForSearchCompanies
                  {...rest}
                  data={{
                    ...data,
                    details: data.isDisabled && detailsText,
                  }}
                />
              </CurrencyNotEnabledOnLedgerTooltip>
            </CurrencyNotSupportedByPlatformTooltip>
          </MatchOnlyDomesticVendorsWithCustomersTooltip>
        </CrossBorderPaymentsNotEnabledTooltip>
      </PreventInternationalCustomerMatchTooltip>
    </LimitedMatchingToBaseCurrencyTooltip>
  );
};

export default MultiLineOptionWithTooltipForMatchCompanies;
