import React from 'react';
import { useHistory, matchPath } from 'react-router-dom';

import { ButtonV2, DashboardToDashboardW9RestrictionTooltipContent, TooltipMUIConditionalWrapper } from 'components';
import { Hint } from 'components/hint';
import { Text } from 'components/text';

import { ButtonSize } from 'constants/button';
import PermissionResourceAction from 'constants/permissions';
import { COMPANIES_ROUTE, SELECTED_COMPANY_TABS } from 'constants/routes';
import { TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';

import { isPartnershipRoutableToRoutable } from 'helpers/partnerships';
import { getJoinedPath } from 'helpers/routeHelpers';

import { usePermissions } from 'hooks';

import { AddTaxFormButton } from 'modules/dashboard/company/companyTaxInfo/components';
import { AddTaxFormButtonDisplayType } from 'modules/dashboard/company/companyTaxInfo/components/AddTaxFormButton/AddTaxFormButton.enums';

import type { RequestContactForTaxesHintProps, MatchParamsFromUrlProps } from './RequestContactForTaxesHint.types';

/**
 * Returns hint notifying the user the need to add a contact for taxes and redirecting them
 */
const RequestContactForTaxesHint = ({ partnership }: RequestContactForTaxesHintProps): JSX.Element => {
  const history = useHistory();
  const permissions = usePermissions([PermissionResourceAction.VENDOR_EDIT]);

  const handleClick = () => {
    const match: MatchParamsFromUrlProps = matchPath(history.location.pathname, {
      path: `${COMPANIES_ROUTE}/:filter/:id/:tab`,
    });

    const path = getJoinedPath(COMPANIES_ROUTE, match.params.filter, partnership.id, SELECTED_COMPANY_TABS.ABOUT);

    history.push(path);
  };

  const isSelfManaged = isPartnershipRoutableToRoutable(partnership);
  const isButtonDisabled = isSelfManaged || !permissions.hasPermission;

  let tooltipProps;

  if (isSelfManaged) {
    tooltipProps = {
      arrow: true,
      title: (
        <DashboardToDashboardW9RestrictionTooltipContent actionText=" to add contact details and request W-9 info" />
      ),
    };
  } else if (!permissions.hasPermission) {
    tooltipProps = { ...permissions.tooltipProps, padding: undefined };
  }

  return (
    <Hint className="margin--m" intent={Intent.WARNING} shouldRemoveBackgroundColor>
      <Text.Bold className="margin-bottom--xs" color="var(--color-blue70)" size={TextSize.LEVEL_200}>
        {`You don't have tax information recorded for this vendor`}
      </Text.Bold>

      <p className="hint--content" style={{ color: 'var(--color-blue70)' }}>
        Add a company contact with a reachable email to request a tax form from your vendor.
      </p>

      <div className="flex-row items-center">
        <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
          <ButtonV2
            className="margin-bottom--sm margin-top--sm margin-right--large"
            intent={Intent.NEUTRAL}
            isDisabled={isButtonDisabled}
            onClick={handleClick}
            size={ButtonSize.MEDIUM}
          >
            Go to contacts
          </ButtonV2>
        </TooltipMUIConditionalWrapper>
        <AddTaxFormButton displayType={AddTaxFormButtonDisplayType.LARGE} />
      </div>
    </Hint>
  );
};

export default RequestContactForTaxesHint;
