import { StatusIndicator } from '@routable/gross-ds';
import React from 'react';

import { ExternalBlock, ExternalBlockItem } from 'modules/external/ExternalBlocksSection';

import { ExternalReceiptItemStatusProps } from './ExternalReceiptItemStatus.types';

/**
 * Component rendering External Receipt Single Item status
 */
const ExternalReceiptItemStatus: React.FC<ExternalReceiptItemStatusProps> = ({ status, statusText, text }) => (
  <ExternalBlock>
    <ExternalBlockItem>
      <StatusIndicator className="text-sm" status={status}>
        {statusText}
      </StatusIndicator>

      <span className="text-grey-60 text-sm">{text}</span>
    </ExternalBlockItem>
  </ExternalBlock>
);

export default ExternalReceiptItemStatus;
