import React from 'react';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

/**
 * This component informs RCTMs that there are side-effects if they create or take action on an item without contacts.
 */
const ItemWithoutContactsHint: React.FC = () => (
  <BaseHint
    intent={Intent.INFO}
    title="If you don't add a contact, this vendor won't be notified when you send a payment."
  />
);

export default ItemWithoutContactsHint;
