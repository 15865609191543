import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { createSidePanelSelector } from 'selectors/sidePanelsSelector';

const initialState = { open: false, partnershipId: undefined };

const addTaxFormSidePanel = createSlice({
  name: 'addTaxFormSidePanel',
  initialState,
  reducers: {
    closeAddTaxFormSidePanel: (draft) => {
      draft.open = false;
      draft.partnershipId = undefined;
    },
    openAddTaxFormSidePanel: (draft, action?: PayloadAction<{ partnershipId?: string }>) => {
      draft.open = true;
      draft.partnershipId = action.payload.partnershipId;
    },
  },
});

export const addTaxFormSidePanelSelector = createSidePanelSelector(addTaxFormSidePanel.name);

export const {
  name,
  actions: { openAddTaxFormSidePanel, closeAddTaxFormSidePanel },
  reducer,
} = addTaxFormSidePanel;
