import { apiCall, type ApiCall } from '@routable/framework';

import { CountriesApi, getCountriesModel } from './Countries.models';

const generateCountriesApi = (api: ApiCall): CountriesApi => ({
  getCountries: () =>
    api({
      camelCaseResponse: true,
      url: `data/reference/countries`,
      method: 'get',
      responseModel: getCountriesModel,
    }),
});

export const countriesApi = generateCountriesApi(apiCall);
