import React from 'react';
import { useSearchParam } from 'react-use';

import { ItemKinds } from 'enums/items';

import { getItemKindFromExternalV2Path } from 'helpers/items';
import { hasZeroLength } from 'helpers/utility';

import { usePayments } from 'hooks/usePayments';

import { Item } from 'interfaces/item';
import { Payment } from 'interfaces/redux';

import {
  ExternalReceiptPaymentFullPageModal,
  ExternalReceiptPaymentLineItemsModal,
} from '../externalReceiptV2/ExternalReceiptSingleItem/components/ExternalReceiptPayment/components';

import { ExternalPaymentsListTable } from './ExternalPaymentsListTable';

const ExternalPaymentsList = (): JSX.Element => {
  const partnershipId = useSearchParam('partnership_id');
  const [activePayment, setActivePayment] = React.useState<Payment>();
  const [activeItem, setActiveItem] = React.useState<Item>();

  const kind = getItemKindFromExternalV2Path() as ItemKinds;

  const { isLoading, isError, data: payments } = usePayments({ kind, partnershipId });
  const hasActivePayment = Boolean(activePayment);

  if (isLoading || isError || hasZeroLength(payments)) {
    return null;
  }

  return (
    <div className="external-payments-list">
      <ExternalPaymentsListTable
        data={payments}
        onClick={({ payload }) => {
          setActivePayment(payload);
        }}
      />
      <ExternalReceiptPaymentLineItemsModal
        item={activeItem}
        modalState={!!activeItem}
        onCloseModal={() => setActiveItem(undefined)}
      />
      {hasActivePayment && (
        <ExternalReceiptPaymentFullPageModal
          modalState={hasActivePayment}
          onCloseModal={() => setActivePayment(undefined)}
          onSetActiveItem={setActiveItem}
          payment={activePayment}
        />
      )}
    </div>
  );
};

export default ExternalPaymentsList;
