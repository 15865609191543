import React from 'react';

import { ButtonV2, FlexRow } from 'components';

import { ButtonAppearance } from 'constants/button';

import { DataCardDetail } from './components';
import type { DataCardProps } from './DataCard.types';

import './DataCard.scss';

/**
 * Component rendering generic Data Card
 * @param props
 */
const DataCard: React.FC<DataCardProps> = ({ buttonProps, buttonText, entries }) => (
  <div className="data-card">
    <FlexRow className="data-card__entries" stackOnMobile={false}>
      {entries.map((entry) => (
        <DataCardDetail key={entry.label} {...entry} />
      ))}
    </FlexRow>

    {buttonText && (
      <div className="data-card__footer">
        <ButtonV2
          {...buttonProps}
          appearance={ButtonAppearance.BORDERLESS}
          className="font-sm--important font-color--blue-bold--important"
        >
          {buttonText}
        </ButtonV2>
      </div>
    )}
  </div>
);

export default DataCard;
