import { SIGNUP_CONNECT_BANK, SIGNUP_CREATE_NAMESPACE, SIGNUP_VERIFY_EMAIL } from 'constants/routes';

/**
 * Return current step position index from given url path
 */
export const getCurrentStepPositionFromPath = (path: string): 0 | 1 | 2 | 3 => {
  switch (path) {
    case SIGNUP_VERIFY_EMAIL:
      return 1;

    case SIGNUP_CREATE_NAMESPACE:
      return 2;

    case SIGNUP_CONNECT_BANK:
      return 3;

    default:
      return 0;
  }
};
