import type { PaymentDeliveryOptionType } from '@routable/shared';

import { PaymentSources } from 'enums/billing';
import { ItemKinds } from 'enums/items';

import { isFundingAccountEligibleForRTP } from 'helpers/funding';
import { isKindReceivable } from 'helpers/items';
import { isPaymentDeliveryOptionRTP } from 'helpers/paymentMethods';

import type { BillingCodeData } from 'interfaces/billing';
import type { PartnershipFundingAccount } from 'interfaces/partnerFundingAccount';

export const shouldDisplayRTPEligibilityWarning = ({
  partnershipFundingAccounts,
  paymentDeliveryOption,
}: {
  partnershipFundingAccounts: PartnershipFundingAccount[];
  paymentDeliveryOption: PaymentDeliveryOptionType;
}): boolean => {
  const isPartnershipEligibleForRTP = partnershipFundingAccounts.some(isFundingAccountEligibleForRTP);
  const isRTPSelected = isPaymentDeliveryOptionRTP(paymentDeliveryOption);

  return !isPartnershipEligibleForRTP && isRTPSelected;
};

export const shouldDisplayBalanceTooLowWarning = ({
  paymentTotalAmount,
  balanceAmount,
  paymentSource,
}: {
  paymentTotalAmount: number;
  balanceAmount: number;
  paymentSource: PaymentSources;
}): boolean => {
  const isBalanceFundingAccountSelected = paymentSource === PaymentSources.BALANCE;
  const isFormTotalAboveBalanceAmount = paymentTotalAmount > balanceAmount;

  return isBalanceFundingAccountSelected && isFormTotalAboveBalanceAmount;
};

export const shouldDisplayAmountAboveLimitWarning = ({
  paymentMaxIndividualAmount,
  currentBillingData,
}: {
  paymentMaxIndividualAmount: number;
  currentBillingData: BillingCodeData;
}): boolean => {
  const isMaxAmountAboveTransactionMax = paymentMaxIndividualAmount > currentBillingData?.paymentDeliveryOptionMax;

  return isMaxAmountAboveTransactionMax;
};

export const shouldDisplayMaxTransactionFeeWarning = ({
  itemKind,
  showAmountAboveLimitWarning,
}: {
  itemKind: ItemKinds;
  showAmountAboveLimitWarning: boolean;
}): boolean => isKindReceivable(itemKind) && !showAmountAboveLimitWarning;
