import React from 'react';

import { GenericError } from './GenericFormError.styles';
import { GenericFormErrorProps } from './GenericFormError.types';

const GenericFormError: React.FC<GenericFormErrorProps> = ({ errorMessage, children }) => (
  <GenericError>
    {errorMessage}
    {children}
  </GenericError>
);

export default GenericFormError;
