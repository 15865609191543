import React from 'react';

import { Text, WhiteSpace } from 'components/text';

import { getPartnershipType } from 'helpers/partnerships';
import { apostrophiseString } from 'helpers/stringHelpers';

import { PaymentMethodAccountNumberLockedTooltipContentProps } from './PaymentMethodAccountNumberLockedTooltipContent.types';

/**
 * Component rendering Payment Method Account Number locked tooltip content
 * @param {PaymentMethodAccountNumberLockedTooltipContentProps} props
 */
const PaymentMethodAccountNumberLockedTooltipContent: React.FC<PaymentMethodAccountNumberLockedTooltipContentProps> = ({
  partnership,
}) => {
  const partnershipType = getPartnershipType(partnership);

  return (
    <Text.Regular className="payment-method-account-number-locked-tooltip-content">
      This account number has been securely encrypted through your
      <WhiteSpace />
      {apostrophiseString(partnershipType)}
      <WhiteSpace />
      bank connection and we are unable to show the full account number.
    </Text.Regular>
  );
};

export default PaymentMethodAccountNumberLockedTooltipContent;
