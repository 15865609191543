export const staticImagesBasePaths = {
  ANNOUNCEMENTS: '/images/announcements',
  ERRORS: '/images/errors',
  INTEGRATIONS: '/images/_global/integrations',
};

export const staticImagesFullPaths = {
  ERROR_GENERIC: `${staticImagesBasePaths.ERRORS}/error-generic.svg`,
  ANNOUNCEMENT_SECURITY: `${staticImagesBasePaths.ANNOUNCEMENTS}/security.svg`,
};
