import { announcementFetchRoutine } from 'actions/routines/announcements';

import { gatherReducerCasesFromRoutines } from 'helpers/routine';

import { createIsFetchingReducer } from 'store/redux';

export const { failureCases, requestCases, successCases } = gatherReducerCasesFromRoutines([announcementFetchRoutine]);

export const finishCases = [...failureCases, ...successCases];

export default createIsFetchingReducer(finishCases, requestCases);
