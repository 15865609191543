import type { RoutableApiResponse } from 'interfaces/routableApiResponse';

import { routableApi, RoutableTags } from '../routableApi';

const extendedCompanyApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    companyForId: builder.query<RoutableApiResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/companies/${id}/`,
      }),
      providesTags: [RoutableTags.Company],
    }),
  }),
});

export const { useCompanyForIdQuery } = extendedCompanyApi;
