import React from 'react';

import { FormControl } from 'components/form';
import BaseHint from 'components/hintTypes/BaseHint';

import { Intent } from 'constants/ui';

import { getExistingPartnerSinglePaymentMethodHintTitle } from '../helpers/text';

import { ExistingPartnerSinglePaymentMethodHintProps } from './ExistingPartnerSinglePaymentMethodHint.types';

/**
 * Hint showing which payment method a vendor can use for accepting a payable when they only have a single funding-type available.
 */
const ExistingPartnerSinglePaymentMethodHint: React.VFC<ExistingPartnerSinglePaymentMethodHintProps> = ({
  availablePaymentMethods,
}) => (
  <FormControl>
    <BaseHint intent={Intent.INFO} title={getExistingPartnerSinglePaymentMethodHintTitle(availablePaymentMethods)} />
  </FormControl>
);

export default ExistingPartnerSinglePaymentMethodHint;
