import { ssoDisableRoutine } from 'actions/routines/sso';

import { SSOActionType } from 'enums/sso';

import { createIsDisablingReducer } from 'store/redux';

export const finishCases = [SSOActionType.CLOSE_DISABLE_MODAL, ssoDisableRoutine.FAILURE, ssoDisableRoutine.SUCCESS];

export const requestCases = [ssoDisableRoutine.TRIGGER];

const isDisablingReducer = createIsDisablingReducer(finishCases, requestCases);

export default isDisablingReducer;
