import _flow from 'lodash/flow';

import { StaticCountryCode } from 'constants/countries';

import { compact, isArray } from 'helpers/utility';

import type { CountryCodeMap } from 'interfaces/countries';
import type { Partnership } from 'interfaces/partnerships';
import type { MultiLineOption } from 'interfaces/select';

/**
 * Return only country codes supported by partnership
 */
export const filterCountryCodesByCountryPaymentOptions = (
  countryCodes: CountryCodeMap,
  partnership?: Partnership,
): CountryCodeMap => {
  if (isArray(partnership?.countryPaymentOptions)) {
    return _flow([
      Object.entries,
      (arr) => arr.filter(([key]) => partnership.countryPaymentOptions.includes(key)),
      Object.fromEntries,
    ])(countryCodes);
  }

  return countryCodes;
};

/**
 * Sorts countries by alphabetical order.
 */
export const byAlphabeticalOrder = (a: MultiLineOption, b: MultiLineOption): number => {
  if (a.label && b.label) {
    return a.label.localeCompare(b.label);
  }

  return 0;
};

/**
 * Sort countries by popularity - puts US first and CA second.
 */
export const putPopularCountriesFirst = (countries: MultiLineOption[]): MultiLineOption[] =>
  compact([
    countries.find((country) => country.value === StaticCountryCode.US),
    countries.find((country) => country.value === StaticCountryCode.CA),
    ...countries.filter((country) => country.value !== StaticCountryCode.US && country.value !== StaticCountryCode.CA),
  ]);
