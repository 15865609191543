import classNames from 'classnames';
import React from 'react';

import { ButtonV2, IconNames, InteractionBar } from 'components';

import { colors } from 'constants/styles';
import { Intent } from 'constants/ui';

import type { AddRegisteredAddressPaneProps } from './AddRegisteredAddressPane.types';

/**
 * Component rendering the Add Registered Address interaction bar (pane)
 * @param props - Component props
 */
const AddRegisteredAddressPane: React.VFC<AddRegisteredAddressPaneProps> = ({
  className,
  isDisabled,
  onAddRegisteredAddress,
}) => (
  <InteractionBar
    buttonLeft={
      <ButtonV2
        intent={Intent.NEUTRAL}
        isDisabled={isDisabled}
        leftIconClassName="margin-right--xm"
        leftIconColor={colors.colorGreyDarkHex}
        leftIconName={IconNames.ADD}
        onClick={onAddRegisteredAddress}
        type="button"
      >
        Add registered address
      </ButtonV2>
    }
    className={classNames('add-registered-address-pane', className)}
    iconProps={{ color: colors.colorGreyDarkHex, icon: IconNames.MAP_MARKER }}
    leftContent="Registered address (optional)"
  />
);

export default AddRegisteredAddressPane;
