import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { getDomainWithOrWithoutCompanyNamespace } from 'helpers/urls';

import { authNamespaceValueSelector } from 'selectors/forms';

/**
 * ChangeWorkspace
 */
const ChangeWorkspace: React.FC = () => {
  const namespace = useSelector(authNamespaceValueSelector);
  const brandedWorkspaceDisplay = getDomainWithOrWithoutCompanyNamespace(namespace).split('//')[1];
  const topLevelUrl = getDomainWithOrWithoutCompanyNamespace();
  const lastPathSection = window.location.pathname.split('/').pop();
  const changeWorkspaceUrl = `${topLevelUrl}/${lastPathSection}`;

  return (
    <>
      <span
        className={classNames({
          'display--inline-block': true,
          'line-height--l': true,
        })}
      >
        {brandedWorkspaceDisplay}
      </span>

      <a
        className={classNames({
          semibold: true,
          'display--block': true,
          'font-color--blue-bold--important': true,
          'line-height--m ': true,
          'font-xs': true,
        })}
        href={changeWorkspaceUrl}
      >
        Change workspace
      </a>
    </>
  );
};

export default ChangeWorkspace;
