import { AxiosRequestHeaders } from 'axios';
import { createRoutine } from 'redux-saga-routines';

import { CreateApiRoutine } from './createApiRoutine.types';
import { withApiRequestFor } from './withApiRequestFor';

export const createApiRoutine: CreateApiRoutine = (key, baseOptions, payload, meta) => {
  const updatedBaseOptions = {
    ...baseOptions,
    headers: (baseOptions.headers as AxiosRequestHeaders) || ({} as AxiosRequestHeaders),
  };

  return withApiRequestFor('trigger', updatedBaseOptions, createRoutine(key, payload, meta));
};
