import React from 'react';
import { useSelector } from 'react-redux';
import './NamespaceAvailability.module.scss';

import { isFetchingNamespaceSelector, isNamespaceValidSelector } from 'selectors/signupSelectors';

import { availabilityStatuses, contentPerStatus } from './constants/AvailabilityConstants';

const NamespaceAvailability: React.VFC = () => {
  let availability;

  const isFetchingNamespace = useSelector(isFetchingNamespaceSelector);
  const isNamespaceAvailable = useSelector(isNamespaceValidSelector);

  if (isFetchingNamespace) {
    availability = availabilityStatuses.LOADING;
  }

  if (!isFetchingNamespace) {
    availability = isNamespaceAvailable ? availabilityStatuses.AVAILABLE : availabilityStatuses.UNAVAILABLE;
  }

  return (
    <div className="workspace-availability-container">
      {contentPerStatus[availability].icon}
      <span className="availability-hint font-size--16 font-color--greyXDark">
        {contentPerStatus[availability].message}
      </span>
    </div>
  );
};

export default NamespaceAvailability;
