import classNames from 'classnames';
import React from 'react';

import { IconNames, TooltipMUIIcon } from 'components';

import { colors, sizes } from 'constants/styles';

/**
 * Warning sign icon mostly used in inputs, notifying the user that the field is required
 */
const RequiredInputErrorTooltipIcon: React.FC<{ className?: string }> = ({ className }) => (
  <TooltipMUIIcon
    icon={IconNames.WARNING_SIGN}
    iconProps={{
      className: classNames('not-modified', { [className]: !!className }),
      color: colors.colorRedBoldHex,
      size: sizes.iconSizes.XX_LARGE,
    }}
    title="This is a required field."
  />
);

export default RequiredInputErrorTooltipIcon;
