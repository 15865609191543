import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { disableMembershipRoutine } from 'actions/routines/membership';

import { currentMembershipIdSelector } from 'selectors/membershipsSelector';
import { getDeactivateTeamMemberModal } from 'selectors/signupSelectors';

import TeamMemberDeactivateCell from './TeamMemberDeactivateCell';

const mapStateToProps = createStructuredSelector({
  currentMembershipId: currentMembershipIdSelector,
  deactivateTeamMemberModal: getDeactivateTeamMemberModal,
});

const mapDispatchToProps = {
  onDeactivate: disableMembershipRoutine,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberDeactivateCell);
