import { RoutableObject, useEventSync } from '@routable/framework';
import { Flex, Menu, Tooltip } from '@routable/gross-ds';
import _cloneDeep from 'lodash/cloneDeep';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { LINE_ITEMS_DRAWER_TABS_EVENT_NAME, LineItemStyles } from 'constants/lineItems';

import { getInitialAnnotationLineItems } from 'helpers/ocr';

import { lineItemsSectionsSelector } from 'modules/dashboard/createItems/createItemForm/components/CreateItemForm/lineItemsSectionsSelector';
import { useTransferLineItems } from 'modules/table/hooks';

import { useAnnotationLineItems } from './useAnnotationLineItems';
import { useSummarizeLineItemsToggle } from './useSummarizeLineItemsToggle';

export type SummarizeLineItemsProps = {
  isForcedConsolidatedLineItem: boolean;
  ocrAnnotation: RoutableObject;
  viewModelManager: RoutableObject;
  lineItems: RoutableObject;
};

export const SummarizeLineItems = ({
  ocrAnnotation,
  isForcedConsolidatedLineItem,
  viewModelManager,
  lineItems,
}: SummarizeLineItemsProps) => {
  const [unsummarizedLineItems, setUnsummarizedLineItems] = useState({});
  const { annotationCount, annotationItems } = useAnnotationLineItems();

  const { lineItemsAccountSection, lineItemsItemSection } = useSelector(lineItemsSectionsSelector);
  const lineItemsAccountDisplayName = lineItemsAccountSection?.display;
  const lineItemsItemDisplayName = lineItemsItemSection?.display;
  const hasBothSections = Boolean(lineItemsItemDisplayName && lineItemsAccountDisplayName);

  const [activeTabId, setActiveTabId] = useState(
    lineItemsAccountDisplayName ? LineItemStyles.ACCOUNT : LineItemStyles.ITEM,
  );
  const activeTabIsItem = activeTabId === LineItemStyles.ITEM;
  useEventSync(`tabs:${LINE_ITEMS_DRAWER_TABS_EVENT_NAME}:onUpdate`, setActiveTabId);

  useEffect(() => {
    const originalLineItems = getInitialAnnotationLineItems(
      _cloneDeep(viewModelManager?.initialValues?.line_items || {}),
      annotationItems,
    );
    setUnsummarizedLineItems(originalLineItems);
  }, [annotationItems, viewModelManager.initialValues.line_items]);

  const { disabledTooltipText, onHandleSummarize, summarizeOrSeparateText } = useSummarizeLineItemsToggle({
    activeTabIsItem,
    annotationCount,
    isForcedConsolidatedLineItem,
    ocrAnnotation,
    unsummarizedLineItems,
  });

  const { hasTransferrableLineItems, transferText, transferLineItems } = useTransferLineItems({
    activeTabIsItem,
    viewModelManager,
  });

  const isSummarizeVisible =
    !(hasBothSections && activeTabIsItem) &&
    ((ocrAnnotation?.lineItems || [])?.length > 1 || isForcedConsolidatedLineItem);
  const isTransferVisible = hasBothSections && (ocrAnnotation?.lineItems || [])?.length > 0;

  if (!isSummarizeVisible && !isTransferVisible) {
    return null;
  }

  return (
    <Flex
      style={{
        alignItems: 'center',
        gap: '8px',
        position: 'relative',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          cursor: 'pointer',
          gap: '8px',
        }}
      >
        <Menu
          buttonVariant="floating"
          data-testid="summarize-line-items-action-menu"
          intent="secondary"
          label="Actions"
          placement="bottom right"
          size="small"
        >
          {isSummarizeVisible && (
            <Menu.Item
              disabled={Boolean(disabledTooltipText)}
              key="summarize-line-items"
              onAction={onHandleSummarize}
              textValue="Summarize"
            >
              <Tooltip data-testid="summarize-line-items-tooltip" tooltip={disabledTooltipText} variant="light">
                <div className="flex items-center">
                  <span className="pl-4">{summarizeOrSeparateText}</span>
                </div>
              </Tooltip>
            </Menu.Item>
          )}
          {isTransferVisible && (
            <Menu.Item
              disabled={!hasTransferrableLineItems}
              key="transfer-line-items"
              onAction={() => transferLineItems(lineItems)}
              textValue={transferText}
            >
              <div className="flex items-center">
                <span className="pl-4">{transferText}</span>
              </div>
            </Menu.Item>
          )}
        </Menu>
      </div>
    </Flex>
  );
};

export default SummarizeLineItems;
