import { webhookSettingsFetchRoutine } from 'actions/routines/webhooks';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [webhookSettingsFetchRoutine.FAILURE, webhookSettingsFetchRoutine.SUCCESS];
export const requestCases = [webhookSettingsFetchRoutine.REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;
