import React from 'react';

import { Text } from 'components';

import { typography } from 'constants/styles';

import { DataCardTooltipConditional } from '../DataCardTooltipConditional';

import type { DataCardDetailProps } from './DataCardDetail.types';

import './DataCardDetail.scss';

/**
 * Component rendering Single Detail Entry of the Data Card component
 * @see {DataCard}
 * @param props
 */
const DataCardDetail = ({
  icon,
  iconProps,
  iconRight,
  iconRightProps,
  label,
  tooltipContent,
  tooltipContentRight,
  value,
}: DataCardDetailProps) => (
  <div className="data-card-detail">
    <div className="data-card-detail__label">
      <Text.Regular color={typography.TextColor.GREY_X_DARK} size={typography.TextSize.LEVEL_200}>
        {label}
      </Text.Regular>
    </div>

    <div className="data-card-detail__value">
      <DataCardTooltipConditional icon={icon} iconProps={iconProps} tooltipContent={tooltipContent} />

      <Text.Regular color={typography.TextColor.BLACK} size={typography.TextSize.LEVEL_400}>
        {value}
      </Text.Regular>

      <DataCardTooltipConditional icon={iconRight} iconProps={iconRightProps} tooltipContent={tooltipContentRight} />
    </div>
  </div>
);

export default DataCardDetail;
