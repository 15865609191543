import { useMemo } from 'react';

import { useItemInitialDataFromOverlays, useSelectorWithProps } from 'hooks';

import { AnnotationLineItem } from 'interfaces/redux/annotations';

import { itemSelector } from 'selectors/itemsSelectors';

type AnnotationLineItems = {
  annotationItems: AnnotationLineItem[];
  annotationCount: number;
};

export const useAnnotationLineItems = (): AnnotationLineItems => {
  const item = useSelectorWithProps(itemSelector, undefined);
  const { data: annotationFromOverlays } = useItemInitialDataFromOverlays({
    enabled: Boolean(item),
    itemId: item?.id,
    kind: item?.kind,
  });

  const annotationItems = annotationFromOverlays?.overlayLineItems as AnnotationLineItem[];

  const result = useMemo(
    () => (annotationItems || []).map((lineItem) => ({ ...lineItem, id: crypto.randomUUID(), isOcr: true })),
    [annotationItems],
  );

  return {
    annotationItems: result,
    annotationCount: result.length,
  };
};
