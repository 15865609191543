import React from 'react';

import { BaseHint, FormControl } from 'components';

import { Intent } from 'constants/ui';

import './ContactRequiredToAllowSelectPaymentMethodHint.scss';
import { ContactRequiredHintText } from './components';
import type { ContactRequiredToAllowSelectPaymentMethodHintProps } from './ContactRequiredToAllowSelectPaymentMethodHint.types';

/**
 * Hint for notifying the user that the item must have one active (reachable) contact selected
 */
const ContactRequiredToAllowSelectPaymentMethodHint = ({
  formSyncErrors,
  ...rest
}: ContactRequiredToAllowSelectPaymentMethodHintProps): JSX.Element => {
  const HintTextComponent = <ContactRequiredHintText {...rest} />;

  const intent = formSyncErrors?.item?.paymentDeliveryMethod ? Intent.DANGER : Intent.WARNING;

  return (
    <FormControl>
      <BaseHint intent={intent} text={HintTextComponent} title="A contact with a valid email is required" />
    </FormControl>
  );
};
export default ContactRequiredToAllowSelectPaymentMethodHint;
