import React from 'react';

import { DashboardToDashboardRestrictionTooltipContent } from 'components';

import { isPartnershipMemberAccessSelfManaged } from 'helpers/partnershipMembers';

import { ItemMember } from 'interfaces/item';

import { CommonMenuItemProps } from './CommonMenuItemProps.types';

export const getCommonMenuItemProps = (contact: ItemMember): CommonMenuItemProps => {
  const isMemberSelfManaged = isPartnershipMemberAccessSelfManaged(contact?.accessItem);
  const selfManageTooltipProps: CommonMenuItemProps['tooltip'] = {
    tooltip: <DashboardToDashboardRestrictionTooltipContent />,
  };

  if (isMemberSelfManaged) {
    return {
      disabled: isMemberSelfManaged,
      tooltip: selfManageTooltipProps,
    };
  }

  return undefined;
};
