import React from 'react';

import { SwalWrapperV2 } from 'components';
import BulletList from 'components/BulletList';

import { Intent } from 'constants/ui';

import { ModalContainer, Title, BulletContainer, BulletListContainer } from './DocumentModal.styles';

const DocumentModal: React.FC = () => (
  <ModalContainer>
    <SwalWrapperV2
      intent={Intent.NEUTRAL}
      rightButtonProps={{
        onClick: () => {},
        children: 'Got it',
        intent: 'primary',
      }}
      shouldCloseOnClick
      title="Business verification forms"
    >
      <BulletContainer>
        <Title>To verify your business on Routable you can upload:</Title>
        <BulletListContainer>
          <BulletList
            bullets={[
              'Business License',
              'Certificate of Good Standing',
              'IRS-issued EIN letter',
              'Filed and stamped Articles of Organization or Incorporation',
              'Sales/Use Tax License',
            ]}
          />
        </BulletListContainer>
      </BulletContainer>

      <BulletContainer>
        <Title>Sole Proprietors using a fictitious name can use:</Title>
        <BulletListContainer>
          <BulletList
            bullets={[
              'Fictitious Name Certificate/Statement',
              'Certificate of Assumed name',
              'Business License',
              'Sales/Use Tax License',
              'Registration of Trade Name',
            ]}
          />
        </BulletListContainer>
      </BulletContainer>
    </SwalWrapperV2>
  </ModalContainer>
);

export default DocumentModal;
