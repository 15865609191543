import React from 'react';

import { ButtonV2, TooltipMUIConditionalWrapper } from 'components';

import { ButtonSize } from 'constants/button';
import { colors, sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import { isFn } from 'helpers/utility';

import { IconToActionTypeMap } from './constants';
import type { PaymentMethodManageActionButtonProps } from './PaymentMethodManageActionButton.types';

/**
 *  Renders a styled button for payment method component.
 */
const PaymentMethodManageActionButton = ({
  actionType,
  onClick,
  tooltipProps,
  ...props
}: PaymentMethodManageActionButtonProps) => {
  // If onClick handler is not passed, we don't want to render the
  // action button
  if (!isFn(onClick)) {
    return null;
  }

  return (
    <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
      <ButtonV2
        className="payment_method__manage-action-button"
        intent={Intent.NEUTRAL}
        leftIconColor={colors.colorGreyXDarkHex}
        leftIconName={IconToActionTypeMap[actionType]}
        leftIconSize={sizes.iconSizes.LARGE}
        onClick={onClick}
        size={ButtonSize.SMALL}
        {...props}
      />
    </TooltipMUIConditionalWrapper>
  );
};

export default PaymentMethodManageActionButton;
