import { CornerDialog } from '@routable/components';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeCornerDialogUploadBills } from 'actions/cornerDialog';

import FileUploadProgress from 'components/fileUpload/FileUploadProgress';

import { useSelectorWithProps } from 'hooks';

import { cornerDialogSelector } from 'selectors/cornerDialogsSelectors';
import { itemUploadBillsSelector } from 'selectors/itemsSelectors';

const CornerDialogUploadBills = () => {
  const dispatch = useDispatch();
  const uploadBillsState = useSelector(itemUploadBillsSelector);
  const cornerDialogState = useSelectorWithProps(cornerDialogSelector, 'cornerDialogUploadBills');

  const [isClosing, setIsClosing] = useState(false);

  const allUploadedBills = Object.values(uploadBillsState);
  const title = cornerDialogState.count > 1 ? `Uploading ${cornerDialogState.count} bills` : 'Uploading a bill';

  const handleCloseCornerDialog = useCallback(() => {
    dispatch(closeCornerDialogUploadBills());
  }, [dispatch]);

  useEffect(() => {
    // if we're in the process of closing
    if (isClosing) {
      // two seconds from now
      setTimeout(() => {
        handleCloseCornerDialog();
        // and update the state to match
        setIsClosing(false);
      }, 2000);
    }
  }, [isClosing, handleCloseCornerDialog]);

  // Auto-close when all files uploaded successfully
  useEffect(() => {
    // If the dialog is open and we're not already in the process of closing it
    if (cornerDialogState.open && !isClosing) {
      // Check that all bills uploaded successfully
      if (allUploadedBills.length > 0 && allUploadedBills.every((bill) => bill.isUploaded)) {
        // start autoclosing the dialog
        setIsClosing(true);
      }
    }
  }, [allUploadedBills, cornerDialogState.open, isClosing]);

  /**
   * Close complete method for corner dialog
   * Return anonymous function if already closing automatically
   */
  const handleCloseComplete = isClosing ? () => {} : handleCloseCornerDialog;

  return (
    <CornerDialog
      className="corner-dialog"
      contentClassName="flex flex-col gap-2"
      hasFooter={false}
      isShown={cornerDialogState.open}
      onClose={handleCloseComplete}
      title={<h4 className="text-xl text-grey-70 font-medium">{title}</h4>}
    >
      {allUploadedBills.map((row) => (
        <FileUploadProgress key={row.uploadId} row={row} />
      ))}
    </CornerDialog>
  );
};

export default CornerDialogUploadBills;
