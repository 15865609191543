import React from 'react';

import { UpdatePaymentMethodFundingAccount, UpdatePaymentMethodFundingAccountHint } from './components';
import { UpdatePaymentMethodFundingAccountsProps } from './UpdatePaymentMethodFundingAccounts.types';

/**
 * Component rendering selectable funding accounts inside of a Update Payment Method International
 * Form
 * @param {UpdatePaymentMethodFundingAccountsProps} props
 * @returns {StatelessComponent}
 */
const UpdatePaymentMethodFundingAccounts: React.FC<UpdatePaymentMethodFundingAccountsProps> = ({
  fundingAccounts,
  partnership,
}) => (
  <div className="update-payment-method-form-international__funding-accounts">
    {fundingAccounts.map((fundingAccount) => (
      <UpdatePaymentMethodFundingAccount
        fundingAccount={fundingAccount}
        key={fundingAccount.id}
        partnership={partnership}
      />
    ))}

    <UpdatePaymentMethodFundingAccountHint />
  </div>
);

export default UpdatePaymentMethodFundingAccounts;
