import React from 'react';

import { TwoLineButton } from 'complexComponents';

import { IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';

import { AddCheckButtonProps } from './AddCheckButton.types';

/**
 * Component rendering Add CAD Cheque Button. It opens Create Manual Address Modal on
 * button click.
 * @param {AddCheckButtonProps} props
 * @returns {StatelessComponent}
 */
const AddCheckButton: React.FC<AddCheckButtonProps> = ({ onAddAddress }) => (
  <TwoLineButton
    className="width--100-percent"
    description="5 - 7 business days"
    intent={Intent.NEUTRAL}
    leftIconClassName="margin-right--m font-size--sm"
    leftIconName={IconNames.ENVELOPE}
    onClick={onAddAddress}
    rightIconClassName="margin-left--m"
    rightIconName={IconNames.ARROW_RIGHT}
    rightIconSize={14}
    size={ButtonSize.MEDIUM}
    title="Check address"
  />
);

export default AddCheckButton;
