import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import { toggleSkipStepModal, changeView, changeSaveStatus } from 'actions/signUpFlow';

import { Modal } from 'components';

import { colors } from 'constants/styles';

import { currentCompanyVerificationUpdateAllowedSelector } from 'selectors/currentCompanySelectors';
import {
  currentMembershipVerificationDocumentUpdateAllowedSelector,
  currentMembershipVerificationUpdateAllowedSelector,
} from 'selectors/membershipsSelector';
import { activeViewSelector, getNextView, getSkipStepModal } from 'selectors/signupSelectors';

import { businessVerificationViews, representativeVerificationViews, signupFlowForm, views } from '../../constants';
import { clearViewFields } from '../../helpers/clearViewFields';

import SkipStepModalBody from './SkipStepModalBody';
import { SkipStepModalProps } from './types/SkipStepModal.types';

const SkipStepModal: React.FC<SkipStepModalProps> = ({ invalid }) => {
  const dispatch = useDispatch();

  const activeView = useSelector(activeViewSelector);
  const isSkipModalActive = useSelector(getSkipStepModal);
  const nextView = useSelector(getNextView);

  const updateAllowedCurrentCompany = useSelector(currentCompanyVerificationUpdateAllowedSelector);
  const updatedAllowedMembership = useSelector(currentMembershipVerificationUpdateAllowedSelector);
  const updateAllowedDocumentMembership = useSelector(currentMembershipVerificationDocumentUpdateAllowedSelector);

  if (
    // there are no form values to save on this view
    // clicking on 'save' would cause an API error
    activeView === views.uploadDocument ||
    // don't show this modal if user is on rep doc upload view but can't update rep docs
    // TODO: Handle the edge case where RCTM clicks on 'back' from this view or on another
    // step in progress bar stepper and is shown this modal and clicks on 'save' which would cause an error
    (activeView === views.representativeUploadDocument && !updateAllowedDocumentMembership) ||
    // don't show this modal if user is on any other business view and can't update business data
    (businessVerificationViews.includes(activeView) &&
      activeView !== views.uploadDocument &&
      !updateAllowedCurrentCompany) ||
    // don't show this modal if user is on any other rep view and can't update representative data
    (representativeVerificationViews.includes(activeView) &&
      activeView !== views.representativeUploadDocument &&
      !updatedAllowedMembership)
  ) {
    return null;
  }

  const onClose = () => {
    dispatch(toggleSkipStepModal());
  };

  const goToNextView = () => {
    onClose();
    dispatch(changeView(nextView));
  };

  const onSkipStep = () => {
    goToNextView();
    dispatch(changeSaveStatus.saved());
    clearViewFields(activeView);
  };

  const onSaveChanges = () => {
    dispatch(submit(signupFlowForm));
    goToNextView();
  };

  return (
    <Modal
      body={
        <SkipStepModalBody invalid={invalid} onClose={onClose} onSaveChanges={onSaveChanges} onSkipStep={onSkipStep} />
      }
      hasInlineFooter
      hideHeader
      modalState={isSkipModalActive}
      onCloseModal={onSkipStep}
      overrideStyle={{
        content: {
          maxWidth: '500px',
          backgroundColor: colors.colorWhiteHex,
        },
      }}
    />
  );
};

export default SkipStepModal;
