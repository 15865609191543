import React from 'react';

import { Table } from 'complexComponents/Table';

import type { CompanyContactsTableRenderProps } from './CompanyContactsTableRender.types';
import { CompanyContactsTableConfig } from './constants/tableConfig';
import { addContactActionClickHandlers } from './helpers';

/**
 * Just the table in the CompanyContactsTable. Displays PartnershipMembers.
 */
const CompanyContactsTableRender = (props: CompanyContactsTableRenderProps) => {
  const decoratedData = addContactActionClickHandlers(props);
  const getRowProps = () => ({ className: 'disable-hover' });

  return <Table columns={CompanyContactsTableConfig} data={decoratedData} getRowProps={getRowProps} />;
};

export default CompanyContactsTableRender;
