import _capitalize from 'lodash/capitalize';
import React from 'react';
import { useSelector } from 'react-redux';

import { Icon } from 'components/icon';

import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';

import { PaymentMethodStatusType } from '../../enums';

import { PaymentMethodStatusMap } from './constants';
import type { PaymentMethodStatusProps } from './PaymentMethodStatus.types';

/**
 * Renders the status for payment methods
 */
const PaymentMethodStatus = ({ currencyCode, status }: PaymentMethodStatusProps) => {
  const statusEntry = PaymentMethodStatusMap[status];
  const isInternationalPaymentsEnabled = useSelector(currentCompanySettingsIsInternationalPaymentsEnabledSelector);

  if (!statusEntry) {
    return null;
  }

  const statusText =
    status === PaymentMethodStatusType.PRIMARY && isInternationalPaymentsEnabled
      ? `${_capitalize(status)} for ${currencyCode}`
      : statusEntry.text;

  return (
    <div className={`payment_method__status-container payment_method__status-container--${statusEntry.theme}`}>
      <Icon icon={statusEntry.icon} />
      <span className="payment_method__status-label">{statusText}</span>
    </div>
  );
};

export default PaymentMethodStatus;
