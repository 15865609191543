import React from 'react';

import { IconNames } from 'components/icon';
import { TooltipMUIButton } from 'components/tooltip';

import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

/**
 * ToolbarDeleteBillButton
 * @return {StatelessComponent}
 */
const ToolbarDeleteBillButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <div className="toolbar--button-wrapper margin-left--xm">
    <TooltipMUIButton
      className="hover--danger"
      intent={Intent.NEUTRAL}
      isIconButton
      leftIconName={IconNames.TRASH}
      leftIconSize={sizes.iconSizes.LARGE}
      onClick={onClick}
      title="Permanently delete bill record"
    />
  </div>
);

export default ToolbarDeleteBillButton;
