import { createSelector } from 'reselect';

import { AddressTypes } from 'constants/address';
import { MembershipKey } from 'constants/membership';

import { isCurrentMembershipApproverOnItem } from 'helpers/approvals';
import {
  getCompanySettingsApiVersion,
  getCompanySettingsMaxBankAccounts,
  getCompanySettingsPayablePaymentDeliveryMethods,
  getCompanySettingsReceivablePaymentDeliveryMethods,
  isCompanySettingsInternationalPaymentsEnabled,
  isCompanySettingsExpressPaymentsEnabled,
  isCompanySettingsIntegrationClearingAccountsEnabled,
  isCompanySettingsIntegrationIncludePaymentReferenceEnabled,
  isCompanySettingsIntegrationSyncLegalNameChangesEnabled,
  isCompanySettingsIntegrationProfileNameChangesEnabled,
  isCurrentCompanyAccountVerified,
  getCompanySettingsCollectVendorTaxInfo,
} from 'helpers/currentCompany';
import { getCurrentCompanyId } from 'helpers/localStorage';
import {
  findOneMembershipById,
  getMembershipsSortedByRoleOrdinalOrAlphabetically,
  isMembershipDisabled,
  isBusinessRepresentative,
} from 'helpers/memberships';
import { sortObjectArray } from 'helpers/sort';
import { allValues, isEqual } from 'helpers/utility';

import { addressesSelector } from 'selectors/addressesSelectors';
import { itemApprovalLevelsSelector } from 'selectors/approvalsSelectors';
import { idSelector } from 'selectors/globalSelectors';
import { itemSelector } from 'selectors/itemsSelectors';
import { allMembershipsSelector, currentMembershipIdSelector } from 'selectors/membershipsSelector';
import { idPropSelector } from 'selectors/propSelectors';
import { rolesByIdSelector } from 'selectors/rolesSelectors';
import { idParamSelector } from 'selectors/routerSelectors';

const getState = (state) => state.currentCompany;

/**
 * Selects the current company id from localStorage / queryString
 * @return {string}
 */
export const currentCompanyIdSelector = () => getCurrentCompanyId();

/**
 * Selects the isFetching bool for current company from the state
 * @param {object} state - Redux state
 */
export const isFetchingCurrentCompanySelector = createSelector(
  [getState],
  (currentCompany) => currentCompany.isFetching,
);

/**
 * Selects the isUpdating bool for current company from the state
 * @param {object} state - Redux state
 */
export const isUpdatingCurrentCompanySelector = createSelector(
  [getState],
  (currentCompany) => currentCompany.update.isUpdating,
);

/**
 * Selects the isUpdating bool for current company settings from the state
 */
export const isUpdatingCurrentCompanySettingsSelector = createSelector(
  [getState],
  (currentCompany) => currentCompany.updateSettings.isUpdating,
);

/**
 * Selects the isUpdating bool for current company settings integration from the state
 */
export const isUpdatingCurrentCompanySettingsIntegrationSelector = createSelector(
  [getState],
  (currentCompany) => currentCompany.updateSettingsIntegration.isUpdating,
);

/**
 * Selects the isSubmitting bool for current company from the state
 * @param {object} state - Redux state
 */
export const isSubmittingCurrentCompanyRepresentativesSelector = createSelector(
  [getState],
  (currentCompany) => currentCompany.isSubmitting,
);

/**
 * Selects the last updated date for current company from the state
 * @param {object} state - Redux state
 */
export const lastUpdatedCurrentCompanySelector = createSelector(
  [getState],
  (currentCompany) => currentCompany.update.lastUpdated,
);

/**
 * Selects the current company from the state
 * @param {object} state - Redux state
 */
export const currentCompanySelector = createSelector([getState], (currentCompany) => currentCompany.company);

/**
 * Selects whether the current company has fetched
 * @param {object} state - Redux state
 */
export const currentCompanyHasFetchedSelector = createSelector(
  [getState],
  (currentCompany) => currentCompany.hasFetched,
);

/*
 * Selects the companyType of the current company from the state
 * @param {object} state - Redux state
 * @return {UserTypes}
 */
export const currentCompanyCompanyTypeSelector = createSelector(
  [currentCompanySelector],
  (currentCompany) => currentCompany.companyType,
);

/*
 * Selects the name of the current company from the state
 * @param {object} state - Redux state
 */
export const currentCompanyNameSelector = createSelector(
  [currentCompanySelector],
  (currentCompany) => currentCompany.name,
);

/*
 * Selects the namespace of the current company from the state
 * @param {object} state - Redux state
 */
export const currentCompanyNamespaceSelector = createSelector(
  [currentCompanySelector],
  (currentCompany) => currentCompany.namespace,
);

/**
 * Selects the CompanyPlatformType for the current company.
 * @param {ReduxState} state
 * @return {CompanyPlatformTypes|undefined}
 */
export const currentCompanyPlatformTypeSelector = createSelector(
  [currentCompanySelector],
  (currentCompany) => currentCompany?.platformType,
);

/**
 * Selects the current company and returns true if the company's account is verified
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const currentCompanyIsAccountVerifiedSelector = createSelector([currentCompanySelector], (currentCompany) =>
  isCurrentCompanyAccountVerified(currentCompany),
);

/*
 * Selects the current company errors.
 * @function
 * @param {ReduxState} state
 * @return {ObjectMaybe}
 */
export const currentCompanyErrorsSelector = createSelector([getState], (currentCompany) => currentCompany.errors);

/**
 * Selects id of the currentCompany.company state object.
 * @function
 * @param {ReduxState} state
 * @return {StringMaybe}
 */
export const companyIdSelector = createSelector([currentCompanySelector], (company) => company.id);

/**
 * Selects the current company users from the state
 * @param {object} state - Redux state
 */
export const currentCompanyUsersSelector = createSelector([getState], (currentCompany) => currentCompany.users);

/**
 * Selects the current company memberships from the state
 * @param {object} state - Redux state
 */
export const currentCompanyMembershipsByIdSelector = createSelector(
  [getState],
  (currentCompany) => currentCompany.memberships,
);

/**
 * Selects the current company memberships from the state
 * @param {object} state - Redux state
 */
export const currentCompanyMembershipsSelector = createSelector(
  [currentCompanyIdSelector, allMembershipsSelector],
  (currentCompanyId, memberships) => memberships.filter((membership) => isEqual(membership.company, currentCompanyId)),
);

/**
 * Merging user and membership data for current company members
 * @param {object} state - Redux state
 */
export const currentCompanyMembersWithUserInfoSelector = createSelector(
  [currentCompanyMembershipsSelector, currentCompanyUsersSelector],
  (memberships, users) => {
    const mergedData = {};
    Object.values(memberships).forEach((membership) => {
      mergedData[membership.id] = {
        ...membership,
        ...users[membership.user],
        id: membership.id,
      };
    });
    return mergedData;
  },
);

/**
 * Selects the member based on the given id
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {string} memberId - Partnership id
 * @return {MembershipWithUserData|undefined}
 */
export const memberWithUserInfoSelector = createSelector(
  [currentCompanyMembersWithUserInfoSelector, idSelector],
  (members, memberId) => members[memberId],
);

/**
 * Merging membership with role data for current company members
 * @param {object} state - Redux state
 */
export const currentCompanyMembersWithUserInfoAndRoleSelector = createSelector(
  [currentCompanyMembersWithUserInfoSelector, rolesByIdSelector],
  (memberships, roles) => {
    const mergedData = {};

    allValues(memberships).forEach((membership) => {
      mergedData[membership.id] = {
        ...membership,
        role: {
          ...roles[membership.role],
        },
      };
    });
    return mergedData;
  },
);

/**
 * Merges user, membership data and role data for current company members, returning only the values from the object.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {MembershipWithUserData[]}
 */
export const currentCompanyMembersWithUserInfoAllValuesSelector = createSelector(
  [currentCompanyMembersWithUserInfoAndRoleSelector],
  allValues,
);

/**
 * Use a Membership.id to find a specific Membership and merge the Membership with its user data.
 * @type {StandardSelector}
 * @param {ReduxState}
 * @param {ComponentProps} props
 * @param {Membership.id} props.id
 * @returns {MembershipWithUserData} - Membership with additional User data
 */
export const currentCompanyOneMemberWithUserInfoSelector = createSelector(
  [currentCompanyMembersWithUserInfoAllValuesSelector, idPropSelector],
  findOneMembershipById,
);

/**
 * Use a Membership.id from a Router params match to find a specific Membership merged with its user data.
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @param {ComponentProps} props
 * @param {History} props.history
 * @param {Object} props.history.match
 * @param {Object} props.history.match.params
 * @param {string} props.history.match.params.id
 * @returns {MembershipWithUserData}
 */
export const currentCompanyOneMemberWithUserInfoFromMatchSelector = createSelector(
  [currentCompanyMembersWithUserInfoAllValuesSelector, idParamSelector],
  findOneMembershipById,
);

/**
 * Get the Membership from the Router params match. Then, using the Membership.disabledById, find the membership which
 * disabled the Membership from the Router params match.
 * @type {StandardSelector}
 * @param {ReduxState}
 * @param {ComponentProps} props
 * @param {History} props.history
 * @returns {MembershipWithUserData|null}
 */
export const whoDisabledOneMemberFromMatchSelector = createSelector(
  [currentCompanyOneMemberWithUserInfoFromMatchSelector, currentCompanyMembersWithUserInfoAllValuesSelector],
  (disabledMembership = {}, allMemberships) => {
    const idForTheMemberWhichDidTheDisabling = disabledMembership[MembershipKey.DISABLED_BY_ID];
    return findOneMembershipById(allMemberships, idForTheMemberWhichDidTheDisabling);
  },
);

/**
 * Selects all memberships (including disabled ones!) belonging to the current company
 * Merging user and membership data
 * @type {import('reselect').Selector<import('interfaces/redux').ReduxState, (import('interfaces/memberships').Membership & import('interfaces/user').User)[]}
 */
export const currentCompanyMembersWithDisabledSelector = createSelector(
  [currentCompanyMembersWithUserInfoSelector],
  (membershipsWithUserData) => allValues(membershipsWithUserData),
);

/**
 * Selects the active (non-disabled) memberships belonging to the current company
 * Merging user and membership data
 * @type {import('reselect').Selector<import('interfaces/redux').ReduxState, (import('interfaces/memberships').Membership & import('interfaces/user').User)[]}
 */
export const currentCompanyMembersSelector = createSelector(
  [currentCompanyMembersWithDisabledSelector],
  (memberships) => memberships.filter((membership) => !isMembershipDisabled(membership)),
);

/**
 * Selects the current company representatives from the state
 * TODO SUF: we should rename this so it's clearer that they are prospective biz reps
 * but haven't submitted/confirmed yet (ie. hasAllPersonalInfo)
 * we may want to return both from this selector to include existing bizReps/hasAllPersonalInfo
 * @param {object} state - Redux state
 */
export const currentCompanyRepresentativesSelector = createSelector([currentCompanyMembersSelector], (memberships) =>
  memberships.filter((membership) => isBusinessRepresentative(membership)),
);

/**
 * Selects the current company beneficial owners (beneficiaries) from the state
 * @param {object} state - Redux state
 */
export const currentCompanyBeneficiariesSelector = createSelector(
  [currentCompanyRepresentativesSelector],
  (representatives) => representatives.filter((representative) => representative?.isCompanyBeneficiary),
);

/**
 * Selects the current company representatives from the state
 * Helper to know if all reps for a company have submitted all their info
 * @param {object} state - Redux state
 */
export const currentCompanyHasAllRepresentativeInfoSelector = createSelector(
  [currentCompanyRepresentativesSelector],
  (representatives) => representatives.every((representative) => representative.hasAllPersonalInfo),
);

/**
 * Selects the active (non-disabled) memberships belonging to the current company with role data
 * Merging membership and role data
 * @param {ReduxState} state - Redux state
 * @returns {Membership[]}
 */
export const currentCompanyMembersWithRoleSelector = createSelector(
  [currentCompanyMembersSelector, rolesByIdSelector],
  (memberships, roles) =>
    memberships
      // sort the memberships by role ordinal and alphabetically on first name within each role
      .sort((currentMembership, nextMembership) =>
        getMembershipsSortedByRoleOrdinalOrAlphabetically(currentMembership, nextMembership, roles),
      )
      // then add role data to each membership
      .map((membership) => ({
        ...membership,
        role: {
          ...roles[membership.role],
        },
      })),
);

/**
 * Selects the inactive (disabled) memberships belonging to the current company
 * Merging user and membership data
 * @param {object} state - Redux state
 */
export const inactiveCompanyMembersSelector = createSelector(
  [currentCompanyMembersWithDisabledSelector],
  (memberships) => memberships.filter(isMembershipDisabled),
);

/**
 * Selects the current company settings from the state
 * @param {object} state - Redux state
 */
export const currentCompanySettingsSelector = createSelector([getState], (currentCompany) =>
  Object(currentCompany?.settings),
);

/**
 * Selects the enablePoMatching prop from current company settings from the state
 * @param {object} state - Redux state
 */
export const currentCompanyIsPoMatchingEnabledSelector = createSelector(
  [currentCompanySettingsSelector],
  (companySettings) => companySettings.enablePoMatching,
);

/**
 * Selects the enablePoMatching prop from current company settings from the state
 * @param {object} state - Redux state
 */
export const currentCompanyCreatorCanApproveDiscrepanciesEnabledSelector = createSelector(
  [currentCompanySettingsSelector],
  (companySettings) => companySettings.allowCreatorOfPayableToApproveDiscrepancies,
);

/**
 * Selects the current company integration settings from the state
 * @param {object} state - Redux state
 */
export const currentCompanySettingsIntegrationSelector = createSelector([getState], (currentCompany) =>
  Object(currentCompany?.settingsIntegration),
);

/**
 * Selects the current company item approval settings from the state
 * @param {object} state - Redux state
 */
export const currentCompanyItemApprovalSettingsSelector = createSelector(
  [getState],
  (currentCompany) => currentCompany.itemApprovalSettings,
);

/**
 * Select the itemApprovalLevels from the state and returns an array
 * @param {object} state - Redux state
 */
export const currentCompanyItemApprovalLevelsAllSelector = createSelector([getState], (currentCompany) =>
  allValues(currentCompany.itemApprovalLevels),
);

/**
 * Sorts itemApprovalLevels by position value
 * @param {object} state - Redux state
 */
export const currentCompanyItemApprovalLevelsSortedSelector = createSelector(
  [currentCompanyItemApprovalLevelsAllSelector],
  (itemApprovalLevels) => sortObjectArray(itemApprovalLevels, 'position'),
);

/**
 * Create an array of only approver ids from itemApprovalLevels based on item kind
 * @param {object} state - Redux state
 */
export const currentCompanyAllApproversByItemKindSelector = createSelector(
  [currentCompanyItemApprovalLevelsAllSelector],
  (itemApprovalLevels) => itemApprovalLevels.reduce((arr, row) => [...arr, ...row.qualifiedApprovers], []),
);

/**
 * Selects the current membership
 * @param {object} state - Redux state
 * @param {object} item - item object
 */
export const canCurrentMembershipApproveSelector = createSelector(
  [currentMembershipIdSelector, itemSelector, currentCompanyItemApprovalSettingsSelector, itemApprovalLevelsSelector],
  (currentMembershipId, item, approvalSettings, approvals) => {
    const isItemCreatorCurrentMember = isEqual(item.sender, currentMembershipId);

    const approvalObj = isCurrentMembershipApproverOnItem(currentMembershipId, approvals);

    // Check if current membership is an approver for this item
    if (!approvalObj) {
      return false;
    }

    // Possible future edge case: https://routablehq.slack.com/archives/C013MQ1C36D/p1603224067039100
    if (approvalSettings?.enforceForApprovers && isItemCreatorCurrentMember) {
      // check if the current member is the item creator
      return false;
    }

    // Check if current membership can approve at the moment
    return approvalObj.canApprove;
  },
);

/**
 * Returns if the current membership can approve discrepancies
 * @param {object} state - Redux state
 */
export const canCurrentMembershipApproveDiscrepanciesSelector = createSelector(
  [currentMembershipIdSelector, itemSelector, currentCompanyCreatorCanApproveDiscrepanciesEnabledSelector],
  (currentMembershipId, item, allowCreatorOfPayableToApproveDiscrepancies) => {
    const isItemCreatorCurrentMember = item.sender === currentMembershipId;
    if (isItemCreatorCurrentMember && !allowCreatorOfPayableToApproveDiscrepancies) {
      return false;
    }

    return true;
  },
);

/**
 * Selects the current company info from the state
 * @param {object} state - Redux state
 */
export const currentCompanyInfoSelector = createSelector([getState], (currentCompany) => currentCompany.info);

/**
 * Selects the current company verificationUpdateAllowed from the state
 * @param {object} state - Redux state
 */
export const currentCompanyVerificationUpdateAllowedSelector = createSelector(
  [getState],
  (currentCompany) => currentCompany.company.verificationUpdateAllowed,
);

/**
 * Selects the current company verificationDocumentUpdateAllowed from the state
 * @param {object} state - Redux state
 */
export const currentCompanyVerificationDocumentUpdateAllowedSelector = createSelector(
  [getState],
  (currentCompany) => currentCompany.company.verificationDocumentUpdateAllowed,
);

/**
 * Selects the current company brand from the state
 * @param {object} state - Redux state
 */
export const currentCompanyBrandSelector = createSelector([getState], (currentCompany) => currentCompany.brand);

/**
 * Selects the current company brand logo from state
 * @param {object} state - Redux state
 */
export const currentCompanyBrandLogoSelector = createSelector([currentCompanyBrandSelector], (brand) => brand.logo);

/**
 * Selects the current company inbox email from state
 * @param {object} state - Redux state
 */
export const currentCompanyInboxEmailSelector = createSelector(
  [currentCompanySelector],
  (company) => company.inboxEmail,
);

/**
 * Selects the current company controller from state
 * @param {object} state - Redux state
 */
export const currentCompanyControllerSelector = createSelector(
  [getState],
  (currentCompany) => currentCompany.controller,
);

/**
 * Selects the mailing address belonging to the current company
 * @type {StandardSelector}
 * @param {object} state - Redux state
 */
export const currentCompanyMailingAddressSelector = createSelector(
  [addressesSelector, currentCompanySelector],
  (addresses, currentCompany) => {
    const addressId = currentCompany[AddressTypes.MAILING_ADDRESS];
    return addresses[addressId];
  },
);

/**
 * Selects the physical address belonging to the current company
 * @type {StandardSelector}
 * @param {object} state - Redux state
 */
export const currentCompanyAddressSelector = createSelector(
  [addressesSelector, currentCompanySelector, idSelector],
  (addresses, currentCompany, addressKey) => {
    const addressId = currentCompany[addressKey];
    return addresses[addressId];
  },
);

/**
 * Selects the current apiVersion
 * @param {object} state - Redux state
 */
export const currentCompanyApiVersionSelector = createSelector(
  [currentCompanySettingsSelector],
  getCompanySettingsApiVersion,
);

/**
 * Selects the current company payable payment methods from the state
 * @param {object} state - Redux state
 */
export const currentCompanyPayablePaymentDeliveryMethodsSelector = createSelector(
  [currentCompanySettingsSelector],
  getCompanySettingsPayablePaymentDeliveryMethods,
);

/**
 * Selects the current company receivable payment methods from the state
 * @param {object} state - Redux state
 */
export const currentCompanyReceivablePaymentDeliveryMethodsSelector = createSelector(
  [currentCompanySettingsSelector],
  getCompanySettingsReceivablePaymentDeliveryMethods,
);

/**
 * Selects maxBankAccounts current company settings property
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {number}
 */
export const currentCompanyMaxBankAccountsSettingsSelector = createSelector(
  [currentCompanySettingsSelector],
  getCompanySettingsMaxBankAccounts,
);

/**
 * Selects collectVendorTaxInfo current company settings property
 */
export const currentCompanyCollectVendorTaxInfoSettingsSelector = createSelector(
  [currentCompanySettingsSelector],
  getCompanySettingsCollectVendorTaxInfo,
);

/**
 * Selects enableInternationalPayments current company settings property
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const currentCompanySettingsIsInternationalPaymentsEnabledSelector = createSelector(
  [currentCompanySettingsSelector],
  isCompanySettingsInternationalPaymentsEnabled,
);

/**
 * Selects expressPayments from current company settings
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const currentCompanySettingsHasExpressPaymentsSelector = createSelector(
  [currentCompanySettingsSelector],
  isCompanySettingsExpressPaymentsEnabled,
);

/**
 * Selects default enableAutomaticBatchPayments
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const currentCompanySettingsEnableAutomaticBatchPayments = createSelector(
  [currentCompanySettingsSelector],
  (currentCompanySettings) => currentCompanySettings?.enableAutomaticBatchPayments,
);

/**
 * Selects default itemSendDateDefault
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {ItemDateScheduledTypes}
 */
export const currentCompanySettingsDefaultItemSendDateSelector = createSelector(
  [currentCompanySettingsSelector],
  (currentCompanySettings) => currentCompanySettings?.itemSendDateDefault,
);

/**
 * Selects default lineItemDisplay
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const currentCompanySettingsLineItemDisplay = createSelector(
  [currentCompanySettingsSelector],
  (currentCompanySettings) => currentCompanySettings?.lineItemDisplay,
);

/**
 * Selects enableVendorRiskChecks from company settings
 * @param {object} state - Redux state
 */
export const enableVendorRiskChecksSelector = createSelector(
  [currentCompanySettingsSelector],
  (currentCompanySettings) => currentCompanySettings?.enableVendorRiskChecks,
);

/**
 * Selects holdPayablesForVendorComplianceFailures from company settings
 * @param {object} state - Redux state
 */
export const holdPayablesForVendorComplianceFailuresSelector = createSelector(
  [currentCompanySettingsSelector],
  (currentCompanySettings) => currentCompanySettings?.holdPayablesForVendorComplianceFailures,
);

/**
 * Selects initial values for company settings payables configuration form
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {{
 *   enableAutomaticBatchPayments: boolean,
 *   itemSendDateDefault: ItemDateScheduledTypes,
 *   lineItemDisplay: boolean
 * }}
 */
export const currentCompanySettingsInitialValues = createSelector(
  [currentCompanySettingsSelector],
  (currentCompanySettings = {}) => {
    const { enableAutomaticBatchPayments, itemSendDateDefault, lineItemDisplay } = currentCompanySettings;

    return {
      enableAutomaticBatchPayments,
      itemSendDateDefault,
      lineItemDisplay,
    };
  },
);

/**
 * Selects "Enable OCR Inbox Processing" from current company settings
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const currentCompanySettingsEnableOcrInboxProcessingSelector = createSelector(
  [currentCompanySettingsSelector],
  (currentCompanySettings) => currentCompanySettings.enableOcrInboxProcessing,
);

/**
 * Selects enableClearingAccounts current company settings integration property
 * @type {StandardSelector}
 * @param {ReduxState} state
 * @returns {Boolean}
 */
export const currentCompanySettingsIntegrationIsClearingAccountsEnabledSelector = createSelector(
  [currentCompanySettingsIntegrationSelector],
  isCompanySettingsIntegrationClearingAccountsEnabled,
);

/**
 * Selects syncLegalNameChanges current company settings integration property
 */
export const currentCompanyIntegrationSyncLegalNameChangesSelector = createSelector(
  [currentCompanySettingsIntegrationSelector],
  isCompanySettingsIntegrationSyncLegalNameChangesEnabled,
);

/**
 * Selects includePaymentReference current company settings integration property
 */
export const currentCompanyIntegrationIncludePaymentReferenceSelector = createSelector(
  [currentCompanySettingsIntegrationSelector],
  isCompanySettingsIntegrationIncludePaymentReferenceEnabled,
);

/**
 * Selects ledgerProfileNameChanges current company settings integration property
 */
export const currentCompanyIntegrationProfileNameChangesSelector = createSelector(
  [currentCompanySettingsIntegrationSelector],
  isCompanySettingsIntegrationProfileNameChangesEnabled,
);

/**
 * Selects enablePoMatchingBypassEar current company settings property
 */
export const enablePoMatchingBypassEarSelector = createSelector(
  [currentCompanySettingsSelector],
  (currentCompanySettings) => currentCompanySettings?.enablePoMatchingBypassEar,
);
