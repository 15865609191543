import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
`;

export const Tag = styled.span`
  background-color: var(--blue--x-light);
  border-radius: 0.25rem;
  color: var(--blue--dark);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 0.25rem 0.75rem;
`;
