import React from 'react';

import { Image } from 'components/media';
import { Emoji, Text } from 'components/text';

import { typography } from 'constants/styles';

import { ListEmptyProps } from './ListEmpty.types';

/**
 * Component displaying empty table state
 */
const ListEmpty: React.FC<ListEmptyProps> = ({ content, emoji, image, message }) => (
  <div className="display--flex flex-direction--column align-items--center list-empty">
    <Image alt="No results" src={image} />

    <Text.Bold
      className="display--flex justify-content--center line-height--25--important margin-top--large"
      color={typography.TextColor.GREY_XX_DARK}
      size={typography.TextSize.LEVEL_500}
    >
      {message}
      {emoji && <Emoji ariaLabel="Hey" className="margin-left--sm" text={emoji} />}
    </Text.Bold>

    {content && (
      <Text.Regular
        className="display--flex margin-top--sm white-space-pre-wrap justify-content--center line-height--25--important"
        color={typography.TextColor.GREY_XX_DARK}
        size={typography.TextSize.LEVEL_200}
      >
        {content}
      </Text.Regular>
    )}
  </div>
);

export default ListEmpty;
