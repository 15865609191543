import React from 'react';

import { ListEmpty, SettingsTable } from 'components';

import { TABLE_EMPTY_STATE } from 'constants/images';

import { hasLength } from 'helpers/utility';

import type { ActiveMembersTableProps } from './ActiveMembersTable.types';
import { TeamMembersListTableData } from './config';

const ActiveMembersTable = ({
  currentCompanyMembers,
  isFetchingMembers,
  roles,
}: ActiveMembersTableProps): JSX.Element => {
  const hasMembers = roles && hasLength(currentCompanyMembers);

  return (
    <div className="p-6">
      {hasMembers && (
        <SettingsTable
          data={currentCompanyMembers}
          isLoadingTable={isFetchingMembers}
          tableConfig={TeamMembersListTableData}
        />
      )}
      {!isFetchingMembers && !hasMembers && (
        <ListEmpty image={TABLE_EMPTY_STATE} message="You have not invited any team members." />
      )}
    </div>
  );
};

export default ActiveMembersTable;
