import { toastController, type ToastType } from '@routable/gross-ds';
import React from 'react';

import type { Toaster, NotificationFunction } from 'store/middlewares/notifications/notifications.types';

const addToast =
  (type: ToastType): NotificationFunction =>
  (message, { description, duration, ...options } = {}) => {
    // duration is sent as seconds, not milliseconds.
    toastController.addToast({
      message: description ? (
        <div className="flex-col justify-start">
          <span className="font-semibold">{message}</span>
          <span className="">{description}</span>
        </div>
      ) : (
        message
      ),
      type,
      timeout: duration,
      ...options,
    });
    return { title: message, duration };
  };

/**
 * @deprecated use `useAddToast` hook or the `toastController` interface
 */
export const toaster: Toaster = {
  notify: addToast('info'),
  success: addToast('success'),
  warning: addToast('warning'),
  danger: addToast('critical'),
  closeAll: () => {
    toastController.clearToasts();
  },
} as const;
