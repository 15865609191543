import {
  Account,
  Add,
  AddAlt,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpRight,
  ArrowsHorizontal,
  Attachment,
  Badge,
  Book,
  Branch,
  Building,
  Calendar,
  CaretDown,
  ChartLine,
  ChartLineSmooth,
  ChartPopulation,
  Chat,
  ChatLaunch,
  Checkmark,
  CheckmarkFilled,
  CheckmarkOutline,
  ChevronDown,
  ChevronDownOutline,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronUpOutline,
  CircleFill,
  CircleSolid,
  CircleStroke,
  Close,
  CloseOutline,
  Code,
  CollapseCategories,
  Copy,
  CurrencyDollar,
  DataFormat,
  DirectionMerge,
  DocumentBlank,
  DocumentExport,
  DocumentImport,
  DocumentTasks,
  Download,
  EarthFilled,
  Edit,
  Email,
  Error,
  ExpandCategories,
  Eyedropper,
  Favorite,
  FavoriteFilled,
  Filter,
  Finance,
  FlagFilled,
  FloatingIp,
  FolderOpen,
  Folders,
  HardwareSecurityModule,
  HelpFilled,
  Home,
  Image,
  IncompleteCancel,
  InformationFilled,
  JoinRight,
  Launch,
  Layers,
  Link,
  ListBoxes,
  LocationCurrent,
  LocationFilled,
  Locked,
  Login,
  Logout,
  Maximize,
  Minimize,
  // eslint-disable-next-line camelcase
  Network_2,
  NotificationFilled,
  OverflowMenuHorizontal,
  PanHorizontal,
  PauseFilled,
  Person,
  PiggyBank,
  PlayFilledAlt,
  Portfolio,
  Product,
  Quotes,
  RadioButtonChecked,
  RecentlyViewed,
  Redo,
  Renew,
  RetryFailed,
  RotateCounterclockwise,
  Search,
  Security,
  SelectWindow,
  Settings,
  SettingsAdjust,
  SkipForwardFilled,
  SquareFill,
  Stamp,
  Star,
  StarFilled,
  Store,
  Subtract,
  SubtractAlt,
  SubtractFilled,
  Tag,
  Task,
  TaskAdd,
  TimeFilled,
  TrashCan,
  Undo,
  UpdateNow,
  Upload,
  UserAdmin,
  UserCertification,
  UserMultiple,
  UserOnline,
  ViewFilled,
  ViewOffFilled,
  Warning,
  WarningAltFilled,
  WarningFilled,
  ZoomIn,
  ZoomOut,
} from '@carbon/icons-react';
import { SidebarCompanies, SidebarInbox, SidebarPayables, SidebarReceivables } from '@routable/icons';

export const IconNames = {
  /**
   * Translates to `<Add />` IBM icon
   */
  ADD: 'add',
  /**
   * Translates to `<AddAlt />` IBM icon
   */
  ADD_CIRCLE: 'add-circle',
  /**
   * Translates to `<TaskAdd />` IBM icon
   */
  ADD_TO_ARTIFACT: 'add-to-artifact',
  /**
   * Translates to `<Edit />` IBM icon
   */
  ANNOTATION: 'annotation',
  /**
   * Translates to `<Account />` IBM icon
   */
  APPLICATION: 'application',
  /**
   * Translates to `<PanHorizontal />` IBM icon
   */
  ARROWS_HORIZONTAL: 'arrows-horizontal',
  /**
   * Translates to `<ArrowDown />` IBM icon
   */
  ARROW_DOWN: 'arrow-down',
  /**
   * Translates to `<ArrowLeft />` IBM icon
   */
  ARROW_LEFT: 'arrow-left',
  /**
   * Translates to `<ArrowRight />` IBM icon
   */
  ARROW_RIGHT: 'arrow-right',
  /**
   * Translates to `<ArrowUpRight />` IBM icon
   */
  ARROW_TOP_RIGHT: 'arrow-top-right',
  /**
   * Translates to `<ArrowUp />` IBM icon
   */
  ARROW_UP: 'arrow-up',
  /**
   * Translates to `<ArrowUpRight />` IBM icon
   */
  ARROW_UP_RIGHT: 'arrow-up-right',
  /**
   * Translates to `<UpdateNow />` IBM icon
   */
  AUTOMATIC_UPDATES: 'automatic-updates',
  /**
   * Translates to `<Badge />` IBM icon
   */
  BADGE: 'badge',
  /**
   * Translates to `<Finance />` IBM icon
   */
  BANK_ACCOUNT: 'bank-account',
  /**
   * Translates to `<SubtractFilled />` IBM icon
   */
  BAN_CIRCLE: 'ban-circle',
  /**
   * Translates to `<SquareFill />` IBM icon
   */
  BLANK: 'blank',
  /**
   * Translates to `<UserOnline />` IBM icon
   */
  BLOCKED_PERSON: 'blocked-person',
  /**
   * Translates to `<Portfolio />` IBM icon
   */
  BRIEFCASE: 'briefcase',
  /**
   * Translates to `<Calendar />` IBM icon
   */
  CALENDAR: 'calendar',
  /**
   * Translates to `<CaretDown />` IBM icon
   */
  CARET_DOWN: 'caret-down',
  /**
   * Translates to `<ChartLine />` IBM icon
   */
  CHART: 'chart',
  /**
   * Translates to `<Chat />` IBM icon
   */
  CHAT: 'chat',
  /**
   * Translates to `<ChevronDown />` IBM icon
   */
  CHEVRON_DOWN: 'chevron-down',
  /**
   * Translates to `<ChevronLeft />` IBM icon
   */
  CHEVRON_LEFT: 'chevron-left',
  /**
   * Translates to `<ChevronRight />` IBM icon
   */
  CHEVRON_RIGHT: 'chevron-right',
  /**
   * Translates to `<ChevronUp />` IBM icon
   */
  CHEVRON_UP: 'chevron-up',
  /**
   * Translates to `<CircleStroke />` IBM icon
   */
  CIRCLE: 'circle',
  /**
   * Translates to `<ChevronDownOutline />` IBM icon
   */
  CIRCLE_ARROW_DOWN: 'circle-arrow-down',
  /**
   * Translates to `<ChevronLeft />` IBM icon
   */
  CIRCLE_ARROW_LEFT: 'circle-arrow-left',
  /**
   * Translates to `<ChevronRight />` IBM icon
   */
  CIRCLE_ARROW_RIGHT: 'circle-arrow-right',
  /**
   * Translates to `<ChevronUpOutline />` IBM icon
   */
  CIRCLE_ARROW_UP: 'circle-arrow-up',
  /**
   * Translates to `<Quotes />` IBM icon
   */
  CITATION: 'citation',
  /**
   * Translates to `<Task />` IBM icon
   */
  CLIPBOARD: 'clipboard',
  /**
   * Translates to `<Download />` IBM icon
   */
  CLOUD_DOWNLOAD: 'cloud-download',
  /**
   * Translates to `<Upload />` IBM icon
   */
  CLOUD_UPLOAD: 'cloud-upload',
  /**
   * Translates to `<Code />` IBM icon
   */
  CODE: 'code',
  /**
   * Translates to `<Settings />` IBM icon
   */
  COG: 'cog',
  /**
   * Translates to `<CollapseCategories />` IBM icon
   */
  COLLAPSE_ALL: 'collapse-all',
  /**
   * Translates to `<ChartPopulation />` IBM icon
   */
  COMPARISON: 'comparison',
  /**
   * Translates to `<CheckmarkOutline />` IBM icon
   */
  CONFIRM: 'confirm',
  /**
   * Translates to `<Close />` IBM icon
   */
  CROSS: 'cross',
  /**
   * Translates to `<UserCertification />` IBM icon
   */
  CROWN: 'crown',
  /**
   * Translates to `<CloseOutline />` IBM icon
   */
  DELETE: 'delete',
  /**
   * Translates to `<LocationCurrent />` IBM icon
   */
  DIRECTION_RIGHT: 'direction-right',
  /**
   * Translates to `<Error />` IBM icon
   */
  DISABLE: 'disable',
  /**
   * Translates to `<DocumentBlank />` IBM icon
   */
  DOCUMENT: 'document',
  /**
   * Translates to `<DocumentExport />` IBM icon
   */
  DOCUMENT_OPEN: 'document-open',
  /**
   * Translates to `<DocumentExport />` IBM icon
   */
  DOCUMENT_SHARE: 'document-share',
  /**
   * Translates to `<CurrencyDollar />` IBM icon
   */
  DOLLAR: 'dollar',
  /**
   * Translates to `<ChevronDown />` IBM icon
   */
  DOUBLE_CHEVRON_DOWN: 'double-chevron-down',
  /**
   * Translates to `<ChevronUp />` IBM icon
   */
  DOUBLE_CHEVRON_UP: 'double-chevron-up',
  /**
   * Translates to `<ArrowDown />` IBM icon
   */
  DOWNLOAD: 'download',
  /**
   * Translates to `<Copy />` IBM icon
   */
  DUPLICATE: 'duplicate',
  /**
   * Translates to `<Edit />` IBM icon
   */
  EDIT: 'edit',
  /**
   * Translates to `<Email />` IBM icon
   */
  ENVELOPE: 'envelope',
  /**
   * Translates to `<WarningFilled />` IBM icon
   */
  ERROR: 'error',
  /**
   * Translates to `<ExpandCategories />` IBM icon
   */
  EXPAND_ALL: 'expand-all',
  /**
   * Translates to `<ViewOffFilled />` IBM icon
   */
  EYE_OFF: 'eye-off',
  /**
   * Translates to `<ViewFilled />` IBM icon
   */
  EYE_ON: 'eye-on',
  /**
   * Translates to `<ViewFilled />` IBM icon
   */
  EYE_OPEN: 'eye-open',
  /**
   * Translates to `<Filter />` IBM icon
   */
  FILTER_LIST: 'filter-list',
  /**
   * Translates to `<FlagFilled />` IBM icon
   */
  FLAG: 'flag',
  /**
   * Translates to `<FloatingIp />` IBM icon
   */
  FLOW_END: 'flow-end',
  /**
   * Translates to `<Folders />` IBM icon
   */
  FOLDER_CLOSE: 'folder-close',
  /**
   * Translates to `<FolderOpen />` IBM icon
   */
  FOLDER_OPEN: 'folder-open',
  /**
   * Translates to `<DataFormat />` IBM icon
   */
  FORM: 'form',
  /**
   * Translates to `<CircleSolid />` IBM icon
   */
  FULL_CIRCLE: 'full-circle',
  /**
   * Translates to `<LocationCurrent />` IBM icon
   */
  GEOLOCATION: 'geolocation',
  /**
   * Translates to `<DirectionMerge />` IBM icon
   */
  GIT_MERGE: 'git-merge',
  /**
   * Translates to `<Branch />` IBM icon
   */
  GIT_PUSH: 'git-push',
  /**
   * Translates to `<EarthFilled />` IBM icon
   */
  GLOBE: 'globe',
  /**
   * Translates to `<IncompleteCancel />` IBM icon
   */
  GRAPH_REMOVE: 'graph-remove',
  /**
   * Translates to `<FavoriteFilled />` IBM icon
   */
  HEART: 'heart',
  /**
   * Translates to `<Favorite />` IBM icon
   */
  HEART_BROKEN: 'heart-broken',
  /**
   * Translates to `<HelpFilled />` IBM icon
   */
  HELP: 'help',
  /**
   * Translates to `<RecentlyViewed />` IBM icon
   */
  HISTORY: 'history',
  /**
   * Translates to `<Home />` IBM icon
   */
  HOME: 'home',
  /**
   * Translates to `<DocumentImport />` IBM icon
   */
  IMPORT: 'import',
  /**
   * Translates to `<Download />` IBM icon
   */
  INBOX: 'inbox',
  /**
   * Translates to `<InformationFilled />` IBM icon
   */
  INFO_SIGN: 'info-sign',
  /**
   * Translates to `<Network_2 />` IBM icon
   */
  INHERITANCE: 'inheritance',
  /**
   * Translates to `<Warning />` IBM icon
   */
  ISSUE: 'issue',
  /**
   * Translates to `<HardwareSecurityModule />` IBM icon
   */
  KEY: 'key',
  /**
   * Translates to `<EyeDropper />` IBM icon
   */
  LAB_TEST: 'lab-test',
  /**
   * Translates to `<Link />` IBM icon
   */
  LINK: 'link',
  /**
   * Translates to `<Locked />` IBM icon
   */
  LOCK: 'lock',
  /**
   * Translates to `<LogIn />` IBM icon
   */
  LOG_IN: 'log-in',
  /**
   * Translates to `<LogOut />` IBM icon
   */
  LOG_OUT: 'log-out',
  /**
   * Translates to `<Book />` IBM icon
   */
  MANUAL: 'manual',
  /**
   * Translates to `<LocationFilled />` IBM icon
   */
  MAP_MARKER: 'map-marker',
  /**
   * Translates to `<Maximize />` IBM icon
   */
  MAXIMIZE: 'maximize',
  /**
   * Translates to `<Image />` IBM icon
   */
  MEDIA: 'media',
  /**
   * Translates to `<Minimize />` IBM icon
   */
  MINIMIZE: 'minimize',
  /**
   * Translates to `<Subtract />` IBM icon
   */
  MINUS: 'minus',
  /**
   * Translates to `<OverflowMenuHorizontal />` IBM icon
   */
  MORE: 'more',
  /**
   * Translates to `<Layers />` IBM icon
   */
  MULTI_SELECT: 'multi-select',
  /**
   * Translates to `<NotificationFilled />` IBM icon
   */
  NOTIFICATIONS: 'notifications',
  /**
   * Translates to `<Building />` IBM icon
   */
  OFFICE: 'office',
  /**
   * Translates to `<RetryFailed />` IBM icon
   */
  OUTDATED: 'outdated',
  /**
   * Translates to `<Attachment />` IBM icon
   */
  PAPERCLIP: 'paperclip',
  /**
   * Translates to `<PauseFilled />` IBM icon
   */
  PAUSE: 'pause',
  /**
   * Translates to `<UserMultiple />` IBM icon
   */
  PEOPLE: 'people',
  /**
   * Translates to `<Person />` IBM icon
   */
  PERSON: 'person',
  /**
   * Translates to `<PiggyBank />` IBM icon
   */
  PIGGY_BANK: 'piggy-bank',
  /**
   * Translates to `<PlayFilledAlt />` IBM icon
   */
  PLAY: 'play',
  /**
   * Translates to `<Add />` IBM icon
   */
  PLUS: 'plus',
  /**
   * Translates to `<Product />` IBM icon
   */
  PROJECTS: 'projects',
  /**
   * Translates to `<ListBoxes />` IBM icon
   */
  PROPERTIES: 'properties',
  /**
   * Translates to `<Redo />` IBM icon
   */
  REDO: 'redo',
  /**
   * Translates to `<Renew />` IBM icon
   */
  REFRESH: 'refresh',
  /**
   * Translates to `<SubtractAlt />` IBM icon
   */
  REMOVE: 'remove',
  /**
   * Translates to `<Renew />` IBM icon
   */
  REPEAT: 'repeat',
  /**
   * Translates to `<RotateCounterclockwise />` IBM icon
   */
  ROTATE_PAGE: 'rotate-page',
  /**
   * Translates to `<DocumentTasks />` IBM icon
   */
  SAVED: 'saved',
  /**
   * Translates to `<Search />` IBM icon
   */
  SEARCH: 'search',
  /**
   * Translates to `<SelectWindow />` IBM icon
   */
  SELECT: 'select',
  /**
   * Translates to `<RadioButtonChecked />` IBM icon
   */
  SELECTION: 'selection',
  /**
   * Translates to `<ChatLaunch />` IBM icon
   */
  SEND_TO: 'send-to',
  /**
   * Translates to `<SettingsAdjust />` IBM icon
   */
  SETTINGS: 'settings',
  /**
   * Translates to `<Launch />` IBM icon
   */
  SHARE: 'share',
  /**
   * Translates to `<Security />` IBM icon
   */
  SHIELD: 'shield',
  /**
   * Translates to `<Store />` IBM icon
   */
  SHOP: 'shop',
  /**
   * Translates to `<SidebarCompanies />` custom icon. DO NOT USE unless 100% coordinated with product/design!
   */
  SIDEBAR_COMPANIES: 'sidebar-companies',
  /**
   * Translates to `<SidebarInbox />` custom icon. DO NOT USE unless 100% coordinated with product/design!
   */
  SIDEBAR_INBOX: 'sidebar-inbox',
  /**
   * Translates to `<SidebarPayables />` custom icon. DO NOT USE unless 100% coordinated with product/design!
   */
  SIDEBAR_PAYABLES: 'sidebar-payables',
  /**
   * Translates to `<SidebarReceivables />` custom icon. DO NOT USE unless 100% coordinated with product/design!
   */
  SIDEBAR_RECEIVABLES: 'sidebar-receivables',
  /**
   * Translates to `<Close />` IBM icon
   */
  SMALL_CROSS: 'small-cross',
  /**
   * Translates to `<Add />` IBM icon
   */
  SMALL_PLUS: 'small-plus',
  /**
   * Translates to `<Checkmark />` IBM icon
   */
  SMALL_TICK: 'small-tick',
  /**
   * Translates to `<StarFilled />` IBM icon
   */
  STAR: 'star',
  /**
   * Translates to `<Star />` IBM icon
   */
  STAR_EMPTY: 'star-empty',
  /**
   * Translates to `<SkipForwardFilled />` IBM icon
   */
  STEP_FORWARD: 'step-forward',
  /**
   * Translates to `<ArrowsHorizontal />` IBM icon
   */
  SWAP_HORIZONTAL: 'swap-horizontal',
  /**
   * Translates to `<Tag />` IBM icon
   */
  TAG: 'tag',
  /**
   * Translates to `<Stamp />` IBM icon
   */
  TAKE_ACTION: 'take-action',
  /**
   * Translates to `<Checkmark />` IBM icon
   */
  TICK: 'tick',
  /**
   * Translates to `<CheckmarkFilled />` IBM icon
   */
  TICK_CIRCLE: 'tick-circle',
  /**
   * Translates to `<TimeFilled />` IBM icon
   */
  TIME: 'time',
  /**
   * Translates to `<ChartLineSmooth />` IBM icon
   */
  TIMELINE_LINE_CHART: 'timeline-line-chart',
  /**
   * Translates to `<TrashCan />` IBM icon
   */
  TRASH: 'trash',
  /**
   * Translates to `<Undo />` IBM icon
   */
  UNDO: 'undo',
  /**
   * Translates to `<JoinRight />` IBM icon
   */
  UNRESOLVE: 'unresolve',
  /**
   * Translates to `<Upload />` IBM icon
   */
  UPLOAD: 'upload',
  /**
   * Translates to `<WarningAltFilled />` IBM icon
   */
  WARNING_SIGN: 'warning-sign',
  /**
   * Translates to `<CircleFill />` IBM icon
   */
  WHATEVER: 'whatever',
  /**
   * Translates to `<ZoomIn />` IBM icon
   */
  ZOOM_IN: 'zoom-in',
  /**
   * Translates to `<ZoomOut />` IBM icon
   */
  ZOOM_OUT: 'zoom-out',
} as const;

export type IconName = ValueOf<typeof IconNames>;

export const legacyIconToRoutableIconMap = {
  [IconNames.ADD]: Add,
  [IconNames.ADD_CIRCLE]: AddAlt,
  [IconNames.ADD_TO_ARTIFACT]: TaskAdd,
  [IconNames.ANNOTATION]: Edit,
  [IconNames.APPLICATION]: Account,
  [IconNames.ARROWS_HORIZONTAL]: PanHorizontal,
  [IconNames.ARROW_DOWN]: ArrowDown,
  [IconNames.ARROW_LEFT]: ArrowLeft,
  [IconNames.ARROW_RIGHT]: ArrowRight,
  [IconNames.ARROW_TOP_RIGHT]: ArrowUpRight,
  [IconNames.ARROW_UP]: ArrowUp,
  [IconNames.ARROW_UP_RIGHT]: ArrowUpRight,
  [IconNames.AUTOMATIC_UPDATES]: UpdateNow,
  [IconNames.BADGE]: Badge,
  [IconNames.BANK_ACCOUNT]: Finance,
  [IconNames.BAN_CIRCLE]: SubtractFilled,
  [IconNames.BLANK]: SquareFill,
  [IconNames.BLOCKED_PERSON]: UserOnline,
  [IconNames.BRIEFCASE]: Portfolio,
  [IconNames.CALENDAR]: Calendar,
  [IconNames.CARET_DOWN]: CaretDown,
  [IconNames.CHART]: ChartLine,
  [IconNames.CHAT]: Chat,
  [IconNames.CHEVRON_DOWN]: ChevronDown,
  [IconNames.CHEVRON_LEFT]: ChevronLeft,
  [IconNames.CHEVRON_RIGHT]: ChevronRight,
  [IconNames.CHEVRON_UP]: ChevronUp,
  [IconNames.CIRCLE]: CircleStroke,
  [IconNames.CIRCLE_ARROW_DOWN]: ChevronDownOutline,
  [IconNames.CIRCLE_ARROW_LEFT]: ChevronLeft,
  [IconNames.CIRCLE_ARROW_RIGHT]: ChevronRight,
  [IconNames.CIRCLE_ARROW_UP]: ChevronUpOutline,
  [IconNames.CITATION]: Quotes,
  [IconNames.CLIPBOARD]: Task,
  [IconNames.CLOUD_DOWNLOAD]: Download,
  [IconNames.CLOUD_UPLOAD]: Upload,
  [IconNames.CODE]: Code,
  [IconNames.COG]: Settings,
  [IconNames.COLLAPSE_ALL]: CollapseCategories,
  [IconNames.COMPARISON]: ChartPopulation,
  [IconNames.CONFIRM]: CheckmarkOutline,
  [IconNames.CROSS]: Close,
  [IconNames.CROWN]: UserCertification,
  [IconNames.DELETE]: CloseOutline,
  [IconNames.DIRECTION_RIGHT]: LocationCurrent,
  [IconNames.DISABLE]: Error,
  [IconNames.DOCUMENT]: DocumentBlank,
  [IconNames.DOCUMENT_OPEN]: DocumentExport,
  [IconNames.DOCUMENT_SHARE]: DocumentExport,
  [IconNames.DOLLAR]: CurrencyDollar,
  [IconNames.DOUBLE_CHEVRON_DOWN]: ChevronDown,
  [IconNames.DOUBLE_CHEVRON_UP]: ChevronUp,
  [IconNames.DOWNLOAD]: ArrowDown,
  [IconNames.DUPLICATE]: Copy,
  [IconNames.EDIT]: Edit,
  [IconNames.ENVELOPE]: Email,
  [IconNames.ERROR]: WarningFilled,
  [IconNames.EXPAND_ALL]: ExpandCategories,
  [IconNames.EYE_OFF]: ViewOffFilled,
  [IconNames.EYE_ON]: UserAdmin,
  [IconNames.EYE_OPEN]: ViewFilled,
  [IconNames.FILTER_LIST]: Filter,
  [IconNames.FLAG]: FlagFilled,
  [IconNames.FLOW_END]: FloatingIp,
  [IconNames.FOLDER_CLOSE]: Folders,
  [IconNames.FOLDER_OPEN]: FolderOpen,
  [IconNames.FORM]: DataFormat,
  [IconNames.FULL_CIRCLE]: CircleSolid,
  [IconNames.GEOLOCATION]: LocationCurrent,
  [IconNames.GIT_MERGE]: DirectionMerge,
  [IconNames.GIT_PUSH]: Branch,
  [IconNames.GLOBE]: EarthFilled,
  [IconNames.GRAPH_REMOVE]: IncompleteCancel,
  [IconNames.HEART]: FavoriteFilled,
  [IconNames.HEART_BROKEN]: Favorite,
  [IconNames.HELP]: HelpFilled,
  [IconNames.HISTORY]: RecentlyViewed,
  [IconNames.HOME]: Home,
  [IconNames.IMPORT]: DocumentImport,
  [IconNames.INBOX]: Download,
  [IconNames.INFO_SIGN]: InformationFilled,
  [IconNames.INHERITANCE]: Network_2,
  [IconNames.ISSUE]: Warning,
  [IconNames.KEY]: HardwareSecurityModule,
  [IconNames.LAB_TEST]: Eyedropper,
  [IconNames.LINK]: Link,
  [IconNames.LOCK]: Locked,
  [IconNames.LOG_IN]: Login,
  [IconNames.LOG_OUT]: Logout,
  [IconNames.MANUAL]: Book,
  [IconNames.MAP_MARKER]: LocationFilled,
  [IconNames.MAXIMIZE]: Maximize,
  [IconNames.MEDIA]: Image,
  [IconNames.MINIMIZE]: Minimize,
  [IconNames.MINUS]: Subtract,
  [IconNames.MORE]: OverflowMenuHorizontal,
  [IconNames.MULTI_SELECT]: Layers,
  [IconNames.NOTIFICATIONS]: NotificationFilled,
  [IconNames.OFFICE]: Building,
  [IconNames.OUTDATED]: RetryFailed,
  [IconNames.PAPERCLIP]: Attachment,
  [IconNames.PAUSE]: PauseFilled,
  [IconNames.PEOPLE]: UserMultiple,
  [IconNames.PERSON]: Person,
  [IconNames.PIGGY_BANK]: PiggyBank,
  [IconNames.PLAY]: PlayFilledAlt,
  [IconNames.PLUS]: Add,
  [IconNames.PROJECTS]: Product,
  [IconNames.PROPERTIES]: ListBoxes,
  [IconNames.REDO]: Redo,
  [IconNames.REFRESH]: Renew,
  [IconNames.REMOVE]: SubtractAlt,
  [IconNames.REPEAT]: Renew,
  [IconNames.ROTATE_PAGE]: RotateCounterclockwise,
  [IconNames.SAVED]: DocumentTasks,
  [IconNames.SEARCH]: Search,
  [IconNames.SELECT]: SelectWindow,
  [IconNames.SELECTION]: RadioButtonChecked,
  [IconNames.SEND_TO]: ChatLaunch,
  [IconNames.SETTINGS]: SettingsAdjust,
  [IconNames.SHARE]: Launch,
  [IconNames.SHIELD]: Security,
  [IconNames.SHOP]: Store,
  [IconNames.SIDEBAR_COMPANIES]: SidebarCompanies,
  [IconNames.SIDEBAR_INBOX]: SidebarInbox,
  [IconNames.SIDEBAR_PAYABLES]: SidebarPayables,
  [IconNames.SIDEBAR_RECEIVABLES]: SidebarReceivables,
  [IconNames.SMALL_CROSS]: Close,
  [IconNames.SMALL_PLUS]: Add,
  [IconNames.SMALL_TICK]: Checkmark,
  [IconNames.STAR]: StarFilled,
  [IconNames.STAR_EMPTY]: Star,
  [IconNames.STEP_FORWARD]: SkipForwardFilled,
  [IconNames.SWAP_HORIZONTAL]: ArrowsHorizontal,
  [IconNames.TAG]: Tag,
  [IconNames.TAKE_ACTION]: Stamp,
  [IconNames.TICK]: Checkmark,
  [IconNames.TICK_CIRCLE]: CheckmarkFilled,
  [IconNames.TIME]: TimeFilled,
  [IconNames.TIMELINE_LINE_CHART]: ChartLineSmooth,
  [IconNames.TRASH]: TrashCan,
  [IconNames.UNDO]: Undo,
  [IconNames.UNRESOLVE]: JoinRight,
  [IconNames.UPLOAD]: Upload,
  [IconNames.WARNING_SIGN]: WarningAltFilled,
  [IconNames.WHATEVER]: CircleFill,
  [IconNames.ZOOM_IN]: ZoomIn,
  [IconNames.ZOOM_OUT]: ZoomOut,
} as const;
