import { DependencyList } from 'react';
import { useKey } from 'react-use';

import { EventKeyValues } from 'constants/events';

/**
 * Attaches keyup event listener on document and call callback upon ESCAPE key press
 * @param {Function} callback
 */
const useEscapeKeyPressed = (callback: () => void, deps?: DependencyList): void => {
  useKey(EventKeyValues.ESCAPE, callback, { event: 'keyup', target: document }, deps);
};

export default useEscapeKeyPressed;
