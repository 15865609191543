import React from 'react';

import ExternalLink from 'components/ExternalLink';
import { Hint, HintText } from 'components/hint';
import { WhiteSpace } from 'components/text';

import helpDocs from 'helpers/helpDocs';

/**
 * We support selecting the country for a newly-created vendor in the Invite Partnership flow
 * and show the currency that we could send to vendors in each country as a hint next to each.
 * This component informs clients about why a country does not appear in the PartnerCountryField selection list.
 */
const MissingPartnerCountryHint: React.VFC = () => (
  <Hint intent="info" multiline>
    <HintText>
      Can’t find your country? Learn more about
      <WhiteSpace />
      <ExternalLink
        className="font-color--blue-bold font-weight--regular remove-hover-underline"
        href={helpDocs.PARTNER_COUNTRY_CURRENCY_HINT}
      >
        supported countries and currencies
      </ExternalLink>
    </HintText>
  </Hint>
);

export default MissingPartnerCountryHint;
