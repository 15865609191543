import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { AutosizeTextareaBlockField } from 'components';

import * as propTypes from 'constants/propTypes';
import { field } from 'constants/styles/formStyles';

import { useMemoizedConditionalValidate } from 'hooks';

import { useDynamicFieldRequiredValidators } from 'modules/table/hooks';

/**
 * Renders a text input field to be rendered in a block section
 * @param {ComponentProps} props
 * @param {Object} props.formValues
 * @param {function} props.getIsDisabled
 * @param {string} props.name
 * @param {string} props.placeholder
 * @param {number} props.maxLength
 * @param {Function} props.validate
 * @returns {StatelessComponent}
 */
const TextBlockField = (props) => {
  const { formValues, getIsDisabled, name, placeholder, maxLength } = props;

  const { ui } = formValues;

  const { isRequired, validators } = useDynamicFieldRequiredValidators(props);
  const cachedValidators = useMemoizedConditionalValidate(!ui?.blockDetailsSections, validators);

  return (
    <Field
      className={field.xl.full}
      component={AutosizeTextareaBlockField}
      isDebounced
      isDisabled={getIsDisabled(formValues)}
      isRequired={isRequired}
      maxLength={maxLength}
      name={name}
      placeholder={placeholder}
      type="text"
      validate={cachedValidators}
    />
  );
};

TextBlockField.propTypes = {
  ...propTypes.baseFieldSharedPropTypes,
  placeholder: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
};

TextBlockField.defaultProps = {
  ...propTypes.baseFieldSharedDefaultProps,
  maxLength: undefined,
};

export default TextBlockField;
