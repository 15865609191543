import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { updatePartnerField } from 'actions/partnership';

import type { ReduxState } from 'interfaces/redux';

import { PartnershipNameSettingsForm } from 'modules/dashboard/createPartnership/components/PartnershipNameSettings/components';
import {
  DispatchProps,
  ExternalStateProps,
} from 'modules/dashboard/createPartnership/components/PartnershipNameSettings/components/PartnershipNameSettingsFormProps.types';

import {
  createPartnershipFormCompanyTypeSelector,
  createPartnershipFormNameSelector,
  createPartnershipFormPartnershipTypeSelector,
  createPartnershipFormPartnerLedgerNameSelector,
  createPartnershipFormPartnerNameSelector,
} from 'selectors/forms';

export const mapStateToProps = createStructuredSelector<ReduxState, ExternalStateProps>({
  companyType: createPartnershipFormCompanyTypeSelector,
  displayName: createPartnershipFormNameSelector,
  partnershipType: createPartnershipFormPartnershipTypeSelector,
  partnerLedgerName: createPartnershipFormPartnerLedgerNameSelector,
  partnerName: createPartnershipFormPartnerNameSelector,
});

export const mapDispatchToProps: DispatchProps = {
  updatePartnerField,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnershipNameSettingsForm);
