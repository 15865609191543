import classNames from 'classnames';
import React from 'react';

import { SafeAndSecureBadge } from 'components/badges';
import { BrandLogo } from 'components/brand';
import { TermsOfServiceLink, HelpDocsLink, LinkNewPage, PrivacyPolicyLink } from 'components/link';

import { LOGO } from 'global/images/platform';

import helpDocs from 'helpers/helpDocs';

import { BrandWrapperV2Props } from './BrandWrapperV2.types';

import './BrandV2.scss';

const BrandWrapperV2: React.FC<BrandWrapperV2Props> = ({
  children,
  className,
  helpDocsLink,
  showFooter = true,
  showLogo,
}) => {
  let helpLink = <HelpDocsLink />; // e.g ADDENDA_RECORDS

  if (helpDocs[helpDocsLink]) {
    helpLink = (
      <LinkNewPage className="primary" href={helpDocs[helpDocsLink]}>
        Help Docs
      </LinkNewPage>
    );
  }
  return (
    <div
      className={classNames('brandedV2--container', {
        'has--footer': !!showFooter,
        [className]: !!className,
      })}
    >
      {showLogo && (
        <div className="brandedV2--logo-container">
          <BrandLogo imageSrc={LOGO.NAVY} />
        </div>
      )}

      {children}

      {showFooter && (
        <div className="brandedV2--footer-container">
          <div className="brandedV2--footer">
            <div className="brandedV2--footer__nav-container">
              <ul className="brandedV2--footer__nav-links">
                <li>
                  <SafeAndSecureBadge includeLink />
                </li>
                <li>
                  <TermsOfServiceLink />
                </li>
                <li>
                  <PrivacyPolicyLink />
                </li>
                <li>{helpLink}</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandWrapperV2;
