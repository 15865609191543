import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { change } from 'redux-form';

import useSelectorWithProps from 'hooks/useSelectorWithProps/useSelectorWithProps';

import { commonFormFields } from 'constants/formFields';

import { ItemCurrencyCodeKey } from 'enums/items';

import { mapCurrencyCodeToSelectOption } from 'helpers/currency';
import { isExternalPathAny } from 'helpers/external';

import type { Partnership } from 'interfaces/partnerships';

import { Options, UseBankAccountReceivingCurrency } from './useBankAccountReceivingCurrency.types';

/**
 * Given form options and selectors, returns info needed by currency code select
 * component. This includes field label and name, weather it is locked or not, formatted
 * options to show and tooltip text in case it is locked.
 * It also binds effects to:
 * - Set the initial currency code recevier value for the provided form
 * - Re-trigger the request of bank fields whenever that value changes.
 * @param options
 * @param options.formName - Name of the form affected
 * @param options.availableCurrencies - available currencies as flat strings ['USD', 'EUR']
 * @param options.itemCurrencyCodeSelector - Selector to get the currency code receiver from an item
 * @param options.partnershipSelector - Selector to get the current partnership
 * @param options.selectedCurrencyCodeSelector - Selector to get the currency code receiver from a form
 * @param options.companyTypeSelector - Selector to get the vendor company company type
 */
export const useBankAccountReceivingCurrency = ({
  formName,
  availableCurrencies,
  itemCurrencyCodeSelector,
  partnershipSelector,
}: Options): UseBankAccountReceivingCurrency => {
  const dispatch = useDispatch();
  const params = useParams<{ id?: Id }>();
  const isExternalFlow = isExternalPathAny();

  const itemCurrencyCodeReceiver = useSelector(itemCurrencyCodeSelector);
  const partnership = useSelectorWithProps<Partnership, Id>(partnershipSelector, params?.id);

  const defaultCurrencyCodeKey = isExternalFlow ? ItemCurrencyCodeKey.COMPANY : ItemCurrencyCodeKey.PARTNER;
  const partnershipCurrencyCode = partnership?.[defaultCurrencyCodeKey];
  const activeCurrencyCode = itemCurrencyCodeReceiver || partnershipCurrencyCode;

  const hasSingleOption = availableCurrencies?.length === 1;
  const options = availableCurrencies?.map(mapCurrencyCodeToSelectOption);
  const tooltipText = hasSingleOption ? `Receiving currency has been set to ${availableCurrencies[0]}` : undefined;

  useEffect(() => {
    dispatch(change(formName, commonFormFields.CURRENCY_CODE, activeCurrencyCode));
  }, [dispatch, formName, activeCurrencyCode]);

  return {
    fieldLabel: 'Account Currency',
    fieldName: commonFormFields.CURRENCY_CODE,
    isLocked: hasSingleOption,
    options,
    tooltipText,
  };
};
