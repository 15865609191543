import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fundingAccountFundingSourcesFromFundingAccountPropSelector } from 'queries/fundingCompoundSelectors';
import { partnershipFundingAccountForFundingAccountPropSelector } from 'queries/partnershipCompoundSelectors';

import {
  fundingInfoAddressForFundingAccountPropSelector,
  fundingInfoBankAccountForFundingAccountPropSelector,
  fundingInfoInternationalForFundingAccountPropSelector,
} from 'selectors/fundingSelectors';

import UpdatePaymentMethodFundingAccount from './UpdatePaymentMethodFundingAccount';
import { StateProps } from './UpdatePaymentMethodFundingAccount.types';

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  fundingInfoAddress: fundingInfoAddressForFundingAccountPropSelector,
  fundingInfoBankAccount: fundingInfoBankAccountForFundingAccountPropSelector,
  fundingInfoInternational: fundingInfoInternationalForFundingAccountPropSelector,
  fundingSources: fundingAccountFundingSourcesFromFundingAccountPropSelector,
  partnershipFundingAccount: partnershipFundingAccountForFundingAccountPropSelector,
});

export default connect(mapStateToProps)(UpdatePaymentMethodFundingAccount);
