import React from 'react';

import { hasLength } from 'helpers/utility';

import { AddBankAccountManuallyButton } from 'modules/fundingAccount';

import {
  AcceptPendingPaymentsCheckboxField,
  UpdatePaymentMethodFundingAccounts,
  UpdatePaymentFlowFormLabel,
} from './components';
import { UpdatePaymentMethodInternationalLayoutProps } from './UpdatePaymentMethodInternationalLayout.types';

const UpdatePaymentMethodInternationalLayout: React.FC<UpdatePaymentMethodInternationalLayoutProps> = ({
  fundingAccounts,
  isUpdatePaymentMethodFlow,
}) => {
  const hasFundingAccounts = hasLength(fundingAccounts);

  if (isUpdatePaymentMethodFlow && hasFundingAccounts) {
    return (
      <>
        <UpdatePaymentFlowFormLabel />
        <AddBankAccountManuallyButton />
        <UpdatePaymentFlowFormLabel labelText="Select preferred payment method" />
        <UpdatePaymentMethodFundingAccounts fundingAccounts={fundingAccounts} />
        <AcceptPendingPaymentsCheckboxField />
      </>
    );
  }

  if (hasFundingAccounts) {
    return (
      <>
        <UpdatePaymentMethodFundingAccounts fundingAccounts={fundingAccounts} />
        <AcceptPendingPaymentsCheckboxField />
        <UpdatePaymentFlowFormLabel />
        <AddBankAccountManuallyButton />
      </>
    );
  }

  return <AddBankAccountManuallyButton />;
};

export default UpdatePaymentMethodInternationalLayout;
