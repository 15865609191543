// items keys starting with this will not be cleared out of local storage between sessions.
export const PERSISTENCE_KEY = 'persistence/';
export const PAGE_SIZE = '-page-size';

export enum PERSISTENCE_KEYS {
  BULK_ITEMS_PAGE_SIZE = 'bulk-imports-page-size',
  PAYABLES_PAGE_SIZE = 'payables-page-size',
  RECEIVABLES_PAGE_SIZE = 'receivables-page-size',
  INBOX_PAGE_SIZE = 'inbox-page-size',
}
