import { internationalBeneficiaryFieldName } from '@routable/fund-routes';
import { requiredValidator } from '@routable/shared';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { Field, change } from 'redux-form';

import { SelectFieldV2 } from 'components';
import { useFormattedCountries } from 'components/form/CountryField/hooks';

import { useCountryCodesContext, useFieldValue } from 'hooks';

import type { NationalityFieldProps } from './NationalityField.types';

export const NationalityField = ({ className, defaultValue, form }: NationalityFieldProps) => {
  const dispatch = useDispatch();
  const selectedNationality = useFieldValue(form, internationalBeneficiaryFieldName.Enum.country_of_nationality);
  const countryCodeMap = useCountryCodesContext();
  const { prioritizedCountriesWithNationalities } = useFormattedCountries(countryCodeMap);

  // Setting the "default value" this way as redux-form doesn't play
  // nicely with async default values
  useEffectOnce(() => {
    if (!selectedNationality) {
      dispatch(change(form, internationalBeneficiaryFieldName.Enum.country_of_nationality, defaultValue));
    }
    // Remove the value if component is unmounted
    return () => {
      dispatch(change(form, internationalBeneficiaryFieldName.Enum.country_of_nationality, null));
    };
  });

  return (
    <div className={className}>
      <Field
        component={SelectFieldV2}
        isRequired
        label="Nationality"
        name={internationalBeneficiaryFieldName.enum.country_of_nationality}
        options={prioritizedCountriesWithNationalities}
        validate={[requiredValidator]}
      />
    </div>
  );
};
