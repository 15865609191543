import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import React from 'react';

import { legacyIconToRoutableIconMap, type IconName } from './IconMapping';

type IconProps = {
  color?: string;
  className?: string;
  size?: number;
  isCustomIcon?: boolean;
  icon: IconName;
  marginRight?: string | number;
  marginLeft?: string | number;
  marginTop?: string | number;
} & Record<string, unknown>;

/** @deprecated migrate to use the \@carbon/icons-react package directly */
const Icon = ({
  className,
  color,
  icon,
  isCustomIcon,
  marginLeft,
  marginRight,
  marginTop,
  size,
  ...rest
}: IconProps) => {
  if (!isCustomIcon) {
    const MappedIcon = legacyIconToRoutableIconMap[icon];

    if (!MappedIcon) {
      // Log message to sentry, this means that we have missed one of the icons previously used
      Sentry.captureMessage(`Icon ${icon} is not supported`, { level: 'warning', extra: { icon } });
      return null;
    }

    return (
      <MappedIcon
        className={clsx('inline-block', className)}
        data-icon={icon}
        data-testid={`icon-${icon}`}
        fill={color ?? 'currentColor'}
        size={size}
        style={{ marginLeft, marginRight, marginTop }}
        {...rest}
      />
    );
  }

  /**
   * USE WITH CAUTION!
   * This type of icon rendering is meant only as a fallback option for cases
   * where we include icons that are not part of the Evergreen icon pack. In 99%
   * of the cases, Evergreen icon *should* be used.
   *
   * Specifically, this is added to support custom icons in ButtonV2 component. Support
   * for icon size, color... and other style properties is not as strong as with Evergreen
   * one and appropriate workarounds with classNames must be applied to the icon in order
   * to get the result that you want.
   */
  return <span className={clsx(`icon ${icon}`, className)} />;
};

export default Icon;
