import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputField } from 'components';

import { getErrorsFromFieldState } from '../helpers';

import type { RenderTextInputProps } from './RenderTextInput.types';

const RenderTextInput = ({ isRequired, name, placeholder, shouldUnregister }: RenderTextInputProps): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <InputField
          errors={getErrorsFromFieldState(fieldState, field.name)}
          isRequired={!!isRequired}
          isUncontrolled
          name={field.name}
          onBlur={field.onBlur}
          onChange={field.onChange}
          placeholder={placeholder}
          setRef={field.ref}
          value={field.value}
        />
      )}
      rules={{ required: isRequired }}
      shouldUnregister={shouldUnregister}
    />
  );
};

export default RenderTextInput;
