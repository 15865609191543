import React from 'react';
import { Field } from 'redux-form';

import { Radio, FormFieldLabel, FormControl, PerformActionOnRoutableButNotLedgerHint } from 'components';

import { LedgerOptionsFormValue } from 'constants/ledger';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { isExistingItemActionMarkAsPaid } from 'helpers/items';
import { capitalize } from 'helpers/stringHelpers';

import type { LedgerOptionsFormSectionProps } from './LedgerOptionsFormSection.types';

/**
 * Component that allows an RCTM to select if an action should be performed on a connected ledger
 */
export const LedgerOptionsFormSection = ({
  action,
  isDoNotUpdateLedgerSelected,
  item,
  ledgerName,
  name,
}: LedgerOptionsFormSectionProps) => {
  if (!ledgerName) {
    return null;
  }

  const isActionMarkAsPaid = isExistingItemActionMarkAsPaid(action);
  const actionWord = isActionMarkAsPaid ? 'mark as paid' : 'cancel';
  const ledgerActionWord = isActionMarkAsPaid ? `mark as paid in ${ledgerName}` : `delete in ${ledgerName}`;

  const updateLedgerOptionText = `${capitalize(actionWord)} in ${PLATFORM_DISPLAY_SHORT_NAME} and ${ledgerActionWord}`;

  return (
    <>
      <FormControl className="flex-direction--column">
        <FormFieldLabel>{`${ledgerName} options`}</FormFieldLabel>
        <Field
          className="margin-bottom--m"
          component={Radio}
          id={LedgerOptionsFormValue.UPDATE_LEDGER}
          name={name}
          optionText={updateLedgerOptionText}
          type="radio"
          value={LedgerOptionsFormValue.UPDATE_LEDGER}
        />
        <Field
          component={Radio}
          id={LedgerOptionsFormValue.DO_NOT_UPDATE_LEDGER}
          name={name}
          optionText={`${capitalize(actionWord)} in ${PLATFORM_DISPLAY_SHORT_NAME} and do not update in ${ledgerName}`}
          type="radio"
          value={LedgerOptionsFormValue.DO_NOT_UPDATE_LEDGER}
        />
      </FormControl>
      {isDoNotUpdateLedgerSelected && (
        <PerformActionOnRoutableButNotLedgerHint action={action} item={item} ledgerName={ledgerName} />
      )}
    </>
  );
};
