import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { BoxV2 } from 'components';

import { RESET_PASSWORD } from 'constants/routes';

import { SSOProviders } from 'enums/sso';

import { asPath } from 'helpers/routeHelpers';

import ChangeWorkspace from '../ChangeWorkspace';
import EmailPasswordFormContainer from '../EmailPasswordForm';
import LoginWithGoogleButton from '../LoginWithGoogleButton/LoginWithGoogleButton';
import LoginWithSSOButton from '../LoginWithSSOButton/LoginWithSSOButton';

import type { RequiredSSOLoginFormProps } from './RequiredSSOLoginForm.types';
import './RequiredSSOLoginForm.scss';

/**
 * RequiredSSOLoginForm
 * Used when the company has sso settings as required.
 */
const RequiredSSOLoginForm: React.FC<RequiredSSOLoginFormProps> = ({
  currentSSOSettings,
  onSSOLogin,
  isSSOSubmitting,
}) => {
  let buttonComponent: React.ReactElement;
  if (currentSSOSettings.ssoProvider === SSOProviders.GOOGLE_OAUTH2) {
    buttonComponent = <LoginWithGoogleButton isLoading={isSSOSubmitting} onClick={onSSOLogin} />;
  } else {
    buttonComponent = <LoginWithSSOButton isLoading={isSSOSubmitting} onClick={onSSOLogin} />;
  }

  const [showEmailPasswordForm, setShowEmailPasswordForm] = React.useState(false);

  const toggleLoginForm = React.useCallback(() => {
    setShowEmailPasswordForm(!showEmailPasswordForm);
  }, [showEmailPasswordForm]);

  return (
    <BoxV2
      className="box-v2--container--login required-sso-login-form"
      subtitle={<ChangeWorkspace />}
      title="Log in with single sign-on"
    >
      {showEmailPasswordForm ? (
        <>
          <EmailPasswordFormContainer loginBtnText="Log in as admin" usernameFieldLabel="Admin login" />
          <div className="links-container">
            <Link
              className={classNames({
                link: true,
                'margin-bottom--xm': true,
              })}
              to={asPath(RESET_PASSWORD)}
            >
              Forgot your password?
            </Link>
            <a className="link" onClick={onSSOLogin} role="presentation">
              Login with Google
            </a>
          </div>
        </>
      ) : (
        <>
          {buttonComponent}
          <div className="margin-top--m-large text-align--center">
            <span
              className={classNames({
                'font-sm': true,
                'font-weight--regular': true,
                'font-color--grey--xx-dark': true,
                'margin-right--sm': true,
              })}
            >
              Admins can also
            </span>
            <a
              className={classNames({
                'dark-blue': true,
                'font-sm': true,
              })}
              onClick={toggleLoginForm}
              role="presentation"
            >
              login here
            </a>
          </div>
        </>
      )}
    </BoxV2>
  );
};

export default RequiredSSOLoginForm;
