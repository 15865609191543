import React from 'react';

import { HintContainer, HintText } from './ApprovalRulesBypassedHint.styles';
import type { ApprovalRulesBypassedHintProps } from './ApprovalRulesBypassedHint.types';

/**
 * Component rendering a hint outlining that the approval rules have been
 * bypassed
 * @param props
 */
const ApprovalRulesBypassedHint = ({ bypasserName }: ApprovalRulesBypassedHintProps): JSX.Element => {
  const text = `${bypasserName} bypassed the approval rules`;

  return (
    <HintContainer>
      <HintText>{text}</HintText>
    </HintContainer>
  );
};

export default ApprovalRulesBypassedHint;
