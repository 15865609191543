import { sidePanelNameAddOrUpdateVendorRegisteredAddress } from 'constants/sidePanels';

import * as types from 'types/sidePanels';

import {
  AddVendorRegisteredAddressSideSheetPayloadAction,
  AddVendorRegisteredAddressSideSheetState,
} from './addOrUpdateVendorRegisteredAddressReducer.types';

const initialState: AddVendorRegisteredAddressSideSheetState = {
  action: null,
  open: false,
  partnershipId: null,
};

/**
 * Redux reducer for Add Vendor Registered Address Side Sheet
 * @param state - Reducer's state
 * @param action - Dispatched action
 */
const addVendorRegisteredAddressSideSheetReducer = (
  state = initialState,
  action: AddVendorRegisteredAddressSideSheetPayloadAction,
): AddVendorRegisteredAddressSideSheetState => {
  switch (action.type) {
    case types.SIDE_PANEL_OPEN: {
      // to trigger this, use the generic openSidePanel actionCreator and pass this sidepanel's name
      return action.payload.name === sidePanelNameAddOrUpdateVendorRegisteredAddress
        ? { ...state, ...action.payload.state, open: true }
        : state;
    }

    case types.SIDE_PANEL_CLOSE: {
      // to trigger this, use the generic closeSidePanel actionCreator and pass this sidepanel's name
      return action.payload.name === sidePanelNameAddOrUpdateVendorRegisteredAddress
        ? { ...state, action: null, open: false, partnershipId: null }
        : state;
    }

    default:
      return state;
  }
};

export { initialState };
export default addVendorRegisteredAddressSideSheetReducer;
