import styled from 'styled-components';

import { containerWidthMap } from 'modules/signup-v3/constants/container';

export const Container = styled.div`
  max-width: ${containerWidthMap.medium};
`;

export const Title = styled.h3`
  color: var(--grey--xx-dark);
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

export const FieldWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 0.5rem;
`;

export const HintLink = styled.button`
  color: var(--blue--bold);
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
`;

export const HelperContainer = styled.div`
  margin-top: 2.188rem;
`;
