import { SSOSetupFormState } from 'interfaces/sso';

/**
 * Returns submit button text depending on company's SSO settings
 */
export const getSubmitButtonText = (
  companySSOSettings: SSOSetupFormState,
  loginWithPassword: boolean,
  canManageSsoSettings: boolean,
): string => {
  const isSSOTurnedOnAndRequired = companySSOSettings && companySSOSettings.ssoRequired;
  const isSSOTurnedOnAndOptional = companySSOSettings && !companySSOSettings.ssoRequired;

  if (!loginWithPassword && !canManageSsoSettings) {
    if (isSSOTurnedOnAndRequired) {
      return 'Create account and log in with Google';
    }

    if (isSSOTurnedOnAndOptional) {
      return 'Log in with Google';
    }
  }

  return 'Create my account';
};
