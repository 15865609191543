import React from 'react';

import { formatCurrency } from 'helpers/currencyFormatter';

import { useCurrencyContext } from 'hooks';

import { CurrencyAmountTextPlainProps } from './CurrencyAmountTextPlain.types';

/**
 * Return formatted amount using currencyContext.
 */
const CurrencyAmountTextPlain: React.FC<CurrencyAmountTextPlainProps> = ({ amount, currencyCode }) => {
  const currencyCodeMap = useCurrencyContext();

  const options = {
    format: '%v %s',
    symbol: currencyCode,
  };

  return <>{formatCurrency(amount, currencyCode, currencyCodeMap, options)}</>;
};

export default CurrencyAmountTextPlain;
