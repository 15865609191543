/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tooltip } from '@routable/components';
import React, { ReactNode, ComponentProps, ReactElement } from 'react';

import type { MuiTooltipProps, MuiPopperPlacementType } from './MuiTooltipPoly.types';

const getPositionFromPlacement = (placement?: MuiPopperPlacementType) => {
  if (placement?.includes?.('bottom')) {
    return 'bottom';
  }
  if (placement?.includes?.('left')) {
    return 'left';
  }
  if (placement?.includes?.('right')) {
    return 'right';
  }
  return 'top';
};

/** @deprecated use @routable/components's Tooltip instead */
export function MuiTooltipPoly({
  TooltipProps,
  TooltipBodyStyles,
  TooltipBody,
  // removed to avoid pushing into props, however not really able to fully remove
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TooltipContentContainerStyles,
  text,
  children,
  placement,
  position = placement,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useTooltipContentContainer = true,
  ...props
}: {
  TooltipProps?: Partial<MuiTooltipProps>;
  TooltipBodyStyles?: React.CSSProperties;
  TooltipBody?: ReactNode;
  TooltipContentContainerStyles?: React.CSSProperties;
  text: ReactNode;
  children: ReactElement<any, any>;
  useTooltipContentContainer?: boolean;
  placement?: 'bottom' | 'left' | 'right' | 'top';
  'data-testid'?: string;
} & Partial<ComponentProps<typeof Tooltip>>): JSX.Element {
  return text ? (
    <Tooltip
      data-testid={props['data-testid']}
      {...TooltipProps}
      {...props}
      position={getPositionFromPlacement(position || TooltipProps?.placement)}
      tooltip={TooltipBody || <div style={TooltipBodyStyles}>{text}</div>}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
}

/** @deprecated use @routable/components's Tooltip instead */
export function LightTooltip({
  text,
  children,
  TooltipBaseProps,
}: {
  text: ReactNode;
  children: ReactElement<any, any>;
  TooltipBaseProps?: Partial<ComponentProps<typeof MuiTooltipPoly>>;
}): JSX.Element {
  return (
    <MuiTooltipPoly text={text} variant="light" {...TooltipBaseProps}>
      {children}
    </MuiTooltipPoly>
  );
}
/** @deprecated use @routable/components's Tooltip instead */
export function DarkTooltip({
  text,
  children,
  TooltipBaseProps,
}: {
  text: ReactNode;
  children: ReactElement<any, any>;
  TooltipBaseProps?: Partial<ComponentProps<typeof MuiTooltipPoly>>;
}): JSX.Element {
  return (
    <MuiTooltipPoly text={text} variant="dark" {...TooltipBaseProps}>
      {children}
    </MuiTooltipPoly>
  );
}
