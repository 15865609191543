import React from 'react';

import { Icon, TooltipMUIConditionalWrapper } from 'components';

import { colors } from 'constants/styles';

import type { CompanyAddressDataCardActionProps } from './CompanyAddressDataCardAction.types';

/**
 * Component rendering Action Icon in the Company Address Data Card
 * @see {CompanyAddressDataCard}
 * @param props - Component props
 */
const CompanyAddressDataCardAction = React.forwardRef<HTMLSpanElement, CompanyAddressDataCardActionProps>(
  ({ icon, tooltipTitle }, ref) => (
    <span className="company-address-data-card__expandable__item__action-icon" ref={ref}>
      <TooltipMUIConditionalWrapper tooltipProps={{ title: tooltipTitle }}>
        <Icon color={colors.colorGreyDarkHex} icon={icon} />
      </TooltipMUIConditionalWrapper>
    </span>
  ),
);

export default CompanyAddressDataCardAction;
