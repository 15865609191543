import { getQueryString } from 'helpers/queryParams';

import { RequestParams } from 'interfaces/request';

import { BASE_API_URL } from './index';

export const webhookBaseEndpoint = `${BASE_API_URL}/webhooks/`;
export const webhookSettingsBaseEndpoint = `${BASE_API_URL}/webhook_settings/`;

export const getWebhooksEndpoint = (params: RequestParams = {}): string => {
  if (params.url) {
    // Use URL specified
    return params.url;
  }

  return `${webhookBaseEndpoint}?${getQueryString(params)}`;
};

export const getWebhookSettingsInstanceEndpoint = (id: string): string => `${webhookSettingsBaseEndpoint}${id}/`;

export const getWebhookSettingsTestEndpoint = (id: string): string => `${webhookSettingsBaseEndpoint}${id}/test/`;
