import { ElementType } from 'react';

import type { SchemaFieldGroup } from '../../TaxFormRenderer.types';

import {
  FieldGroupPermanentAddress,
  FieldGroupMailingAddress,
  FieldGroupTaxTreatyBenefits,
  FieldGroupTaxPayerIdentificationNumber,
  FieldGroupFormCertification,
  FieldGroupEntityInformation,
  FieldGroupBase,
  FieldGroupTaxExemptions,
} from './components';
import { FieldGroupGenericAddress } from './components/FieldGroupAddress';

export const fieldGroupMap: Record<SchemaFieldGroup, ElementType> = {
  base: FieldGroupBase,
  entityInformation: FieldGroupEntityInformation,
  formCertification: FieldGroupFormCertification,
  genericAddress: FieldGroupGenericAddress,
  mailingAddress: FieldGroupMailingAddress,
  permanentResidenceAddress: FieldGroupPermanentAddress,
  taxpayerIdentification: FieldGroupTaxPayerIdentificationNumber,
  taxTreatyBenefits: FieldGroupTaxTreatyBenefits,
  taxExemptions: FieldGroupTaxExemptions,
};
