import classNames from 'classnames';
import React from 'react';

import type { PaymentMethodBaseProps } from './PaymentMethodBase.types';

import './PaymentMethodBase.scss';

/**
 * Base container for payment method component.
 * Given a variant of payment method, this will provide styles and classes.
 */
const PaymentMethodBase: React.FC<PaymentMethodBaseProps> = ({ children, className, input, variant }) => (
  <div
    className={classNames('payment_method', `payment_method--${variant}-style`, className, {
      'payment_method--input-disabled': input?.disabled,
    })}
  >
    {children}
  </div>
);

export default PaymentMethodBase;
