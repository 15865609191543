import React from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonV2 } from 'components/buttonV2';
import {
  Modal,
  ModalFooter,
  ModalFooterButtonCancel,
  ModalContentWrapper,
  ModalText,
  ModalTitle,
} from 'components/modal';
import { UniversalChildren } from 'components/utility';

import { Intent } from 'constants/ui';

import { PromptProps } from './Prompt.types';

/**
 * A custom implementation of the `Prompt` component that was part of react-router up through v5 (removed in v6).
 * `Prompt` blocks navigation pending user confirmation, when the `shouldPrompt` prop is `true`.
 * This implementation uses the `block` function from the underlying `history` package, which remains supported in
 * later versions of `history`.
 * Writing this custom also allows us to use our own alert dialog for confirmation; the Prompt component from
 * react-router only allows for the native browser alert box.
 */
const Prompt = (props: PromptProps): JSX.Element => {
  const {
    cancelText = 'Cancel',
    children,
    confirmText = 'Confirm',
    message,
    onCancel,
    onConfirm,
    shouldPrompt,
    title,
  } = props;

  const [showModal, setShowModal] = React.useState(false);
  const [shouldNavigate, setShouldNavigate] = React.useState<boolean>();
  const [attemptedPath, setAttemptedPath] = React.useState<string>();
  // TODO: Bishop said this `any` type is ok, as we are working on getting to react-router-dom v6,
  //  and the type definition of `history.block` is an issue in the version we are currently using,
  //  so we are bypassing until the upgrade happens
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const history: any = useHistory();

  const unblockRef = React.useRef<UnregisterCallback>();

  const confirmPrompt = React.useCallback(
    async (response: boolean) => {
      // sets the shouldNavigate flag, which will be our cue to push the user through to their
      // attempted next route if they confirmed
      setShouldNavigate(response);

      if (response) {
        // if navigation is confirmed, remove the current blocker
        unblockRef.current?.();

        if (onConfirm) {
          await onConfirm();
        }
      } else if (onCancel) {
        await onCancel();
      }
    },
    [onCancel, onConfirm],
  );

  React.useEffect(() => {
    // we want to make sure the value of `shouldNavigate` flips anytime `shouldPrompt` changes
    setShouldNavigate(!shouldPrompt);
  }, [shouldPrompt]);

  React.useEffect(() => {
    // in this case, we've been directed to prompt before navigation, but the user hasn't yet confirmed
    // any navigation action, so we'll implement a navigation block
    if (shouldPrompt && !shouldNavigate) {
      unblockRef.current = history.block((loc) => {
        setAttemptedPath(loc.pathname);
        // this returns a promise, but if we await its resolution we'll get a navigation
        // action before the user has confirmed anything
        setShowModal(true);
        return shouldNavigate;
      });
    }

    return () => {
      // make sure we don't leave any navigation blockers turned on
      unblockRef.current?.();
    };
  }, [attemptedPath, confirmPrompt, history, shouldNavigate, shouldPrompt]);

  React.useEffect(() => {
    // if we've been directed to prompt before navigation, and the user has already confirmed a nav action,
    // we should push the user through to their attempted next route
    if (shouldPrompt && shouldNavigate) {
      history.push(attemptedPath);
    }
  }, [attemptedPath, history, shouldNavigate, shouldPrompt]);

  // passing children to this component isn't necessary, but depending on how you'd like things to rerender,
  // this may sometimes be desired
  return (
    <>
      <Modal
        body={
          <div>
            <ModalContentWrapper>
              <ModalTitle>{title}</ModalTitle>
              <ModalText>{message}</ModalText>
            </ModalContentWrapper>
            <ModalFooter
              style={{
                backgroundColor: 'var(--color-white)',
              }}
            >
              <ModalFooterButtonCancel
                buttonText={cancelText}
                onCloseModal={() => {
                  confirmPrompt(false);
                  setShowModal(false);
                }}
              />
              <ButtonV2
                intent={Intent.PRIMARY}
                onClick={() => {
                  confirmPrompt(true);
                  setShowModal(false);
                }}
              >
                {confirmText}
              </ButtonV2>
            </ModalFooter>
          </div>
        }
        hasInlineFooter
        hideHeader
        modalState={showModal}
        onCloseModal={() => setShowModal(false)}
        overrideStyle={{
          content: {
            width: '31.25rem',
          },
          overlay: {
            backgroundColor: 'rgba(76,84,103,0.3)',
          },
        }}
      />

      <UniversalChildren>{children}</UniversalChildren>
    </>
  );
};

export default Prompt;
