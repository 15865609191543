import { createSelector } from '@reduxjs/toolkit';

import { ReduxState } from 'interfaces/redux';

const getApprovalSummary = (reduxState: ReduxState) => reduxState.approvalSummary;

export const earItemSummarySelector = createSelector(
  [getApprovalSummary],
  (approvalSummary) => approvalSummary.itemSummary,
);
