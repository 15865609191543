import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ssoHandleRedirectRoutine } from 'actions/routines/auth';

import { BrandWrapperV2, RoutableHead } from 'components';

import { getCompanyNamespaceFromUrl, getDomainWithOrWithoutCompanyNamespace } from 'helpers/urls';

import { useLoginIdp } from 'hooks/useLoginIdP';

import { Help } from 'modules/app/components';
import { DashboardNotificationBar } from 'modules/dashboard/components/Dashboard/components';
import { IsLoadingHasWrapper } from 'modules/isLoading';

import { authNamespaceValueSelector } from 'selectors/forms';

import { NamespaceForm, NoSSOLoginForm, OptionalSSOLoginForm, RequiredSSOLoginForm } from './components';
import type { LoginProps } from './Login.types';

/**
 * Login
 * Conditionally renders the Login Form depending on company sso settings.
 */
const Login = ({
  authNamespaceHasSubmitSucceeded,
  currentSSOSettings,
  isSSOFetching,
  isSSORedirecting,
  onFetchCompanySettingsSSOByNamespace,
}: LoginProps) => {
  const namespace = useSelector(authNamespaceValueSelector);

  const companyNamespace = getCompanyNamespaceFromUrl();

  const dispatch = useDispatch();
  const { idp, companyId } = useLoginIdp();

  React.useEffect(() => {
    if (companyNamespace && !isSSORedirecting) {
      onFetchCompanySettingsSSOByNamespace({
        params: { namespace: companyNamespace },
      });
    }
  }, [companyNamespace, isSSORedirecting, onFetchCompanySettingsSSOByNamespace]);

  React.useEffect(() => {
    if (idp) {
      const options = { authorizationParams: { login_from: 'idp' } };
      dispatch(ssoHandleRedirectRoutine.trigger({ options, companyId }));
    }
  }, [companyId, dispatch, idp]);

  if (authNamespaceHasSubmitSucceeded && namespace) {
    const lastPathSection = window.location.pathname.split('/').pop();
    const urlUpdate = `${getDomainWithOrWithoutCompanyNamespace(namespace)}/${lastPathSection}`;

    window.location.replace(urlUpdate);

    return null;
  }
  // we are checking if we are on a branded workspace URL and if the SSO settings are being fetched together
  // so that we don't show the loading wrapper when submitting the namespace form - which fetches SSO settings
  // TL;DR: on branded workspace: if we are fetching the SSO settings -> show loading wrapper
  // on regular URL: if we are fetching the SSO settings -> means we submitted the namespace form -> show form
  if ((companyNamespace && isSSOFetching) || isSSORedirecting) {
    return <IsLoadingHasWrapper />;
  }

  let formComponent: React.ReactElement;
  if (currentSSOSettings?.ssoRequired) {
    formComponent = <RequiredSSOLoginForm />;
  } else if (currentSSOSettings && !currentSSOSettings.ssoRequired) {
    formComponent = <OptionalSSOLoginForm />;
  } else if (!companyNamespace && !authNamespaceHasSubmitSucceeded) {
    formComponent = <NamespaceForm />;
  } else {
    formComponent = <NoSSOLoginForm />;
  }

  return (
    <>
      <RoutableHead noIndex />
      <Help />
      <BrandWrapperV2>
        <DashboardNotificationBar className="dashboard-notification-bar--brand-page" />
        <div className="branded--contents remove-margin">{formComponent}</div>
      </BrandWrapperV2>
    </>
  );
};

export default Login;
