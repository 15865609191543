import { PredefinedSectionType, SectionSubcomponentType, SectionType } from '@routable/tablematic';

import { FormFieldLabel, FormLabelWithTooltip } from 'components/form';

import {
  BlockSection,
  BlockSectionNoWrap,
  POLineItemsRow,
  BillSectionHeaderComponent,
  POSectionHeaderComponent,
  SectionHeader,
} from '../components';

export const sectionComponents = {
  [SectionType.block]: BlockSection,
  [SectionType.presentational_block]: BlockSection,
  [SectionType.block_no_wrap]: BlockSectionNoWrap,
  [SectionType.tab]: 'div',
  [SectionType.tabbed]: 'div',
  [SectionType.table]: BlockSection,
  [SectionType.customizableTable]: BlockSection,
  [SectionType.presentationalTable]: BlockSection,
  [SectionSubcomponentType.label]: FormFieldLabel,
  [SectionSubcomponentType.tooltipLabel]: FormLabelWithTooltip,
} as const;

export const predefinedSectionComponents = {
  [PredefinedSectionType.actionsItemTableSection]: {
    SectionHeaderComponent: SectionHeader,
  },
  [PredefinedSectionType.lineItemAccountTableSection]: {
    SectionHeaderComponent: BillSectionHeaderComponent,
  },
  [PredefinedSectionType.lineItemItemTableSection]: {
    SectionHeaderComponent: BillSectionHeaderComponent,
  },
  [PredefinedSectionType.poAccountTableSection]: {
    SectionHeaderComponent: POSectionHeaderComponent,
    RowComponent: POLineItemsRow,
  },
  [PredefinedSectionType.poDiscrepancyStatusSection]: {
    SectionHeaderComponent: SectionHeader,
  },
  [PredefinedSectionType.poItemTableSection]: {
    SectionHeaderComponent: POSectionHeaderComponent,
    RowComponent: POLineItemsRow,
  },
} as const;
