import * as routines from 'actions/routines/purposeCode';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [routines.fetchPurposeCodeRoutine.FAILURE, routines.fetchPurposeCodeRoutine.SUCCESS];

export const requestCases = [routines.fetchPurposeCodeRoutine.REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;
