import { mediaQueryHelpers } from '@routable/core/helpers';
import classNames from 'classnames';
import _find from 'lodash/find';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import Select, { components as defaultComponents, createFilter } from 'react-select';
import Creatable from 'react-select/creatable';

import { reduxFormInputPropType } from 'components/commonProps';
import { FormFieldErrors } from 'components/error';

import { mobileMediaQuery } from 'constants/mediaQuery';

import { getFieldErrors } from 'helpers/errors';
import { keyTabOrEnter } from 'helpers/keyEvents';
import { parseValueFromOptionForSelects } from 'helpers/selects';
import {
  becameTruthy,
  becameFalsy,
  callWithArgsIfIsFn,
  isEmptyObject,
  isString,
  isValueEmpty,
  ternary,
  noop,
  isUndef,
} from 'helpers/utility';

import { BottomSheetMenu, LockedIndicator, NoSearchTerm, SelectLabel } from './components';
import {
  getSelectComponents,
  getSelectComponentsStyles,
  getSelectPlaceholderValue,
  isInputActionInputBlur,
  isInputActionInputChange,
  SelectActions,
  sizes,
} from './utils';

import './Options.scss';
import './MultiSelect.scss';
import './SelectAlt.scss';

const currentWindow = global.window || undefined;
const mql = currentWindow.matchMedia(mobileMediaQuery);

const MemoizedFloatingLabel = React.memo(SelectLabel);

class SelectFieldV2 extends React.Component {
  state = {
    hasInput: false,
    hasFocus: false,
    hasValue: !!this.props.input.value,
    isMobile: false,
    /* eslint-disable react/no-unused-state */
    /* lastInput is used in AsyncWrapper subclass */
    lastInput: '',
    lastValue: null,
    menuMaxHeight: sizes.defaultMaxHeight,
  };

  // reference the the Select class instance
  selectRef = React.createRef();

  // reference to our div wrapper DOM node
  wrapperRef = this.props.setWrapperRef || React.createRef();

  intObserver = null;

  componentDidMount() {
    const { avoidScreenEdge } = this.props;

    if (avoidScreenEdge) {
      this.calculateEdgeAvoidingHeight();
    }

    this.handleMediaQueryChange();
    // We need the "isMobile" info in JS since we need to render Select menu in a portal (detached)
    // to display it as a bottom sheet correctly. We only want this behavior on mobile devices, hence
    // the need for media query in JS.
    mediaQueryHelpers.addEventListener(mql, 'change', this.handleMediaQueryChange);

    // Set observer to check if select is 100% in view, if not it will close the dropdown (menu)
    // eslint-disable-next-line no-undef
    if (globalThis.IntersectionObserver) {
      const observerOptions = {
        root: document?.querySelector('#vertical-resize-top-panel'),
        rootMargin: '0px',
        threshold: 1.0,
      };

      this.intObserver = new IntersectionObserver((entries) => {
        if (entries?.[0] && !entries?.[0].isIntersecting) {
          this.selectRef.current.onMenuClose();
        }
      }, observerOptions);

      if (this.wrapperRef?.current) {
        this.intObserver?.observe(this.wrapperRef.current);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      avoidScreenEdge: prevAvoidScreenEdge,
      input: { value: prevValue },
    } = prevProps;
    const {
      avoidScreenEdge,
      input: { value },
    } = this.props;
    const { hasValue } = this.state;

    if (!prevAvoidScreenEdge && avoidScreenEdge) {
      this.calculateEdgeAvoidingHeight();
    }

    if (value || prevValue) {
      // when the Select is controlled externally, its value may change
      // independently of any user interaction with the select field itself,
      // but subcomponents (e.g. floating label) still depend on this value updating
      if (becameTruthy(hasValue, value) || becameFalsy(hasValue, value)) {
        this.setState({ hasValue: !!value });
      }
    }
  }

  componentWillUnmount() {
    mediaQueryHelpers.removeEventListener(mql, 'change', this.handleMediaQueryChange);
    this.intObserver?.disconnect();
  }

  handleMediaQueryChange = () => {
    this.setState((state) => ({ ...state, isMobile: mql.matches }));
  };

  getErrors = () => {
    const {
      errors,
      input: { name },
      meta,
    } = this.props;

    return ternary(errors, getFieldErrors(errors, name), (meta.touched || meta.submitFailed) && meta.error);
  };

  // options can be passed as a map, or as an array
  getSelectOptions = () => {
    const { options, valueKey } = this.props;

    if (Array.isArray(options) || !valueKey) {
      return options;
    }

    return _map(options, (o) => o);
  };

  // value can be passed as the value object, or as the string value
  // identifying an option such that `option[valueKey] === value`
  getCurrentValue = () => {
    const {
      options,
      input: { value },
      valueKey,
    } = this.props;

    // if we've passed down a value string, match it up with the proper option object
    if (typeof value === 'string' && valueKey) {
      const valueObject = _find(options, (o) => o[valueKey] === value);
      if (valueObject) {
        return valueObject;
      }
    }

    return value;
  };

  getSelectComponentClass = () => {
    const { isCreatable } = this.props;
    return isCreatable ? Creatable : Select;
  };

  getMenuMaxAndMin = () => {
    const { menuMaxHeight } = this.state;
    const max = Math.min(sizes.defaultMaxHeight, menuMaxHeight);

    return {
      max,
      // 190 === try for a 5 option spread minimum
      min: Math.max(max - 190, sizes.defaultMinHeight),
    };
  };

  getSelectProps = () => {
    const {
      avoidScreenEdge,
      backspaceRemovesValue,
      className,
      classNamePrefix,
      components,
      filterConfig,
      filterOption,
      getCreatePrompt,
      getIsNewOptValid,
      getNewOptData,
      hasAvatar,
      hasErrors: hasErrorsProp,
      hideLabel,
      label,
      menuPlacement,
      noResultsText,
      noSearchTermText,
      getOptionLabel,
      getOptionValue,
      idPrefix,
      isClearable,
      isDisabled,
      isLocked,
      isLoading,
      isMulti,
      isOpen,
      isRequired,
      isSearchable,
      input: { name },
      lockedTooltipProps,
      onCreate,
      placeholder,
      styles,
      ...rest
    } = this.props;

    const { hasFocus, hasInput, hasValue, isMobile } = this.state;

    const filter = ternary(filterConfig, createFilter(filterConfig), undefined);
    const noOptionsMessage = () => ternary(hasInput, noResultsText, <NoSearchTerm searchTerm={noSearchTermText} />);

    const hasErrors = ternary(!isUndef(hasErrorsProp), hasErrorsProp, Boolean(this.getErrors()));

    const placeholderValue = getSelectPlaceholderValue({
      hideLabel,
      label,
      placeholder,
    });

    const LockedIndicatorWithProps = (indicatorProps) => (
      <LockedIndicator {...indicatorProps} tooltipProps={lockedTooltipProps} />
    );

    const componentProps = {
      ...rest,
      backspaceRemovesValue,
      // This fixes an issue where select fields
      // are unusable in mobile browsers (https://warrenpay.atlassian.net/browse/FRON-2706)
      blurInputOnSelect: false,
      className: classNames({
        [className]: !!className,
        'has-avatar': !!hasAvatar,
        'has-error': !!hasErrors,
        'has-input': !!hasInput,
        'has-value': !!hasValue,
        'is-disabled': !!isDisabled,
        'is-focused': !!hasFocus,
        Select: true,
      }),
      classNamePrefix,
      components: getSelectComponents({
        BottomSheetComponent: BottomSheetMenu,
        components,
        defaultComponents,
        LockIndicatorComponent: LockedIndicatorWithProps,
        isLocked,
        isMobile,
      }),
      // if a filterOption function was provided, it takes precedence
      // over a filter configuration object
      filterOption: filterOption ?? filter,
      formatCreateLabel: getCreatePrompt,
      getOptionLabel,
      getOptionValue,
      getNewOptionData: getNewOptData,
      hasAvatar,
      inputId: name,
      instanceId: idPrefix,
      isClearable,
      isDisabled,
      isLoading,
      isMulti,
      isNewOptionValid: getIsNewOptValid,
      isSearchable,
      label,
      menuIsOpen: isOpen,
      menuPosition: this.props.menuPortalTarget === document.body ? 'fixed' : undefined,
      menuPlacement,
      menuPortalTarget: this.props.menuPortalTarget,
      name,
      noOptionsMessage,
      onBlur: this.handleBlur,
      onChange: this.handleChange,
      onCreateOption: onCreate,
      onFocus: this.handleFocus,
      onInputChange: this.handleInputChange,
      options: this.getSelectOptions(),
      placeholder: (
        <>
          {placeholderValue}
          {isRequired && <span className="asterisk">{' *'}</span>}
        </>
      ),
      required: isRequired,
      shouldKeyDownEventCreateNewOption: keyTabOrEnter,
      styles: getSelectComponentsStyles({
        isDisabled,
        isLocked,
        isMobile,
        isSearchable,
        lockedTooltipProps,
        styles,
      }),
      value: this.getCurrentValue(),
    };

    if (avoidScreenEdge) {
      const { max, min } = this.getMenuMaxAndMin();
      componentProps.maxMenuHeight = max;
      componentProps.minMenuHeight = min;
    }

    return componentProps;
  };

  calculateEdgeAvoidingHeight = () => {
    const div = this.wrapperRef.current;

    if (div && currentWindow) {
      const { bottom, height } = div.getBoundingClientRect();
      const { innerHeight } = currentWindow;
      const gapSize = innerHeight - bottom - height + sizes.screenEdgePadding;
      const linesFit = Math.floor(gapSize / sizes.defaultOptHeight);
      const maxDistanceToScreen = linesFit * sizes.defaultOptHeight;

      this.setState({ menuMaxHeight: maxDistanceToScreen });
    }
  };

  // the onInputChange handler is spread over a couple of functions
  // for readability / keeping concerns separate. this piece's job is
  // to grab the onInputChange callback prop and fire away if it exists.
  afterInputChange = (inputStr, event) => {
    const { onInputChange } = this.props;
    callWithArgsIfIsFn(onInputChange, inputStr, event);
  };

  // this is the first function call after onInputChange events
  // it covers both keyboard and focus events for the inner text input.
  handleInputChange = (inputStr, event) => {
    const { lastInput } = this.state;
    const inputValue = inputStr || lastInput;

    if (isInputActionInputChange(event.action)) {
      const hasInput = inputStr?.length > 0;
      this.setState({ hasInput, lastInput: inputValue });
    } else if (isInputActionInputBlur(event.action)) {
      this.setState({ hasInput: false, lastInput: inputValue });
    }

    this.afterInputChange(inputStr, event);
  };

  // this function formats the value
  // and fires the onChange callback prop if it exists
  afterChange = (option, event) => {
    const {
      input: { onChange },
      parseValue,
      valueKey,
    } = this.props;
    const value = parseValue(option, valueKey);
    callWithArgsIfIsFn(onChange, value, event);
  };

  // first function call after an onChange event;
  // this covers all events related to the state of the ReactSelect
  // component's current value, or lack thereof.
  handleChange = (val, event) => {
    switch (event.action) {
      case SelectActions.CREATE_OPTION:
      case SelectActions.REMOVE_VALUE:
      case SelectActions.SELECT_OPTION:
      case SelectActions.SET_VALUE:
        this.setState({
          hasValue: !isValueEmpty(val),
          lastValue: val || null,
        });
        break;

      case SelectActions.CLEAR:
        this.setState({
          hasValue: false,
          hasInput: false,
          lastValue: null,
        });
        break;

      default:
        break;
    }

    this.afterChange(val, event);
  };

  // this piece's job is to grab the onFocus callback prop and fire it if it exists.
  afterFocus = (evt) => {
    const {
      input: { onFocus },
    } = this.props;
    callWithArgsIfIsFn(onFocus, evt);
  };

  // first point of contact after onFocus events
  // called when the RS field focuses.
  handleFocus = (evt) => {
    this.setState({ hasFocus: true });
    this.afterFocus(evt);
  };

  // first function call after an onBlur event fires
  // called when the RS field blurs.
  handleBlur = () => {
    this.setState({ hasFocus: false });
    this.afterBlur();
  };

  // fire onBlur callback prop if exists
  afterBlur = () => {
    const {
      input: { onBlur, value },
      discardOnBlur,
    } = this.props;
    if (discardOnBlur) {
      return;
    }
    // Must pass value as param because of an issue redux-form has with react-select
    // This way we don't lose the value when blurring the field
    callWithArgsIfIsFn(onBlur, value);
  };

  close = () => {
    this.selectRef.current.onMenuClose();
  };

  render() {
    const {
      dataFullStory,
      dataTestId,
      hideErrors,
      hideLabel,
      isDisabled,
      isRequired,
      label,
      labelClassName,
      input: { name, value },
      meta,
      wrapperClassName,
      placeholderValue,
    } = this.props;

    const { hasInput, hasValue, hasFocus } = this.state;

    const SelectComponentClass = this.getSelectComponentClass();

    const selectElement = <SelectComponentClass {...this.getSelectProps()} ref={this.selectRef} />;

    const isShowingValue = Boolean(hasInput || hasValue);

    let labelElement;

    if (isString(label)) {
      labelElement = (
        <MemoizedFloatingLabel
          className={labelClassName}
          isFocused={hasFocus}
          isRequired={isRequired && !!this.getCurrentValue() && !placeholderValue}
          label={label}
          name={name}
        />
      );
    } else {
      labelElement = label;
    }

    const showLabel = !!label && !hideLabel;
    const errors = this.getErrors();

    const isReduxField = !isEmptyObject(meta);

    return (
      <div
        className={classNames({
          'select-wrapper': true,
          'showing-value': isShowingValue,
          [wrapperClassName]: !!wrapperClassName,
        })}
        data-fullstory={dataFullStory}
        data-testid={dataTestId}
        ref={this.wrapperRef}
      >
        {selectElement}
        {showLabel && labelElement}

        {!isDisabled && !isReduxField && isRequired && (
          <input
            autoComplete="off"
            className="select--required-input"
            name={name}
            onChange={noop}
            required={isRequired}
            tabIndex={-1}
            // force an empty string instead of null or undefined
            // to trigger required validation
            value={ternary(value, value, '')}
          />
        )}

        {!hideErrors && (
          <span>
            <FormFieldErrors errors={errors} fieldName={name} />
          </span>
        )}
      </div>
    );
  }
}
/* eslint-enable react/no-unused-state */

SelectFieldV2.propTypes = {
  avoidScreenEdge: PropTypes.bool,
  autoFocus: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  components: PropTypes.shape(),
  dataTestId: PropTypes.string,
  discardOnBlur: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.shape()]),
  filterConfig: PropTypes.shape(),
  filterOption: PropTypes.func,
  getCreatePrompt: PropTypes.func,
  getIsNewOptValid: PropTypes.func,
  getNewOptData: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  hasAvatar: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hideErrors: PropTypes.bool,
  hideLabel: PropTypes.bool,
  idPrefix: PropTypes.string,
  input: reduxFormInputPropType.isRequired,
  isClearable: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLocked: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOpen: PropTypes.bool,
  isRequired: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  lockedTooltipProps: PropTypes.shape(),
  menuPlacement: PropTypes.string,
  menuPortalTarget: PropTypes.instanceOf(window.Element),
  meta: PropTypes.shape(),
  noResultsText: PropTypes.string,
  noSearchTermText: PropTypes.string,
  onCreate: PropTypes.func,
  onInputChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]).isRequired,
  parseValue: PropTypes.func,
  placeholder: PropTypes.string,
  setWrapperRef: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  styles: PropTypes.shape({}),
  valueKey: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

SelectFieldV2.defaultProps = {
  avoidScreenEdge: undefined,
  autoFocus: undefined,
  backspaceRemovesValue: true,
  className: undefined,
  classNamePrefix: 'Select',
  closeMenuOnSelect: true,
  components: {},
  dataTestId: undefined,
  discardOnBlur: undefined,
  errors: undefined,
  filterConfig: undefined,
  filterOption: undefined,
  getCreatePrompt: undefined,
  getIsNewOptValid: undefined,
  getNewOptData: undefined,
  getOptionLabel: (opt) => opt.label || opt.text || opt.name,
  getOptionValue: undefined,
  hasAvatar: undefined,
  hasErrors: undefined,
  hideErrors: undefined,
  hideLabel: undefined,
  idPrefix: undefined,
  isClearable: undefined,
  isCreatable: undefined,
  isDisabled: undefined,
  isLoading: undefined,
  isLocked: undefined,
  isMulti: undefined,
  isOpen: undefined,
  isRequired: true,
  isSearchable: true,
  label: '',
  lockedTooltipProps: undefined,
  labelClassName: undefined,
  menuPlacement: undefined,
  menuPortalTarget: document.body,
  meta: {},
  noResultsText: 'No matches found',
  noSearchTermText: 'Type to search',
  onCreate: undefined,
  onInputChange: undefined,
  parseValue: parseValueFromOptionForSelects,
  placeholder: undefined,
  setWrapperRef: undefined,
  styles: undefined,
  valueKey: 'value',
  wrapperClassName: undefined,
};

export default SelectFieldV2;
