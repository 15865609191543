import { TrashCan } from '@carbon/icons-react';
import { Button } from '@routable/gross-ds';
import React from 'react';

import './LineItemDelete.scss';

const LineItemDelete = ({ onClick }: { onClick: () => void }): JSX.Element => (
  <div className="flex items-center justify-center w-full">
    <Button
      aria-label="line item delete"
      data-testid="line-item-delete"
      onPress={onClick}
      squareButton
      variant="floating"
    >
      <TrashCan />
    </Button>
  </div>
);

export default LineItemDelete;
