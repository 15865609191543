import React from 'react';

import { Text } from 'components';

import { typography } from 'constants/styles';

import { hasLength } from 'helpers/utility';

import type { CompanyAddressDataCardItemProps } from './CompanyAddressDataCardItem.types';

/**
 * Component rendering single item entry of the Company Address Data Card
 * @see {CompanyAddressDataCard}
 * @param props - Component props
 */
const CompanyAddressDataCardItem: React.VFC<CompanyAddressDataCardItemProps> = ({ actions, label, value }) => (
  <div className="company-address-data-card__expandable__item">
    <div className="company-address-data-card__expandable__item__label-value-container">
      <div className="company-address-data-card__expandable__item__label-value-container__label">
        <Text.Regular size={typography.TextSize.LEVEL_100}>{label}</Text.Regular>
      </div>

      <div className="company-address-data-card__expandable__item__label-value-container__value" data-fullstory>
        {value}
      </div>
    </div>

    {hasLength(actions) && <div className="company-address-data-card__expandable__item__actions">{actions}</div>}
  </div>
);

export default CompanyAddressDataCardItem;
