import { AxiosRequestHeaders } from 'axios';

import { fetchItemsRoutine } from 'actions/routines/item';

import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getPaymentsEndpoint } from 'services/api/paymentsEndpoint';

import { paymentRoutine } from './payment';
import {
  FailureType,
  PaymentCancelFailure,
  PaymentCancelSuccess,
  PaymentCancelTrigger,
  SuccessType,
  TriggerType,
} from './paymentCancel.types';

const TRIGGER: TriggerType = `PAYMENT_CANCEL/TRIGGER`;
const SUCCESS: SuccessType = `PAYMENT_CANCEL/SUCCESS`;
const FAILURE: FailureType = `PAYMENT_CANCEL/FAILURE`;

const success: PaymentCancelSuccess = (data) => [
  { type: SUCCESS, payload: data },
  paymentRoutine.success(data),
  fetchItemsRoutine.trigger(),
];

const failure: PaymentCancelFailure = (data) => ({
  type: FAILURE,
  error: data,
});

const trigger: PaymentCancelTrigger = ({ id, payload }) => ({
  type: TRIGGER,
  payload: {
    id,
    endpoint: `${getPaymentsEndpoint({ paymentId: id })}cancel/`,
    method: 'POST',
    requireAuth: true,
    onSuccess: success,
    onFailure: failure,
    payload: payloadToJSONAPI(payload, 'payment'),
    setRequester: true,
    headers: {} as AxiosRequestHeaders,
  },
});

export const paymentCancelRoutine = {
  TRIGGER,
  trigger,
  SUCCESS,
  success,
  FAILURE,
  failure,
};
