import indefinite from 'indefinite';
import React from 'react';
import { v4 } from 'uuid';
import validator from 'validator';

import { END_OF_LINE_REGEX } from 'constants/regex';

import { isLastIndex, isLessThan, isString, isStringOrNum, lengthOf, ternary } from 'helpers/utility';

// Sadly, these options are not exported from the package's typings so this
// interface is a direct copy from node_modules/@types/indefinite/index.d.ts
interface IndefiniteOptions {
  articleOnly?: boolean;
  capitalize?: boolean;
  caseInsensitive?: boolean;
  numbers?: 'colloquial';
}

/**
 * Converts all characters of given value param to the lowercase
 */
export const convertToLowerCase = (value: unknown): string => {
  if (!isStringOrNum(value)) {
    return '';
  }
  return value.toString().toLowerCase();
};

/**
 * Converts all characters of given value param to the uppercase
 */
export const convertToUpperCase = (value: unknown): string => {
  if (!isStringOrNum(value)) {
    return '';
  }
  return value.toString().toUpperCase();
};

/**
 * Capitalizes the first letter of given text param
 */
export const capitalize = (text: unknown): string => {
  if (!isString(text)) {
    return '';
  }

  // noinspection JSUnresolvedFunction
  if (validator.isUUID(text)) {
    return text;
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
};

/**
 * Uncapitalizes the first letter of given text param
 */
export const uncapitalize = (text: unknown): string => {
  if (!isString(text)) {
    return '';
  }

  // noinspection JSUnresolvedFunction
  if (validator.isUUID(text)) {
    return text;
  }

  return text.charAt(0).toLowerCase() + text.slice(1);
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Formats given dashed text to camelCase
 */
export const dashTextToCamelCase = (text: unknown): string => {
  if (!isString(text)) {
    return '';
  }

  // noinspection JSUnresolvedFunction
  if (validator.isUUID(text)) {
    return text;
  }

  return text.replace(/([-_][a-z0-9])/g, ($1) => $1.toUpperCase().replace(/[-_]/, ''));
};

/**
 * Replaces an uppercase letter with a lowercase and puts a dash in front
 * uppercaseToDash('camelCased') = 'camel-cased'
 */
export const uppercaseToDash = (text: unknown): string => {
  if (!isString(text)) {
    return '';
  }

  // noinspection JSUnresolvedFunction
  if (validator.isUUID(text)) {
    return text;
  }

  return text.replace(/([A-Z])/g, ($1) => `-${$1.toLowerCase()}`);
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Replaces an uppercase letter with a lowercase and puts an underscore in front
 * uppercaseToUnderscore('camelCased') = 'camel_cased'
 */
export const uppercaseToUnderscore = (text: unknown): string => {
  if (!isString(text)) {
    return '';
  }

  // noinspection JSUnresolvedFunction
  if (validator.isUUID(text)) {
    return text;
  }

  const underscoredText = text.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);

  // Make sure not to add a double underscore at the beginning
  if (underscoredText.charAt(1) === '_') {
    return underscoredText.substring(1);
  }

  return underscoredText;
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Replace an uppercase letter with a lowercase and puts an underscore in front
 * of any previous uppercase letter or number.
 *
 * @example
 * ```
 * // Returns 'ap_international_transfer_swift_tier_2'
 * uppercaseToSnakeCaseWithNumbers('apInternationalTransferSwiftTier2');
 * ```
 */
export const uppercaseToSnakeCaseWithNumbers = (text: unknown): string => {
  if (typeof text !== 'string') {
    return '';
  }

  const snakeCase = uppercaseToUnderscore(text);
  const underscoredText = snakeCase.replace(/\d+/, ($1) => `_${$1.toLowerCase()}`);

  if (underscoredText.charAt(1) === '_' || !!text.match(/^[1-9]/)) {
    return underscoredText.substring(1);
  }

  return underscoredText;
};

/**
 * Splits camelCased text to words
 * camelCaseTextToWords('camelCased') = 'camel cased'
 * camelCaseTextToWords('camelCased camelText') = 'camel cased camel text'
 */
export const camelCaseTextToWords = (text: unknown): string => {
  if (!isString(text)) {
    return '';
  }

  // noinspection JSUnresolvedFunction
  if (validator.isUUID(text)) {
    return text;
  }

  const words = text.split(' ');
  const returnArr = [];

  words.forEach((word) => {
    if (word === word.toUpperCase()) {
      returnArr.push(word);
    } else {
      returnArr.push(
        uppercaseToUnderscore(word)
          .replace(/([-_])/g, ' ')
          .trim(),
      );
    }
  });

  return returnArr.join(' ');
};

/**
 * Utility function to add 'a' or 'an' before the text given to it.
 * Examples:
 * ---------
 * getTextWithLeadingArticle('fox') => 'a fox'
 * getTextWithLeadingArticle('alert') => 'an alert'
 */
export const getTextWithLeadingArticle = (text: string, options: IndefiniteOptions = {}): string =>
  indefinite(text, options);

/**
 * Utility function to check whether a string's length is greater than a given character limit
 * TODO: Remove optional chaining when we have more type safety
 */
export const isStringLengthGreaterThanLimit = (str: string, limit: number): boolean => str?.length > limit;

interface NewLineToBRTagOptions {
  useStaticHtml?: boolean; // Provides an option to get back "raw" stringified HTML to be rendered later
}

/**
 * Utility function to replace new line characters with HTML <br> tags
 */
export const newLineToBRTag = (
  stringWithNewLines: string,
  options: NewLineToBRTagOptions = {},
): Exclude<React.ReactNode, undefined>[] => {
  const { useStaticHtml = false } = options;

  return stringWithNewLines
    .replace(END_OF_LINE_REGEX, '\n')
    .split('\n')
    .map((part, idx, arr) => {
      const fragment = (
        <React.Fragment key={v4()}>
          {part}
          <br />
        </React.Fragment>
      );

      if (isLastIndex(arr, idx)) {
        const htmlStringWithoutMargin = `<span key="${v4()}">${part}</span>`;
        return ternary(useStaticHtml, htmlStringWithoutMargin, part);
      }

      const htmlStringWithMargin = `<span class="margin-bottom--xm" key="${v4()}">${part}</span>`;
      return ternary(useStaticHtml, htmlStringWithMargin, fragment);
    });
};

/**
 * Get the base object path for a redux-form field
 * e.g. if the field is item.address.postalcode, this will return item.address
 */
export const getFieldBaseObjectPath = (fieldName: string): string => {
  const lastPeriodIdx = fieldName.lastIndexOf('.');

  if (lastPeriodIdx < 0) {
    return fieldName;
  }

  return fieldName.substr(0, lastPeriodIdx);
};

/**
 * Converts given number to its ordinal string
 * @example
 * 1 -> 1st
 * 2 -> 2nd
 * 3 -> 3rd
 * 4 -> 4th
 */
export const convertNumberToOrdinalString = (num: number): string => {
  if (!isStringOrNum(num) || isLessThan(Number(num), 1)) {
    return '1st';
  }

  const stringNum = num.toString();

  switch (stringNum) {
    case '1':
      return '1st';
    case '2':
      return '2nd';
    case '3':
      return '3rd';

    default:
      return `${stringNum}th`;
  }
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Given a string, returns it with newlines stripped.
 */
export const stripNewlinesFromString = (str: string): string => str.replace(/\n|\r\n/g, '');

/**
 * Given a string, returns it with ending apostrophe and (if needed) added
 */
export const apostrophiseString = (str: unknown): string => {
  if (!str || !isString(str)) {
    return '';
  }

  if (str.endsWith('s')) {
    return `${str}'`;
  }

  return `${str}'s`;
};

/**
 * Given a string replace all underscores with spaces
 */
export const underscoresToSpaces = (str: unknown): string => {
  if (!isString(str)) {
    return '';
  }

  return str.replace(/_/g, ' ');
};

/**
 * Given an array of string, it lists all elements adding a comma between them and "and" between the last two.
 * @example
 * listAllElementsInArray(['one','two','three']) -> 'one, two and three'
 */
export const listAllElementsInArray = (strArr: string[] = []): string =>
  lengthOf(strArr) > 1 ? `${strArr.slice(0, -1).join(', ')} and ${strArr.slice(-1)}` : strArr[0] || '';
