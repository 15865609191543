import React from 'react';

import { LEGAL_PRIVACY_URL } from 'constants/platform';

import LinkNewPage from '../LinkNewPage';

interface PrivacyPolicyLinkProps {
  className?: string;
  [rest: string]: unknown;
}

/**
 * Component rendering Privacy Policy Link
 * @param {PrivacyPolicyLinkProps} props
 * @returns {StatelessComponent}
 */
const PrivacyPolicyLink: React.FC<PrivacyPolicyLinkProps> = ({ className, ...rest }) => (
  <LinkNewPage className={className} href={LEGAL_PRIVACY_URL} {...rest}>
    Privacy policy
  </LinkNewPage>
);

PrivacyPolicyLink.defaultProps = {
  className: undefined,
};

export { PrivacyPolicyLinkProps };
export default PrivacyPolicyLink;
