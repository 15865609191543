import ClipboardJS from 'clipboard';
import React from 'react';

import { valueOrDefault } from 'helpers/utility';

import { toaster } from 'services/toaster';

interface UseClipboardOptions {
  copyValue: string;
  successText?: string;
  elementId?: string;
}

/**
 * Hook for making an arbitrary HTML Element dispatch a copy to clipboard action.
 * @see {withCopyAction}
 *
 * @example
 * const ref = useClipboard({ copyValue: 'Copy me', successText: 'Copy successful! });
 *
 * <div ref={ref}>
 *   Some content
 * </div>
 *
 * @param {UseClipboardOptions} options
 * @returns {Ref}
 */
const useClipboard = ({
  copyValue,
  successText,
  elementId,
}: UseClipboardOptions): React.MutableRefObject<HTMLElement> => {
  // We create a ref. Any HTML element is a valid ref holder.
  const ref = React.useRef<HTMLElement>();

  React.useEffect(() => {
    // We need the reference to the clipboard instance in the return statement of the useEffect hook,
    // that's way we need to initialise it outside of a if (ref.current) check.
    let clipboard;

    const target = ref.current || elementId;

    // We want to check that ref.current holds a value since otherwise ClipboardJS will throws an error
    // when it will try to initialize with an undefined DOM node.
    if (target) {
      clipboard = new ClipboardJS(target, {
        text: () => copyValue,
      });

      // We listen for copy success action and dispatch a toaster event with provided successText (or default success
      // value if success text is not passed).
      clipboard.on('success', () => {
        const text = valueOrDefault(successText, 'Copied to clipboard!');
        toaster.success(text, { id: copyValue });
      });
    }

    return () => {
      // On hook un-mount, if we have an active clipboard instance, we want to destroy it.
      if (clipboard) {
        clipboard.destroy();
      }
    };
  }, [copyValue, elementId, ref, successText]);

  // The output of the hook is the ref element which needs to be set on an element that we want to hold the copy to
  // clipboard action.
  return ref;
};

export default useClipboard;
