import React from 'react';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

/**
 * Hint to display when the CSV is invalid.
 * @return {StatelessComponent}
 */
const CsvInvalidHint: React.FC = () => (
  <BaseHint
    intent={Intent.DANGER}
    // @ts-ignore
    multiline
    text="Please send a file in the CSV format."
    title="Your file is not in the CSV format"
  />
);

export default CsvInvalidHint;
