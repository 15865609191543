import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, InjectedFormProps } from 'redux-form';

import {
  ButtonV2,
  FormControl,
  FormFieldLabel,
  IconNames,
  ReduxFormFieldRenderInput,
  PrivacyPolicyLink,
  TermsOfServiceLink,
} from 'components';
import PasswordInput from 'components/passwordInput';
import { MINIMUM_STRENGTH } from 'components/passwordInput/constants/passwordRequirementParameters';

import { signUpCreateAccountFields } from 'constants/formFields';
import { formNamesSignup } from 'constants/forms';
import { sizes } from 'constants/styles';
import { field } from 'constants/styles/formStyles';

import { trimString } from 'helpers/fieldNormalizers';
import { emailValidator, requiredValidator } from 'helpers/fieldValidation';

import { getPasswordScore } from 'selectors/passwordInputSelectors';
import { isSubmittingSignupCreateAccountSelector } from 'selectors/signupSelectors';

import { ButtonSize } from '../../../constants/button';
import { Intent } from '../../../constants/ui';
import SignUpFormFooter from '../components/SignUpFormFooter';
import SignUpFormHeader from '../components/SignUpFormHeader';
import { sectionHeaderClassname, pClassname, spanClassname } from '../constants/relevantClassnames';

interface CreateAccountProps extends InjectedFormProps {
  handleSubmit(): void;
}

const CreateAccount: React.FC<CreateAccountProps> = ({ handleSubmit, invalid }) => {
  const [userAttemptedSubmitting, setUserAttemptedSubmitting] = useState(false);

  const handleSubmitClick = () => setUserAttemptedSubmitting(true);
  const isLoading = useSelector(isSubmittingSignupCreateAccountSelector);
  const passwordScore = useSelector(getPasswordScore);
  const isPasswordStrengthAcceptable = passwordScore >= MINIMUM_STRENGTH;

  return (
    <div className="submodule-container">
      <div className="form-container">
        <SignUpFormHeader hasSignInOption />

        <form className="form" id={formNamesSignup.CREATE_ACCOUNT_COMPANY} onSubmit={handleSubmit}>
          <h2>Let’s create your account</h2>
          <div>
            <FormFieldLabel className={sectionHeaderClassname}>Company name</FormFieldLabel>
            <p className={pClassname}>
              This is the name your company uses when communicating with vendors and customers.
            </p>
            <FormControl>
              <Field
                className={field.xl.full}
                component={ReduxFormFieldRenderInput}
                dataFullStory
                dataTestId="companyName"
                isRequired
                maxLength={100}
                name={signUpCreateAccountFields.COMPANY_NAME}
                placeholder="Company name"
                validate={requiredValidator}
              />
            </FormControl>
          </div>
          <div>
            <FormFieldLabel className={sectionHeaderClassname}>Account information</FormFieldLabel>
            <FormControl>
              <Field
                className={field.xl.full}
                component={ReduxFormFieldRenderInput}
                dataFullStory
                dataTestId="companyEmail"
                isRequired
                maxLength={100}
                name={signUpCreateAccountFields.USER_EMAIL}
                normalize={trimString}
                placeholder="Company email"
                validate={[requiredValidator, emailValidator]}
              />
            </FormControl>
            <FormControl>
              <Field
                className={field.xl.left}
                component={ReduxFormFieldRenderInput}
                dataFullStory
                dataTestId="firstName"
                isRequired
                maxLength={100}
                name={signUpCreateAccountFields.USER_FIRST_NAME}
                placeholder="First Name"
                validate={requiredValidator}
              />
              <Field
                className={field.xl.right}
                component={ReduxFormFieldRenderInput}
                dataFullStory
                dataTestId="lastName"
                isRequired
                maxLength={100}
                name={signUpCreateAccountFields.USER_LAST_NAME}
                placeholder="Last name"
                validate={requiredValidator}
              />
            </FormControl>
            <FormControl>
              <PasswordInput
                formName={formNamesSignup.CREATE_ACCOUNT}
                name={signUpCreateAccountFields.USER_PASSWORD}
                userAttemptedSubmission={userAttemptedSubmitting}
              />
            </FormControl>
          </div>

          <FormControl className="form-control remove-margin-bottom" id="button-control">
            <ButtonV2
              className="display--table-cell"
              dataTestId="createAccountButton"
              intent={Intent.PRIMARY}
              isDisabled={invalid || !isPasswordStrengthAcceptable}
              isLoading={isLoading}
              onClick={handleSubmitClick}
              rightIconClassName="margin-left--m"
              rightIconName={IconNames.ARROW_RIGHT}
              rightIconSize={sizes.iconSizes.SMALL}
              size={ButtonSize.MEDIUM}
              type="submit"
            >
              Create account
            </ButtonV2>
          </FormControl>

          <p className={spanClassname}>
            By creating my account I am agreeing to Routable’s <TermsOfServiceLink className="font-color--greyXDark" />{' '}
            and acknowledging the <PrivacyPolicyLink className="font-color--greyXDark" />.
          </p>
        </form>

        <SignUpFormFooter extraBottomPadding />
      </div>
    </div>
  );
};

export default CreateAccount;
