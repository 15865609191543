import { ADMISSION, AdmissionRoutes } from 'constants/routes';

import type { RoutableApiResponse } from 'interfaces/routableApiResponse';

import { routableApi, RoutableTags } from '../routableApi';

export const permissionsEndpoint = `/${ADMISSION}/${AdmissionRoutes.PERMISSIONS}/`;

const extendedPermissionsApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    // Get the custom roles list with proper permissions, we use this to map permissions checklist
    getPermissions: builder.query<RoutableApiResponse, unknown>({
      query: () => ({
        url: permissionsEndpoint,
      }),
      providesTags: [RoutableTags.Permissions],
    }),
  }),
});

export const { useGetPermissionsQuery } = extendedPermissionsApi;
