import { all, call, delay, put } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import { ssoSetupFormEditSubmitRoutine } from 'actions/routines/sso';
import { openSSOSetupFormSplashscreenModal } from 'actions/ssoActions';
import { showErrorUi } from 'actions/ui';

import { SSOSetupFailUIText, SSOSetupSplashscreenDuration } from 'constants/sso';

import { SSOProviders } from 'enums/sso';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';

import { FetchServiceResponse } from 'interfaces/fetchService';

import * as api from './api';

/**
 * Enable SSO for a company and open splashscreen
 */
export function* enableSSO(settingsId: string, ssoSetupFormProvider: SSOProviders): IterableIterator<unknown> {
  let submitErrors = {};

  try {
    const response: FetchServiceResponse = yield call(api.enableSSO, settingsId);
    if (response.ok) {
      yield all([put(openSSOSetupFormSplashscreenModal()), delay(SSOSetupSplashscreenDuration)]);
      return true;
    }

    submitErrors = parseErrorResponse(response);
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  yield all([
    put(handleRequestErrors(ssoSetupFormEditSubmitRoutine.failure, submitErrors)),
    put(showErrorUi(SSOSetupFailUIText[ssoSetupFormProvider].SETUP)),
  ]);
  return false;
}
