import React from 'react';
import { useDispatch } from 'react-redux';
import { InjectedFormProps } from 'redux-form';

import { toggleInviteTeamMemberModal } from 'actions/signUpFlow';

import { Modal } from 'components';

import { colors } from 'constants/styles';

import InviteTeamMemberContent from './InviteTeamMemberContent';
import InviteTeamMemberFooter from './InviteTeamMemberFooter';
import { InviteTeamMemberModalProps } from './InviteTeamMemberModal.types';
// To change header only here without causing any regression.
//  Maybe in the future we can update the Modal component? - or use a styled-component Modal
import './InviteTeamMemberModal.scss';

const InviteTeamMemberModal: React.VFC<InviteTeamMemberModalProps & InjectedFormProps> = ({
  open,
  handleSubmit,
  invalid,
}) => {
  const dispatch = useDispatch();
  const onSubmit = (e) => {
    // we need this so we don't submit the company info form when submitting this one
    e.preventDefault();
    handleSubmit(e);
  };

  const onCloseModal = () => {
    dispatch(toggleInviteTeamMemberModal());
  };

  return (
    <Modal
      body={
        <form onSubmit={onSubmit}>
          <InviteTeamMemberContent />
          <InviteTeamMemberFooter disabled={invalid} handleClose={onCloseModal} />
        </form>
      }
      hasInlineFooter
      modalHeader="Invite a team member"
      modalState={open}
      onCloseModal={onCloseModal}
      overrideStyle={{
        content: {
          backgroundColor: colors.colorBlueExtraLight,
        },
      }}
    />
  );
};

export default InviteTeamMemberModal;
