import { capitalize } from 'helpers/stringHelpers';

/**
 * Given a contact (user) object returns formatted name string
 * @param contact - The user object to format
 * @param options - Formatting options
 */
const getContactName = (contact: User, options?: { treatLastNameAsDisplayOption?: boolean }): string => {
  let name = contact.email;

  if (contact.firstName && contact.lastName) {
    name = `${capitalize(contact.firstName)} ${capitalize(contact.lastName)}`;
  } else if (contact.firstName) {
    name = capitalize(contact.firstName);
  } else if (contact.lastName && options?.treatLastNameAsDisplayOption) {
    name = capitalize(contact.lastName);
  }

  return name.trim();
};

export default getContactName;
