import React from 'react';

import { SYSTEM_STATUS_URL } from 'constants/platform';

import LinkNewPage from '../LinkNewPage';

interface SystemStatusLinkProps {
  className?: string;
  [rest: string]: unknown;
}

const SystemStatusLink = ({ className, ...rest }: SystemStatusLinkProps): JSX.Element => (
  <LinkNewPage className={className} href={SYSTEM_STATUS_URL} {...rest}>
    System status
  </LinkNewPage>
);

export { SystemStatusLinkProps };
export default SystemStatusLink;
