import React from 'react';

import { componentSwitcher } from './helpers/componentSwitcher';
import { VisualAid } from './SignUpVisualAid.styles';
import { SignUpVisualAidProps } from './types/SignUpVisualAid.types';

const SignUpVisualAid: React.VFC<SignUpVisualAidProps> = ({ component }) => (
  <VisualAid>{componentSwitcher(component)}</VisualAid>
);

export default SignUpVisualAid;
