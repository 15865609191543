import { isCountryCodeUSOrUSTerritory } from 'helpers/countries';

import type { Company } from 'interfaces/company';

/**
 * Helpers for interacting with the Company interface
 */

/**
 * Determine if a given company is has a country code defined that would
 * categorize it as an international company.
 */
export const isCompanyCountryCodeDefinedAndInternational = (company: Company): boolean =>
  Boolean(company?.countryCode) && !isCountryCodeUSOrUSTerritory(company.countryCode);

/**
 * Split the company name for a personal/individual company to first & last name.
 * This is used as the best guess for the legalFirstName and legalLastName.
 * We have to guess which part is the first vs last because the BE stores this name as a single string
 */
export const splitPersonalCompanyNameToFirstLast = (
  personalCompanyName: string,
): { legalFirstName: string; legalLastName: string | undefined } => {
  const [legalFirstName, ...lastNameArr] = personalCompanyName?.split(' ') ?? [];
  const legalLastName = lastNameArr.length > 0 ? lastNameArr.join(' ') : undefined;

  return {
    legalFirstName,
    legalLastName,
  };
};
