import { CurrencyCodeUsd, CurrencyCodeMapUsd } from 'constants/currency';

import type { ParseCurrencyOptions } from 'interfaces/parseCurrencyOptions';

import { getCurrencyFromCode } from './currency';
import { parseCurrency } from './numbers';

/**
 * Given amount and will return formatted string
 * ex.) parseCurrency("12345.6") // "12,345.60"
 */
export const formatAmount = (amount: number | string, options?: ParseCurrencyOptions): string =>
  parseCurrency(amount, { symbol: '', ...options });

/**
 * Given amount and currency code will return amount in short format
 * ex.) formatCurrency("1,234.56", "EUR", currencyCodeMap) // "€1,234.56"
 */
export const formatCurrency = (
  amount: number | string,
  currencyCode: string,
  currencyCodeMap: CurrencyCodeMap,
  options?: ParseCurrencyOptions,
): string => {
  const currencyCodeDetail = getCurrencyFromCode(currencyCodeMap, currencyCode);

  if (!currencyCodeDetail) {
    return '';
  }

  const { symbol } = currencyCodeDetail;

  return parseCurrency(amount, { symbol, ...options });
};

/**
 * Given amount and currency code will return amount explicit format
 * ex.) formatCurrencyExplicit("1,234.56", "EUR", currencyCodeMap) // "€1,234.56 EUR"
 */
export const formatCurrencyExplicit = (
  amount: number | string,
  currencyCode: string,
  currencyCodeMap: CurrencyCodeMap,
  options?: ParseCurrencyOptions,
): string => {
  const formattedAmount = formatCurrency(amount, currencyCode, currencyCodeMap, { ...options });

  if (!formattedAmount) {
    return '';
  }

  return `${formattedAmount} ${currencyCode}`;
};

/**
 * Given amount will format amount with amount explicit format
 * ex.) formatCurrencyUSDExplicit("1,234.56") // "$1,234.56 USD"
 */
export const formatCurrencyUSDExplicit = (amount: number | string, options?: ParseCurrencyOptions): string =>
  formatCurrencyExplicit(amount, CurrencyCodeUsd, CurrencyCodeMapUsd, options);

/**
 * Given amount will return amount short format
 * ex.) formatCurrencyUSD("1,234.56") // "$1,234.56"
 */
export const formatCurrencyUSD = (amount: number | string, options?: ParseCurrencyOptions): string =>
  formatCurrency(amount, CurrencyCodeUsd, CurrencyCodeMapUsd, options);

/**
 * Strips currency symbols and thousands separators from string
 */
export const currencyToNumberString = (currency: string): string => (currency || '').replace(/[^0-9.-]+/g, '');
