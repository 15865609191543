/* eslint-disable no-param-reassign */
import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { ReduxState } from 'interfaces/redux';

import { idSelector } from 'selectors/globalSelectors';

export type ShiftSelectWatcher = Record<
  string,
  {
    allIds: string[];
    lastClickedId: string;
  }
>;

const initialState: ShiftSelectWatcher = {};

const reduxShiftSelect = createSlice({
  name: 'reduxShiftSelect',
  initialState,
  reducers: {
    setLastClicked(state, action: PayloadAction<{ formField: string; fieldId?: string }>) {
      if (state[action.payload.formField]) {
        state[action.payload.formField].lastClickedId = action.payload.fieldId;
      }
    },
    setIdOrderList(state, action: PayloadAction<{ formField: string; allIds: string[] }>) {
      state[action.payload.formField] = {
        lastClickedId: null,
        allIds: action.payload.allIds.map((id) => `${action.payload.formField}.${id}`),
      };
    },
  },
});

const getShiftSelectorWatcher = (reduxState: ReduxState) => reduxState.reduxShiftSelect;

export const getShiftSelectData = createSelector(
  [getShiftSelectorWatcher, idSelector],
  (state, id) => state[id] || { lastClickedId: undefined, allIds: [] },
);

export const {
  name,
  actions: { setIdOrderList, setLastClicked },
  reducer,
} = reduxShiftSelect;
