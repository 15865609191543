import React from 'react';
import Toggle, { ToggleProps } from 'react-toggle';
import styled from 'styled-components';

import { callOnEnterKeyEvent, createPersistedEventCallback } from 'helpers/events';
import { callWithArgsIfIsFn } from 'helpers/utility';

export interface ToggleSwitchProps extends ToggleProps {
  htmlFor?: string;
  id?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const StyledLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

/**
 * Displays a ToggleSwitch with any children as the label.
 * TODO: BUGS-200 — react-toggle currently has a problem aligning icons passed via the icons prop. We don't use this
 * feature anywhere, but I removed the props from the component so we don't erroneously use it and wonder why it's not
 * working.
 * @param {Object} props
 * @returns {FunctionComponent}
 * @constructor
 */
const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  children,
  htmlFor,
  id,
  isChecked,
  isDisabled,
  name,
  onChange,
  ...rest
}) => {
  const handleKeyPress = React.useCallback(
    callOnEnterKeyEvent(
      createPersistedEventCallback((event) => {
        callWithArgsIfIsFn(onChange, event);
      }),
    ),
    [onChange],
  );

  return (
    <StyledLabel htmlFor={htmlFor || name}>
      <Toggle
        {...rest}
        checked={isChecked}
        disabled={isDisabled}
        icons={false}
        id={id}
        name={name}
        onChange={onChange}
        onKeyPress={handleKeyPress}
      />

      {children}
    </StyledLabel>
  );
};

ToggleSwitch.defaultProps = {
  id: '',
};

export default ToggleSwitch;
