import { taxTools } from '@routable/taxes';
import { vendorRiskCheckReport } from '@routable/vendor-risk';
import { queryClient } from 'milton/components';
import { change, stopAsyncValidation } from 'redux-form';
import { all, call, fork, join, put, select, spawn, take, takeLatest } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import * as actions from 'actions/partnership';
import {
  fetchPartnershipItemsRoutine,
  lookupPartnershipEmailsRoutine,
  resendPartnershipInviteRoutine,
  sendUpdatePaymentLinkRoutine,
  submitCreatePartnershipRoutine,
  updatePartnershipGeneralInfoRoutine,
} from 'actions/routines/partnership';
import { closeSidePanel } from 'actions/sidePanels';
import * as uiActions from 'actions/ui';

import { formNamesItem } from 'constants/forms';
import { PartnershipEmailWarning } from 'constants/partnership';
import { sidePanelNameContact, sidePanelNameEditCompanyGeneralInfo } from 'constants/sidePanels';
import { SuccessIndicatorMessages } from 'constants/ui';

import { parsePartnerships } from 'data/parse';
import { partnershipMemberSubmitTransformers, partnershipSubmitTransformers } from 'data/submitTransformers';

import { isCompanyTypePersonal } from 'helpers/currentCompany';
import { parseCaughtError, parseErrorResponse, getRequestErrorAction } from 'helpers/errors';
import { trackEvent, TrackEventName } from 'helpers/eventTracking';
import * as fileHelpers from 'helpers/fileHelpers';
import { isKindPayable } from 'helpers/items';
import { isPaymentDeliveryMethodAvailable } from 'helpers/paymentMethods';
import * as reducerHelpers from 'helpers/reducer';
import { createSaga } from 'helpers/saga';
import { isEqual, allValues, hasZeroLength } from 'helpers/utility';
import { isValidEmail } from 'helpers/validation';

import { handleChangePartnerCompany } from 'modules/dashboard/createItems/helpers/partnerships';
import { sortParamToUrl } from 'modules/itemSort/sortParamToUrl';

import {
  editGeneralInfoCompanyIdSelector,
  editGeneralInfoCompanyTypeSelector,
  editGeneralInfoSideSheetPartnershipIdSelector,
} from 'queries/partnershipCompoundSelectors';

import * as sharedTasks from 'sagas/shared';

import {
  currentCompanyPayablePaymentDeliveryMethodsSelector,
  currentCompanyReceivablePaymentDeliveryMethodsSelector,
} from 'selectors/currentCompanySelectors';
import { createSidePanelSelector } from 'selectors/sidePanelsSelector';
import {
  tableCompanyPaymentHistoryTablePartnershipIdSelector,
  tableCompanyPaymentHistoryTableSortSelector,
} from 'selectors/tableSelectors';

import FetchService from 'services/fetch';

import { CANCEL_SET_EXPORT_FILE_ID } from 'types/export';
import * as types from 'types/partnership';
import { APPLY_COMPANY_PAYMENT_HISTORY_TABLE_SORT } from 'types/tables';

import * as api from './api';
import {
  resendPartnershipInviteHandleFailureHelper,
  resendPartnershipInviteHandleSuccessHelper,
  resendPartnershipInviteSendInviteHelper,
} from './helpers';

/**
 * Handle fetching all partnerships.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* fetchPartnerships(action) {
  let errorData = {};

  try {
    const {
      payload: { params },
    } = action;

    const response = yield call(api.fetchPartnerships, params);

    if (response.ok) {
      yield put(actions.fetchPartnershipsSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchPartnershipsFailure, errorData));
}

/**
 * Handle a lookup for emails already belonging to partnershipMembers in a partnership.
 * @param {ReduxSagaRoutineAction} action
 * @returns {IterableIterator<*>}
 */
export function* fetchPartnershipEmails({ payload }) {
  const { email, partnershipMembers } = payload.values;

  // By default, we want to look at the email value (that is when dealing with
  // the Add Contact side sheet form). There are cases, though, when we want to check
  // the email of the first partnership member added to the form (for example, the
  // Create Partnership Individual form), so we want to fallback to that.
  const lookupEmail = email || (partnershipMembers && partnershipMembers[0]?.email);

  if (!isValidEmail(lookupEmail)) {
    // this email doesn't pass minimal validation, don't bother sending it
    const { form } = payload.props;
    yield put(stopAsyncValidation(form, {}));

    // If the email isn't valid, then there shouldn't be any warnings
    // All warnings are removed by the success trigger
    yield put(lookupPartnershipEmailsRoutine.success());
    return;
  }

  yield put(lookupPartnershipEmailsRoutine.request());

  let errorData = {};

  try {
    /*
      we can run into the following situation:
        1) we've opened the UpdateContactSidePanel
        2) we've clicked the edit button for the contact's email address
        3) we've focused the email input field
        4) we haven't changed anything about the email (or we have changed it, but reverted that change)
        5) the email validation is triggered onBlur
        6) since the email is in use by the contact being edited, we get a warning about reusing the email,
          but in this case, should not see that warning
       the redux-form function `shouldAsyncValidate` gives insufficient parameters for us to be able to detect
       this particular case. as such, we need to check the email being validated here against the one that was
       originally set on the partnershipMember in the update form. if they match, we can skip async validation.
       if they do not match, async validation should be performed as usual.
     */
    const contactSidePanelSelector = createSidePanelSelector(sidePanelNameContact);
    const contactSidePanel = yield select(contactSidePanelSelector);

    if (isEqual(contactSidePanel?.initialPartnershipMember?.email, lookupEmail)) {
      // we aren't changing the email address; skip validation and dispatch success
      yield put(lookupPartnershipEmailsRoutine.success());
      return;
    }

    const response = yield call(api.fetchPartnershipEmails, {
      email: lookupEmail,
    });

    if (response.ok) {
      if (hasZeroLength(response.originalData.data)) {
        // email address isn't attached to any existing partnerships, all done
        yield put(lookupPartnershipEmailsRoutine.success());
        return;
      }

      const associatedPartnerships = reducerHelpers.getObjectsByIdWithRelationships(response.data.partnership);

      yield put(
        lookupPartnershipEmailsRoutine.failure({
          warnings: {
            email: {
              warningType: PartnershipEmailWarning.ASSOCIATED_TO_COMPANIES,
              partnerships: allValues(associatedPartnerships),
            },
          },
        }),
      );

      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(errorData);
  yield put(errorAction(lookupPartnershipEmailsRoutine.failure, errorData));
}

/**
 * Handle submitting the create partnership form.
 * @param {ReduxSagaRoutineAction} action
 * @returns {IterableIterator<*>}
 */
export function* submitCreatePartnership(action) {
  const createPartnershipTask = yield fork(sharedTasks.submitCreatePartnership, action);
  yield join(createPartnershipTask);
}

/**
 * Handle fetching all partnerships.
 * For async files, stores the export file id.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* fetchPartnershipsExport(action) {
  let errorData = {};

  try {
    const {
      payload: { params },
    } = action;

    const requestOptions = fileHelpers.getFileDownloadRequestOptions(params.accept);

    const response = yield call(api.fetchPartnerships, params, requestOptions);

    // case: async 202
    if (FetchService.isResponseAccepted(response)) {
      const exportFileId = yield new Promise((resolve) => {
        response.data.text().then((text) => {
          resolve(text.trim().split('\n')[1]);
        });
      });
      yield put({ type: CANCEL_SET_EXPORT_FILE_ID, payload: { exportFileId } });
      yield put(actions.fetchPartnershipsExportSuccess());
      yield call(trackEvent, TrackEventName.CSV_EXPORT_CLICKED, {
        exportType: 'companies',
        downloadType: 'background',
      });
      return;
    }

    // case: sync anything between 200 and 300
    if (FetchService.isResponseOK(response)) {
      const fileName = fileHelpers.getExportZipFilename(response, 'companies');
      fileHelpers.downloadFile(response.data, fileName);
      yield put(actions.fetchPartnershipsExportSuccess());
      yield call(trackEvent, TrackEventName.CSV_EXPORT_CLICKED, {
        exportType: 'companies',
        downloadType: 'immediate',
      });
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchPartnershipsExportFailure, errorData));
}

/**
 * Handle fetching a specific partnership.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* fetchSinglePartnership(action) {
  let errorData = {};

  try {
    const {
      payload: { partnershipId, queryParams },
    } = action;

    const response = yield call(api.fetchSinglePartnership, partnershipId, queryParams);

    if (response.ok) {
      yield put(actions.fetchSinglePartnershipSuccess(response.data));
      return response.data;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchSinglePartnershipFailure, errorData));
  return undefined;
}

/**
 * Handle resending partnership invite.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* resendPartnershipInvite(action) {
  const {
    payload: { id: partnershipId, partnership },
  } = action;

  let errorData = null;
  let noMembershipError = false;

  try {
    // disable the invite button
    yield put(resendPartnershipInviteRoutine.request(action.payload));

    // fetch partnership details to make sure membership data is available
    const fetchPartnershipTask = yield fork(
      fetchSinglePartnership,
      actions.fetchSinglePartnershipRequest(partnershipId),
    );
    yield join(fetchPartnershipTask);
    const partnershipDetails = fetchPartnershipTask.result();

    if (partnershipDetails == null) {
      // at this point it was not possible to fetch partnership details
      // an error SWAL should be shown to the user by fetchSinglePartnership

      // returning undefined means something went wrong
      yield put(resendPartnershipInviteRoutine.failure(action.payload));
      yield put(resendPartnershipInviteRoutine.fulfill(action.payload));
      return undefined;
    }

    // select members, create payload, and send invitation POST request
    const response = yield call(resendPartnershipInviteSendInviteHelper, partnershipId);

    if (response.ok) {
      yield call(() => queryClient.invalidateQueries({ queryKey: [taxTools, partnershipId] }));
      return yield call(resendPartnershipInviteHandleSuccessHelper, response, partnershipId);
    }

    // the error is about missing partnership members?
    // that's important to show the correct error message
    noMembershipError = Array.isArray(response.data?.errors?.fields?.partnership_members);

    errorData = yield call(parseErrorResponse, response);
  } catch (error) {
    errorData = yield call(parseCaughtError, error);
  }

  yield call(resendPartnershipInviteHandleFailureHelper, action.payload, noMembershipError, errorData, partnership);

  // returning undefined means something went wrong
  return undefined;
}

/**
 * Handle fetching a specific partnership, from the create items flow.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* createItemsFetchSinglePartnership(action) {
  const fetchPartnershipTask = yield fork(fetchSinglePartnership, action);

  yield join(fetchPartnershipTask);

  const parsedResponse = fetchPartnershipTask.result();

  if (parsedResponse) {
    const {
      payload: { partnershipData, partnershipId, updateMethod },
    } = action;
    const updatedData = parsePartnerships.partnership.getMergedPartnershipData(
      partnershipData,
      partnershipId,
      parsedResponse,
    );
    handleChangePartnerCompany(updatedData, null, updateMethod);
  }
}

/**
 * Handle fetching a specific partnership.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* fetchPartnershipItems(action) {
  let errorData = {};

  yield put(fetchPartnershipItemsRoutine.request());

  try {
    const { payload: { partnershipId, params } = {} } = action;

    let queryParams = {
      ...partnershipId,
      sort: yield select(tableCompanyPaymentHistoryTableSortSelector),
    };

    if (!partnershipId?.partnershipId) {
      queryParams = {
        ...queryParams,
        partnershipId: yield select(tableCompanyPaymentHistoryTablePartnershipIdSelector),
      };
    }

    const response = yield call(api.fetchPartnershipItems, queryParams, params);

    if (response.ok) {
      yield put(actions.fetchPartnershipItemsSuccess(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchPartnershipItemsFailure, errorData));
}

/**
 * Handle fetching partnership receivable funding accounts.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* fetchPartnershipReceivableFundingAccounts(action) {
  let errorData = {};

  try {
    const {
      payload: { partnershipId, queryParams },
    } = action;

    const response = yield call(api.fetchPartnershipReceivableFundingAccounts, partnershipId, queryParams);

    if (response.ok) {
      yield put(actions.fetchPartnershipReceivableFundingAccountsSuccess(response.data));
      return response.data;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(actions.fetchPartnershipReceivableFundingAccountsFailure, errorData));
  return undefined;
}

/**
 * Handle fetching receivable funding accounts for a specific partnership,
 * from the create items flow.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* createItemsFetchPartnershipReceivableFundingAccounts(action) {
  const fetchFundingAccountsTask = yield fork(fetchPartnershipReceivableFundingAccounts, action);

  yield join(fetchFundingAccountsTask);

  const parsedResponse = fetchFundingAccountsTask.result();

  if (parsedResponse) {
    const {
      payload: { itemKind },
    } = action;

    const primaryAccount = parsePartnerships.fundingAccounts.getPrimaryReceivableFundingAccount(parsedResponse);

    if (primaryAccount) {
      const { fundingAccount, paymentDeliveryMethod } = primaryAccount.attributes;

      const methodsAccepted = isKindPayable(itemKind)
        ? yield select(currentCompanyPayablePaymentDeliveryMethodsSelector)
        : yield select(currentCompanyReceivablePaymentDeliveryMethodsSelector);

      if (isPaymentDeliveryMethodAvailable(methodsAccepted, paymentDeliveryMethod)) {
        // Update the item payment method and funding account id from the primary funding account's
        // payment method, if that payment method is available for the current company
        yield all([
          put(change(formNamesItem.CREATE_ITEM, 'item.paymentDeliveryMethod', paymentDeliveryMethod)),
          put(change(formNamesItem.CREATE_ITEM, 'item.partnerReceivableAccount', fundingAccount)),
        ]);
      }
    }
  }
}

/**
 * Handle updating partnership general info -> display name and legal name
 * @param {ReduxAction} action Action dispatched by updatePartnershipGeneralInfoRoutine
 * @return {IterableIterator<*>}
 */
export function* updatePartnershipGeneralInfo({ payload }) {
  const { values } = payload;

  const partnershipId = yield select(editGeneralInfoSideSheetPartnershipIdSelector);
  const partnerCompanyId = yield select(editGeneralInfoCompanyIdSelector);
  const partnerCompanyType = yield select(editGeneralInfoCompanyTypeSelector);

  // Attach the partnership id and the id if the partner's company to the submit values
  const submitValues = {
    ...values,
    id: partnershipId,
    name: values.displayName,
    partner: {
      id: partnerCompanyId,
      name: values.name,
    },
  };

  // If we are dealing with a personal company type, we don't want to
  // submit the partner relationship. We also want to submit the first/last
  // name as the legalFirstName and legalLastName
  if (isCompanyTypePersonal(partnerCompanyType)) {
    delete submitValues.partner;

    submitValues.legalFirstName = values.legalFirstName;
    submitValues.legalLastName = values.legalLastName;
  } else {
    // if company type is business, we don't want to submit anything as
    // legalFirstName and legalLastName
    delete submitValues.legalFirstName;
    delete submitValues.legalLastName;
  }

  /**
   * if partner.governmentId field is present, then we need to include
   * the partner, regardless vendor is a business or individual
   */
  if (values.partner?.governmentId) {
    const { governmentId } = values.partner;

    /**
     * Avoid sending just 'name' for individuals.
     * If partner was removed from submitValues cause vendor is an individual,
     * we just include id and govenrment id values.
     * If partner exists under submitValues cause vendor is business,
     * we attach government id to the existing submitValues partner.
     */
    submitValues.partner = submitValues.partner
      ? { ...submitValues.partner, governmentId }
      : { id: partnerCompanyId, governmentId };

    delete submitValues.governmentId;
  }

  const options = {
    apiParams: [submitValues],
    onSuccess: [
      () => uiActions.showSuccessUi(SuccessIndicatorMessages.GENERAL_INFO_UPDATED),
      () => closeSidePanel({ name: sidePanelNameEditCompanyGeneralInfo }),
    ],
    onSuccessCallback: () => {
      queryClient.invalidateQueries({ queryKey: [taxTools] });
      queryClient.invalidateQueries({ queryKey: [vendorRiskCheckReport] });
    },
  };

  yield call(createSaga, api.updatePartnership, updatePartnershipGeneralInfoRoutine, options);
}

/**
 * Handle updating payment link
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* sendUpdatePaymentLink(action) {
  let submitErrors = {};

  try {
    const {
      payload: { values },
    } = action;

    // we use the same payload structure here as for sending an invite
    const submitData = partnershipSubmitTransformers.sendPartnershipInviteData(values, {
      transformPartnershipMember: partnershipMemberSubmitTransformers.partnershipMemberForSendUpdatePaymentLink,
    });
    const response = yield call(api.sendUpdatePaymentMethodLink, submitData);

    if (response.ok) {
      yield put(
        sendUpdatePaymentLinkRoutine.success({
          id: values.partnership.id,
        }),
      );
      return;
    }

    submitErrors = parseErrorResponse(response);
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(sendUpdatePaymentLinkRoutine.failure, submitErrors));
}

export function* applySort(action) {
  const {
    payload: { sortParam },
  } = action;

  sortParamToUrl(sortParam);

  yield put(fetchPartnershipItemsRoutine.trigger());
}

/**
 * Listens for redux actions related to notifications.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  yield takeLatest(types.FETCH_PARTNERSHIPS_REQUEST, fetchPartnerships);
  yield takeLatest(fetchPartnershipItemsRoutine.TRIGGER, fetchPartnershipItems);
  yield takeLatest(APPLY_COMPANY_PAYMENT_HISTORY_TABLE_SORT, applySort);

  while (true) {
    const action = yield take([
      lookupPartnershipEmailsRoutine.TRIGGER,
      resendPartnershipInviteRoutine.TRIGGER,
      sendUpdatePaymentLinkRoutine.TRIGGER,
      submitCreatePartnershipRoutine.TRIGGER,
      updatePartnershipGeneralInfoRoutine.TRIGGER,
      types.CREATE_ITEMS_FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_REQUEST,
      types.CREATE_ITEMS_FETCH_PARTNERSHIP_REQUEST,
      types.FETCH_PARTNERSHIPS_EXPORT_REQUEST,
      types.FETCH_PARTNERSHIPS_REQUEST,
      types.FETCH_PARTNERSHIP_ITEMS_REQUEST,
      types.FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_REQUEST,
      types.FETCH_PARTNERSHIP_REQUEST,
    ]);

    switch (action.type) {
      case lookupPartnershipEmailsRoutine.TRIGGER:
        yield spawn(fetchPartnershipEmails, action);
        break;

      case resendPartnershipInviteRoutine.TRIGGER:
        yield spawn(resendPartnershipInvite, action);
        break;

      case sendUpdatePaymentLinkRoutine.TRIGGER:
        yield spawn(sendUpdatePaymentLink, action);
        break;

      case submitCreatePartnershipRoutine.TRIGGER:
        yield spawn(submitCreatePartnership, action);
        break;

      case updatePartnershipGeneralInfoRoutine.TRIGGER:
        yield spawn(updatePartnershipGeneralInfo, action);
        break;

      case types.FETCH_PARTNERSHIP_REQUEST:
        yield spawn(fetchSinglePartnership, action);
        break;

      case types.CREATE_ITEMS_FETCH_PARTNERSHIP_REQUEST:
        yield spawn(createItemsFetchSinglePartnership, action);
        break;

      case types.FETCH_PARTNERSHIP_ITEMS_REQUEST:
        yield spawn(fetchPartnershipItems, action);
        break;

      case types.FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_REQUEST:
        yield spawn(fetchPartnershipReceivableFundingAccounts, action);
        break;

      case types.CREATE_ITEMS_FETCH_PARTNERSHIP_RECEIVABLE_FUNDING_ACCOUNTS_REQUEST:
        yield spawn(createItemsFetchPartnershipReceivableFundingAccounts, action);
        break;

      case types.FETCH_PARTNERSHIPS_EXPORT_REQUEST:
        yield spawn(fetchPartnershipsExport, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root partnerships saga.
 * @return {IterableIterator<*>}
 */
export default function* partnerships() {
  yield watch();
}
