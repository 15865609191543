export enum LedgerContactType {
  BOTH = 'both',
  SINGULAR = 'singular',
}

export enum IntegrationSyncOptions {
  BASE_CURRENCY = 'bcs',
  MULTI_CURRENCY = 'mcs',
}

export enum TopOrEntityOptions {
  TOP = 'top level',
  ENTITY = 'entity level',
}
