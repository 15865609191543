import React from 'react';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

/**
 * Render FAQ about usage of routable balance
 */
const BalanceFAQItemUsageAnswer = ({
  exampleAmount,
  nextDayTitle,
  rtpTitle,
  sameDayTitle,
}: {
  exampleAmount: string;
  nextDayTitle: string;
  rtpTitle?: string;
  sameDayTitle: string;
}): JSX.Element => {
  const rtpFragment = rtpTitle ? (
    <>
      {`To transact via ${rtpTitle}, funds need to be initiated from your ${PLATFORM_DISPLAY_SHORT_NAME} balance. \
          For example, if you intend to send ${exampleAmount} via RTP, your ${PLATFORM_DISPLAY_SHORT_NAME} balance needs \
          to have at least ${exampleAmount} pre-funded.`}
      <br />
    </>
  ) : null;

  return (
    <>
      {rtpFragment}
      {`You can send ${sameDayTitle} and ${nextDayTitle} payments using the ${PLATFORM_DISPLAY_SHORT_NAME} balance as the funding source.`}
    </>
  );
};

export default BalanceFAQItemUsageAnswer;
