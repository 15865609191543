/* eslint-disable lines-between-class-members, max-classes-per-file */
import { isLevelTypeAny } from './approvals';

/**
 * OOP for Item approval helpers.
 *
 * Items often have multiple ItemSideApprovals of multiple levels and varying types.
 * This file aims to switch to OOP for item approvals making them simpler to deal with and easier to find.
 * More so than a hundred functions of slightly different use-cases anyway.
 */
export class ItemApprovalLevel {
  itemSideApprovals: ItemSideApproval[];
  levelPosition: number;
  levelType: 'all' | 'any';

  constructor(itemSideApprovals: ItemSideApproval[]) {
    this.itemSideApprovals = itemSideApprovals;
    this.levelPosition = itemSideApprovals[0].levelPosition;
    this.levelType = itemSideApprovals[0].levelType;
  }

  /**
   * Number of ItemSideApprovals for this level that have been approved.
   */
  get numberOfApprovals(): number {
    return this.itemSideApprovals.filter((v) => v.hasApproved === true).length;
  }

  /**
   * Number of ItemSideApprovals for this level that have not yet been approved.
   */
  get numberOfNotApproved(): number {
    return this.itemSideApprovals.length - this.numberOfApprovals;
  }

  /**
   * Whether the minimum required approvals have been given to satisfy this level.
   */
  isLevelSatisfied = (): boolean => {
    if (isLevelTypeAny(this)) {
      return this.numberOfApprovals > 0;
    }
    return this.numberOfApprovals === this.itemSideApprovals.length;
  };

  /**
   * Whether a specific membership can approve this level.
   * A member who has already approved this level CANNOT approve this level again.
   * @param {string} membershipId
   */
  canMemberApproveLevel = (membershipId: string): boolean =>
    this.itemSideApprovals.some((isa) => isa.membership === membershipId && isa.canApprove && !isa.hasApproved);

  /**
   * After a member approves this level will this level have met the minimum approval requirements to move on.
   * @param {string} membershipId
   */
  canMemberSatisfyLevel = (membershipId: string): boolean => {
    if (isLevelTypeAny(this)) {
      return this.canMemberApproveLevel(membershipId);
    }
    return this.canMemberApproveLevel(membershipId) && this.numberOfNotApproved === 1;
  };
}

export class ItemApprovals {
  itemSideApprovals: ItemSideApproval[];
  approvalLevels: ItemApprovalLevel[];

  constructor(itemSideApprovals: ItemSideApproval[]) {
    this.itemSideApprovals = itemSideApprovals;
    this.approvalLevels = [];
    const levels = new Set(itemSideApprovals.map((a) => a.levelPosition));

    levels.forEach((level) => {
      const approvalsForLevel = itemSideApprovals.filter((isa) => isa.levelPosition === level);
      this.approvalLevels.push(new ItemApprovalLevel(approvalsForLevel));
    });
  }

  /**
   * After a member approves the item will all the approval requirements have been met.
   * @param {string} membershipId
   */
  canMemberSatisfyItemApproval = (membershipId: string): boolean => {
    const levelsNotSatisfied = this.approvalLevels.filter((v) => !v.isLevelSatisfied());
    return levelsNotSatisfied.length === 1 && levelsNotSatisfied[0].canMemberSatisfyLevel(membershipId);
  };
}
