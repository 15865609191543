import { StaticCountryCode } from 'constants/countries';

import { UserType } from 'enums/user';

/**
 * Array based structure, used by GovernmentIdFieldSet. This const will be removed
 * once we migrate its data to a BE endpoint under DEV-14990 and DEV-15003
 */
export const GovernmentIdFieldsPerCountry = {
  [StaticCountryCode.MX]: {
    [UserType.BUSINESS]: [
      {
        placeholder: 'RFC (Registro Federal de Contribuyentes)',
        code: 'rfc',
        regex: /^([A-Z]{3}\d{6}.{3}|.{4}\\d{6}.{3})$/,
        errorMessage: 'Invalid RFC code',
      },
    ],
    [UserType.PERSONAL]: [
      {
        placeholder: 'CURP (Clave Única de Registro de Población)',
        code: 'curp',
        // This long line will be removed once we migrate the regex to BE
        // eslint-disable-next-line max-len
        regex:
          // eslint-disable-next-line max-len
          /^([A-Z][A,E,I,O,U,X][A-Z]{2}\d{6}(M|H)[A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9])$/,
        errorMessage: 'Invalid CURP code',
      },
    ],
  },
};
