import { createSelector } from 'reselect';

import { ItemById, Payment, PaymentById, ReduxState } from 'interfaces/redux';

import { idSelector } from 'selectors/globalSelectors';

const paymentsSelector = (state: ReduxState) => state.payments;

const payments = createSelector(paymentsSelector, (state) => state.payments);
const items = createSelector(paymentsSelector, (state) => state.items);
const payment = createSelector(paymentsSelector, (state) => state.payment);

const paymentsAllPaymentsMapSelector = createSelector(payments, ({ byId }) => byId);

const paymentsItemSelectorById = createSelector(items, (itemList) => itemList.byId);
const paymentsItemSelectorIds = createSelector(items, (itemList) => itemList.ids);

export const paymentActiveIdSelector = createSelector(payment, ({ activePaymentId }) => activePaymentId);

const paymentsListSelector = createSelector(payments, (state) => state.ids);

const paymentsListSelectorWithoutActiveId = createSelector(
  paymentsListSelector,
  paymentActiveIdSelector,
  (ids, activeId) => ids.filter((id) => id !== activeId),
);

export const paymentStateSelector = createSelector(payment, ({ apiStatus }) => apiStatus);

export const paymentCancelStateSelector = createSelector(paymentsSelector, ({ cancel: { apiStatus } }) => apiStatus);

export const paymentsListStateSelector = createSelector(
  payments,
  items,
  ({ apiStatus }, { apiStatus: { isLoading, isError, error } }) => ({
    isLoading: isLoading || apiStatus.isLoading,
    isError: isError || apiStatus.isError,
    error: error || apiStatus?.error,
  }),
);

export const paymentsPaginationSelector = createSelector(payments, ({ pagination }) => pagination);

export const paymentsLinkSelector = createSelector(payments, ({ links }) => links);

const mapToPayment = (id: string, allPayments: Record<string, PaymentById>): Payment => ({
  id,
  type: 'Payment',
  // @ts-ignore
  partnershipRequest: allPayments[id].relationships.partnershipRequest.data.id,
  ...allPayments[id].attributes,
});
const mapToItemPayment = (id: string, allPayments: Record<string, ItemById>): Payment => {
  const {
    amount,
    amountPartner,
    currencyCode,
    currencyCodePartner,
    currencyCodeReceiver,
    dateExpected,
    dateScheduled,
    dateSent,
    threadModified,
    kind,
    fundingProviderMemo,
    reference,
    status,
    version,
  } = allPayments[id].attributes;
  const { partnershipRequest } = allPayments[id].relationships;

  return {
    amount,
    amountPartner,
    currencyCode,
    currencyCodePartner,
    currencyCodeReceiver,
    dateExpected,
    dateScheduled,
    dateSent,
    fundingProviderMemo,
    id,
    itemCount: 1,
    kind,
    modified: threadModified,
    // @ts-ignore
    partnershipRequest: partnershipRequest.data.id,
    reference,
    status,
    type: 'Item',
    version,
  };
};

const allPaymentsSelector = createSelector(
  paymentsAllPaymentsMapSelector,
  paymentsListSelectorWithoutActiveId,
  (allPayments, paymentsList) => paymentsList.map((id) => mapToPayment(id, allPayments)),
);

const allItemsSelector = createSelector(paymentsItemSelectorById, paymentsItemSelectorIds, (allItems, itemIds) =>
  itemIds.map((id) => mapToItemPayment(id, allItems)),
);

export const paymentsAllPaymentsSelector = createSelector(
  allPaymentsSelector,
  allItemsSelector,
  (paymentsList, itemList) => [...paymentsList, ...itemList].sort((a, b) => b.modified.localeCompare(a.modified)),
);

export const paymentActivePaymentSelector = createSelector(
  paymentsAllPaymentsMapSelector,
  paymentActiveIdSelector,
  (allPayments, activePayment) => (allPayments[activePayment] ? mapToPayment(activePayment, allPayments) : null),
);

export const paymentIdByPropSelector = createSelector([paymentsAllPaymentsMapSelector, idSelector], (allPayments, id) =>
  allPayments[id] ? mapToPayment(id, allPayments) : null,
);
