import React from 'react';

import { Text, WhiteSpace } from 'components';

import { typography } from 'constants/styles';

import {
  getFormattedDetailsOfFundingInfoAddress,
  getFundingInfoAddressDisplayName,
  isFundingAccountBankAccount,
} from 'helpers/funding';

import type { PaymentMethodHintBaseContentProps } from './PaymentMethodHintBaseContent.types';

/**
 * Component rendering the content of CurrentPaymentMethodHint component
 * @see PaymentMethodHintBase
 * @param props - Component props
 */
const PaymentMethodHintBaseContent = ({
  fundingAccount,
  fundingInfoAddress,
}: PaymentMethodHintBaseContentProps): JSX.Element => {
  const isBankAccount = isFundingAccountBankAccount(fundingAccount);

  // We return only the funding account name if it is bank account
  if (isBankAccount) {
    return (
      <>
        <Text.Bold color={typography.TextColor.BLACK} dataFullStory>
          {fundingAccount.name}
        </Text.Bold>
        <WhiteSpace />
        <Text.Regular color={typography.TextColor.GREY_X_DARK} dataFullStory>
          {fundingAccount.currencyCode}
        </Text.Regular>
      </>
    );
  }

  // We return details of funding account delivery address if funding account is
  // check/address
  return (
    <>
      <Text.Bold color={typography.TextColor.BLACK}>Check mailing address:</Text.Bold>
      <Text.Semibold color={typography.TextColor.BLACK} dataFullStory>
        {' '}
        {getFundingInfoAddressDisplayName(fundingInfoAddress)}
        {', '}
        {fundingInfoAddress.streetAddress}
        {', '}
        {getFormattedDetailsOfFundingInfoAddress(fundingInfoAddress)}
      </Text.Semibold>
    </>
  );
};

export default PaymentMethodHintBaseContent;
