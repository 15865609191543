import * as types from 'types/payment';

export const initialState = {
  open: false,
  paymentId: null,
};

const existingPaymentSidePanelReducer = (state = initialState, action: ReduxAction): ReduxState => {
  switch (action.type) {
    case types.CLOSE_EXISTING_PAYMENT_SIDE_PANEL:
      return initialState;

    case types.OPEN_EXISTING_PAYMENT_SIDE_PANEL:
      return {
        ...state,
        ...action.payload,
        open: true,
      };

    default:
      return state;
  }
};

export default existingPaymentSidePanelReducer;
