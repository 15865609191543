import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { resendMembershipInvite } from 'actions/inviteTeamMember';
import { revokeMembershipInviteRoutine } from 'actions/routines/inviteTeamMember';

import {
  isFetchingMembershipInvitesSelector,
  isRevokingMembershipInviteSelector,
  pendingMembershipInvitesSelector,
} from 'selectors/membershipInvitesSelectors';

import InvitedTeamMembersTable from './InvitedMembersTable';

const mapStateToProps = createStructuredSelector({
  isFetchingInvites: isFetchingMembershipInvitesSelector,
  isRevokingInvite: isRevokingMembershipInviteSelector,
  pendingMembershipInvites: pendingMembershipInvitesSelector,
});

export const mapDispatchToProps = {
  onResendInvite: resendMembershipInvite,
  onRevokeInvite: revokeMembershipInviteRoutine,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitedTeamMembersTable);
