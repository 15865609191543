import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';

import { UseRoutableParams, PathParams } from './useRoutableParams.types';

/**
 * Returns an object of parameters based on the path or query parameters used in routable
 */
// @ts-expect-error queryString.parse is used directly here and returns a ParsedQuery, which does not work together with
// our Partial type for the return interface.
export const useRoutableParams: UseRoutableParams = () => {
  const { search } = useLocation();
  const pathParams = useParams<PathParams>();
  const queryParams = queryString.parse(search);
  return {
    ...pathParams,
    ...queryParams,
  };
};
