import * as routines from 'actions/routines/funding';

import { countryPaymentOption } from 'constants/relationships';

import { allValues } from 'helpers/utility';

import type { Action } from 'interfaces/actions';
import type { CountryCode } from 'interfaces/countries';
import type { SupportedCountryResultRecord, SupportedCountryItemOptions } from 'interfaces/fundingSupportedCountries';
import type { Reducer } from 'interfaces/reducers';

type State = CountryCode[];

interface SuccessAction extends Action {
  payload: SupportedCountryResultRecord;
}

const getAllSupportedCountryCodes = (supportedCountries: SupportedCountryItemOptions) => {
  const result = allValues(supportedCountries).map((country) => {
    const { attributes } = country;
    return attributes.countryCode;
  });

  // [srajchevski] map US config for empty-string key ('')
  // because of legacy Canadian vendors that do not have countryCodePartner set
  return ['', ...result];
};

const allCountryCodesReducer: Reducer<State, SuccessAction> = (state = [], action): State => {
  switch (action.type) {
    case routines.fetchSupportedCountriesRoutine.SUCCESS:
      return getAllSupportedCountryCodes(action.payload[countryPaymentOption]);

    default:
      return state;
  }
};

export default allCountryCodesReducer;
