import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfig, AxiosError, AxiosResponseHeaders } from 'axios';

import { MetaData } from 'actions/modifiers/meta.types';

import { getURLWithQueryParams } from 'helpers/urls';

import { UnknownObject } from 'interfaces/global';
import { RoutableApiResponse } from 'interfaces/routableApiResponse';

import { FetchService } from 'services';
import { BASE_API_URL } from 'services/api';

type ErrorResponse = UnknownObject;
type FetchServiceResponse<D> = {
  ok: boolean;
  data: D;
  headers: AxiosResponseHeaders;
  status: string;
};

export const generateAxiosBaseQuery =
  (fetchService: Pick<typeof FetchService, 'request' | 'requestFile'>) =>
  <ResponseBody = RoutableApiResponse>({ baseUrl = BASE_API_URL }: { baseUrl?: string } = {}): BaseQueryFn<
    {
      endpoint?: string;
      url?: string;
      method?: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      requestFile?: boolean;
    },
    ResponseBody,
    ErrorResponse,
    unknown,
    MetaData<unknown>
  > =>
  async ({ method = 'GET', data, params, url = '', endpoint, requestFile = false, ...args }) => {
    try {
      const endpointWithParams = getURLWithQueryParams(endpoint ?? baseUrl + url, params);

      // We need to handle request for a file differently, using the fetchService.requestFile method.
      // Since under the hood that method is using vanilla fetch, we need to convert the response
      // to .blob() before sending it back (that is when dealing with success response).
      if (requestFile) {
        const fileResponse = await fetchService.requestFile(endpointWithParams, {
          ...args,
          method,
          responseType: 'blob',
        });

        if (fileResponse.ok) {
          return { data: await fileResponse.blob() };
        }

        return {
          error: { status: fileResponse.status, data: fileResponse },
        };
      }

      const response: FetchServiceResponse<ResponseBody> = await fetchService.request({
        endpoint: endpointWithParams,
        method,
        data,
        ...args,
      });
      if (response.ok) {
        return { data: response.data };
      }
      return {
        error: {
          status: response.status,
          data: response,
          requestId: response.headers?.['request-id'],
        },
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
          requestId: err.response?.headers?.['request-id'],
        },
      };
    }
  };

export const axiosBaseQuery = generateAxiosBaseQuery(FetchService);
