import classNames from 'classnames';
import React from 'react';

import { Col, Row } from 'components/layout';
import { Text } from 'components/text';

import { typography } from 'constants/styles';

import { formatCurrencyExplicit } from 'helpers/currencyFormatter';
import { ternary } from 'helpers/utility';

import { useCurrencyContext } from 'hooks';

import type { FundingProviderMemoBankStatementProps } from './FundingProviderMemoBankStatement.types';

import './FundingProviderMemoBankStatement.scss';

/**
 * Component for showing the bank statement details in the FundingProviderMemo modal.
 */
const FundingProviderMemoBankStatement: React.FC<FundingProviderMemoBankStatementProps> = ({
  fundingProviderMemo,
  amount,
  companyName,
  currencyCode,
  isInputFocused,
}) => {
  const currencyCodeMap = useCurrencyContext();
  const renderAmount = ternary(
    amount,
    formatCurrencyExplicit(amount, currencyCode, currencyCodeMap),
    <>
      $
      <div className="no-amount" />
    </>,
  );

  return (
    <div className="funding-provider-memo-bank-statement">
      <Row className="funding-provider-memo-bank-statement__header-row">
        <Col>
          <Text.Bold>Description</Text.Bold>
        </Col>
        <Col>
          <Text.Bold>Deposit</Text.Bold>
        </Col>
      </Row>
      <Row className="margin-top--m-large">
        <Col>
          <Text.Bold color={typography.TextColor.GREY_DARK} size={typography.TextSize.LEVEL_200}>
            {companyName}
          </Text.Bold>
        </Col>
        <Col>
          <Text.Bold
            className="display--flex align-items--center"
            color={typography.TextColor.GREY_DARK}
            size={typography.TextSize.LEVEL_200}
          >
            {renderAmount}
          </Text.Bold>
        </Col>
      </Row>
      <Row className="margin-top--m">
        <Col className="funding-provider-memo--wrapper">
          <Text.Regular
            className="display--block truncate"
            color={typography.TextColor.BLUE_DARK}
            size={typography.TextSize.LEVEL_200}
          >
            {fundingProviderMemo || <>&nbsp;</>}

            <div
              className={classNames('funding-provider-memo--underline', {
                'is-focused': !!isInputFocused,
              })}
            />
          </Text.Regular>
        </Col>
      </Row>
    </div>
  );
};

export default FundingProviderMemoBankStatement;
