import { Email } from '@carbon/icons-react';
import { Button, Tooltip } from '@routable/gross-ds';
import React from 'react';

import { getPartnershipResendButtonProps } from 'helpers/partnerships';

import { ResendPartnershipInviteProps } from './ResendPartnershipInvite.types';

/**
 * Link used for triggering a resend invite action.
 */
export const ResendPartnershipInvite = ({
  id,
  onResendInvite,
  partnership,
  resendAction,
  variant = 'floating',
}: ResendPartnershipInviteProps) => {
  const { buttonText, isDisabled, tooltip } = getPartnershipResendButtonProps(partnership, resendAction);

  const handleResendInvite = () => {
    onResendInvite({ id, partnership });
  };

  return (
    <Tooltip data-testid="resend-partnership-invite--tooltip" tooltip={tooltip}>
      <Button
        data-testid="resend-partnership-invite--button"
        disabled={isDisabled}
        onPress={handleResendInvite}
        size="small"
        variant={variant}
      >
        <Email />
        {buttonText}
      </Button>
    </Tooltip>
  );
};
