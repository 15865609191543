import { AnyAction, Middleware } from 'redux';

import { MetaData } from 'actions/modifiers/meta.types';

import { toaster } from 'services/toaster';

import type { Toaster } from './notifications.types';

// if the event has a notification meta data, trigger it with toaster
export const generateNotificationMiddleware =
  (toast: Toaster): Middleware =>
  () =>
  (next) =>
  (action: MetaData<AnyAction>) => {
    if (action?.meta?.notification) {
      const { type, message, options } = action?.meta?.notification;
      toast[type](message, options);
    }
    return next(action);
  };

export const notificationMiddleware = generateNotificationMiddleware(toaster);
