import { itemApprovalsSummaryApi } from '@routable/ear/services/api/approvals/summary';
import { change, reset, SubmissionError } from 'redux-form';
import { all, call, put, select, spawn, take, takeEvery, throttle, race } from 'redux-saga/effects';

import { handleRequestErrors, showServerErrorAlert } from 'actions/errors';
import {
  downloadFailureReportRoutine,
  finalizeBulkActionRoutine,
  finalizeBulkImportItemsRoutine,
  getAllBulkImportsRoutine,
  getSingleBulkImportRoutine,
  submitBulkActionRoutine,
  submitBulkImportItemsRoutine,
  uploadBulkImportFileRoutine,
} from 'actions/routines/bulkActions';
import { fetchItemsRoutine } from 'actions/routines/item';
import { showErrorUi, showSuccessUi } from 'actions/ui';

import { BulkImportErrorCodes, CSV_TEMPLATE_FILENAME } from 'constants/bulkActions';
import { bulkActionsFormFields, bulkImportItemsFormFields } from 'constants/formFields';
import { formNamesBulkActions } from 'constants/forms';
import { ItemKinds } from 'constants/item';
import { PermissionResourceAction } from 'constants/permissions';
import { ErrorIndicatorMessages } from 'constants/ui';

import { bulkImportForFinalizeItems, transformPayloadForBulkActionSubmit } from 'data/submitTransformers/bulkActions';

import { BulkActionTypes } from 'enums/bulkActions';

import * as apiHelpers from 'helpers/api';
import { hasEmptyCsvError, hasInvalidCsvError, hasUnsupportedEncodingError } from 'helpers/bulkActions';
import * as errorHelpers from 'helpers/errors';
import { trackEvent, TrackEventName } from 'helpers/eventTracking';
import * as fileHelpers from 'helpers/fileHelpers';
import { checkMemberHasRequiredPermissions } from 'helpers/permissions';
import { systemLogger } from 'helpers/systemLogger';
import { or } from 'helpers/utility';

import { bulkActionsEligibleItemsFromSelectedForSelectedActionSelector } from 'queries/bulkActionsCompoundSelectors';
import { currentMemberPermissionsSetSelector } from 'queries/membershipPermissionCompoundSelectors';

import { setApprovalItemSummary } from 'reducers/approvalSummaryReducer';

import { bulkActionsImportByIdSelector, bulkActionsModalBulkActionSelector } from 'selectors/bulkActionsSelectors';
import { featureFlagEnterpriseApprovalRules } from 'selectors/featureFlagsSelectors';
import { currentMembershipIdSelector } from 'selectors/membershipsSelector';
import { tablePayablesTableSelector } from 'selectors/tableSelectors';

import { DOWNLOAD_CSV_TEMPLATE } from 'types/bulkActions';
import * as tableTypes from 'types/tables';
import * as types from 'types/ui';

import * as api from './api';

/**
 * Downloads the CSV template file
 * @return {IterableIterator<*>}
 */
export function* downloadCsvTemplateFile() {
  const response = yield call(api.downloadCsvTemplateFile);

  if (response.ok) {
    fileHelpers.downloadFile(response.data, CSV_TEMPLATE_FILENAME);
    return;
  }

  yield put(showErrorUi(ErrorIndicatorMessages.DOWNLOAD_TEMPLATE));
}

/**
 * Gets the status of the bulk import process.
 * @return {IterableIterator<*>}
 */
export function* fetchSingleBulkImport(action) {
  let errorData = {};

  try {
    const currentMemberPermissionSet = yield select(currentMemberPermissionsSetSelector);

    const memberHasPermission = checkMemberHasRequiredPermissions({
      actualMemberPermissionSet: currentMemberPermissionSet,
      requiredPermissions: [PermissionResourceAction.PAYABLE_CREATE],
    });

    if (!memberHasPermission) {
      return;
    }

    yield put(getSingleBulkImportRoutine.request());

    const { id } = action.payload;

    const response = yield call(api.getSingleBulkImport, id);

    if (response.ok) {
      yield put(getSingleBulkImportRoutine.success(response.data));
      return;
    }

    errorData = errorHelpers.parseErrorResponse(response);
  } catch (error) {
    errorData = errorHelpers.parseCaughtError(error);
  }

  yield put(handleRequestErrors(getSingleBulkImportRoutine.failure, errorData));
}

/**
 * Gets all of the bulk imports that have been processed
 * @see {BulkUploadHistoryTable}
 * @return {IterableIterator<*>}
 */
export function* fetchAllBulkImports(action) {
  yield put(getAllBulkImportsRoutine.request());

  let errorData = {};

  const cancelSource = apiHelpers.generateApiCancelSource();

  try {
    const { payload: params } = action;

    const { response, cancel } = yield race({
      response: call(api.getAllBulkImports, params, cancelSource),
      cancel: take(types.CANCEL_SAGA),
    });

    if (response.ok) {
      yield put(getAllBulkImportsRoutine.success(response.data));
      return;
    }

    if (cancel) {
      cancelSource.cancel();
      return;
    }

    errorData = errorHelpers.parseErrorResponse(response);
  } catch (error) {
    errorData = errorHelpers.parseCaughtError(error);
  }

  yield put(handleRequestErrors(getAllBulkImportsRoutine.failure, errorData));
}

/**
 * Handle uploading CSV
 * @return {IterableIterator<*>}
 */
export function* uploadBulkImportFile(action) {
  yield put(uploadBulkImportFileRoutine.request());

  let errorData = {};

  const { payload } = action;

  try {
    const fileBase64 = yield call(fileHelpers.getBase64, payload);
    const response = yield call(api.uploadBulkImportFile, payload, fileBase64);

    if (response.ok) {
      yield put(uploadBulkImportFileRoutine.success(response.data));
      return;
    }
    const fieldErrors = response.data?.errors?.fields;
    if (fieldErrors?.file) {
      if (
        or(
          hasUnsupportedEncodingError(fieldErrors.file),
          hasInvalidCsvError(fieldErrors.file),
          hasEmptyCsvError(fieldErrors.file),
        )
      ) {
        yield put(uploadBulkImportFileRoutine.failure({ errors: fieldErrors }));
        return;
      }
      yield all([
        put(showServerErrorAlert(fieldErrors.file, response.headers['request-id'])),
        put(reset(formNamesBulkActions.IMPORT_ITEMS)),
      ]);
    } else if (fieldErrors?.file_size?.includes(BulkImportErrorCodes.FILE_TOO_BIG)) {
      yield put(uploadBulkImportFileRoutine.failure({ errors: fieldErrors }));
      return;
    }

    errorData = errorHelpers.parseErrorResponse(response);
  } catch (error) {
    errorData = errorHelpers.parseCaughtError(error);
  }

  yield put(handleRequestErrors(uploadBulkImportFileRoutine.failure, errorData));
}

/**
 * Finalizes items for creation that were uploaded from a file in bulk.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* finalizeBulkImportItems(action) {
  yield put(finalizeBulkImportItemsRoutine.request());

  let errorData = {};

  try {
    const {
      payload: { props, values },
    } = action;

    const requestPayload = bulkImportForFinalizeItems(values, props, {
      kind: ItemKinds.PAYABLE,
    });

    const response = yield call(api.finalizeBulkImportItems, requestPayload);

    if (response.ok) {
      yield all([
        put(finalizeBulkImportItemsRoutine.success(response.data)),
        put(submitBulkImportItemsRoutine.success()),
      ]);
      return;
    }

    errorData = errorHelpers.parseErrorResponse(response);
  } catch (error) {
    errorData = errorHelpers.parseCaughtError(error);
  }

  yield put(submitBulkImportItemsRoutine.failure(new SubmissionError(errorData)));
}

/**
 * Finalizes items for selected bulk action
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* finalizeBulkAction(action) {
  yield put(finalizeBulkActionRoutine.request());

  let errorData = {};

  try {
    const { props, adesaBulkAction, successMessage } = action.payload;
    const { values, earEligibleItems } = props;

    const bulkActionRedux = yield select(bulkActionsModalBulkActionSelector);
    const selectedItems = yield select(bulkActionsEligibleItemsFromSelectedForSelectedActionSelector, props);
    const currentMembershipId = yield select(currentMembershipIdSelector);

    // If Adesa & the current bulk action is approve, we want to use the
    // earEligibleItems passed via props instead of the selected items from the
    // redux state
    const bulkAction = adesaBulkAction || bulkActionRedux;
    const items =
      adesaBulkAction && (bulkAction === BulkActionTypes.APPROVE || bulkAction === BulkActionTypes.APPROVE_AND_SEND)
        ? earEligibleItems
        : selectedItems;

    const requestPayload = transformPayloadForBulkActionSubmit({
      bulkAction,
      currentMembershipId,
      items,
      values,
    });

    const response = yield call(api.finalizeBulkAction, requestPayload);

    if (response.ok) {
      yield all([
        call(trackEvent, TrackEventName.BULK_ACTION_SUBMITTED, { bulkAction }),
        put(finalizeBulkActionRoutine.success(response.data)),
        put(submitBulkActionRoutine.success()),
        put(change(formNamesBulkActions.BULK_ACTIONS, bulkActionsFormFields.UI_HAS_SUBMITTED, true)),
      ]);

      if (adesaBulkAction) {
        yield put(fetchItemsRoutine({}));
      }
      if (successMessage) {
        yield put(showSuccessUi(successMessage));
      }
      return;
    }

    errorData = errorHelpers.parseErrorResponse(response);
  } catch (error) {
    errorData = errorHelpers.parseCaughtError(error);
  }

  yield put(handleRequestErrors(submitBulkActionRoutine.failure, errorData));
}

/**
 * Handles a submit call on the bulk actions form. May trigger finalization, UI
 * changes, or other side effects.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* submitBulkAction(action) {
  yield put(submitBulkActionRoutine.request());

  const { payload } = action;

  if (payload.values?.ui?.showConfirm) {
    yield put(finalizeBulkActionRoutine.trigger(payload));
    return;
  }

  yield all([
    put(change(formNamesBulkActions.BULK_ACTIONS, bulkActionsFormFields.UI_SHOW_CONFIRM, true)),
    put(submitBulkActionRoutine.success()),
  ]);
}

/**
 * Handles a submit call on the bulk import items form. May trigger finalization, UI
 * changes, or other side effects.
 * @param {ReduxAction} action
 * @return {IterableIterator<*>}
 */
export function* submitBulkImportItems(action) {
  yield put(submitBulkImportItemsRoutine.request());

  const { payload } = action;
  const { showConfirm } = payload.values.ui;

  if (showConfirm) {
    yield put(finalizeBulkImportItemsRoutine.trigger(payload));
    return;
  }

  yield put(change(formNamesBulkActions.IMPORT_ITEMS, bulkImportItemsFormFields.UI_SHOW_CONFIRM, true));

  yield put(submitBulkImportItemsRoutine.success());
}

export function* downloadFailureReport(action) {
  let errorData = {};

  try {
    const { id } = action.payload;

    const bulkImports = yield select(bulkActionsImportByIdSelector);

    const bulkImport = bulkImports[id];

    if (bulkImport) {
      const { fileErrorReport, filenameErrorReport } = bulkImport;
      yield call(fileHelpers.downloadFileFromURL, fileErrorReport, filenameErrorReport);
    }

    return;
  } catch (error) {
    errorData = errorHelpers.parseCaughtError(error);
  }

  yield put(handleRequestErrors(downloadFailureReportRoutine.failure, errorData));
}

/**
 * Listens for redux actions related to bulk import status triggers.
 * The throttle will always spawn after the first dispatch, then keeps at most
 * 1 more dispatch (the latest) in the queue while waiting for previous to complete.
 * @return {IterableIterator<*>}
 */
export function* watchSingle() {
  // 2 second throttle so we have visual time to step through  the loader (and
  // not render loading as a quick flash)
  yield throttle(2000, getSingleBulkImportRoutine.TRIGGER, fetchSingleBulkImport);
}

/**
 * Listens for redux actions related to bulk actions.
 * @return {IterableIterator<*>}
 */
export function* watch() {
  while (true) {
    const action = yield take([
      DOWNLOAD_CSV_TEMPLATE,
      downloadFailureReportRoutine.TRIGGER,
      finalizeBulkActionRoutine.TRIGGER,
      finalizeBulkImportItemsRoutine.TRIGGER,
      getAllBulkImportsRoutine.TRIGGER,
      submitBulkActionRoutine.TRIGGER,
      submitBulkImportItemsRoutine.TRIGGER,
      uploadBulkImportFileRoutine.TRIGGER,
    ]);

    switch (action.type) {
      case DOWNLOAD_CSV_TEMPLATE:
        yield spawn(downloadCsvTemplateFile);
        break;

      case downloadFailureReportRoutine.TRIGGER:
        yield spawn(downloadFailureReport, action);
        break;

      case finalizeBulkActionRoutine.TRIGGER:
        yield spawn(finalizeBulkAction, action);
        break;

      case finalizeBulkImportItemsRoutine.TRIGGER:
        yield spawn(finalizeBulkImportItems, action);
        break;

      case getAllBulkImportsRoutine.TRIGGER:
        yield spawn(fetchAllBulkImports, action);
        break;

      case submitBulkActionRoutine.TRIGGER:
        yield spawn(submitBulkAction, action);
        break;

      case submitBulkImportItemsRoutine.TRIGGER:
        yield spawn(submitBulkImportItems, action);
        break;

      case uploadBulkImportFileRoutine.TRIGGER:
        yield spawn(uploadBulkImportFile, action);
        break;

      default:
        yield null;
    }
  }
}

export function* fetchItemApprovalSummary() {
  const earEnabled = yield select(featureFlagEnterpriseApprovalRules);
  if (!earEnabled) {
    return;
  }
  try {
    const payablesTable = yield select(tablePayablesTableSelector);
    const { selected, lockedSelection } = payablesTable;
    const allItems = [...selected, ...lockedSelection];
    const { data: approvalsSummary } = yield call(
      itemApprovalsSummaryApi.itemsSummary,
      allItems.map(({ id }) => id),
    );
    if (!approvalsSummary) {
      systemLogger.log({
        level: 'ERROR',
        message: 'fetchItemApprobalSummary: no approvalSummary',
        error: approvalsSummary,
        items: allItems.map(({ id }) => id),
      });
      return;
    }
    yield put(setApprovalItemSummary(approvalsSummary.approvalStatuses));
  } catch (e) {
    systemLogger.log({
      level: 'ERROR',
      message: 'fetchItemApprobalSummary: exception',
      error: e,
    });
  }
}

export function* watchTableSelectionChanges() {
  yield takeEvery(
    [
      tableTypes.SELECT_SINGLE_PAYABLES_TABLE_ROW,
      tableTypes.DESELECT_SINGLE_PAYABLES_TABLE_ROW,
      tableTypes.SELECT_MULTIPLE_PAYABLES_TABLE_ROWS,
      tableTypes.DESELECT_MULTIPLE_PAYABLES_TABLE_ROWS,
      tableTypes.DESELECT_ALL_ROWS,
    ],
    fetchItemApprovalSummary,
  );
}

/**
 * Root bulk actions saga.
 * @return {IterableIterator<*>}
 */
export default function* bulkActions() {
  yield all([watch(), watchSingle(), watchTableSelectionChanges()]);
}
