import { FEATURE_FLAG_ENTERPRISE_APPROVAL_RULES } from 'constants/featureFlags';

import useFeatureFlag from 'hooks/useFeatureFlag';

export const useBillDetailsV2 = (): boolean => {
  const isEarEnabled = useFeatureFlag(FEATURE_FLAG_ENTERPRISE_APPROVAL_RULES);

  // [srajchevski] Temporary solution for clients with legacy approvals: block new payable details page if EAR is not turned on
  return isEarEnabled;
};
