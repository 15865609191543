import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { EmptyObject } from 'interfaces/global';

import { partnershipFromCurrentPartnershipRequestSelector } from 'queries/partnershipCompoundSelectors';

import UpdatePaymentMethodFundingAccounts from './UpdatePaymentMethodFundingAccounts';
import { StateProps, ComponentProps } from './UpdatePaymentMethodFundingAccounts.types';

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  partnership: partnershipFromCurrentPartnershipRequestSelector,
});

export default connect<StateProps, EmptyObject, ComponentProps>(mapStateToProps)(UpdatePaymentMethodFundingAccounts);
