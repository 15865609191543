import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { isSubmittingSignupCreateAccountSelector, signupCreateAccountErrorsSelector } from 'selectors/signupSelectors';

import submitSignupCreateAccount from 'thunks/submitSignupCreateAccount';

import CreateAccount from './CreateAccount';
import type { DispatchProps, StateProps } from './CreateAccount.types';

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  errors: signupCreateAccountErrorsSelector,
  isSubmitting: isSubmittingSignupCreateAccountSelector,
});

export const mapDispatchToProps: DispatchProps = {
  onSignupCreateAccount: submitSignupCreateAccount,
};

const enhance = compose(withRouter, connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps));

export default enhance(CreateAccount);
