import { colors } from 'constants/styles';

import type { AmountTextProp } from './AmountTextProps.types';

/**
 * Mapping to transform Text component based on conditions
 */
export const AmountTextProps: Record<string, AmountTextProp> = {
  CancelledItem: {
    className: 'text-decoration--line-through',
  },
  Default: {},
  OutstandingAmount: {
    color: colors.colorRedMediumHex,
  },
  ZeroAmount: {
    color: colors.colorGreyXDarkHex,
  },
};

export default AmountTextProps;
