/**
 * @module sagas/shared/tasks
 */
import { taxTools } from '@routable/taxes';
import { queryClient } from 'milton/components';
import { all, call, put } from 'redux-saga/effects';

import { fetchSinglePartnershipSuccess, fetchSinglePartnershipRequest } from 'actions/partnership';
import { submitCreatePartnershipRoutine } from 'actions/routines/partnership';
import { createPartnershipMemberRoutine } from 'actions/routines/partnershipMember';
import { contactSidePanelClose } from 'actions/sidePanels';
import { showErrorUi, showSuccessUi } from 'actions/ui';

import { partnershipMemberSubmitTransformers, partnershipSubmitTransformers } from 'data/submitTransformers';

import denormalize from 'helpers/denormalizer';
import { parseCaughtError, parseErrorResponse, getRequestErrorAction } from 'helpers/errors';
import { trackEvent, TrackEventName } from 'helpers/eventTracking';
import { isFormWithNestedCreatePartnershipMemberForm } from 'helpers/forms';
import { getMembershipSuccessIndicatorMessages, getMembershipErrorIndicatorMessages } from 'helpers/itemMember';
import { removeMetaFromPartnershipMembers } from 'helpers/partnershipMembers';
import { flattenDataForSearchCompanies, isSelectedPartnerTypeNew } from 'helpers/searchCompanies';
import { callIfIsFn, hasLength } from 'helpers/utility';

import { changeCreatePartnershipFormSubmitted } from 'modules/dashboard/createPartnership/helpers/actions';

import * as api from './api';
import * as fx from './sideEffects';

/**
 * Create a partnershipMember.
 * @param {ReduxSagaRoutineAction} action
 * @return {IterableIterator<*>}
 */
export function* createPartnershipMember({ payload }) {
  const { form, props, values } = payload;
  const { sidePanel } = props;
  const { partnershipId } = values.meta;

  let submitErrors = {};

  yield put(createPartnershipMemberRoutine.request());

  try {
    // when this form is submitted, it could potentially be from e.g. the create partnership flow,
    // and in that case, we can't ACTUALLY submit the form, because there's nothing to submit
    // to (the partnership does not yet exist). in this case, we're essentially submitting
    // to the parent form, and exiting.
    if (isFormWithNestedCreatePartnershipMemberForm(sidePanel.form)) {
      const nestedFormActions = fx.getActionsForNestedPartnershipMembersFormSubmission({
        form,
        values,
      });
      yield all(nestedFormActions.map((act) => put(act)));
      return;
    }

    // otherwise, we're adding to an existing partnership, and want to submit this standalone
    const submitPayload = partnershipMemberSubmitTransformers.partnershipMemberForCreate(values);

    const response = yield call(api.createPartnershipMember, partnershipId, submitPayload);

    if (response.ok) {
      yield all([
        put(
          createPartnershipMemberRoutine.success({
            ...response.data,
            meta: { partnershipId },
          }),
        ),
        put(fetchSinglePartnershipRequest(partnershipId)),
        put(contactSidePanelClose()),
        put(
          showSuccessUi(getMembershipSuccessIndicatorMessages(values), {
            dataFullStory: true,
          }),
        ),
      ]);

      // Since creating a partnership member can affect tax tools data
      // (e.g. contact status can become invalid or missing ), we want to
      // invalidate query cache on successful request
      queryClient.invalidateQueries({ queryKey: [taxTools] });

      // eslint-disable-next-line consistent-return
      return response.data;
    }

    submitErrors = parseErrorResponse(response);
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);

  yield all([
    put(errorAction(createPartnershipMemberRoutine.failure, submitErrors)),
    put(showErrorUi(getMembershipErrorIndicatorMessages(values))),
  ]);
}

/**
 * Handle sending an invite to a partnership.
 * @param {ReduxSagaRoutineAction} action
 * @param {Object} action.payload
 * @returns {IterableIterator<*>}
 */
export function* handleSendInvite({ payload }) {
  const { props, values } = payload;

  let submitErrors = {};

  try {
    const { partnership, sendEmail } = values;

    const { form } = props;

    let response;

    if (sendEmail) {
      const data = partnershipSubmitTransformers.sendPartnershipInviteData(values, {
        transformPartnershipMember: partnershipMemberSubmitTransformers.partnershipMemberForSendInvite,
      });
      response = yield call(api.sendPartnershipInvite, partnership.id, data);

      if (!response.ok) {
        submitErrors = parseErrorResponse(response);
      } else {
        // use response to update partnership stored in the redux store
        yield put(fetchSinglePartnershipSuccess(response.data));
      }
    }

    if (!response || response.ok) {
      // push user to the confirmation state
      yield all([put(changeCreatePartnershipFormSubmitted(form)), put(submitCreatePartnershipRoutine.fulfill())]);

      return;
    }
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(submitCreatePartnershipRoutine.failure, submitErrors));
}

/**
 * Handle sending the request to create a new partnership.
 * @param {ReduxSagaRoutineAction} action
 * @param {Object} action.payload
 * @returns {IterableIterator<*>}
 */
export function* handleCreatePartnership({ payload }) {
  const { props, values } = payload;

  let submitErrors = {};

  try {
    const { form, modal, viewModelManager } = props;

    let submitPayload = partnershipSubmitTransformers.partnershipForCreatePartnership(values);

    // when creating a partnership member using a known email, the `meta` property lives at the top level of the
    // submit DTO instead of on each partnershipMember. See https://warrenpay.atlassian.net/browse/BUGS-468 for more
    if (hasLength(submitPayload?.meta?.knownPartnershipIds)) {
      submitPayload = {
        ...submitPayload,
        partnershipMembers: removeMetaFromPartnershipMembers(submitPayload.partnershipMembers),
      };
    }

    const response = yield call(api.submitCreatePartnership, submitPayload);

    if (response.ok) {
      const denormalizedJsonResponse = denormalize(
        response.data,
        response.data.partnership[response.originalData.data.id],
      );
      const { hasEmail, hasContact } = denormalizedJsonResponse;
      let unreachableReason;

      if (!hasEmail) {
        unreachableReason = 'Contacts with no email';
      }

      if (!hasContact) {
        unreachableReason = 'No Contact';
      }

      if (unreachableReason) {
        yield call(trackEvent, TrackEventName.CREATE_COMPANY_NO_REACHABLE_VENDOR, { unreachableReason });
      }

      const partnershipData = flattenDataForSearchCompanies(denormalizedJsonResponse);

      const formattedPartnershipMembers = partnershipData.partnershipMembers.reduce(
        (obj, member) => ({
          ...obj,
          [member.id]: member,
        }),
        {},
      );

      const successActions = fx.getActionsForCreatePartnershipSuccess({
        data: response.data,
        form,
        formattedPartnershipMembers,
        originalData: response.originalData,
        partnershipData,
        viewModelManager,
      });

      yield all([...successActions.map((action) => put(action)), call(callIfIsFn, modal?.successCallback)]);

      // When a new partnership (vendor) is created, we want to invalidate
      // taxTools query cache. We don't need to invalidate the cache if a
      // customer is created as it doesn't affect the tax tools data.
      if (partnershipData?.isVendor) {
        queryClient.invalidateQueries({ queryKey: [taxTools] });
      }

      return partnershipData;
    }

    submitErrors = parseErrorResponse(response);
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(submitCreatePartnershipRoutine.failure, submitErrors));

  return submitErrors;
}

/**
 * Handle submitting a new partnership.
 * @param {ReduxSagaRoutineAction} action
 * @returns {IterableIterator<*>}
 */
export function* submitCreatePartnership(action) {
  const { values } = action.payload;

  let submitErrors = {};

  yield put(submitCreatePartnershipRoutine.request());

  try {
    const {
      partner,
      partnership,
      ui: { partnershipSubmitted, selectedCompany },
    } = values;

    if (partnershipSubmitted) {
      return yield call(handleSendInvite, action);
    }

    // the first condition is true when we're adding a known company as a vendor/customer,
    // while the second is true when we're adding a brand new company
    if ((partner.name && partnership.id) || isSelectedPartnerTypeNew(selectedCompany?.type)) {
      return yield call(handleCreatePartnership, action);
    }
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(submitCreatePartnershipRoutine.failure, submitErrors));

  return submitErrors;
}
