import styled from 'styled-components';

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  width: 100%;
  background-color: var(--white);
  gap: 10px;
  border: 1px solid var(--color-grey20);
  border-radius: 8px;
`;
