import React from 'react';

import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';

import { typography } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';

import type { LimitedImportingToBaseCurrencyTooltipProps } from './LimitedImportingToBaseCurrencyTooltip.types';

/**
 * Tooltip notifying the user that they are limited in importing only vendors who support their BCC (baseCurrencyCode)
 */
const LimitedImportingToBaseCurrencyTooltip = ({
  children,
  isShown,
  ledger,
}: LimitedImportingToBaseCurrencyTooltipProps): React.ReactElement => (
  <TooltipMUIConditionalWrapper
    tooltipProps={
      isShown
        ? {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            title: (
              <Text.Regular
                color={typography.TextColor.GREY_X_DARK}
                lineHeight={typography.TextLineHeight.MEDIUM}
                size={typography.TextSize.LEVEL_100}
              >
                {`Your account settings only allow you to import vendors associated with ${ledger.baseCurrencyCode} in your ${ledger.name} account.`}
              </Text.Regular>
            ),
          }
        : {}
    }
  >
    {children}
  </TooltipMUIConditionalWrapper>
);

export default LimitedImportingToBaseCurrencyTooltip;
