import React from 'react';

import { Text } from 'components';

import { typography } from 'constants/styles';

import { UpdatePaymentFlowFormLabelProps } from './UpdatePaymentFlowFormLabel.types';

/**
 * Display component used for outputting label text
 */
const UpdatePaymentFlowFormLabel: React.FC<UpdatePaymentFlowFormLabelProps> = ({ labelText }) => {
  const displayedText = labelText || 'Add another payment method';

  return (
    <div className="margin-bottom--m-large margin-top--larger">
      <Text.Bold color={typography.TextColor.GREY_XX_DARK} size={typography.TextSize.LEVEL_400}>
        {displayedText}
      </Text.Bold>
    </div>
  );
};

export default UpdatePaymentFlowFormLabel;
