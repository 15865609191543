import React from 'react';

import {
  FundingProviderMemoBankStatement,
  FundingProviderMemoTransactionCostHint,
  IconNames,
  InputField,
  ModalBodyContent,
  ModalFooter,
  ModalFooterButton,
  ModalFooterButtonCancel,
} from 'components';

import { EventKeyValues } from 'constants/events';
import { ADDENDA_RECORD_FIELD } from 'constants/formFields';
import { formNamesAddenda } from 'constants/forms';
import { sizes } from 'constants/styles';
import { SPECIAL_CHARS_VALIDATION, SPECIAL_CHARS_VALIDATION_REGEX } from 'constants/validation';

import { validateForm } from 'helpers/formValidation';
import {
  getFundingProviderMemoAddedOrUpdatedText,
  getFundingProviderMemoBillingCode,
  getFundingProviderMemoMaxCharLimit,
  getFundingProviderMemoModalButtonText,
  getRemittanceInfoOrAddendaRecordText,
} from 'helpers/fundingProviderMemo';
import { capitalize } from 'helpers/stringHelpers';
import { hasLength, isEqual } from 'helpers/utility';

import usePrevious from 'hooks/usePrevious';

import { FundingProviderMemoModalBodyProps } from './FundingProviderMemoModalBody.types';

/**
 * Content inside the FundingProviderMemoModal
 * @see {FundingProviderMemoModal}
 */
const FundingProviderMemoModalBody: React.FC<FundingProviderMemoModalBodyProps> = ({
  amount,
  billingDataAchAddenda,
  billingDataRtpRemittance,
  companyName,
  defaultFundingProviderMemoValue,
  isDeliveryOptionRTP,
  isInternationalPayment,
  currencyCode,
  onClose,
  onUpdate,
}) => {
  const [formErrors, setFormErrors] = React.useState({});
  const [inputValue, setInputValue] = React.useState(defaultFundingProviderMemoValue || '');
  const [isInputFocused, setInputFocus] = React.useState(false);
  const isInputTouched = usePrevious(isInputFocused);

  React.useEffect(() => {
    if (isInputTouched) {
      setFormErrors(validateForm(formNamesAddenda.ADDENDA_RECORD));
    }
  }, [inputValue, isInputTouched]);

  const successMessage = getFundingProviderMemoAddedOrUpdatedText(defaultFundingProviderMemoValue, isDeliveryOptionRTP);

  const handleKeyPress = (event) => {
    // allow the user to submit the addenda record modal with an enter keystroke
    if (isEqual(event.key, EventKeyValues.ENTER) && !!inputValue) {
      onUpdate(inputValue, successMessage);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    const errors = validateForm(formNamesAddenda.ADDENDA_RECORD);
    if (hasLength(errors[ADDENDA_RECORD_FIELD])) {
      setFormErrors(errors);
      return;
    }

    onUpdate(inputValue, successMessage);
  };

  const billingData = getFundingProviderMemoBillingCode(
    isDeliveryOptionRTP,
    billingDataAchAddenda,
    billingDataRtpRemittance,
  );

  return (
    <form id={formNamesAddenda.ADDENDA_RECORD}>
      <ModalBodyContent className="padding--remove--important">
        <div className="funding-provider-memo-bank-statement--wrapper">
          <FundingProviderMemoBankStatement
            amount={amount}
            companyName={companyName}
            currencyCode={currencyCode}
            fundingProviderMemo={inputValue}
            isInputFocused={isInputFocused}
          />
        </div>

        <div className="funding-provider-memo-bank-statement--bottom-container">
          <div className="margin-bottom--m">
            {/* @ts-ignore */}
            <InputField
              errors={formErrors}
              highlightRegex={SPECIAL_CHARS_VALIDATION_REGEX}
              isCharacterCountShown={isInputFocused}
              isRequired
              maximumCharacterCount={getFundingProviderMemoMaxCharLimit(isDeliveryOptionRTP)}
              name={ADDENDA_RECORD_FIELD}
              onBlur={() => setInputFocus(false)}
              onChange={(event) => setInputValue(event.target.value)}
              onFocus={() => setInputFocus(true)}
              onKeyPress={handleKeyPress}
              placeholder={capitalize(getRemittanceInfoOrAddendaRecordText(isDeliveryOptionRTP))}
              setIsRequired
              showValidationIcon
              type="text"
              validator={SPECIAL_CHARS_VALIDATION}
              value={inputValue}
            />
          </div>

          {!isInternationalPayment && <FundingProviderMemoTransactionCostHint billingData={billingData} />}
        </div>
      </ModalBodyContent>

      <ModalFooter className="width--100-percent">
        <div className="modal-footer--container">
          <div className="content--left">
            <ModalFooterButtonCancel onCloseModal={onClose} />
          </div>

          <div className="content--right">
            <ModalFooterButton
              isActionButton
              leftIconName={IconNames.TICK_CIRCLE}
              leftIconSize={sizes.iconSizes.LARGE}
              onClick={handleUpdate}
            >
              {getFundingProviderMemoModalButtonText(defaultFundingProviderMemoValue, isDeliveryOptionRTP)}
            </ModalFooterButton>
          </div>
        </div>
      </ModalFooter>
    </form>
  );
};

export default FundingProviderMemoModalBody;
