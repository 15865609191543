import { firstKey } from 'helpers/utility';

import type { RoutableApiResponse } from 'interfaces/routableApiResponse';

import { payloadToJSONAPI } from 'services/api/formatHelpers';

import { RateEstimateParameters, RateEstimateFormattedResponse } from './rateEstimateEndpoints.types';
import { routableApi } from './routableApi';

export const transformResponse = (response: RoutableApiResponse): RateEstimateFormattedResponse => {
  const { rateEstimate, fee } = response;
  if (rateEstimate && fee) {
    const { id: rateEstimateId, attributes: rateEstimateAttributes } = rateEstimate[firstKey(rateEstimate)];

    const fees = Object.values(fee).map(({ id: feeId, attributes: feeAttributes }) => ({
      id: feeId,
      ...feeAttributes,
    }));

    return {
      id: rateEstimateId,
      ...rateEstimateAttributes,
      fees,
    };
  }

  return { fees: [] };
};

const extendedRateEstimateApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    /** Get rate estimate based on item form arguments */
    getRateEstimate: builder.query<RateEstimateFormattedResponse, RateEstimateParameters>({
      query: (rateEstimatePayload) => ({
        url: `/funding_accounts/currency_exchange_rates/estimate/`,
        payload: payloadToJSONAPI(rateEstimatePayload, 'RateEstimate'),
        method: 'POST',
      }),
      transformResponse,
    }),
  }),
});

export const { useLazyGetRateEstimateQuery, useGetRateEstimateQuery } = extendedRateEstimateApi;
