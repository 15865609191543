import type { CurrencyCode } from 'interfaces/currency';

/**
 * Returns the reason the bill currency is disabled based on currencyCode
 * @param currencyCode
 * @returns string
 */
export const getBillCurrencyTooltipText = (currencyCode: CurrencyCode): string => {
  if (!currencyCode) {
    return null;
  }

  return `The bill currency is ${currencyCode}, so your vendor can only receive ${currencyCode}.`;
};
