import classNames from 'classnames';
import React from 'react';

import { RadioInput, Text, WhiteSpace } from 'components';
import { IconNames, Icon } from 'components/icon';

import { colors, typography } from 'constants/styles';

import { convertToLowerCase } from 'helpers/stringHelpers';
import { ternary } from 'helpers/utility';

import BankAccountHeaderRtpEnabledTag from 'modules/fundingAccount/bankAccount/components/BankAccountHeader/components/BankAccountHeaderRtpEnabledTag';

import type { PaymentMethodHeaderProps } from './PaymentMethodHeader.types';

/**
 * Renders the header for payment methods
 */
const PaymentMethodHeader: React.FC<PaymentMethodHeaderProps> = ({
  currencyCode,
  fundingAccountId,
  fundingIcon,
  input,
  isExpanded,
  isRTPEnabled = false,
  isSelectable = false,
  onToggleExpand,
  status,
  statusEl,
  subtitle,
  title,
}) => (
  <div className="payment_method__header-container">
    {isSelectable && (
      <div className="payment_method__header-container__radio">
        <RadioInput id={fundingAccountId} input={input} isDisabled={input?.disabled} />
      </div>
    )}

    <div className="payment_method__header-container__content" onClick={onToggleExpand} role="presentation">
      <div
        className={classNames(
          'payment_method__titles-icon-container',
          `payment_method__titles-icon-container--${convertToLowerCase(status)}`,
        )}
      >
        <Icon className="payment_method__funding-icon font-sm" size={14} {...fundingIcon} />
        <div className="payment_method__title-subtitle-wrapper">
          <div className="payment_method__title" data-fullstory>
            {title}
            {currencyCode && (
              <Text.Regular color={typography.TextColor.GREY_DARK} size={typography.TextSize.LEVEL_200}>
                <WhiteSpace />
                {currencyCode}
              </Text.Regular>
            )}
          </div>
          <div className="payment_method__subtitle-wrapper">
            <div className="payment_method__subtitle" data-fullstory>
              {subtitle}
            </div>
          </div>
        </div>
      </div>
      {isRTPEnabled && <BankAccountHeaderRtpEnabledTag />}
      {statusEl}
      <div className="payment_method__chevron">
        <Icon color={colors.colorGreyMedHex} icon={ternary(isExpanded, IconNames.CHEVRON_UP, IconNames.CHEVRON_DOWN)} />
      </div>
    </div>
  </div>
);

export default PaymentMethodHeader;
