import React from 'react';

import FileItem from './FileItem';
import { File } from './FileItem/FileItem';
import { FileListContainer } from './FileList.styles';
import { FileListProps } from './FilesList.types';

const FileList: React.FC<FileListProps> = ({ files, remove, useFormField = true, isDisabled }) => {
  if (files.length === 0) {
    return null;
  }
  return (
    <FileListContainer>
      {files.map((file, index) => {
        const key = `file-${index}`;
        return useFormField ? (
          <FileItem file={file} index={index} isDisabled={isDisabled} key={key} remove={remove} />
        ) : (
          <File index={index} input={{ value: file }} isDisabled={isDisabled} key={key} remove={remove} />
        );
      })}
    </FileListContainer>
  );
};

export default FileList;
