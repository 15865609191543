import React from 'react';

import { convertItemApprovalTypeToQuantity } from 'helpers/approvals';
import { convertNumberToOrdinalString } from 'helpers/stringHelpers';

import type { ApprovalLevelHeaderProps } from './ApprovalLevelHeader.types';

/**
 * ApprovalLevelHeader component
 */
const ApprovalLevelHeader = ({ levelPosition, levelType }: ApprovalLevelHeaderProps) => {
  const levelTypeLabel = convertItemApprovalTypeToQuantity(levelType);
  const ordinal = convertNumberToOrdinalString(levelPosition);

  return (
    <div className="display--flex margin-bottom--xm">
      <span className="font-semibold text-grey-60 text-xs">{`${ordinal} approval`}</span>
      <span>&nbsp;</span>
      <span className="font-light text-grey-60 text-xs">{`(${levelTypeLabel} required)`}</span>
    </div>
  );
};

export default ApprovalLevelHeader;
