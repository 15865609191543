import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import RestrictedPermissionContent from 'components/RestrictedPermissionContent';

import { TooltipPadding } from 'constants/tooltip';

import { redirectToTeamMembersPage } from 'helpers/navigation';
import { checkMemberHasRequiredPermissions } from 'helpers/permissions';

import { currentMemberPermissionsSetSelector } from 'queries/membershipPermissionCompoundSelectors';

import type { UsePermissionsResult, UseGrossDsPermissionsResult } from './usePermissions.types';

/**
 * Given an array of PermissionResourceActions, returns boolean value indicating
 * if the current member has at least one of the permissions in the supplied array.
 * @param permissions - Required permissions
 * @example
 * const { hasPermission, tooltipProps } = usePermissions([PermissionResourceAction.VENDOR_EDIT]);
 * console.log(hasPermission)
 * // true/false
 * <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>...</TooltipMUIConditionalWrapper>
 */
const usePermissions = (permissions: string[]): UsePermissionsResult => {
  const history = useHistory();
  const currentMemberPermissionSet = useSelector(currentMemberPermissionsSetSelector);

  const hasPermission = !permissions?.length
    ? true
    : checkMemberHasRequiredPermissions({
        actualMemberPermissionSet: currentMemberPermissionSet,
        requiredPermissions: permissions,
      });

  const onNavigateToTeamMemberPage = React.useCallback(() => {
    redirectToTeamMembersPage(history);
  }, [history]);

  const tooltipProps = React.useMemo(() => {
    if (hasPermission) {
      return undefined;
    }

    return {
      arrow: true,
      padding: TooltipPadding.LARGE,
      title: <RestrictedPermissionContent onNavigate={onNavigateToTeamMemberPage} />,
      'data-testid': 'tooltip--missing-permissions',
    };
  }, [hasPermission, onNavigateToTeamMemberPage]);

  return { hasPermission, tooltipProps };
};

export const useGrossDsPermissions = (permissions: string[]): UseGrossDsPermissionsResult => {
  const { hasPermission, tooltipProps } = usePermissions(permissions);

  return {
    hasPermission,
    tooltipProps: {
      tooltip: tooltipProps?.title,
      'data-testid': tooltipProps?.['data-testid'],
    },
  };
};

export default usePermissions;
