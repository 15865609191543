import React from 'react';

import { LinkNewPage } from 'components';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import helpDocs from 'helpers/helpDocs';

/**
 * Notification shown in createItem flow, notifying the user they will only be able to use their ledger's account base currency
 */
const LimitedToBaseCurrencyNotification = (): JSX.Element => (
  <>
    {`If you have or will enable international payments within ${PLATFORM_DISPLAY_SHORT_NAME}, you will be limited to only domestic payments. `}

    <LinkNewPage className="primary" href={helpDocs.SYNC_XB_PAYMENTS_TO_LEDGER}>
      Learn more ↗
    </LinkNewPage>
  </>
);

export default LimitedToBaseCurrencyNotification;
