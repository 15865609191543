import React from 'react';

import { IconNames } from 'components';

import { Details, DetailsContent, Summary, ChevronIcon } from './Accordion.styles';
import { AccordionProps } from './Accordion.type';
import Answer from './Answer';

const Accordion: React.VFC<AccordionProps> = ({ title, children, answers, containerWidth }) => (
  <Details containerWidth={containerWidth}>
    <Summary>
      <span>
        <ChevronIcon icon={IconNames.CHEVRON_RIGHT} />
      </span>
      {title}
    </Summary>
    <DetailsContent>
      {children}
      {answers?.length &&
        !children &&
        answers.map((answer, index) => {
          const key = `answer_${index}`;
          return <Answer {...answer} key={key} />;
        })}
    </DetailsContent>
  </Details>
);

export default Accordion;
