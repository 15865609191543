import { getQueryString } from 'helpers/queryParams';

import { UnknownObject } from 'interfaces/global';

import { WithApiRequestFor } from './withApiRequestFor.types';

export const withApiRequestFor: WithApiRequestFor = (key, baseOptions, routine) => ({
  ...routine,
  [key]: (options?: { params?: UnknownObject; [key: string]: unknown }) => {
    const { payload, ...rest } = routine[key](options, baseOptions);
    const endpoint = options?.params
      ? `${baseOptions.endpoint}?${getQueryString(options?.params)}`
      : baseOptions.endpoint;
    return {
      ...rest,
      payload: {
        onSuccess: routine.success,
        onFailure: routine.failure,
        ...baseOptions,
        endpoint,
        ...payload,
      },
    };
  },
});
