import { getQueryString } from 'helpers/queryParams';

import { QueryParams } from 'interfaces/jsonapi';

import { BASE_API_URL } from 'services/api';

export interface PaymentApiOptions {
  partnershipId?: string;
  paymentId?: string;
  params?: QueryParams;
}

// *************************************
// Payments
// *************************************

const getPartnerships = (partnershipId?: string): string => (partnershipId ? `/partnerships/${partnershipId}` : ``);

const getPaymentId = (paymentId?: string): string => (paymentId ? `/${paymentId}` : ``);

const getParams = (params?: PaymentApiOptions['params']) => (params ? `?${getQueryString(params)}` : ``);

export const paymentsEndpoint = `${BASE_API_URL}/payments/`;

export const getPaymentsEndpoint = ({ partnershipId, paymentId, params }: PaymentApiOptions): string =>
  `${BASE_API_URL}${
    // adds in partnership to url if supplied
    getPartnerships(partnershipId)
  }/payments${
    // add in payment id if supplied
    getPaymentId(paymentId)
  }/${
    // add any
    getParams(params)
  }`;
