import React from 'react';

import { LinkNewPage } from 'components/link';
import { WhiteSpace } from 'components/text';

import { PLATFORM_EMAILS } from 'constants/platform';
import { Intent } from 'constants/ui';

import { getMailtoUrl } from 'helpers/urls';

import BaseHint from '../BaseHint';

/**
 * Hint to display when we can't decode the CSV file to UTF-8.
 * @return {StatelessComponent}
 */
const CsvUnsupportedEncodingHint: React.FC = () => (
  <BaseHint
    intent={Intent.DANGER}
    text={
      <span>
        Please check that you are saving the .CSV file with UTF-8 encoding.
        <WhiteSpace />
        <LinkNewPage
          className="font-color--primary force-underline"
          href="https://docs.routable.com/en/articles/5561195"
        >
          Click here
        </LinkNewPage>
        <WhiteSpace />
        for more details or
        <WhiteSpace />
        <LinkNewPage className="font-color--primary force-underline" href={getMailtoUrl(PLATFORM_EMAILS.SUPPORT)}>
          contact support
        </LinkNewPage>
        <WhiteSpace />
        for assistance.
      </span>
    }
    title="Your file cannot be processed"
  />
);

export default CsvUnsupportedEncodingHint;
