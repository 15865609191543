import React from 'react';
import swal from 'sweetalert';

import { IconNames, ShadedBlock, SwalWrapperV2, Text } from 'components';
import { ServerErrorRequestId } from 'components/error/components';

import { ButtonSize } from 'constants/button';
import { GENERIC_ERROR } from 'constants/error';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { colors } from 'constants/styles';
import { Intent } from 'constants/ui';

import ContactSupportLink from 'modules/dashboard/settings/user/profile/UserSettingsProfile/components/ContactSupportLink';

import { FetchService } from 'services';

import type { TaxInfoBulkRequestErrorSwalProps } from './TaxInfoBulkRequestErrorSwal.types';

/**
 * Error SWAL component displaying a Tax Info Bulk Request error(s)
 * @param props - Component props
 */
const TaxInfoBulkRequestErrorSwal = ({
  errorMessage,
  requestId,
  statusCode,
}: TaxInfoBulkRequestErrorSwalProps): JSX.Element => {
  const isErrorTooManyRequests = FetchService.isResponseTooManyRequests({
    status: statusCode,
  });
  const swalTitle = isErrorTooManyRequests ? 'Tax request limit reached' : GENERIC_ERROR;

  const handleClose = () => {
    swal.close();
  };

  const genericErrorContent = (
    <>
      Please contact <ContactSupportLink>{`${PLATFORM_DISPLAY_SHORT_NAME} support`}</ContactSupportLink> and we&apos;ll
      be happy to assist.
    </>
  );

  return (
    <SwalWrapperV2
      iconProps={{
        color: colors.colorRedBoldHex,
        name: IconNames.WARNING_SIGN,
      }}
      rightButtonProps={{
        onClick: handleClose,
        children: 'Okay',
        intent: Intent.PRIMARY,
        size: ButtonSize.MEDIUM,
      }}
      title={swalTitle}
    >
      <Text.Regular>
        {!isErrorTooManyRequests && 'It looks like we ran into an issue.'}

        {isErrorTooManyRequests ? (
          <>{errorMessage}</>
        ) : (
          <>
            <ShadedBlock className="margin-bottom--m margin-top--m" intent={Intent.NEUTRAL}>
              {errorMessage}
            </ShadedBlock>
            {genericErrorContent}
          </>
        )}

        {!!requestId && !isErrorTooManyRequests && <ServerErrorRequestId>{requestId}</ServerErrorRequestId>}
      </Text.Regular>
    </SwalWrapperV2>
  );
};

export default TaxInfoBulkRequestErrorSwal;
