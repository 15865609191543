import { businessType } from '@routable/companies-management';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { w9FormBusinessTypeLabels } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.constants';
import { useTaxFormOptions } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.context';
import {
  w8ChapterFourStatus,
  w8ChapterThreeStatus,
  taxFormEntryType,
  taxFormType,
  TaxFormModel,
} from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';

import { isBusinessTypeSoleProprietor, isCompanyTypeBusiness } from 'helpers/currentCompany';
import { formatAsEIN, formatAsGIIN, formatAsSSN } from 'helpers/numbers';

import { getLabelValuePairsFromEnum } from '../helpers';
import RenderCheckbox from '../RenderCheckbox';
import { RenderMaskedTextInput } from '../RenderMaskedTextInput';
import RenderSelect from '../RenderSelect';
import RenderTextInput from '../RenderTextInput';
import { Group, GroupSlot } from '../styles';

import type { FieldGroupEntityInformationProps } from './FieldGroupEntityInformation.types';

const FieldGroupW8EntityInformation = ({
  businessLegalNamePlaceholder,
  isBusiness,
  isExternalEntry,
}: FieldGroupEntityInformationProps) => {
  const beneficialOwnerNamePlaceholder = isBusiness
    ? `${businessLegalNamePlaceholder} (as shown on ${isExternalEntry ? 'your ' : ''}income tax return)`
    : 'Legal name';

  return (
    <Group>
      <GroupSlot size={6}>
        <RenderTextInput isRequired name="beneficialOwnerName" placeholder={beneficialOwnerNamePlaceholder} />
      </GroupSlot>
      {isBusiness && (
        <>
          <GroupSlot size={3}>
            <RenderSelect
              isRequired
              label="Entity type (Chapter 3 status)"
              name="chapterThreeStatus"
              options={getLabelValuePairsFromEnum(w8ChapterThreeStatus)}
              placeholder="Select entity type"
            />
          </GroupSlot>
          <GroupSlot size={3}>
            <RenderSelect
              isRequired
              label="FATCA status (Chapter 4 status)"
              name="chapterFourStatus"
              options={getLabelValuePairsFromEnum(w8ChapterFourStatus)}
              placeholder="Select FATCA status"
            />
          </GroupSlot>
          <GroupSlot size={6}>
            <RenderMaskedTextInput formatter={formatAsGIIN} name="giin" placeholder="GIIN" />
          </GroupSlot>
        </>
      )}
    </Group>
  );
};

const FieldGroupW9EntityInformation = ({
  businessLegalNamePlaceholder,
  isBusiness,
  isExternalEntry,
}: FieldGroupEntityInformationProps) => {
  const { watch, setValue, unregister } = useFormContext();

  const selectedBusinessType = watch('businessType');
  const isBusinessTinTypeSSN = watch('isBusinessTinTypeSSN');
  const isSoleProprietor = isBusinessTypeSoleProprietor(selectedBusinessType);

  const isBusinessTinTypeSSNLabel = `${isExternalEntry ? "I don't" : "Vendor doesn't"} have a Business EIN`;
  const tinPlaceholder = isBusiness && !isBusinessTinTypeSSN ? 'Business EIN' : 'Social Security Number';

  const tinFormatter = isBusiness && !isBusinessTinTypeSSN ? formatAsEIN : formatAsSSN;

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      if (name === 'companyType' && type === 'change') {
        // We want to reset the legal name field anytime the company type
        // changes to avoid having validation on unexisting inputs
        unregister('legalName');
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, setValue, unregister]);

  return (
    <Group>
      {isBusiness ? (
        <>
          <GroupSlot size={6}>
            <RenderTextInput
              isRequired
              key="businessLegalName"
              name="legalName.0"
              placeholder={businessLegalNamePlaceholder}
            />
          </GroupSlot>
          <GroupSlot size={6}>
            <RenderTextInput
              key="businessName"
              name="businessName"
              placeholder="Business name/diregarded entity name (if different from above)"
            />
          </GroupSlot>
          <GroupSlot size={6}>
            <RenderSelect
              isRequired
              key="businessType"
              label="Business type"
              name="businessType"
              options={getLabelValuePairsFromEnum(businessType, w9FormBusinessTypeLabels)}
              placeholder="Select business type"
              shouldUnregister
            />
          </GroupSlot>
          {selectedBusinessType === businessType.Enum.other && (
            <GroupSlot size={6}>
              <RenderTextInput
                isRequired
                key="businessTypeOtherDescription"
                name="businessTypeOtherDescription"
                placeholder="Other business type"
                shouldUnregister
              />
            </GroupSlot>
          )}
        </>
      ) : (
        <>
          <GroupSlot size={3}>
            <RenderTextInput isRequired key="legalFirstName" name="legalName.0" placeholder="Legal first name" />
          </GroupSlot>
          <GroupSlot size={3}>
            <RenderTextInput
              isRequired
              key="legalLastName"
              name="legalName.1"
              placeholder="Legal last name"
              shouldUnregister
            />
          </GroupSlot>
        </>
      )}

      <GroupSlot size={6}>
        <RenderMaskedTextInput
          formatter={tinFormatter}
          isRequired
          key={isBusiness ? 'businessTin' : 'individualTin'}
          name="tin"
          placeholder={tinPlaceholder}
        />
      </GroupSlot>

      {isSoleProprietor && (
        <GroupSlot size={6}>
          <RenderCheckbox label={isBusinessTinTypeSSNLabel} name="isBusinessTinTypeSSN" shouldUnregister />
        </GroupSlot>
      )}
    </Group>
  );
};

/**
 * Tax form field group component containing fields related to the entity information.
 * We render different fields based on the form type (W9 vs W8-BEN vs W8-BEN-E)
 */
const FieldGroupEntityInformation = () => {
  const { watch } = useFormContext<TaxFormModel>();
  const { entryType, formType } = useTaxFormOptions();

  const companyType = watch('companyType');
  const isBusiness = isCompanyTypeBusiness(companyType);

  const isExternalEntry = entryType === taxFormEntryType.Enum.external;
  const businessLegalNamePlaceholder = isExternalEntry ? 'Business legal name' : "Vendor's business legal name";

  const EntityInformationGroup =
    formType === taxFormType.Enum.W8 ? FieldGroupW8EntityInformation : FieldGroupW9EntityInformation;

  return (
    <EntityInformationGroup
      businessLegalNamePlaceholder={businessLegalNamePlaceholder}
      isBusiness={isBusiness}
      isExternalEntry={isExternalEntry}
    />
  );
};

export default FieldGroupEntityInformation;
