import React from 'react';
import { useHistory } from 'react-router-dom';

import { RestrictedPermissionContent } from 'components';

import { redirectToTeamMembersPage } from 'helpers/navigation';

/**
 * Returns the RestrictedPermissionContent with the onNavigate prop
 * @return {StatelessComponent}
 */
const RestrictedPermissionTooltipContentContainer = () => {
  const history = useHistory();
  const onNavigate = () => redirectToTeamMembersPage(history);

  return <RestrictedPermissionContent onNavigate={onNavigate} />;
};

export default RestrictedPermissionTooltipContentContainer;
