import React from 'react';
import { Field } from 'redux-form';

import { SelectFieldV2 } from 'components/selectV2';

import { Tooltip } from './components';
import type { VendorReceivingCurrencyProps } from './VendorReceivingCurrency.types';

/**
 * Presentational component for rendering a SelectField with options and locked tooltip
 */
const VendorReceivingCurrency = ({
  className,
  fieldLabel,
  fieldName,
  isLocked,
  options,
  tooltipText,
}: VendorReceivingCurrencyProps): JSX.Element => (
  <Field
    className={className}
    component={SelectFieldV2}
    isDisabled={isLocked}
    isLocked={isLocked}
    label={fieldLabel}
    lockedTooltipProps={
      isLocked && tooltipText ? { title: <Tooltip showTooltip={isLocked} tooltipText={tooltipText} /> } : {}
    }
    name={fieldName}
    options={options}
    placeholder="Select"
  />
);

export default VendorReceivingCurrency;
