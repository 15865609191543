import { useMemo } from 'react';

import { getCountryNameFromCode } from 'helpers/countries';

import { useCountryCodesContext } from 'hooks';

import type { CountryCodeMap } from 'interfaces/countries';
import type { Partnership } from 'interfaces/partnerships';

import { byAlphabeticalOrder, filterCountryCodesByCountryPaymentOptions, putPopularCountriesFirst } from './helpers';

/**
 * Hook for formatting country options consumed by CountryField.
 */
export const useFormattedCountries = (countryCodes: CountryCodeMap, partnership?: Partnership) => {
  const countryCodeMap = useCountryCodesContext();

  return useMemo(() => {
    const filteredCountryCodes = filterCountryCodesByCountryPaymentOptions(countryCodes, partnership);
    const formattedCountries = Object.keys(filteredCountryCodes).map((countryCode, index) => ({
      id: index + 1,
      value: countryCode,
      label: getCountryNameFromCode(countryCodeMap, countryCode),
    }));
    const sortedCountries = [...formattedCountries].sort(byAlphabeticalOrder);
    const prioritizedCountries = putPopularCountriesFirst(sortedCountries);

    const countriesWithNationalities = Object.keys(filteredCountryCodes).map((countryCode, idx) => ({
      id: idx + 1,
      value: countryCode,
      label: `${countryCode} - ${countryCodeMap[countryCode].nationality}`,
    }));
    const sortedCountriesWithNationalities = [...countriesWithNationalities].sort(byAlphabeticalOrder);
    const prioritizedCountriesWithNationalities = putPopularCountriesFirst(sortedCountriesWithNationalities);

    return {
      countriesWithNationalities,
      formattedCountries,
      prioritizedCountries,
      prioritizedCountriesWithNationalities,
      sortedCountries,
      sortedCountriesWithNationalities,
    };
  }, [countryCodeMap, countryCodes, partnership]);
};
