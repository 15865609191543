import React from 'react';
import { Redirect } from 'react-router-dom';

import { DASHBOARD, LOGIN } from 'constants/routes';

import { getQueryParamsFromUrl } from 'helpers/queryParams';
import { asPath } from 'helpers/routeHelpers';

import QuickswitchErrorModal from '../QuickswitchErrorModal';
import QuickswitchSplashscreen from '../QuickswitchSplashscreen/QuickswitchSplashscreen';

type QuickswitchToBrandedSubdomainContentProps = {
  goToDashboard: boolean;
  goToLogin: boolean;
  showErrorModal: boolean;
  companyName?: string;
};

const QuickswitchToBrandedSubdomainContent: React.FC<QuickswitchToBrandedSubdomainContentProps> = ({
  goToDashboard,
  goToLogin,
  showErrorModal,
  companyName,
}) => {
  if (!('data' in getQueryParamsFromUrl()) || goToLogin) {
    return <Redirect to={asPath(LOGIN)} />;
  }
  if (goToDashboard) {
    return <Redirect to={asPath(DASHBOARD)} />;
  }
  if (showErrorModal) {
    return <QuickswitchErrorModal isModalOpen />;
  }
  return <QuickswitchSplashscreen companyName={companyName} />;
};

QuickswitchToBrandedSubdomainContent.defaultProps = { companyName: undefined };

export default QuickswitchToBrandedSubdomainContent;
