import { apiCall } from '@routable/framework';
import { useQuery } from '@tanstack/react-query';
import { queryContext } from 'milton/components';
import React from 'react';
import { useSelector } from 'react-redux';

import { PLATFORM_EMAILS } from 'constants/platform';

import { getMailtoUrl, isExternalZendeskRoute } from 'helpers/urls';
import { isFn } from 'helpers/utility';

import { currentMembershipSelector } from 'selectors/membershipsSelector';

import { ZENDESK_TOKEN_INITIAL_REFETCH_INTERVAL } from './constants';
import { membershipConfigResponseModel } from './CustomerService.models';

declare const window: {
  zE(...props: unknown[]): void;
  location: string;
};

type ConversationField = {
  id: string;
  value: string | number | boolean;
};

export const CustomerServiceHelper = {
  onScriptLoaded() {
    // Hide the widget when the page loads
    this.hide();
    // When the messenger is closed, hide the widget completely
    window.zE('messenger:on', 'close', () => this.hide());
    // Show the messenger when there are unread messages

    // TODO: This is causing the app to always open the chat on page load
    // We're temporarily removing this until we find why the `unreadMessages`
    // event is marking bot messages as unread even if the user already dismissed the chat
    // window.zE('messenger:on', 'unreadMessages', (count: number) => {
    //   if (count > 0) {
    //     this.show();
    //   }
    // });
  },
  open() {
    window.zE?.('messenger', 'open');
  },
  close() {
    window.zE?.('messenger', 'close');
  },
  hide() {
    window.zE?.('messenger', 'hide');
  },
  show({
    fallbackEmail = PLATFORM_EMAILS.SUPPORT,
  }: {
    fallbackEmail?: string;
  } = {}) {
    if (this.isWidgetAvailable()) {
      window.zE?.('messenger', 'show');
      this.open();
    } else {
      window.location = getMailtoUrl(fallbackEmail);
    }
  },
  showAndSetRequestId({ requestId }: { requestId: ValueOf<Pick<ConversationField, 'value'>> }) {
    this.show();
    if (this.isWidgetAvailable()) {
      this.setConversationFields([
        {
          id: process.env.ROUTABLE_FE_ZENDESK_REQUEST_FIELD_ID,
          value: requestId,
        },
      ]);
    }
  },
  showAndSetPaymentId({ paymentId }: { paymentId: ValueOf<Pick<ConversationField, 'value'>> }) {
    this.show();
    if (this.isWidgetAvailable()) {
      this.setConversationFields([
        {
          id: process.env.ROUTABLE_FE_ZENDESK_PAYMENT_FIELD_ID,
          value: paymentId,
        },
      ]);
    }
  },
  setIdentification(jwt: string) {
    window.zE?.('messenger', 'loginUser', (callback) => {
      callback(jwt);
    });
  },
  setConversationFields(fields: ConversationField[]) {
    window.zE?.('messenger:set', 'conversationFields', fields);
  },
  logout() {
    window.zE?.('messenger', 'logoutUser');
  },
  isWidgetAvailable() {
    return Boolean(window.zE && isFn(window.zE));
  },
} as const;

export const useCustomerServiceWidget = (): void => {
  const [loaded, setLoaded] = React.useState(false);
  const [expiration, setExpiration] = React.useState(ZENDESK_TOKEN_INITIAL_REFETCH_INTERVAL);
  const currentMembership = useSelector(currentMembershipSelector);

  useQuery({
    queryKey: ['service token', 'zendesk'],
    context: queryContext,
    queryFn: () =>
      apiCall({
        url: `memberships/${currentMembership?.id}/config/`,
        responseModel: membershipConfigResponseModel,
        method: 'get',
        camelCaseResponse: true,
      }),
    onSuccess(data) {
      const { zendeskPayload } = data?.data?.attributes || {};

      if (!zendeskPayload) {
        return;
      }

      CustomerServiceHelper.setIdentification(zendeskPayload?.token);
      setExpiration(zendeskPayload?.tokenTtl * 1000); // multiple by 1000 to have it in miliseconds
    },
    refetchInterval: expiration,
    enabled: Boolean(loaded && currentMembership?.id),
  });

  React.useEffect(() => {
    let cancelled = false;
    const widgetId = isExternalZendeskRoute()
      ? process.env.ROUTABLE_FE_ZENDESK_EXTERNAL_WIDGET_ID
      : process.env.ROUTABLE_FE_ZENDESK_INTERNAL_WIDGET_ID;

    if (!widgetId) {
      return () => {};
    }

    const script = document.createElement('script');
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${widgetId}`;
    script.id = 'ze-snippet';
    script.async = true;
    script.addEventListener('load', () => {
      if (cancelled) {
        return;
      }
      CustomerServiceHelper.onScriptLoaded();
      setLoaded(true);
    });
    document.body.appendChild(script);

    return () => {
      cancelled = true;
      setLoaded(false);
      document.body.removeChild(script);
    };
  }, []);
};
