import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import Icon, { IconNames } from 'components/icon';

import { getIsFetchingStrength, getPasswordScore } from 'selectors/passwordInputSelectors';

import { availableRequirementValidators } from '../../constants/availableRequirementValidators';
import { passwordRequirements } from '../../helpers/passwordRequirements';
import { PasswordFormState } from '../../types/PasswordInput.types';

import { iconByStrength, iconWhenFetching } from './constants/iconByStrength';
import { strengthScores, strengthTypes } from './constants/strengthScores';
import { getIconColor } from './helpers/iconColors';
import { IndividualHintProps, PasswordStrengthProps, PasswordHintProps } from './types/PasswordHints.types';

import './PasswordHints.module.scss';

export const IndividualHint: React.FC<IndividualHintProps> = ({ formName, requirement, styling }) => {
  const formValues: PasswordFormState = useSelector(getFormValues(formName));

  const currentPassword = formValues.user?.password;
  const errors = passwordRequirements[requirement]?.validator(currentPassword);
  const isValid = !errors;

  return (
    <div className="individual-hint">
      <div className="hint-icon-container">
        <Icon
          color={getIconColor(isValid, styling)}
          dataTestId="password-hint-icon"
          icon={isValid ? IconNames.SMALL_TICK : IconNames.SMALL_CROSS}
        />
      </div>

      <span className={classNames('hint-requirement', isValid ? 'valid' : 'invalid', styling)}>
        {passwordRequirements[requirement].label}
      </span>
    </div>
  );
};

export const PasswordStrength: React.FC<PasswordStrengthProps> = ({ styling }) => {
  const passwordScore = useSelector(getPasswordScore);
  const strength = strengthScores[passwordScore];

  const isFetching = useSelector(getIsFetchingStrength);

  const isValid = strength !== strengthTypes.WEAK;

  return (
    <div className={classNames('password-strength-container', strength)}>
      <div className="password-icon-span-container">
        <div className={classNames('password-strength-icon', styling)}>
          {isFetching ? (
            iconWhenFetching
          ) : (
            <Icon color={getIconColor(isValid, styling)} icon={iconByStrength[strength]} />
          )}
        </div>

        <span className={classNames('password-strength', strength, styling)}>
          Password strength:
          {strength}
        </span>
      </div>
    </div>
  );
};

const PasswordHints: React.FC<PasswordHintProps> = ({ formName, requirementsAreMet, showStrength, styling }) => (
  <div className="password-hint-container">
    {showStrength && <PasswordStrength styling={styling} />}

    {showStrength &&
      !requirementsAreMet &&
      Object.values(availableRequirementValidators).map((requirement, idx) => (
        <IndividualHint
          formName={formName}
          key={`individualHint${idx.toString()}`}
          requirement={requirement}
          styling={styling}
        />
      ))}
  </div>
);

export default PasswordHints;
