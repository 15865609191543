import React from 'react';
import { Field } from 'redux-form';

import Document from 'components/document';

import { getVerificationDocumentStatus } from 'helpers/document';

import { File } from '../FilesList.types';

const FileItem = ({ input }) => (
  <>
    {input.value.status && !input.value.canDelete && (
      <Document
        className="w-full document margin-bottom--m"
        documentCreatedDate={input.value.created}
        documentName={input.value.filename}
        documentStatus={getVerificationDocumentStatus(input.value)}
        documentURL={input.value.file}
        hasStatusTag
        isClearable={false}
        key={input.value.id}
      />
    )}
  </>
);

const FileError: React.FC<{ file: File }> = ({ file }) => <Field component={FileItem} name={`${file}`} type="file" />;

export default FileError;
