export enum TaxInfoRequestButtonDisplayType {
  /**
   * Large, neutral button without an icon
   */
  LARGE = 'large',
  /**
   * Small, neutral button without an icon
   */
  COMPACT = 'compact',
  /**
   * Small, neutral button with an envelope icon on the left side
   */
  COMPACT_WITH_ICON = 'compact_inverted',
}
