import styled from 'styled-components';

export const Group = styled.div.withConfig({ displayName: 'Group' })`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: var(--spacing-5);

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const GroupSlot = styled.div.withConfig({ displayName: 'GroupSlot' })<{
  size?: number;
}>`
  grid-column: span ${({ size }) => size || 4};

  @media screen and (max-width: 768px) {
    grid-column: unset;
  }
`;
