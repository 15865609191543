import React from 'react';

import {
  UpdatePaymentMethodFormInternational,
  UpdatePaymentMethodFormWithCheckboxAndHintsDomestic,
} from './components';
import { UpdatePaymentMethodFormWithCheckboxAndHintsProps } from './UpdatePaymentMethodFormWithCheckboxAndHints.types';

/**
 * Top-level Update Payment Method form component. It renders correct form component
 * based on the domestic/international partnership.
 * @param {UpdatePaymentMethodFormWithCheckboxAndHintsProps} props
 * @return {StatelessComponent}
 */
const UpdatePaymentMethodFormWithCheckboxAndHints: React.FC<UpdatePaymentMethodFormWithCheckboxAndHintsProps> = ({
  isInternational,
  isUpdatePaymentMethodFlow,
}) => {
  // If the partnership is internation and the partnership currency code is non-USD (that exludes
  // Canadian partnerships with USD as the currency) we want to display the international update
  // payment method form
  if (isInternational) {
    return <UpdatePaymentMethodFormInternational isUpdatePaymentMethodFlow={isUpdatePaymentMethodFlow} />;
  }

  // Otherwise, we display the domestic update payment method form
  return <UpdatePaymentMethodFormWithCheckboxAndHintsDomestic isUpdatePaymentMethodFlow={isUpdatePaymentMethodFlow} />;
};

export default UpdatePaymentMethodFormWithCheckboxAndHints;
