import React from 'react';

import { LinkNewPage } from 'components/link';
import { LabelLink } from 'components/link/LabelLink/LabelLink.styles';

import { PartnershipMemberAccess } from 'enums/partnerships';

import { getCompanyRouteByPartnershipId } from 'helpers/routeHelpers';

import { getOnCreateContactOptions } from 'modules/contacts/components/GroupedContactSelectsInForm/logic';

import type { CreateEditPaymentHintTextBusinessComponentProps } from '../../ContactRequiredToAllowSelectPaymentMethodHint.types';

/**
 * Hint text element specific for the create/edit payable flow
 */
const CreateEditPaymentHintTextBusinessComponent = ({
  isInviting = false,
  onOpenContactSidePanel,
  selectedCompany,
}: CreateEditPaymentHintTextBusinessComponentProps): JSX.Element => {
  const createContactOptions = getOnCreateContactOptions('', selectedCompany, PartnershipMemberAccess.ACTIONABLE);
  const linkToPartnerCompanyPage = getCompanyRouteByPartnershipId(selectedCompany?.id);

  return (
    <span className="contact-required-to-select-payment-hint">
      {`To allow a vendor to ${isInviting ? 'add' : 'select'} their payment method, please include a contact above,`}
      <br />
      <LabelLink onClick={() => onOpenContactSidePanel(createContactOptions)}>add a new contact</LabelLink>
      {`, or `}
      <LinkNewPage href={linkToPartnerCompanyPage}>edit existing contacts ↗</LinkNewPage>.
    </span>
  );
};

export default CreateEditPaymentHintTextBusinessComponent;
