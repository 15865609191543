import dayjs from 'dayjs';
import React from 'react';
import ReactDatePicker from 'react-datepicker';

import DatePickerCustomInput from 'components/form/CustomDatePickerInput';

import { DatePickerProps } from './DatePicker.types';

import 'react-datepicker/dist/react-datepicker.css';

/**
 * Date picker component
 * @param {DatePickerProps} props
 * @returns {StatelessComponent}
 */
const DatePicker: React.FC<DatePickerProps> = ({
  addon,
  autoFocus,
  hasErrors,
  hideLabel,
  innerRef,
  input,
  isDisabled,
  isRequired,
  meta,
  selected,
  ...rest
}) => {
  if (!input) {
    return null;
  }

  return (
    <ReactDatePicker
      {...rest}
      autoFocus={autoFocus}
      customInput={
        <DatePickerCustomInput
          addon={addon}
          hasErrors={hasErrors}
          hideLabel={hideLabel}
          input={input}
          isRequired={isRequired}
          meta={meta}
        />
      }
      disabled={isDisabled}
      name={input.name}
      onChange={input.onChange}
      onFocus={input.onFocus}
      ref={innerRef}
      selected={selected || (input.value && dayjs(input.value).toDate())}
      todayButton="Today"
    />
  );
};

DatePicker.defaultProps = {
  allowSameDay: true,
  maxDate: null,
  meta: {},
  minDate: null,
  placeholderText: 'Select date',
  selected: null,
};

export default DatePicker;
