import React from 'react';

import { IconNames, Icon } from 'components/icon';
import InputFieldWrapper from 'components/input';

import { colors, sizes } from 'constants/styles';

import { ternary } from 'helpers/utility';

import './SearchBar.scss';

export interface SearchBarProps {
  onTermChange: (searchTerm: string) => void;
  onSearchExecuted: (searchTerm: string) => void;
  placeholderText: string;
  searchTerm: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ placeholderText, onTermChange, onSearchExecuted, searchTerm }) => {
  const handleClearSearch = () => {
    onTermChange('');
  };

  const handleOnChange = (e) => {
    onTermChange(e.target.value);
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    onSearchExecuted(searchTerm);
  };

  const inputAddon = ternary(
    searchTerm,
    <span
      className="icon-ic-x-out general-cursor-hover search-x-icon"
      onClick={handleClearSearch}
      role="presentation"
    />,
    <Icon color={colors.colorMainJordanHex} icon={IconNames.SEARCH} size={sizes.iconSizes.MEDIUM} />,
  );

  return (
    <form className="form margin-left--auto" id="company-match-form" onSubmit={handleSubmitSearch}>
      <InputFieldWrapper
        addon={inputAddon}
        // eslint-disable-next-line react/no-children-prop
        children={undefined}
        className="h-m"
        isRequired={false}
        name="search"
        onChange={handleOnChange}
        placeholder={placeholderText}
        type="text"
        value={searchTerm}
      />
    </form>
  );
};

export default SearchBar;
