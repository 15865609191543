import { change, FormAction } from 'redux-form';

import { createPartnershipFormFields } from 'constants/formFields';
import { formNamesPartnership } from 'constants/forms';

/**
 * Action creator which submits a redux-form change event to clear the inviteMessage field value.
 * If an inviteMessage is typed and the 'remove message' button is pressed, the inviteMessage is still sent.
 * Clearing the inviteMessage field value solves this issue.
 */
export const clearCreatePartnershipInviteMessage = (formName = formNamesPartnership.CREATE): FormAction =>
  change(formName, createPartnershipFormFields.INVITE_MESSAGE, '');

/**
 * Action creator which submits a redux-form change event to update the ui.formSubmitted boolean. This boolean controls
 * whether to show/hide the form or confirmation screen for create partnership, although we've generalized this function
 * to be reusable with function params, albeit with defaults.
 *
 * @param {string} [formName=formNamesPartnership.CREATE]
 * @param {boolean} [newValue=true]
 * @returns {FormAction}
 */
export const changeCreatePartnershipFormSubmitted = (
  formName = formNamesPartnership.CREATE,
  newValue = true,
): FormAction => change(formName, createPartnershipFormFields.UI_FORM_SUBMITTED, newValue);
