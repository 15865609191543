import { DomesticCountryCodes, StaticCountryCode } from 'constants/countries';

import type { CountryCode, CountryName, CountryCodeMap, CountryInfo } from 'interfaces/countries';

import { isEqual } from './utility';

/**
 * Returns true if given country code is a US country code
 * or country code is empty, which is assumed domestic
 * @param {CountryCode} countryCode - Country code to check against
 * @returns True if given country code is US or US territory country code
 */
export const isCountryCodeUSOrUSTerritory = (countryCode: CountryCode): boolean => {
  const isCountryCodeDomestic = DomesticCountryCodes.includes(countryCode);
  return isCountryCodeDomestic || countryCode === '';
};

/**
 * Returns true if given country code is a Canada country code
 * @param {CountryCode} countryCode - Country code to check against
 * @returns True if given country code is Canada country code
 */
export const isCountryCodeCanada = (countryCode: CountryCode): boolean => isEqual(countryCode, StaticCountryCode.CA);

/**
 * Returns country name when given a valid country code.
 */
export const getCountryNameFromCode = (countryCodeMap: CountryCodeMap, countryCode: CountryCode): CountryName =>
  countryCodeMap[countryCode]?.fullName;

/**
 * Given an array of country info objects it maps that array to a format
 * that can be used with select components (label/value pairs).
 * @param countryInfos - Array of country info objects
 */
export const mapCountryInfosToSelectOptions = (countryInfos: CountryInfo[] = []): { label: string; value: string }[] =>
  countryInfos.map(({ fullName, id }) => ({ label: fullName, value: id }));

export const isCountryCodeUsTerritoryOrCanada = (countryCode: CountryCode): boolean =>
  isCountryCodeCanada(countryCode) || isCountryCodeUSOrUSTerritory(countryCode);
