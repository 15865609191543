import classNames from 'classnames';
import React from 'react';

import { ContentBlockShadeProps } from './ContentBlockShade.types';
import { noPropagationMouseEvent } from './helper';

/**
 * Shade layout component for the ContentBlock.
 * Adds an opacity and click-blocking overlay to contents.
 */
const ContentBlockShade = ({ children, className, isShady, ...rest }: ContentBlockShadeProps): JSX.Element => (
  <div
    className={classNames({
      'content-block--shade': true,
      shady: isShady,
      [className]: !!className,
    })}
    onClickCapture={(evt) => (isShady ? noPropagationMouseEvent(evt) : undefined)}
    {...rest}
  >
    {children}

    <div className="content-block--shade--overlay" />
  </div>
);

export default ContentBlockShade;
