import { InformationFilled } from '@carbon/icons-react';
import { internationalBeneficiaryFieldName } from '@routable/fund-routes';
import { requiredValidator } from '@routable/shared';
import clsx from 'clsx';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Field, change } from 'redux-form';

import { Checkbox, ReduxFormFieldRenderInput, Text } from 'components';

import { TextColor } from 'constants/styles/typography';

import type { NonGovermentOrganizationFieldsProps } from './NonGovermentOrganizationFields.types';

export const NonGovermentOrganizationFields = ({
  className,
  form,
  label = 'This vendor is an NGO (Non-Government Organization)',
}: NonGovermentOrganizationFieldsProps) => {
  const [isVendorNgo, setIsVendorNgo] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    // Reset fcra field
    if (!isVendorNgo) {
      dispatch(change(form, internationalBeneficiaryFieldName.Enum.in_fcra, null));
    }
  }, [dispatch, form, isVendorNgo]);

  return (
    <div className={clsx('flex flex-col gap-[14px]', className)}>
      <Checkbox
        content={label}
        isChecked={isVendorNgo}
        onChange={() => setIsVendorNgo(!isVendorNgo)}
        tooltip="A non-profit organization that operates independently of any government."
      />
      {isVendorNgo && (
        <>
          <Field
            component={ReduxFormFieldRenderInput}
            isRequired
            name={internationalBeneficiaryFieldName.Enum.in_fcra}
            placeholder="FCRA Registration Number"
            validate={[requiredValidator]}
          />
          <div className="flex gap-3 items-start">
            <InformationFilled className="fill-blue-40 shrink-0 mt-1" size={16} />
            <Text.Regular color={TextColor.GREY_XX_DARK}>
              Indian nonprofits must have a valid FCRA Registration to be able to legally receive charitable funds from
              donors outside of India.
            </Text.Regular>
          </div>
        </>
      )}
    </div>
  );
};
