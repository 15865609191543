import { createSelector } from 'reselect';

import { AttachmentState } from 'interfaces/attachment';
import { ReduxState } from 'interfaces/redux';

const getState = (state: ReduxState): AttachmentState => state.attachments;

/**
 * Selects the attachments by id from the state
 */
export const attachmentsSelector = createSelector([getState], (attachments) => attachments.byId);

/**
 * Selects attachments and returns an array
 */
export const allAttachmentsSelector = createSelector([attachmentsSelector], (attachments) =>
  Object.values(attachments),
);

/**
 * Selects the attachments.isDeleting from the state
 */
export const attachmentsIsDeletingSelector = createSelector([getState], (attachments) => attachments.isDeleting);
