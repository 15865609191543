import React from 'react';

import { DropdownMenuButton } from 'complexComponents';

import { AddPaymentMethodDropdownButtonProps } from './AddPaymentMethodDropdownButton.types';
import { AddPaymentMethodDropdownOption } from './components';
import { useGetAddPaymentMethodDropdownOptions } from './hooks';

import './AddPaymentMethodDropdownButton.scss';

/**
 * Component rendering AddPaymentMethod dropdown
 * @param {AddPaymentMethodDropdownButtonProps} props
 * @returns {StatelessComponent}
 */
const AddPaymentMethodDropdownButton: React.FC<AddPaymentMethodDropdownButtonProps> = ({
  isDisabled,
  onAddCheckAccount,
  onAddBankAccount,
  visible = true,
}) => {
  const options = useGetAddPaymentMethodDropdownOptions({
    onAddBankAccount,
    onAddCheckAccount,
  });

  if (!visible) {
    return null;
  }

  return (
    <DropdownMenuButton
      containerClassName="add-payment-method-dropdown-button"
      isDisabled={isDisabled}
      menuAlign="right"
      OptionComponent={AddPaymentMethodDropdownOption}
      options={options}
    >
      Add a payment method
    </DropdownMenuButton>
  );
};

export default AddPaymentMethodDropdownButton;
