import { isArray, hasLength } from 'helpers/utility';

import { FilterOperator, UIItemFilter } from 'interfaces/itemFilters';

export const getFilterParamsForBetween = (filter: UIItemFilter): Record<string, unknown> => {
  const params = {};
  if (isArray(filter.value) && hasLength(filter.value)) {
    const [lowerVal, upperVal] = filter.value as Array<string | number>;
    return {
      [`${filter.urlParam}__gte`]: lowerVal,
      [`${filter.urlParam}__lte`]: upperVal,
    };
  }

  return params;
};

const commaSeparatedListCleanup = (value: string) =>
  value
    .split(',')
    // trim leading and trailing whitespace
    .map((str) => str.trim())
    // remove any empty strings
    .filter(Boolean)
    .join(',');

export const filterToUrlParams = (filter: UIItemFilter): Record<string, unknown> => {
  switch (filter.selectedOperator) {
    case FilterOperator.GREATER_THAN_OR_EQUAL:
      return { [`${filter.urlParam}__gte`]: filter.value };
    case FilterOperator.LESS_THAN_OR_EQUAL:
      return { [`${filter.urlParam}__lte`]: filter.value };
    case FilterOperator.BETWEEN:
      return getFilterParamsForBetween(filter);
    case FilterOperator.EXACT_MATCH_IN_COMMA_SEPARATED_LIST:
      return {
        [`${filter.urlParam}__iexact`]: commaSeparatedListCleanup(filter.value),
      };
    case FilterOperator.CONTAIN_MATCH_IN_COMMA_SEPARATED_LIST:
      return {
        [`${filter.urlParam}__icontains`]: commaSeparatedListCleanup(filter.value),
      };
    case FilterOperator.STARTS_WITH_MATCH_IN_COMMA_SEPARATED_LIST:
      return {
        [`${filter.urlParam}__istartswith`]: commaSeparatedListCleanup(filter.value),
      };
    case FilterOperator.ENDS_WITH_MATCH_IN_COMMA_SEPARATED_LIST:
      return {
        [`${filter.urlParam}__iendswith`]: commaSeparatedListCleanup(filter.value),
      };
    default:
      return { [filter.urlParam]: filter.value };
  }
};
