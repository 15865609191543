import { useSelector } from 'react-redux';

import { usePartnershipPaymentOptions } from 'hooks';

import type { CurrencyCode } from 'interfaces/currency';
import type { Partnership } from 'interfaces/partnerships';

import { getBillCurrencyTooltipText } from './helpers';
import type { UseVendorRecevingCurrency } from './useVendorReceivingCurrency.types';

/**
 * Given a partnershipSelector and a billCurrencyCodeSelector, returns:
 *  billCurrencyCode: selected bill currency
 *  isLocked: when options has length === 1
 *  options: currencyCodeReceiver options based on the selected billCurrency
 * @param partnershipSelector
 * @param billCurrencyCodeSelector
 * @returns {boolean}
 */
const useVendorReceivingCurrency = (
  partnershipSelector: StandardSelector,
  billCurrencyCodeSelector: StandardSelector,
): UseVendorRecevingCurrency => {
  const partnership = useSelector<ReduxState, Partnership>(partnershipSelector);
  const billCurrencyCode = useSelector<ReduxState, CurrencyCode>(billCurrencyCodeSelector);

  const tooltipText = getBillCurrencyTooltipText(billCurrencyCode);

  const { vendorCurrencyCodeReceiverOptionsByBillCurrency } = usePartnershipPaymentOptions({
    partnershipId: partnership.id,
  });

  const options = vendorCurrencyCodeReceiverOptionsByBillCurrency?.[billCurrencyCode] || [];

  // RCTM should be able to select the vendor currency only when there are more than one available option
  const isLocked = options?.length <= 1;

  return { billCurrencyCode, isLocked, options, tooltipText };
};

export default useVendorReceivingCurrency;
