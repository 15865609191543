import { Address } from '../types/addressTypes.types';

/**
 * Checks whether address has more than 1 property that is filled with truthy values
 * To prevent attempt of submission with just 1 pre-filled field (namely country)
 * @param {Object} address
 */
export const getHasAddress = (address: Address): boolean => {
  const hasValidLength = Object.keys(address).length > 1;
  const hasFilledValues = Object.values(address).filter((value) => Boolean(value)).length > 1;

  return hasValidLength && hasFilledValues;
};
