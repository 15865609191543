import { getItemMemberMenuText, getPastItemMemberMenuText } from 'helpers/itemMember';
import { isContactsInteractionListViewTypeThread } from 'helpers/ui';

import { Item, ItemMember } from 'interfaces/item';

/**
 * Get text for the MenuPopoverItems in ContactListItemMenuPopover.
 * @function
 * @param {ContactsInteractionListViewType} view
 * @param {ItemMember|PartnershipMember} [contact]
 * @param {Item} [item]
 */
export const getContactListMenuItemsText = (
  view: string,
  contact: ItemMember,
  item: Item,
): {
  editText: string;
  removeText: string;
} => {
  if (isContactsInteractionListViewTypeThread(view)) {
    return {
      ...getItemMemberMenuText(contact, item),
      ...getPastItemMemberMenuText(contact, item),
    };
  }

  return {
    editText: 'Edit contact',
    removeText: 'Remove contact',
  };
};
