import type { GetTooltipTextType } from './text.types';

/**
 * Return tooltip text for CurrencyCodeBlockField
 */
export const getTooltipText: GetTooltipTextType = ({
  companyIntegrationSettings,
  currencyCode,
  countryName,
  ledger,
}) => {
  if (companyIntegrationSettings.enableBaseCurrencyMode && currencyCode !== ledger.baseCurrencyCode) {
    return `As per your settings, this bill will be coded in ${currencyCode}, but it will sync to ${ledger.name} in ${ledger.baseCurrencyCode}.`;
  }

  const vendorCountry = countryName ? `Vendors in ${countryName}` : 'Vendor';

  return `${vendorCountry} can only be paid in ${currencyCode}.`;
};
