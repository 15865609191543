import { Action } from 'redux';

import * as types from 'types/passwordInput';

const isFetchingPasswordStrengthReducer = (state = false, action: Action): boolean => {
  if (action.type === types.FETCH_PASSWORD_STRENGTH_REQUEST) {
    return true;
  }

  if (action.type === types.FETCH_PASSWORD_STRENGTH_COMPLETED) {
    return false;
  }

  return state;
};

export default isFetchingPasswordStrengthReducer;
