import classNames from 'classnames';
import React from 'react';

import useEscapeKeyPressed from 'hooks/useEscapeKeyPressed';

import { SidePanelNav } from './components';
import type { SidePanelProps } from './SidePanel.types';

import './SidePanel.scss';

const SidePanel = ({ className, children, headerText, isOpen, onClose }: SidePanelProps): JSX.Element => {
  useEscapeKeyPressed(onClose);

  return (
    <div aria-hidden={!isOpen} className={classNames('slide-main', { [className]: !!className })}>
      <SidePanelNav headerText={headerText} onClose={onClose} />

      <div className="slide-body">{children}</div>
    </div>
  );
};

export default SidePanel;
