import type { Action } from 'interfaces/actions';

import { getModalReducerOpenAction } from 'store/redux';

import { MODAL_ADD_INTERNATIONAL_PAYMENT_CLOSE, MODAL_ADD_INTERNATIONAL_PAYMENT_OPEN } from 'types/modals';

import type { AddInternationalPaymentMethodModalReducerProps } from './modalsReducer.types';

export const initialState: AddInternationalPaymentMethodModalReducerProps = {
  open: false,
  partnershipId: null,
  formContext: null,
};

/**
 * Modal reducer for the add international payment method modal.
 */
const addInternationalPaymentMethodModalReducer = (
  state = initialState,
  action: Action,
): AddInternationalPaymentMethodModalReducerProps => {
  switch (action.type) {
    case MODAL_ADD_INTERNATIONAL_PAYMENT_CLOSE:
      return initialState;

    case MODAL_ADD_INTERNATIONAL_PAYMENT_OPEN:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default addInternationalPaymentMethodModalReducer;
