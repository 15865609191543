import classNames from 'classnames';
import React from 'react';

import { Text } from 'components';

import { paginationDataTestId } from 'constants/dataTestId';
import { typography } from 'constants/styles';

import { getPaginationBlockResultText } from 'modules/dashboard/paginationBlock/helpers/component';

import { PageNav, PageQuickJumper, PageSizeSelection } from './components';
import { TablePaginationProps } from './TablePagination.types';

import './TablePagination.scss';

/**
 * TablePagination
 */
export const TablePagination: React.FC<TablePaginationProps> = ({
  className,
  isFetching,
  onGoToPage,
  onPageSizeChange,
  page,
  pages,
  pageSize,
  count,
  ...rest
}) => {
  const resultText = getPaginationBlockResultText({
    currentPage: page,
    isFetching,
    pageSize,
    totalResults: count,
  });

  return (
    <div
      className={classNames('table-pagination', {
        [className]: !!className,
      })}
    >
      <div className="tlp--control-right">
        <PageQuickJumper onGoToPage={onGoToPage} pageCount={pages} pageIndex={page} />
        <PageNav
          {...rest}
          canNextPage={page !== pages}
          canPreviousPage={page !== 1}
          onGoToPage={onGoToPage}
          pageCount={pages}
          pageIndex={page}
        />
      </div>
      <hr />
      <div className="tlp--control-left">
        <PageSizeSelection isLoading={isFetching} onPageSizeChange={onPageSizeChange} pageSize={pageSize} />
        <Text.Regular
          color={typography.TextColor.GREY_X_DARK}
          data-testid={paginationDataTestId.paginationComponent.FOOTER_INDEX}
          size={typography.TextSize.LEVEL_100}
        >
          {resultText}
        </Text.Regular>
      </div>
    </div>
  );
};
