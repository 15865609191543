import { RoutableApiResponse, LedgerObjectSearchResponseData } from 'interfaces/routableApiResponse';

import { routableApi, RoutableTags } from './routableApi';

const ledgerObjectKeysApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    lookupLedgerObjectKeys: builder.query<RoutableApiResponse, { ledgerId: string }>({
      query: ({ ledgerId }) => ({
        url: `/integrations/configs/${ledgerId}/ledger-object-keys/`,
      }),
      providesTags: [RoutableTags.LedgerObjects],
    }),
    ledgerObjectSearch: builder.query<
      LedgerObjectSearchResponseData,
      {
        ledgerId: string;
        objectKey: string;
        searchString: string;
        pageNumber: number;
      }
    >({
      query: ({ ledgerId, objectKey, searchString, pageNumber }) => ({
        url: `/integrations/configs/${ledgerId}/ledger-object-search/${objectKey}/`,
        params: {
          search: searchString,
          page: {
            number: pageNumber,
          },
        },
      }),
      providesTags: [RoutableTags.LedgerObjects],
    }),
  }),
});

export const {
  useLookupLedgerObjectKeysQuery,
  useLazyLookupLedgerObjectKeysQuery,
  useLedgerObjectSearchQuery,
  useLazyLedgerObjectSearchQuery,
} = ledgerObjectKeysApi;
