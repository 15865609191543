import React from 'react';

import { Col } from 'components/layout';

import { ContentBlockBodyProps } from './ContentBlockBody.types';

/**
 * Body layout component for the ContentBlock.
 */
const ContentBlockBody = ({ children, colProps }: ContentBlockBodyProps): JSX.Element => (
  <Col className="content-block--body" {...colProps}>
    {children}
  </Col>
);

export default ContentBlockBody;
