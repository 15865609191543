import classNames from 'classnames';
import React from 'react';

import type { TableToolbarContentProps } from './TableToolbarContent.types';

/**
 * A content section within a TableToolbar.
 * @type {React.FC<TableToolbarContentProps>}
 */
const TableToolbarContent: React.FC<TableToolbarContentProps> = ({ children, className }) => (
  <div className={classNames('table-toolbar--content', className)}>{children}</div>
);

TableToolbarContent.defaultProps = {
  children: undefined,
  className: undefined,
};

export default TableToolbarContent;
