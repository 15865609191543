/* eslint-disable no-restricted-syntax */
import throttle from 'lodash/throttle';

export type EventNamespace = string;

export interface EventInfo {
  namespace: string;
  eventName: string;
  timestamp: number;
  elapsedClockTime: number;
  elapsedActiveTime: number;
}

export interface EventLog {
  namespace: string;
  eventName: string;
  timestamp: number;
}

export interface InactiveTimeRange {
  start: number;
  end: number;
  delta: number;
}

const INPUT_IDLE_TIMEOUT_MS = 30e3;
const THROTTLE_MS = 50;

export class UserActivityTracker {
  private INPUT_ACTIVITY_EVENTS = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];

  private events: Record<EventNamespace, EventLog[]> = {};

  private onIdleChange: (isIdle: boolean) => void;

  private onInternalIdleChange: (isIdle: boolean) => void;

  private inactiveTimeRanges: Array<InactiveTimeRange> = [];

  private isIdle = false;

  private setTimeoutHandle: ReturnType<typeof setTimeout>;

  private globalScope: Window;

  constructor({
    globalScope,
    onIdleChange,
  }: {
    globalScope?: Window;
    onIdleChange?: (isIdle: boolean) => void;
  } = {}) {
    this.globalScope = globalScope;
    this.onIdleChange = onIdleChange;
    this.onInternalIdleChange = throttle((isIdle: boolean) => {
      if (isIdle) {
        this.inactiveTimeRanges = [...this.inactiveTimeRanges, { start: performance.now(), end: null, delta: null }];
      } else {
        const lastUpdatedRange = this.inactiveTimeRanges.slice(-1)?.[0];
        const now = performance.now();

        if (!lastUpdatedRange) {
          return;
        }

        this.inactiveTimeRanges = [
          ...this.inactiveTimeRanges.slice(0, -1),
          {
            start: lastUpdatedRange.start,
            end: now,
            delta: Math.abs(now - lastUpdatedRange.start),
          },
        ];
      }

      if (typeof this.onIdleChange === 'function') {
        this.onIdleChange(isIdle);
      }
    }, THROTTLE_MS);

    const globalScopeDocument = (globalScope as Window)?.document;
    if (typeof globalScopeDocument !== 'undefined') {
      globalScopeDocument.addEventListener('visibilitychange', this.onDocumentVisibilityChange.bind(this), {
        capture: true,
        passive: true,
      });
      if (globalScopeDocument.hidden) {
        this.onDocumentVisibilityChange();
      }
    }
    if (typeof globalScope !== 'undefined') {
      for (const eventName of this.INPUT_ACTIVITY_EVENTS) {
        globalScope.addEventListener(eventName, this.onInputActivityEvent.bind(this), { capture: true, passive: true });
      }
      this.scheduleIdleCheck();
    }
  }

  private scheduleIdleCheck() {
    this.setTimeoutHandle = setTimeout(() => {
      if (!this.isIdle) {
        this.isIdle = true;
        this.onInternalIdleChange(this.isIdle);
      }
    }, INPUT_IDLE_TIMEOUT_MS);
  }

  private onInputActivityEvent() {
    if (this.isIdle) {
      this.isIdle = false;
      this.onInternalIdleChange(this.isIdle);
    }

    clearTimeout(this.setTimeoutHandle);
    this.scheduleIdleCheck();
  }

  private onDocumentVisibilityChange() {
    if (!this.globalScope?.document?.hidden) {
      this.onInputActivityEvent();
    } else if (!this.isIdle) {
      this.isIdle = true;
      this.onInternalIdleChange(this.isIdle);
    }
  }

  private getInactiveTimeMsBetweenTimestamps(timestampA: number, timestampB: number) {
    let inactiveTimeDelta = 0;

    for (const range of this.inactiveTimeRanges) {
      // Each inactive time range is a starting and ending timestamp of when the user was not interacting with the page
      // All the inactive periods are stored in inactiveTimeRanges
      // For a given timestampA and timestampB, we calculate how much between timestampA and timestampB was inactive
      if (range.start >= timestampA && range.end <= timestampB) {
        inactiveTimeDelta += range.end - range.start;
      } else if (range.end > timestampA && range.start <= timestampA) {
        inactiveTimeDelta += range.end - timestampA;
      } else if (range.start <= timestampB && range.end > timestampB) {
        inactiveTimeDelta += timestampB - range.start;
      }
    }

    return inactiveTimeDelta;
  }

  private getElapsedTimeInfo(timestampA: number, timestampB: number) {
    const elapsedClockTime = Math.abs(timestampA - timestampB);
    const elapsedInactiveTime = this.getInactiveTimeMsBetweenTimestamps(timestampA, timestampB);
    return {
      timestampA,
      timestampB,
      elapsedClockTime,
      elapsedActiveTime: elapsedClockTime - elapsedInactiveTime,
    };
  }

  trackEvent({ namespace, eventName }: { namespace: string; eventName: string }) {
    this.events = {
      ...this.events,
      [namespace]: [
        ...(this.events[namespace] || []),
        {
          namespace,
          eventName,
          timestamp: performance.now(),
        },
      ],
    };
  }

  deleteEvents(namespace?: string, eventName?: string) {
    if (!namespace) {
      this.events = {};
    }

    if (namespace && !eventName) {
      delete this.events[namespace];
    }

    if (namespace && eventName) {
      const events = this.events[namespace];
      if (!events) {
        return;
      }

      this.events[namespace] = events.filter((x) => x.eventName !== eventName);
    }
  }

  getMostRecentEventByName(namespace: string, eventName: string) {
    const matchingEvents = this.events[namespace]?.filter((eventsLog) => eventsLog.eventName === eventName) || [];
    return matchingEvents.slice(-1)[0];
  }

  getEventInfo(
    eventPredicateFn: ({
      events,
      getMostRecentEventByName,
    }: {
      events: Record<EventNamespace, EventLog[]>;
      getMostRecentEventByName: (namespace: string, eventName: string) => EventLog | null;
    }) => EventLog | null,
  ): EventInfo {
    if (!this.events || typeof eventPredicateFn !== 'function') {
      return null;
    }

    const event = eventPredicateFn({
      events: this.events,
      getMostRecentEventByName: this.getMostRecentEventByName.bind(this),
    });

    if (!event) {
      return null;
    }

    const elapsedTimeInfo = this.getElapsedTimeInfo(event.timestamp, performance.now());
    return {
      namespace: event.namespace,
      eventName: event.eventName,
      timestamp: event.timestamp,
      elapsedClockTime: elapsedTimeInfo.elapsedClockTime,
      elapsedActiveTime: elapsedTimeInfo.elapsedActiveTime,
    };
  }
}

let userActivityTracker;

export const withUserActivityTracker = (globalScope: Window = window, onIdleChange?: (isIdle: boolean) => void) => {
  if (typeof userActivityTracker === 'undefined') {
    userActivityTracker = new UserActivityTracker({
      globalScope,
      onIdleChange,
    });
  }
  return userActivityTracker;
};
