import { Action } from 'interfaces/actions';

import * as types from 'types/modals';

import type { AddInternationalPaymentActionProps } from './modals.types';

/**
 * Action for closing the match platform partnership modal
 */
export const closeMatchPlatformPartnershipModal = (): Action => ({
  type: types.MODAL_MATCH_PLATFORM_PARTNERSHIP_CLOSE,
});

/**
 * Action for closing the merge ledger partnership modal
 */
export const closeMergeLedgerPartnershipModal = (): Action => ({
  type: types.MODAL_MERGE_LEDGER_PARTNERSHIP_CLOSE,
});

/**
 * Action for opening the add international payment modal
 */
export const openInternationalPaymentModal = ({
  partnershipId,
  formContext,
}: AddInternationalPaymentActionProps): Action => ({
  payload: { partnershipId, formContext },
  type: types.MODAL_ADD_INTERNATIONAL_PAYMENT_OPEN,
});

/**
 * Action for closing the add international payment modal
 */
export const closeInternationalPaymentModal = (): Action => ({
  type: types.MODAL_ADD_INTERNATIONAL_PAYMENT_CLOSE,
});

/**
 * Opens IntegrationSyncOptionsModal
 */
export const openIntegrationSyncOptionsModal = (): Action => ({
  type: types.MODAL_INTEGRATION_SYNC_OPTIONS_OPEN,
});
