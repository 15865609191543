import * as routines from 'actions/routines/purposeCode';

import { createByIdReducer } from 'store/redux';

const byIdReducer = createByIdReducer({
  key: 'purposeCode',
  initialState: {},
  types: [routines.fetchPurposeCodeRoutine.SUCCESS],
  // add any relationships here
});

export default byIdReducer;
