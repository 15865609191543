import classNames from 'classnames';
import React from 'react';

import { ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { googleLetterLogoMap } from 'constants/images';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import type { LoginWithGoogleButtonProps } from './LoginWithGoogleButton.types';

import './LoginWithGoogleButton.scss';

/**
 * LoginWithGoogleButton
 * Supports dark and light theme.
 */
const LoginWithGoogleButton: React.FC<LoginWithGoogleButtonProps> = ({ mode, className, ...btnProps }) => (
  <ButtonV2
    className={classNames({
      'login-with-google-btn': true,
      [className]: Boolean(className),
    })}
    intent={mode === 'light' ? Intent.NEUTRAL : Intent.PRIMARY}
    rightIconClassName="google-btn--right-icon"
    rightIconName={IconNames.ARROW_RIGHT}
    rightIconSize={sizes.iconSizes.LARGE}
    size={ButtonSize.LARGE}
    {...btnProps}
  >
    <img alt="Google logo" className="google-btn--logo" src={googleLetterLogoMap[mode]} />
    Login with Google
  </ButtonV2>
);

LoginWithGoogleButton.defaultProps = {
  mode: 'light',
};

export default LoginWithGoogleButton;
