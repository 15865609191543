import { CurrencyCodeUsd } from 'constants/currency';

import { mapCurrencyCodeToSelectOption } from 'helpers/currency';
import { reduceKeys } from 'helpers/utility';

import { usePartnershipPaymentCurrencies } from 'hooks';

import { UsePartnershipPaymentOptionsResult } from './usePartnershipPaymentOptions.types';

/**
 * Extracts bill currency cod and vendor currency code receiver options supported for a given partnership.
 * If for any reasons the resulting array of currencies is empty, it returns:
 * - bill currency options: a single value array containing 'USD';
 * - currency code receiver options: empty object;
 */
export const usePartnershipPaymentOptions = ({
  partnershipId,
}: {
  partnershipId: string;
}): UsePartnershipPaymentOptionsResult => {
  const { data, isFetching } = usePartnershipPaymentCurrencies(partnershipId);

  const currencyCodesBillSelectable = data?.currencyCodesBillSelectable;

  // Default to ['USD']
  const currencyCodesBill = currencyCodesBillSelectable?.length ? currencyCodesBillSelectable : [CurrencyCodeUsd];

  // Format bill currency data to selectable options used with a select field
  const currencyCodesBillOptions = currencyCodesBill.map(mapCurrencyCodeToSelectOption);

  // Format currency receiver data to selectable options used with a select field
  const mapCurrencyReceiverToOptions = (routePayable) =>
    mapCurrencyCodeToSelectOption(routePayable.currencyCodeReceiver);
  const receiverOptions = reduceKeys(
    data?.routesPayable,
    (accumulator, key) => ({
      ...accumulator,
      [key.toUpperCase()]: data.routesPayable[key].map(mapCurrencyReceiverToOptions),
    }),
    {},
  );

  return {
    currencyCodesBillOptions,
    isFetching,
    vendorCurrencyCodeReceiverOptionsByBillCurrency: receiverOptions,
  };
};
