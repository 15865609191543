import { Tooltip } from '@routable/gross-ds';
import classNames from 'classnames';
import React from 'react';
import DropdownMenu from 'react-dd-menu';

import { ButtonV2, IconNames } from 'components';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';
import { TooltipPlacement } from 'constants/tooltip';

import { noDefaultEventWithCallback } from 'helpers/events';
import { or } from 'helpers/utility';

import { useControlledOrUncontrolled } from 'hooks';

import RestrictedPermissionTooltip from 'modules/auth/RestrictedPermissionTooltip';

import { DropdownMenuOption } from './components';
import type { DropdownMenuButtonProps } from './DropdownMenuButton.types';

import './DropdownMenuButton.scss';

/**
 * DropdownMenuButton
 * A button with an attached dropdown menu which opens on click
 *
 * @param {DropdownMenuButtonProps} props
 * @param {RestOfProps} rest
 * @return {FunctionComponent}
 */
const DropdownMenuButton: React.FC<DropdownMenuButtonProps> = ({
  align,
  children,
  containerClassName,
  isOpen,
  menuAlign,
  onClose,
  OptionComponent,
  options,
  textAlign,
  upwards,
  ...rest
}) => {
  const [isMenuOpen, setIsMenuOpen] = useControlledOrUncontrolled({
    defaultValue: false,
    value: isOpen,
  });

  const onOpenMenu = (event) => noDefaultEventWithCallback(event, () => setIsMenuOpen(true));

  const onCloseMenu = or(onClose, () => setIsMenuOpen(false));

  return (
    <DropdownMenu
      align={align}
      className={classNames('dropdown-menu-btn', {
        [containerClassName]: !!containerClassName,
      })}
      close={onCloseMenu}
      closeOnInsideClick={false}
      isOpen={isMenuOpen}
      menuAlign={menuAlign}
      textAlign={textAlign}
      toggle={
        <ButtonV2
          onClick={onOpenMenu}
          rightIconClassName="margin-left--xm"
          rightIconName={IconNames.CHEVRON_DOWN}
          rightIconSize={sizes.iconSizes.LARGE}
          size={ButtonSize.SMALL}
          {...rest}
        >
          {children}
        </ButtonV2>
      }
      upwards={upwards}
    >
      {options.map(({ onCloseMenu: optionOnCloseMenu, ...option }, idx) => {
        if (option?.isDisabledDueToPermissions) {
          return (
            <RestrictedPermissionTooltip
              customization={{ placement: TooltipPlacement.LEFT }}
              // TODO: using idx as part of the key here since options are dynamic (Not necessarily have id)
              // eslint-disable-next-line react/no-array-index-key
              key={`option-${idx}`}
            >
              <OptionComponent
                isDisabled={option.isDisabledDueToPermissions}
                key={option.title}
                onCloseMenu={optionOnCloseMenu || onCloseMenu}
                {...option}
              />
            </RestrictedPermissionTooltip>
          );
        }

        if (option.isDisabled && option.tooltipTitle) {
          return (
            <Tooltip
              data-testid={`${option.title}-tooltip`}
              // TODO: using idx as part of the key here since options are dynamic (Not necessarily have id)
              // eslint-disable-next-line react/no-array-index-key
              key={`tooltip-${idx}`}
              position="left"
              tooltip={option.tooltipTitle}
              variant="light"
            >
              <OptionComponent key={option.title} onCloseMenu={optionOnCloseMenu || onCloseMenu} {...option} />
            </Tooltip>
          );
        }

        return <OptionComponent key={option.title} onCloseMenu={optionOnCloseMenu || onCloseMenu} {...option} />;
      })}
    </DropdownMenu>
  );
};

DropdownMenuButton.defaultProps = {
  align: 'left',
  containerClassName: undefined,
  isOpen: undefined,
  menuAlign: 'left',
  onClose: undefined,
  OptionComponent: DropdownMenuOption,
  options: [],
  textAlign: 'left',
  upwards: undefined,
};

export default DropdownMenuButton;
