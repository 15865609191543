import { createSelector } from 'reselect';

import { ReduxState } from 'interfaces/redux';
import { PartnershipPayment, PartnershipPaymentResponse } from 'interfaces/redux/partnershipPaymentReducer.types';

import { idSelector } from 'selectors/globalSelectors';

const getState = (state: ReduxState) => state.partnershipPayment;

export const partnershipPaymentByIdSelector = createSelector([getState], ({ byId }) => byId);

const mapToPartnershipPayment = (
  partnershipPaymentById: Record<string, PartnershipPaymentResponse>,
  id: string,
): PartnershipPayment => ({
  id,
  type: 'PartnershipPayment',
  ...partnershipPaymentById[id].attributes,
});

export const partnershipPaymentCurrentPartnershipSelector = createSelector(
  [partnershipPaymentByIdSelector, idSelector],
  (partnershipPaymentById, id): PartnershipPayment =>
    partnershipPaymentById[id] ? mapToPartnershipPayment(partnershipPaymentById, id) : null,
);
