import type { RoutableApiResponse } from 'interfaces/routableApiResponse';

import { payloadToJSONAPI } from 'services/api/formatHelpers';

import { routableApi } from '../routableApi';

import type {
  FundingAccountsRequestEstimatedArrivalDatePayload,
  FundingAccountsRequestEstimatedArrivalDateResult,
} from './fundingAccounts.types';

export const transformResponse = (response: RoutableApiResponse): FundingAccountsRequestEstimatedArrivalDateResult => {
  /**
   * Access the first value under arrival estimate response.
   * This response has an id as a key, and then an object with attributes
   * as value. These attributes is what our consumers need.
   */
  const data = Object.values(response?.arrivalEstimate).find(Boolean);

  return data?.attributes;
};

const extendedFundingAccountsApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    fundingAccountsEstimatedArrivalDate: builder.query<
      FundingAccountsRequestEstimatedArrivalDateResult,
      FundingAccountsRequestEstimatedArrivalDatePayload
    >({
      query: ({
        initiationDate,
        currencyCodeReceiver,
        paymentDeliveryMethod,
        paymentDeliveryOption,
        paymentSource,
      }) => ({
        url: '/funding_accounts/estimated_arrival/estimate/',
        method: 'POST',
        payload: payloadToJSONAPI(
          {
            initiationDate,
            currencyCodeReceiver,
            paymentDeliveryMethod,
            paymentDeliveryOption,
            paymentSource,
          },
          'ArrivalEstimate',
        ),
      }),
      transformResponse,
    }),
  }),
});

export const { useFundingAccountsEstimatedArrivalDateQuery } = extendedFundingAccountsApi;
