import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { SelectFieldV2 } from 'components';

import { getErrorsFromFieldState } from '../helpers';

import type { RenderSelectProps } from './RenderSelect.types';

const RenderSelect = ({
  isClearable,
  isRequired,
  label,
  name,
  options,
  placeholder,
  shouldUnregister,
}: RenderSelectProps): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <SelectFieldV2
          errors={getErrorsFromFieldState(fieldState, field.name)}
          input={field}
          isClearable={isClearable}
          isRequired={isRequired}
          label={label}
          options={options}
          placeholder={placeholder}
          setRef={field.ref}
        />
      )}
      rules={{ required: isRequired }}
      shouldUnregister={shouldUnregister}
    />
  );
};

export default RenderSelect;
