import React from 'react';

import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';

import { typography } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';

import type { MergeOnlyDomesticPartnershipsTooltipProps } from './MergeOnlyDomesticPartnershipsTooltip.types';

/**
 * Tooltip notifying the user that merging international partnerships may cause inconsistencies,
 * and is therefore disallowed
 */
const MergeOnlyDomesticPartnershipsTooltip = ({
  children,
  isShown,
  ledgerName,
}: MergeOnlyDomesticPartnershipsTooltipProps): React.ReactElement => (
  <TooltipMUIConditionalWrapper
    tooltipProps={
      isShown
        ? {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            title: (
              <Text.Regular
                color={typography.TextColor.GREY_X_DARK}
                lineHeight={typography.TextLineHeight.MEDIUM}
                size={typography.TextSize.LEVEL_100}
              >
                Merging international vendors or customers can cause inconsistencies with your
                {` ${ledgerName} `}
                data, so we have limited this action to domestic vendors or customers.
              </Text.Regular>
            ),
          }
        : {}
    }
  >
    {children}
  </TooltipMUIConditionalWrapper>
);

export default MergeOnlyDomesticPartnershipsTooltip;
