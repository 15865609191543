import { taxTools } from '@routable/taxes';
import { vendorRiskCheckReport } from '@routable/vendor-risk';
import { queryClient } from 'milton/components';
import { SubmissionError } from 'redux-form';
import { all, call, put, select } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import { fetchSinglePartnershipRequest } from 'actions/partnership';
import { createAddressRoutine, updateAddressRoutine } from 'actions/routines/addresses';
import { closeSidePanel } from 'actions/sidePanels';
import { showSuccessUi } from 'actions/ui';

import { sidePanelNameAddOrUpdateVendorRegisteredAddress } from 'constants/sidePanels';
import { SuccessIndicatorMessages } from 'constants/ui';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { sagaWatcher } from 'helpers/saga';

import type { Company } from 'interfaces/company';
import type { FetchServiceResponse } from 'interfaces/fetchService';
import type { UnknownObject } from 'interfaces/global';

import { partnerCompanyFromPartnershipPropSelector } from 'queries/companyCompoundSelectors';

import { createCompanyMailingAddress, updateCompanyMailingAddress } from './api';
import type { CreateOrUpdateAddressAction } from './sagas.types';

/**
 * Saga handling the create company address action
 * @param action - Action dispatched by the createAddressRoutine
 */
export function* createAddressSaga(action: CreateOrUpdateAddressAction): IterableIterator<unknown> {
  let errorData: UnknownObject = {};

  try {
    const { props, values } = Object(action.payload);

    const company: Company = yield select(partnerCompanyFromPartnershipPropSelector, props);
    const response: FetchServiceResponse = yield call(createCompanyMailingAddress, values, company.id);

    if (response.ok) {
      yield all([
        put(createAddressRoutine.success(response.data)),
        put(showSuccessUi(SuccessIndicatorMessages.VENDOR_ADDRESS_ADDED)),
        put(
          closeSidePanel({
            name: sidePanelNameAddOrUpdateVendorRegisteredAddress,
          }),
        ),
        put(fetchSinglePartnershipRequest(props.partnership.id)),
      ]);
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (err) {
    errorData = parseCaughtError(err);
  }

  // If there are field-level errors, we want to dispatch submission error
  // in order to populate the fields with the correct error state(s)
  if (errorData?.fields) {
    const errorPayload = new SubmissionError(errorData.fields);
    yield put(createAddressRoutine.failure(errorPayload));
    return;
  }

  yield put(handleRequestErrors(createAddressRoutine.failure, errorData));
}

/**
 * Saga handling the update company address action
 * @param action - Action dispatched by the updateAddressRoutine
 */
export function* updateAddressSaga(action: CreateOrUpdateAddressAction): IterableIterator<unknown> {
  let errorData: UnknownObject = {};

  yield put(updateAddressRoutine.request());

  try {
    const { props, values } = action.payload;

    const company: Company = yield select(partnerCompanyFromPartnershipPropSelector, props);
    const response: FetchServiceResponse = yield call(updateCompanyMailingAddress, company, values);

    if (response.ok) {
      yield all([
        put(updateAddressRoutine.success(response.data)),
        put(
          closeSidePanel({
            name: sidePanelNameAddOrUpdateVendorRegisteredAddress,
          }),
        ),
        put(showSuccessUi(SuccessIndicatorMessages.VENDOR_ADDRESS_UPDATED)),
        put(fetchSinglePartnershipRequest(props.partnership.id)),
      ]);
      // Invalidate TaxTools and RiskReport query cache as the tax document can get archived
      // on address change
      queryClient.invalidateQueries({ queryKey: [taxTools] });
      queryClient.invalidateQueries({ queryKey: [vendorRiskCheckReport] });
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (err) {
    errorData = parseCaughtError(err);
  }

  // If there are field-level errors, we want to dispatch submission error
  // in order to populate the fields with the correct error state(s)
  if (errorData?.fields) {
    const errorPayload = new SubmissionError(errorData.fields);
    yield put(updateAddressRoutine.failure(errorPayload));
    return;
  }

  yield put(handleRequestErrors(updateAddressRoutine.failure, errorData));
}

/**
 * Addresses root saga
 */
export default function* addresses(): IterableIterator<unknown> {
  yield sagaWatcher([
    {
      type: createAddressRoutine.TRIGGER,
      saga: createAddressSaga,
    },
    {
      type: updateAddressRoutine.TRIGGER,
      saga: updateAddressSaga,
    },
  ]);
}
