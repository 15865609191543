import React from 'react';

import LimitedMergingToBaseCurrencyTooltip from 'complexComponents/tooltipTypes/LimitedMergingToBaseCurrencyTooltip';
import MergeOnlyDomesticPartnershipsCrossBorderDisabledTooltip from 'complexComponents/tooltipTypes/MergeOnlyDomesticPartnershipsCrossBorderDisabledTooltip';
import MergeOnlyDomesticPartnershipsTooltip from 'complexComponents/tooltipTypes/MergeOnlyDomesticPartnershipsTooltip';

import { MultiLineOptionForSearchCompanies } from 'components/selectV2/components';

import { getCustomerOrVendorTextByPartnership } from 'helpers/partnerships';

import { checkPartnershipEligibilityForMergeCompanies } from '../../helpers/logic';
import { getDetailsTextForMergeCompanies } from '../../helpers/text';

import type { MultiLineOptionWithTooltipForMergeCompaniesProps } from './MultiLineOptionWithTooltipForMergeCompanies.types';

/**
 * Renders multi line option for merge companies select wrapped in a conditional tooltip
 */
const MultiLineOptionWithTooltipForMergeCompanies = ({
  companyIntegrationSettings,
  companySettings,
  data,
  ledger,
  supportedCurrencies,
  ...rest
}: MultiLineOptionWithTooltipForMergeCompaniesProps): React.ReactElement => {
  const {
    isLimitedMergingTooltipShown,
    isMergeDisabledWithXBFlagOffTooltipShown,
    isMergeDisabledWithXBFlagOnTooltipShown,
  } = checkPartnershipEligibilityForMergeCompanies({
    companyIntegrationSettings,
    companySettings,
    ledger,
    partnership: data,
    supportedCurrencies,
  });

  const partnershipType = getCustomerOrVendorTextByPartnership(data);
  const detailsText = getDetailsTextForMergeCompanies({
    isLimitedMergingTooltipShown,
    isMergeDisabledWithXBFlagOffTooltipShown,
    isMergeDisabledWithXBFlagOnTooltipShown,
    ledger,
    partnershipType,
  });

  return (
    <LimitedMergingToBaseCurrencyTooltip
      isShown={isLimitedMergingTooltipShown}
      ledger={ledger}
      partnershipType={partnershipType}
    >
      <MergeOnlyDomesticPartnershipsCrossBorderDisabledTooltip
        isShown={isMergeDisabledWithXBFlagOffTooltipShown}
        ledgerName={ledger.name}
      >
        <MergeOnlyDomesticPartnershipsTooltip
          isShown={isMergeDisabledWithXBFlagOnTooltipShown}
          ledgerName={ledger.name}
        >
          <MultiLineOptionForSearchCompanies
            {...rest}
            data={{
              ...data,
              details: data.isDisabled && detailsText,
            }}
          />
        </MergeOnlyDomesticPartnershipsTooltip>
      </MergeOnlyDomesticPartnershipsCrossBorderDisabledTooltip>
    </LimitedMergingToBaseCurrencyTooltip>
  );
};

export default MultiLineOptionWithTooltipForMergeCompanies;
