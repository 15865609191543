import { PaymentDeliveryMethodType } from '@routable/shared';
import React from 'react';

import { isCurrentCompanyAchCustomerApproved } from 'helpers/currentCompany';
import { isPaymentDeliveryMethodAvailable } from 'helpers/paymentMethods';

import type { Company } from 'interfaces/company';

type UseActivePaymentMethodsReturnType = (props: {
  currentCompany: Company;
  currentCompanyPayablePaymentMethods: PaymentDeliveryMethodType[];
}) => {
  [PaymentDeliveryMethodType.ACH]: boolean;
  [PaymentDeliveryMethodType.CHECK]: boolean;
  [PaymentDeliveryMethodType.INTERNATIONAL]: boolean;
};

/**
 * Hook to check if payment methods are active or not
 */
export const useActivePaymentMethods: UseActivePaymentMethodsReturnType = ({
  currentCompany,
  currentCompanyPayablePaymentMethods,
}: {
  currentCompany: Company;
  currentCompanyPayablePaymentMethods: PaymentDeliveryMethodType[];
}) => {
  const isAchApproved = isCurrentCompanyAchCustomerApproved(currentCompany);

  const achActive = React.useMemo(() => {
    const isAchAvailable = isPaymentDeliveryMethodAvailable(
      currentCompanyPayablePaymentMethods,
      PaymentDeliveryMethodType.ACH,
    );

    return Boolean(currentCompany && isAchApproved && isAchAvailable);
  }, [isAchApproved, currentCompany, currentCompanyPayablePaymentMethods]);

  const checkActive = React.useMemo(() => {
    const isCheckAvailable = isPaymentDeliveryMethodAvailable(
      currentCompanyPayablePaymentMethods,
      PaymentDeliveryMethodType.CHECK,
    );

    return Boolean(currentCompany && isAchApproved && isCheckAvailable);
  }, [isAchApproved, currentCompany, currentCompanyPayablePaymentMethods]);

  const checkInternational = React.useMemo(() => {
    const isInternationalAvailable = isPaymentDeliveryMethodAvailable(
      currentCompanyPayablePaymentMethods,
      PaymentDeliveryMethodType.INTERNATIONAL,
    );

    return Boolean(currentCompany && isAchApproved && isInternationalAvailable);
  }, [currentCompanyPayablePaymentMethods, currentCompany, isAchApproved]);

  return {
    [PaymentDeliveryMethodType.ACH]: achActive,
    [PaymentDeliveryMethodType.CHECK]: checkActive,
    [PaymentDeliveryMethodType.INTERNATIONAL]: checkInternational,
  };
};
