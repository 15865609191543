import { PaymentDeliveryMethodType } from '@routable/shared';
import { useSelector } from 'react-redux';

import { useActivePaymentMethods } from 'modules/dashboard/settings/account/paymentMethods/components/PaymentMethods/hooks';

import { currentCompanySelector, currentCompanySettingsSelector } from 'selectors/currentCompanySelectors';

import { IAddPaymentMethodDropdownOptionProps } from './components';

interface GetAddPaymentMethodDropdownOptionsParams {
  onAddBankAccount: () => void;
  onAddCheckAccount: () => void;
}

/**
 * Returns array of dropdown options for AddPaymentMEthodDropdown component
 * @param {GetAddPaymentMethodDropdownOptionsParams} options
 * @returns {Partial<IAddPaymentMethodDropdownOptionProps>[]} Array of dropdown options
 */
export const useGetAddPaymentMethodDropdownOptions = ({
  onAddBankAccount,
  onAddCheckAccount,
}: GetAddPaymentMethodDropdownOptionsParams): Omit<IAddPaymentMethodDropdownOptionProps, 'onCloseMenu'>[] => {
  const currentCompany = useSelector(currentCompanySelector);
  const currentCompanySettings = useSelector(currentCompanySettingsSelector);

  const activeMethods = useActivePaymentMethods({
    currentCompany,
    currentCompanyPayablePaymentMethods: currentCompanySettings.payablePaymentDeliveryMethods,
  });

  const checkOption = activeMethods[PaymentDeliveryMethodType.CHECK]
    ? [
        {
          onClick: onAddCheckAccount,
          title: 'Check delivery',
          description: 'Add a mailing address for check delivery',
        },
      ]
    : [];

  const achOption = activeMethods[PaymentDeliveryMethodType.ACH]
    ? [
        {
          onClick: onAddBankAccount,
          title: 'Bank account',
          description: 'Add a bank account with account and routing numbers',
        },
      ]
    : [];

  return [...checkOption, ...achOption];
};
