import React from 'react';

/**
 * Provides a small amount of bi-directional communication between table cell fields and the cells rendered
 * directly by react-table. Used to set the disabled class name on table cells.
 * @param {OptionsArg} options
 * @param {Object} options.formValues
 * @param {Function} options.getIsDisabled
 * @param {Function} options.setIsDisabled
 * @return {boolean}
 */
const useTableCellFieldIsDisabled = ({ formValues, getIsDisabled, setIsDisabled }) => {
  const isDisabled = React.useMemo(() => getIsDisabled(formValues), [formValues, getIsDisabled]);

  React.useEffect(() => {
    setIsDisabled(isDisabled);
  }, [isDisabled, setIsDisabled]);

  return isDisabled;
};

export default useTableCellFieldIsDisabled;
