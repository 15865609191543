import React from 'react';

import { LinkNewPage } from 'components';
import { Image } from 'components/media';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { LOGIN } from 'constants/routes';

import { LOGO } from 'global/images/platform';

import { Header, HeaderLogo, HeaderLogin } from './SignUpFormHeader.styles';
import { SignUpHeaderProps } from './types/SignUpFormHeader.types';

const SignUpFormHeader: React.VFC<SignUpHeaderProps> = ({ hasSignInOption }) => (
  <Header>
    <HeaderLogo hasSignInOption>
      <Image alt={PLATFORM_DISPLAY_SHORT_NAME} src={LOGO.NAVY} />
    </HeaderLogo>

    {hasSignInOption && (
      <HeaderLogin>
        <span className="has-account">Have an account? </span>
        <LinkNewPage href={`${window.location.origin}/${LOGIN}`}>Log in</LinkNewPage>
      </HeaderLogin>
    )}
  </Header>
);

export default SignUpFormHeader;
