/**
 * Map of partnership types
 * @enum {String}
 */
export enum PartnershipType {
  CUSTOMER = 'customer',
  VENDOR = 'vendor',
  COMPANY = 'company',
}

export enum PartnershipPartnerStatus {
  ACCEPTED = 'accepted',
  ADDED = 'added',
  INVITED = 'invited',
}

/**
 * @enum {string} Status of a partnership member's email address
 */
export enum PartnershipMemberEmailStatus {
  BOUNCED = 'bounced',
}

/**
 * @enum {string} Access values for a partnership member (e.g. member.defaultItem)
 */
export enum PartnershipMemberAccess {
  ACTIONABLE = 'actionable',
  NONE = 'none',
  SELF_MANAGED = 'self_managed',
  READ_ONLY = 'read_only',
  // These bottom values are not from the database, they are derived on the frontend by helpers which determine if the
  // PartnershipMember has defaultItem and/or defaultGeneral set.
  HAS_DEFAULTS = 'has_defaults',
  NO_DEFAULTS = 'no_defaults',
}

/**
 * Map of partnership country code keys. The keys are used to access country code properties on
 * the Partnership entity
 * @enum {String}
 */
export enum PartnershipCountryCodeKey {
  COMPANY = 'countryCodeCompany',
  PARTNER = 'countryCodePartner',
}

/**
 * Access scopes for a PartnershipMember. A PartnershipMember could have a "contact" access level on items, but "read-only"
 * access level for company management.
 * @enum {string}
 */
export enum PartnershipMemberAccessScope {
  COMPANY_MANAGEMENT = 'company_management',
  ITEMS = 'items',
}

/**
 * Map of partnership invite action types
 * @enum {String}
 */
export enum PartnershipResendInviteAction {
  SUBMITTING = 'submitting',
  SUBMITTED = 'submitted',
}
