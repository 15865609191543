import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { disableMembershipRoutine } from 'actions/routines/membership';

import { currentCompanyMembersWithRoleSelector } from 'selectors/currentCompanySelectors';
import { isFetchingMembershipSelector } from 'selectors/membershipsSelector';
import { rolesByIdSelector } from 'selectors/rolesSelectors';

import ActiveMembersTable from './ActiveMembersTable';

const mapStateToProps = createStructuredSelector({
  currentCompanyMembers: currentCompanyMembersWithRoleSelector,
  isFetchingMembers: isFetchingMembershipSelector,
  roles: rolesByIdSelector,
});

const mapDispatchToProps = {
  disableMembership: disableMembershipRoutine,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveMembersTable);
