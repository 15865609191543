import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox } from 'components';

import { getErrorsFromFieldState } from '../helpers';

import type { RenderCheckboxProps } from './RenderCheckbox.types';

const RenderCheckbox = ({ name, label, isRequired, shouldUnregister }: RenderCheckboxProps): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <Checkbox
          content={label}
          errors={getErrorsFromFieldState(fieldState, field.name)}
          isChecked={!!field.value}
          name={field.name}
          onBlur={field.onBlur}
          onChange={field.onChange}
        />
      )}
      rules={{ required: isRequired }}
      shouldUnregister={shouldUnregister}
    />
  );
};

export default RenderCheckbox;
