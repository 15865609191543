import React from 'react';
import { compose } from 'redux';

import { hasLength } from 'helpers/utility';

import { withCopyAction, withLockedAction, withRevealAction, withTooltipAction } from './hoc';
import type { PaymentMethodDetailItemProps } from './PaymentMethodDetailItem.types';

/**
 * Renders funding attribute names and values like accountNumber and routingNumber of a PaymentMethod.
 */
export const PaymentMethodDetailItem: React.FC<PaymentMethodDetailItemProps> = ({
  actionsElements = [],
  label,
  value,
}) => (
  <div className="payment_method__detail-item-container">
    <div className="payment_method__detail-item-label-value-container">
      <div className="payment_method__detail-item-label">{label}</div>
      <div className="payment_method__detail-item-value" data-fullstory>
        {value}
      </div>
    </div>
    {hasLength(actionsElements) && <div className="payment_method__detail-item-actions">{actionsElements}</div>}
  </div>
);

const enhance = compose(withTooltipAction, withRevealAction, withCopyAction, withLockedAction);

export default enhance(PaymentMethodDetailItem);
