import { combineReducers } from 'redux';

import webhookSettingsReducer from './webhookSettingsReducer';
import webhooksReducer from './webhooksReducer';
import webhookTestReducer from './webhookTestReducer';

export default combineReducers({
  settings: webhookSettingsReducer,
  tests: webhookTestReducer,
  webhooks: webhooksReducer,
});
