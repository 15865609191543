import clsx from 'clsx';
import React from 'react';

import { FlexCol } from 'components';

import { ExternalButtonStateTypes } from 'constants/ui';

import { ExternalButton, ExternalBackButton, ExternalColumnHeader, ExternalComponents } from './components';
import { ExternalColumnProps } from './ExternalColumn.types';

/**
 * ExternalColumn structures option header body and other components for external workflows
 * @param props
 */
const ExternalColumn = ({
  body,
  buttonMap = {},
  className,
  components,
  currentButton = ExternalButtonStateTypes.INITIAL,
  header = {},
  isBackButtonEnabled = false,
  renderExternalComponent,
}: ExternalColumnProps) => {
  const buttonProps = Object(buttonMap)[currentButton];

  // Determine whether or not to render a back button for the external flow.
  // TODO: There will be more conditional code and plumbing added in future
  // pull requests. For now this enables us to set a flag in storybook to
  // show a "with back button" variant.
  const shouldShowBackButton = isBackButtonEnabled && buttonProps !== undefined;

  return (
    <FlexCol className={clsx(className, 'external-column')}>
      <ExternalColumnHeader renderExternalComponent={renderExternalComponent} {...header} />
      {body && <div className="external-column--body">{body}</div>}
      <ExternalComponents
        className="external-column--components-container"
        components={components}
        renderExternalComponent={renderExternalComponent}
      />
      <ExternalButton {...buttonProps} />
      {shouldShowBackButton && <ExternalBackButton />}
    </FlexCol>
  );
};

export default ExternalColumn;
