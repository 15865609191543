import React from 'react';

import { FormControl, Text } from 'components';

import { commonFormFields } from 'constants/formFields';

import { isCompanyTypeBusiness } from 'helpers/currentCompany';
import { requiredValidator } from 'helpers/fieldValidation';
import { getVendorOrCustomerTitleFromItemKind } from 'helpers/partnerships';
import './PartnershipNameSettingsForm.scss';

import PartnershipNameSettingsFormField from './PartnershipNameSettingsFormField';
import type { PartnershipNameSettingsFormProps } from './PartnershipNameSettingsFormProps.types';

/**
 * Partnership name form fields to be rendered inside a disclosure list
 * @param {PartnershipNameSettingsFormProps} props
 * @returns {StatelessComponent}
 */
const PartnershipNameSettingsForm: React.VFC<PartnershipNameSettingsFormProps> = ({
  companyType,
  displayName,
  isLedgerIntegrationConnected,
  itemKind,
  ledgerName,
  partnerLedgerName,
  partnerName,
  partnershipType,
  updatePartnerField,
}) => {
  const isCompanyBusiness = isCompanyTypeBusiness(companyType);

  const onResetFieldValue = React.useCallback(
    (fieldName: string) => {
      updatePartnerField(fieldName, displayName);
    },
    [displayName, updatePartnerField],
  );

  const resetFields = React.useCallback(() => {
    if (isCompanyBusiness) {
      onResetFieldValue(commonFormFields.PARTNER_NAME);
    }

    if (isLedgerIntegrationConnected) {
      onResetFieldValue(commonFormFields.PARTNER_LEDGER_NAME);
    }
  }, [isCompanyBusiness, isLedgerIntegrationConnected, onResetFieldValue]);

  // Reset field value whenever displayName is updated or component unmounts
  const [isMounted, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    if (isMounted) {
      // We do not want the fields to be reset on mount as the correct values already come from
      // initial state - specially useful when we are creating a partner from ledger and this partner
      // already has custom ledger and legal names, hence we can not reset them on mount
      resetFields();
    } else {
      setIsMounted(true);
    }

    return resetFields;
    // disabling lint on line of dependencies as we do not want this effect to run when
    // isMounted changes
    // eslint-disable-next-line
  }, [displayName, resetFields]);

  const partnershipTypeTitle = partnershipType || getVendorOrCustomerTitleFromItemKind(itemKind);

  return (
    <FormControl className="flex-direction--column legal-name--width">
      <Text.Regular>We use the display name as your legal name by default. You can update it below.</Text.Regular>

      {isCompanyBusiness && (
        <PartnershipNameSettingsFormField
          isInitialValueLocked={partnerName === displayName}
          label="Legal entity name"
          name={commonFormFields.PARTNER_NAME}
          validate={requiredValidator}
          value={partnerName}
        />
      )}

      {isLedgerIntegrationConnected && (
        <PartnershipNameSettingsFormField
          isInitialValueLocked={partnerLedgerName === displayName}
          label={`${ledgerName} ${partnershipTypeTitle} profile`}
          name={commonFormFields.PARTNER_LEDGER_NAME}
          validate={requiredValidator}
          value={partnerLedgerName}
        />
      )}
    </FormControl>
  );
};

export default PartnershipNameSettingsForm;
