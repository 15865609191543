import classNames from 'classnames';
import React from 'react';

import { FormFieldErrors } from 'components/error/components';
import { FormControl } from 'components/form';

import { hasLength } from 'helpers/utility';

import type { HintFormFieldWrapperProps } from './HintFormFieldWrapper.types';

/**
 * When  a hint is used as a form field, this wrapper will display field errors,
 * and will apply a `has-error` class to the wrapper when they exist.
 * @param props - Component props
 */
const HintFormFieldWrapper: React.FC<HintFormFieldWrapperProps> = ({
  children,
  className,
  fieldName,
  hideErrors,
  meta,
}) => {
  const hasErrors = meta && hasLength(meta.error) && !hideErrors;

  return (
    <FormControl>
      <div
        className={classNames({
          'hint-field': true,
          'has-error': hasErrors,
          [className]: !!className,
        })}
      >
        {children}
        {hasErrors && <FormFieldErrors errors={meta.error} fieldName={fieldName} />}
      </div>
    </FormControl>
  );
};

export default HintFormFieldWrapper;
