import React from 'react';

import { IconNames, Text, TooltipMUIIcon } from 'components';

import { colors } from 'constants/styles';
import { TooltipMaxWidth, TooltipPadding } from 'constants/tooltip';

import { getCountryNameFromCode } from 'helpers/countries';
import { getExchangeRateText } from 'helpers/currency';
import { formatCurrencyExplicit } from 'helpers/currencyFormatter';

import { useCountryCodesContext, useCurrencyContext } from 'hooks';

import { TooltipEntry } from '..';

import { CurrencyConversionRateTooltipProps } from './CurrencyConversionRateTooltip.types';

/**
 * Renders tooltip with currency exchange info
 */
const CurrencyConversionRateTooltip = ({
  conversionCurrency,
  country,
  currencyCodeReceiver,
  exchangeRate,
  hasConversion,
  isExchangeRateLocked,
  plainAmount,
}: CurrencyConversionRateTooltipProps): JSX.Element => {
  const currencyCodeMap = useCurrencyContext();
  const countryCodeMap = useCountryCodesContext();

  const formattedExchangeRate = getExchangeRateText(exchangeRate, currencyCodeReceiver, currencyCodeMap, {
    precision: 4,
  });

  const formattedAmount = formatCurrencyExplicit(plainAmount, currencyCodeReceiver, currencyCodeMap);

  return (
    <TooltipMUIIcon
      icon={IconNames.GLOBE}
      iconClassName="tooltip-icon"
      iconColor={colors.colorGreyMedHex}
      padding={TooltipPadding.SKINNY}
      title={
        <div className="flex-col">
          <div className="flex">
            <TooltipEntry label="Country" value={getCountryNameFromCode(countryCodeMap, country)} />
            <TooltipEntry label="Amount" value={formattedAmount} />
            {hasConversion && <TooltipEntry label="Conversion rate" value={formattedExchangeRate} />}
          </div>
          {!isExchangeRateLocked && hasConversion && (
            <Text.Regular className="margin-top--m">{`Amount in ${conversionCurrency} is estimated.`}</Text.Regular>
          )}
        </div>
      }
      tooltipProps={{
        arrow: true,
        maxWidth: TooltipMaxWidth.NONE,
      }}
    />
  );
};

export default CurrencyConversionRateTooltip;
