import React from 'react';

import { HELP_DOCS_URL_EXTERNAL } from 'constants/platform';

import LinkNewPage from '../LinkNewPage';

interface HelpDocsLinkProps {
  className?: string;
}

/**
 * Renders link to the help docs page
 */
const HelpDocsLink = ({ className, ...rest }: HelpDocsLinkProps): JSX.Element => {
  return (
    <LinkNewPage className={className} href={HELP_DOCS_URL_EXTERNAL} {...rest}>
      Help docs
    </LinkNewPage>
  );
};

export default HelpDocsLink;
