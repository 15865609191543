import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import {
  APPROVAL_QUEUED_TEXT,
  RESCHEDULING_QUEUED_TEXT,
  SCHEDULING_QUEUED_TEXT,
  SEND_QUEUED_TEXT,
} from 'constants/status';

import {
  bulkImportHasIssues,
  getBulkActionFromItemLockState,
  isBulkActionApprove,
  isBulkActionApproveAndSend,
  isBulkActionSend,
} from 'helpers/bulkActions';
import { isDateToday } from 'helpers/date';
import {
  getItemDateExpected,
  isItemDueSoon,
  isItemKindPayable,
  isItemKindReceivable,
  isItemOverdue,
  isItemPaymentDeliveryMethodAch,
  isItemPaymentDeliveryMethodInternational,
  itemDueDateDescription,
} from 'helpers/items';
import { isPaymentDeliveryOptionRTP } from 'helpers/paymentMethods';
import { or } from 'helpers/utility';

import { Item } from 'interfaces/item';

/**
 * Get text for bulk status.
 */
export const getBulkUploadText = (): string => 'Loading bill from a file...';

/**
 * Get the text for a completed import with failures
 */
export const getCompletedWithFailuresText = ({ summary }: Pick<BulkImport, 'summary'>): string => {
  const {
    totals: { count, errors },
  } = summary;
  const difference = count - errors;

  return `${difference.toLocaleString()} / ${count.toLocaleString()} uploaded successfully`;
};

/**
 * Get text for created status.
 */
export const getCreatedText = (dateStr?: string): string => (dateStr ? `Created on ${dateStr}` : '');

/**
 * Get text for draft status.
 */
export const getDraftText = (dateStr?: string): string => (dateStr ? `Draft created ${dateStr}` : '');

/**
 * Get text for externally paid status.
 */
export const getExternallyPaidText = (dateStr?: string): string =>
  dateStr ? `Paid outside ${PLATFORM_DISPLAY_SHORT_NAME} ${dateStr}` : `Paid outside ${PLATFORM_DISPLAY_SHORT_NAME}`;

/**
 * Get text for initiated status.
 */
export const getInitiatedText = (item: Pick<Item, 'paymentDeliveryOption' | 'dateExpected'>): string => {
  if (isPaymentDeliveryOptionRTP(item.paymentDeliveryOption)) {
    return 'Payment will arrive today';
  }

  const dateExpected = getItemDateExpected(item);

  return dateExpected ? `Expected ${dateExpected}` : '';
};

/**
 * Get text for issue status.
 */
export const getIssueText = (): string => 'Please contact support';

/**
 * Get the text for a completed item.
 */
export const getItemCompletedText = (item: BulkImport, dateStatusChange?: string): string => {
  if (bulkImportHasIssues(item)) {
    return getCompletedWithFailuresText(item);
  }

  if (isItemPaymentDeliveryMethodAch(item) || isItemPaymentDeliveryMethodInternational(item)) {
    return dateStatusChange ? `Transferred ${dateStatusChange}` : '';
  }

  return dateStatusChange ? `Check arrived ${dateStatusChange}` : '';
};

/**
 * Get the text for a new item.
 */
export const getItemNewText = (item: Pick<Item, 'kind' | 'daysUntilDue'>): string | null => {
  if (isItemKindPayable(item)) {
    if (isItemDueSoon(item) || isItemOverdue(item)) {
      return itemDueDateDescription(item);
    }

    return 'Pay now';
  }

  if (isItemKindReceivable(item)) {
    return 'Accept to receive';
  }

  return null;
};

/**
 * Get the text for a pending item.
 */
export const getItemPendingText = (item: Pick<Item, 'kind' | 'daysUntilDue'>): string | null => {
  if (isItemKindPayable(item)) {
    return 'Awaiting vendor action';
  }

  if (isItemKindReceivable(item)) {
    if (isItemDueSoon(item) || isItemOverdue(item)) {
      return itemDueDateDescription(item);
    }

    return 'Unpaid';
  }

  return null;
};

/**
 * Get text for processing status.
 */
export const getOCRText = (): string => 'OCR';

/**
 * Get generic status text e.g. "On Jun 6th, 2019"
 */
export const getOnDateText = (dateStr?: string): string => (dateStr ? `On ${dateStr}` : '');

/**
 * Get text for processing status.
 */
export const getProcessingText = (): string => 'Preparing transaction...';

/**
 * Get text for queued item based on item.lockState
 */
export const getQueuedText = (item: Pick<Item, 'lockState' | 'dateScheduled'>): string => {
  const bulkAction = getBulkActionFromItemLockState(item.lockState);

  if (isBulkActionApprove(bulkAction)) {
    return APPROVAL_QUEUED_TEXT;
  }

  if (or(isBulkActionApproveAndSend(bulkAction), isBulkActionSend(bulkAction))) {
    if (isDateToday(item.dateScheduled)) {
      return SEND_QUEUED_TEXT;
    }

    return SCHEDULING_QUEUED_TEXT;
  }

  // bulk action is "Edit send date"
  return RESCHEDULING_QUEUED_TEXT;
};

/**
 * Get text for ready-to-send status.
 */
export const getReadyToSendText = (isExternal = false): string =>
  isExternal ? 'Schedule date pending' : 'No send date selected';

/**
 * Get text for scheduled status.
 */
export const getScheduledText = (dateStr?: string, isExternal?: boolean): string => {
  if (isExternal) {
    return 'Queued for release';
  }
  return dateStr ? `Send on ${dateStr}` : '';
};
