import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { commonFormFields, partnerFundingAccountFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';

import type { ReduxState } from 'interfaces/redux';

import { partnerCompanyNameFromPartnershipPropSelector } from 'queries/companyCompoundSelectors';

import { createFieldValueSelector } from 'selectors/createFormSelectors';
import { currentCompanyMailingAddressSelector } from 'selectors/currentCompanySelectors';

import ManuallyAddBankAccountAddressDetails from './ManuallyAddBankAccountAddressDetails';
import type { StateProps } from './ManuallyAddBankAccountAddressDetails.types';

const countryFormFieldSelector = createFieldValueSelector(
  formNamesFunding.ADD_MANUAL_BANK,
  commonFormFields.PARTNER_COUNTRY,
);
const applyRegisteredAddressSelector = createFieldValueSelector(
  formNamesFunding.ADD_MANUAL_BANK,
  partnerFundingAccountFormFields.APPLY_REGISTERED_ADDRESS,
);

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  address: currentCompanyMailingAddressSelector,
  country: countryFormFieldSelector,
  partnerCompanyName: partnerCompanyNameFromPartnershipPropSelector,
  shouldApplyRegisteredAddress: applyRegisteredAddressSelector,
});

export default connect(mapStateToProps)(ManuallyAddBankAccountAddressDetails);
