import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { isInternationalPartnershipSelector } from 'queries/partnershipCompoundSelectors';

import UpdatePaymentMethodFormWithCheckboxAndHints from './UpdatePaymentMethodFormWithCheckboxAndHints';
import { StateProps } from './UpdatePaymentMethodFormWithCheckboxAndHints.types';

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  isInternational: isInternationalPartnershipSelector,
});

export default connect(mapStateToProps)(UpdatePaymentMethodFormWithCheckboxAndHints);
