import { convertToSnakeCase } from '@routable/framework';
import { taxToolsFiltersModel, type TaxToolsListFilter } from '@routable/taxes';
import { taxTools } from '@routable/taxes';
import { generateKeys as generateVendorRiskChecksKeys } from '@routable/vendor-risk';
import { queryClient } from 'milton/components';

import { fetchSinglePartnershipRequest, fetchSinglePartnershipSuccess } from 'actions/partnership';
import { updateAddressRoutine } from 'actions/routines/addresses';
import { showErrorUi, showSuccessUi } from 'actions/ui';

import type { W8FormModel, W9FormModel } from 'complexComponents';

import { GENERIC_ERROR } from 'constants/error';
import { ExportTaxInfoSuccessText } from 'constants/partnership';
import { TaxInfoDataKey, TaxSingleRequestText, TinTypes } from 'constants/taxes';
import { SuccessIndicatorMessages } from 'constants/ui';

import { isCompanyTypePersonal } from 'helpers/currentCompany';
import { downloadFile } from 'helpers/fileHelpers';

import type { RoutableApiResponse } from 'interfaces/routableApiResponse';

import type { TaxDownloadOptions } from 'modules/dashboard/company/CompaniesSection/components/TaxTools/components';

import { payloadToJSONAPI } from 'services/api/formatHelpers';

import { routableApi, RoutableTags, RoutableTagIds } from '../routableApi';

const extendedPartnershipApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    partnershipForId: builder.query<RoutableApiResponse, string>({
      query: (partnershipId) => ({
        url: `/partnerships/${partnershipId}/`,
        method: 'GET',
      }),
      providesTags: [RoutableTags.Partnership],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          // Since we're using the legacy saga/reducer/selector setup for partnerships
          // in some places, we need to update that state after we receive data in order
          // to keep everything in-sync.
          const { data } = await queryFulfilled;
          dispatch(fetchSinglePartnershipSuccess(data));
        } catch {
          // Don't do anything if the request has failed
        }
      },
    }),
    lookupDisplayName: builder.query<RoutableApiResponse, { search: string }>({
      query: ({ search }) => ({
        url: `/partnerships/display_name_lookup?display_name=${encodeURIComponent(search)}`,
      }),
      providesTags: [RoutableTags.Partnership],
    }),
    taxInfoBulkRequest: builder.mutation<RoutableApiResponse, { filters?: TaxToolsListFilter }>({
      query: ({ filters }) => ({
        url: '/taxes/bulk_requests/',
        params: convertToSnakeCase(filters),
        payload: payloadToJSONAPI({}, 'TaxBulkRequest'),
        method: 'POST',
        setRequester: true,
      }),
      invalidatesTags: [RoutableTags.Partnership],
    }),
    taxInfoSingleRequest: builder.mutation<
      RoutableApiResponse,
      { partnershipId: string; taxDataKey: ValueOf<typeof TaxInfoDataKey> }
    >({
      query: ({ partnershipId, taxDataKey }) => ({
        url: `/partnerships/${partnershipId}/taxes/${TaxSingleRequestText[taxDataKey]?.formUrl}/requests/`,
        payload: payloadToJSONAPI({}, 'PartnershipRequest'),
        method: 'POST',
        setRequester: true,
      }),
      onQueryStarted: async ({ taxDataKey }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const { email } = Object.values(data.user).find(Boolean)?.attributes;

          dispatch(showSuccessUi(`Request for ${TaxSingleRequestText[taxDataKey].formName} sent to ${email}!`));
          queryClient.invalidateQueries({ queryKey: [taxTools] });
        } catch {
          dispatch(showErrorUi(GENERIC_ERROR));
        }
      },
      invalidatesTags: [RoutableTags.Partnership],
    }),
    w8BenTaxes: builder.query<RoutableApiResponse, { partnershipId: string }>({
      query: ({ partnershipId }) => ({
        url: `/partnerships/${partnershipId}/taxes/w_8ben/`,
      }),
      providesTags: [{ type: RoutableTags.Partnership, id: RoutableTagIds.W8TaxInfo }],
    }),
    w8BenDetails: builder.query<RoutableApiResponse, { partnershipId: string; docId: string }>({
      query: ({ partnershipId, docId }) => ({
        url: `/partnerships/${partnershipId}/taxes/w_8ben/${docId}/`,
      }),
    }),
    w8BenETaxes: builder.query<RoutableApiResponse, { partnershipId: string }>({
      query: ({ partnershipId }) => ({
        url: `/partnerships/${partnershipId}/taxes/w_8ben_e/`,
      }),
      providesTags: [{ type: RoutableTags.Partnership, id: RoutableTagIds.W8TaxInfo }],
    }),
    w9Taxes: builder.query<RoutableApiResponse, { partnershipId: string }>({
      query: ({ partnershipId }) => ({
        url: `/partnerships/${partnershipId}/taxes/w9/`,
      }),
      providesTags: [{ type: RoutableTags.Partnership, id: RoutableTagIds.W9TaxInfo }],
    }),
    w9Details: builder.query<RoutableApiResponse, { partnershipId: string; docId: string }>({
      query: ({ partnershipId, docId }) => ({
        url: `/partnerships/${partnershipId}/taxes/w9/${docId}/`,
      }),
    }),
    exportTax1099: builder.mutation<
      RoutableApiResponse,
      { filters?: TaxToolsListFilter; options?: TaxDownloadOptions } | never
    >({
      query: ({ filters = {}, options = {} } = {}) => {
        const safeParsedFilters = taxToolsFiltersModel.safeParse(filters);
        const parsedFilters = safeParsedFilters.success ? safeParsedFilters.data : {};

        const { totalPaidYear, ...taxFormOptions } = options;
        const meta = Object.keys(taxFormOptions).length ? taxFormOptions : undefined;
        const params = totalPaidYear ? { ...parsedFilters, totalPaidYear } : parsedFilters;

        return {
          url: `/taxes/export/tax1099/`,
          payload: payloadToJSONAPI({ meta }, 'PartnershipRequest'),
          params: convertToSnakeCase(params),
          method: 'POST',
          setRequester: true,
        };
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(showSuccessUi(ExportTaxInfoSuccessText));
        } catch {
          dispatch(showErrorUi(GENERIC_ERROR));
        }
      },
    }),
    exportTrack1099: builder.mutation<
      RoutableApiResponse,
      { filters?: TaxToolsListFilter; options?: TaxDownloadOptions }
    >({
      query: ({ filters = {}, options = {} } = {}) => {
        const safeParsedFilters = taxToolsFiltersModel.safeParse(filters);
        const parsedFilters = safeParsedFilters.success ? safeParsedFilters.data : {};

        const { totalPaidYear, ...taxFormOptions } = options;
        const meta = Object.keys(taxFormOptions).length ? taxFormOptions : undefined;
        const params = totalPaidYear ? { ...parsedFilters, totalPaidYear } : parsedFilters;

        return {
          url: `/taxes/export/track1099/`,
          payload: payloadToJSONAPI({ meta }, 'PartnershipRequest'),
          params: convertToSnakeCase(params),
          method: 'POST',
          setRequester: true,
        };
      },
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(showSuccessUi(ExportTaxInfoSuccessText));
        } catch {
          dispatch(showErrorUi(GENERIC_ERROR));
        }
      },
    }),
    w9TaxDetailsFile: builder.query<RoutableApiResponse, { partnershipId: Id; documentId: Id; documentName: string }>({
      query: ({ partnershipId, documentId }) => ({
        url: `/partnerships/${partnershipId}/taxes/w9/${documentId}/?fmt=pdf`,
        requestFile: true,
      }),
      onQueryStarted: async ({ documentName }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          downloadFile(data, `${documentName}.pdf`);
          dispatch(showSuccessUi(SuccessIndicatorMessages.W9_TAX_DOCUMENT_DOWNLOADED));
        } catch {
          dispatch(showErrorUi(GENERIC_ERROR));
        }
      },
      providesTags: [],
    }),
    w8BenTaxDetailsFile: builder.query<
      RoutableApiResponse,
      { partnershipId: Id; documentId: Id; documentName: string }
    >({
      query: ({ partnershipId, documentId }) => ({
        url: `/partnerships/${partnershipId}/taxes/w_8ben/${documentId}/?fmt=pdf`,
        requestFile: true,
      }),
      onQueryStarted: async ({ documentName }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          downloadFile(data, `${documentName}.pdf`);
          dispatch(showSuccessUi(SuccessIndicatorMessages.W_8BEN_TAX_DOCUMENT_DOWNLOADED));
        } catch {
          dispatch(showErrorUi(GENERIC_ERROR));
        }
      },
      providesTags: [],
    }),
    w8BenEDetails: builder.query<RoutableApiResponse, { partnershipId: Id; docId: Id }>({
      query: ({ partnershipId, docId }) => ({
        url: `/partnerships/${partnershipId}/taxes/w_8ben_e/${docId}/`,
      }),
    }),
    w8BenETaxDetailsFile: builder.query<
      RoutableApiResponse,
      { partnershipId: Id; documentId: Id; documentName: string }
    >({
      query: ({ partnershipId, documentId }) => ({
        url: `/partnerships/${partnershipId}/taxes/w_8ben_e/${documentId}/?fmt=pdf`,
        requestFile: true,
      }),
      onQueryStarted: async ({ documentName }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          downloadFile(data, `${documentName}.pdf`);
          dispatch(showSuccessUi(SuccessIndicatorMessages.W_8BEN_E_TAX_DOCUMENT_DOWNLOADED));
        } catch {
          dispatch(showErrorUi(GENERIC_ERROR));
        }
      },
      providesTags: [],
    }),
    submitW8Ben: builder.mutation<RoutableApiResponse, { membershipId: Id; partnershipId: Id; values: W8FormModel }>({
      onQueryStarted: async ({ partnershipId }, { dispatch, queryFulfilled }) => {
        dispatch(updateAddressRoutine.request());
        try {
          const { data } = await queryFulfilled;
          dispatch(updateAddressRoutine.success(data));
          dispatch(fetchSinglePartnershipRequest(partnershipId));
          queryClient.invalidateQueries([taxTools]);
          queryClient.invalidateQueries(generateVendorRiskChecksKeys.latestReport(partnershipId));
        } catch (err) {
          dispatch(updateAddressRoutine.failure({ errors: err?.error?.data }));
        }
      },
      query: ({ membershipId, partnershipId, values }) => ({
        url: `/partnerships/${partnershipId}/taxes/w_8ben/`,
        payload: payloadToJSONAPI(
          {
            ...values,
            membership: { id: membershipId },
          },
          'W8BENTaxInfo',
          'membership',
        ),
        method: 'POST',
        requireAuth: true,
      }),
      invalidatesTags: [{ type: RoutableTags.Partnership, id: RoutableTagIds.W8TaxInfo }],
    }),
    submitW8BenE: builder.mutation<RoutableApiResponse, { membershipId: Id; partnershipId: Id; values: W8FormModel }>({
      query: ({ membershipId, partnershipId, values }) => ({
        url: `/partnerships/${partnershipId}/taxes/w_8ben_e/`,
        payload: payloadToJSONAPI(
          {
            ...values,
            membership: { id: membershipId },
          },
          'W8BENETaxInfo',
          'membership',
        ),
        method: 'POST',
        requireAuth: true,
      }),
      onQueryStarted: async ({ partnershipId }, { dispatch, queryFulfilled }) => {
        dispatch(updateAddressRoutine.request());
        try {
          const { data } = await queryFulfilled;
          dispatch(updateAddressRoutine.success(data));
          dispatch(fetchSinglePartnershipRequest(partnershipId));
          queryClient.invalidateQueries([taxTools]);
          queryClient.invalidateQueries(generateVendorRiskChecksKeys.latestReport(partnershipId));
        } catch (err) {
          dispatch(updateAddressRoutine.failure({ errors: err?.error?.data }));
        }
      },
      invalidatesTags: [{ type: RoutableTags.Partnership, id: RoutableTagIds.W8TaxInfo }],
    }),
    submitW9: builder.mutation<
      RoutableApiResponse,
      {
        values: W9FormModel;
        partnershipId: Id;
        membershipId: Id;
      }
    >({
      query: ({
        values: { isBusinessTinTypeSSN, companyType, address, ...formValues },
        partnershipId,
        membershipId,
      }) => {
        const isIndividual = isCompanyTypePersonal(companyType);
        const tinType = isIndividual || isBusinessTinTypeSSN ? TinTypes.SSN : TinTypes.EIN;

        const data = {
          ...formValues,
          ...address,
          companyType,
          tinType,
          membership: { id: membershipId },
        };

        return {
          url: `/partnerships/${partnershipId}/taxes/w9/`,
          payload: payloadToJSONAPI(data, 'W9TaxInfo', 'membership'),
          method: 'POST',
          requireAuth: true,
        };
      },
      onQueryStarted: async ({ partnershipId }, { dispatch, queryFulfilled }) => {
        dispatch(updateAddressRoutine.request());
        try {
          const { data } = await queryFulfilled;
          dispatch(updateAddressRoutine.success(data));
          dispatch(fetchSinglePartnershipRequest(partnershipId));
          queryClient.invalidateQueries([taxTools]);
          queryClient.invalidateQueries(generateVendorRiskChecksKeys.latestReport(partnershipId));
        } catch (err) {
          dispatch(updateAddressRoutine.failure({ errors: err?.error?.data }));
        }
      },
      invalidatesTags: [RoutableTags.Partnership],
    }),
  }),
});

export const {
  useTaxInfoBulkRequestMutation,
  useExportTax1099Mutation,
  useExportTrack1099Mutation,
  useLazyLookupDisplayNameQuery,
  useLazyW8BenTaxesQuery,
  useLazyW8BenETaxesQuery,
  useLazyW8BenTaxDetailsFileQuery,
  useLazyW8BenETaxDetailsFileQuery,
  useLazyW9TaxDetailsFileQuery,
  useLazyW9TaxesQuery,
  useLookupDisplayNameQuery,
  usePartnershipForIdQuery,
  useLazyPartnershipForIdQuery,
  useTaxInfoSingleRequestMutation,
  useSubmitW8BenMutation,
  useSubmitW8BenEMutation,
  useW8BenTaxesQuery,
  useW8BenDetailsQuery,
  useW8BenEDetailsQuery,
  useW8BenETaxesQuery,
  useW8BenTaxDetailsFileQuery,
  useW8BenETaxDetailsFileQuery,
  useW9TaxDetailsFileQuery,
  useW9TaxesQuery,
  useW9DetailsQuery,
  useSubmitW9Mutation,
} = extendedPartnershipApi;
