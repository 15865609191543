import { formNamesItem } from '@routable/shared';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderNumericInput } from 'components';

import { formatNumberForFlexibleDecimalScale } from 'helpers/fieldFormatters';
import { numberOnly } from 'helpers/fieldNormalizers';

import { useDiscrepancyContext, useMemoizedConditionalValidate } from 'hooks';

import { useDynamicFieldRequiredValidators, useTableCellFieldIsDisabled } from 'modules/table/hooks';

import { createFieldValueSelector } from 'selectors/createFormSelectors';
import { createItemFormPurchaseOrderSelector } from 'selectors/forms';

import type { NumberTableFieldProps } from './NumberTableField.types';

/**
 * Renders a text field formatted for numbers to be rendered in a table cell.
 */
const NumberTableField = (props: NumberTableFieldProps): JSX.Element => {
  const {
    decimalScale,
    fixedDecimalScale,
    formValues,
    getIsDisabled,
    getIsRequired,
    getRowValues,
    minDecimalScale,
    name,
    setIsDisabled,
    validate,
  } = props;

  const { isRequired, validators } = useDynamicFieldRequiredValidators({
    formValues,
    getIsRequired,
    getRowValues,
    validate,
  });
  const cachedValidators = useMemoizedConditionalValidate(!formValues.ui?.blockDetailsSections, validators);
  const isDisabled = useTableCellFieldIsDisabled({ formValues, getIsDisabled, setIsDisabled });
  const purchaseOrder = useSelector(createItemFormPurchaseOrderSelector);

  const { hasDiscrepancyForColumn } = useDiscrepancyContext();
  const fieldSelector = createFieldValueSelector(formNamesItem.CREATE_ITEM, name.split('.').slice(0, -1).join('.'));
  const lineItem = useSelector(fieldSelector);
  const [fieldName] = name.split('.').slice(-1);

  const lineItemId = lineItem?.id || lineItem?.purchase_order_line_item_id;
  const hasDiscrepancy = purchaseOrder?.value && hasDiscrepancyForColumn(lineItemId, fieldName);

  return (
    <Field
      allowNegative
      className="w-full ledger-form-field"
      component={ReduxFormFieldRenderNumericInput}
      contentBefore={
        hasDiscrepancy && (
          <div
            className={clsx('absolute border border-gold-40 rounded-[13px] inset-y-[6px] -inset-x-[8px]', {
              'z-[-1]': !isDisabled,
            })}
            data-testid="discrepancy-border"
          />
        )
      }
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      format={(value) =>
        formatNumberForFlexibleDecimalScale({
          decimalScale,
          fixedDecimalScale,
          minDecimalScale,
          value,
        })
      }
      hideLabel
      isDebounced
      isDisabled={isDisabled}
      isNumericString
      isRequired={isRequired}
      name={name}
      normalize={numberOnly}
      // we want to update the value only on blur
      onChange={(evt) => evt.preventDefault()}
      type="text"
      validate={cachedValidators}
    />
  );
};

export default NumberTableField;
