import { PERSISTENCE_KEY } from 'constants/persistence';

import { getQueryParamValueFromUrl } from './queryParams';

/**
 * Sets an item in local storage.
 * @param {string} key
 * @param {*} value
 */
export const localStorageSet = (key: string, value: string): void => localStorage.setItem(key, value);

/**
 * Gets an item from local storage.
 * @param {string} key
 * @return {*}
 */
export const localStorageGet = (key: string): string | undefined => localStorage.getItem(key);

/**
 * Removes an item from local storage entirely.
 * @param {string} key
 */
export const localStorageRemove = (key: string): void => {
  localStorage.removeItem(key);
};

export const handleClearLocalStorage = (): void => {
  // remove all non persistence entries.
  Object.keys(localStorage)
    .filter((key) => !key.includes(PERSISTENCE_KEY))
    .forEach(localStorageRemove);
};

// Need to check "first" if there is actual data in local storage otherwise
// json parse will result in an error so we add a simple try/catch->nothing
export const getMembershipsDataFromLocalStorage = (): MembershipWithUserData[] => {
  let storageResult = [];
  try {
    storageResult = JSON.parse(localStorageGet('memberships'));
  } catch {
    /** */
  }

  return storageResult;
};

export const getCurrentMembershipId = (): string => {
  // Check localStorage first
  const localStorageMembershipId = localStorageGet('currentMembershipId');
  if (localStorageMembershipId) {
    return localStorageMembershipId;
  }

  // Check query params
  return getQueryParamValueFromUrl('membership_id');
};

export const getCurrentMembershipData = (): Partial<MembershipWithUserData> => {
  const membershipsData = getMembershipsDataFromLocalStorage();
  const currentMembershipId = getCurrentMembershipId();

  if (membershipsData && currentMembershipId && membershipsData[currentMembershipId]) {
    return membershipsData[currentMembershipId];
  }

  return {};
};

export const getCurrentCompanyId = (): string => {
  const membershipData = getCurrentMembershipData();

  if (membershipData && membershipData.companyId) {
    return membershipData.companyId;
  }

  return getQueryParamValueFromUrl('company_id');
};

export const getCurrentUserId = (): string => {
  const membershipData = getCurrentMembershipData();

  if (membershipData && membershipData.userId) {
    return membershipData.userId;
  }

  return getQueryParamValueFromUrl('user_id');
};

export const getFromStore = <T = unknown>(key: string, defaultValue?: T): T | undefined => {
  const storedValue = localStorageGet(key);
  if (storedValue) {
    try {
      return JSON.parse(storedValue);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`failed to parse ${key}; using defaultValue instead`);
    }
  }
  return defaultValue;
};

export const setInStore = <T>(key: string, value: T): void => {
  localStorageSet(key, JSON.stringify(value));
};
