import { DateElement, DateFormats } from '@routable/shared';
import dayjs from 'dayjs';
import _find from 'lodash/find';
import _get from 'lodash/get';
import React from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Validator } from 'redux-form';

import { FundingErrors } from 'constants/error';
import { commonFormFields, partnershipMemberContactFormFields } from 'constants/formFields';
import { PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MAX, PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MIN } from 'constants/platform';
import * as regexp from 'constants/regex';
import { URL } from 'constants/regex';
import { DateStringLength, TimeZoneId } from 'constants/temporal';
import { ValidationErrorText } from 'constants/validation';

import { MAX_SUBDOMAIN_LENGTH } from 'helpers/constants';
import { getAge, isDateBefore } from 'helpers/date';
import { digitsOnly } from 'helpers/fieldNormalizers';
import { isClientSideValidationDisabled } from 'helpers/formValidation';
import { unformatCurrency } from 'helpers/numbers';
import {
  any,
  anyValues,
  areAllEqual,
  hasLength,
  hasZeroLength,
  isArray,
  isEqual,
  isFn,
  isGreaterOrEqual,
  isNotEqual,
  isNum,
  isObject,
  isString,
  isValueEmpty,
  lengthOf,
  or,
  ternary,
} from 'helpers/utility';
import { isValidEmail } from 'helpers/validation';

import { BUSINESS_NAME_VALIDATION_REGEX } from 'modules/signup-v3/constants/regex';

import { partnershipMembersFromPartnershipPropSelector } from 'queries/partnershipMemberCompoundSelectors';

import { currentCompanyMembersSelector } from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { createPartnershipFormSelector } from 'selectors/forms';

import { storeAccessor as store } from 'store/accessor';

import { hintErrors } from '../components/passwordInput/components/PasswordHints/constants/hintErrors';
import { availableRequirementValidators } from '../components/passwordInput/constants/availableRequirementValidators';

import { isFullAddress } from './addressHelpers';
import { isCompanyTypeBusiness, isCompanyTypePersonal } from './currentCompany';
import {
  ConditionalValidator,
  CreateFreeformDatetimeValidator,
  CreateMinLengthValidator,
  GenerateDateLimitValidator,
} from './fieldValidation.types';
import { getVendorOrCustomerTitleFromItemKind, isPartnershipTypeVendor } from './partnerships';
import { checkRoutingNumber } from './routingNumbers';

export const bankAccountNumberValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  const regex = /^[0-9]{4,17}$/i;
  if (!regex.test(value)) {
    return ['Invalid bank account number!'];
  }

  return undefined;
};

export const bankRoutingNumberValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (checkRoutingNumber(value)) {
    return undefined;
  }

  return [FundingErrors.INVALID_ROUTING_NUMBER];
};

/**
 * Evaluates the first argument, `conditionalValueOrFunction`, and if truthy,
 * returns the second argument (a field validator function);
 *
 * `conditionalValueOrFunction` can be either... a condition or function.
 *
 * If a conditional value, it will be evaluated immediately, and the validator
 * will be returned if truthy.
 *
 * If a function, this will return a new validator. `conditionalValueOrFunction`
 * will be evaluated as a part of the actual field validation process, with
 * the same props the validator will receive plus the props of the wrapping component.
 */
export const conditionalValidator: ConditionalValidator = (conditionalValueOrFunction, validator) => {
  if (isClientSideValidationDisabled()) {
    return () => undefined;
  }

  if (isFn(conditionalValueOrFunction)) {
    // if conditionalValueOrFunction is a function, return a new validator
    // that calls conditionalValueOrFunction with all validator arguments.
    // conditionalValueOrFunction should return true to enforce validation,
    // or false to skip validation.
    // tl;dr-- can use a function here to define validators inside of configuration
    // files that use component props, but which don't have access to those props directly
    return (...args: [value: unknown, allValues?: unknown, props?: unknown, name?: unknown]) => {
      if (!conditionalValueOrFunction(...args)) {
        return undefined;
      }

      if (isArray(validator)) {
        // if validator is an array, return the result of the first validator that fails
        return validator.reduce((previousResult, validate: Validator) => {
          if (previousResult) {
            return previousResult;
          }
          return validate(...args);
        }, undefined);
      }

      return validator(...args);
    };
  }

  if (!conditionalValueOrFunction) {
    return () => undefined;
  }

  return validator;
};

export const dateValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  const error = ['Please enter a valid date (mm/dd/yyyy)'];

  if (!value) {
    return error;
  }

  if (value.length < DateStringLength.BIRTH_DATE) {
    return error;
  }

  const date = dayjs(value, DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY, true);

  if (date.isValid()) {
    return undefined;
  }

  return error;
};

/**
 * @description Generator is used for testing purposes. normally we should only import the useDateNotBeforeValidator
 */
export const generateUseDateNotBeforeValidator: GenerateDateLimitValidator =
  (useMemo) =>
  (lowestDate = dayjs().tz(TimeZoneId.PT).add(1, 'days').startOf('day')) =>
    useMemo(
      () => (value) => isDateBefore(value, lowestDate) ? [ValidationErrorText.minDateValidation] : undefined,
      [lowestDate],
    );

/**
 * @description makes a memo-ized date after this date validator to be used in redux-forms
 */
export const useDateNotBeforeValidator = generateUseDateNotBeforeValidator(React.useMemo);

export const EINValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (value && digitsOnly(value).length === 9) {
    return undefined;
  }

  return ['EIN must be a 9-digit number'];
};

export const emailValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (isValidEmail(value)) {
    return undefined;
  }

  return ['Invalid email address'];
};

export const fileInputValidator: Validator = (fileList) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (fileList && isArray(fileList) && fileList.length > 0) {
    return undefined;
  }

  return ['Required'];
};

/**
 * Use react-phone-number-input's helper method to determine if a phone number is valid.
 * @param value - Phone number with + prefix
 * @returns - Undefined if no error, otherwise array with error.
 */
export const internationalPhoneNumberValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (value && value.number && isValidPhoneNumber(value.number)) {
    return undefined;
  }

  return ['Not a valid phone number for this country'];
};

/**
 * Returns a function that, when called, checks through component or field props
 * for the given prop name. Returns the prop value if it exists, or true
 * by default (to enforce validation) if it does not exist.
 * @param {string} propName
 * @return {Function}
 */
export const makeEnforceByPropCondition: Validator =
  (propName) => (value, allValues, fieldProps, nameOrAccessor, componentProps) => {
    // use wrapping component props if given, or fallback to props of the field
    const evalProps = componentProps || fieldProps;

    // if we don't receive any props to evaluate, enforce validation by default
    if (!evalProps) {
      return true;
    }

    // if we don't receive a value for the specified prop, enforce validation by default
    if (evalProps[propName] === undefined) {
      return true;
    }

    // we received a value for the specified prop, return that value
    return evalProps[propName];
  };

export const maxAgeValidator: Validator = (maxAge) => (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (!value) {
    return undefined;
  }

  if (value.length < DateStringLength.BIRTH_DATE) {
    return undefined;
  }

  const birthDate = dayjs(value, DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY, true);

  if (!birthDate.isValid()) {
    return undefined;
  }

  const age = getAge(birthDate);

  if (age >= maxAge) {
    return [`You must be younger than ${maxAge} to use Routable`];
  }

  return undefined;
};

export const maxCharacterLengthValidator: Validator = (maxCharacters) => (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (!value) {
    return undefined;
  }

  if (String(value).length > maxCharacters) {
    return [`Has to be at most ${maxCharacters} characters`];
  }

  return undefined;
};

export const microdepositValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  const unformatted = unformatCurrency(value);

  if (unformatted < PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MIN) {
    return [`Amount must be at least ${PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MIN}`];
  }

  if (unformatted > PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MAX) {
    return [`Amount cannot be over ${PLATFORM_VERIFY_ACCOUNT_DEPOSIT_MAX}`];
  }

  return undefined;
};

export const minAgeValidator: Validator = (minAge) => (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (!value) {
    return undefined;
  }

  if (value.length < DateStringLength.BIRTH_DATE) {
    return undefined;
  }

  const birthDate = dayjs(value, DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY, true);

  if (!birthDate.isValid()) {
    return undefined;
  }

  const age = getAge(birthDate);

  if (age < minAge) {
    return [`You must be at least ${minAge} to use Routable`];
  }

  return undefined;
};

export const minCharacterLengthValidator: Validator = (minCharacters) => (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (!value) {
    return undefined;
  }

  if (String(value).length < minCharacters) {
    return [`Has to be at least ${minCharacters} characters`];
  }

  return undefined;
};

export const minMaxDateValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (!value) {
    return undefined;
  }

  const maxDate = dayjs('2100-12-31', DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY);
  const minDate = dayjs('1970-01-01', DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY);
  const date = dayjs(value, DateFormats.FULL_NUMERIC_YEAR_MONTH_DAY, true);

  const error = ['You must specify a date between 1/1/1970 and 12/31/2100'];

  if (!date.isValid()) {
    return undefined;
  }

  if (date <= maxDate && date >= minDate) {
    return undefined;
  }

  return error;
};

/**
 * If the value passed is an email belonging to the current team member, throw an error.
 * @function
 * @param {string} errorMessage
 * @returns {function}
 */
export const customizableNotYourEmailValidator =
  (errorMessage: string): Validator =>
  (value) => {
    if (!value || isClientSideValidationDisabled()) {
      return undefined;
    }

    const reduxState = store.getState();
    const currentUser = currentUserSelector(reduxState);

    if (isNotEqual(value, currentUser.email)) {
      return undefined;
    }

    return [errorMessage];
  };

/**
 * If the value passed is your email, return an error message of "You cannot add yourself as a contact"
 * @function
 * @param {StringMaybe} [value]
 * @returns {string[]|undefined}
 */
export const notYourEmailValidator = customizableNotYourEmailValidator('You cannot add yourself as a contact');

/**
 * If the value passed is your email, return an error message of "You cannot add yourself as a team member"
 * @function
 * @param {StringMaybe} [value]
 * @returns {string[]|undefined}
 */
export const cantAddYourselfAsTeamMemberValidator = customizableNotYourEmailValidator(
  'You cannot add yourself as a team member',
);

/**
 * If the value passed is an email belonging to an existing team member, throw an error.
 * @function
 * @param {string} errorMessage
 * @returns {function}
 */
export const customizableNotYourTeamMemberEmailValidator =
  (errorMessage: string): Validator =>
  (value) => {
    if (!value || isClientSideValidationDisabled()) {
      return undefined;
    }

    const reduxState = store.getState();
    const currentMembers = currentCompanyMembersSelector(reduxState);

    const foundEmailMatch = _find(currentMembers, (member) => member.email === value);

    if (!foundEmailMatch) {
      return undefined;
    }

    return [errorMessage];
  };

/**
 * If the value passed is an email belonging to one of your team members, return an error message of "You cannot add
 * your team member as a contact"
 * @function
 * @param {StringMaybe} [value]
 * @returns {string[]|undefined}
 */
export const notYourTeamMemberEmailValidator = customizableNotYourTeamMemberEmailValidator(
  'You cannot add your team member as a contact',
);

/**
 * If the value passed is an email belonging to one of your team members, return an error message of "This email already
 * belongs to an existing team member"
 * @function
 * @param {StringMaybe} [value]
 * @returns {string[]|undefined}
 */
export const alreadyATeamMemberEmailValidator = customizableNotYourTeamMemberEmailValidator(
  'This email already belongs to an existing team member',
);

/**
 * For the given partnership id, returns a validator that checks whether
 * the given value (email address) already exists in the associated partnership.
 */
export const notAnExistingPartnershipMemberEmailValidator: Validator = (value, allValues, props) => {
  const { partnership, sidePanel } = props;

  // __isNew__ or isLedgerOnly is used to differentiate partnerships that have been already persisted in the BE vs. new partnerships
  // that are being created.

  if (
    any([
      isValueEmpty(value),
      isValueEmpty(partnership),
      // eslint-disable-next-line no-underscore-dangle
      partnership?.__isNew__,
      partnership?.isLedgerOnly,
      isClientSideValidationDisabled(),
    ])
  ) {
    return undefined;
  }

  const reduxState = store.getState();
  const partnershipMembers = partnershipMembersFromPartnershipPropSelector(
    reduxState,
    // @ts-ignore -- According to the current jsdoc typing, this only gets one argument
    props,
  );

  const foundEmailMatch = _find(partnershipMembers, (member) => isEqual(member.email, value));

  if (!foundEmailMatch) {
    return undefined;
  }

  // when editing a partnership member, we don't want to flag the email as invalid if the field
  // value matches that of the existing member that is being edited
  if (isEqual(value, sidePanel?.initialPartnershipMember?.email)) {
    return undefined;
  }

  return ['You cannot add existing contacts'];
};

/**
 * Returns a validator that checks whether the given value (email address)
 * is already added in the current createPartnershipForm
 */
export const notAddedInCurrentFormEmailValidator: Validator = (value, allValues, props, fieldName) => {
  if (!value || isClientSideValidationDisabled()) {
    return undefined;
  }

  const { initialValues } = props;
  const { partner } = allValues;

  if (isEqual(value, initialValues[fieldName])) {
    return undefined;
  }

  // If the company type is individual, we want to skip checking if the email has already
  // been added in the  context of the current form, since we are adding partnership member
  // directly from the createPartnership form, and not the createPartnershipMember form,
  // like we do for the BUSINESS company type
  if (isCompanyTypePersonal(partner?.companyType)) {
    return undefined;
  }

  const reduxState = store.getState();
  const formPartnershipMembers = createPartnershipFormSelector(reduxState, commonFormFields.PARTNERSHIP_MEMBERS);

  const foundEmailMatch = _find(formPartnershipMembers, (member) => isEqual(member.email, value));

  if (!foundEmailMatch) {
    return undefined;
  }

  return ['This contact has already been added'];
};

/**
 * Creates a validator that checks if there is at least one contact, unless
 * it is a vendor and optionally if the companyType is a business
 */
export const createAtLeastOneContactValidator =
  (validateBusiness = false): Validator =>
  (value, allValues) => {
    if (!value || isClientSideValidationDisabled()) {
      return undefined;
    }
    const { item, partner, partnershipType } = allValues;
    const type = partnershipType || getVendorOrCustomerTitleFromItemKind(item?.kind);

    const isVendor = isPartnershipTypeVendor(type);
    const isPassing = isVendor || (validateBusiness && isCompanyTypeBusiness(partner?.companyType));

    if (isPassing) {
      return undefined;
    }

    if (isGreaterOrEqual(lengthOf(value), 1)) {
      return undefined;
    }
    return ['You must select at least one contact'];
  };

/**
 * Returns a validator that checks if there is at least one contact, unless
 * it is a vendor, business
 */
export const atLeastOneContactUnlessIsVendorAndIsBusinessValidator = createAtLeastOneContactValidator(true);

/**
 * Returns a validator that checks if there is at least one contact, unless
 * it is a vendor
 */
export const atLeastOneContactUnlessIsVendorValidator = createAtLeastOneContactValidator();

/**
 * Returns a validator that checks if at least one paymentDeliveryMethodsAccepted is selected
 */
export const atLeastOnePaymentMethodOptionValidator: Validator = (value, allValues, props, name) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  const [formFieldPrefix] = name.split('.');
  const paymentDeliveryMethodsAccepted = allValues[formFieldPrefix]?.paymentDeliveryMethodsAccepted;

  if (!paymentDeliveryMethodsAccepted) {
    return undefined;
  }

  if (Object.values(paymentDeliveryMethodsAccepted).some((el) => el === true)) {
    return undefined;
  }

  return ['At least 1 option is required to continue'];
};

export const numberGreaterOrEqualToZeroValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (typeof value === 'number' && value >= 0) {
    return undefined;
  }

  return ['Has to be greater or equal to zero'];
};

export const numberGreaterThanZeroValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (typeof value === 'number' && value > 0) {
    return undefined;
  }

  return ['Has to be greater than zero'];
};

/**
 * For reduxForm validate the max amount passed in.
 * Value is inherently passed by a reduxForm field.
 */
export const numberLessThanOrEqualToValidator =
  (lessThanOrEqualTo: number, options: { formatter?: (number: number) => string } = {}): Validator =>
  (value) => {
    if (isClientSideValidationDisabled()) {
      return undefined;
    }

    if (value <= lessThanOrEqualTo) {
      return undefined;
    }

    const { formatter } = options;

    const formattedValue = formatter ? formatter(lessThanOrEqualTo) : lessThanOrEqualTo;

    return [`Has to be less than or equal to ${formattedValue}`];
  };

/**
 * For reduxForm validate the max amount passed in.
 * Value is inherently passed by a reduxForm field.
 */
export const numberLessThanValidator =
  (lessThan: number): Validator =>
  (value) => {
    if (isClientSideValidationDisabled()) {
      return undefined;
    }

    if (value < lessThan) {
      return undefined;
    }

    return [`Has to be less than ${lessThan}`];
  };

export const numberNonNegativeValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (typeof value === 'number' && value >= 0) {
    return undefined;
  }

  return ['Has to be a non-negative number'];
};

/**
 * Validate that at least 1 field has data in it
 */
export const oneOfManyFieldsRequiredValidator: Validator = (allValues, fields) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  let hasAtLeastOneField = false;
  const fieldsCount = Object.keys(fields).length;

  if (!fields || fieldsCount < 2) {
    return undefined;
  }

  for (let i = 0; i < fieldsCount; i += 1) {
    const fieldValue = _get(allValues, fields[i].path);

    if (fieldValue || fieldValue === 0) {
      hasAtLeastOneField = true;
      break;
    }
  }

  if (hasAtLeastOneField) {
    return undefined;
  }

  const displayFieldNames = fields
    .map((fieldName) => {
      if (fieldName.display) {
        return fieldName.display;
      }

      const fieldNamePathParts = fieldName.path.split('.');
      return fieldNamePathParts[fieldNamePathParts.length - 1];
    })
    .join(', ');

  return [`At least one of ${displayFieldNames} is required`];
};

export const phoneNumberLengthValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (value && value.number && digitsOnly(value.number).length >= 7 && digitsOnly(value.number).length <= 16) {
    return undefined;
  }

  return ['Phone number must be 7-16 digits long'];
};

/**
 * Validation function for selecting approvers in multi-level approvals
 * @param {Array|null|undefined} value
 * @return {string[]|undefined}
 */
export const approverRequiredValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (hasLength(value)) {
    return undefined;
  }

  return ['At least one approver is required'];
};

/**
 * Checks if the phone number object in redux-form has a number filled in. All of our other fields in the app are flat,
 * where as phoneNumber is an object. {} is == truthy, so our normal required validator won't throw an error here.
 * @function
 * @example
 * const phoneNumber = { country: 'US', number: undefined };
 * phoneNumberRequiredValidator(phoneNumber); // ['Required']
 * requiredValidator(phoneNumber); // undefined
 * const phoneNumber = { country: 'US', number: '+1' };
 * phoneNumberRequiredValidator(phoneNumber); // undefined
 * @example
 * @param {PhoneNumber} value
 * @returns {string[]|undefined}
 */
export const phoneNumberRequiredValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (value && value.number) {
    return undefined;
  }

  return ['Required'];
};

/**
 * Returns error message if currentPassword matches the new passwords.
 */
export const isCurrentPasswordReusedValidator: Validator = (currentPasssword, { form }) =>
  currentPasssword === form.newPassword || currentPasssword === form.newPasswordConfirm
    ? ["The new password can't match the current password."]
    : undefined;

/**
 * Returns error message if given input matches the currentPassword.
 */
export const isNewPasswordSameValidator: Validator = (newPassword, { form }) =>
  newPassword === form.currentPassword ? ["The new password can't match the current password."] : undefined;

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 */
export const requiredValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (isString(value) && value.trim()) {
    return undefined;
  }

  if (isNum(value)) {
    return undefined;
  }

  if (isArray(value) && hasZeroLength(value)) {
    return ['Required'];
  }

  if (!isString(value) && !isNum(value) && value) {
    return undefined;
  }

  return ['Required'];
};

/**
 * Validation function for confirm account number field.
 * @param {string} value
 * @param {Object} allValues
 * @return {string[]|undefined}
 */
export const sameAccountNumberValidator: Validator = (value, allValues) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  // This validator applies to multiple forms which have a different structure
  const accountNumber = ternary(
    allValues?.form?.bankAccount,
    allValues?.form?.bankAccount?.bankAccountNumber,
    allValues?.form?.bankAccountNumber,
  );

  return ternary(isEqual(value, accountNumber), undefined, ['Account numbers do not match!']);
};

export const slugValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  const regex = /^[A-Za-z0-9]+(?:[_-][A-Za-z0-9]+)*$/;

  if (!regex.test(value)) {
    return ['Must contain letters or numbers. Can be separated by underscores or hyphens.'];
  }

  return undefined;
};

export const subdomainValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  const regex = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;

  if (value.length > MAX_SUBDOMAIN_LENGTH) {
    return [`Your entry can't exceed ${MAX_SUBDOMAIN_LENGTH} characters.`];
  }

  if (!regex.test(value)) {
    return ['You can only enter letters, numbers, or hyphens.'];
  }

  return undefined;
};

/**
 * Terms of service (and privacy policy) validator
 */
export const termsOfServiceValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (value === true) {
    return undefined;
  }

  return ['You must agree to the Terms of Service to continue.'];
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Creates a field validator that tests the given value (as value.toString()) against the provided regular expression.
 * This function accepts a regular expression as a string and an error message, and returns the validator function.
 */
export const createRegExpValidator =
  (regExpString: string, errorMessage: string): Validator =>
  (value) => {
    const regExp = new RegExp(regExpString);

    const stringValue = value?.toString?.();

    if (!regExp.test(stringValue)) {
      return [errorMessage];
    }

    return undefined;
  };

export const SSNValidator: Validator = (value) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (value && digitsOnly(value).length === 9) {
    return undefined;
  }

  return ['SSN must be 9 digits long'];
};

/**
 * Generic version of SSNValidator and EINValidator, but needs to be memoized before use.
 * @example
 * const SomeComponent = (props) => {
 *   const ssnValidator = React.useMemo(() => TINValidator('SSN'), []);
 *
 *   return (
 *     <Field
 *       name="form.membership.personalInfo.ssn"
 *       validators={[requiredValidator, ssnValidator]}
 *     >
 *   );
 * };
 */
export const TINValidator =
  (tinType: string): Validator =>
  (value) => {
    if (isClientSideValidationDisabled()) {
      return undefined;
    }

    if (value && digitsOnly(value).length === 9) {
      return undefined;
    }

    return [`${tinType} must be a 9-digit number`];
  };

/**
 * Method that build a password validator that ensures users enters the same password twice
 */
export const getPasswordMatchingValidator =
  (fieldNames: string[]): Validator =>
  (_value, allValues) => {
    if (isClientSideValidationDisabled()) {
      return undefined;
    }

    // Setup improperly
    if (!isArray(fieldNames)) {
      return undefined;
    }

    // Create an array of the values to test
    const passwordValues = fieldNames.map((field) => allValues[field]);

    // Ensure all values are filled (otherwise it throws before you typed anything)
    if (passwordValues.some((val) => val === undefined)) {
      return undefined;
    }

    if (areAllEqual(passwordValues)) {
      return undefined;
    }

    return ['Passwords do not match'];
  };

/**
 * Accepts an error message argument, and creates a field validator that
 * checks the given field value, and if not an object returns undefined,
 * or if an object, returns an error only if no property values are truthy.
 */
export const getAnyTruthyPropertyValueValidator =
  (message: string): Validator =>
  (value) => {
    if (isClientSideValidationDisabled()) {
      return undefined;
    }

    if (!isObject(value)) {
      return undefined;
    }

    if (anyValues(value)) {
      return undefined;
    }

    return [message];
  };

/**
 * Accepts options arg containing the error message if validation fails, and the minLength
 * to check against. Returns a validator function.
 */
export const createMinLengthValidator: CreateMinLengthValidator =
  ({ message, minLength = 1 } = {}): Validator =>
  (value) => {
    if (isGreaterOrEqual(lengthOf(value), minLength)) {
      return undefined;
    }

    return [message];
  };

/**
 * Validates if value contains special characters
 * @param value - String
 */
export const specialCharactersValidator: Validator = (value) => {
  const FORBIDDEN_CHARS_REGEX = /[<>="“”`!?%~${}[\]\\]/;
  if (value && !FORBIDDEN_CHARS_REGEX.test(value)) {
    return undefined;
  }

  return ['Ensure this field has no special characters - <>="“”`!?%~${}[]\\`'];
};

/**
 * Configures the validator functions for create/update contact form fields.
 * @type {Object.<string, Function[]|Function>}
 */
export const partnershipMemberContactFormValidators = {
  /**
   * Require valid email always.
   * @type {Function[]}
   */
  email: [
    requiredValidator,
    emailValidator,
    notYourEmailValidator,
    notYourTeamMemberEmailValidator,
    notAddedInCurrentFormEmailValidator,
    notAnExistingPartnershipMemberEmailValidator,
  ],

  /**
   * Validate email if there's a value for it.
   * @type {Function}
   */
  emailOptional: conditionalValidator(
    (value: string) => Boolean(value),
    [
      emailValidator,
      notYourEmailValidator,
      notYourTeamMemberEmailValidator,
      notAddedInCurrentFormEmailValidator,
      notAnExistingPartnershipMemberEmailValidator,
    ],
  ),

  /**
   * Require a value if and check the format, if the user has provided a value or if name fields are explicitly required.
   * @type {Function}
   */
  name: conditionalValidator(
    (value, allValues) => Boolean(value || _get(allValues, partnershipMemberContactFormFields.UI_REQUIRE_NAME_FIELDS)),
    [requiredValidator, specialCharactersValidator],
  ),

  /**
   * Require defaultGeneral always.
   * @type {Function[]}
   */
  defaultGeneral: [requiredValidator],
  /**
   * Require defaultItem always.
   * @type {Function[]}
   */
  defaultItem: [requiredValidator],
  /**
   * Require length and valid-for-country on phone number, if a phone number was entered.
   * @type {Function}
   */
  phoneNumber: conditionalValidator(
    (value) => Boolean(_get(value, 'number')),
    [phoneNumberLengthValidator, internationalPhoneNumberValidator],
  ),
  /**
   * Require the checkbox if a already associated email is provided
   * @type {Function}
   */
  alreadyAssociatedEmailCheck: requiredValidator,
};

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 */
export const createFreeformDatetimeValidator: CreateFreeformDatetimeValidator =
  ({ allowEmpty, dateElements }) =>
  (value) => {
    const includesDate = dateElements.includes(DateElement.date);
    const includesTime = dateElements.includes(DateElement.time);

    let testValid;

    if (allowEmpty && !value) {
      testValid = true;
    } else if (includesDate && includesTime) {
      testValid = regexp.DATE_TIME.test(value);
    } else if (includesDate) {
      testValid = or(regexp.DATE_ONLY.test(value), regexp.ISO_DATE_ONLY.test(value));
    } else {
      testValid = regexp.TIME_ONLY.test(value);
    }

    if (testValid) {
      return undefined;
    }

    // dateElements.join('') will give us one of 'date', 'time', or 'datetime'
    return [`Please enter a valid ${dateElements.join('')}`];
  };

/**
 * Configures the validator functions for adding a team member form fields.
 * @type {Object.<string, Function[]|Function>}
 */
export const inviteTeamMemberFormValidators = {
  /**
   * Require valid email always.
   * @type {Function[]}
   */
  EMAIL: [requiredValidator, emailValidator, cantAddYourselfAsTeamMemberValidator],
};

export const urlValidator: Validator = (value: string) => {
  if (isClientSideValidationDisabled()) {
    return undefined;
  }

  if (!URL.test(value)) {
    return ['Must be a full URL starting with http(s)://'];
  }

  return undefined;
};

/**
 * Validator for comparing Vendor's selected country and the country added to the address object
 * @param value - Selected country code
 * @param allValues - All form values
 */
export const vendorCountryAndAddressCountryMatchValidator: Validator = (value, allValues) => {
  const { address } = Object(allValues);

  // we don't want to do any validation if:
  // - we don't have selected country yet
  // - we don't have the address object
  // - the address object is not a full address object (i.e. the address is still being added to the form)
  if (!value || !anyValues(address) || !isFullAddress(address)) {
    return undefined;
  }

  // If the selected country and the address.country do not match, return error
  if (value !== address.country) {
    return [
      "This country does not match this vendor's registered address. Change the country or remove the registered address.",
    ];
  }

  // Otherwise, return nothing
  return undefined;
};

/**
 * Validator for checking if string has at least 1 letter
 * @param value - String
 */
export const hasLettersValidator: Validator = (value) => {
  if (value && /[a-zA-Z]/.test(value)) {
    return undefined;
  }

  return 'Must contain at least 1 letter';
};

/**
 * Validator for checking if string has at least 1 upper case letter
 * @param value - String
 */
export const hasUpperLettersValidator: Validator = (value) => {
  if (value && /[A-Z]/.test(value)) {
    return undefined;
  }

  return [hintErrors[availableRequirementValidators.HAS_UPPER_LETTER]];
};

/**
 * Validator for checking if string has at least 1 lower case letter
 * @param value - String
 */
export const hasLowerLettersValidator: Validator = (value) => {
  if (value && /[a-z]/.test(value)) {
    return undefined;
  }

  return [hintErrors[availableRequirementValidators.HAS_LOWER_LETTER]];
};

/**
 * Validator for checking if string has at least 1 number
 * @param value - String
 */
export const hasNumbersValidator: Validator = (value) => {
  if (value && /[0-9]/.test(value)) {
    return undefined;
  }

  return [hintErrors[availableRequirementValidators.HAS_NUMBERS]];
};

/**
 * Validator for checking if the company name has valid characters
 * @param value - String
 */
export const companyNameValidator: Validator = (value) => {
  if (value && !BUSINESS_NAME_VALIDATION_REGEX.test(value)) {
    return undefined;
  }

  return ['Ensure this field has no special characters: <>="`!?%~${}\\'];
};

/**
 * Warning validator for when we have email warnings present in the form's
 * meta property.
 * @param _
 * @param values - All form values
 */
export const shouldWarnEmail: Validator = (_, values) =>
  _get(values, partnershipMemberContactFormFields.META_WARNINGS_EMAIL);

/**
 * Function to validate a minimum percentage of ownership, which is 25%.
 * @param {string} value
 */
export const ownershipPercentageValidator: Validator = (value: string) => {
  const floatValue = parseFloat(value);

  if (floatValue < 25) {
    return ['Minimum percentage of ownership is 25%'];
  }

  if (floatValue > 100) {
    return ['Maximum percentage of ownership is 100%'];
  }

  return undefined;
};
