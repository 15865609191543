import { CompaniesListCellDataKeys } from 'constants/tables';

import { ItemAmountKey } from 'enums/items';

import { isItemAmountZero, isItemStatusCanceled } from 'helpers/items';

import type { Item } from 'interfaces/item';

import { AmountTextProp, AmountTextProps } from '../constants';

/**
 * Returns props to transform text based on given rowData
 */
const getAmountProps = (rowData: Item, dataKey: ItemAmountKey): AmountTextProp => {
  const isAmountZero = isItemAmountZero(rowData, dataKey);
  const isItemCanceled = isItemStatusCanceled(rowData);
  const isOutstanding = dataKey === CompaniesListCellDataKeys.OutstandingSum;

  if (isItemCanceled) {
    return AmountTextProps.CancelledItem;
  }

  if (isAmountZero) {
    return AmountTextProps.ZeroAmount;
  }

  if (isOutstanding && !isAmountZero) {
    return AmountTextProps.OutstandingAmount;
  }

  return AmountTextProps.Default;
};

export default getAmountProps;
