import React from 'react';

import { LinkNewPage, WhiteSpace } from 'components';
import BaseHint from 'components/hintTypes/BaseHint';

import { SETTINGS_ACCOUNT_BALANCE_ROUTE } from 'constants/routes';
import { TooltipPlacement } from 'constants/tooltip';
import { Intent } from 'constants/ui';

import helpDocs from 'helpers/helpDocs';
import { parseCurrency } from 'helpers/numbers';

import ConditionalRestrictedPermissionTooltip from 'modules/auth/ConditionalRestrictedPermissionTooltip';

import { BulkActionBalanceTooLowHintProps } from './BulkActionBalanceTooLowHint.types';

/**
 * Hint when amount of bulk action send is greater than current Routable balance for Same Day/Next Day Payments
 */
const BulkActionBalanceTooLowHint: React.FC<BulkActionBalanceTooLowHintProps> = ({
  amount,
  isDisabledDueToPermissions,
}) => (
  <BaseHint
    content={
      <React.Fragment>
        <p className="hint--text remove-margin-bottom font-color--black">
          Your total bill amount for Same Day and Next Day ACH payments exceeds your available balance.
          <WhiteSpace />
          {/* eslint-disable-next-line max-len */}
          You may update the affected payments to Expedited ACH delivery option by editing or sending them individually.
          <WhiteSpace />
          <LinkNewPage
            className="font-sm font-weight--regular text-decoration--none font-color--blue-bold"
            href={helpDocs.BALANCE_TRANSACTIONS_ABOUT}
          >
            Learn more about balance transactions.
          </LinkNewPage>
        </p>
        <br />
        <p className="hint--text display--flex white-space-pre-wrap remove-margin-bottom">
          To avoid this issue in the future,
          <WhiteSpace />
          <ConditionalRestrictedPermissionTooltip
            customization={{ placement: TooltipPlacement.TOP }}
            useTooltip={isDisabledDueToPermissions}
          >
            <LinkNewPage
              className="font-sm font-weight--regular text-decoration--none font-color--blue-bold"
              href={SETTINGS_ACCOUNT_BALANCE_ROUTE}
            >
              add funds to your balance.
            </LinkNewPage>
          </ConditionalRestrictedPermissionTooltip>
        </p>
      </React.Fragment>
    }
    intent={Intent.DANGER}
    isCollapsed
    isCollapsible
    multiline
    title={`Unselect ${parseCurrency(amount)} in Same Day and Next Day ACH payments to proceed`}
  />
);

export default BulkActionBalanceTooLowHint;
