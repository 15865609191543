import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParam } from 'react-use';
import { z } from 'zod';

import { useSubmitW8BenMutation, useSubmitW8BenEMutation } from 'ducks/routableApi/partnership';

import { updateCompany } from 'actions/companies';
import { externalOnboardingIncrementStep } from 'actions/externalOnboarding';
import { setW8FormIsSubmittingState, setW8FormIsValidState } from 'actions/partnershipRequests';
import { showErrorUi } from 'actions/ui';

import {
  TaxFormRenderer,
  taxCertificationType,
  taxFormEntryType,
  taxFormId,
  W8FormModel,
  taxFormType,
} from 'complexComponents';

import { ErrorIndicatorMessages } from 'constants/ui';

import { isCompanyPlatformTypeInInviteStage, isCompanyTypePersonal } from 'helpers/currentCompany';

import type { Company } from 'interfaces/company';

import { companyFromQuerySelector } from 'queries/companyCompoundSelectors';
import { fundingAccountsForCompanyIdQuerySelector } from 'queries/fundingCompoundSelectors';

import { partnershipRequestMembershipIdSelector } from 'selectors/partnershipRequestSelectors';

import { payloadToCamelCase } from 'services/api/formatHelpers';

import { getDerivedApiFieldErrors } from '../taxForms.helpers';

/**
 * Wrapper around TaxFormRenderer, providing needed context from the state
 * and API functionality
 */
export const SubstituteW8Form = (): JSX.Element => {
  // Search params hooks
  const partnershipId = useSearchParam('partnership_id');

  // API hooks
  const [submitW8Ben] = useSubmitW8BenMutation();
  const [submitW8BenE] = useSubmitW8BenEMutation();

  // Redux hooks
  const membershipId = useSelector(partnershipRequestMembershipIdSelector);
  const company: Company = useSelector(companyFromQuerySelector);
  const fundingAccounts = useSelector(fundingAccountsForCompanyIdQuerySelector);
  const dispatch = useDispatch();

  const isPlatformTypeInInviteStage = isCompanyPlatformTypeInInviteStage(company?.platformType);

  // When an international vendor has a funding account we don't want to display the company type inputs
  // This is done this way to prevent an error in CurrencyCloud
  const shouldShowPartnerCompanyType = isPlatformTypeInInviteStage && !fundingAccounts?.length;

  const handleFormSubmit = async (values: W8FormModel): Promise<void | Record<string, string>> => {
    try {
      // Since we're dealing with query params & loose redux state values,
      // we first want to make sure that the partnership and membership ids
      // are in fact UUIDs. If they're not, we want to throw an error before
      // making any API calls.
      const parsedMembershipId = z.string().uuid().parse(membershipId);
      const parsedPartnershipId = z.string().uuid().parse(partnershipId);

      // When dealing with individuals, we want to submit to the W-8BEN endpoint. When dealing with
      // businesses, we want to submit to the W-8BEN-E endpoint.
      const submit = isCompanyTypePersonal(values.companyType) ? submitW8Ben : submitW8BenE;

      // We submit the form via the API and await for the API resolution. This
      // will ensure that the form remains in "submitting" state for as long as
      // we are waiting for the API response.
      const data = await submit({
        membershipId: parsedMembershipId,
        partnershipId: parsedPartnershipId,
        values,
      }).unwrap();

      // Finally, if everything went well, we want to increment the
      // external onboarding step and update the current company values with
      // the data received. Since the component will unmount before
      // it will receive form submitting state change, we also need to
      // manually set it to false.
      dispatch(updateCompany(data));
      dispatch(externalOnboardingIncrementStep());
      dispatch(setW8FormIsSubmittingState(false));
    } catch (err) {
      const errors = getDerivedApiFieldErrors(err);

      // If we have a general error, we want to use that message in the
      // error toast. Otherwise, we use the generic error message.
      if (errors?.general) {
        dispatch(showErrorUi(errors.general.detail));
      } else {
        dispatch(showErrorUi(ErrorIndicatorMessages.AN_ERROR_OCCURRED));
      }

      // If we have field-level errors, we want to transform
      // the field names from snake_case to camelCase and return the
      // errors, so the form renderer can use them to set errors
      // internally.
      if (errors?.fields) {
        return payloadToCamelCase(errors.fields);
      }
    }

    // If there are no errors, we return null from this handler.
    return null;
  };

  const handleFormValidStateChange = (isValid: boolean) => {
    dispatch(setW8FormIsValidState(isValid));
  };

  const handleFormSubmitStateChange = (isSubmitting: boolean) => {
    dispatch(setW8FormIsSubmittingState(isSubmitting));
  };

  return (
    <TaxFormRenderer
      defaultValues={{
        certificationType: isCompanyTypePersonal(company?.companyType)
          ? taxCertificationType.Enum.signedElectronically
          : taxCertificationType.Enum.uploadSignedPDF,
        claimingTreatyBenefits: false,
        mailingAddressMatchPermanentAddress: true,
        companyType: company?.companyType,
      }}
      entryType={taxFormEntryType.Enum.external}
      formId={taxFormId.Enum.ext_substituteW8Form}
      formType={taxFormType.Enum.W8}
      onFormSubmitStateChange={handleFormSubmitStateChange}
      onFormValidStateChange={handleFormValidStateChange}
      onSubmit={handleFormSubmit}
      showCompanyTypeField={shouldShowPartnerCompanyType}
    />
  );
};
