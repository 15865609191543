import React from 'react';

import { BoxV2, ButtonV2, ContactUs, IconNames, InputField, Text, WhiteSpace } from 'components';

import { ButtonSize } from 'constants/button';
import { formStyles, sizes, typography } from 'constants/styles';

import { VerifyEmailProps } from './VerifyEmail.types';

/**
 * Verify email form
 */
const VerifyEmail: React.FC<VerifyEmailProps> = ({
  errors,
  formState,
  isSubmitting,
  lastSubmittedVerificationEmail,
  onFormSubmit,
  onInputChange,
  onSubmitResendVerificationEmail,
}) => (
  <div className="branded--contents">
    <BoxV2
      subtitle="We've sent a six-digit confirmation code to your email address. It will expire in 30 minutes, so enter your code soon."
      title="Please check your email"
    >
      <form className="form" id="signup-verify-email-form">
        <p className="font-regular regular font-color--greyXDark remove-margin-bottom" />

        <div className="form-control margin-top--m">
          <InputField
            className={formStyles.field.xl.full}
            errors={errors}
            maxLength="6"
            name="code"
            onChange={onInputChange}
            placeholder="Verification code"
            setIsRequired
            type="text"
            value={formState.code}
          />
        </div>

        <div className="form-control remove-margin-bottom">
          <ButtonV2
            isLoading={isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              onFormSubmit();
            }}
            rightIconName={IconNames.ARROW_RIGHT}
            rightIconProps={{ style: { marginLeft: 'auto' } }}
            rightIconSize={sizes.iconSizes.LARGE}
            size={ButtonSize.LARGE}
          >
            Continue
          </ButtonV2>
        </div>
      </form>

      {!lastSubmittedVerificationEmail && (
        <Text.Regular
          className="display--block margin-top--larger"
          color={typography.TextColor.GREY_XX_DARK}
          lineHeight={typography.TextLineHeight.LARGE}
          size={typography.TextSize.LEVEL_200}
        >
          Didn&apos;t receive your code?
          <WhiteSpace />
          <a
            className="font-color--blue-bold--important font-weight--bold"
            onClick={onSubmitResendVerificationEmail}
            role="presentation"
          >
            Click here
          </a>
          <WhiteSpace />
          to have us send a new one.
        </Text.Regular>
      )}

      {lastSubmittedVerificationEmail && (
        <Text.Regular
          className="display--block margin-top--larger"
          color={typography.TextColor.GREY_XX_DARK}
          lineHeight={typography.TextLineHeight.LARGE}
          size={typography.TextSize.LEVEL_200}
        >
          <span className="icon-ic-checkmark bold font-color--main-aqua margin-right--sm" />
          We sent a new verification code. Didn&apos;t receive it?
          <WhiteSpace />
          <ContactUs />
        </Text.Regular>
      )}
    </BoxV2>
  </div>
);

VerifyEmail.defaultProps = {
  errors: {},
};

export default VerifyEmail;
