import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { formNamesAuthV2 } from 'constants/forms';

import { UnknownObject } from 'interfaces/global';

import { isSSOFetchingSelector } from 'selectors/authSelectors';

import NamespaceForm from './NamespaceForm';
import { NamespaceFormProps } from './NamespaceForm.types';

export const mapStateToProps = createStructuredSelector({
  isSSOFetching: isSSOFetchingSelector,
});

const NamespaceFormContainer = reduxForm<UnknownObject, NamespaceFormProps>({
  destroyOnUnmount: false,
  form: formNamesAuthV2.NAMESPACE,
})(NamespaceForm);

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(NamespaceFormContainer);
