import { Action as ItemAction } from 'interfaces/actions';
import { Reducer } from 'interfaces/reducers';

import { OPEN_ITEM_HAS_BEEN_UPDATED_SWAL, RESET_ITEM_HAS_BEEN_UPDATED_SWAL } from 'types/item';

interface State {
  itemsWithUpdatedVersion: { string?: boolean };
}

interface ItemHasBeenUpdatedSwalAction extends ItemAction {
  payload: {
    itemId: string;
  };
}

type Action = ItemHasBeenUpdatedSwalAction;

export const initialState = {
  itemsWithUpdatedVersion: {},
};

const itemEditAlertsReducer: Reducer<State, Action> = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case OPEN_ITEM_HAS_BEEN_UPDATED_SWAL: {
      return {
        ...state,
        itemsWithUpdatedVersion: {
          ...state.itemsWithUpdatedVersion,
          [action.payload.itemId]: true,
        },
      };
    }

    case RESET_ITEM_HAS_BEEN_UPDATED_SWAL: {
      return {
        ...state,
        itemsWithUpdatedVersion: {
          ...state.itemsWithUpdatedVersion,
          [action.payload.itemId]: false,
        },
      };
    }

    default:
      return state;
  }
};

// @ts-ignore
export default itemEditAlertsReducer;
