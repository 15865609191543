import { LOCAL_STORAGE_KEY_ITEM_QUEUEING } from 'constants/debug/bulkActions';

import { localStorageGet, localStorageSet } from 'helpers/localStorage';

export const getIsItemQueueingOnFromLocalStorage = (): boolean =>
  JSON.parse(localStorageGet(LOCAL_STORAGE_KEY_ITEM_QUEUEING) || 'false');

export const toggleItemQueueingInLocalStorage = (): void => {
  localStorageSet(LOCAL_STORAGE_KEY_ITEM_QUEUEING, `${!getIsItemQueueingOnFromLocalStorage()}`);
};
