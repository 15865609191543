import { ItemFiltersByLabel } from 'constants/itemFilters';

import { ItemFilterLabels } from 'enums/itemFilters';

import { getQueryParamsFromUrl, getQueryString } from 'helpers/queryParams';

import { FilterList, ItemStatusFilter, UIItemFilter } from 'interfaces/itemFilters/filter';

import { filterToUrlParams } from 'modules/itemFilters/filterToUrlParams';

/**
 * Takes the query part of the url (should come from location.search)
 * keeps any non filter parameters, and adds parameters from the filterlist
 */
export const getQueryStringFromFilterList = (urlQuery: string, filterList: FilterList): string => {
  // strips starting '?'
  const queryPart = urlQuery.replace(/^\?+(.+)/, '$1');

  const currentParams = getQueryParamsFromUrl(queryPart);
  const itemFilterUrlParams = Object.values(ItemFiltersByLabel).map<string>((filter: UIItemFilter) => filter.urlParam);

  // add all non-filter parameters to the new params object
  const nonFilterParams: Record<string, unknown> = Object.entries(currentParams || {})
    // remove all entries that do not start with an item filter urlParam
    .filter(([key]) => !itemFilterUrlParams.some((param) => key.indexOf(param) === 0))
    // map to a Record<string, string> to easly merge in the following reducer
    .map(([key, obj]) => ({ [key]: obj }))
    // group into an object
    .reduce((agg, add) => ({ ...agg, ...add }), {});

  // add all parameters from the filterList
  const newParams = filterList
    // remove item status filter
    .filter((filter) => filter.label !== ItemFilterLabels.ITEM_STATUS)
    // merge into newParams variable
    .reduce(
      (cur, filter) => ({
        ...cur,
        ...filterToUrlParams(filter as ItemStatusFilter),
      }),
      nonFilterParams,
    );

  return getQueryString(newParams);
};

/**
 * Updates the browser url with needed params from the filterList
 */
export const persistFilterListToURL = (filterList: FilterList, window: Window = global.window): void => {
  const lastPathSection = window.location.pathname.split('/').pop();
  const hashPart = window.location.hash;
  const newQuery = getQueryStringFromFilterList(window.location.search, filterList);
  const urlUpdate = `${lastPathSection}?${newQuery}${hashPart}`;

  // Does an in-place update of the url without re-triggering the react router or reloading the page
  // it updates from the last path section onwards
  window.history.replaceState(null, '', urlUpdate);
};
