import React from 'react';

import { Hint, Text } from 'components';

import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';

import './MicroDepositsReadyHint.scss';

/**
 * Renders hint with text explaining that micro deposits are ready to be
 * verified. Meant to be used in External workflow.
 */
const MicroDepositsReadyHint: React.FC = () => (
  <Hint className="micro-deposits-ready-hint" intent={Intent.INFO} multiline>
    <Text.Semibold color={typography.TextColor.BLUE_BOLD} size={typography.TextSize.LEVEL_200}>
      You can verify your micro-deposits
    </Text.Semibold>
    <Text.Semibold color={typography.TextColor.BLACK} size={typography.TextSize.LEVEL_200}>
      It looks like your micro-deposits are ready, continue to verify your new Bank Transfer account.
    </Text.Semibold>
  </Hint>
);

export default MicroDepositsReadyHint;
