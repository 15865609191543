import { Options } from 'actions/routines/createApiRoutine';

import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getSplitBillEndpoint } from 'services/api/itemEndpoints';

/**
 * Split a bill.
 */
export async function postItemSplitBill(
  itemId: string,
  attachmentId: string,
  options: Partial<Options> = {},
): Promise<any> {
  const config = {
    ...options,
    endpoint: getSplitBillEndpoint(itemId),
    method: 'POST',
    payload: payloadToJSONAPI({ id: itemId, attachment: { id: attachmentId } }, 'item', {
      kind: 'ItemAttachment',
      name: 'attachment',
    }),
    requireAuth: true,
    setRequester: true,
  };
  return FetchService.request(config);
}
