import { useSelector } from 'react-redux';

import { BRANDED_WORKSPACE_REDIRECT_QUERY_PARAM_KEY } from 'helpers/constants';
import { getCurrentMembershipData, getCurrentMembershipId } from 'helpers/localStorage';
import { getCurrentUrlPath, getDomainWithOrWithoutCompanyNamespace } from 'helpers/urls';
import { isEmptyObject } from 'helpers/utility';

import { currentCompanyNamespaceSelector } from 'selectors/currentCompanySelectors';

const RedirectToBrandedWorkspace = (): null => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);

  const namespace = useSelector(currentCompanyNamespaceSelector);
  const brandedWorkspace = `${getDomainWithOrWithoutCompanyNamespace(namespace)}`;

  const membershipData = getCurrentMembershipData();
  if (!isEmptyObject(membershipData)) {
    const encodedData = encodeURI(
      JSON.stringify({
        ...membershipData,
        userAuthToken: membershipData.authToken,
        membershipId: getCurrentMembershipId(),
      }),
    );
    searchParams.set(BRANDED_WORKSPACE_REDIRECT_QUERY_PARAM_KEY, encodedData);
  }

  window.location.replace(`${brandedWorkspace}${getCurrentUrlPath()}?${searchParams.toString()}`);

  return null;
};

export default RedirectToBrandedWorkspace;
