import classNames from 'classnames';
import React from 'react';

import { ButtonV2 } from 'components';

import { TwoLineButtonProps } from './TwoLineButton.types';

import './TwoLineButton.scss';

/**
 * Component rendering ButtonV2 component with two lines of text - title
 * and description. Meant as a "generic" two-line button which can be used
 * for different usecases.
 * @param {TwoLineButtonProps} props
 * @returns {StatelessComponent}
 */
const TwoLineButton: React.FC<TwoLineButtonProps> = ({ className, title, description, ...rest }) => (
  <ButtonV2 className={classNames('two-line-button', { [className]: !!className })} {...rest}>
    <div className="two-line-button__content">
      <span className="two-line-button__title">{title}</span>
      <span className="two-line-button__description">{description}</span>
    </div>
  </ButtonV2>
);

export default TwoLineButton;
