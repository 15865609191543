import React from 'react';

import ExternalLink from 'components/ExternalLink';
import { FormControl } from 'components/form';
import { text } from 'components/hintTypes/constants';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

/**
 * Hint notifying the user that the total amount is below the RTP transfer limit
 * @param {AmountAboveRtpTransferLimitHintProps} props
 * @returns {StatelessComponent}
 */
const AmountTransactionMinimumHint: React.FC = () => (
  <FormControl>
    <BaseHint
      className="margin-top--m"
      content={
        <ExternalLink
          className="primary"
          href="https://docs.routable.com/articles/3483833-bank-transfer-transaction-limits"
        >
          {text.amountTransactionMinimumHint.learnMore}
        </ExternalLink>
      }
      contentProps={{ className: 'margin-top--m' }}
      intent={Intent.DANGER}
      multiline
      text="Payments require a minimum transfer amount."
      title={text.amountTransactionMinimumHint.title}
      titleProps={{ className: 'font-color--red--bold--important' }}
    />
  </FormControl>
);

export default AmountTransactionMinimumHint;
