import React from 'react';

import { anyValues } from 'helpers/utility';

import TooltipMUI from '../TooltipMUI';

import type { TooltipMUIConditionalWrapperProps } from './TooltipMUIConditionalWrapper.types';

/**
 * Wrap the children in a TooltipMUI if the user supplies tooltipProps. Otherwise, just return the children.
 *
 * @deprecated use the @routable/components tooltip
 */
const TooltipMUIConditionalWrapper = ({
  children,
  tooltipProps,
}: TooltipMUIConditionalWrapperProps): React.ReactElement =>
  anyValues(tooltipProps) ? <TooltipMUI {...tooltipProps}>{children}</TooltipMUI> : <>{children}</>;

export default TooltipMUIConditionalWrapper;
