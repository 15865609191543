import React from 'react';

import { ModalNavTab } from 'components';
import FullPageModal from 'components/modal/FullPageModal';

import { ZIndexLayers } from 'constants/ui';

import { getZIndex } from 'helpers/zIndex';

import QuickswitchSplashscreenBody from './QuickswitchSplashscreenBody';

import './QuickswitchSplashscreen.scss';

export interface QuickswitchSplashscreenProps {
  companyName: string;
}

const QuickswitchSplashscreen: React.FC<QuickswitchSplashscreenProps> = ({ companyName }) => (
  <FullPageModal
    body={<QuickswitchSplashscreenBody companyName={companyName} />}
    contentLabel="Quickswitch splashcreen"
    header={<ModalNavTab tabText="Switch Routable account" />}
    modalClassname="quickswitch-splashscreen"
    modalState
    overrideStyle={{
      overlay: { zIndex: getZIndex(ZIndexLayers.MODAL.OVERLAY, 25) },
    }}
  />
);

export default QuickswitchSplashscreen;
