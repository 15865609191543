import { getDomainOnly } from 'helpers/urls';

/**
 * Helper for setting a cookie in the browser
 */
export const setCookie = (name: string, value: string, sameSite = 'lax', timeInMinutes = 15): void => {
  const d = new Date(Date.now());
  d.setTime(d.getTime() + timeInMinutes * 60 * 1000);

  const expires = `expires=${d.toUTCString()}`;
  const sameSiteString = `samesite=${sameSite}`;
  const domain = getDomainOnly();
  const domainString = `domain=${domain}`;

  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value,
  )};${expires};${domainString};${sameSiteString};secure;path=/`;
};

/**
 * Helper for reading a cookie value
 */
export const getCookie = (name: string): string => {
  // Gets the cookie value by name, source: https://javascript.info/cookie#appendix-cookie-functions
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
    ),
  );

  return matches ? decodeURIComponent(matches[1]) : undefined;
};

/**
 * Helper for removing a cookie by name
 */
export const removeCookie = (name: string): void => {
  document.cookie = `${encodeURIComponent(name)}=;max-age=-1;path=/`;
};
