import * as routines from 'actions/routines/purposeCode';

import { createAllIdsReducer } from 'store/redux';

const allIdsReducer = createAllIdsReducer({
  key: 'purposeCode',
  replaceState: true,
  types: [routines.fetchPurposeCodeRoutine.SUCCESS],
});

export default allIdsReducer;
