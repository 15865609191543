import React from 'react';

import { textHelpers } from 'components/hintTypes/helpers';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

import { EmailAlreadyInvitedHintProps } from './EmailAlreadyInvitedHint.types';
import { EmailAlreadyInvitedHintText } from './EmailAlreadyInvitedHintText';

/**
 * Hint to display information when the typed e-mail was already invited.
 */
const EmailAlreadyInvitedHint = ({ member, onNavigate }: EmailAlreadyInvitedHintProps): JSX.Element => (
  <BaseHint
    intent={Intent.DANGER}
    multiline
    text={<EmailAlreadyInvitedHintText onNavigate={onNavigate} />}
    title={textHelpers.getEmailAlreadyInvitedHintTitle(member)}
  />
);

export default EmailAlreadyInvitedHint;
