import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { PERSISTENCE_KEY } from 'constants/persistence';

import { getFromStore, setInStore } from 'helpers/localStorage';

import { currentMembershipIdSelector } from 'selectors/membershipsSelector';

import { GenerateUsePersistentValue } from './usePersistentValue.types';

// this is exported to make testing of the code easier, this isn't exported in index.ts
export const generateUsePersistentValue: GenerateUsePersistentValue =
  ({ getItem, setItem, membershipIdSelector }) =>
  (id, defaultValue) => {
    const membershipId = useSelector(membershipIdSelector);
    const key = `${PERSISTENCE_KEY}${membershipId}/${id}`;
    const [setting, setSetting] = useState(() => getItem(key, defaultValue));

    const updateSetting = useCallback(
      (updatedVal) => {
        setSetting(updatedVal);
        setItem(key, updatedVal);
      },
      [key],
    );
    useEffect(
      // on key/default change, update current persistence value
      () => setSetting(() => getItem(key, defaultValue)),
      [key, setSetting, defaultValue],
    );

    return [setting, updateSetting];
  };

export const usePersistentValue = generateUsePersistentValue({
  getItem: getFromStore,
  setItem: setInStore,
  membershipIdSelector: currentMembershipIdSelector,
});
