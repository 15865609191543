import React from 'react';

import { ButtonV2, IconNames, InputField } from 'components';

import { formStyles, sizes } from 'constants/styles';

import { ButtonSize } from '../../../constants/button';
import { Intent } from '../../../constants/ui';
import SignUpFormFooter from '../components/SignUpFormFooter';
import SignUpFormHeader from '../components/SignUpFormHeader';
import { sectionHeaderClassname, pClassname } from '../constants/relevantClassnames';

import { VerifyEmailProps } from './VerifyEmail.types';

/**
 * Verify email form
 */
const VerifyEmail: React.FC<VerifyEmailProps> = ({ errors, formState, isSubmitting, onFormSubmit, onInputChange }) => (
  <div className="submodule-container">
    <div className="form-container">
      <SignUpFormHeader hasSignInOption={false} />

      <form className="form" id="signup-verify-email-form">
        <h2>Please check your email</h2>

        <p className={sectionHeaderClassname}>Add your verification code</p>
        <p className={pClassname}>
          We have sent you a six-digit confirmation code to your e-mail. It will expire in 30 minutes, so enter your
          code soon.
        </p>
        <div className="form-control margin-top--m">
          <InputField
            className={formStyles.field.xl.full}
            dataTestId="code"
            errors={errors}
            maxLength="6"
            name="code"
            onChange={onInputChange}
            placeholder="Verification code"
            setIsRequired
            type="text"
            value={formState.code}
          />
        </div>

        <div className="form-control remove-margin-bottom" id="button-control">
          <ButtonV2
            className="display--table-cell"
            dataTestId="continue--button"
            intent={Intent.PRIMARY}
            isDisabled={!formState.code}
            isLoading={isSubmitting}
            onClick={(e) => {
              if (e) {
                e.preventDefault();
              }
              onFormSubmit();
            }}
            rightIconClassName="margin-left--m"
            rightIconName={IconNames.ARROW_RIGHT}
            rightIconSize={sizes.iconSizes.SMALL}
            size={ButtonSize.MEDIUM}
            type="submit"
          >
            Continue
          </ButtonV2>
        </div>
      </form>
      <SignUpFormFooter />
    </div>
  </div>
);

export default VerifyEmail;
