import classNames from 'classnames';
import React from 'react';

import { HeadingTitle, Text } from 'components/text';

import { TextColor, TextSize } from 'constants/styles/typography';

import { isLtLastIndex } from 'helpers/utility';

import type { SimpleFAQProps } from './SimpleFAQ.types';

/**
 * A simple FAQ-style "list" of labels (questions) and values (answers).
 */
const SimpleFAQ: React.FC<SimpleFAQProps> = ({ className, items }) => (
  <div className={className}>
    {items.map((item, idx) => (
      <div
        className={classNames({
          'margin-bottom--large': isLtLastIndex(idx, items),
        })}
        key={item.question}
      >
        <HeadingTitle className="has-description font-regular">{item.question}</HeadingTitle>

        <Text.Regular className="display--block" color={TextColor.DARK_JORDAN} size={TextSize.LEVEL_300}>
          {item.answer}
        </Text.Regular>
      </div>
    ))}
  </div>
);

export default SimpleFAQ;
