import React from 'react';

import { LinkNewPage, SwalWrapperV2, Text, WhiteSpace } from 'components';
import { IconNames } from 'components/icon';

import { ButtonSize } from 'constants/button';
import { colors, typography } from 'constants/styles';
import { TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';

import { noop } from 'helpers/utility';

import { textHelpers } from '../helpers';

import { CompanyMenuLinkResendPartnershipInviteSwalProps } from './CompanyMenuLinkResendPartnershipInviteSwal.types';

const CompanyMenuLinkResendPartnershipInviteSwal: React.FC<CompanyMenuLinkResendPartnershipInviteSwalProps> = ({
  partnership,
}) => {
  const errorText = textHelpers.getPartnershipInviteSwalErrorText(partnership);

  return (
    <SwalWrapperV2
      iconProps={{
        color: colors.colorRedBoldHex,
        name: IconNames.WARNING_SIGN,
      }}
      rightButtonProps={{
        children: 'Close',
        intent: Intent.DANGER,
        size: ButtonSize.MEDIUM,
        onClick: noop,
      }}
      title="We couldn't send an invite"
    >
      <Text.Regular color={typography.TextColor.BLACK} size={TextSize.LEVEL_200}>
        {errorText}
      </Text.Regular>
      <Text.Regular
        className="display--block line-height--22--important margin-top--xm margin-bottom--xm"
        color={typography.TextColor.GREY_XX_DARK}
        size={typography.TextSize.LEVEL_200}
      >
        <LinkNewPage
          className="primary"
          href="https://docs.routable.com/en/articles/4151187-understanding-contact-types"
        >
          Learn more
        </LinkNewPage>
        <WhiteSpace />
        about types of contacts.
      </Text.Regular>
    </SwalWrapperV2>
  );
};

export default CompanyMenuLinkResendPartnershipInviteSwal;
