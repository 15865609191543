import React from 'react';

import { Table } from 'complexComponents';

import { getExternalItemsListTableData } from './data';
import type { ExternalItemsListProps } from './ExternalItemsList.types';

/**
 * Component rendering Partnership Payment Items table
 */
const ExternalItemsList = ({ itemsKind, partnershipItems = [] }: ExternalItemsListProps) => {
  const columns = getExternalItemsListTableData({ itemsKind });

  // If the length of partnershipItems is 0, we don't want to display anything
  if (!partnershipItems?.length) {
    return null;
  }

  return (
    <div className="external-items-list">
      <Table columns={columns} data={partnershipItems} />
    </div>
  );
};

export default ExternalItemsList;
