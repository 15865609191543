import classNames from 'clsx';
import React from 'react';

import type { FormControlProps } from './FormControl.types';

const FormControl: React.FC<FormControlProps> = ({ children, className, style, ...rest }) => (
  <div className={classNames(className, 'form-control')} style={style} {...rest}>
    {children}
  </div>
);

export default FormControl;
