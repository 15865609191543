import { getFeatureSettingsRoutine } from 'actions/routines/featureSettings';

import { Action } from 'interfaces/actions';

import { initialState } from './initialState';

const dashboardLoadedFeatureSettingsReducer = (state = initialState.featureSettings, action: Action): boolean => {
  switch (action.type) {
    case getFeatureSettingsRoutine.SUCCESS:
      return true;

    default:
      return state;
  }
};

export default dashboardLoadedFeatureSettingsReducer;
