import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ssoHandleRedirectRoutine } from 'actions/routines/auth';

import { getEnvVariable } from 'helpers/env';
import { getCurrentCompanyId } from 'helpers/localStorage';

import { Auth0ProviderWithHistoryProps } from './Auth0ProviderWithHistory.types';

const auth0ApiIdentifier = getEnvVariable('REACT_APP_AUTH0_API_IDENTIFIER');
const auth0ClientId = getEnvVariable('REACT_APP_AUTH0_CLIENT_ID');
const auth0Domain = getEnvVariable('REACT_APP_AUTH0_DOMAIN');

const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onRedirectCallback = React.useCallback(
    (appState: AppState) => {
      if (appState?.companyId && !getCurrentCompanyId()) {
        dispatch(ssoHandleRedirectRoutine.trigger({ companyId: appState.companyId }));
      }
      if (appState?.returnTo) {
        history.replace(appState?.returnTo);
      }
    },
    [history, dispatch],
  );

  return (
    <Auth0Provider
      authorizationParams={{
        audience: auth0ApiIdentifier,
        redirect_uri: `${window.location.origin}/login/`,
        scope: 'openid profile email',
      }}
      cacheLocation="localstorage"
      clientId={auth0ClientId}
      domain={auth0Domain}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      useRefreshTokensFallback
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
