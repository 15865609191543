import React from 'react';

import { BaseHint } from 'components/hintTypes';

import { Intent } from 'constants/ui';

/**
 * Rescheduling approved payments hint
 */
const BulkReschedulingApprovedHint: React.FC = () => (
  <BaseHint intent={Intent.WARNING} title="Rescheduling approved payments will dismiss any completed approvals." />
);

export default BulkReschedulingApprovedHint;
