/* Since redux toolkit uses immer for immutability */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import type { ItemApprovals } from '@routable/ear/models/itemApprovals.model';
import { itemApprovalsApi } from '@routable/ear/services/api/approvals/item';

import { systemLogger } from 'helpers/systemLogger';

import type { ReduxState } from 'interfaces/redux';

import { propsSelector } from 'selectors/globalSelectors';

export type ItemEarApprovals = {
  request: Record<string, undefined | 'SUCCESS' | 'LOADING' | 'FAILURE'>;
  approvals: Record<string, ItemApprovals>;
};

export const itemEarApprovalsRoutine = createAsyncThunk('get:items/ear/approvals', async (ids: string[], thunkApi) => {
  // Early release if no id's are passed in
  if (ids.length === 0) {
    return thunkApi.fulfillWithValue([]);
  }
  try {
    const results = await itemApprovalsApi.getMany(ids);
    return thunkApi.fulfillWithValue(results.data);
  } catch (error) {
    systemLogger.log({
      level: 'ERROR',
      message: 'Error loading approvals',
      ids,
      error,
    });
    return thunkApi.rejectWithValue(ids);
  }
});

const initialState: ItemEarApprovals = {
  request: {},
  approvals: {},
};

export const itemEarApprovalsSlice = createSlice({
  name: 'itemEarApprovals',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(itemEarApprovalsRoutine.pending, (state, action) => {
        action.meta.arg.forEach((id) => {
          state.request[id] = 'LOADING';
        });
      })
      .addCase(itemEarApprovalsRoutine.fulfilled, (state, action) => {
        // setting original argument ids to failure, success will overwrite it and we would be left with any not returned as failures.
        action.meta.arg.forEach((id) => {
          state.request[id] = 'FAILURE';
        });
        if (Array.isArray(action.payload)) {
          action.payload.forEach((approval: ItemApprovals) => {
            // itemId may be incorrect, backup code to handle that or proposed update
            state.request[approval.relatedObjectId] = 'SUCCESS';
            state.approvals[approval.relatedObjectId] = approval;
          });
        }
      })
      .addCase(itemEarApprovalsRoutine.rejected, (state, error) => {
        error.meta.arg.forEach((id) => {
          state.request[id] = 'FAILURE';
        });
      });
  },
});

export const getItemEarApprovals = (reduxState: ReduxState): ItemEarApprovals => reduxState[itemEarApprovalsSlice.name];

export const getItemEarApprovalRequestStatus = createSelector(
  [getItemEarApprovals, propsSelector],
  (approvals, id?: string) => approvals?.request[id],
);
