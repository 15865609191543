import React from 'react';

import Avatar from 'components/avatar/Avatar';

import { MemberNameAddOn } from 'constants/ui';

import { getMembershipFullNameAppendingTextToCurrent } from 'helpers/memberships';

import type { Membership } from 'interfaces/memberships';

import * as Cell from './CompanyRepTableName.styles';

const { MEMBER_NAME_ME } = MemberNameAddOn;

const CompanyRepTableName = ({ membership }: { membership: Membership & User }): JSX.Element => {
  const { avatar, email, firstName, lastName, isCompanyBeneficiary } = membership;
  const memberFullName = getMembershipFullNameAppendingTextToCurrent(membership, MEMBER_NAME_ME);

  return (
    <Cell.Wrapper>
      <Cell.Name>
        <Avatar
          avatar={avatar}
          email={email}
          firstName={firstName}
          lastName={lastName}
          sizeClass="avatar--sm"
          styleClass="avatar--light"
        />

        <span className="margin-left--m font-color--greyXDark font-regular">{memberFullName}</span>
      </Cell.Name>

      {isCompanyBeneficiary && <Cell.Tag>Beneficial owner</Cell.Tag>}
    </Cell.Wrapper>
  );
};

export default CompanyRepTableName;
