import { type HorizontalExternalResizableEvent, horizontalResizeEventName } from '@routable/components';
import { useEvent } from '@routable/framework';
import classNames from 'clsx';
import React from 'react';
import { Field } from 'redux-form';

import FormControl from 'components/form/FormControl';

import { CompanyTypeSettings } from 'constants/ui';

import { useOnElementResize } from 'hooks';

import Radio from '../Radio';

import type { HorizontalRadiosProps } from './HorizontalRadios.types';

import './HorizontalRadios.scss';

/**
 * Helper component to place two Radios side-by-size (a.k.a horizontal). Automatically stacks when containing
 * wrapper reaches below a stackWidth threshold if stackOnMobile is true (defaults to true).
 */
const HorizontalRadios: React.VFC<HorizontalRadiosProps> = ({
  isDisabled,
  name,
  leftOptions,
  rightOptions,
  stackOnMobile = true,
  stackWidth = CompanyTypeSettings.HORIZONTAL_MINIMUM_WIDTH,
  className,
  ...rest
}) => {
  const commonProps = {
    component: Radio,
    isDisabled,
    isHorizontalLayout: true,
    name,
    type: 'radio',
  };
  const formControlId = 'formControlForHorizontalRadios';

  const [width] = useOnElementResize(formControlId);
  const isSmall = useEvent(
    horizontalResizeEventName('horizontal:bill:coding'),
    (ev: HorizontalExternalResizableEvent) => ev.rightSize < CompanyTypeSettings.HORIZONTAL_MINIMUM_WIDTH,
    false,
  );

  const classes = {
    flex: true,
    stack: isSmall || (stackOnMobile && width <= stackWidth),
    'flex-wrap': isSmall,
    '!m-0': isSmall,
    'gap-5': true,
    '[&>*]:!mr-0': true,
    '[&>*]:!flex-shrink': true,
    'py-3': isSmall,
  };

  return (
    <FormControl className={classNames(className, classes)} id={formControlId} {...rest}>
      <Field {...commonProps} {...leftOptions} />

      <Field className="second-radio-option" {...commonProps} {...rightOptions} />
    </FormControl>
  );
};

export default HorizontalRadios;
