/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import React from 'react';

import { BankAccountViewType } from 'constants/ui';

import { isFundingAccountEligibleForRTP } from 'helpers/funding';
import { isNotEqual } from 'helpers/utility';

import BankAccountHeaderContentDateAdded from '../BankAccountHeaderContentDateAdded';
import BankAccountHeaderContentFlairItems from '../BankAccountHeaderContentFlairItems';
import BankAccountHeaderRtpEnabledTag from '../BankAccountHeaderRtpEnabledTag';

import { BankAccountHeaderContentRightProps } from './BankAccountHeaderContentRight.types';

/**
 * BankAccountHeaderContentRight
 * @param {BankAccountHeaderContentRightProps} props
 * @returns {StatelessComponent}
 */
const BankAccountHeaderContentRight: React.FC<BankAccountHeaderContentRightProps> = ({
  fundingAccount,
  menu,
  partnership,
  partnershipFundingAccount,
  viewType,
}) => (
  <div
    className={classNames('bank-account--header--content-right', {
      'align-items--center': isNotEqual(viewType, BankAccountViewType.PARTNER_ADDRESS),
    })}
  >
    {isFundingAccountEligibleForRTP(fundingAccount) && (
      <div className="margin-right--xm">
        <BankAccountHeaderRtpEnabledTag />
      </div>
    )}

    <BankAccountHeaderContentFlairItems
      partnership={partnership}
      partnershipFundingAccount={partnershipFundingAccount}
    />

    <BankAccountHeaderContentDateAdded partnershipFundingAccount={partnershipFundingAccount} />

    {menu}
  </div>
);

export default BankAccountHeaderContentRight;
