import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { Text } from 'components';

import { paginationDataTestId } from 'constants/dataTestId';
import { typography } from 'constants/styles';

import PaginationBlockNextButton from 'modules/dashboard/paginationBlock/components/PaginationBlockNextButton';
import PaginationBlockPreviousButton from 'modules/dashboard/paginationBlock/components/PaginationBlockPreviousButton';
import { getPaginationBlockResultText } from 'modules/dashboard/paginationBlock/helpers/component';

import 'modules/dashboard/paginationBlock/PaginationBlock.scss';

import { PaginationBlockProps } from './PaginationBlock.types';

/**
 * The component is partially copied over from 'modules/dashboard/paginationBlock'.
 * In part, it still references the children components and stylesheet from 'modules/dashboard/paginationBlock'.
 *
 * This component is intended to decouple dashboard specific logic and
 * make it more re-usable in other parts of the application other than dashboard.
 * This component removed most of the business logic and handles display logic only.
 *
 * For now, this component does not support filter and search.
 */
const PaginationBlock: React.FC<PaginationBlockProps> = ({
  count,
  filterLabel,
  isFetching,
  onGoToPage,
  page,
  pages,
  pageSize,
}) => {
  const resultText = getPaginationBlockResultText({
    currentPage: page,
    isFetching,
    pageSize,
    totalResults: count,
  });

  return (
    <div className="pagination-bar">
      <div className="left">
        <Text.Semibold
          className="display--block line-height--xl--important"
          color={typography.TextColor.BLUE_DARK}
          size={typography.TextSize.LEVEL_400}
        >
          {filterLabel}
        </Text.Semibold>
        <Text.Regular
          color={typography.TextColor.GREY_X_DARK}
          data-testid={paginationDataTestId.paginationComponent.HEADER_INDEX}
          size={typography.TextSize.LEVEL_100}
        >
          {resultText}
        </Text.Regular>
      </div>
      <div className="right">
        <PaginationBlockPreviousButton isDisabled={page <= 1} onClick={() => onGoToPage('PREV')} />
        <PaginationBlockNextButton
          buttonContainerClassname="no-margin-right"
          isDisabled={page >= pages}
          onClick={() => onGoToPage('NEXT')}
        />
      </div>
    </div>
  );
};

export default PaginationBlock;
