import { announcementFetchRoutine } from 'actions/routines/announcements';

import { createByIdReducer } from 'store/redux';

export default createByIdReducer({
  initialState: {},
  key: 'userFeatureAnnouncement',
  replaceState: true,
  types: [announcementFetchRoutine.SUCCESS],
});
