import React from 'react';
import { useDispatch } from 'react-redux';
import { change, Field } from 'redux-form';

import LockedCountryInput from 'components/form/LockedCountryInput';
import { SelectFieldV2 } from 'components/selectV2';

import { InputTypes } from 'constants/ui';

import { requiredValidator } from 'helpers/fieldValidation';
import { lengthOf } from 'helpers/utility';

import { CountryFieldProps } from './CountryField.types';
import { useFormattedCountries } from './hooks';

/**
 * Component rendering country field. Meant to be used
 * inside a Redux Form container.
 */
const CountryField = ({
  countryCodes,
  formName,
  isEnabled,
  label = 'Country',
  partnership,
  dataTestId = 'country-selection',
  styles,
  validate = [],
  ...rest
}: CountryFieldProps) => {
  const dispatch = useDispatch();
  const { prioritizedCountries } = useFormattedCountries(countryCodes, partnership);

  const onlyOneOptionAvailable = lengthOf(prioritizedCountries) === 1;

  // Auto-populate field if only one option is available
  React.useEffect(() => {
    if (onlyOneOptionAvailable) {
      dispatch(change(formName, rest.name, prioritizedCountries[0].value));
    }
  }, [dispatch, formName, onlyOneOptionAvailable, prioritizedCountries, rest.name]);

  return isEnabled ? (
    <Field
      component={SelectFieldV2}
      dataTestId={dataTestId}
      isDisabled={onlyOneOptionAvailable}
      isLocked={onlyOneOptionAvailable}
      label={label}
      options={prioritizedCountries}
      styles={styles}
      type={InputTypes.TEXT}
      validate={[requiredValidator, ...validate]}
      {...rest}
    />
  ) : (
    <LockedCountryInput {...rest} dataTestId={dataTestId} label={label} />
  );
};

export default CountryField;
