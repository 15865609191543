import classNames from 'classnames';
import React from 'react';

import { Text } from 'components';

import { colors, typography } from 'constants/styles';

import { formatCurrency } from 'helpers/currencyFormatter';

import { useCurrencyContext } from 'hooks';

import type { CurrencyAmountTextProps } from './CurrencyAmountText.types';

/**
 * Used to display amount and currency code together while allowing customization
 * E.g "$1,234.56 USD"
 * @see {ShowAmountCell}
 */
const CurrencyAmountText: React.FC<CurrencyAmountTextProps> = ({
  amount,
  amountProps,
  className,
  currencyCode,
  currencyCodeProps,
  parseCurrencyOptions,
}: CurrencyAmountTextProps) => {
  const currencyCodeMap = useCurrencyContext();
  const formattedAmount = formatCurrency(amount, currencyCode, currencyCodeMap, parseCurrencyOptions);

  return (
    <span className={className}>
      <Text.Regular color={colors.colorBlueDarkHex} size={typography.TextSize.LEVEL_100} {...amountProps}>
        {formattedAmount}
      </Text.Regular>
      <Text.Regular
        color={colors.colorGreyXDarkHex}
        size={typography.TextSize.LEVEL_100}
        {...currencyCodeProps}
        className={classNames('margin-left--sm-alt', currencyCodeProps?.className)}
      >
        {currencyCode?.toUpperCase()}
      </Text.Regular>
    </span>
  );
};

export default CurrencyAmountText;
