import React from 'react';

import { isString } from 'helpers/utility';

import './BulletList.scss';

/**
 * Used to display strings and nodes as a bulleted list.
 * ex: ["bullet 1", "bullet 2", /<a>hello world</a>]
 */
const BulletList = ({ bullets }: { bullets: (string | JSX.Element)[] }): JSX.Element | null => {
  if (!bullets.length) {
    return null;
  }

  return (
    <>
      {bullets
        // removes these from the list if invalid, as we only want the 'valid' values
        .filter((bullet) => isString(bullet) || React.isValidElement(bullet))
        .map((bullet, index) => (
          // using index as key as there is no reliable key and the list it not expected to be modified
          // eslint-disable-next-line react/no-array-index-key
          <div className="display--flex bullet-list" key={index}>
            <div className="bullet-list--bullet" />
            <span className="bullet-list--text">{bullet}</span>
          </div>
        ))}
    </>
  );
};

export default BulletList;
