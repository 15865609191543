import * as routines from 'actions/routines/purposeCode';

import { createErrorReducer } from 'store/redux';

export const failureCases = [routines.fetchPurposeCodeRoutine.FAILURE];

export const successCases = [routines.fetchPurposeCodeRoutine.REQUEST, routines.fetchPurposeCodeRoutine.SUCCESS];

const errorsReducer = createErrorReducer(successCases, failureCases);

export default errorsReducer;
