import { createAction } from '@reduxjs/toolkit';

export const setW8FormIsSubmittingState = createAction<boolean>(
  '@partnershipRequestForms/SET_W8_FORM_IS_SUBMITTING_STATE',
);
export const setW8FormIsValidState = createAction<boolean>('@partnershipRequestForms/SET_W8_FORM_IS_VALID_STATE');

export const setW9FormIsSubmittingState = createAction<boolean>(
  '@partnershipRequestForms/SET_W9_FORM_IS_SUBMITTING_STATE',
);
export const setW9FormIsValidState = createAction<boolean>('@partnershipRequestForms/SET_W9_FORM_IS_VALID_STATE');
