import React from 'react';

import { ItemKinds } from 'enums/items';

import { ExternalItemsList } from '../components';

import { PartnershipPaymentItemsProps } from './PartnershipPaymentItems.types';

/**
 * Component rendering external partnership payment items list
 * @param {PartnershipPaymentItemsProps} props
 * @returns {StatelessComponent}
 */
const PartnershipPaymentItems: React.FC<PartnershipPaymentItemsProps> = ({ partnershipItems }) => (
  <ExternalItemsList itemsKind={ItemKinds.RECEIVABLE} partnershipItems={partnershipItems} />
);

PartnershipPaymentItems.defaultProps = {
  partnershipItems: undefined,
};

export default PartnershipPaymentItems;
