import React from 'react';

import { getItemStatusText, getStatusToConsider, itemStatusToText, itemStatusToVariant } from 'helpers/status';

import ExternalBlocksSection from 'modules/external/ExternalBlocksSection';

import ExternalReceiptItemStatus from '../ExternalReceiptItemStatus';

import {
  ExternalReceiptPaymentHeader,
  ExternalReceiptPaymentItemSummary,
  ExternalReceiptPaymentPaginator,
  ExternalReceiptPaymentViewDetails,
} from './components';
import { ExternalReceiptPaymentProps } from './ExternalReceiptPayment.types';

import './ExternalReceiptPayment.scss';

/**
 * Component rendering External Receipt for batch payment
 */
const ExternalReceiptPayment: React.FC<ExternalReceiptPaymentProps> = ({
  amount,
  attachments,
  currencyCode,
  canNextPage,
  canPreviousPage,
  item,
  payment,
  onGoToPage,
  onViewBills,
  onViewLineItems,
  pageCount,
  pageIndex,
}) => {
  const status = payment ? payment.status : getStatusToConsider(item);
  const text = getItemStatusText(item, status);

  const showViewDetailsButton = !!item?.lineItems?.length;
  return (
    <ExternalBlocksSection className="external-receipt-payment">
      <ExternalReceiptPaymentHeader amount={amount} currencyCode={currencyCode} itemKind={item?.kind} />
      <ExternalReceiptItemStatus
        status={itemStatusToVariant[status]}
        statusText={itemStatusToText[status]}
        text={text}
      />
      {payment ? (
        <ExternalReceiptPaymentPaginator
          canNextPage={canNextPage}
          canPrevPage={canPreviousPage}
          onGoToPage={onGoToPage}
          onViewAll={() => onViewBills(payment)}
          page={pageIndex}
          pages={pageCount}
        />
      ) : (
        showViewDetailsButton && <ExternalReceiptPaymentViewDetails onClick={() => onViewLineItems(item)} />
      )}
      <ExternalReceiptPaymentItemSummary attachments={attachments} item={item} />
    </ExternalBlocksSection>
  );
};

export default ExternalReceiptPayment;
