import { useSelector } from 'react-redux';

import { FeatureFlags, FeatureFlagState } from 'interfaces/featureFlags';

/**
 * Provides access to the current state of active feature flags.
 *
 * Usage:
 *
 *     import { FEATURE_FLAG_NAME_XYZ } from 'constants/featureFlags';
 *
 *     const MyComponent = () => {
 *         const activeFeatureFlags = useFeatureFlags();
 *         return (<span>{activeFeatureFlags[FEATURE_FLAG_NAME_XYZ] && "flag is on"}</span>);
 *     }
 */
const useFeatureFlags = (): FeatureFlags => useSelector((state: FeatureFlagState) => state.featureFlags.activeFlags);

export default useFeatureFlags;
