import React from 'react';
import { useSelector } from 'react-redux';

import { IconNames } from 'components/icon';

import { Environment } from 'constants/env';
import { formNamesSignup } from 'constants/forms';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { ENVIRONMENT_DOMAINS } from 'constants/routes';

import { LOGO_CIRCLE } from 'global/images/platform';

import { getSelectNamespace } from 'selectors/signupSelectors';
import 'modules/signup-v3/styles/variables.scss';

import {
  Browser,
  ContentIcon,
  NavigationIcon,
  TabRow,
  WindowControls,
  WindowControlButton,
  OpenTab,
  TabLogo,
  ControlsRow,
  PageControls,
  AddressBar,
  Namespace,
  GrayBar,
  ContentRow,
  BarContainer,
  MockContentContainer,
  MockContentHeader,
  MockContentBubble,
  ContentIconContainer,
} from './DynamicTextBrowser.styles';

const DynamicTextBrowser: React.FC = () => {
  const controlIcons = [IconNames.ARROW_LEFT, IconNames.ARROW_RIGHT, IconNames.REFRESH];

  const { isNamespaceValid } = useSelector(getSelectNamespace);
  const formSelector = useSelector((state: ReduxState) => state.form[formNamesSignup.SELECT_NAMESPACE]);
  const namespace = formSelector?.values?.company?.namespace;

  return (
    <Browser>
      <TabRow>
        <WindowControls>
          <WindowControlButton color="#ED6A5E" />
          <WindowControlButton color="#F5BD4F" />
          <WindowControlButton color="#61C455" />
        </WindowControls>

        <OpenTab>
          <TabLogo alt={PLATFORM_DISPLAY_SHORT_NAME} className="tab-icon" src={LOGO_CIRCLE.BLUE} />
          {` Routable`}
        </OpenTab>
      </TabRow>

      <ControlsRow>
        <PageControls>
          {controlIcons.map((icon, idx) => (
            <NavigationIcon icon={icon} key={`nav-icon-${idx + 1}`} />
          ))}
        </PageControls>

        <AddressBar data-testid="address-bar">
          {namespace?.length < 30 ? 'https://' : ''}
          <Namespace isValid={isNamespaceValid}>{namespace}</Namespace>
          {namespace?.length < 28 ? `.${ENVIRONMENT_DOMAINS[Environment.PRODUCTION].brandedWorkspaces}` : ''}
        </AddressBar>
      </ControlsRow>

      <ContentRow>
        <MockContentContainer>
          <MockContentHeader>
            <GrayBar type="small" />
          </MockContentHeader>

          <MockContentBubble>
            <ContentIconContainer>
              <ContentIcon icon={IconNames.TICK_CIRCLE} size={18} />
            </ContentIconContainer>
            <BarContainer>
              <GrayBar type="small" />
              <GrayBar type="large" />
            </BarContainer>
          </MockContentBubble>
        </MockContentContainer>
      </ContentRow>
    </Browser>
  );
};

export default DynamicTextBrowser;
