import React from 'react';

import { BrandWrapperV2 } from 'components';

import * as ROUTES from 'constants/routes';

import { asPath } from 'helpers/routeHelpers';

import NotAuthenticated from 'modules/app/NotAuthenticatedRoute';

import CreatePasswordV2 from '../ResetPassword/components/ResetPasswordComplete/ResetPasswordCompleteContainer';

/**
 * CreatePassword wrapper
 */
const CreatePassword: React.VFC = () => (
  <BrandWrapperV2>
    <div className="branded--contents remove-margin">
      <NotAuthenticated component={CreatePasswordV2} creatingNewPassword path={asPath(ROUTES.CREATE_PASSWORD)} />
    </div>
  </BrandWrapperV2>
);

export default CreatePassword;
