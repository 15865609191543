import classNames from 'classnames';
import React, { type PropsWithChildren } from 'react';

const FormFieldLabel = ({ children, className }: PropsWithChildren<{ className?: string }>): JSX.Element => {
  const paragraphClasses = classNames('form-field--label', {
    [className]: !!className,
  });

  return <p className={paragraphClasses}>{children}</p>;
};

export default FormFieldLabel;
