import * as routines from 'actions/routines/funding';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [
  routines.fetchSupportedCountriesRoutine.FAILURE,
  routines.fetchSupportedCountriesRoutine.SUCCESS,
];

export const requestCases = [routines.fetchSupportedCountriesRoutine.REQUEST];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;
