import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { sidePanelsSelector } from 'selectors/sidePanelsSelector';

import ExternalSideSheets from './ExternalSideSheets';
import { StateProps } from './ExternalSideSheets.types';

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  sideSheets: sidePanelsSelector,
});

export default connect(mapStateToProps)(ExternalSideSheets);
