import { Repeat, SubtractAlt } from '@carbon/icons-react';
import { Menu } from '@routable/gross-ds';
import React from 'react';

import PermissionResourceAction from 'constants/permissions';

import { useGrossDsPermissions } from 'hooks';

/**
 * Displays a menu popover in a table cell.
 */
const TeamMemberListPopoverCell = ({
  onResendInvite,
  onRevokeInvite,
}: {
  onResendInvite: () => void;
  onRevokeInvite: () => void;
}) => {
  const { hasPermission, tooltipProps } = useGrossDsPermissions([PermissionResourceAction.SETTINGS_TEAM_MEMBERS_EDIT]);

  return (
    <Menu buttonVariant="floating" placement="bottom">
      <Menu.Item disabled={!hasPermission} icon={<Repeat />} onAction={onResendInvite} tooltip={tooltipProps}>
        Re-send invite
      </Menu.Item>

      <Menu.Item disabled={!hasPermission} icon={<SubtractAlt />} onAction={onRevokeInvite} tooltip={tooltipProps}>
        Revoke invite
      </Menu.Item>
    </Menu>
  );
};

export default TeamMemberListPopoverCell;
