import styled from 'styled-components';

import { ProgressBarProps } from './ProgressBar.types';

export const Bar = styled.div`
  background-color: var(--blue--light);
  width: 100%;
  height: 0.25rem;
  margin: 0.75rem 0;
`;

export const FilledBar = styled.div<ProgressBarProps>(
  ({ percentage }) => `
  background-color: var(--blue--bold);
  height: 4px;
  width: ${percentage}%;
`,
);
