import { sidePanelNameEditCompanyGeneralInfo } from 'constants/sidePanels';

import * as types from 'types/sidePanels';

import {
  EditCompanyGeneralInfoSidePanelPayloadAction,
  EditCompanyGeneralInfoSidePanelState,
} from './editCompanyGeneralInfoReducer.types';

const initialState: EditCompanyGeneralInfoSidePanelState = {
  open: false,
  partnershipId: undefined,
};

/**
 * Redux reducer for Edit Company General Info Side Panel
 * @param state - Reducer's state
 * @param action - Dispatched action
 */
const editCompanyGeneralInfoReducer = (
  state = initialState,
  action: EditCompanyGeneralInfoSidePanelPayloadAction,
): EditCompanyGeneralInfoSidePanelState => {
  switch (action.type) {
    case types.SIDE_PANEL_OPEN: {
      // to trigger this, use the generic openSidePanel actionCreator and pass this sidepanel's name
      return action.payload.name === sidePanelNameEditCompanyGeneralInfo
        ? { ...state, ...action.payload.state, open: true }
        : state;
    }

    case types.SIDE_PANEL_CLOSE: {
      // to trigger this, use the generic closeSidePanel actionCreator and pass this sidepanel's name
      return action.payload.name === sidePanelNameEditCompanyGeneralInfo
        ? { ...state, partnershipId: undefined, open: false }
        : state;
    }

    default:
      return state;
  }
};

export { initialState };
export default editCompanyGeneralInfoReducer;
