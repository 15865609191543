import React from 'react';
import { Redirect } from 'react-router-dom';

import { LOGIN } from 'constants/routes';

import { hasAuthToken } from 'helpers/auth';
import { asPath } from 'helpers/routeHelpers';

import { IsLoadingHasWrapper } from 'modules/isLoading';

import { clearLocalStorageOnLogout } from './helpers';

const Logout: React.VFC = ({ onLogoutUser }: { onLogoutUser: () => void }) => {
  const isAuthenticated = hasAuthToken();
  React.useEffect(() => {
    if (isAuthenticated) {
      clearLocalStorageOnLogout();
      onLogoutUser();
    }
  });

  if (isAuthenticated) {
    return <IsLoadingHasWrapper />;
  }

  return <Redirect to={asPath(LOGIN)} />;
};

export default Logout;
