import { connect } from 'react-redux';

import { openCreateManualAddressModal } from 'actions/createManualAddress';

import AddCheckButton from './AddCheckButton';
import type { DispatchProps } from './AddCheckButton.types';

export const mapDispatchToProps: DispatchProps = {
  onAddAddress: openCreateManualAddressModal,
};

export default connect(null, mapDispatchToProps)(AddCheckButton);
