import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { logoutRequest } from 'actions/auth';
import { toggleLogoutModal } from 'actions/signUpFlow';

import { Modal, ModalFooter, ButtonV2 } from 'components';
import { Text } from 'components/text';

import { ButtonSize } from 'constants/button';
import { colors, typography } from 'constants/styles';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';

import { getSaveStatus } from 'selectors/signupSelectors';

import { SAVE_STATUSES } from '../../constants';

import { BodyContainer, Subtext } from './LogoutModal.styles';
import { ModalBodyProps } from './LogoutModal.type';

export const ModalBody: React.FC<ModalBodyProps> = ({ saveStatus, onCloseModal, onLogout }) => (
  <>
    <BodyContainer>
      <Text.Bold color={TextColor.BLUE_DARK} size={TextSize.LEVEL_400}>
        Are you sure you want to log out?
      </Text.Bold>
      <Subtext>
        <Text.Regular
          color={typography.TextColor.GREY_XX_DARK}
          lineHeight={typography.TextLineHeight.EXTRA_LARGE}
          size={typography.TextSize.LEVEL_200}
        >
          {saveStatus === SAVE_STATUSES.saved ? (
            'We’ve saved your progress so far.'
          ) : (
            <>
              If you have unsaved changes, click{' '}
              <Text.Bold
                color={typography.TextColor.GREY_XX_DARK}
                lineHeight={typography.TextLineHeight.EXTRA_LARGE}
                size={typography.TextSize.LEVEL_200}
              >
                Keep working
              </Text.Bold>
              , then save them before logging out.
            </>
          )}
        </Text.Regular>
        <Text.Regular
          color={typography.TextColor.GREY_XX_DARK}
          lineHeight={typography.TextLineHeight.EXTRA_LARGE}
          size={typography.TextSize.LEVEL_200}
        >
          You&apos;ll need to log back in to Routable to verify your business.
        </Text.Regular>
      </Subtext>
    </BodyContainer>
    <ModalFooter
      style={{
        backgroundColor: colors.colorWhiteHex,
      }}
    >
      <div className="content--left">
        <ButtonV2 intent={Intent.NEUTRAL} onClick={onLogout} size={ButtonSize.MEDIUM} type="button">
          Logout
        </ButtonV2>
      </div>
      <div className="content--right">
        <ButtonV2 onClick={onCloseModal} size={ButtonSize.MEDIUM} type="button">
          Keep working
        </ButtonV2>
      </div>
    </ModalFooter>
  </>
);

const LogoutModal: React.VFC<{ open: boolean }> = ({ open }) => {
  const dispatch = useDispatch();
  const onCloseModal = () => dispatch(toggleLogoutModal());
  const onLogout = () => {
    dispatch(toggleLogoutModal());
    dispatch(logoutRequest());
  };
  const saveStatus = useSelector(getSaveStatus);
  return (
    <Modal
      body={<ModalBody onCloseModal={onCloseModal} onLogout={onLogout} saveStatus={saveStatus} />}
      hasInlineFooter
      hideHeader
      modalState={open}
      onCloseModal={onCloseModal}
      overrideStyle={{
        content: {
          maxWidth: '500px',
          backgroundColor: colors.colorWhiteHex,
        },
        overlay: {
          backgroundColor: 'rgba(60, 72, 88, 0.1)',
        },
      }}
    />
  );
};

export default LogoutModal;
