import { Edit, TrashCan } from '@carbon/icons-react';
import { Menu } from '@routable/gross-ds';
import React from 'react';

import { ActiveContactActionsMenuProps } from './ActiveContactActionsMenu.types';

/**
 * Actions which can be taken on an non-archived contact. Actions may be restricted if the contact is a member of
 * another dashboard company.
 */
const ActiveContactActionsMenu = ({
  canArchive,
  canEdit,
  isArchiveDisabled,
  onArchive,
  onEdit,
}: ActiveContactActionsMenuProps) => {
  return (
    <Menu buttonVariant="floating" placement="bottom">
      <Menu.Item disabled={!canEdit.hasPermission} onAction={() => onEdit()} tooltip={canEdit.tooltipProps}>
        <Edit />
        Edit contact
      </Menu.Item>

      <Menu.Item
        disabled={isArchiveDisabled || !canArchive.hasPermission}
        onAction={() => onArchive()}
        tooltip={canArchive.tooltipProps}
      >
        <TrashCan />
        Archive contact
      </Menu.Item>
    </Menu>
  );
};

export default ActiveContactActionsMenu;
