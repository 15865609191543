import React from 'react';

import { BrandWrapperV2 } from 'components';

import { AcceptMembershipInvitePage } from './components';

const AcceptMembershipInvite: React.VFC = () => (
  <BrandWrapperV2>
    <AcceptMembershipInvitePage />
  </BrandWrapperV2>
);

export default AcceptMembershipInvite;
