import classNames from 'classnames';
import React from 'react';

import './NumberChip.scss';

export interface NumberChipProps {
  children?: number;
  className?: string;
}

/**
 * Displays a number inside a rounded chip.
 */
const NumberChip: React.FC<NumberChipProps> = ({ children, className }) => (
  <span className={classNames('number-chip', className)}>{children}</span>
);

const defaultProps = {
  children: undefined,
  className: undefined,
};

NumberChip.defaultProps = defaultProps;

export default NumberChip;
