import React from 'react';
import { Field } from 'redux-form';

import { updatePaymentMethodFormFields } from 'constants/formFields';

import { PaymentMethodManage } from 'modules/fundingAccount/PaymentMethod';

import { UpdatePaymentMethodFundingAccountProps } from './UpdatePaymentMethodFundingAccount.types';

/**
 * Component rendering single selectable Funding Account inside of a
 * International Update Payment Method Form
 * @param {UpdatePaymentMethodFundingAccountProps} props
 * @returns {StatelessComponent}
 */
const UpdatePaymentMethodFundingAccount: React.FC<UpdatePaymentMethodFundingAccountProps> = ({
  fundingAccount,
  ...rest
}) => (
  <Field
    className="margin-bottom--m-large"
    component={PaymentMethodManage}
    // DEV-2832 @zigcccc For the MVP implementation of the XB$ flow, manage actions will be disabled
    disableManageActions
    fundingAccount={fundingAccount}
    isSelectable
    key={fundingAccount.id}
    name={updatePaymentMethodFormFields.FUNDING_ACCOUNT}
    type="radio"
    value={fundingAccount.id}
    {...rest}
  />
);

export default UpdatePaymentMethodFundingAccount;
