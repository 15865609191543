import React from 'react';

import { PoweredByWordMark, SafeAndSecureBadge } from 'components';
import { PrivacyPolicyLink, TermsOfServiceLink, HelpDocsLink, SystemStatusLink } from 'components/link';

import { CustomerServiceHelper } from 'modules/customerSupport/CustomerService';
import './ExternalFlowFooter.scss';

const ExternalFlowFooter: React.FC = () => {
  return (
    <footer className="external__footer">
      <div className="external__footer--wordmark">
        <PoweredByWordMark />
      </div>
      <div className="external__footer--items">
        <SafeAndSecureBadge />
        <HelpDocsLink />
        <SystemStatusLink />
        <PrivacyPolicyLink />
        <TermsOfServiceLink />
        <a onClick={() => CustomerServiceHelper.show()} role="presentation">
          Contact us
        </a>
      </div>
    </footer>
  );
};

export default ExternalFlowFooter;
