import { DateFormats } from '@routable/shared';
import React from 'react';

import { getDate, getObjDate } from 'helpers/date';
import { getClassNames } from 'helpers/ui';

import TableCellText from '../TableCellText';

import { DateCellProps } from './DateCell.types';

const DateCell = ({
  value,
  dataKey,
  defaultValue = '--',
  format = DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR,
  rowData,
  ...rest
}: DateCellProps) => (
  <TableCellText className={getClassNames(rest)}>
    {(value ? getDate(value, format) : getObjDate(rowData, dataKey, format)) || defaultValue}
  </TableCellText>
);

export default DateCell;
