import React from 'react';
import { Field } from 'redux-form';

import { CompanyAddressDataCard } from 'complexComponents';

import { Checkbox, FlexCol, Text } from 'components';

import { partnerFundingAccountFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';
import { typography } from 'constants/styles';

import { PartnershipCountryCodeKey } from 'enums/partnerships';

import { getCountryNameFromCode } from 'helpers/countries';
import { isInternationalPartnership } from 'helpers/partnerships';

import { useCountryCodesContext } from 'hooks';

import AddressContainer from 'modules/address/containers/AddressContainer';

import type { InternationalPartnerFundingAccountAddressDetailsProps as Props } from './InternationalPartnerFundingAccountAddressDetails.types';

/**
 * Component rendering the Address Details section in the Add International Partner
 * funding account form
 * @param props - Component props
 */
const InternationalPartnerFundingAccountAddressDetails = ({
  address,
  country,
  partnership,
  shouldApplyRegisteredAddress,
  showApplyRegisteredAddress = true,
}: Props): JSX.Element => {
  const countryCodesMap = useCountryCodesContext();
  const countryName = getCountryNameFromCode(countryCodesMap, country);
  const isInternational = isInternationalPartnership(partnership, PartnershipCountryCodeKey.PARTNER);

  return (
    <div className="international-partner-funding-account__address-details margin-top--large">
      <FlexCol>
        <Text.Semibold color={typography.TextColor.GREY_XX_DARK} weight={typography.TextWeight.LEVEL_600}>
          Address details
        </Text.Semibold>
        <Text.Regular color={typography.TextColor.GREY_XX_DARK}>
          We&apos;re required to collect a billing address for each new bank account in
          {` ${countryName}`}.
        </Text.Regular>
      </FlexCol>

      {showApplyRegisteredAddress && (
        <Field
          className="margin-top--m-large"
          component={Checkbox}
          content="Apply registered address"
          name={partnerFundingAccountFormFields.APPLY_REGISTERED_ADDRESS}
        />
      )}

      <div className="margin-top--m-large">
        {shouldApplyRegisteredAddress ? (
          <CompanyAddressDataCard address={address} />
        ) : (
          <AddressContainer
            formId={formNamesFunding.ADD_MANUAL_BANK}
            isInternational={isInternational}
            lockedCountryReason="This vendor's country has already been set."
            stateKey="partner"
          />
        )}
      </div>
    </div>
  );
};

export default InternationalPartnerFundingAccountAddressDetails;
