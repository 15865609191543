import React from 'react';

import { FormControl } from 'components/form';
import { Hint, HintTitle } from 'components/hint';

import { Intent } from 'constants/ui';

import { PartnerSelfManagedTooltip } from './PartnerSelfManagedTooltip';

type Props = {
  partnerName: string;
};

export const PartnerSelfManagedHint = ({ partnerName }: Props): JSX.Element => {
  const hintTitle = (
    <>
      {`Approvals are not available for this payable because ${partnerName} is `}
      <PartnerSelfManagedTooltip>self-managed</PartnerSelfManagedTooltip>
    </>
  );

  return (
    <FormControl className="!mb-0 mt-7 self-stretch">
      <div className="flex flex-1" data-testid="partner-self-managed-hint">
        <Hint
          aria-label={`Approvals are not available for this payable because ${partnerName} is self-managed`}
          intent={Intent.WARNING}
          multiline
          role="note"
        >
          <HintTitle>{hintTitle}</HintTitle>
        </Hint>
      </div>
    </FormControl>
  );
};
