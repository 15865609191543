import classNames from 'classnames';
import React from 'react';

import './MaintenanceHeading.scss';

export const MaintenanceHeading = ({ className, name, subtitle, title }) => (
  <div
    className={classNames({
      'feature-heading': true,
      [className]: !!className,
    })}
  >
    {name && <p className="feature-heading--name">{name}</p>}
    <h3 className="feature-heading--title">{title}</h3>
    {subtitle && <p className="feature-heading--subtitle">{subtitle}</p>}
  </div>
);
