import { all, call, put, takeEvery } from 'redux-saga/effects';

import { changeBillViewSelectedBillIndex } from 'actions/billViewForm';
import { removeCreateItemBill } from 'actions/createItemForm';
import { handleRequestErrors } from 'actions/errors';
import { deleteBillAttachmentRoutine } from 'actions/routines/itemAttachments';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { getNextBillIndex } from 'helpers/itemAttachments';

import { Action } from 'interfaces/actions';
import { FetchServiceResponse } from 'interfaces/fetchService';
import { DeleteItemAttachmentPayload } from 'interfaces/itemAttachment';

import { deleteItemAttachment } from './api';

/*
 * Handles making API request to delete item attachments
 */
export function* deleteBillAttachmentOnServer(action: Action<DeleteItemAttachmentPayload>): IterableIterator<Action> {
  const handleReqErrors = handleRequestErrors.bind(null, deleteBillAttachmentRoutine.failure);

  try {
    const { itemId, attachment } = action.payload;

    yield put(deleteBillAttachmentRoutine.request());
    const response: FetchServiceResponse = yield call(deleteItemAttachment, itemId, attachment.id);

    if (response.ok) {
      yield put(deleteBillAttachmentRoutine.success(action.payload));
      return;
    }

    yield put(handleReqErrors(parseErrorResponse(response)));
  } catch (error) {
    yield put(handleReqErrors(parseCaughtError(error)));
  }
}

export function* handleDeleteBillAttachmentTrigger(
  action: Action<DeleteItemAttachmentPayload>,
): IterableIterator<Action> {
  const { selectedAttachment, attachment, attachments } = action.payload;

  // Local attachments are files uploaded from the user's computer that don't exist on the server
  // Local attachments don't have IDs and can be deleted by being removed from the local store
  // [srajchevski] DEV-14494:
  // Commenting this out for now because:
  // - the only way to delete an attachment via the FE is from the "Edit item" page
  // - the only items allowed to be edited are the ones with READY_TO_SEND, SCHEDULED, NEEDS_APPROVAL
  // - cannot delete an attachment via the endpoint below for items in READY_TO_SEND/SCHEDULED/NEEDS_APPROVAL status
  // Will leave the whole infrastructure for now, in case we need it in the future
  // if (attachment.isRemote) {
  //   yield call(deleteBillAttachmentOnServer, action);
  // }

  const nextIndex = getNextBillIndex(selectedAttachment.index, attachment.index, attachments);

  yield all([put(removeCreateItemBill(attachment.index)), put(changeBillViewSelectedBillIndex(nextIndex))]);
}

/**
 * Root itemAttachments saga.
 */
export default function* itemAttachments(): IterableIterator<Action> {
  yield takeEvery(deleteBillAttachmentRoutine.TRIGGER, handleDeleteBillAttachmentTrigger);
}
