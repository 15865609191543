// if you change the value of this constant make sure to
// update the validator name inside formValidation.js (currently specialCharError)
export const SPECIAL_CHARS_VALIDATION = 'specialChar';

export const SPECIAL_CHARS_VALIDATION_REGEX = /([^a-zA-Z0-9-_.~!\s*'();:@&=+$,/?%#[\]]+)/gm;

export const ValidationErrorText = {
  minDateValidation: `Date must be in the future`,
};

// 25MB is the upper limit allowed by our nginx config. Since we want to
// allocate some space to the other data send with the request (json), we
// are limiting the files total size to 15MB (the size is represented in bytes).
export const MAX_FILES_TOTAL_SIZE = 15_728_640;
