import React from 'react';

import { ModalFooter, ButtonV2 } from 'components';
import { Text } from 'components/text';

import { ButtonSize } from 'constants/button';
import { colors, typography } from 'constants/styles';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';

import { modalCopies } from '../../constants/modalCopies';

import { ModalKeepWorkingButton, ModalSaveButton } from './components/ModalBodyButtons';
import { BodyContainer, Subtext } from './SkipStepModal.styles';
import { SkipStepModalBodyProps } from './types/SkipStepModal.types';

const SkipStepModalBody: React.FC<SkipStepModalBodyProps> = ({ invalid, onClose, onSaveChanges, onSkipStep }) => (
  <>
    <BodyContainer>
      <Text.Bold color={TextColor.BLUE_DARK} size={TextSize.LEVEL_400}>
        Before you continue...
      </Text.Bold>
      <Subtext>
        <Text.Regular
          color={typography.TextColor.GREY_XX_DARK}
          lineHeight={typography.TextLineHeight.EXTRA_LARGE}
          size={typography.TextSize.LEVEL_200}
        >
          {invalid ? modalCopies.SKIP_STEP_INVALID_FORM : modalCopies.SKIP_STEP_VALID_FORM}
        </Text.Regular>
      </Subtext>
    </BodyContainer>
    <ModalFooter
      style={{
        backgroundColor: colors.colorWhiteHex,
      }}
    >
      <div className="content--left">
        <ButtonV2 id="skip-button" intent={Intent.NEUTRAL} onClick={onSkipStep} size={ButtonSize.MEDIUM} type="button">
          Leave without saving
        </ButtonV2>
      </div>
      <div className="content--right">
        {invalid ? <ModalKeepWorkingButton onClose={onClose} /> : <ModalSaveButton onSaveChanges={onSaveChanges} />}
      </div>
    </ModalFooter>
  </>
);

export default SkipStepModalBody;
