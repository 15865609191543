import { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import { openNotificationBar } from 'actions/ui';

/**
 * Note: Check getIntentIconName in src/helpers/ui.js to see which of these will have icons,
 * string constants are from Intent in src/constants/ui.js
 */
type IntentIcon = 'danger' | 'info' | 'schedule' | 'success' | 'warning' | 'neutral' | null;

type DispatchOpenNotification = (message: ReactNode, options: { intent?: IntentIcon }) => void;

/**
 * Provides way of dispatching an action to open the notification bar.
 *
 * Usage:
 *
 *     import { Intent } from 'constants/ui';
 *     const MyComponent = () => {
 *         const openNotificationBar = useNotification();
 *         const onClick = () => {
 *           openNotificationBar("Hello World!", { intent: Intent.SUCCESS });
 *         };
 *         return (<span onClick={onClick}>Click</span>);
 *     }
 */
const useNotification = (): DispatchOpenNotification => {
  const dispatch = useDispatch();

  return (message, options) => {
    dispatch(openNotificationBar(message, options));
  };
};

export default useNotification;
