import React from 'react';

import { Form } from 'components';

import { DEFAULT_RECEIVABLE } from 'constants/formFields';

import { usePrevious } from 'hooks';

import { UpdatePaymentMethodInternationalLayout } from './components';
import { createSubmitHandler, getUpdatedReceivableFormValue } from './helpers';
import { UpdatePaymentMethodFormInternationalProps } from './UpdatePaymentMethodFormInternational.types';

/**
 * Component rendering international partnership Update Payment Method form
 * @param {UpdatePaymentMethodFormInternationalProps} props
 * @returns {StatelessComponent}
 */
const UpdatePaymentMethodFormInternational: React.FC<UpdatePaymentMethodFormInternationalProps> = ({
  defaultReceivable,
  form: formName,
  fundingAccounts,
  handleSubmit,
  isUpdatePaymentMethodFlow,
  lastSubmittedFundingAccountId,
  onSubmitAllExistingItems,
  onValuesChange,
}) => {
  const handleSubmitExistingItems = createSubmitHandler(onSubmitAllExistingItems);

  const previousLastSubmittedFundingAccountId = usePrevious(lastSubmittedFundingAccountId);

  React.useEffect(() => {
    const { shouldUpdate, updatedReceivable } = getUpdatedReceivableFormValue({
      defaultReceivable,
      lastSubmittedFundingAccountId,
      previousLastSubmittedFundingAccountId,
    });

    if (shouldUpdate) {
      onValuesChange(formName, DEFAULT_RECEIVABLE, updatedReceivable);
    }
  }, [
    defaultReceivable,
    formName,
    lastSubmittedFundingAccountId,
    onValuesChange,
    previousLastSubmittedFundingAccountId,
  ]);

  return (
    <Form
      className="update-payment-method-form-international"
      id={formName}
      name={formName}
      onSubmit={handleSubmit(handleSubmitExistingItems)}
    >
      <UpdatePaymentMethodInternationalLayout
        fundingAccounts={fundingAccounts}
        isUpdatePaymentMethodFlow={isUpdatePaymentMethodFlow}
      />
    </Form>
  );
};

export default UpdatePaymentMethodFormInternational;
