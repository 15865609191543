import React from 'react';
import { Dispatch } from 'redux';

import { lookupMembershipInviteEmailHandler } from 'actions/routines/inviteTeamMember';

import { EmailAlreadyInvitedError } from '../components/EmailAlreadyInvitedError';

export const alreadyInvitedAsyncValidation = (value: unknown, dispatch: Dispatch, props: unknown): Promise<unknown> => {
  const lookupPromise = lookupMembershipInviteEmailHandler(value, dispatch, props);

  const intoFormValidationError = (error: { warnings: { form: { email: unknown } } }) => {
    const { memberships = [], membershipInvites = [] } = Object(error?.warnings?.form?.email);
    const member = memberships[0] || membershipInvites[0];

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      form: {
        email: [<EmailAlreadyInvitedError key="email-already-invited" member={member} />],
      },
    });
  };

  return lookupPromise.catch(intoFormValidationError);
};
