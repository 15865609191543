import { AuthActionType } from 'enums/auth';

import { AuthActionCreator, AuthActionCreatorWithPayload, LogoutFailure, LogoutFailurePayload } from 'interfaces/auth';

export const clearLoginErrorState = (): AuthActionCreator => ({
  type: AuthActionType.CLEAR_LOGIN_ERROR_STATE,
});

export const clearResetPasswordState = (): AuthActionCreator => ({
  type: AuthActionType.CLEAR_RESET_PASSWORD_STATE,
});

export const logoutRequest = (): AuthActionCreator => ({
  type: AuthActionType.SUBMIT_LOGOUT_REQUEST,
});

export const logoutComplete = (): AuthActionCreator => ({
  type: AuthActionType.SUBMIT_LOGOUT_COMPLETE,
});

export const logoutCleanup = (): AuthActionCreator => ({
  type: AuthActionType.SUBMIT_LOGOUT_CLEANUP,
});

export const logoutFailure = (errors: LogoutFailure): AuthActionCreatorWithPayload<LogoutFailurePayload> => ({
  payload: { errors },
  type: AuthActionType.SUBMIT_LOGOUT_FAILURE,
});
