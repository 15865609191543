import PropTypes from 'prop-types';
import React from 'react';

import { ternary } from 'helpers/utility';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import RestrictedPermissionTooltip from '../RestrictedPermissionTooltip';

/**
 * A conditional tooltip wrapper which only wraps the children if useTooltip is true.
 *
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {boolean} [props.useTooltip=undefined]
 * @param {RestOfProps} [props.rest]
 * @returns {StatelessComponent}
 */
const ConditionalRestrictedPermissionTooltip = ({ children, useTooltip, ...rest }) =>
  ternary(useTooltip, <RestrictedPermissionTooltip {...rest}>{children}</RestrictedPermissionTooltip>, children);

ConditionalRestrictedPermissionTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  customization: PropTypes.shape({}),
  useTooltip: PropTypes.bool,
};

ConditionalRestrictedPermissionTooltip.defaultProps = {
  customization: undefined,
  useTooltip: undefined,
};

export default ConditionalRestrictedPermissionTooltip;
