import classNames from 'classnames';
import React from 'react';

import { IsLoading } from 'modules/isLoading/IsLoading';

import { FloatingIsLoadingProps } from './FloatingIsLoading.types';

const FloatingIsLoading: React.FC<FloatingIsLoadingProps> = ({ className, floatDirection, color }) => (
  <div
    className={classNames({
      [`loading--float-${floatDirection}`]: !!floatDirection,
      [className]: !!className,
    })}
  >
    <IsLoading color={color} />
  </div>
);

FloatingIsLoading.defaultProps = {
  floatDirection: 'left',
};

export default FloatingIsLoading;
