import styled, { keyframes, css } from 'styled-components';

import { Icon } from 'components';

type UploadTypes = {
  uploaded: boolean;
  uploading?: boolean;
};

const progressBar = keyframes`
  30% {
    background-size: 40% 100%;
  }
  50% {
    background-size: 66% 100%;
  }
  70% {
    background-size: 100% 100%;
  }
  100% {
    background-size: 100% 100%;
  }
`;

const progressColor = keyframes`
  30% {
    color: var(--white);
  }
  100% {
    color: var(--white);
  }
`;

const blinkIcon = keyframes`
  68% {
    opacity: 0;

  }
  69% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

export const FileItemContainer = styled.div<UploadTypes>`
  background-color: var(--blue--x-light);
  background-size: 0 100%;
  background-position: left;
  background-repeat: no-repeat;
  border: 1px solid var(--blue--bold);
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 16px 12px;

  ${({ uploaded }) => !uploaded && `background-image: linear-gradient(var(--blue--bold), var(--blue--bold))`};

  ${({ uploading }) =>
    uploading &&
    css`
      animation: ${progressBar} 3s;
    `};
  ${({ uploading }) => (uploading ? 'color: var(--white)' : 'color: var(--blue--bold)')};

  button:hover {
    cursor: pointer;
  }
`;

export const FileInput = styled.input<UploadTypes>`
  color: inherit;
  font-size: 14px;
  pointer-events: none;
  width: 100%;
`;

export const FileIcon = styled(Icon)``;

export const LoaderWrapper = styled.div`
  min-width: 32px;
`;
