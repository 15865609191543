import React from 'react';

import { DashboardToDashboardW9RestrictionTooltipContent, Text } from 'components';
import { CombinedTooltipProps } from 'components/tooltip/TooltipMUIConditionalWrapper';

import { TooltipPadding } from 'constants/tooltip';

import { VendorWithoutValidContactTooltipContent, VendorNeedsToAcceptInviteTooltipContent } from './components';
import { vendorRequestedTaxInfoBefore } from './TaxInfoRequestButton.constants';
import type { GetTaxInfoRequestButtonTooltipPropsArgs } from './TaxInfoRequestButton.types';

/**
 * Given the state properties of the TaxInfoRequest button, it returns a correct
 * set of tooltip props for that button, or null if we don't need to show the tooltip
 * @param options - TaxInfoRequest button state flags
 * @returns Tooltip props config or null
 */
export const getTaxInfoRequestButtonTooltipProps = ({
  hasBeenTriggered,
  isDisabled,
  isLoading,
  isPartnerStatusInvited,
  isTaxCollectionDisabled,
  isR2R,
  isWithoutContact,
  partnerName,
  permissions,
}: GetTaxInfoRequestButtonTooltipPropsArgs): Partial<CombinedTooltipProps> | null => {
  // If the request tax info action has already been triggered (i.e. the button has already been
  // clicked on), the button will get disabled and we want to show the tooltip explaining why
  if (hasBeenTriggered) {
    return {
      arrow: true,
      title: <Text.Regular>{vendorRequestedTaxInfoBefore}</Text.Regular>,
    };
  }

  // If we have permissions and all other conditions are met,
  // we want to show a generic tooltip explaining what action
  // will this button trigger. The button is enabled in this case.
  // When we're in a loading state, we don't want to display any
  // tooltip
  if (!isDisabled && !isLoading) {
    return {
      arrow: true,
      padding: TooltipPadding.SKINNY,
      title: <Text.Regular>Vendor will receive an email request to provide tax info</Text.Regular>,
    };
  }

  // If we're unable to execute the action due to whatever reason,
  // we want to apply the relevant messaging to the tooltip for the
  // disabled button.
  if (!permissions.hasPermission) {
    return { ...permissions.tooltipProps, padding: undefined };
  }
  if (isTaxCollectionDisabled) {
    return {
      arrow: true,
      title: 'Tax collection settings must be enabled for this request',
    };
  }
  if (isR2R) {
    return {
      arrow: true,
      title: <DashboardToDashboardW9RestrictionTooltipContent />,
    };
  }
  if (isWithoutContact) {
    return { arrow: true, title: <VendorWithoutValidContactTooltipContent /> };
  }
  if (isPartnerStatusInvited) {
    return {
      arrow: true,
      title: <VendorNeedsToAcceptInviteTooltipContent partnerName={partnerName} />,
    };
  }

  // If none if the criteria is met up to this point, we want to return
  // null as we don't want to show any tooltip
  return null;
};
