import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { Checkbox } from 'components';

import * as propTypes from 'constants/propTypes';

import { useMemoizedConditionalValidate } from 'hooks';

import { useDynamicFieldRequiredValidators, useTableCellFieldIsDisabled } from 'modules/table/hooks';

/**
 * Renders a checkbox field for boolean values to be rendered in a table cell.
 * @param {ComponentProps} props
 * @param {Function} props.getIsDisabled
 * @param {Function} props.getIsRequired
 * @param {Function} props.getRowValues
 * @param {string} props.id
 * @param {string} props.name
 * @param {Function|Function[]} props.validate
 * @returns {StatelessComponent}
 */
const CheckboxTableField = (props) => {
  const { formValues, id, name } = props;

  const { isRequired, validators } = useDynamicFieldRequiredValidators(props);
  const cachedValidators = useMemoizedConditionalValidate(!formValues.ui?.blockDetailsSections, validators);
  const isDisabled = useTableCellFieldIsDisabled(props);

  return (
    <div className="column-content align-items--center">
      <Field
        component={Checkbox}
        id={id}
        isDebounced
        isDisabled={isDisabled}
        isRequired={isRequired}
        name={name}
        validate={cachedValidators}
      />
    </div>
  );
};

CheckboxTableField.propTypes = {
  ...propTypes.tableFieldSharedPropTypes,
  id: PropTypes.string.isRequired,
};

CheckboxTableField.defaultProps = {
  ...propTypes.tableFieldSharedDefaultProps,
};

export default CheckboxTableField;
