import { DateFormats } from '@routable/shared';
import classNames from 'classnames';
import React from 'react';

import { IconNames, Text, TooltipMUIConditionalWrapper } from 'components';

import { typography } from 'constants/styles';

import { getCountryNameFromCode } from 'helpers/countries';

import { useClipboard, useCountryCodesContext, useToggle } from 'hooks';

import { formatDateString } from '../../helpers/date';

import type { CompanyAddressDataCardProps } from './CompanyAddressDataCard.types';
import {
  CompanyAddressDataCardAction,
  CompanyAddressDataCardActionButton,
  CompanyAddressDataCardHeader,
  CompanyAddressDataCardItem,
  CompanyRegisteredAddressDisplayValue,
} from './components';

import './CompanyAddressDataCard.scss';

/**
 * Component rendering Company's (mailing) Address Data Card
 * @param props - Component props
 */
const CompanyAddressDataCard = ({
  address,
  className,
  disabledTooltipProps,
  disableManageActions,
  hasError,
  isEdittingDisabled,
  isSubmitting,
  onEdit,
  onDelete,
}: CompanyAddressDataCardProps): JSX.Element => {
  const [isExpanded, toggleExpanded] = useToggle();
  const countryCodesMap = useCountryCodesContext();

  const countryName = getCountryNameFromCode(countryCodesMap, address.country);
  const fullAddress = `${address.streetAddress}, ${address.postalcode} ${address.city}, ${countryName}`;

  const ref = useClipboard({ copyValue: fullAddress });

  let createdDateString = null;
  let updatedDateString = null;
  if (address?.createdByUserFullName) {
    createdDateString = `Added on ${formatDateString(address.created, DateFormats.LOCAL_DATE_SHORT)}`;
  }

  if (address?.lastUpdatedByUserFullName) {
    updatedDateString = `Updated on ${formatDateString(address.modified, DateFormats.LOCAL_DATE_SHORT)}`;
  }

  return (
    <div className={classNames('company-address-data-card', className)}>
      <CompanyAddressDataCardHeader
        hasError={hasError}
        isExpanded={isExpanded}
        onToggleExpanded={toggleExpanded}
        title={fullAddress}
      />

      <div
        className={classNames('company-address-data-card__expandable', {
          'company-address-data-card__expandable--expanded': isExpanded,
        })}
      >
        <CompanyAddressDataCardItem
          actions={[
            <CompanyAddressDataCardAction
              icon={IconNames.DUPLICATE}
              key="Copy Registered Address"
              ref={ref}
              tooltipTitle="Copy registered address"
            />,
          ]}
          label="Registered Address"
          value={<CompanyRegisteredAddressDisplayValue address={address} countryName={countryName} />}
        />

        {createdDateString && (
          <CompanyAddressDataCardItem
            actions={[
              <CompanyAddressDataCardAction
                icon={IconNames.EYE_OPEN}
                key={createdDateString}
                tooltipTitle={<Text.Regular>{createdDateString}</Text.Regular>}
              />,
            ]}
            label="Added by"
            value={<Text.Regular color={typography.TextColor.BLACK}>{address.createdByUserFullName}</Text.Regular>}
          />
        )}

        {updatedDateString && (
          <CompanyAddressDataCardItem
            actions={[
              <CompanyAddressDataCardAction
                icon={IconNames.EYE_OPEN}
                key={updatedDateString}
                tooltipTitle={<Text.Regular>{updatedDateString}</Text.Regular>}
              />,
            ]}
            label="Updated by"
            value={<Text.Regular color={typography.TextColor.BLACK}>{address.lastUpdatedByUserFullName}</Text.Regular>}
          />
        )}

        {!disableManageActions && (
          <div className="company-address-data-card__expandable__actions">
            <TooltipMUIConditionalWrapper tooltipProps={disabledTooltipProps}>
              <CompanyAddressDataCardActionButton
                dataTestId="companyAddressEdit"
                icon={IconNames.EDIT}
                isDisabled={isSubmitting || hasError || isEdittingDisabled}
                onClick={onEdit}
              />
            </TooltipMUIConditionalWrapper>
            <TooltipMUIConditionalWrapper tooltipProps={disabledTooltipProps}>
              <CompanyAddressDataCardActionButton
                dataTestId="companyAddressDelete"
                icon={IconNames.TRASH}
                isDisabled={isSubmitting || isEdittingDisabled}
                onClick={onDelete}
              />
            </TooltipMUIConditionalWrapper>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyAddressDataCard;
