import React from 'react';

import { IconNames } from 'components/icon';
import { LinkNewPage } from 'components/link';
import { Text, WhiteSpace } from 'components/text';
import { TooltipMUIIcon } from 'components/tooltip';

import { colors } from 'constants/styles';
import { TextSize } from 'constants/styles/typography';

import type { InfoIconWithTooltipProps } from './InfoIconWithTooltip.types';

/**
 * Info icon with a tooltip
 */
export const InfoIconWithTooltip = ({ link, text }: InfoIconWithTooltipProps): JSX.Element => (
  <TooltipMUIIcon
    icon={IconNames.INFO_SIGN}
    iconColor={colors.colorGreyMed}
    title={
      <Text.Regular size={TextSize.LEVEL_100}>
        {text}
        <WhiteSpace />
        {link && (
          <LinkNewPage className="primary" href={link}>
            Learn more
          </LinkNewPage>
        )}
      </Text.Regular>
    }
    tooltipProps={{ arrow: true }}
  />
);
