import React from 'react';

import { Text } from 'components';

import { VendorNeedsToAcceptInviteTooltipContentProps } from './VendorNeedsToAcceptInviteTooltipContent.types';

/**
 * Component rendering the content of the tooltip when the Request Tax Info
 * button is disabled due to partner already having an active invite
 * @param props - Component props
 */
const VendorNeedsToAcceptInviteTooltipContent = ({
  partnerName,
}: VendorNeedsToAcceptInviteTooltipContentProps): JSX.Element => (
  <Text.Regular>{`${partnerName} has to accept the invite before you can request tax information`}</Text.Regular>
);

export default VendorNeedsToAcceptInviteTooltipContent;
