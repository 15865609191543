import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParam } from 'react-use';
import { z } from 'zod';

import { useSubmitW9Mutation } from 'ducks/routableApi';

import { updateCompany } from 'actions/companies';
import { externalOnboardingIncrementStep } from 'actions/externalOnboarding';
import { setW9FormIsSubmittingState, setW9FormIsValidState } from 'actions/partnershipRequests';
import { showErrorUi } from 'actions/ui';

import { taxCertificationType, taxFormId, TaxFormRenderer, taxFormType, type W9FormModel } from 'complexComponents';

import { ErrorIndicatorMessages } from 'constants/ui';

import { isCompanyPlatformTypeInInviteStage } from 'helpers/currentCompany';

import type { Company } from 'interfaces/company';

import { companyFromQuerySelector } from 'queries/companyCompoundSelectors';

import { partnershipRequestMembershipIdSelector } from 'selectors/partnershipRequestSelectors';

import { payloadToCamelCase } from 'services/api/formatHelpers';

import { getDerivedApiFieldErrors } from '../taxForms.helpers';

/**
 * Component rendering the Substitute W-9 Form in the scope
 * of the Accept Partnership External Flow
 */
export const SubstituteW9Form = (): JSX.Element => {
  // Search params hooks
  const partnershipId = useSearchParam('partnership_id');

  // API hooks
  const [submitW9] = useSubmitW9Mutation();

  // Redux hooks
  const membershipId = useSelector(partnershipRequestMembershipIdSelector);
  const company: Company = useSelector(companyFromQuerySelector);
  const dispatch = useDispatch();

  const isPlatformTypeInInviteStage = isCompanyPlatformTypeInInviteStage(company?.platformType);

  const handleFormSubmit = async (values: W9FormModel): Promise<void | Record<string, string>> => {
    try {
      // Since we're dealing with query params & loose redux state values,
      // we first want to make sure that the partnership and membership ids
      // are in fact UUIDs. If they're not, we want to throw an error before
      // making any API calls.
      const parsedMembershipId = z.string().uuid().parse(membershipId);
      const parsedPartnershipId = z.string().uuid().parse(partnershipId);

      // We submit the form via the API and await for the API resolution. This
      // will ensure that the form remains in "submitting" state for as long as
      // we are waiting for the API response.
      const data = await submitW9({
        membershipId: parsedMembershipId,
        partnershipId: parsedPartnershipId,
        values,
      }).unwrap();

      // Finally, if everything went well, we want to increment the
      // external onboarding step and update the current company values with
      // the data received. Since the component will unmount before
      // it will receive form submitting state change, we also need to
      // manually set it to false.
      dispatch(updateCompany(data));
      dispatch(externalOnboardingIncrementStep());
      dispatch(setW9FormIsSubmittingState(false));
    } catch (err) {
      const errors = getDerivedApiFieldErrors(err);

      // If we have a general error, we want to use that message in the
      // error toast. Otherwise, we use the generic error message.
      if (errors?.general) {
        dispatch(showErrorUi(errors.general.detail));
      } else {
        dispatch(showErrorUi(ErrorIndicatorMessages.AN_ERROR_OCCURRED));
      }

      // If we have field-level errors, we want to transform
      // the field names from snake_case to camelCase and return the
      // errors, so the form renderer can use them to set errors
      // internally.
      if (errors?.fields) {
        return payloadToCamelCase(errors.fields);
      }
    }

    // If there are no errors, we return null from this handler.
    return null;
  };

  const handleFormValidStateChange = (isValid: boolean) => {
    dispatch(setW9FormIsValidState(isValid));
  };

  const handleFormSubmitStateChange = (isSubmitting: boolean) => {
    dispatch(setW9FormIsSubmittingState(isSubmitting));
  };

  return (
    <TaxFormRenderer
      defaultValues={{
        certificationType: taxCertificationType.Enum.signedElectronically,
        companyType: company?.companyType,
      }}
      formId={taxFormId.Enum.ext_substituteW9Form}
      formType={taxFormType.Enum.W9}
      isSignatureAllowed
      onFormSubmitStateChange={handleFormSubmitStateChange}
      onFormValidStateChange={handleFormValidStateChange}
      onSubmit={handleFormSubmit}
      showCompanyTypeField={isPlatformTypeInInviteStage}
    />
  );
};
