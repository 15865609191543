import { BaseMessage, Transport } from 'milton/logging';

import { LogLevel } from '../systemLogger.types';

export const withLogLevelFilter = <T extends BaseMessage & { level: LogLevel }>(
  transport: Transport<T>,
  filterLevel: LogLevel,
  levelMap: { [key in LogLevel]: number } = {
    TRACE: 10,
    DEBUG: 20,
    INFO: 30,
    WARN: 40,
    ERROR: 50,
    FATAL: 60,
  },
): Transport<T> => ({
  name: transport.name,
  transport: async (messages) => {
    const filteredMessages = messages.filter((message) => levelMap[message.level] >= levelMap[filterLevel]);
    if (filteredMessages.length > 0) {
      transport.transport(filteredMessages);
    }
  },
});
