import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import type { ReduxState } from 'interfaces/redux';

import { ledgerNameSelector, isLedgerIntegrationConnectedSelector } from 'selectors/integrationsSelectors';

import PartnershipNameSettingsForm from './PartnershipNameSettingsForm';
import { StateProps } from './PartnershipNameSettingsFormProps.types';

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  isLedgerIntegrationConnected: isLedgerIntegrationConnectedSelector,
  ledgerName: ledgerNameSelector,
});

export default connect(mapStateToProps)(PartnershipNameSettingsForm);
