import { combineReducers } from 'redux';

import { ItemState } from 'interfaces/redux/';

import itemsAllIdsReducer from './itemsAllIdsReducer';
import itemsByIdReducer from './itemsByIdReducer';
import itemsDeleteReducer from './itemsDeleteReducer';
import itemsErrorReducer from './itemsErrorReducer';
import itemsFetchReducer from './itemsFetchReducer';
import itemsPaginationReducer from './itemsPaginationReducer';
import itemsSearchReducer from './itemsSearchReducer';
import itemsSubmitReducer from './itemsSubmitReducer';
import itemsUpdateReducer from './itemsUpdateReducer';
import itemsUploadBillsReducer from './itemsUploadBillsReducer';
import singleItemRequests from './singleItemRequestsReducer';

const itemsReducer = combineReducers<ItemState>({
  allIds: itemsAllIdsReducer,
  byId: itemsByIdReducer,
  isDeleting: itemsDeleteReducer,
  errors: itemsErrorReducer,
  isFetching: itemsFetchReducer,
  pagination: itemsPaginationReducer,
  search: itemsSearchReducer,
  submit: itemsSubmitReducer,
  isUpdating: itemsUpdateReducer,
  uploadBills: itemsUploadBillsReducer,
  singleItemRequests,
});

export default itemsReducer;
