import React from 'react';

import { Text, TooltipMUIConditionalWrapper } from 'components';

import { CurrencyCodeUsd } from 'constants/currency';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { typography } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';

import type { PreventInviteAndAddForInternationalVendorTooltipProps } from './PreventInviteAndAddForInternationalVendorTooltip.types';

/**
 * Tooltip notifying the user why they cannot Invite/Add an international tooltip when XB flag is off
 * @see {LedgerPartnershipsListAction}
 */
const PreventInviteAndAddForInternationalVendorTooltip = ({
  children,
  isShown,
}: PreventInviteAndAddForInternationalVendorTooltipProps): React.ReactElement => (
  <TooltipMUIConditionalWrapper
    tooltipProps={
      isShown
        ? {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            title: (
              <Text.Regular
                color={typography.TextColor.GREY_X_DARK}
                lineHeight={typography.TextLineHeight.MEDIUM}
                size={typography.TextSize.LEVEL_100}
              >
                {`We cannot invite or add this vendor to ${PLATFORM_DISPLAY_SHORT_NAME} `}
                {`because you haven't yet enabled payments to vendors using currencies other than ${CurrencyCodeUsd}. `}
                Please speak to a Routable Customer Success Manager to enable this feature.
              </Text.Regular>
            ),
          }
        : {}
    }
  >
    {children}
  </TooltipMUIConditionalWrapper>
);

export default PreventInviteAndAddForInternationalVendorTooltip;
