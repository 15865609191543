import PropTypes from 'prop-types';
import React from 'react';

import { radioPropTypes, radioDefaultProps } from 'components/commonProps';

import { getClassNames } from 'helpers/ui';

import RadioInput from '../RadioInput';

import '../Radio.scss';

/**
 * A wrapper component for RadioInput.
 * @param {Object} props
 * @param {String} [props.id]
 * @param {Object} [props.input]
 * @param {Boolean} [props.isHorizontalLayout]
 * @param {Boolean} [props.isRequired]
 * @param {String} [props.optionText]
 * @param {String} [props.subText]
 * @param {String} props.type
 * @returns {StatelessComponent}
 */
const Radio = ({
  content,
  id,
  input,
  isDisabled,
  isHorizontalLayout,
  isRequired,
  optionText,
  subText,
  tooltipProps,
  ...rest
}) => {
  const radioClasses = getClassNames(rest, {
    active: !!input.checked,
    disabled: !!isDisabled,
    horizontal: !!isHorizontalLayout,
    'radio--container': true,
  });

  const headerClasses = getClassNames(
    {},
    {
      'has-content': !!content,
      'radio--header': true,
    },
  );

  return (
    <div className={radioClasses}>
      <div className={headerClasses}>
        <RadioInput
          id={id}
          input={input}
          isDisabled={isDisabled}
          isRequired={isRequired}
          optionText={optionText}
          subText={subText}
          tooltipProps={tooltipProps}
          {...rest}
        />
      </div>

      {!!content && <div className="radio--content">{content}</div>}
    </div>
  );
};

Radio.propTypes = {
  ...radioPropTypes,
  content: PropTypes.node,
  id: PropTypes.string,
  isHorizontalLayout: PropTypes.bool,
  input: PropTypes.shape(),
  optionText: PropTypes.string,
  subText: PropTypes.string,
  tooltipProps: PropTypes.shape(),
  type: PropTypes.string,
};
Radio.defaultProps = {
  ...radioDefaultProps,
  content: undefined,
  formState: undefined,
  tooltipProps: undefined,
};

export default Radio;
