import { Tooltip } from '@routable/components';
import React from 'react';
import { useDispatch } from 'react-redux';

import { toggleDeactivateTeamMemberModal } from 'actions/signUpFlow';

import { IconNames } from 'components';
import { Icon } from 'components/icon';

import { colors } from 'constants/styles';

import DeactivateTeamMemberModal from './DeactivateTeamMemberModal';
import type { TeamMemberDeactivateCellProps } from './TeamMemberDeactivateCell.types';

const TeamMemberDeactivateCell = ({
  currentMembershipId,
  onDeactivate,
  membership,
  firstName,
  lastName,
  deactivateTeamMemberModal,
}: TeamMemberDeactivateCellProps): JSX.Element => {
  const dispatch = useDispatch();

  const { id: membershipId } = membership;
  const tooltipProps = {
    active: {
      content: 'Deactivate team member',
      iconClassNames: 'hover--blue-bold cursor--pointer',
      variant: 'dark',
    },
    inactive: {
      content: "You can't deactivate your own account.",
      iconClassNames: 'icon-tooltip',
      variant: 'light',
    },
  } as const;

  const isDeactivateDisabled = membershipId === currentMembershipId;
  const { content, variant, iconClassNames } = isDeactivateDisabled ? tooltipProps.inactive : tooltipProps.active;

  const handleDeactivate = () => {
    onDeactivate({ membership: { id: membershipId, firstName, lastName } });
    dispatch(toggleDeactivateTeamMemberModal(membershipId));
  };

  return (
    <div className="w-full flex items-center justify-center">
      <DeactivateTeamMemberModal
        deactivateMember={handleDeactivate}
        firstName={firstName}
        lastName={lastName}
        membershipId={membershipId}
        open={deactivateTeamMemberModal[membershipId]}
      />
      <Tooltip data-testid="deactivate-team-member-tooltip" tooltip={content} variant={variant}>
        <button
          aria-label="Deactivate team member"
          disabled={isDeactivateDisabled}
          onClick={() => dispatch(toggleDeactivateTeamMemberModal(membershipId))}
          type="button"
        >
          <Icon className={iconClassNames} color={colors.colorGreyMed} icon={IconNames.REMOVE} />
        </button>
      </Tooltip>
    </div>
  );
};

export default TeamMemberDeactivateCell;
