import React from 'react';
import { useSelector } from 'react-redux';

import type { UnknownObject } from 'interfaces/global';
import type { ReduxState } from 'interfaces/redux';

/**
 * Custom hook for mapping props (or any extra arguments, really) to the useSelector Redux hook.
 * @example
 * const Component: React.FC<ComponentProps> = (props) => {
 *   const value = useSelectorWithProps<OutputOfSelector>(someSelector, props);
 *
 *   return <Child value={value} />
 * }
 * @param selector
 * @param props
 */
const useSelectorWithProps = <Output = unknown, Props = UnknownObject>(
  selector: (state: ReduxState, props: Props) => Output,
  props: Props,
): Output => {
  const selectorWithMappedProps = React.useCallback((state) => selector(state, props), [selector, props]);

  const value = useSelector<ReduxState, Output>(selectorWithMappedProps);

  return value;
};

export default useSelectorWithProps;
