import { TaxDocType } from 'constants/taxes';

export const penaltiesOfPerjurySectionsW9 = [
  {
    id: 'section-1',
    text: "1. The number shown on this form is my correct taxpayer identification number (or I'm waiting for a number to be issued to me).",
  },
  {
    id: 'section-2',
    text: "2. One or more of the following is true: I'm not subject to backup withholding. The Internal Revenue Service hasn't told me that I'm subject to backup withholding because I failed to report all interest or dividends. The IRS has told me that I'm no longer subject to backup withholding.",
  },
  {
    id: 'section-3',
    text: '3. I am a US citizen or other US person.',
  },
  {
    id: 'section-4',
    text: "4. If I entered any FATCA codes on this form to suggest that I'm exempt from FATCA reporting, those codes are correct.",
  },
  {
    id: 'section-5',
    text: "The Internal Revenue Service doesn't require your consent to any provision of this document, other than the certifications required to avoid backup withholding.",
  },
];

export const penaltiesOfPerjurySectionsW8BEN = [
  {
    id: 'section-1',
    text: '• I am the individual/authorized signer that is the beneficial owner of all the income or proceeds to which this form relates or am using this form to document myself for chapter 4 purposes;',
  },
  {
    id: 'section-2',
    text: '• The person named in this form is not a U.S. person;',
  },
  {
    id: 'section-3',
    text: '• This form relates to: (a) income not effectively connected with the conduct of a trade or business in the United States; (b) income effectively connected with the conduct of a trade or business in the United States but is not subject to tax under an applicable income tax treaty; (c) the partner’s share of a partnership’s effectively connected taxable income; or (d) the partner’s amount realized from the transfer of a partnership interest subject to withholding under section 1446(f);',
  },
  {
    id: 'section-4',
    text: '• The person named in this form is a resident of the treaty country (if any) within the meaning of the income tax treaty between the United States and that country; and',
  },
  {
    id: 'section-5',
    text: '• For broker transactions or barter exchanges, the beneficial owner is an exempt foreign person as defined in the instructions.',
  },
  {
    id: 'section-6',
    text: 'Furthermore, I authorize this form to be provided to any withholding agent that has control, receipt, or custody of the income of which I am the beneficial owner or any withholding agent that can disburse or make payments of the income of which I am the beneficial owner. I agree that I will submit a new form within 30 days if any certification made on this form becomes incorrect.',
  },
];

type PenaltiesOfPerjurySections = Record<
  typeof TaxDocType.W8BEN | typeof TaxDocType.W9,
  Array<{
    id: string;
    text: string;
  }>
>;

/**
 * Sections (texts) rendered by the Penalties of Perjury Hint. The "id"
 * property is added for easier/better dealing with .map() key prop, it
 * doesn't hold any real meaning, just has to be unique
 */
export const penaltiesOfPerjurySections: PenaltiesOfPerjurySections = {
  [TaxDocType.W8BEN]: penaltiesOfPerjurySectionsW8BEN,
  [TaxDocType.W9]: penaltiesOfPerjurySectionsW9,
};
