import classNames from 'classnames';
import React from 'react';

import type { TableToolbarProps } from './TableToolbar.types';

import './TableToolbar.scss';

/**
 * A toolbar that houses actions, etc, that apply to selected table entries.
 * @type {React.FC<TableToolbarProps>}
 */
const TableToolbar: React.FC<TableToolbarProps> = ({ children, className }) => (
  <div className="table-toolbar--wrapper">
    <div className={classNames('table-toolbar', className)}>{children}</div>
  </div>
);

TableToolbar.defaultProps = {
  children: undefined,
  className: undefined,
};

export default TableToolbar;
