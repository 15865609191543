import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ssoLoginRoutine } from 'actions/routines/auth';
import { openNotificationBar } from 'actions/ui';

import { IconNames } from 'components/icon';

import { Intent } from 'constants/ui';

import { handleAddAuthTokenAndCurrentIds } from 'helpers/auth';
import { QuickswitchPayload } from 'helpers/auth.types';
import { getCurrentMembershipId, handleClearLocalStorage } from 'helpers/localStorage';
import { getQueryParamValueFromUrl } from 'helpers/queryParams';
import { triggerOtherTabsToRefresh } from 'helpers/refreshTabs';
import { systemLogger, LogLevel } from 'helpers/systemLogger';

import { Auth0Client } from 'services/auth0';

import QuickswitchToBrandedSubdomainContent from './QuickswitchToBrandedSubdomainContent';

const QuickswitchToBrandedSubdomain = (): JSX.Element => {
  const dispatch = useDispatch();
  const [goToDashboard, setGoToDashboard] = useState(false);
  const [goToLogin, setGoToLogin] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [companyName, setCompanyName] = useState(undefined);

  const parseAuthData = (): QuickswitchPayload => {
    const dataParam = getQueryParamValueFromUrl('data');
    return JSON.parse(decodeURIComponent(dataParam));
  };

  useEffect(() => {
    try {
      const authData = parseAuthData();
      const { shouldAuthenticateWithSSO, companyId, userEmail, isAuthenticatedWithSSO, membershipId } = authData;
      setCompanyName(authData.companyName);
      const currentMembershipId = getCurrentMembershipId();
      let shouldRefreshOtherTabs = false;

      /*
      If there's data stored in local storage from a different membership
      than the one we're switching to, we clear local storage first.
      */
      if (currentMembershipId && currentMembershipId !== membershipId) {
        Auth0Client.logout({ openUrl: false });
        handleClearLocalStorage();
        shouldRefreshOtherTabs = true;
        if (!shouldAuthenticateWithSSO) {
          triggerOtherTabsToRefresh();
        }
      }

      /*
        Specifically added to handle switching from user/pass companies
        to SSO required. For those cases we cannot go straight to the Google
        SSO flow, as we also need to support both SSO or admins logging in
        with user/pass.
      */
      if (shouldAuthenticateWithSSO && !isAuthenticatedWithSSO) {
        setGoToLogin(true);
        return;
      }

      handleAddAuthTokenAndCurrentIds(authData);
      if (shouldAuthenticateWithSSO) {
        const payload = {
          companyId,
          companyName: authData.companyName,
          ssoProvider: authData.ssoProvider,
          ssoProviderConnection: authData.ssoProviderConnection,
          isAuthenticatedWithSSO,
          shouldRefreshOtherTabs,
          userEmail,
        };
        dispatch({ ...ssoLoginRoutine.trigger(), payload });
      } else {
        setGoToDashboard(true);
        dispatch(
          openNotificationBar(`You have successfully switched your account to ${authData.companyName}.`, {
            icon: IconNames.TICK_CIRCLE,
            intent: Intent.SUCCESS,
          }),
        );
      }
    } catch (error) {
      systemLogger.log({ level: LogLevel.ERROR, error });
      setShowErrorModal(true);
    }
  }, [dispatch]);

  return (
    <QuickswitchToBrandedSubdomainContent
      companyName={companyName}
      goToDashboard={goToDashboard}
      goToLogin={goToLogin}
      showErrorModal={showErrorModal}
    />
  );
};

export default QuickswitchToBrandedSubdomain;
