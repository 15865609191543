import { getItemKindFromExternalPath } from 'helpers/external';

import { UnknownObject } from 'interfaces/global';

import {
  GetUpdatedReceivableFormValueOptions,
  GetUpdatedReceivableFormValueReturnType,
} from './UpdatePaymentMethodFormInternational.types';

/**
 * Handler to bind an onSubmit function and create the correct payload.
 *
 * @function
 * @param {Function} onSubmit
 * @returns {Function}
 */
export const createSubmitHandler =
  (onSubmit: (Object) => void) =>
  async (formValues: UnknownObject): Promise<void> =>
    onSubmit({
      defaultReceivable: formValues.defaultReceivable,
      itemKind: getItemKindFromExternalPath(),
    });

/**
 * When a external vendor adds a new funding account, we want to select the most recently added funding account from the
 * list of available accounts.
 *
 * @param options - Object containing current default receivable and last submitted funding account id
 */
export const getUpdatedReceivableFormValue = ({
  defaultReceivable,
  lastSubmittedFundingAccountId,
  previousLastSubmittedFundingAccountId,
}: GetUpdatedReceivableFormValueOptions): GetUpdatedReceivableFormValueReturnType => {
  const justAddedNewAccount = previousLastSubmittedFundingAccountId !== lastSubmittedFundingAccountId;

  // if the user just added a new funding account
  if (lastSubmittedFundingAccountId && justAddedNewAccount) {
    // select the new funding account
    const updatedReceivable = {
      ...defaultReceivable,
      fundingAccount: lastSubmittedFundingAccountId,
    };
    return {
      shouldUpdate: true,
      updatedReceivable,
    };
  }

  // preserve the user's funding account selection
  return { shouldUpdate: false };
};
