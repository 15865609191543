import React from 'react';

import { useDebouncedField } from 'hooks';

import NumericInputWrapper from './NumericInputWrapper';
import { ReduxFormFieldRenderNumericInputProps } from './ReduxFormFieldRenderNumericInput.types';

const ReduxFormFieldRenderNumericInput: React.FC<ReduxFormFieldRenderNumericInputProps> = ({
  debounceDelay,
  input,
  isDebounced,
  meta,
  ...rest
}) => {
  const { onChange, value } = useDebouncedField({
    debounceDelay,
    isDebounced,
    input,
  });

  const errors = (meta.touched || meta.submitFailed) && meta.error;

  return (
    // @ts-expect-error types
    <NumericInputWrapper
      {...rest}
      errors={errors}
      hasErrors={!!errors}
      name={input.name}
      onBlur={input.onBlur}
      onChange={onChange}
      onFocus={input.onFocus}
      value={value as string | number}
    />
  );
};

export default ReduxFormFieldRenderNumericInput;
