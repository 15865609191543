import { CurrencyCodeUsd } from 'constants/currency';

import { formatCurrencyExplicit, formatCurrencyUSDExplicit } from 'helpers/currencyFormatter';

import type { CurrencyCode, CurrencyCodeSelectOption } from 'interfaces/currency';
import type { ParseCurrencyOptions } from 'interfaces/parseCurrencyOptions';

/**
 * Rejects multi zeroes before decimal separator.
 * Contemplates optional dollar sign $ at the beginning of the string,
 * so this can be used in places where $ is not present
 */
export const rejectMultiZeroesBeforeDecimalSeparator = (value = ''): boolean => !/^(\$)?(0{2,})(\.)/.test(value);

/**
 * Returns exchange rate text
 * @example
 * getExchangeRateText(1.33, CAD, currencyCodeMap) -> "$1 USD = $1.33 CAD"
 */
export const getExchangeRateText = (
  amount: string,
  currencyCode: string,
  currencyCodeMap: CurrencyCodeMap,
  options?: ParseCurrencyOptions,
): string =>
  `${formatCurrencyUSDExplicit(1)} = ${formatCurrencyExplicit(amount, currencyCode, currencyCodeMap, options)}`;

/**
 * Getter function that returns CurrencyCodeDetail from give currency code
 */
export const getCurrencyFromCode = (currencyCodeMap: CurrencyCodeMap, currencyCode = ''): CurrencyCodeDetail =>
  currencyCodeMap[currencyCode.toUpperCase()];

/**
 * Getter function that returns the name of a specific currency by its code.
 */
export const getCurrencyNameFromCode = (currencyCodeMap: CurrencyCodeMap, currencyCode = ''): string =>
  getCurrencyFromCode(currencyCodeMap, currencyCode)?.name;

/**
 * Getter function that returns currency symbol from given currency code
 */
export const getSymbolFromCode = (currencyCodeMap: CurrencyCodeMap, currencyCode = ''): string =>
  currencyCodeMap[currencyCode.toUpperCase()]?.symbol || '';

/**
 * Check if the currencyCode is USD.
 */
export const isCurrencyCodeUSD = (currencyCode?: string): boolean => currencyCode === CurrencyCodeUsd;

/**
 * Check if the currencyCode is non-USD.
 */
export const isCurrencyCodeNonUSD = (currencyCode?: string): boolean =>
  !currencyCode || !isCurrencyCodeUSD(currencyCode);

/**
 * Map a currency code to a select option object
 */
export const mapCurrencyCodeToSelectOption = (currencyCode: CurrencyCode): CurrencyCodeSelectOption => ({
  id: currencyCode,
  value: currencyCode,
  text: currencyCode,
});
