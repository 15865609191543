import React from 'react';

import { FormFieldProps } from 'interfaces/forms';

import { EnterExistingPasswordError } from './components';
import { EXISTING_ROUTABLE_ACCOUNT_ERROR } from './constants';

/**
 * Replaces error message with react element that contains link.
 * @param index - This param is unused since this function is
 * meant to be used inside an array map method.
 */
export const replaceExistingAccountError = (error: string, index: number, errors: string[]): React.ReactNode =>
  error === EXISTING_ROUTABLE_ACCOUNT_ERROR ? React.createElement(EnterExistingPasswordError, { errors }) : error;

/**
 * Updates the error messsages in field input props
 */
export const replaceExistingAccountErrorFromProps = (props: FormFieldProps): FormFieldProps => {
  // Avoid changing props when 'errors' is an object
  if (!Array.isArray(props?.meta?.error)) {
    return props;
  }

  const error = props.meta.error.map(replaceExistingAccountError);

  return {
    ...props,
    meta: {
      ...props.meta,
      error,
    },
  };
};
