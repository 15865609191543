import React from 'react';

import { ButtonV2, FlexRow, LinkNewPage, Text } from 'components';

import { typography } from 'constants/styles';

import { getExternalItemsItemButtonTextFromItemKind } from 'helpers/external';
import { isExternalItemActionable } from 'helpers/items';
import { getExternalV2ItemSimilarRoute } from 'helpers/routeHelpers';
import { getOriginal } from 'helpers/tables';
import { ternary } from 'helpers/utility';

import { Item } from 'interfaces/item';

import ExternalItemsListTableText from '../ExternalItemsListTableText';

import { ExternalItemsListTableInvoiceNumberProps } from './ExternalItemsListTableInvoiceNumber.types';

/**
 * Component rendering Invoice Number in External Items Table
 * @param {ExternalItemsListTableInvoiceNumberProps} props
 * @returns {StatelessComponent}
 */
const ExternalItemsListTableInvoiceNumber: React.FC<ExternalItemsListTableInvoiceNumberProps> = ({
  fallbackText,
  hideLink = false,
  row,
  value,
}) => {
  const item = getOriginal<Item>({ row });
  const itemUrl = getExternalV2ItemSimilarRoute(item);
  const buttonText = getExternalItemsItemButtonTextFromItemKind(item.kind);
  const displayValue = value || fallbackText;

  return (
    <FlexRow className="align-items--center flex--1 justify-content--space-between" stackOnMobile={false}>
      {ternary(
        hideLink,
        <ExternalItemsListTableText
          color={ternary(value, typography.TextColor.GREY_X_DARK, typography.TextColor.GREY_DARK)}
          value={displayValue}
        />,
        <>
          <LinkNewPage href={itemUrl}>
            <Text.Bold color={typography.TextColor.BLUE_BOLD} size={typography.TextSize.LEVEL_100}>
              {displayValue}
            </Text.Bold>
          </LinkNewPage>
          {isExternalItemActionable(item) && (
            <LinkNewPage className="enable-pointer-events" href={itemUrl}>
              <ButtonV2>{buttonText}</ButtonV2>
            </LinkNewPage>
          )}
        </>,
      )}
    </FlexRow>
  );
};

export default ExternalItemsListTableInvoiceNumber;
