import React from 'react';

import { ContactAutofillIcons } from 'complexComponents/ContactAutofillIcons';

import { FlexRow } from 'components';

import { isContactsInteractionListViewTypeThread } from 'helpers/ui';

import { useContactsInteractionListViewContext } from 'hooks';

import { ContactListItemMenu, ContactListItemTitle } from './components';
import { ContactListItemProps } from './ContactListItem.types';

/**
 * An item representing a partnership member resource, in the ContactsInteractionList.
 */
const ContactListItem = (props: ContactListItemProps): JSX.Element => {
  const { contact } = props;
  const view = useContactsInteractionListViewContext();

  return (
    <FlexRow className="justify-content--space-between" stackOnMobile>
      <ContactListItemTitle contact={contact} />

      <FlexRow className="align-items--center justify-content--flex-end" stackOnMobile={false}>
        {!isContactsInteractionListViewTypeThread(view) && <ContactAutofillIcons partnershipMember={contact} />}

        <ContactListItemMenu {...props} />
      </FlexRow>
    </FlexRow>
  );
};

export default ContactListItem;
