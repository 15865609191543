import classNames from 'classnames';
import React from 'react';
import { Field } from 'redux-form';

import { loginHandler } from 'actions/routines/auth';

import { ButtonV2, FormControl, FormFieldLabel, IconNames, ReduxFormFieldRenderInput, Form } from 'components';

import { ButtonSize } from 'constants/button';
import { formNamesAuthV2 } from 'constants/forms';
import { formStyles, sizes } from 'constants/styles';

import { emailValidator, requiredValidator } from 'helpers/fieldValidation';

import InvalidLoginCredentialsHint from '../InvalidLoginCredentialsHint';

import { EmailPasswordFormProps } from './EmailPasswordForm.types';

import './EmailPasswordForm.scss';

/**
 * EmailPasswordForm
 * This is the regular username/password form.
 */
const EmailPasswordForm: React.FC<EmailPasswordFormProps> = ({
  isSubmitting,
  handleSubmit,
  loginBtnText = 'Log in',
  usernameFieldLabel,
  onClearLoginErrorState,
  errors,
}) => {
  React.useEffect(() => {
    onClearLoginErrorState();
  }, [onClearLoginErrorState]);

  return (
    <Form className="form email-password-form" id={formNamesAuthV2.LOGIN} onSubmit={handleSubmit(loginHandler)}>
      {usernameFieldLabel && <FormFieldLabel className="username-field-label">{usernameFieldLabel}</FormFieldLabel>}
      <FormControl>
        <Field
          className={classNames({
            [formStyles.field.xl.full]: true,
            'input-height--xl': true,
          })}
          component={ReduxFormFieldRenderInput}
          dataFullStory
          name="form.username"
          placeholder="Email"
          type="email"
          validate={[requiredValidator, emailValidator]}
        />
      </FormControl>

      <FormControl>
        <Field
          className={classNames({
            [formStyles.field.xl.full]: true,
            'input-height--xl': true,
          })}
          component={ReduxFormFieldRenderInput}
          dataFullStory
          name="form.password"
          placeholder="Password"
          type="password"
          validate={requiredValidator}
        />
      </FormControl>

      <InvalidLoginCredentialsHint errors={Boolean(errors)} />

      <div className="margin-top--m-large">
        <ButtonV2
          htmlFor={formNamesAuthV2.LOGIN}
          id="auth_login_btn"
          isLoading={isSubmitting}
          rightIconName={IconNames.ARROW_RIGHT}
          rightIconProps={{ style: { marginLeft: 'auto' } }}
          rightIconSize={sizes.iconSizes.LARGE}
          size={ButtonSize.LARGE}
          type="submit"
        >
          {loginBtnText}
        </ButtonV2>
      </div>
    </Form>
  );
};

export default EmailPasswordForm;
