import React from 'react';

import { IconNames } from 'components/icon';
import { TooltipMUI } from 'components/tooltip';

import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { getDisplayName } from 'helpers/hoc';

import PaymentMethodDetailItemAction from '../../PaymentMethodDetailItemAction';
import { PaymentMethodDetailAction } from '../enums';
import type { PaymentMethodDetailItemProps } from '../PaymentMethodDetailItem.types';

/**
 * HOC that is meant to add clipboard functionality and icon to PaymentMethodDetailItem
 */
const withTooltipAction = (
  PaymentMethodDetailItem: React.FC<PaymentMethodDetailItemProps>,
): React.FC<PaymentMethodDetailItemProps> => {
  const Component = (props: PaymentMethodDetailItemProps) => {
    const newProps = { ...props };
    const { actions, actionsElements = [], tooltipContent } = props;
    const hasTooltip = actions.includes(PaymentMethodDetailAction.TOOLTIP);

    if (hasTooltip) {
      newProps.actionsElements = [
        ...actionsElements,
        <TooltipMUI
          key="tooltip-action"
          padding={TooltipPadding.SKINNY}
          placement={TooltipPlacement.TOP}
          title={tooltipContent}
        >
          <PaymentMethodDetailItemAction icon={IconNames.TIME} key="timestamp-action" />
        </TooltipMUI>,
      ];
    }

    return <PaymentMethodDetailItem {...newProps} />;
  };

  Component.displayName = `${getDisplayName(PaymentMethodDetailItem)}WithTooltipAction`;
  return Component;
};

export default withTooltipAction;
