import { DateFormats } from '@routable/shared';

import { ItemPaymentMethodsText } from 'constants/item';

import { formatDateString } from 'helpers/date';
import { isPaymentDeliveryMethodAch } from 'helpers/paymentMethods';

import { Transaction } from 'interfaces/transactions';

/**
 * Returns tooltip text for transaction details header.
 */
export const getTransactionDetailsHeaderTooltipText = (transaction: Transaction): string => {
  const dateText = formatDateString(transaction.created, DateFormats.LOCAL_DATE_SHORT);
  const timeText = formatDateString(transaction.created, DateFormats.LOCAL_TIME_AM_PM).toLowerCase();
  const transactionOrNothing = isPaymentDeliveryMethodAch(transaction.paymentDeliveryMethod) ? '' : ' transaction';

  return `This ${
    ItemPaymentMethodsText[transaction.paymentDeliveryMethod]
  }${transactionOrNothing} was initiated on ${dateText} at ${timeText}`;
};
