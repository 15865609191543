import styled from 'styled-components';

import { ButtonV2 } from 'components';

import { containerWidthMap } from './constants/container';
import { FlowStepperMainProps } from './FlowStepperMain.types';

export const Container = styled.div<FlowStepperMainProps>`
  max-width: ${(props) => (props.containerWidth ? props.containerWidth : containerWidthMap.large)};
`;

export const Header = styled.header<FlowStepperMainProps>`
  max-width: ${(props) => (props.containerWidth ? props.containerWidth : containerWidthMap.large)};
`;

export const Main = styled.section`
  padding: 1rem 6rem;

  @media screen and (max-width: 1024px) {
    padding: 1rem;
  }
`;

// using subclass here due to specificity issues
export const BackButton = styled(ButtonV2)`
  &.btn--borderless {
    color: var(--grey--x-dark);
    margin-left: -1.125rem;
    padding: 0;

    &:hover {
      background-color: white;
    }
  }
`;

export const Title = styled.h1`
  color: var(--neutral-black);
  font-size: 1.625rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  margin-top: 1.375rem;
`;

export const Subtitle = styled.h2`
  color: var(--grey--x-dark);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const Footer = styled.footer<FlowStepperMainProps>`
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-top: 2.75rem;
  max-width: ${(props) => (props.containerWidth ? props.containerWidth : containerWidthMap.large)};
`;

export const SkipButton = styled(ButtonV2)`
  &.btn--borderless {
    color: var(--grey--xx-dark);
    padding: 0;
    font-weight: 500;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const Label = styled.label`
  color: var(--grey--xx-dark);
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
`;

export const LabelHelper = styled.p`
  color: var(--grey--x-dark);
  font-size: 1rem;
  line-height: 1.5rem;
`;
