import React from 'react';

import { Text } from 'components';

import { StaticCountryCode } from 'constants/countries';
import { TextSize } from 'constants/styles/typography';

import { getCountryNameFromCode } from 'helpers/countries';
import { firstValue } from 'helpers/utility';

import { useCountryCodesContext } from 'hooks';

import { getTooltipText } from '../helpers/text';

import { CurrencyCodeBlockFieldTooltipTextProps } from './CurrencyCodeBlockFieldTooltipText.types';

const CurrencyCodeBlockFieldTooltipText = ({
  companyIntegrationSettings,
  countryCodePartner,
  currencyCodesBillOptions,
  ledger,
  onlyOneOptionAvailable,
}: CurrencyCodeBlockFieldTooltipTextProps): JSX.Element => {
  const countryCodeMap = useCountryCodesContext();

  if (!onlyOneOptionAvailable) {
    return null;
  }

  const countryCode = countryCodePartner || StaticCountryCode.US;

  // Get the country name based on country code: i.e. FR => France / US => United States
  const countryName = getCountryNameFromCode(countryCodeMap, countryCode);

  /**
   * We extract the first currency because onlyOneOptionAvailable is true, then
   * only one currency is available and we should lock the field. Then we need the
   * tooltip.
   */
  const currencyCode = firstValue(currencyCodesBillOptions).text;

  return (
    <Text.Regular size={TextSize.LEVEL_100}>
      {getTooltipText({
        companyIntegrationSettings,
        currencyCode,
        countryName,
        ledger,
      })}
    </Text.Regular>
  );
};

export default CurrencyCodeBlockFieldTooltipText;
