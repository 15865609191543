import { PaymentDeliveryMethodType } from '@routable/shared';

import type { CurrencyCode } from 'interfaces/currency';
import { type Item, type ItemCreation } from 'interfaces/item';
import type { Partnership } from 'interfaces/partnerships';

import { filterPaymentDeliveryOptions } from './paymentMethods';

export const hasConversion = (
  item: Pick<Item, 'isInternational' | 'currencyCodeReceiver'>,
  partnership: Pick<Partnership, 'currencyCodeCompany'>,
): boolean => item.isInternational && item.currencyCodeReceiver !== partnership.currencyCodeCompany;

export const getConversionCurrency = (
  item: Pick<Item, 'currencyCode' | 'currencyCodeReceiver'>,
  partnership: Pick<Partnership, 'currencyCodeCompany'>,
): CurrencyCode =>
  item.currencyCode === item.currencyCodeReceiver ? partnership.currencyCodeCompany : item.currencyCodeReceiver;

export const isInternationalACHOnlyAvailableMethod = (item: ItemCreation): boolean => {
  // Filter out ANY / vendor's choice
  const filtered = filterPaymentDeliveryOptions(item?.paymentMethodOptions, PaymentDeliveryMethodType.ANY);

  // Return true when only available method is international
  return filtered.length === 1 && filtered[0] === PaymentDeliveryMethodType.INTERNATIONAL;
};
