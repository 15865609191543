import { TablematicTableColumnSelect } from '@routable/components';
import React from 'react';

import { SectionHeader } from './SectionHeaders.styled';

export const POSectionHeaderComponent = ({ columnSelectProps, isOnDetailsPage }) => (
  <SectionHeader isOnDetailsPage={isOnDetailsPage}>
    <div className="flex flex-row justify-between items-center text-xs pl-4 pr-2 h-full">
      <div className="font-semibold">PO lines</div>
      <div>
        <TablematicTableColumnSelect isOnDetailsPage={isOnDetailsPage} {...columnSelectProps} />
      </div>
    </div>
  </SectionHeader>
);
