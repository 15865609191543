import React from 'react';
import { useHistory } from 'react-router-dom';

import { handleAddAuthTokenAndCurrentIds, hasAuthToken } from 'helpers/auth';
import { BRANDED_WORKSPACE_REDIRECT_QUERY_PARAM_KEY } from 'helpers/constants';
import { getQueryParamValueFromUrl } from 'helpers/queryParams';

/**
 * useHandleBrandedWorkspaceRedirect
 * Redirects logged in users to their respective branded subdomains, e.g. app.routable.com -> my-company.routablehq.com
 *
 * This hook is used to deprecated the old app domain and force users to always use the new branded domain.
 */
export const useHandleBrandedWorkspaceRedirect = (): void => {
  const history = useHistory();

  React.useEffect(() => {
    const dataParam = getQueryParamValueFromUrl(BRANDED_WORKSPACE_REDIRECT_QUERY_PARAM_KEY);
    if (dataParam) {
      if (!hasAuthToken()) {
        try {
          const authData = JSON.parse(decodeURIComponent(dataParam));
          handleAddAuthTokenAndCurrentIds(authData);
        } catch (error) {
          return;
        }
      }
      // Remove query parameter 'redirectBrandedSubdomainData'
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('redirectBrandedSubdomainData');
      history.replace(`${newUrl.pathname}${newUrl.search}`);
    }
  }, [history]);
};
