import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fundingAccountFromPartnershipFundingAccount } from 'queries/fundingCompoundSelectors';

import BankAccountHeaderContentRight from './BankAccountHeaderContentRight';
import { StateProps } from './BankAccountHeaderContentRight.types';

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  fundingAccount: fundingAccountFromPartnershipFundingAccount,
});

export default connect<StateProps>(mapStateToProps)(BankAccountHeaderContentRight);
