import { AxiosRequestHeaders } from 'axios';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

import { SSOActionType } from 'enums/sso';

import { sharedFormSubmitRoutinePayloadCreators } from 'helpers/routine';

import { ssoSettingsBaseEndpoint, ssoSettingsByNamespaceBaseEndpoint } from 'services/api/ssoEndpoints';

import { createApiRoutine } from './createApiRoutine';

/**
 * Defines types and creates actions for all fetch-company-sso-settings related side effects.
 * Fetch Current company's sso settings
 */
export const companySettingsSSOFetchRoutine = createApiRoutine(SSOActionType.FETCH_COMPANY_SETTINGS_SSO, {
  endpoint: ssoSettingsBaseEndpoint,
  method: 'GET',
  requireAuth: true,
  headers: {} as AxiosRequestHeaders,
});

/**
 * Defines types and creates actions for company-sso-settings by namespace.
 * Fetch Company's sso settings by namespace
 */
export const companySettingsSSOByNamespaceFetchRoutine = createApiRoutine(
  SSOActionType.FETCH_COMPANY_SETTINGS_SSO_BY_NAMESPACE,
  {
    endpoint: ssoSettingsByNamespaceBaseEndpoint,
    method: 'GET',
    requireAuth: true,
    headers: {} as AxiosRequestHeaders,
  },
  undefined,
  { trigger: () => ({ parseErrorResponseOptions: { muteAlerts: true } }) },
);

export const companySettingsSSOByNamespaceFetchHandler = promisifyRoutine(companySettingsSSOByNamespaceFetchRoutine);

/**
 * Defines types and creates actions for all disable sso related side effects.
 */
export const ssoDisableRoutine = createRoutine(SSOActionType.DISABLE_SSO);

/**
 * Defines types and creates actions for all submit-sso-setup-form related side effects.
 */
export const ssoSetupFormSubmitRoutine = createRoutine(
  SSOActionType.SUBMIT_SSO_SETTINGS,
  sharedFormSubmitRoutinePayloadCreators,
);

export const ssoSetupFormSubmitHandler = promisifyRoutine(ssoSetupFormSubmitRoutine);

/**
 * Defines types and creates actions for all submit-edit-sso-setup-form related side effects.
 */
export const ssoSetupFormEditSubmitRoutine = createRoutine(
  SSOActionType.SUBMIT_UPDATE_SSO_SETTINGS,
  sharedFormSubmitRoutinePayloadCreators,
);

export const ssoSetupFormEditSubmitHandler = promisifyRoutine(ssoSetupFormEditSubmitRoutine);
