import { Address } from '../types/addressTypes.types';

/**
 * Returns a clone of physical address to fill any unfilled mailing address fields
 * Properties on mailing address that do not exist on physical address are ignored
 * @param {Object} physicalAddress
 * @param {Object} mailingAddress
 */
export const getMailingAddressFields = (physicalAddress: Address, mailingAddress: Address): Address =>
  Object.keys(physicalAddress).reduce(
    (previousIteration, currentKey) => ({
      ...previousIteration,
      [currentKey]: mailingAddress[currentKey] ? mailingAddress[currentKey] : physicalAddress[currentKey],
    }),
    {},
  );
