import React from 'react';

import { LabelLink } from 'components/link/LabelLink/LabelLink.styles';
import { WhiteSpace } from 'components/text';

import { ITEM_DETAILS_FILTERS } from 'enums/routes';

import { makeSimilarUrl } from 'helpers/urls';

import { FinalizePaymentHintTextComponentProps } from '../../ContactRequiredToAllowSelectPaymentMethodHint.types';

/**
 * Get the hint text element specific for the finalize payable
 */
const FinalizePaymentHintTextComponent = ({
  history,
  onCloseExistingItemSidePanel,
}: FinalizePaymentHintTextComponentProps): JSX.Element => {
  const partnerTabPath = makeSimilarUrl(ITEM_DETAILS_FILTERS.PARTNER);

  return (
    <span className="contact-required-to-select-payment-hint">
      To allow a vendor to select their payment method, please
      <WhiteSpace />
      <LabelLink
        onClick={() => {
          onCloseExistingItemSidePanel();
          history.push(partnerTabPath);
        }}
      >
        add a contact to this payment
      </LabelLink>
      .
    </span>
  );
};

export default FinalizePaymentHintTextComponent;
