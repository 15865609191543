import { type StatusVariant, statusVariants } from '@routable/gross-ds';

import { isCurrentCompanyAccountVerified } from 'helpers/currentCompany';
import { isDocumentStatusRejected } from 'helpers/document';

import type { Membership } from 'interfaces/memberships';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import {
  lastMembershipDocumentSelector,
  rejectedMembershipDocumentsByMembershipSelector,
} from 'selectors/documentsSelectors';
import {
  allMembershipsWithFailedBeneficiaryStatus,
  memberHasDocumentBeneficiaryStatus,
} from 'selectors/membershipsSelector';

import { storeAccessor as store } from 'store/accessor';

type StatusObj = {
  showStatus: StatusVariant;
  text: string;
};

export const statuses = {
  pending: {
    showStatus: statusVariants.pending,
    text: 'In progress',
  },
  docFailure: {
    showStatus: statusVariants.error,
    text: 'Document failure',
  },
  missingInfo: {
    showStatus: statusVariants.error,
    text: 'Missing info',
  },
  added: {
    showStatus: statusVariants.success,
    text: 'Info added',
  },
  verified: {
    showStatus: statusVariants.success,
    text: 'Verified',
  },
};

/**
 * Helper to prepare an object to display the error text + circle color/class for the company rep list
 * @param disableCompanyRepFormSubmission
 * @param membership
 * @return {object}
 */
export const getMembershipVerificationStatusObj = ({ membership }: { membership: Membership & User }): StatusObj => {
  const reduxState = store.getState();
  const currentCompany = currentCompanySelector(reduxState);

  // in retry case, RCTM can upload multiple docs so we need to check the "latest" submitted
  const lastMembershipDocument = lastMembershipDocumentSelector(reduxState, membership.id);

  const failedBeneficiaries = allMembershipsWithFailedBeneficiaryStatus(reduxState) || [];
  const failedDocumentsForThisMember = rejectedMembershipDocumentsByMembershipSelector(reduxState, membership.id);
  const memberIsAFailedBeneficiary = failedBeneficiaries?.some((rep) => rep.id === membership.id);
  const memberHasDocumentStatus = memberHasDocumentBeneficiaryStatus(reduxState, membership.id);

  const { hasAllPersonalInfo } = membership;

  if (!hasAllPersonalInfo) {
    return statuses.pending;
  }

  if (isCurrentCompanyAccountVerified(currentCompany)) {
    return statuses.verified;
  }

  if (
    (lastMembershipDocument && isDocumentStatusRejected(lastMembershipDocument)) ||
    failedDocumentsForThisMember?.length ||
    memberHasDocumentStatus
  ) {
    return statuses.docFailure;
  }

  if (memberIsAFailedBeneficiary) {
    return statuses.missingInfo;
  }

  return statuses.added;
};
