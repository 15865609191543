import { z } from 'zod';

const countryModel = z.object({
  id: z.string().uuid(),
  attributes: z.object({
    fullName: z.string(),
    countryCode: z.string(),
  }),
});

const countryOptionModel = z.object({
  id: z.string().uuid(),
  value: z.string(),
  label: z.string(),
});

const countriesModel = z.array(countryModel);
const countriesAsOptionsModel = z.array(countryOptionModel);

export const getCountriesModel = z.object({
  data: countriesModel,
});

export type Country = z.infer<typeof countryModel>;
export type CountryAsOption = z.infer<typeof countryOptionModel>;
export type Countries = z.infer<typeof countriesModel>;
export type CountriesAsOptions = z.infer<typeof countriesAsOptionsModel>;

export type CountriesApi = {
  getCountries: () => Promise<{ data: Countries }>;
};
