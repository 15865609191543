import React from 'react';

import { BaseHint } from 'components';

import { Intent } from 'constants/ui';

import { useExternalPaymentFundingAccountHint } from '../../hooks';

/**
 * Renders hint for errors related to updating the payment funding account on external flow
 */
const UpdatePaymentMethodFundingAccountHint = (): JSX.Element => {
  const message = useExternalPaymentFundingAccountHint();

  if (!message) {
    return null;
  }

  return <BaseHint className="margin-bottom--m-large" intent={Intent.DANGER} multiline text={message} />;
};

export default UpdatePaymentMethodFundingAccountHint;
