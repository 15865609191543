import { PAYMENTS_LIST_FILTERS, PAYMENTS_LIST_TABS } from 'constants/routes';

import { ItemStatuses } from 'enums/items';

import { isTabInbox } from 'helpers/urls';
import { isEqual } from 'helpers/utility';

import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import {
  getDeleteItemEndpoint,
  getExistingInvoicesAndBillsEndpoint,
  getItemEndpoint,
  getItemListEndpoint,
} from 'services/api/itemEndpoints';
import { getThreadEndpoint } from 'services/api/threadEndpoints';

/**
 * Check if any ledger invoices or bills exist.
 * @param {ItemKind} kind
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const checkExistingInvoicesAndBills = async (kind, options = {}) => {
  const config = {
    ...options,
    endpoint: getExistingInvoicesAndBillsEndpoint(kind),
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Fetch all items.
 * @param {Object} params
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchItems = async (params, options = {}) => {
  let endpoint;

  if (params.url) {
    // Use URL specified
    endpoint = params.url;
  } else {
    // Build endpoint from params
    let tab;
    let itemsFilter;

    switch (params.tab) {
      case PAYMENTS_LIST_TABS.RECEIVABLES:
        tab = 'receivable';
        break;
      case PAYMENTS_LIST_TABS.PAYABLES:
      case PAYMENTS_LIST_TABS.INBOX:
        tab = 'payable';
        break;
      default:
        break;
    }

    switch (params.filter) {
      case PAYMENTS_LIST_FILTERS.COMPLETED:
        itemsFilter = ItemStatuses.COMPLETED;
        break;

      case PAYMENTS_LIST_FILTERS.COMPLETED_AND_EXTERNALLY_PAID:
        itemsFilter = ItemStatuses.COMPLETED_AND_EXTERNALLY_PAID;
        break;

      case PAYMENTS_LIST_FILTERS.FAILED_AND_ISSUE:
        itemsFilter = [ItemStatuses.FAILED, ItemStatuses.ISSUE];
        break;

      case PAYMENTS_LIST_FILTERS.INITIATED:
        itemsFilter = ItemStatuses.INITIATED;
        break;

      case PAYMENTS_LIST_FILTERS.MY_APPROVAL:
      case PAYMENTS_LIST_FILTERS.TEAM_APPROVAL:
        itemsFilter = ItemStatuses.NEEDS_APPROVAL;
        break;

      case PAYMENTS_LIST_FILTERS.NEEDS_APPROVAL:
      case PAYMENTS_LIST_FILTERS.READY_TO_SEND:
      case PAYMENTS_LIST_FILTERS.SCHEDULED:
        itemsFilter = params.filter;
        break;

      case PAYMENTS_LIST_FILTERS.WAITING_FOR_ME:
        itemsFilter = ItemStatuses.NEW;
        break;

      case PAYMENTS_LIST_FILTERS.WAITING_FOR_OTHERS:
        itemsFilter = ItemStatuses.PENDING;
        break;

      default:
        break;
    }

    // Endpoint query params
    const queryParams = { kind: tab, status: itemsFilter };

    if (isEqual(params.filter, PAYMENTS_LIST_FILTERS.MY_APPROVAL)) {
      queryParams.my_approvals = true;
    } else if (isEqual(params.filter, PAYMENTS_LIST_FILTERS.TEAM_APPROVAL)) {
      queryParams.my_approvals = false;
    }

    if (params.page) {
      queryParams.page = params.page;
    }

    if (params.search) {
      queryParams.search = params.search;
    }

    // Inbox tab always uses created status filter
    if (isTabInbox(params.tab)) {
      queryParams.status = ItemStatuses.CREATED;
    }

    if (params.bulkActionId) {
      queryParams.bulk_action = params.bulkActionId;
    }

    if (params.bulkActionStatus) {
      queryParams.bulk_action_status = params.bulkActionStatus;
    }

    if (params.ids) {
      queryParams.id__in = params.ids.join();
    }

    endpoint = getItemListEndpoint(queryParams);
  }

  const config = {
    ...options,
    endpoint,
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Fetch a specific item.
 * @param {string} itemId
 * @param {Object} queryParams
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchSingleItem = async (itemId, queryParams, options = {}, extraParams = {}) => {
  const config = {
    ...options,
    ...extraParams,
    endpoint: getItemEndpoint(itemId, queryParams),
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Fetch a specific item.
 * @param {string} threadId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchThread = async (threadId, options = {}, extraParams = {}) => {
  const config = {
    ...options,
    ...extraParams,
    endpoint: getThreadEndpoint(threadId),
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Delete a bill.
 * @param {string} itemId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const deleteInboxItem = async (itemId, options = {}) => {
  const config = {
    ...options,
    endpoint: getDeleteItemEndpoint(itemId),
    method: 'POST',
    payload: payloadToJSONAPI({ id: itemId }, 'item'),
    requireAuth: true,
    setRequester: true,
  };
  return FetchService.request(config);
};
