import { StatusIndicator } from '@routable/gross-ds';
import React from 'react';

import { FlexCol, FlexRow } from 'components';

import { getExternalItemsItemActionText } from 'helpers/external';
import { itemStatusToText, itemStatusToVariant } from 'helpers/status';
import { getOriginal } from 'helpers/tables';

import { Payment } from 'interfaces/redux';
import { TableCellProps } from 'interfaces/table.types';

/**
 * Component rendering Status Indicator in External Items table
 */
const ExternalItemsListTableItemStatus: React.VFC<TableCellProps<Payment>> = ({ row }) => {
  const item = getOriginal({ row });
  const { status } = item;

  return (
    <FlexCol>
      <FlexRow className="align-items--center" stackOnMobile={false}>
        <StatusIndicator className="text-xs" status={itemStatusToVariant[status]}>
          {itemStatusToText[status]}
        </StatusIndicator>
      </FlexRow>

      <span className="text-xs text-grey-60">{getExternalItemsItemActionText(item)}</span>
    </FlexCol>
  );
};

export default ExternalItemsListTableItemStatus;
