import React from 'react';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

import {
  PaymentBlockedMissingApproverReason,
  paymentBlockedReasonText,
} from './PaymentBlockedMissingApproverHint.constants';
import { PaymentBlockedMissingApproverHintProps } from './PaymentBlockedMissingApproverHint.types';

/**
 * Hint component rendering a warning about payment being blocked due to
 * missing (in-active) approver(s)
 * @param props - Component props
 */
const PaymentBlockedMissingApproverHint = ({ reason }: PaymentBlockedMissingApproverHintProps): JSX.Element => {
  const unblockText =
    reason === PaymentBlockedMissingApproverReason.NO_MANUAL_APPROVER
      ? "bypass approval or edit this payment's approvers"
      : "either bypass approval or update approval rules and edit this payment's approvers";

  return (
    <BaseHint
      intent={Intent.WARNING}
      text={`To unblock it, ${unblockText}.`}
      title={`This payment is blocked ${paymentBlockedReasonText[reason]}`}
      titleProps={{ className: 'font-weight--semibold--important' }}
    />
  );
};

export default PaymentBlockedMissingApproverHint;
