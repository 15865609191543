import {
  shouldDisplayAmountAboveLimitWarning,
  shouldDisplayBalanceTooLowWarning,
  shouldDisplayMaxTransactionFeeWarning,
  shouldDisplayRTPEligibilityWarning,
} from './helpers';
import { useSingleItemCutoffWarning } from './useItemCutoffWarning';
import { WarningTypes, type Options, type UseItemWarnings } from './useItemWarnings.types';

/**
 * This hook is meant to return a general flag identifying if a blocker warning is present
 * based on user selection. The general flag showWarning can be consumed to determine
 * weather the form is valid to be sent or not.
 * Examples of blockers: balance too low, vendor can't receive RTP
 */
export const useItemWarnings = ({
  itemKind,
  dateScheduledType,
  partnershipFundingAccounts,
  paymentTotalAmount,
  balanceAmount,
  paymentMaxIndividualAmount,
  currentBillingData,
}: Options): UseItemWarnings => {
  const { paymentSource, paymentDeliveryOption } = currentBillingData || {};

  const showAfterCutoffTimeWarning = useSingleItemCutoffWarning({
    dateScheduledType,
    currentBillingData,
  });

  const showRTPEligibilityWarning = shouldDisplayRTPEligibilityWarning({
    partnershipFundingAccounts,
    paymentDeliveryOption,
  });

  const showBalanceTooLowWarning = shouldDisplayBalanceTooLowWarning({
    paymentTotalAmount,
    balanceAmount,
    paymentSource,
  });

  const showAmountAboveLimitWarning = shouldDisplayAmountAboveLimitWarning({
    paymentMaxIndividualAmount,
    currentBillingData,
  });

  const showMaxTransactionFeeWarning = shouldDisplayMaxTransactionFeeWarning({
    itemKind,
    showAmountAboveLimitWarning,
  });

  /**
   * Red warnings: if any red warning is present, then the form can not be submitted.
   * The goal of exposing this canSubmit is to consume it from any part of the app
   * and simplify CTA disabling.
   */
  const hasBlockerWarnings = showRTPEligibilityWarning || showBalanceTooLowWarning || showAmountAboveLimitWarning;

  return {
    hasBlockerWarnings,
    showWarning: {
      [WarningTypes.AFTER_CUTOFF]: showAfterCutoffTimeWarning,
      [WarningTypes.RTP_ELIGIBILITY]: showRTPEligibilityWarning,
      [WarningTypes.BALANCE_TOO_LOW]: showBalanceTooLowWarning,
      [WarningTypes.AMOUNT_ABOVE_LIMIT]: showAmountAboveLimitWarning,
      [WarningTypes.MAX_FEE]: showMaxTransactionFeeWarning,
    },
  };
};
