/* eslint-disable no-restricted-globals */
import { useAuth0 } from '@auth0/auth0-react';
import * as FullStory from '@fullstory/browser';
import { setAuth0Client } from '@routable/core/helpers';
import { setFrameworkOAuthClient } from '@routable/framework';
import { RoutableDashboard } from '@routable/pages';
import { RootDashboardPaths, RootRouting, RouteObject } from '@routable/routing';
import SentryFullStory from '@sentry/fullstory';
import {
  ReportingObserver as ReportingObserverIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  CaptureConsole as CaptureConsoleIntegration,
} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import whyDidYouUpdate from 'why-did-you-update';

import RoutableHead from 'components/head/RoutableHead';
import { ProtectedRouteWarnDisabledUser } from 'components/routing';
import Sandbox from 'components/sandbox/Sandbox';

import { FEATURE_FLAG_MILTON_TGS } from 'constants/featureFlags';
import { BLOG_URL, HELP_DOCS_URL, LEGAL_PRIVACY_URL, LEGAL_TOS_URL, SECURITY_URL } from 'constants/platform';
import * as ROUTES from 'constants/routes';

import { hasAuthToken } from 'helpers/auth';
import { isBrowserSupported } from 'helpers/browser';
import { isNodeEnvProduction, isNodeEnvSandbox } from 'helpers/env';
import { identifyUserByMembershipId } from 'helpers/eventTracking';
import { getCurrentMembershipData, getCurrentMembershipId } from 'helpers/localStorage';
import { asPath, getJoinedPath } from 'helpers/routeHelpers';
import { processSentryEvent } from 'helpers/sentry.helper';
import { systemLogger, LogLevel } from 'helpers/systemLogger';
import { isSubdomainNamespaceExternal } from 'helpers/urls';
import { setUTMTagsToSessionStorage } from 'helpers/utmTags';

import { useFeatureFlag } from 'hooks';
import { useHandleBrandedWorkspaceRedirect } from 'hooks/useHandleBrandedWorkspaceRedirect';
import { useRedirectFromTopLevelToBrandedWorkspace } from 'hooks/useRedirectFromTopLevelToBrandedWorkspace';

import AllowAny from 'modules/app/AllowAny';
import Authenticated from 'modules/app/AuthenticatedRoute';
import NotAuthenticated from 'modules/app/NotAuthenticatedRoute';
import RedirectExternal from 'modules/app/RedirectExternal';
import SwitchOr404 from 'modules/app/SwitchOr404';
import { AcceptMembershipInvite, CreatePassword, Login, ResetPassword, Logout } from 'modules/auth';
import { IntercomWidget } from 'modules/customerSupport';
import { useCustomerServiceWidget } from 'modules/customerSupport/CustomerService/CustomerService';
import DashboardContainer from 'modules/dashboard/components/Dashboard';
import DeepLinks from 'modules/deepLinks';
import ExternalContainer from 'modules/external/global/containers/ExternalContainer';
import ExternalContainerV2 from 'modules/external/global/containers/ExternalContainerV2';
import { IsLoadingHasWrapper } from 'modules/isLoading';
import { MaintenanceModeFullPage, OldVersionModeFullPage } from 'modules/maintenance';
import { QuickswitchToBrandedSubdomain } from 'modules/quickswitch';
import ConvertExternal from 'modules/signup/components/ConvertExternal';
import SignupV2 from 'modules/signup/SignupV2';
import SignupAccountCreation from 'modules/signup-accountCreation/SignupV2';
import SignUpFlow from 'modules/signup-v3/SignUpFlowContainer';
import Processing from 'modules/signup-v3/views/Processing';
import UnsupportedBrowser from 'modules/unsupportedBrowser/UnsupportedBrowser';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import { currentUserSelector } from 'selectors/currentUserSelectors';
import { disabledUserErrorSelector } from 'selectors/errorsSelectors';
import { maintenanceModeSelector } from 'selectors/maintenanceModeSelectors';
import { oldVersionModeSelector } from 'selectors/oldVersionModeSelectors';

import { initAuth0Client } from 'services/auth0';

import { initTestableAuth0Client } from 'tests/helpers/auth0';
import { SentryErrorPage } from 'tests/pages';

import { AppProps } from './App.types';
import { ExporterComponent } from './components';

// const RoutableDashboard = lazy(() => import('../../pages/routable_v2/dashboard/dashboard.page'));

const { REACT_APP_LOG_COMPONENT_UPDATES, REACT_APP_FULLSTORY_ORG_ID } = process.env;

if (REACT_APP_FULLSTORY_ORG_ID) {
  FullStory.init({ orgId: REACT_APP_FULLSTORY_ORG_ID });
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    // Sentry DSN from setup-box or local env.
    dsn: process.env.REACT_APP_SENTRY_DSN,
    ignoreErrors: ['No FullStory session URL found'],
    integrations: [
      new ReportingObserverIntegration(),
      new BrowserTracing(),
      new ExtraErrorDataIntegration({
        depth: 3,
      }),
      new CaptureConsoleIntegration({
        // Types of console messages to capture ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['error'],
      }),
      new SentryFullStory('routable', { client: FullStory }),
    ],
    denyUrls: [
      // Chrome extensions filtering
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
    tracesSampleRate: 0.1, // We will adjust this to fine the right threshold from 0.1 to 1.0
    normalizeDepth: 8,
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_SENTRY_ENV,
    beforeSend(event) {
      // Pass the event to a process method in sentry helper
      // which will return the modified event stripped of PII info etc.
      return processSentryEvent(event);
    },
  });
}

if (!isNodeEnvProduction() && REACT_APP_LOG_COMPONENT_UPDATES === '1') {
  whyDidYouUpdate(React);
}

const App: React.FC<AppProps> = ({
  isMaintenanceModeOn,
  isOldVersionModeOn,
  isUserDisabled,
  currentCompany,
  currentUser,
}) => {
  const auth0 = useAuth0();

  if (!(isNodeEnvProduction() || isNodeEnvSandbox())) {
    // load dev version of auth client
    initTestableAuth0Client(auth0);
  }

  // sets the client utility in the frontend app
  initAuth0Client(auth0);
  // sets the client utility in the core package
  // NOTE: this will be removed after core package is removed from our project
  setAuth0Client(auth0);

  // Because we have 2 different types of auth we need to pass in the
  // auth0 const above to make sure our JWT gets passed inside of networking.
  // TODO: This should be refactored to store the JWT the same way the "token"
  // works so we don't have to use two different check methods.
  setFrameworkOAuthClient(auth0);
  if (auth0.error) {
    systemLogger.log({ level: LogLevel.ERROR, error: auth0.error });
  }

  // set session storage UTM tags
  React.useEffect(() => {
    setUTMTagsToSessionStorage();
  }, []);

  React.useEffect(() => {
    if (!hasAuthToken()) {
      return;
    }
    const { companyId, userId } = getCurrentMembershipData();

    identifyUserByMembershipId(getCurrentMembershipId(), {
      companyId,
      userId,
      email: currentUser?.email,
      name: `${currentUser?.firstName} ${currentUser?.lastName}`,
      namespace: currentCompany?.namespace,
      accountName: currentCompany?.name,
    });
  }, [currentCompany, currentUser]);

  const RoutableV2 = useFeatureFlag(FEATURE_FLAG_MILTON_TGS);
  useHandleBrandedWorkspaceRedirect();
  useRedirectFromTopLevelToBrandedWorkspace();
  useCustomerServiceWidget();

  if (auth0.isLoading) {
    return <IsLoadingHasWrapper />;
  }

  return (
    <React.Suspense fallback={<IsLoadingHasWrapper />}>
      <RoutableHead />
      <Sandbox />
      <IntercomWidget />
      <SwitchOr404>
        {
          /* Maintenance mode */
          isMaintenanceModeOn && <AllowAny component={MaintenanceModeFullPage} />
        }

        {
          /* Old Version mode */
          isOldVersionModeOn && <AllowAny component={OldVersionModeFullPage} />
        }

        {
          /* User account disabled */
          isUserDisabled && <AllowAny component={ProtectedRouteWarnDisabledUser} />
        }

        {/* Non platform routes */}
        <RedirectExternal path={asPath(ROUTES.REDIRECT_TO_BLOG)} to={BLOG_URL} />
        <RedirectExternal path={asPath(ROUTES.REDIRECT_TO_DOCS)} to={HELP_DOCS_URL} />

        {
          /* Old Browser */
          !isBrowserSupported() && <AllowAny component={UnsupportedBrowser} />
        }

        {
          /* external. subdomain */
          isSubdomainNamespaceExternal() && <AllowAny component={ExternalContainerV2} />
        }

        {/* External */}
        <AllowAny component={ExternalContainer} path={asPath(ROUTES.EXTERNAL)} />

        {/* External V2 */}
        <AllowAny component={ExternalContainerV2} path={asPath(ROUTES.EXTERNAL_V2)} />

        {/* Routable V2 DASHBOARD AND ROUTES */}
        {RoutableV2 && (
          <>
            <SwitchOr404>
              <Authenticated component={RoutableDashboard} exact path={asPath('v2/dashboard')} />
              {RoutableV2 &&
                RootRouting?.map(({ path, component }: RouteObject, idx) => {
                  const eslintKey = `v2_route_${idx}`; // Good rule but for this it doesn't matter
                  if (hasAuthToken()) {
                    return (
                      <Route
                        exact
                        key={eslintKey}
                        path={asPath(path)}
                        render={() => <RoutableDashboard renderComponent={component} />}
                      />
                    );
                  }
                  return <></>;
                })}

              {RootDashboardPaths?.map((route, idx) => {
                const eslintKey = `v2_route_${idx}`; // Good rule but for this it doesn't matter
                if (!hasAuthToken()) {
                  return <Redirect exact from={asPath(route)} key={eslintKey} to={`/${ROUTES.LOGIN}`} />;
                }

                return <Redirect exact from={asPath(route)} key={eslintKey} to={asPath('v2/dashboard')} />;
              })}
            </SwitchOr404>
          </>
        )}

        {/* Authenticated Pages */}
        {!RoutableV2 && <Authenticated component={DashboardContainer} path={asPath(ROUTES.DASHBOARD)} />}

        {/* Sentry Error Pages */}
        <Authenticated component={SentryErrorPage} path={asPath(ROUTES.SENTRYERRORPAGE)} />

        {/* Signup v3 - Signup flow */}
        <Authenticated component={SignUpFlow} path={asPath(ROUTES.GET_STARTED)} />

        <Authenticated component={Processing} path={asPath(ROUTES.PROCESSING)} />

        <Authenticated component={DeepLinks} path={asPath(ROUTES.DEEP_LINK)} />

        <Authenticated component={ExporterComponent} path={asPath(ROUTES.EXPORT)} />

        {/* Static Pages */}
        <RedirectExternal exact path={getJoinedPath(ROUTES.LEGAL, ROUTES.PRIVACY)} to={LEGAL_PRIVACY_URL} />
        <RedirectExternal exact path={getJoinedPath(ROUTES.LEGAL, ROUTES.TERMS)} to={LEGAL_TOS_URL} />
        <RedirectExternal exact path={asPath(ROUTES.SECURITY)} to={SECURITY_URL} />

        {/* Auth Pages */}
        <AllowAny component={Logout} exact path={asPath(ROUTES.LOGOUT)} />

        <NotAuthenticated component={AcceptMembershipInvite} exact path={asPath(ROUTES.ACCEPT_MEMBERSHIP_INVITE)} />

        <NotAuthenticated component={Login} exact path={asPath(ROUTES.LOGIN)} />

        <NotAuthenticated component={ResetPassword} path={asPath(ROUTES.RESET_PASSWORD)} />

        <AllowAny component={CreatePassword} path={asPath(ROUTES.CREATE_PASSWORD)} />

        <NotAuthenticated component={ConvertExternal} path={asPath(ROUTES.CONVERT_EXTERNAL_COMPANY_TO_REGISTERED)} />

        {/* Quickswitch */}
        <AllowAny component={QuickswitchToBrandedSubdomain} exact path={asPath(ROUTES.QUICKSWITCH)} />

        {/* Signup */}
        <AllowAny component={SignupV2} path={asPath(ROUTES.SIGNUP)} />

        {/* Signup Account Creation */}
        <AllowAny component={SignupAccountCreation} path={asPath(ROUTES.ACCOUNT_CREATION)} />

        {/* base url redirects to dashboard, which will redirect non-authenticated users to login */}
        <Redirect exact from={asPath(ROUTES.HOME)} to={asPath(ROUTES.LOGIN)} />
      </SwitchOr404>
    </React.Suspense>
  );
};

const mapStateToProps = createStructuredSelector({
  isMaintenanceModeOn: maintenanceModeSelector,
  isOldVersionModeOn: oldVersionModeSelector,
  isUserDisabled: disabledUserErrorSelector,
  currentUser: currentUserSelector,
  currentCompany: currentCompanySelector,
});

export default connect(mapStateToProps)(App);
