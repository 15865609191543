import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getGenericDisplayTagType } from 'helpers/integrations';
import { tagTypeToStatusVariant } from 'helpers/status';

import { isFetchingIntegrationConfigsSelector } from 'selectors/integrationConfigsSelectors';
import { isLedgerIntegrationConnectedSelector } from 'selectors/integrationsSelectors';

export const useLedgerIntegrationStatus = (ledgerName: string) => {
  const isFetchingIntegrationConfig = useSelector(isFetchingIntegrationConfigsSelector);
  const isLedgerIntegrationConnected = useSelector(isLedgerIntegrationConnectedSelector);

  const statusText = useMemo(() => {
    if (isFetchingIntegrationConfig) {
      return 'Connecting...';
    }

    return isLedgerIntegrationConnected ? `${ledgerName} connected` : `${ledgerName} disconnected`;
  }, [isFetchingIntegrationConfig, isLedgerIntegrationConnected, ledgerName]);

  const tagType = getGenericDisplayTagType({ isFetchingIntegrationConfig, isLedgerIntegrationConnected });
  const statusVariant = tagTypeToStatusVariant[tagType];

  return { statusText, statusVariant } as const;
};
