import { AuthTokenResponse } from 'interfaces/auth';

import { Membership, QuickswitchActionCreator, QuickswitchActionType } from 'types/quickswitch';

export const openQuickswitchModal = (): QuickswitchActionCreator => ({
  type: QuickswitchActionType.OPEN_MODAL,
});

export const closeQuickswitchModal = (): QuickswitchActionCreator => ({
  type: QuickswitchActionType.CLOSE_MODAL,
});

export const executeQuickswitchSwitchMembership = (membershipId: string): QuickswitchActionCreator => ({
  type: QuickswitchActionType.SWITCH_MEMBERSHIP_REQUEST,
  payload: { membershipId },
});

export const redirectToSSOLogin = (companyNamespace: string): QuickswitchActionCreator => ({
  type: QuickswitchActionType.REDIRECT_TO_SSO_LOGIN,
  payload: { companyNamespace },
});

export const fetchQuickswitchMembershipList = (): QuickswitchActionCreator => ({
  type: QuickswitchActionType.FETCH_MEMBERSHIP_LIST_REQUEST,
});

export const fetchQuickswitchMembershipSearch = (searchTerm: string): QuickswitchActionCreator => ({
  type: QuickswitchActionType.FETCH_MEMBERSHIP_SEARCH_REQUEST,
  payload: { searchTerm },
});

export const switchToBrandedSubdomain = (responseMeta: AuthTokenResponse): QuickswitchActionCreator => ({
  type: QuickswitchActionType.SWITCH_TO_BRANDED_SUBDOMAIN,
  payload: { meta: responseMeta },
});

export const updateMembershipList = (memberships: Membership[]): QuickswitchActionCreator => ({
  type: QuickswitchActionType.UPDATE_MEMBERSHIP_LIST,
  payload: { memberships },
});

export const updateMembershipListError = (): QuickswitchActionCreator => ({
  type: QuickswitchActionType.FETCH_MEMBERSHIP_REQUEST_ERROR,
});

export const openQuickswitchErrorModal = (): QuickswitchActionCreator => ({
  type: QuickswitchActionType.OPEN_ERROR_MODAL,
});

export const closeQuickswitchErrorModal = (): QuickswitchActionCreator => ({
  type: QuickswitchActionType.CLOSE_ERROR_MODAL,
});

export const reLogin = (): QuickswitchActionCreator => ({
  type: QuickswitchActionType.RE_LOGIN,
});

export const reLoginSuccess = (): QuickswitchActionCreator => ({
  type: QuickswitchActionType.RE_LOGIN_SUCCESS,
});

export const reLoginError = (): QuickswitchActionCreator => ({
  type: QuickswitchActionType.RE_LOGIN_ERROR,
});

export const switchResetState = (): QuickswitchActionCreator => ({
  type: QuickswitchActionType.SWITCH_RESET_STATE,
});

export const setSearchTerm = (searchTerm: string): QuickswitchActionCreator => ({
  type: QuickswitchActionType.SET_SEARCH_TERM,
  payload: { searchTerm },
});

export const setTotalNumberOfMemberships = (totalNumberOfMemberships: number): QuickswitchActionCreator => ({
  type: QuickswitchActionType.SET_TOTAL_NUMBER_OF_MEMBERSHIPS,
  payload: { totalNumberOfMemberships },
});
