import { getFetchFeatureFlagsApiConfig } from 'sagas/featureFlags/api';

import { GET_FEATURE_FLAGS } from 'types/featureFlags';

import { createApiRoutine } from './createApiRoutine';

/**
 * Defines types and creates actions for all getting-feature-flags related side effects.
 */
export const getFeatureFlagsRoutine = createApiRoutine(GET_FEATURE_FLAGS, getFetchFeatureFlagsApiConfig());
