import React from 'react';

import { CountryFlag, FlexRow } from 'components';

import { useCountryCodesContext } from 'hooks';

import { getCountryAndCurrencyText, getInferredCountryCodeFromFundingData } from './helpers';
import type { PaymentMethodDetailsCountryCurrencyFieldProps } from './PaymentMethodDetailsCountryCurrencyField.types';

/**
 * Renders Country Flag along with country name and currency
 */
const PaymentMethodDetailsCountryCurrencyField: React.FC<PaymentMethodDetailsCountryCurrencyFieldProps> = ({
  fundingAccount,
  fundingInfoInternational,
}) => {
  const countryCodeMap = useCountryCodesContext();
  const text = getCountryAndCurrencyText({
    countryCodeMap,
    fundingAccount,
    fundingInfoInternational,
  });

  return (
    <FlexRow className="align-items--center" stackOnMobile={false}>
      <CountryFlag
        className="payment_method__detail-item-value-flag"
        countryCode={getInferredCountryCodeFromFundingData(fundingAccount, fundingInfoInternational)}
      />
      {text}
    </FlexRow>
  );
};

export default PaymentMethodDetailsCountryCurrencyField;
