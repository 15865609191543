import { FetchServiceResponse } from 'interfaces/fetchService';
import { RequestParams } from 'interfaces/request';
import { CreateWebhookSettingsPayloadProps, WebhookSettings } from 'interfaces/webhooks';

import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import {
  getWebhooksEndpoint,
  getWebhookSettingsInstanceEndpoint,
  getWebhookSettingsTestEndpoint,
  webhookSettingsBaseEndpoint,
} from 'services/api/webhooksEndpoints';

// Webhooks
/**
 * Fetch webhooks
 */
export const fetchWebhooks = async (params: RequestParams, options = {}): Promise<FetchServiceResponse> => {
  const config = {
    ...options,
    endpoint: getWebhooksEndpoint(params),
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

// Webhook Settings
/**
 * Delete webhook settings
 */
export const deleteWebhookSettings = async (webhookSettingsId: string, options = {}): Promise<FetchServiceResponse> => {
  const config = {
    ...options,
    endpoint: getWebhookSettingsInstanceEndpoint(webhookSettingsId),
    method: 'DELETE',
    requireAuth: true,
  };

  return FetchService.request(config);
};

/**
 * Fetch webhook settings
 */
export const fetchWebhookSettings = async (options = {}): Promise<FetchServiceResponse> => {
  const config = {
    ...options,
    endpoint: webhookSettingsBaseEndpoint,
    method: 'GET',
    requireAuth: true,
  };

  return FetchService.request(config);
};

/**
 * Create webhook settings
 */
export const submitWebhookSettings = async (
  payload: CreateWebhookSettingsPayloadProps,
  options = {},
): Promise<FetchServiceResponse> => {
  const augmentedPayload = {
    ...payload,
    itemCreate: true,
    itemStatusChange: true,
  };

  const config = {
    ...options,
    endpoint: webhookSettingsBaseEndpoint,
    method: 'POST',
    payload: payloadToJSONAPI(augmentedPayload, 'WebhookSettings'),
    requireAuth: true,
  };

  return FetchService.request(config);
};

/**
 * Test webhook settings
 */
export const testWebhookSettings = async (webhookSettingsId: string, options = {}): Promise<FetchServiceResponse> => {
  const config = {
    ...options,
    endpoint: getWebhookSettingsTestEndpoint(webhookSettingsId),
    method: 'POST',
    requireAuth: true,
  };

  return FetchService.request(config);
};

/**
 * Update webhook settings
 */
export const updateWebhookSettings = async (
  payload: Pick<WebhookSettings, 'id' | 'isPaused'>,
  options = {},
): Promise<FetchServiceResponse> => {
  const config = {
    ...options,
    endpoint: getWebhookSettingsInstanceEndpoint(payload.id),
    payload: payloadToJSONAPI(payload, 'WebhookSettings'),
    method: 'PATCH',
    requireAuth: true,
  };

  return FetchService.request(config);
};
