import React from 'react';
import { useDispatch } from 'react-redux';

import { SwiftChargeOptions } from 'enums/paymentMethods';

import { isPartnerOnlyElegibleForInternationalSwift } from 'helpers/partnerships';
import { isPaymentDeliveryOptionInternationalSwift } from 'helpers/paymentDeliveryOption';

import type { PartnerPaymentOption } from 'interfaces/fundingSupportedCountries';

import type { UseShouldShowSwiftFeeChargeToggleOptions } from './useShouldShowSwiftFeeChargeToggle.types';

export const paymentShouldIncludeSwiftFee = (item: Item, partnerPaymentOptions: PartnerPaymentOption[]): boolean =>
  isPartnerOnlyElegibleForInternationalSwift(partnerPaymentOptions) ||
  isPaymentDeliveryOptionInternationalSwift(item?.paymentDeliveryOption);

/**
 * Given an item and partnerPaymentOptions, determines whether or not Swift Fee Charge
 * toggle should be visible. In case where it should be visible, it also sets the formField
 * value to the item's swiftChargeOption or a default of SwiftChargeOptions.OUR
 * @param options
 * @param options.fieldName - Name of the form field for which we want to set the value for
 * @param options.item - Item object
 * @param options.onChange - On form field change event handler
 * @param options.partnerPaymentOptions - Partner's payment delivery options array
 */
const useShouldShowSwiftFeeChargeToggle = ({
  fieldName,
  item,
  onChange,
  partnerPaymentOptions,
}: UseShouldShowSwiftFeeChargeToggleOptions): boolean => {
  const dispatch = useDispatch();
  const shouldShowSwiftFeeChargeToggle = React.useMemo(
    () => paymentShouldIncludeSwiftFee(item, partnerPaymentOptions),
    [item, partnerPaymentOptions],
  );

  React.useEffect(() => {
    if (shouldShowSwiftFeeChargeToggle) {
      dispatch(onChange(fieldName, item.swiftChargeOption ?? SwiftChargeOptions.OUR));
    } else {
      dispatch(onChange(fieldName, undefined));
    }
  }, [dispatch, fieldName, item.swiftChargeOption, onChange, shouldShowSwiftFeeChargeToggle]);

  return shouldShowSwiftFeeChargeToggle;
};

export default useShouldShowSwiftFeeChargeToggle;
