import classNames from 'classnames';
import React from 'react';

import { ButtonV2, IconNames } from 'components';
import { ButtonV2Props } from 'components/buttonV2/ButtonV2.types';

import { ButtonSize } from 'constants/button';
import { SSO_LOGIN } from 'constants/images';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import './LoginWithSSOButton.scss';

/**
 * LoginWithSSOButton
 */
const LoginWithSSOButton: React.FC<ButtonV2Props> = ({ className, ...btnProps }) => (
  <ButtonV2
    className={classNames({
      'login-with-sso-btn': true,
      [className]: Boolean(className),
    })}
    intent={Intent.NEUTRAL}
    rightIconClassName="sso-btn--right-icon"
    rightIconName={IconNames.ARROW_RIGHT}
    rightIconSize={sizes.iconSizes.LARGE}
    size={ButtonSize.LARGE}
    {...btnProps}
  >
    <img alt="SSO 2fa logo" className="sso-btn--logo" src={SSO_LOGIN} />
    Login with SSO
  </ButtonV2>
);

export default LoginWithSSOButton;
