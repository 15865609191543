import React from 'react';

import { LinkNewPage } from 'components/link';
import { Image } from 'components/media';
import ShadedBlock from 'components/shadedBlock';

import { Intent } from 'constants/ui';

import { hasLength, isArray, isObject, isString } from 'helpers/utility';

import ServerErrorDefaultMessage from '../ServerErrorDefaultMessage';
import ServerErrorDefaultTitle from '../ServerErrorDefaultTitle';
import ServerErrorRequestId from '../ServerErrorRequestId';

import type { ServerErrorProps } from './ServerError.types';
import './ServerError.scss';

/**
 * Server error SWAL inner component
 * Note: this has to be a single element, hence the <div> and not <React.Fragment>, for SWAL to render it properly
 */
const ServerError = ({
  alertErrors = [],
  className,
  errorMessage,
  imageSrc,
  requestId,
  title,
}: ServerErrorProps): JSX.Element => (
  <div className={className}>
    {imageSrc && (
      <div className="alert-error-image margin-bottom--m-large">
        <Image alt="Error alert graphic" src={imageSrc} />
      </div>
    )}

    {title || <ServerErrorDefaultTitle />}

    {hasLength(alertErrors) && (
      <ShadedBlock className="margin-bottom--large margin-top--m" intent={Intent.NEUTRAL}>
        <ul className="alert-error-list">
          {/* Using idx as the error as this is an immutable list of components */}
          {/* eslint-disable react/no-array-index-key */}
          {alertErrors.map((error, idx) => (
            <li key={`alert-err-${idx}`}>
              {isString(error) && <span>{error}</span>}

              {isObject(error) && (
                <>
                  <span className="font-bold block">{error.title}</span>
                  <p className="mt-2">{error.detail}</p>

                  <span className="font-bold block mt-6">{error.resolution?.title || 'Resolution steps:'}</span>
                  <p className="mt-2">
                    {error.resolution?.header && <span className="block">{error.resolution.header}</span>}

                    {error.resolution?.steps && (
                      <ol className="list-decimal mt-4 pl-5">
                        {error.resolution.steps.map((step) => (
                          <>
                            {isString(step) && <li>{step}</li>}
                            {isArray(step) && (
                              <ol className="list-style--lower-alpha pl-5">
                                {step.map((substep, substepIdx) => (
                                  <li key={`substep-${substepIdx + 1}`}>{substep}</li>
                                ))}
                              </ol>
                            )}
                          </>
                        ))}
                      </ol>
                    )}

                    {error.resolution?.footer && <span className="block mt-4">{error.resolution.footer}</span>}

                    <div className="[&>a:nth-child(1)]:mt-5 [&>a:nth-child(2)]:mt-2">
                      {error.resolution?.externalLink && (
                        <LinkNewPage className="block primary" href={error.resolution.externalLink.link}>
                          {`${error.resolution.externalLink.text} ↗`}
                        </LinkNewPage>
                      )}
                      {error.resolution?.helpLink && (
                        <LinkNewPage className="block primary" href={error.resolution.helpLink.link}>
                          {`${error.resolution.helpLink.text} ↗`}
                        </LinkNewPage>
                      )}
                    </div>
                  </p>
                </>
              )}
            </li>
          ))}
        </ul>
      </ShadedBlock>
    )}

    {errorMessage || <ServerErrorDefaultMessage />}

    {requestId && <ServerErrorRequestId>{requestId}</ServerErrorRequestId>}
  </div>
);

export default ServerError;
