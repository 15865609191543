import clsx from 'clsx';
import React from 'react';

import type { InputPlaceholderProps } from './InputPlaceholder.types';

/**
 * Placeholder for input
 * @param props - Component props
 */
export const InputPlaceholder = ({ className, isRequired, placeholder, value }: InputPlaceholderProps) => {
  if (!placeholder || value) {
    return null;
  }

  return (
    <div className={clsx('placeholder', 'truncate', className)}>
      {placeholder}
      {isRequired && <span>{' *'}</span>}
    </div>
  );
};
