import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { Text, LinkNewPage, WhiteSpace } from 'components';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import helpDocs from 'helpers/helpDocs';

/**
 * Component rendering tooltip text that notifies that the balance is automated because Express Payments are enabled
 * @return {StatelessComponent}
 */
const AutomatedBalanceTooltipText: React.FC = () => (
  <Text.Regular>
    {`Your balance is automated by ${PLATFORM_DISPLAY_SHORT_NAME}.
    You are only able to deposit funds if your balance goes negative due to a payment failure.`}
    <WhiteSpace />
    <LinkNewPage className="primary" href={helpDocs.EXPRESS_PAYMENTS_AUTOMATED_BALANCE}>
      Learn more
    </LinkNewPage>
    .
  </Text.Regular>
);

export default AutomatedBalanceTooltipText;
