import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { itemEarApprovalsSlice } from 'ducks/itemEarApproval';
import { reducer as splitBill } from 'ducks/itemSplitBill';
import { reducer as reduxShiftSelect } from 'ducks/reduxShiftSelect';
import { routableApi } from 'ducks/routableApi';

import { AuthActionType } from 'enums/auth';

import { isEqual } from 'helpers/utility';

import addressesReducer from 'reducers/addressesReducer';
import affiliatesRootReducer from 'reducers/affiliates';
import annotationLineItemsReducer from 'reducers/annotationLineItemsReducer';
import annotationsReducer from 'reducers/annotationsReducer';
import announcementsReducer from 'reducers/announcementReducer';
import apiTokensReducer from 'reducers/apiTokensReducer';
import approvalRequirements from 'reducers/approvalRequirementsReducer';
import approvalsReducer from 'reducers/approvalsReducer';
import approvalSummaryReducer from 'reducers/approvalSummaryReducer';
import attachmentsReducer from 'reducers/attachmentsReducer';
import authReducer from 'reducers/authReducer';
import billingReducer from 'reducers/billingReducer';
import brandsReducer from 'reducers/brandReducer';
import bulkActionsReducer from 'reducers/bulkActions';
import clearingAccountsReducer from 'reducers/clearingAccountsReducer';
import companiesReducer from 'reducers/companiesReducer';
import componentConfigurationReducer from 'reducers/componentConfigurationReducer';
import cornerDialogsReducer from 'reducers/cornerDialogsReducer';
import countriesReducer from 'reducers/countriesReducer';
import currenciesReducer from 'reducers/currenciesReducer';
import currentCompanyReducer from 'reducers/currentCompanyReducer';
import currentUserReducer from 'reducers/currentUserReducer';
import dashboardLoadedReducer from 'reducers/dashboardReducer';
import documentsReducer from 'reducers/documentsReducer';
import errorsReducer from 'reducers/errorsReducer';
import exportFileReducer from 'reducers/exportFileReducer';
import externalOnboardingReducer from 'reducers/externalOnboardingReducer';
import featureFlagsReducer from 'reducers/featureFlags';
import featureSettingsReducer from 'reducers/featureSettings';
import filesReducer from 'reducers/filesReducer';
import formReducerPlugin from 'reducers/formReducer';
import fundingReducer from 'reducers/fundingReducer';
import integrationConfigsReducer from 'reducers/integrationConfigsReducer';
import integrationsReducer from 'reducers/integrationsReducer';
import invoicesReducer from 'reducers/invoicesReducer';
import itemMemberReducer from 'reducers/itemMemberReducer';
import itemsReducer from 'reducers/itemsReducer';
import logEntryReducer from 'reducers/logEntryReducer';
import maintenanceModeReducer from 'reducers/maintenanceModeReducer';
import membershipInvitesReducer from 'reducers/membershipInvitesReducer';
import membershipPersonalInfoReducer from 'reducers/membershipPersonalInfoReducer';
import membershipsReducer from 'reducers/membershipsReducer';
import messagesReducer from 'reducers/messagesReducer';
import modalsReducer from 'reducers/modalsReducer';
import notificationSettingsEmailForwardsReducer from 'reducers/notificationSettingsEmailForwardsReducer';
import notificationSettingsReducer from 'reducers/notificationSettingsReducer';
import notificationTableDataReducer from 'reducers/notificationTableDataReducer';
import oldVersionModeReducer from 'reducers/oldVersionModeReducer';
import partnershipFundingStatesReducer from 'reducers/partnershipFundingStatesReducer';
import partnershipItemsReducer from 'reducers/partnershipItemsReducer';
import partnershipMembersReducer from 'reducers/partnershipMemberReducer';
import partnershipPaymentReducer from 'reducers/partnershipPaymentReducer';
import partnershipRequestReducer from 'reducers/partnershipRequestReducer';
import partnershipsReducer from 'reducers/partnershipsReducer';
import passwordInputReducer from 'reducers/passwordInputReducer';
import paymentsReducer from 'reducers/paymentsReducer/paymentsReducer';
import permissionGroupsReducer from 'reducers/permissionGroupsReducer';
import permissionsReducer from 'reducers/permissionsReducer';
import purposeCodeReducer from 'reducers/purposeCodeReducer';
import quickswitchReducer from 'reducers/quickswitchReducer';
import rolesReducer from 'reducers/rolesReducer';
import sidebarReducer from 'reducers/sidebarReducer';
import sidePanelsReducer from 'reducers/sidePanelsReducer';
import signupReducer from 'reducers/signupReducer';
import ssoReducer from 'reducers/ssoReducer';
import tablesReducer from 'reducers/tablesReducer';
import taggingReducer from 'reducers/taggingReducer';
import taxesReducer from 'reducers/taxesReducer';
import threadsReducer from 'reducers/threadsReducer';
import transactionsReducer from 'reducers/transactionsReducer';
import uiReducer from 'reducers/uiReducer';
import usersReducer from 'reducers/usersReducer';
import webhooksReducer from 'reducers/webhooksReducer';

import { QuickswitchActionType } from 'types/quickswitch';

export const createAppReducer = (history) =>
  combineReducers({
    addresses: addressesReducer,
    affiliates: affiliatesRootReducer,
    annotations: annotationsReducer,
    annotationLineItems: annotationLineItemsReducer,
    announcements: announcementsReducer,
    apiTokens: apiTokensReducer,
    approvals: approvalsReducer,
    approvalRequirements,
    approvalSummary: approvalSummaryReducer,
    attachments: attachmentsReducer,
    auth: authReducer,
    billing: billingReducer,
    brands: brandsReducer,
    bulkActions: bulkActionsReducer,
    clearingAccounts: clearingAccountsReducer,
    companies: companiesReducer,
    componentConfigs: componentConfigurationReducer,
    cornerDialogs: cornerDialogsReducer,
    countries: countriesReducer,
    currencies: currenciesReducer,
    currentCompany: currentCompanyReducer,
    currentUser: currentUserReducer,
    dashboardLoaded: dashboardLoadedReducer,
    documents: documentsReducer,
    errors: errorsReducer,
    exportFile: exportFileReducer,
    externalOnboarding: externalOnboardingReducer,
    featureFlags: featureFlagsReducer,
    featureSettings: featureSettingsReducer,
    files: filesReducer,
    form: formReducer.plugin(formReducerPlugin),
    funding: fundingReducer,
    integrationConfigs: integrationConfigsReducer,
    integrations: integrationsReducer,
    invoices: invoicesReducer,
    [itemEarApprovalsSlice.name]: itemEarApprovalsSlice.reducer,
    itemMembers: itemMemberReducer,
    items: itemsReducer,
    logEntry: logEntryReducer,
    maintenanceMode: maintenanceModeReducer,
    membershipInvites: membershipInvitesReducer,
    membershipPersonalInfo: membershipPersonalInfoReducer,
    memberships: membershipsReducer,
    messages: messagesReducer,
    modals: modalsReducer,
    notificationSettings: notificationSettingsReducer,
    notificationSettingsEmailForwards: notificationSettingsEmailForwardsReducer,
    notificationTableData: notificationTableDataReducer,
    oldVersionMode: oldVersionModeReducer,
    partnershipFundingStates: partnershipFundingStatesReducer,
    partnershipItems: partnershipItemsReducer,
    partnershipMembers: partnershipMembersReducer,
    partnershipRequest: partnershipRequestReducer,
    partnershipPayment: partnershipPaymentReducer,
    partnerships: partnershipsReducer,
    passwordInput: passwordInputReducer,
    payments: paymentsReducer,
    permissionGroups: permissionGroupsReducer,
    permissions: permissionsReducer,
    purposeCode: purposeCodeReducer,
    quickswitch: quickswitchReducer,
    reduxShiftSelect,
    roles: rolesReducer,
    router: connectRouter(history),
    sidebar: sidebarReducer,
    sidePanels: sidePanelsReducer,
    signup: signupReducer,
    splitBill,
    sso: ssoReducer,
    table: tablesReducer,
    tagging: taggingReducer,
    taxes: taxesReducer,
    threads: threadsReducer,
    transactions: transactionsReducer,
    ui: uiReducer,
    users: usersReducer,
    webhooks: webhooksReducer,
    [routableApi.reducerPath]: routableApi.reducer,
  });

const getRootReducer = (appReducer) => (state, action) => {
  // Ensure state is destroyed upon log out
  if (
    isEqual(action.type, AuthActionType.SUBMIT_LOGOUT_COMPLETE) ||
    isEqual(action.type, QuickswitchActionType.RE_LOGIN_SUCCESS) ||
    isEqual(action.type, QuickswitchActionType.SWITCH_RESET_STATE)
  ) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

const createRootReducer = (history) => getRootReducer(createAppReducer(history));

export default createRootReducer;
