import React from 'react';
import { Field } from 'redux-form';

import { ButtonV2 } from 'components/buttonV2';
import { FormControl } from 'components/form';
import { IconNames } from 'components/icon';
import { ReduxFormFieldRenderInput } from 'components/input';

import { createItemFormFields } from 'constants/formFields';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import { confirmAlert } from 'helpers/confirmAlert';
import {
  getFundingProviderMemoConfirmDeleteSwalText,
  getRemittanceInfoOrAddendaRecordText,
} from 'helpers/fundingProviderMemo';
import { capitalize } from 'helpers/stringHelpers';

import { FundingProviderMemoInteractionBarProps } from './FundingProviderMemoInteractionBar.types';

/**
 * The interaction bar used for editing/deleting the already added fundingProviderMemo.
 */
const FundingProviderMemoInteractionBar: React.FC<FundingProviderMemoInteractionBarProps> = ({
  isDeliveryOptionRTP,
  onDeleteClick,
  onEditClick,
}) => {
  const handleDeleteClick = async () => {
    const config = {
      buttonsConfig: {
        confirmButtonText: 'Delete',
      },
    };

    const swalText = getFundingProviderMemoConfirmDeleteSwalText(isDeliveryOptionRTP);
    const confirm = await confirmAlert(swalText, undefined, config);

    if (confirm) {
      onDeleteClick();
    }
  };

  return (
    <FormControl>
      <Field
        addon={
          <>
            <ButtonV2
              className="margin-right--m"
              intent={Intent.NEUTRAL}
              isIconButton
              leftIconName={IconNames.ANNOTATION}
              leftIconSize={sizes.iconSizes.LARGE}
              onClick={onEditClick}
              type="button"
            />
            <ButtonV2
              intent={Intent.NEUTRAL}
              isIconButton
              leftIconName={IconNames.TRASH}
              leftIconSize={sizes.iconSizes.LARGE}
              onClick={handleDeleteClick}
              type="button"
            />
          </>
        }
        className="w-full"
        component={ReduxFormFieldRenderInput}
        isDisabled
        label={capitalize(getRemittanceInfoOrAddendaRecordText(isDeliveryOptionRTP))}
        name={createItemFormFields.FUNDING_PROVIDER_MEMO}
      />
    </FormControl>
  );
};

export default FundingProviderMemoInteractionBar;
