import { CornerDialog as DsCornerDialog } from '@routable/components';
import React from 'react';

import { callIfIsFn } from 'helpers/utility';

import type { CornerDialogProps } from './CornerDialog.types';

const CornerDialog = ({ children, onCloseComplete, ...rest }: CornerDialogProps) => (
  <DsCornerDialog className="corner-dialog" onClose={() => callIfIsFn(onCloseComplete)} {...rest}>
    {children}
  </DsCornerDialog>
);

export default CornerDialog;
