import React from 'react';
import { useSelector } from 'react-redux';
import { Field, InjectedFormProps } from 'redux-form';

import { InternationalPhoneInput } from 'components';
import CannotUpdateFieldHint from 'components/hintTypes/CannotUpdateFieldHint';

import {
  internationalPhoneNumberValidator,
  phoneNumberLengthValidator,
  phoneNumberRequiredValidator,
} from 'helpers/fieldValidation';

import {
  currentCompanyVerificationUpdateAllowedSelector,
  isUpdatingCurrentCompanySelector,
} from 'selectors/currentCompanySelectors';

import { views } from '../constants';
import { mapViewToFields } from '../fields';
import FlowStepperMain from '../FlowStepperMain';
import { viewSteps } from '../helpers/viewSteps';

type BusinessPhoneNumberProps = {
  activeView: ValueOf<typeof views>;
} & Pick<InjectedFormProps, 'invalid'>;

const BusinessPhoneNumber = ({ activeView, invalid }: BusinessPhoneNumberProps): JSX.Element => {
  const updatedAllowed = useSelector(currentCompanyVerificationUpdateAllowedSelector);
  const isUpdating = useSelector(isUpdatingCurrentCompanySelector);

  return (
    <FlowStepperMain
      fields={
        <div>
          {!updatedAllowed && <CannotUpdateFieldHint />}
          {mapViewToFields[activeView]?.map(({ name, ...props }) => (
            <Field
              className="mt-7 w-[242px]"
              component={InternationalPhoneInput}
              dataFullStory
              dataTestId="businessPhoneNumber"
              isDisabled={!updatedAllowed}
              isLocked={!updatedAllowed}
              isRequired
              key={name}
              name={name}
              placeholder="Phone number"
              type="text"
              validate={[phoneNumberRequiredValidator, phoneNumberLengthValidator, internationalPhoneNumberValidator]}
              {...props}
            />
          ))}
        </div>
      }
      invalid={invalid || !updatedAllowed}
      onBack={viewSteps[activeView].previous}
      onNext={{
        ...viewSteps[activeView].next,
        isLoading: isUpdating,
      }}
      onSkip={updatedAllowed && viewSteps[activeView].skip}
      title={{ content: 'What’s your business phone number?' }}
    />
  );
};

export default BusinessPhoneNumber;
