import React from 'react';
import { useSearchParam } from 'react-use';

import { ItemFilterUrlParams } from 'enums/itemFilters';

import { isItemKindPayable } from 'helpers/items';

import PartnershipPaymentItems from '../PartnershipPaymentItems';
import PartnershipRequestItems from '../PartnershipRequestItems';

import { ExternalItemsListWrapperProps } from './ExternalItemsListWrapper.types';

/**
 * Wrapper component around ExternalItemsList table component
 *
 * @param {ExternalItemsListWrapperProps} props
 * @returns {StatelessComponent}
 */
const ExternalItemsListWrapper: React.FC<ExternalItemsListWrapperProps> = ({
  fetchPartnershipItems,
  itemsKind,
  partnershipItems,
  wasFetched,
}) => {
  const paymentId = useSearchParam(ItemFilterUrlParams.FILTER_PAYMENT_ID_URL_PARAM);
  const handleFetchPartnershipItems = React.useCallback(() => {
    // We only want to fetch partnership items if not already fetched
    if (!wasFetched) {
      fetchPartnershipItems();
    }
  }, [fetchPartnershipItems, wasFetched]);

  React.useEffect(() => {
    // if this is a payment external flow, we want to skip this call.
    if (!paymentId) {
      handleFetchPartnershipItems();
    }
  }, [handleFetchPartnershipItems, paymentId]);

  // If item kind is payable, we want to display PartnershipPaymentItems table
  if (isItemKindPayable({ kind: itemsKind })) {
    return <PartnershipPaymentItems partnershipItems={partnershipItems} />;
  }

  // Otherwise (if item kind is receivable), we want to display PartnershipRequestItems table
  return <PartnershipRequestItems partnershipItems={partnershipItems} />;
};

export default ExternalItemsListWrapper;
