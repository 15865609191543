import React from 'react';

import { useGetItemListQuery } from 'ducks/routableApi';

import { Table, TablePagination } from 'complexComponents';

import TableLoading from 'complexComponents/Table/components/TableLoading';

import {
  FlexCol,
  FlexRow,
  FullPageModal,
  ModalFooter,
  ModalFooterButtonCancel,
  PaginationBlock,
  Text,
} from 'components';

import { ButtonSize } from 'constants/button';
import { typography } from 'constants/styles';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { getItemStatusText, itemStatusToText, itemStatusToVariant } from 'helpers/status';
import { allValues } from 'helpers/utility';

import { Item } from 'interfaces/item';
import { Pagination } from 'interfaces/jsonapi';

import { SetActivePage } from 'types/UseApi';

import ExternalReceiptItemStatus from '../../../ExternalReceiptItemStatus';
import { InvoicesTableConfig } from '../../constants/tableConfig';
import ExternalReceiptPaymentHeader from '../ExternalReceiptPaymentHeader';

import { ExternalReceiptPaymentFullPageModalProps } from './ExternalReceiptPaymentFullPageModal.types';

import './ExternalReceiptPaymentFullPageModal.scss';

/**
 * Full page modal component rendering payment details.
 */
const ExternalReceiptPaymentFullPageModal: React.FC<ExternalReceiptPaymentFullPageModalProps> = ({
  modalState,
  onCloseModal,
  onSetActiveItem,
  payment,
}) => {
  const [pageNumber, setPageNumber] = React.useState(1);

  const { data, isFetching } = useGetItemListQuery({
    params: {
      page: {
        size: 25,
        number: pageNumber,
      },
      payment: payment?.id,
    },
  });

  const goToPage: SetActivePage = (destinationPage) => {
    switch (destinationPage) {
      case 'PREV':
        setPageNumber((current) => current - 1);
        break;
      case 'NEXT':
        setPageNumber((current) => current + 1);
        break;
      default:
        setPageNumber(destinationPage);
    }
  };

  let items: Item[] = [];
  if (data?.item) {
    const itemsById = getObjectsByIdWithRelationships(data.item);
    items = allValues(itemsById);
  }

  const pagination: Pagination = data?.meta?.pagination ?? {
    page: pageNumber,
    pageSize: 25,
  };

  const { amount, currencyCode, kind: itemKind, status: paymentStatus } = payment;
  const showDetailsColumn = items.some(({ lineItems }) => lineItems?.length);

  const text = items.length ? getItemStatusText(items[0], paymentStatus) : '';

  const paymentName = 'Batch payment';

  return (
    <FullPageModal
      body={
        <>
          <FlexRow>
            <FlexCol className="flex--1 padding--m-large padding-bottom--remove--important">
              <ExternalReceiptPaymentHeader
                amount={parseFloat(amount)}
                currencyCode={currencyCode}
                itemKind={itemKind}
              />
              <ExternalReceiptItemStatus
                status={itemStatusToVariant[paymentStatus]}
                statusText={itemStatusToText[paymentStatus]}
                text={text}
              />
            </FlexCol>
            <div className="flex--3 padding--m-large">
              <PaginationBlock
                count={pagination.count}
                filterLabel="All invoices in this payment"
                onGoToPage={goToPage}
                page={pagination.page}
                pages={pagination.pages}
                pageSize={pagination.pageSize}
              />
              {isFetching ? (
                <TableLoading />
              ) : (
                <Table
                  className="border-top--none border-bottom--none"
                  columns={InvoicesTableConfig({
                    itemKind,
                    showDetailsColumn,
                    onClick: onSetActiveItem,
                  })}
                  data={items}
                  pageSize={pagination.pageSize}
                />
              )}
              <TablePagination
                count={pagination.count}
                onGoToPage={goToPage}
                page={pagination.page}
                pages={pagination.pages}
                pageSize={pagination.pageSize}
              />
            </div>
          </FlexRow>
          <ModalFooter className="external-receipt-payment-full-page-modal__footer">
            <div className="content--left" />
            <div className="content--right">
              <ModalFooterButtonCancel buttonSize={ButtonSize.MEDIUM} buttonText="Close" onCloseModal={onCloseModal} />
            </div>
          </ModalFooter>
        </>
      }
      hasInlineFooter
      header={
        <Text.Semibold
          className="padding-top--m-large padding-left--large"
          color={typography.TextColor.BLACK}
          size={typography.TextSize.LEVEL_200}
        >
          {paymentName}
        </Text.Semibold>
      }
      modalClassname="external-receipt-payment-full-page-modal"
      modalState={modalState}
      onCloseModal={onCloseModal}
    />
  );
};

export default ExternalReceiptPaymentFullPageModal;
