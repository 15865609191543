/* eslint-disable no-param-reassign */
import { useMemo, useEffect } from 'react';
import { usePrevious, useMount, useUnmount } from 'react-use';

// I'm assuming we don't need this as this can only ever happen with SSR,
// but keeping this functionality just to be safe
const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

type IntercomInternalProps = {
  appId: string;
  config?: Record<string, unknown> | null;
};

export const IntercomInternal = ({ appId, config }: IntercomInternalProps) => {
  const email = config?.email;
  const userId = config?.userId;

  const isLoggedIn = useMemo(() => Boolean(email || userId), [email, userId]);
  const wasLoggedIn = usePrevious(isLoggedIn);
  const canUseIntercom = useMemo(() => canUseDOM && !!window.Intercom, []);

  useMount(() => {
    if (!window.Intercom) {
      // Not a fan of this but it's what's being recommended on the Intercom's docs page
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (function bootIntercom(w, d, id, s, _x) {
        function i() {
          // eslint-disable-next-line prefer-rest-params
          i.c(arguments);
        }
        i.q = [];
        i.c = function pushArguments(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = `https://widget.intercom.io/widget/${id}`;
        d.head.appendChild(s);
      })(window, document, appId);
    }

    window.intercomSettings = { ...config, app_id: appId };

    window.Intercom?.('boot', config);
  });

  useEffect(() => {
    // Update windiw.intercomSettings anytime the config changes
    window.intercomSettings = { app_id: appId, ...config };
  }, [config, appId]);

  useEffect(() => {
    if (!canUseIntercom) {
      return;
    }

    // Handle user logout - Shutdown and boot each time the user logs out to clear conversations
    if (!isLoggedIn && wasLoggedIn) {
      window.Intercom('shutdown');
      window.Intercom('boot', config);
    } else {
      window.Intercom('update', config);
    }
  }, [canUseIntercom, isLoggedIn, wasLoggedIn, config]);

  useUnmount(() => {
    if (!canUseIntercom) {
      return;
    }

    window.Intercom('shutdown');

    delete window.Intercom;
    delete window.intercomSettings;
  });

  return null;
};
