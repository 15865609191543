import { REST } from 'helpers/api';

import { FetchServiceResponse } from 'interfaces/fetchService';
import { PurposeCodePayloadType } from 'interfaces/purposeCode';

import * as endpoints from 'services/api/purposeCodeEndpoints';

export const fetchPurposeCode = async (payload: PurposeCodePayloadType, options = {}): Promise<FetchServiceResponse> =>
  REST.get({
    endpoint: endpoints.getPurposeCodeEndpoint(payload),
    requireAuth: true,
    ...options,
  });
