import { CurrencyInput } from '@routable/components';
import { RoutableObject } from '@routable/framework';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

import FormFieldErrors from 'components/error/components/FormFieldErrors';

import { getFieldErrors } from 'helpers/errors';

import { inputHelpers } from '../helpers';

type FieldInput = {
  name: string;
  onBlur(): void;
  onChange(val: string): void;
  onDragStart(val: string): void;
  onDrop(val: string): void;
  onFocus(val: string): void;
  value: string | number;
};

interface CurrencyInputFieldProps extends RoutableObject {
  contentBefore?: ReactNode;
  input: FieldInput;
}

export const CurrencyInputField: React.FC<CurrencyInputFieldProps> = ({
  addon,
  className,
  contentBefore,
  input,
  isDisabled,
  meta,
  ...rest
}) => {
  const errors = (meta.touched || meta.submitFailed) && meta.error;

  const hasErrors = Boolean(errors) || getFieldErrors(errors, input.name);
  const { widthClasses, otherClasses } = inputHelpers.getInputClasses(
    {
      addon,
      className,
      isDisabled,
    },
    { hasErrors },
  );

  return (
    <div className={clsx(widthClasses)}>
      <div className={clsx(otherClasses)}>
        {Boolean(contentBefore) && contentBefore}

        <CurrencyInput
          currencyCode={rest?.suffix?.trim() || 'USD'}
          disabled={isDisabled}
          hidePlaceholder={rest?.hidePlaceholder}
          name={input.name}
          onChange={input.onChange}
          required={rest.isRequired}
          value={input?.value}
        />
      </div>
      <FormFieldErrors errors={errors} fieldName={input.name} />
    </div>
  );
};
