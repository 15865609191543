import { createRoutine } from 'redux-saga-routines';

import * as types from 'types/currentCompany';

export const fetchCurrentCompanySettingsItemApprovalRoutine = createRoutine(
  types.FETCH_CURRENT_COMPANY_SETTINGS_ITEM_APPROVAL,
);

export const updateCurrentCompanySettingsIntegrationRoutine = createRoutine(
  types.UPDATE_CURRENT_COMPANY_SETTINGS_INTEGRATION,
);

export const enableInternationalPaymentsRoutine = createRoutine(types.ENABLE_INTERNATIONAL_PAYMENTS);
