import React from 'react';

import LinkNewPage from 'components/link/LinkNewPage';
import { Text, WhiteSpace } from 'components/text';

import { TagText, TagType } from 'constants/ui';

import helpDocs from 'helpers/helpDocs';

import type { DashboardToDashboardW9RestrictionTooltipContentProps as Props } from './DashboardToDashboardW9RestrictionTooltipContent.types';

/**
 * Tooltip content for when a partnership is dashboard-to-dashboard and we want to
 * block W9 related action.
 */
const DashboardToDashboardW9RestrictionTooltipContent = ({
  actionText = 'to request W-9 tax info',
}: Props): JSX.Element => (
  <Text.Regular>
    {`A ${TagText[TagType.SELF_MANAGED].toLowerCase()} company means that they are also a Routable user, and because 
    of this, you are not able ${actionText}. Learn more about`}
    <WhiteSpace />
    <LinkNewPage className="primary" href={helpDocs.SELF_MANAGED_COMPANIES}>
      {`${TagText[TagType.SELF_MANAGED].toLowerCase()} companies`}
    </LinkNewPage>
    .
  </Text.Regular>
);

export default DashboardToDashboardW9RestrictionTooltipContent;
