import styled from 'styled-components';

import { Icon } from 'components';

export const Item = styled.li<{ disabled: boolean; isActive: boolean }>`
  align-items: center;
  color: ${({ color }) => color};
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
  ${({ disabled, isActive }) => (disabled || isActive) && `pointer-events: none;`}

  &:hover {
    ${({ disabled }) => !disabled && 'text-decoration: underline;'}
  }
`;

export const SubstepIcon = styled(Icon)`
  height: 14px;
  width: 14px;
`;
