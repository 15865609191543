import { IconNames } from 'components';

import { colors } from 'constants/styles';

import { isEqual, lengthOf } from 'helpers/utility';

import { PermissionSectionDisclosureListSummary, PermissionSectionDisclosureListHeaderTextPrefix } from '../constants';

/**
 * Is the summary of permissions for the PermissionSectionDisclosureList that ALL of the permissions are enabled?
 *
 * @param {PermissionSectionDisclosureListSummary} summary
 * @returns {boolean}
 */
export const isPermissionSectionDisclosureListSummaryAll = (summary) =>
  isEqual(summary, PermissionSectionDisclosureListSummary.ALL);

/**
 * Is the summary of permissions for the PermissionSectionDisclosureList that NONE of the permissions are enabled?
 *
 * @param {PermissionSectionDisclosureListSummary} summary
 * @returns {boolean}
 */
export const isPermissionSectionDisclosureListSummaryNone = (summary) =>
  isEqual(summary, PermissionSectionDisclosureListSummary.NONE);

/**
 * Is the summary of permissions for the PermissionSectionDisclosureList that SOME of the permissions are enabled?
 *
 * @param {PermissionSectionDisclosureListSummary} summary
 * @returns {boolean}
 */
export const isPermissionSectionDisclosureListSummarySome = (summary) =>
  isEqual(summary, PermissionSectionDisclosureListSummary.SOME);

/**
 * Sometimes we add an extra enabled key on the frontend to make it easier to determine if a permission is enabled for
 * a specific role. This checks if that key is true/false.
 *
 * @param {Permission} permission
 * @returns {boolean}
 */
export const isPermissionEnabled = (permission) => Boolean(permission?.enabled);

/**
 * Give us a summary of how many permissions in a list are enabled.
 *
 * @param {Permission[]} permissions
 * @returns {PermissionSectionDisclosureListSummary}
 */
export const summarizePermissionSection = (permissions) => {
  const enabledPermissions = permissions.filter(isPermissionEnabled);

  const totalNumberOfPermissions = lengthOf(permissions);
  const numberOfEnabledPermissions = lengthOf(enabledPermissions);

  // all of the permissions are enabled
  if (isEqual(totalNumberOfPermissions, numberOfEnabledPermissions)) {
    return PermissionSectionDisclosureListSummary.ALL;
  }

  // none of the permissions are enabled
  if (isEqual(numberOfEnabledPermissions, 0)) {
    return PermissionSectionDisclosureListSummary.NONE;
  }

  // somewhere in the middle, some enabled, some disabled
  return PermissionSectionDisclosureListSummary.SOME;
};

/**
 * Determine the header icon and color based on how many of the permissions in this section are enabled.
 *
 * @param {PermissionSectionDisclosureListSummary} permissionEnabledSummary
 * @return {Object}
 */
export const getPermissionSectionDisclosureListHeaderIconProps = (permissionEnabledSummary) => {
  if (isPermissionSectionDisclosureListSummaryAll(permissionEnabledSummary)) {
    return {
      iconName: IconNames.TICK_CIRCLE,
      iconProps: {
        color: colors.colorGreenBoldHex,
      },
    };
  }

  if (isPermissionSectionDisclosureListSummaryNone(permissionEnabledSummary)) {
    return {
      iconName: IconNames.BAN_CIRCLE,
      iconProps: {
        color: colors.colorRedBoldHex,
      },
    };
  }

  // PermissionSectionDisclosureListSummary.SOME
  return {
    iconName: IconNames.FORM,
    iconProps: {
      color: 'var(--color-gold40)',
    },
  };
};

/**
 * Get the header text based on how many of the permissions in this section are enabled.
 *
 * @param {OptionsArg} options
 * @param {PermissionGroup.name} options.permissionGroupName
 * @param {PermissionSectionDisclosureListSummary} options.permissionSummary
 * @returns {string}
 */
export const getPermissionSectionDisclosureListHeaderText = ({ permissionGroupName, permissionSummary }) => {
  // full, no, limited
  const prefix = PermissionSectionDisclosureListHeaderTextPrefix[permissionSummary];

  return `${prefix} access to ${permissionGroupName}`;
};

/**
 * A function which unifies all of the UI helpers into a single call for the presenter to get the conditional props it
 * needs to render correctly based on the number of enabled permissions.
 *
 * @param {OptionsArg} options
 * @param {PermissionGroup.name} options.permissionGroupName
 * @param {Permissions[]} options.permissions
 * @returns {Object}
 */
export const getPermissionSectionDisclosureListConditionalProps = ({ permissionGroupName, permissions }) => {
  const permissionSummary = summarizePermissionSection(permissions);

  const { iconName, iconProps } = getPermissionSectionDisclosureListHeaderIconProps(permissionSummary);
  const headerText = getPermissionSectionDisclosureListHeaderText({
    permissionGroupName,
    permissionSummary,
  });

  return { headerText, iconName, iconProps };
};
