import React from 'react';

import { FlexRow, CurrencyAmountText } from 'components';

import { ItemCurrencyCodeKey } from 'enums/items';

import { getItemAmountString } from 'helpers/amounts';

import { useCurrencyContext } from 'hooks';

import type { ShowAmountCellProps } from './ShowAmountCell.types';

/**
 * Renders a read-only cell for displaying amounts
 */
const ShowAmountCell = ({
  amountProps,
  children,
  currencyCodeKey = ItemCurrencyCodeKey.GENERAL,
  dataKey,
  rowData,
}: ShowAmountCellProps) => {
  const currencyCodeMap = useCurrencyContext();
  const amount = getItemAmountString(currencyCodeMap, rowData, dataKey, {
    currencyCode: rowData.predictedCurrencyCode,
    currencyCodeKey,
  });

  return (
    <FlexRow className="w-full items-center justify-end inline-flex gap-4" stackOnMobile={false}>
      {children}

      <CurrencyAmountText
        amount={amount}
        amountProps={amountProps}
        currencyCode={rowData.predictedCurrencyCode ?? rowData[currencyCodeKey]}
        parseCurrencyOptions={{ symbol: '' }}
      />
    </FlexRow>
  );
};

export default ShowAmountCell;
