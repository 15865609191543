import React from 'react';
import { Link } from 'react-router-dom';

import { BoxV2 } from 'components';

import { RESET_PASSWORD } from 'constants/routes';

import { SSOProviders } from 'enums/sso';

import { asPath } from 'helpers/routeHelpers';

import ChangeWorkspace from '../ChangeWorkspace';
import EmailPasswordFormContainer from '../EmailPasswordForm';
import LoginWithGoogleButton from '../LoginWithGoogleButton/LoginWithGoogleButton';
import LoginWithSSOButton from '../LoginWithSSOButton/LoginWithSSOButton';

import './OptionalSSOLoginForm.scss';
import { OptionalSSOLoginFormProps } from './OptionalSSOLoginForm.types';

/**
 * OptionalSSOLoginForm
 * Used when the company has sso settings as optional.
 */
const OptionalSSOLoginForm: React.FC<OptionalSSOLoginFormProps> = ({
  currentSSOSettings,
  onSSOLogin,
  isSSOSubmitting,
}) => {
  let buttonComponent: React.ReactElement;
  if (currentSSOSettings.ssoProvider === SSOProviders.GOOGLE_OAUTH2) {
    buttonComponent = <LoginWithGoogleButton isLoading={isSSOSubmitting} onClick={onSSOLogin} />;
  } else {
    buttonComponent = <LoginWithSSOButton isLoading={isSSOSubmitting} onClick={onSSOLogin} />;
  }

  return (
    <BoxV2
      className="box-v2--container--login optional-sso-login"
      subtitle={<ChangeWorkspace />}
      title="Log in to your workspace"
    >
      {buttonComponent}
      <div className="optional-sso-login--divider">
        <span className="optional-sso-login--divider__text">OR USE EMAIL</span>
      </div>
      <EmailPasswordFormContainer />
      <div className="margin-top--large">
        <Link className="font-sm semibold font-color--steel" to={asPath(RESET_PASSWORD)}>
          Forgot your password?
        </Link>
      </div>
    </BoxV2>
  );
};

export default OptionalSSOLoginForm;
