import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DatePicker } from 'components';

import { getErrorsFromFieldState } from '../helpers';

import { RenderDatePickerProps } from './RenderDatePicker.types';

const RenderDatePicker = ({
  isRequired,
  name,
  minDate,
  maxDate,
  placeholder,
  shouldUnregister,
}: RenderDatePickerProps): JSX.Element => {
  const { control, setFocus, setValue } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <DatePicker
          fixedHeight
          innerRef={(ref) => {
            field.ref({ focus: ref?.setFocus });
          }}
          input={{
            ...field,
            onChange: (date) => setValue(name, date, { shouldTouch: true, shouldValidate: true }),
            onFocus: () => setFocus(name),
          }}
          isRequired={isRequired}
          maxDate={maxDate}
          meta={{
            touched: fieldState.isTouched,
            error: getErrorsFromFieldState(fieldState, field.name),
          }}
          minDate={minDate}
          onBlur={field.onBlur}
          placeholderText={placeholder}
        />
      )}
      rules={{ required: isRequired }}
      shouldUnregister={shouldUnregister}
    />
  );
};

export default RenderDatePicker;
