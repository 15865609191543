import React from 'react';

import { Text, WhiteSpace } from 'components';

import { getPartnershipCurrencyNameAndCode } from './helpers';
import { ManuallyAddBankAccountHelpTextProps } from './ManuallyAddBankAccountHelpText.types';

/**
 * Component rendering Manually Add Bank Account side sheet helper text.
 * @see {ManuallyAddBankAccountSideSheet}
 * @param {ManuallyAddBankAccountHelpTextProps} props
 * @returns {StatelessComponent}
 */
const ManuallyAddBankAccountHelpText: React.FC<ManuallyAddBankAccountHelpTextProps> = ({
  partnerCompany,
  partnershipCurrency,
}) => (
  <Text.Regular>
    You&apos;re set up to receive payments in
    <WhiteSpace />
    {getPartnershipCurrencyNameAndCode(partnershipCurrency)}
    . Contact
    <WhiteSpace />
    {partnerCompany.name}
    <WhiteSpace />
    if you need to change your currency.
  </Text.Regular>
);

export default ManuallyAddBankAccountHelpText;
