import React from 'react';

import { Icon, IconNames } from 'components';

import { colors, sizes } from 'constants/styles';

import { ErrorIndicatorContainer } from './ApprovalLevelsMissingRequiredApprover.styles';

/**
 * Component rendering an error indicator when required approver is
 * missing in the scope of the approval levels table
 */
const ApprovalLevelsMissingRequiredApprover = ({ warning = false }: { warning?: boolean }): JSX.Element => (
  <ErrorIndicatorContainer>
    <Icon
      color={warning ? 'var(--color-gold40)' : colors.colorRedBoldHex}
      icon={warning ? IconNames.WARNING_SIGN : IconNames.ERROR}
      size={sizes.iconSizes.LARGE}
    />
    A required approver is no longer active
  </ErrorIndicatorContainer>
);

export default ApprovalLevelsMissingRequiredApprover;
