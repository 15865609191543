import { getQueryParamsFromUrl, getQueryString } from 'helpers/queryParams';

/**
 * Takes the query part of the url (should come from location.search)
 * Adds parameters from the sortParam
 */
export const getQueryStringFromSortParam = (urlQuery: string, sortParam: string): string => {
  const queryPart = urlQuery.replace(/^\?+(.+)/, '$1');
  const params = getQueryParamsFromUrl(queryPart);
  return getQueryString({ ...params, sort: sortParam });
};

/**
 * Updates the browser url with needed params from the sortParam
 */
export const sortParamToUrl = (sortParam: string, window: Window = global.window): void => {
  const lastPathSection = window.location.pathname.split('/').pop();
  const hashPart = window.location.hash;
  const newQuery = getQueryStringFromSortParam(window.location.search, sortParam);
  const urlUpdate = `${lastPathSection}?${newQuery}${hashPart}`;

  // Does an in-place update of the url without re-triggering the react router or reloading the page
  // it updates from the last path section onwards
  window.history.replaceState(null, '', urlUpdate);
};
