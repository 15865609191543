import ReactDropzone from 'react-dropzone';
import styled from 'styled-components';

import { containerWidthMap } from 'modules/signup-v3/constants/container';

export const DropzoneContainer = styled(ReactDropzone)`
  align-items: center;
  background-color: var(--white);
  border: 1px dashed var(--blue--bold);
  border-radius: 0.5rem;
  ${({ disabled }) => `cursor: ${disabled ? 'initial' : 'pointer'};`}
  ${({ disabled }) => disabled && `pointer-events: none;`}

    display: flex;
  flex-direction: column;
  gap: 12px;
  height: 200px;
  justify-content: center;

  &:hover {
    ${({ disabled }) => !disabled && `background-color: var(--blue--x-light);`};
    ${({ disabled }) => !disabled && `border: 1px solid var(--blue--bold);`};
  }
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: var(--grey--xx-dark);
  text-align: center;
  margin: 0;
  max-width: ${containerWidthMap.medium};
`;

export const SupportText = styled.p`
  font-size: 13px;
  color: var(--grey--dark);
  margin: 0;
  line-height: 18px;
  max-width: ${containerWidthMap.medium};
`;

export const EmphasizedText = styled.span`
  color: var(--blue--bold);
  font-weight: 600;
`;
