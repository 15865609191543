import React from 'react';

import { DropdownMenuOption } from 'complexComponents/DropdownMenuButton';

import { NumberChip } from 'components/index';

import { isGreaterOrEqual } from 'helpers/utility';

import type { TableToolbarSelectionManagerOptionProps } from './TableToolbarSelectionManagerOption.types';

/**
 * Renders a single menu option in the TableToolbarActionsMenu.
 */
const TableToolbarSelectionManagerOption: React.FC<TableToolbarSelectionManagerOptionProps> = ({
  actionableCount,
  isDisabled,
  isDisabledWhenNoActionable,
  title,
  ...rest
}) => {
  const hasActionableCount = isGreaterOrEqual(actionableCount, 1);

  return (
    <DropdownMenuOption
      {...rest}
      className="table-toolbar--dd-option"
      isDisabled={isDisabled || (isDisabledWhenNoActionable && !hasActionableCount)}
      title={<span>{title}</span>}
    >
      {hasActionableCount && <NumberChip className="action-count">{actionableCount}</NumberChip>}
    </DropdownMenuOption>
  );
};

TableToolbarSelectionManagerOption.defaultProps = {
  actionableCount: undefined,
  className: undefined,
  children: undefined,
  icon: undefined,
  isDisabled: undefined,
  isDisabledWhenNoActionable: true,
};

export default TableToolbarSelectionManagerOption;
