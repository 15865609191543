import React from 'react';

import { Icon, IconNames, Text } from 'components';
import { TooltipMUI } from 'components/tooltip';

import { colors, typography, sizes } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';

import './QuickswitchMembershipList.scss';

interface QuickswitchMembershipListIconProps {
  isReauthenticationRequired: boolean;
}

const QuickswitchMembershipListIcon = ({
  isReauthenticationRequired,
}: QuickswitchMembershipListIconProps): JSX.Element => {
  const iconName = isReauthenticationRequired ? IconNames.LOG_IN : IconNames.LINK;
  if (isReauthenticationRequired) {
    return (
      <TooltipMUI
        arrow
        padding={TooltipPadding.SKINNY}
        style={{ backgroundColor: 'white' }}
        title={
          <Text.Regular color={typography.TextColor.GREY_X_DARK} size={typography.TextSize.LEVEL_100}>
            Reauthentication is required for this account.
          </Text.Regular>
        }
      >
        <Icon color={colors.colorBlueBoldHex} icon={iconName} size={sizes.iconSizes.MEDIUM} />
      </TooltipMUI>
    );
  }
  return <Icon color={colors.colorBlueBoldHex} icon={iconName} size={sizes.iconSizes.MEDIUM} />;
};

export default QuickswitchMembershipListIcon;
