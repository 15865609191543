import React from 'react';

import { UpdatePaymentMethodFormWithCheckboxAndHints } from '../components';

/**
 * Wrapper component that passes isUpdatePaymentMethodFlow down to alter the layout
 * of Update Payment method forms both domestic and international.
 */
const UpdatePaymentMethodFlowForm: React.FC = () => (
  <UpdatePaymentMethodFormWithCheckboxAndHints isUpdatePaymentMethodFlow />
);

export default UpdatePaymentMethodFlowForm;
