import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import { CountryField } from 'components';

import { createPartnershipFormFields } from 'constants/formFields';
import { formNamesPartnership } from 'constants/forms';

import { requiredValidator, vendorCountryAndAddressCountryMatchValidator } from 'helpers/fieldValidation';
import { getCustomerOrVendorTextByPartnership } from 'helpers/partnerships';

import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';

import { PartnerCountryFieldProps } from './PartnerCountryField.types';

/**
 * Field to choose or display the partner's country
 */
const PartnerCountryField = ({ partnership, supportedCountryCodes, className, ...rest }: PartnerCountryFieldProps) => {
  const isInternationalPaymentsEnabled = useSelector(currentCompanySettingsIsInternationalPaymentsEnabledSelector);
  const { isCustomer, isVendor } = Object(partnership);

  // For now RCTMs are only able to change the country for vendors when international
  // payments are enabled.
  const isCountryEditable =
    (isVendor ||
      // handles case when partnership is neither vendor nor customer
      (!isCustomer && !isVendor)) &&
    isInternationalPaymentsEnabled;

  // Assemble text for the tooltip to describe to users why this field might be locked.
  const partnerType = getCustomerOrVendorTextByPartnership(partnership);
  const lockedInputReason = `You can only invite US-based ${partnerType}s for now.`;

  return (
    <div className={clsx('form-control', className)}>
      <CountryField
        className="w-full"
        countryCodes={supportedCountryCodes}
        formName={formNamesPartnership.CREATE}
        isEnabled={Boolean(isCountryEditable)}
        isRequired
        name={createPartnershipFormFields.PARTNER_COUNTRY_CODE}
        partnership={partnership}
        placeholder="Enter vendor's country"
        reason={lockedInputReason}
        validate={[requiredValidator, vendorCountryAndAddressCountryMatchValidator]}
        {...rest}
      />
    </div>
  );
};

export default PartnerCountryField;
