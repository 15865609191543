import React from 'react';
import { useMedia } from 'react-use';

import { FullPageModal } from 'components';
import { Modal } from 'components/modal';

import { mobileMediaQuery } from 'constants/mediaQuery';
import { ZIndexLayers } from 'constants/ui';

import { noop, ternary } from 'helpers/utility';
import { getZIndex } from 'helpers/zIndex';

import { modalContentHeight, modalContentWidth } from '../constants';

import QuickswitchErrorModalContent from './components/QuickswitchErrorModalContent';

import './QuickswitchErrorModal.scss';

export interface QuickswitchErrorModalProps {
  isModalOpen: boolean;
}

const QuickswitchErrorModal: React.FC<QuickswitchErrorModalProps> = ({ isModalOpen }) =>
  ternary(
    useMedia(mobileMediaQuery),
    <FullPageModal
      body={<QuickswitchErrorModalContent />}
      modalState={isModalOpen}
      onCloseModal={noop}
      overrideStyle={{
        overlay: { zIndex: getZIndex(ZIndexLayers.MODAL.OVERLAY, 25) },
      }}
      showCloseButton={false}
    />,
    <Modal
      body={<QuickswitchErrorModalContent />}
      hasInlineFooter
      hideHeader
      modalState={isModalOpen}
      onCloseModal={noop}
      overrideStyle={{
        content: { maxHeight: modalContentHeight, width: modalContentWidth },
      }}
    />,
  );

export default QuickswitchErrorModal;
