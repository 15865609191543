import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { logoutCleanup } from 'actions/auth';

import { formNamesAuthV2 } from 'constants/forms';

import { noop } from 'helpers/utility';

import { isSubmittingChangePasswordSelector, lastSubmittedChangePasswordSelector } from 'selectors/authSelectors';

import { getResetPasswordCompleteInitialValues } from './helpers/initialValues';
import ResetPasswordComplete from './ResetPasswordComplete';

export const mapStateToProps = createStructuredSelector({
  isSubmitting: isSubmittingChangePasswordSelector,
  lastSubmitted: lastSubmittedChangePasswordSelector,
  initialValues: getResetPasswordCompleteInitialValues,
});

export const mapDispatchToProps = {
  onLogoutCleanup: logoutCleanup,
};

export const ResetPasswordCompleteReduxForm = reduxForm({
  form: formNamesAuthV2.RESET_PASSWORD_COMPLETE,
  onSubmitFail: noop,
})(ResetPasswordComplete);

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(ResetPasswordCompleteReduxForm);
