import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { AddressHookForm } from 'complexComponents/formField';
import { W8FormModel } from 'complexComponents/taxes/TaxFormRenderer';
import { useTaxFormOptions } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.context';

import { countryListOptions } from 'constants/i18n';

import RenderCheckbox from '../RenderCheckbox';
import RenderSelect from '../RenderSelect';
import RenderTextInput from '../RenderTextInput';
import { Group, GroupSlot } from '../styles';

import type { FieldGroupAddressProps } from './FieldGroupAddress.types';

const FieldGroupAddress = ({ fieldNamePrefix }: FieldGroupAddressProps): JSX.Element => {
  const { countries } = useTaxFormOptions();

  return (
    <Group>
      <GroupSlot size={6}>
        <RenderSelect
          isRequired
          label="Country"
          name={`${fieldNamePrefix}Country`}
          options={countries}
          placeholder="Select Country"
          shouldUnregister
        />
      </GroupSlot>
      <GroupSlot size={6}>
        <RenderTextInput
          isRequired
          name={`${fieldNamePrefix}StreetAddress`}
          placeholder="Street, Apartment, Suite number, or Rural route"
          shouldUnregister
        />
      </GroupSlot>
      <GroupSlot size={6}>
        <RenderTextInput isRequired name={`${fieldNamePrefix}City`} placeholder="City" shouldUnregister />
      </GroupSlot>
      <GroupSlot size={3}>
        <RenderTextInput
          isRequired
          name={`${fieldNamePrefix}State`}
          placeholder="State, Province, or Territory"
          shouldUnregister
        />
      </GroupSlot>
      <GroupSlot size={3}>
        <RenderTextInput isRequired name={`${fieldNamePrefix}Postalcode`} placeholder="Postal code" shouldUnregister />
      </GroupSlot>
    </Group>
  );
};

const FieldGroupPermanentAddress = (): JSX.Element => <FieldGroupAddress fieldNamePrefix="residence" />;
const FieldGroupMailingAddress = (): JSX.Element => {
  const { watch, setValue } = useFormContext<W8FormModel>();
  const shouldMailingAddressMatchPermanentAddress = watch('mailingAddressMatchPermanentAddress');
  const residenceCountry = watch('residenceCountry');

  // When the "Match mailing address" checkbox is untoggled, we want to
  // pre-populate the mailing country field with whatever we had set for
  // the residence country.
  useEffect(() => {
    if (!shouldMailingAddressMatchPermanentAddress) {
      setValue('mailingCountry', residenceCountry);
    }
  }, [shouldMailingAddressMatchPermanentAddress, setValue, residenceCountry]);

  return (
    <>
      <Group>
        <GroupSlot size={6}>
          <RenderCheckbox label="Same as permanent residence address" name="mailingAddressMatchPermanentAddress" />
        </GroupSlot>
      </Group>
      {!shouldMailingAddressMatchPermanentAddress && <FieldGroupAddress fieldNamePrefix="mailing" />}
    </>
  );
};

const FieldGroupGenericAddress = (): JSX.Element => {
  return <AddressHookForm addressPath="address" countryOptions={countryListOptions.US_AND_CANADA} />;
};

export { FieldGroupMailingAddress, FieldGroupPermanentAddress, FieldGroupGenericAddress };
