import React from 'react';

import { PrivacyPolicyLink, TermsOfServiceLink } from 'components/link';
import { WhiteSpace } from 'components/text';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { apostrophiseString } from 'helpers/stringHelpers';

const termsOfServiceLinkClassNames = 'font-color--inherit force-underline semibold';

interface TermsOfServiceContentProps {
  prefix?: string;
}

/**
 * Terms of service text content and links.
 * @param {TermsOfServiceContentProps} props
 * @returns {StatelessComponent}
 */
const TermsOfServiceContent: React.FC<TermsOfServiceContentProps> = ({ prefix }) => (
  <span>
    <span className="disable-pointer-events">
      {`${prefix} agree to ${apostrophiseString(PLATFORM_DISPLAY_SHORT_NAME)}`}
    </span>
    <WhiteSpace />
    <TermsOfServiceLink className={termsOfServiceLinkClassNames} />
    <WhiteSpace />
    <span className="disable-pointer-events">and</span>
    <WhiteSpace />
    <PrivacyPolicyLink className={termsOfServiceLinkClassNames} />.
  </span>
);

TermsOfServiceContent.defaultProps = {
  prefix: 'I',
};

export { TermsOfServiceContentProps };
export default TermsOfServiceContent;
