import { applyCompanyPaymentHistoryTableSort, applyPayablesTableSort, applyReceivablesTableSort } from 'actions/tables';

import { CONTACTS_TABS, PAYMENTS_LIST_TABS } from 'constants/routes';

import { onApplySortActionType, sortSelectorType } from 'interfaces/table.types';

import {
  tableCompanyPaymentHistoryTableSortSelector,
  tablePayablesTableSortSelector,
  tableReceivablesTableSortSelector,
} from 'selectors/tableSelectors';

export const getApplySortActionByTab = (tab: string): onApplySortActionType => {
  switch (tab) {
    case PAYMENTS_LIST_TABS.PAYABLES:
      return applyPayablesTableSort;
    case PAYMENTS_LIST_TABS.INBOX:
    case PAYMENTS_LIST_TABS.RECEIVABLES:
      return applyReceivablesTableSort;
    case CONTACTS_TABS.COMPANIES:
      return applyCompanyPaymentHistoryTableSort;
    default:
      return applyPayablesTableSort;
  }
};

export const getSortSelectorByTab = (tab: string): sortSelectorType => {
  switch (tab) {
    case PAYMENTS_LIST_TABS.PAYABLES:
      return tablePayablesTableSortSelector;
    case PAYMENTS_LIST_TABS.INBOX:
    case PAYMENTS_LIST_TABS.RECEIVABLES:
      return tableReceivablesTableSortSelector;
    case CONTACTS_TABS.COMPANIES:
      return tableCompanyPaymentHistoryTableSortSelector;
    default:
      return tablePayablesTableSortSelector;
  }
};

export const getSortParamAfterClick = (isDescending: boolean, isSorted: boolean, param: string): string => {
  if (isSorted && isDescending) {
    return param;
  }
  return `-${param}`;
};
