import { useQuery } from '@tanstack/react-query';
import { queryContext } from 'milton/components';

import { purchaseOrderService } from './purchaseOrder.service';

export const usePurchaseOrderItemData = (id?: string, excludeBillLedgerRef?: string) =>
  useQuery({
    queryKey: ['purchaseOrders', 'prefilled', id],
    queryFn: () => purchaseOrderService.getPrefilled({ id, excludeBillLedgerRef }),
    enabled: !!id,
    context: queryContext,
  });
