import { createModalReducer } from 'store/redux';

import { REFRESH_INTEGRATION_CONFIG_SUCCESS } from 'types/integrations';
import { MODAL_INTEGRATION_SYNC_OPTIONS_OPEN } from 'types/modals';

export const initialState = {
  open: false,
};

/**
 * Modal reducer for IntegrationSyncOptionsModal
 * @see {IntegrationSyncOptionsModal}
 */
const modalIntegrationSyncOptionsReducer = createModalReducer(
  REFRESH_INTEGRATION_CONFIG_SUCCESS,
  MODAL_INTEGRATION_SYNC_OPTIONS_OPEN,
  initialState,
);

export default modalIntegrationSyncOptionsReducer;
