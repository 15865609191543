import { PaymentDeliveryMethodType } from '@routable/shared';
import React from 'react';
import { Field } from 'redux-form';

import { BANK_ACCOUNT_TRANSFER } from 'constants/paymentMethods';

import { ConnectBankRadio } from './components';
import { PartnerCompanyPaymentMethodAchProps } from './PartnerCompanyPaymentMethodAch.types';

/**
 * Component rendering ACH Payment Method Bank Account radio select Redux Form
 * field
 */
const PartnerCompanyPaymentMethodAch = ({
  fieldNamePrefix,
  fundingAccount,
  paymentDeliveryMethod,
}: PartnerCompanyPaymentMethodAchProps): JSX.Element => (
  <Field
    component={ConnectBankRadio}
    name={`${fieldNamePrefix || ''}paymentDeliveryMethod`}
    optionText={BANK_ACCOUNT_TRANSFER}
    props={{
      fieldNamePrefix,
      fundingAccount,
      paymentDeliveryMethod,
    }}
    type="radio"
    value={PaymentDeliveryMethodType.ACH}
  />
);

export default PartnerCompanyPaymentMethodAch;
