import classNames from 'classnames';
import React from 'react';
import { Field } from 'redux-form';

import { ButtonV2, BoxV2, FormControl, IconNames, ReduxFormFieldRenderInput, Form, LinkNewPage } from 'components';

import { ButtonSize } from 'constants/button';
import { formNamesAuthV2 } from 'constants/forms';
import { PLATFORM_EMAILS } from 'constants/platform';
import { formStyles, sizes } from 'constants/styles';

import { trimString } from 'helpers/fieldNormalizers';
import { requiredValidator, slugValidator, subdomainValidator } from 'helpers/fieldValidation';
import { getBrandedWorkspacesDisplayDomain } from 'helpers/urls';

import submit from './helpers/submit';
import { NamespaceFormProps } from './NamespaceForm.types';

/**
 * NamespaceForm used in main login page
 */
const NamespaceForm: React.FC<NamespaceFormProps> = ({ handleSubmit, isSSOFetching }) => (
  <BoxV2 className="box-v2--container--login" title="Enter your workspace name">
    <Form id={formNamesAuthV2.NAMESPACE} onSubmit={handleSubmit(submit)}>
      <FormControl>
        <Field
          className={`${formStyles.field.xl.full} namespace`}
          component={ReduxFormFieldRenderInput}
          dataFullStory
          name="namespace"
          normalize={trimString}
          placeholder="workspace-name"
          type="text"
          validate={[requiredValidator, slugValidator, subdomainValidator]}
        >
          <div className="inline-label inline-right white-background">
            <span className="font-color--steel font-weight--regular">{`.${getBrandedWorkspacesDisplayDomain()}`}</span>
          </div>
        </Field>
      </FormControl>

      <div className="margin-top--m-large">
        <ButtonV2
          htmlFor={formNamesAuthV2.NAMESPACE}
          id="namespace_continue_btn"
          isLoading={isSSOFetching}
          rightIconName={IconNames.ARROW_RIGHT}
          rightIconProps={{ style: { marginLeft: 'auto' } }}
          rightIconSize={sizes.iconSizes.LARGE}
          size={ButtonSize.LARGE}
          type="submit"
        >
          Continue
        </ButtonV2>
      </div>
      <div className="margin-top--large">
        <span
          className={classNames({
            'font-sm': true,
            'font-weight--regular': true,
            'font-color--grey--xx-dark': true,
            'margin-right--sm': true,
          })}
        >
          {`Don't have an account?`}
        </span>
        <LinkNewPage
          className={classNames({
            'dark-blue': true,
            'font-sm': true,
            'font-color--grey--xx-dark': true,
            'margin-right--sm': true,
          })}
          href={`mailto:${PLATFORM_EMAILS.SALES}`}
        >
          Contact sales
        </LinkNewPage>
      </div>
    </Form>
  </BoxV2>
);

export default NamespaceForm;
