import { taxTools } from '@routable/taxes';
import { queryClient } from 'milton/components';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import {
  usePartnershipAndPartnerCompanyFromPartnershipIdQuery,
  useTaxInfoSingleRequestMutation,
} from 'ducks/routableApi/partnership';

import PermissionResourceAction from 'constants/permissions';

import { isCompanyPlatformTypeInInviteStage } from 'helpers/currentCompany';
import {
  getTaxDataKeyForPartnership,
  isPartnershipRoutableToRoutable,
  isPartnershipVendor,
} from 'helpers/partnerships';

import { usePermissions } from 'hooks';

import { currentCompanyCollectVendorTaxInfoSettingsSelector } from 'selectors/currentCompanySelectors';

import TaxInfoRequestButton from './TaxInfoRequestButton';
import { getTaxInfoRequestButtonTooltipProps } from './TaxInfoRequestButton.helpers';
import type { TaxInfoRequestButtonContainerProps } from './TaxInfoRequestButton.types';

/**
 * Container component holding API logic for handling a
 * Tax Info Request for a single vendor
 * @param props
 */
const TaxInfoRequestButtonContainer = ({
  displayType,
  partnershipId,
}: TaxInfoRequestButtonContainerProps): JSX.Element => {
  const hasBeenTriggeredRef = useRef(false);
  const isTaxCollectionDisabled = !useSelector(currentCompanyCollectVendorTaxInfoSettingsSelector);
  const [taxInfoSingleRequest, { isLoading }] = useTaxInfoSingleRequestMutation();

  const { partner, partnership } = usePartnershipAndPartnerCompanyFromPartnershipIdQuery(partnershipId);

  const permissions = usePermissions([PermissionResourceAction.TAX_BASIC_MANAGEMENT]);

  const isR2R = isPartnershipRoutableToRoutable(partnership);
  const isPartnerStatusInvited = isCompanyPlatformTypeInInviteStage(partner?.platformType);
  const isVendor = isPartnershipVendor(partnership);
  const isWithoutContact = !partnership?.hasCompanyManagementContact;

  const isDisabled = Boolean(
    hasBeenTriggeredRef.current || isR2R || isWithoutContact || isTaxCollectionDisabled || !permissions.hasPermission,
  );

  const tooltipProps = getTaxInfoRequestButtonTooltipProps({
    hasBeenTriggered: hasBeenTriggeredRef.current,
    isDisabled,
    isLoading,
    isPartnerStatusInvited,
    isTaxCollectionDisabled,
    isR2R,
    isWithoutContact,
    partnerName: partnership?.name,
    permissions,
  });

  const handleRequestTaxInfo = async () => {
    if (hasBeenTriggeredRef.current) {
      return;
    }

    try {
      const taxDataKey = getTaxDataKeyForPartnership({ partner, partnership });
      await taxInfoSingleRequest({ partnershipId, taxDataKey }).unwrap();
      queryClient.invalidateQueries({ queryKey: [taxTools, partnershipId] });
    } catch {
      // pass
    }

    hasBeenTriggeredRef.current = true;
  };

  if (!isVendor) {
    return null;
  }

  return (
    <TaxInfoRequestButton
      displayType={displayType}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onClick={handleRequestTaxInfo}
      tooltipProps={tooltipProps}
    />
  );
};

export default TaxInfoRequestButtonContainer;
