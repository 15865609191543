import { RoutableObject } from '@routable/framework';
import _find from 'lodash/find';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { LINE_ITEM_EMAIL_DOCUMENT, LineItemStyles } from 'constants/lineItems';

import { ATTACHMENT_DEFAULT_FILENAME, AttachmentStatuses } from 'helpers/ocr/constants';
import { deCamelCaseObjectKeys } from 'helpers/utility';

import useSelectorWithProps from 'hooks/useSelectorWithProps';

import { Attachment } from 'interfaces/attachment';
import { AnnotationItem, AnnotationState } from 'interfaces/redux/annotations';

import {
  LINE_ITEMS_BLOCKS,
  LINE_ITEMS_SUBSECTIONS,
} from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/constants';

import { currentCompanySettingsSelector } from 'selectors/currentCompanySelectors';
import { itemSelector, annotationsByIdsSelector } from 'selectors/itemsSelectors';
import { ocrInboxEnabledInFeatureFlagsAndCompanySettingsSelector } from 'selectors/ocrInboxEnabledSelectors';

import { storeAccessor as store } from 'store/accessor';

// True if feature flag and RAD company setting are on
export const isOcrEnabled = (): boolean => ocrInboxEnabledInFeatureFlagsAndCompanySettingsSelector(store.getState());

export const useIsOcrEnabled = () => {
  const location = useLocation();
  const isInboxBillView = location?.pathname?.includes('create_bill/new') && location?.search?.includes('id=');
  const currentCompanySettings = useSelector(currentCompanySettingsSelector);
  const isEnabled = useSelector(ocrInboxEnabledInFeatureFlagsAndCompanySettingsSelector);

  const isFetchingOcrEnabled = !currentCompanySettings;

  return { isFetchingOcrEnabled, isInboxBillView, isOcrEnabled: isEnabled } as const;
};

// Add fields that you want to get from OCR here (must be in payload annotations);
const annotationFields = ['amount', 'dateDue', 'id', 'status', 'partnership', 'partnerships', 'lineItems'];

export const hasAnyAnnotationsToProcess = (annotations: RoutableObject, id: string): boolean => {
  const annotationItem: RoutableObject = _find(annotations, {
    relatedItem: { id },
  });

  return !!annotationItem?.lineItems?.length;
};

export const processAnnotations = (options: {
  annotations: AnnotationState['byId'];
  id: string;
}): Record<string, AnnotationItem> => {
  const { annotations, id } = options;

  const annotationItem = _find(annotations, {
    relatedItem: { id },
  });

  if (!annotationItem) {
    return {};
  }

  // Map the annotation fields to the annotations values.
  return annotationFields.reduce(
    (fields, annotation) => Object.assign(fields, { [annotation]: annotationItem[annotation] }),
    {},
  );
};

type UseAnnotationsReturn = {
  annotationData: RoutableObject;
  isOcrEnabled: boolean;
  hasAnnotations: boolean;
  isInboxBillView: boolean;
};

// This hook is used to pass information back to the front end.
// because bill view is made up of sections (including side-by-side)
// we are using a hook instead of using context
export const useAnnotations = (itemId: string): UseAnnotationsReturn => {
  const { isOcrEnabled: isEnabled, isInboxBillView } = useIsOcrEnabled();

  const billViewItem = useSelectorWithProps(itemSelector, itemId);
  const annotations = useSelector(annotationsByIdsSelector);
  const hasAnnotations = hasAnyAnnotationsToProcess(annotations, billViewItem?.primaryAttachment);

  const annotation = useMemo(() => {
    if (isEnabled && billViewItem?.primaryAttachment) {
      return {
        ...processAnnotations({
          annotations,
          ...{
            id: billViewItem?.primaryAttachment,
            kind: billViewItem?.kind,
          },
        }),
        linkedAttachment: billViewItem?.files?.find((file) => file.id === billViewItem?.primaryAttachment),
        item: billViewItem,
        ocr_filled: false,
      };
    }
    return null;
  }, [annotations, billViewItem, isEnabled]);

  return {
    annotationData: annotation,
    hasAnnotations: isEnabled && hasAnnotations,
    isOcrEnabled: isEnabled,
    isInboxBillView,
  };
};

export const areAllAnnotationAttachmentsProcessed = (
  annotationFiles: Omit<Attachment, 'file' | 'fileHash' | 'fileType'>[],
) => {
  if (!annotationFiles?.length) {
    return false;
  }

  // one of the attachment is the email text but we don't need to wait for this to be OCR-ed
  const attachmentsWithoutEmail = annotationFiles.filter((attachment) => {
    if (attachment?.filename === ATTACHMENT_DEFAULT_FILENAME || attachment?.filename === LINE_ITEM_EMAIL_DOCUMENT) {
      return false;
    }
    return true;
  });

  return attachmentsWithoutEmail.every((file?: { status?: string; latestAnnotationStatus?: string }) => {
    const fileStatus = file?.status?.toLowerCase() || file?.latestAnnotationStatus?.toLowerCase();

    const isAttachmentProcessed =
      // it's important to check for null here as lastAnotationStatus initially comes in as null
      !!fileStatus && fileStatus !== AttachmentStatuses.SCANNING && fileStatus !== AttachmentStatuses.UPLOADED;

    return isAttachmentProcessed;
  });
};

export const getInitialAnnotationLineItems = (defaultLineItems, annotationLineItems) => {
  const lineItems = { ...defaultLineItems };

  if (annotationLineItems?.length) {
    const deCamelizedAnnotationLineItems = annotationLineItems.map(deCamelCaseObjectKeys);
    const accountLineItems = deCamelizedAnnotationLineItems
      .filter((lineItem) => lineItem.style === LineItemStyles.ACCOUNT)
      .map((lineItem) => ({
        ...defaultLineItems?.[LINE_ITEMS_BLOCKS.ACCOUNT]?.[LINE_ITEMS_SUBSECTIONS.ACCOUNT]?.[0],
        ...lineItem,
      }));
    const itemLineItems = deCamelizedAnnotationLineItems
      .filter((lineItem) => lineItem.style === LineItemStyles.ITEM)
      .map((lineItem) => ({
        ...defaultLineItems?.[LINE_ITEMS_BLOCKS.ITEM]?.[LINE_ITEMS_SUBSECTIONS.ITEM]?.[0],
        ...lineItem,
      }));
    if (accountLineItems.length > 0) {
      lineItems[LINE_ITEMS_BLOCKS.ACCOUNT] = {
        ...lineItems[LINE_ITEMS_BLOCKS.ACCOUNT],
        [LINE_ITEMS_SUBSECTIONS.ACCOUNT]: accountLineItems,
      };
    }
    if (itemLineItems.length > 0) {
      lineItems[LINE_ITEMS_BLOCKS.ITEM] = {
        ...lineItems[LINE_ITEMS_BLOCKS.ITEM],
        [LINE_ITEMS_SUBSECTIONS.ITEM]: itemLineItems,
      };
    }
  }

  return lineItems;
};
