import classNames from 'classnames';
import React from 'react';

import { LinkNewPage } from 'components/link';
import { WhiteSpace } from 'components/text';

import { RoutableFeesLinkProps } from './RoutableFeesLink.types';

/**
 * Link for transaction fees
 */
const RoutableFeesLink: React.FC<RoutableFeesLinkProps> = ({ className, href, isInternationalPayment }) => (
  <>
    Learn more about
    <WhiteSpace />
    <LinkNewPage
      className={classNames({
        [className]: !!className,
      })}
      href={href}
    >
      {isInternationalPayment ? 'international payment fees' : 'transaction fees'}
    </LinkNewPage>
    .
  </>
);

RoutableFeesLink.defaultProps = {
  isInternationalPayment: undefined,
};

export default RoutableFeesLink;
