import { createSelector } from 'reselect';

import { createTransformAttachmentsToBillAttachment, getSelectedBillIndexInitalValue } from 'helpers/billAttachments';
import { getItemAttachmentsFromMap } from 'helpers/itemAttachments';

import { AttachmentWithPreview } from 'interfaces/attachment';
import { BillAttachment } from 'interfaces/billAttachment';

import { attachmentsSelector } from 'selectors/attachmentsSelectors';
import { createItemBillViewSelectedBillSelector, createItemFormItemBillsSelector } from 'selectors/forms';

import { itemFromParamSelector, itemPrimaryAttachmentFromParamValueFromUrlSelector } from './itemRouterSelectors';

/**
 * Selects a specific attachment from attachments by id
 */
export const itemAttachmentsSelector = createSelector(
  [itemFromParamSelector, attachmentsSelector],
  getItemAttachmentsFromMap,
);

/**
 * Selects the primary attachment from item
 */
export const itemPrimaryAttachmentSelector = createSelector(
  [itemAttachmentsSelector, itemFromParamSelector],
  (attachments, item) => attachments.find((attachment) => attachment.id === item?.primaryAttachment),
);

export const itemBillAttachmentsSelector = createSelector(
  [itemAttachmentsSelector],
  (attachments): AttachmentWithPreview[] =>
    attachments.map((attachment) => ({
      ...attachment,
      // This is needed for when uploading to match uploaded files
      preview: attachment.file,
    })),
);

export const billAttachmentsSelector = createSelector(
  [
    createItemFormItemBillsSelector,
    createItemBillViewSelectedBillSelector,
    itemPrimaryAttachmentFromParamValueFromUrlSelector,
  ],
  (attachments = [], selectedBillIndex, primaryAttachment): BillAttachment[] => {
    const convertIntoBillAttachment = createTransformAttachmentsToBillAttachment(primaryAttachment, selectedBillIndex);

    return attachments.map(convertIntoBillAttachment);
  },
);

export const selectedBillIndexInitialValueSelector = createSelector(
  [billAttachmentsSelector],
  getSelectedBillIndexInitalValue,
);

export const selectedBillAttachmentSelector = createSelector(
  [billAttachmentsSelector, createItemBillViewSelectedBillSelector],
  (billAttachments, selectedBillIndex): BillAttachment => billAttachments[selectedBillIndex],
);

export const hasOnlyOneBillAttachmentSelector = createSelector(
  [billAttachmentsSelector],
  (billAttachments): boolean => billAttachments.length === 1,
);
