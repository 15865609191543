import { ItemStatuses } from 'enums/items';

import {
  getItemDateExternallyPaid,
  getItemDateIssued,
  getItemDateScheduled,
  getItemDateStatusChange,
} from 'helpers/items';
import { LogLevel, systemLogger } from 'helpers/systemLogger';

import { Item } from 'interfaces/item';

import {
  getBulkUploadText,
  getOnDateText,
  getItemCompletedText,
  getDraftText,
  getExternallyPaidText,
  getInitiatedText,
  getIssueText,
  getCreatedText,
  getItemNewText,
  getItemPendingText,
  getOCRText,
  getProcessingText,
  getReadyToSendText,
  getScheduledText,
  getQueuedText,
} from './text';

/**
 * Get the subtext to display for an item of the given status.
 */
export const getItemStatusText = (item: Item, statusToConsider: ItemStatuses, isExternal = false): string => {
  const dateIssued = getItemDateIssued(item);
  const dateScheduled = getItemDateScheduled(item);
  const dateStatusChange = getItemDateStatusChange(item);
  const dateExternallyPaid = getItemDateExternallyPaid(item);

  switch (statusToConsider) {
    case ItemStatuses.BULK_UPLOAD:
      return getBulkUploadText();

    case ItemStatuses.CANCELED:
      return getOnDateText(dateStatusChange);

    case ItemStatuses.BULK_UPLOAD_COMPLETE:
    case ItemStatuses.COMPLETED:
      return getItemCompletedText(item as unknown as BulkImport, dateStatusChange);

    case ItemStatuses.CREATED:
      return getOnDateText(dateIssued);

    case ItemStatuses.DRAFT:
      return getDraftText(dateStatusChange);

    case ItemStatuses.EXTERNALLY_PAID:
      return getExternallyPaidText(dateExternallyPaid);

    case ItemStatuses.FAILED:
      return getOnDateText(dateStatusChange);

    case ItemStatuses.INITIATED:
      return getInitiatedText(item);

    case ItemStatuses.ISSUE:
      return getIssueText();

    case ItemStatuses.NEEDS_APPROVAL:
    case ItemStatuses.PO_DISCREPANCY:
      return getCreatedText(dateIssued);

    case ItemStatuses.NEW:
      return getItemNewText(item);

    case ItemStatuses.OCR:
      return getOCRText();

    case ItemStatuses.PENDING:
      return getItemPendingText(item);

    case ItemStatuses.PROCESSING:
      return getProcessingText();

    case ItemStatuses.READY_TO_SEND:
      return getReadyToSendText(isExternal);

    case ItemStatuses.SCHEDULED:
      return getScheduledText(dateScheduled, isExternal);

    case ItemStatuses.QUEUED:
      return getQueuedText(item);

    case ItemStatuses.COMPLIANCE_HOLD:
      return getOnDateText(dateStatusChange);

    // Error! We don't know what's going on...
    default:
      systemLogger.log({
        level: LogLevel.WARN,
        message: `Unknown status ${statusToConsider} passed in`,
      });
      return getOnDateText(dateStatusChange);
  }
};
