import { TruncatableText } from '@routable/components';
import clsx from 'clsx';
import React from 'react';

import Icon from 'components/icon';

import TableCellSubtext from '../TableCellSubtext';
import TableCellText from '../TableCellText';

import type { TableCellTextWithSubtextProps } from './TableCellTextWithSubtext.types';

/**
 * Renders text and optional subtext for a table cell.
 */
const TableCellTextWithSubtext = ({
  className,
  subtext,
  subtextClassName,
  subtextIconProps,
  text,
  textClassName,
  textIconProps,
  dataFullStory,
}: TableCellTextWithSubtextProps) => {
  return (
    <div className={clsx('overflow-hidden', 'w-full', className)} data-fullstory={dataFullStory}>
      {!!text && (
        <TableCellText className={textClassName}>
          {textIconProps?.icon && <Icon className="margin-right--sm" {...textIconProps} />}
          {text}
        </TableCellText>
      )}

      {!!subtext && (
        <TableCellSubtext className="margin-top--xs">
          {subtextIconProps?.icon && <Icon className="margin-right--sm" {...subtextIconProps} />}
          <TruncatableText className={subtextClassName} maxNumberOfLines={1} position="top" text={subtext} />
        </TableCellSubtext>
      )}
    </div>
  );
};

export default TableCellTextWithSubtext;
