export enum TrackPageName {
  DASHBOARD_PAYABLES = 'Dashboard Payables',
  SETTINGS = 'Settings',
  BILL = 'Bill',
  PAYABLE = 'Payable',
  RECEIVABLE = 'Receivable',
}

export enum TrackEventName {
  QUICKSWITCH_MODAL_OPENED = 'Quick Switch: Modal Opened',
  QUICKSWITCH_MODAL_CLOSED = 'Quick Switch: Modal Closed',
  CSV_EXPORT_CLICKED = 'CSV Export: Button Click',
  CSV_EXPORT_CANCELLED = 'CSV Export: Cancelled',
  SSO_ENABLED = 'SSO: Google Turned On',
  SSO_DISABLED = 'SSO: Google Turned Off',
  SSO_RE_ENABLED = 'SSO: Google Turned Back On',
  CREATE_COMPANY_NO_REACHABLE_VENDOR = 'Companies: No reachable contact for vendor',

  CODING_TIME_PAGE_LOADED = 'Coding Time: Page loaded',
  CODING_TIME_FORM_SUBMITTED = 'Coding Time: Form submitted',
  CODING_TIME_OCR_FIELD_CHANGED = 'Coding Time: OCR field changed',

  BULK_ACTION_SUBMITTED = 'Bulk Action: Success',

  RESYNC_EVENT = 'Re-Sync',
  IMPORT_ITEMS_FROM_LEDGER_TOGGLE_MATCHED_PARTNERSHIPS_CLICKED = 'Import items from ledger: matched partnerships toggle clicked',

  FILE_VIEWER_FAILED_LOADING_PDF = 'File Viewer: Failed Loading PDF',
  FILE_VIEWER_NO_URL_PROVIDED = 'File Viewer: No URL Provided',

  FINANCIAL_TAX_MANAGEMENT_VIEWED = 'Financial: Tax management Page Viewed',

  PAYABLE_APPROVALS_VIEWED = 'Payable Approvals: Policy Page Viewed',
  PAYABLE_APPROVALS_CREATE_NEW_RULE = 'Payable Approvals: Create Rule Clicked',
  PAYABLE_APPROVALS_EDIT_RULE = 'Payable Approvals: Edit Rule Clicked',
  PAYABLE_APPROVALS_DELETE_RULE = 'Payable Approvals: Delete Rule Clicked',
  PAYABLE_APPROVALS_RULE_MOVED = 'Payable Approvals: Moved Rule',
  PAYABLE_APPROVALS_SAVE_ERROR = 'Payable Approvals: Save Error Notification Viewed',
  PAYABLE_APPROVALS_EXIT_WITHOUT_SAVING = 'Payable Approvals: Exit without Saving Confirmation Clicked',

  PAYABLE_APPROVALS_SETTINGS_VIEWED = 'Payable Approvals: Settings Page Viewed',
  PAYABLE_APPROVALS_SETTINGS_EDITED = 'Payable Approvals: Settings Edited',

  PAYABLE_EDIT_CANCELLED = 'Payable: Payable Edit Cancelled',

  PAYABLE_MANUAL_APPROVER_CLICKED = 'Payable: Manually add approver clicked',

  REFRESH_PO_MATCHING = 'Payable: Refresh PO matching',

  TAX_TOOLS_TABLE_FILTER_ON = 'Tax tools table: Filter on',
  TAX_TOOLS_TABLE_FILTER_OFF = 'Tax tools table: Filter off',

  VENDORS_TABLE_FILTER_ON = 'Vendors table: Filter on',
  VENDORS_TABLE_FILTER_OFF = 'Vendors table: Filter off',
}
