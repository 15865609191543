import { RoutableEventsSync, useEventSync } from '@routable/framework';
import React, { useEffect } from 'react';

export const useTabs = (defaultTabId: string | number, tabGroupId: string) => {
  const [activeTabId, setActiveTabId] = React.useState(defaultTabId);

  const getIsActive = (tabId: string | number) => activeTabId === tabId;

  const setActiveTabIdHandler = (tabId: string | number) => {
    if (getIsActive(tabId)) {
      return;
    }

    RoutableEventsSync.Publish(`tabs:${tabGroupId}:onUpdate`, tabId);
    setActiveTabId(tabId);
  };

  useEventSync(`tabs:${tabGroupId}:update`, setActiveTabIdHandler);

  const getActiveTabSetter = (tabId: string | number) => {
    return () => setActiveTabIdHandler(tabId);
  };

  const getTabState = (tabId: string | number) => {
    const isActive = getIsActive(tabId);
    return {
      isActive,
      'data-active': isActive,
      onClick: getActiveTabSetter(tabId),
    };
  };

  useEffect(() => {
    RoutableEventsSync.Publish(`tabs:${tabGroupId}:onUpdate`, defaultTabId);
  }, [defaultTabId, tabGroupId]);

  return {
    activeTabId,
    getTabState,
    setActiveTabId: setActiveTabIdHandler,
  };
};
