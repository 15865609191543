/* eslint-disable */
// ^^^ We disable lint here because we are doing some stuff that requires outside of the lint rules type logic (any etc...).

import * as Sentry from '@sentry/react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { paymentsEndpoint } from 'services/api/paymentsEndpoint';

import {
  CardContainer,
  Container,
  ErrorContainer,
  SentryErrorDescription,
  SentryErrorId,
  SentryErrorTitle,
} from './sentry.styled';

// Just a custom error class for sending to sentry using Sentry.captureException
class CustomRoutableError extends Error {
  date: Date;
  code: string;
  extraData: string;

  constructor(
    name: string,
    message: string,
    errorCode: string,
    extraData: any,
    includeErrorStack: boolean,
    ...params: any
  ) {
    super(...params);

    // Try and capture the error stack if available not all browsers support the stackTrace method.
    if (Error.captureStackTrace && includeErrorStack) {
      Error.captureStackTrace(this, CustomRoutableError);
    }

    // Custom Error Props
    this.name = name;
    this.message = message;
    this.code = errorCode;
    this.date = new Date();
    this.cause = 'Sentry Test Error';
    this.extraData = extraData;
  }
}

// Basic interface for printing out error test.
interface SentryErrorProperties {
  title: string;
  description: string;
  errorCode: string;
}

// The returned SentryError component
const SentryError: React.FC<SentryErrorProperties> = ({
  title,
  description,
  errorCode,
}) => (
  <>
    <SentryErrorTitle>{title}</SentryErrorTitle>
    <SentryErrorDescription>{description}</SentryErrorDescription>
    <SentryErrorId>Error Code: {errorCode}</SentryErrorId>
  </>
);

// Main export this is the entry that the route takes.
export const SentryErrorPage: React.FC = () => {
  // Generate a new ID
  const errorId = useRef(crypto.randomUUID());

  // Keep the state component so in the useEffect we can do additional logic before it renders to screen.
  const [SentryErrorComponent, setSentryErrorComponent] =
    useState<React.ReactNode>(
      <SentryError
        description="Sentry has sent a generic error to the server."
        errorCode={errorId.current}
        title="Generic Error"
      />,
    );

  const pageParams: AbstractObject = useParams();

  // We use useEffect here so we can "run once" any type of custom logic we want to capture
  // for testing purposes.

  useEffect(() => {
    if (pageParams?.type) {
      // anything after the url sentryerror (ie: sentryerror/401) will be capture and can respond to logic
      switch (pageParams.type) {
        // BEGIN CUSTOM ERROR HANDLERS

        case '401': // Do a fetch "without token" this will result in a 401 result from the BE server.
          fetch(paymentsEndpoint).then((resData: AbstractObject) => {
            const errorDescription = `Not authorized ${resData.status} for url ${resData.url}`;

            // Set the component state
            setSentryErrorComponent(
              <SentryError
                description={errorDescription}
                errorCode={errorId.current}
                title="401 Not Authorized Error Test"
              />,
            );

            // Send the captured event to sentry using the captureException event.
            // you could also use console.error here and sentry would capture it as well.
            // Props for CustomRoutableError
            // name: string = The name of the error.
            // message: string = The body content message of the error.
            // errorCode: string = The error code on the error to track in sentry.
            // extraData: any = Any kind of extra data that you want to add to the sentry exception.
            // includeErrorStack: boolean = Do you want to include the stackTrace which will append the current location file etc.
            Sentry.captureException(
              new CustomRoutableError(
                'SENTRYTEST:401',
                errorDescription,
                errorId.current,
                resData,
                false,
              ),
            );
          });
          break;

        // END CUSTOM ERROR HANDLERS
        default:
          setSentryErrorComponent(
            <SentryError
              description="Sentry has sent a generic error to the server."
              errorCode={errorId.current}
              title="Generic Error"
            />,
          );

          Sentry.captureException(
            new CustomRoutableError(
              'SENTRYTEST:GENERIC',
              'Generic Sentry Error',
              errorId.current,
              {},
              false,
            ),
          );
      }
    } else {
      Sentry.captureException(
        new CustomRoutableError(
          'SENTRYTEST:GENERIC',
          'Generic Sentry Error',
          errorId.current,
          {},
          false,
        ),
      );
    }
  }, [pageParams]);

  return (
    <Container>
      <CardContainer className="max-w=[560px] shadow">
        <ErrorContainer>{SentryErrorComponent}</ErrorContainer>
      </CardContainer>
    </Container>
  );
};
