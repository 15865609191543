import { isEqual } from 'helpers/utility';

import { PaymentMethodStatusType } from 'modules/fundingAccount/PaymentMethod/enums';

/**
 * Determines wether to hide all action buttons
 */
export const shouldHideActionButtons = (status: PaymentMethodStatusType): boolean =>
  isEqual(PaymentMethodStatusType.DISABLED, status);

/**
 * Determines wether to disable update action button
 */
export const shouldDisableActionButtonUpdate = (status: PaymentMethodStatusType, isSubmitting?: boolean): boolean => {
  const shouldDisableStatusMap = {
    [PaymentMethodStatusType.ERROR]: true,
    [PaymentMethodStatusType.PENDING_MICRO_DEPOSITS]: true,
    [PaymentMethodStatusType.PENDING_VERIFICATION]: true,
  };

  return isSubmitting || Boolean(shouldDisableStatusMap[status]);
};

/**
 * Determines wether to disable primary action button
 */
export const shouldDisableActionButtonPrimary = (status: PaymentMethodStatusType, isSubmitting?: boolean): boolean => {
  const shouldDisableStatusMap = {
    [PaymentMethodStatusType.ERROR]: true,
    [PaymentMethodStatusType.PENDING_MICRO_DEPOSITS]: true,
    [PaymentMethodStatusType.PENDING_VERIFICATION]: true,
    [PaymentMethodStatusType.PRIMARY]: true,
  };

  return isSubmitting || Boolean(shouldDisableStatusMap[status]);
};
