import React from 'react';

import { Icon } from 'components/icon';
import FlexRow from 'components/layout/FlexRow';
import Text from 'components/text/Text';

import { colors, typography } from 'constants/styles';

import { getClassNames } from 'helpers/ui';

import { SubtitleWithIconProps } from './SubtitleWithIcon.types';

const textProps = {
  ...typography.TitleWithSubtitle.textProps,
  color: colors.colorDarkJordanHex,
};

/**
 * Helper component to compose a TitleWithSubtitle. This component can have its own icon and text.
 * @see Storybook > TitleWithSubtitle > SubtitleWithIcon
 */
const SubtitleWithIcon = ({
  icon,
  iconProps = {},
  text,
  IconComponent = Icon,
  ...rest
}: SubtitleWithIconProps): JSX.Element => (
  <FlexRow className={getClassNames(rest, { 'align-items--center': true })} stackOnMobile={false}>
    {icon && <IconComponent icon={icon} {...typography.TitleWithSubtitle.iconProps} {...iconProps} />}

    <Text.Regular {...textProps} {...rest} className={getClassNames(rest, {}, { classProp: 'textClassName' })}>
      {text}
    </Text.Regular>
  </FlexRow>
);

export default SubtitleWithIcon;
