import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { AsyncSelect } from 'components';

import * as propTypes from 'constants/propTypes';

import { returnFullOptionObjectAsValueForSelects } from 'helpers/selects';

import { useMemoizedConditionalValidate } from 'hooks';

import { useDynamicFieldRequiredValidators } from 'modules/table/hooks';

/**
 * A field allowing users to input a search value to find a set of options from the server, to be rendered in a block.
 * @param {ComponentProps} props
 * @param {object} props.formValues
 * @param {function} props.getIsDisabled
 * @param {string} props.lookupOptionLabelKey
 * @param {string} props.lookupOptionValueKey
 * @param {function} props.onLoadOptions
 * @returns {StatelessComponent}
 */
const ApiLookupBlockField = (props) => {
  const {
    formValues,
    getIsDisabled,
    lookupOptionLabelKey,
    lookupOptionValueKey,
    label,
    name,
    onLoadOptions,
    defaultOptions,
    placeholder,
  } = props;

  const { isRequired, validators } = useDynamicFieldRequiredValidators(props);
  const cachedValidators = useMemoizedConditionalValidate(!formValues.ui?.blockDetailsSections, validators);

  return (
    <Field
      className="api-lookup-block-select"
      component={AsyncSelect}
      defaultOptions={defaultOptions}
      isClearable
      isCreatable={false}
      isDisabled={getIsDisabled(formValues)}
      isRequired={isRequired}
      label={label}
      labelKey={lookupOptionLabelKey}
      loadOptions={onLoadOptions}
      matchProp={lookupOptionLabelKey}
      name={name}
      noResultsText="No matches found"
      onBlurResetsInput={false}
      onCloseResetsInput={false}
      onSelectResetsInput={false}
      parseValue={returnFullOptionObjectAsValueForSelects}
      placeholder={`Select ${placeholder.toLowerCase()}`}
      validate={cachedValidators}
      valueKey={lookupOptionValueKey}
    />
  );
};

ApiLookupBlockField.propTypes = {
  ...propTypes.baseFieldSharedPropTypes,
  lookupOptionLabelKey: PropTypes.string.isRequired,
  lookupOptionValueKey: PropTypes.string.isRequired,
  onLoadOptions: PropTypes.func.isRequired,
  defaultOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

ApiLookupBlockField.defaultProps = {
  ...propTypes.baseFieldSharedDefaultProps,
  defaultOptions: [],
};

export default ApiLookupBlockField;
