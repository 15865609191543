import React from 'react';

import { DataFedSummarizeTransfer } from 'modules/dashboard/createItems/createItemForm/components/CreateItemForm/components/DataFedSummarizeTransfer';

import { SectionHeader } from './SectionHeaders.styled';

export const BillSectionHeaderComponent = ({ isOnDetailsPage }) => (
  <SectionHeader isOnDetailsPage={isOnDetailsPage}>
    <div className="flex flex-row justify-between items-center text-xs h-full pl-4 pr-2">
      <div className="font-semibold">Bill lines</div>
      <div>
        <DataFedSummarizeTransfer />
      </div>
    </div>
  </SectionHeader>
);
