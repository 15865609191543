import { connect } from 'react-redux';

import { reLogin } from 'actions/quickswitchActions';

import QuickswitchErrorModalContent, { QuickswitchErrorModalContentProps } from './QuickswitchErrorModalContent';

export const mapDispatchToProps: Pick<QuickswitchErrorModalContentProps, 'onLoginClick'> = {
  onLoginClick: reLogin,
};

export default connect(null, mapDispatchToProps)(QuickswitchErrorModalContent);
