import debounce from 'lodash/debounce';
import { useState, useCallback } from 'react';

/**
 * @description Works like useState, but debounces the value/render
 */
export const useDebouncedState = <T>(value: T | (() => T), delay = 200): ReturnType<typeof useState<T>> => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const setValDebounced = useCallback(debounce(setDebouncedValue, delay), [delay]);
  return [debouncedValue, setValDebounced];
};
