import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Radio } from 'components';

import { FieldRadioGroup } from './RenderRadioGroup.styles';
import type { RenderRadioGroupProps } from './RenderRadioGroup.types';

const RenderRadioGroup = ({ isHorizontal = true, name, options }: RenderRadioGroupProps): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FieldRadioGroup>
          {options.map((option) => (
            <Radio
              id={`${field.name}_${option.value}`}
              input={{
                ...field,
                value: option.value,
                checked: field.value === option.value,
              }}
              isHorizontalLayout={isHorizontal}
              key={option.value}
              optionText={option.optionText}
              subText={option.subText}
              type="radio"
            />
          ))}
        </FieldRadioGroup>
      )}
    />
  );
};

export default RenderRadioGroup;
