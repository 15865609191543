import React from 'react';

import { IconNames, Text, TooltipMUIButton } from 'components';

import { colors, sizes, typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import { Intent } from 'constants/ui';

import { useSideNavigationV2 } from 'hooks/useSideNavigationV2';

import { QuickswitchActionCreator } from 'types/quickswitch';

import { MINIMUM_COUNT_FOR_SEARCHBAR } from './constants';
import QuickswitchErrorModal from './QuickswitchErrorModal';
import QuickswitchModal, { QuickswitchModalStateProps, QuickswitchModalDispatchProps } from './QuickswitchModal';

export interface QuickswitchDispatchProps extends QuickswitchModalDispatchProps {
  openModal: () => QuickswitchActionCreator;
  fetchMembershipList: () => QuickswitchActionCreator;
}

export interface QuickswitchStateProps extends QuickswitchModalStateProps {
  totalNumberOfMemberships?: number;
  isErrorModalOpen?: boolean;
}

export type QuickswitchProps = QuickswitchDispatchProps & QuickswitchStateProps & { children?: React.ReactNode };

const Quickswitch: React.FC<QuickswitchProps> = (props) => {
  const {
    children,
    totalNumberOfMemberships = 0,
    fetchMembershipList,
    openModal,
    isErrorModalOpen = false,
    ...modalProps
  } = props;
  const handleClick = () => {
    fetchMembershipList();
    openModal();
  };

  const hasSideNavigationV2 = useSideNavigationV2();

  if (totalNumberOfMemberships <= 1) {
    return null;
  }

  return (
    <React.Fragment>
      {hasSideNavigationV2 ? (
        <>
          <button className="justify-between quickswitch-button w-full" onClick={handleClick} type="button">
            {children}
          </button>
        </>
      ) : (
        <div className="margin-left--auto">
          <TooltipMUIButton
            intent={Intent.LIGHT}
            isIconButton
            leftIconColor={colors.colorWhiteHex}
            leftIconName={IconNames.SWAP_HORIZONTAL}
            leftIconSize={sizes.iconSizes.LARGE}
            onClick={handleClick}
            title={
              <Text.Regular color={typography.TextColor.GREY_X_DARK} size={typography.TextSize.LEVEL_100}>
                Switch between your Routable accounts
              </Text.Regular>
            }
            tooltipProps={{
              arrow: true,
              padding: TooltipPadding.SKINNY,
              position: TooltipPlacement.BOTTOM,
            }}
          />
        </div>
      )}

      <QuickswitchModal showSearchBar={totalNumberOfMemberships > MINIMUM_COUNT_FOR_SEARCHBAR} {...modalProps} />
      <QuickswitchErrorModal isModalOpen={isErrorModalOpen} />
    </React.Fragment>
  );
};

export default Quickswitch;
