import classNames from 'classnames';
import React from 'react';

import { Text } from 'components/text';

import { typography } from 'constants/styles';
import { TaxDocType } from 'constants/taxes';
import { Intent } from 'constants/ui';

import { isLastIndex } from 'helpers/utility';

import BaseHint from '../BaseHint';

import { penaltiesOfPerjurySections } from './PenaltiesOfPerjuryHint.data';

type Props = {
  taxDoc: typeof TaxDocType.W8BEN | typeof TaxDocType.W9;
};

export const Title = 'Under Penalties of perjury, I certify that:';

/**
 * Component rendering the "Penalties of Perjury" hint. Closely
 * tied to the tax-collection management
 */
const PenaltiesOfPerjuryHint = ({ taxDoc }: Props): JSX.Element => {
  const perjurySections = penaltiesOfPerjurySections[taxDoc];

  if (!perjurySections) {
    return null;
  }

  return (
    <BaseHint
      content={perjurySections.map(({ id, text }, idx) => (
        <Text.Regular
          className={classNames('display--inline-block', {
            'margin-bottom--m': !isLastIndex(perjurySections, idx),
          })}
          color={typography.TextColor.BLUE_DARK}
          key={id}
        >
          {text}
        </Text.Regular>
      ))}
      contentProps={{ className: 'margin-top--sm' }}
      intent={Intent.INFO}
      isCollapsed
      isCollapsible
      title={Title}
    />
  );
};

export default PenaltiesOfPerjuryHint;
