import React from 'react';

import { Hint } from 'components/hint';
import { Text } from 'components/text';

import { Intent } from 'constants/ui';

import { isCompanyTypeBusiness } from 'helpers/currentCompany';
import { listAllElementsInArray } from 'helpers/stringHelpers';

import type { LegalNameChangeConfirmationHintProps } from './LegalNameChangeConfirmationHint.types';

import './LegalNameChangeConfirmationHint.scss';

// Items with these statuses will be affected/updated any time the legal name
// for the item's associated partnership is changed.
const affectedStatuses = ['Pending', 'Ready to send', 'Scheduled', 'Needs approval'];

/**
 * Returns hint notifying the user of how changing the legal name of the partner will
 * affect certain items already created.
 */
const LegalNameChangeConfirmationHint: React.FC<LegalNameChangeConfirmationHintProps> = ({
  companyType,
  shouldShowTaxAutoArchiveHint,
  ...rest
}) => {
  const isBusiness = isCompanyTypeBusiness(companyType);

  const businessOrPersonalName = isBusiness ? 'name' : 'first and last name';
  const hintTitle = isBusiness
    ? 'Make sure this legal name is correct'
    : 'Make sure your legal first and last name are correct';
  const taxAutoArchiveText = `If you make any edits to the legal entity ${businessOrPersonalName}, you'll need to request new tax information. Existing tax information will be archived.`;
  const hintBody = `We'll update the legal ${businessOrPersonalName} on all payables and receivables with the following statuses: ${listAllElementsInArray(
    affectedStatuses,
  )}.`;

  return (
    <Hint intent={Intent.WARNING} {...rest}>
      <Text.Bold className="font-color--yellow-dark--important">{hintTitle}</Text.Bold>

      {shouldShowTaxAutoArchiveHint && <p className="hint--content">{taxAutoArchiveText}</p>}

      <p className="hint--content remove-margin-bottom">{hintBody}</p>
    </Hint>
  );
};

export default LegalNameChangeConfirmationHint;
