import React from 'react';

import DashboardToDashboardRestrictionTooltipContent from 'components/dashboardToDashboardRestrictionTooltipContent';
import { Text } from 'components/text';

import {
  getPartnershipMemberAccessActionText,
  getPartnershipMemberAccessScopeText,
  getPartnershipMemberAccessText,
  isPartnershipMemberAccessSelfManaged,
} from 'helpers/partnershipMembers';

import { ContactSettingsTooltipTextProps } from './ContactSettingsTooltipText.types';

/**
 * Content for a tooltip explaining the different contact access levels (e.g. contact, read-only) for different
 * scopes (e.g. items, company).
 */
const ContactSettingsTooltipText = ({ access, scope }: ContactSettingsTooltipTextProps): JSX.Element => {
  if (isPartnershipMemberAccessSelfManaged(access)) {
    return <DashboardToDashboardRestrictionTooltipContent />;
  }

  return (
    <>
      <Text.Bold>{getPartnershipMemberAccessScopeText(scope)}</Text.Bold>
      <br />
      <Text.Regular>
        {getPartnershipMemberAccessText(access)}
        {getPartnershipMemberAccessActionText(access)}
      </Text.Regular>
    </>
  );
};

export default ContactSettingsTooltipText;
