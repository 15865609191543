import React from 'react';

import { Icon } from 'components/icon';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';

import { colors } from 'constants/styles';

import type { PaymentMethodDetailItemActionProps } from './PaymentMethodDetailItemAction.types';

/**
 * Renders icon with click listener for PaymentMethodDetail actions
 */
const PaymentMethodDetailItemAction = React.forwardRef<HTMLSpanElement, PaymentMethodDetailItemActionProps>(
  ({ icon, onClick, tooltipTitle }, ref) => (
    <span className="payment_method__detail-item-action-icon" onClick={onClick} ref={ref} role="presentation">
      <TooltipMUIConditionalWrapper tooltipProps={{ title: tooltipTitle }}>
        <Icon color={colors.colorGreyDarkHex} icon={icon} />
      </TooltipMUIConditionalWrapper>
    </span>
  ),
);

export default PaymentMethodDetailItemAction;
