import React from 'react';

import { Icon, IconNames, TooltipMUIConditionalWrapper } from 'components';

import { colors, sizes } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';

import type { DataCardTooltipConditionalProps } from './DataCardTooltipConditional.types';

/**
 * Conditionaly renders icon and tooltip
 */
const DataCardTooltipConditional = ({ icon, iconProps, tooltipContent }: DataCardTooltipConditionalProps) => {
  const iconName = tooltipContent ? icon || IconNames.INFO_SIGN : icon;
  const tooltipProps = tooltipContent
    ? {
        title: tooltipContent,
        arrow: true,
        padding: TooltipPadding.SKINNY,
      }
    : undefined;

  return (
    <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
      <Icon color={colors.colorGreyDarkHex} icon={iconName} size={sizes.iconSizes.XX_LARGE} {...iconProps} />
    </TooltipMUIConditionalWrapper>
  );
};

export default DataCardTooltipConditional;
