import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import PermissionResourceAction from 'constants/permissions';

import { withIsDisabledWhenMissingPermissions } from 'hoc';

import BulkActionBalanceTooLowHint from './BulkActionBalanceTooLowHint';

export const requiredPermissions = [PermissionResourceAction.FUNDING_ACCOUNTS_BALANCE];

const enhance = compose(
  withRouter,
  withIsDisabledWhenMissingPermissions(requiredPermissions, 'isDisabledDueToPermissions'),
);

export default enhance(BulkActionBalanceTooLowHint);
