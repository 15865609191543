import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import swal from 'sweetalert';

import { RestrictedFeatureModal } from 'complexComponents/modals';

import { DASHBOARD } from 'constants/routes';

import { redirectToTeamMembersPage } from 'helpers/navigation';
import { showSwal } from 'helpers/swal';

/**
 * Simple component which redirects to /dashboard/ and ensures the RestrictedFeatureModal is shown
 * @param {ComponentProps} props
 * @param {object} props.history
 * @return {StatelessComponent}
 */
const RestrictedPage = ({ history }) => {
  const onNavigate = React.useCallback(() => {
    redirectToTeamMembersPage(history);
  }, [history]);

  React.useEffect(() => {
    showSwal({
      Content: <RestrictedFeatureModal onConfirm={() => swal.close()} onNavigate={onNavigate} />,
    });
  }, [onNavigate]);

  return <Redirect to={`/${DASHBOARD}/`} />;
};

RestrictedPage.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default withRouter(RestrictedPage);
