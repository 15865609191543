import clsx from 'clsx';
import React from 'react';

import { DateCell, ShowAmountCell, TableCellText } from 'complexComponents';

import { ButtonV2 } from 'components';

import { ButtonSize } from 'constants/button';
import { ItemKinds } from 'constants/item';
import { DETAILS_COLUMN_ACCESSOR_ID } from 'constants/tables';
import { Intent } from 'constants/ui';

import { ItemAmountKey } from 'enums/items';

import { getItemPaymentTerms } from 'helpers/items';
import { getOriginal } from 'helpers/tables';
import { isValueEmpty } from 'helpers/utility';

import type { Item } from 'interfaces/item';
import type { TableConfigColumn } from 'interfaces/table.types';

export const DecoratedCell: React.FC<{ value?: string }> = ({ value }) => (
  <TableCellText className={clsx({ 'font-color--grey--dark': !value })}>{value || 'Not provided'}</TableCellText>
);

export type RowType = {
  row: { original: Item | LineItem };
};

export const ShowAmountOrDefaultCell: React.FC<RowType> = ({ row }) =>
  !isValueEmpty(row.original.amount) ? (
    // @ts-expect-error rowData types
    <ShowAmountCell dataKey={ItemAmountKey.TOTAL} rowData={{ ...getOriginal({ row }) }} />
  ) : (
    <DecoratedCell value="--" />
  );

interface ItemsPropType {
  row: { original: Item };
}

const PayablesTableTableConfig: TableConfigColumn[] = [
  {
    accessor: 'reference',
    Header: 'Invoice #',
    Cell: ({ row }: ItemsPropType): React.ReactElement => <DecoratedCell value={row.original.reference} />,
    width: 1,
  },
  {
    accessor: 'partnerReference',
    Header: 'Reference',
    Cell: ({ row }: ItemsPropType): React.ReactElement => <DecoratedCell value={row.original.partnerReference} />,
    width: 3,
  },
  {
    accessor: 'paymentTerms',
    Header: 'Payment terms',
    Cell: ({ value }) => <TableCellText>{getItemPaymentTerms(value)}</TableCellText>,
    width: 1,
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    Cell: ShowAmountOrDefaultCell,
    width: 1,
  },
];

const ReceivablesTableConfig: TableConfigColumn[] = [
  {
    accessor: 'reference',
    Header: 'Invoice #',
    Cell: ({ row }: ItemsPropType): React.ReactElement => <DecoratedCell value={row.original.reference} />,
    width: 1,
  },
  {
    accessor: 'partnerReference',
    Header: 'Reference',
    Cell: ({ row }: ItemsPropType): React.ReactElement => <DecoratedCell value={row.original.partnerReference} />,
    width: 3,
  },
  {
    accessor: 'dateDue',
    Header: 'Due date',
    Cell: DateCell,
    width: 1,
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    Cell: ShowAmountOrDefaultCell,
    width: 1,
  },
];

interface InvoicesTableConfigOptions {
  itemKind: ItemKinds;
  showDetailsColumn?: boolean;
  onClick?: (item: Item) => void;
}

export const InvoicesTableConfig = ({
  itemKind,
  showDetailsColumn = false,
  onClick,
}: InvoicesTableConfigOptions): TableConfigColumn[] => {
  const config = itemKind === ItemKinds.PAYABLE ? [...PayablesTableTableConfig] : [...ReceivablesTableConfig];
  if (showDetailsColumn) {
    config.push({
      accessor: DETAILS_COLUMN_ACCESSOR_ID,
      Header: 'Details',
      Cell: (props) => (
        <TableCellText>
          <ButtonV2 intent={Intent.NEUTRAL} onClick={() => onClick(getOriginal(props))} size={ButtonSize.SMALL}>
            View
          </ButtonV2>
        </TableCellText>
      ),
      width: 2,
    });
  }
  return config;
};

interface LineItemsPropsType {
  row: { original: LineItem };
}

export const LineItemsItemsTableConfig: TableConfigColumn[] = [
  {
    accessor: 'description',
    Header: 'Description',
    Cell: ({ row }: LineItemsPropsType): React.ReactElement => <DecoratedCell value={row.original.description} />,
    width: 5,
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    Cell: ShowAmountOrDefaultCell,
    width: 1,
  },
];

export const LineItemsExpensesTableConfig: TableConfigColumn[] = LineItemsItemsTableConfig;
