import React from 'react';

import { Hint, Text, WhiteSpace } from 'components';

import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';

import type { CustomerSetUpToPayWithACHHintProps } from './CustomerSetUpToPayWithACHHint.types';

/**
 * Component rendering success hint for notifying that the Customer is set-up to pay
 * with ACH
 * @param props
 */
const CustomerSetUpToPayWithACHHint: React.FC<CustomerSetUpToPayWithACHHintProps> = ({ partnership }) => (
  <Hint className="customer-set-up-to-pay-with-ach-hint" intent={Intent.SUCCESS}>
    <Text.Bold color={typography.TextColor.GREEN_DARK}>
      {partnership.name}
      <WhiteSpace />
      is set up to pay with ACH
    </Text.Bold>
  </Hint>
);

export default CustomerSetUpToPayWithACHHint;
