import { useQuery } from '@tanstack/react-query';
import { queryContext } from 'milton/components';

import { countriesApi } from './Countries.endpoint';
import type { Countries, CountriesAsOptions } from './Countries.models';

const defaultResult = { countries: [], countriesAsOptions: [] };

export const useCountries = (): {
  countries: Countries;
  countriesAsOptions: CountriesAsOptions;
} => {
  const result = useQuery({
    queryKey: ['countries', 'all'],
    context: queryContext,
    queryFn: () => countriesApi.getCountries(),
    select: ({ data }) => ({
      countries: data,
      countriesAsOptions: data.map(({ attributes: { fullName, countryCode } }) => ({
        id: countryCode,
        value: countryCode,
        label: fullName,
      })),
    }),
  });

  return result?.data || defaultResult;
};
