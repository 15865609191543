import { taxFormType, type TaxFormType } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';

import { TaxDocType } from 'constants/taxes';

export const getTaxFormName = (formType: TaxFormType, isIndividual: boolean): string => {
  if (formType === taxFormType.Enum.W9) {
    return 'W-9';
  }

  return isIndividual ? 'W-8BEN' : 'W-8BEN-E';
};

export const isEligibleForCertificationTypeSelect = (
  formType: TaxFormType,
  isIndividual: boolean,
  isSignatureAllowed: boolean,
): boolean => {
  if (!isSignatureAllowed) {
    return false;
  }

  if (formType === taxFormType.Enum.W9) {
    return true;
  }

  return isIndividual;
};

export const translateFormTypeToTaxDoc = (formType: TaxFormType): typeof TaxDocType.W8BEN | typeof TaxDocType.W9 =>
  formType === taxFormType.Enum.W9 ? TaxDocType.W9 : TaxDocType.W8BEN;
