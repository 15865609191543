import { type InternationalBankField } from '@routable/fund-routes';
import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';

import { type UnknownObject } from 'interfaces/global';
import { type Partnership } from 'interfaces/partnerships';

import {
  CREATE_INTERNATIONAL_BANK_ACCOUNT,
  CREATE_INTERNATIONAL_FUNDING_ACCOUNT,
  FETCH_SUPPORTED_COUNTRIES,
} from 'types/funding';

/**
 * Routine which is used to submit an international funding account on the external flow.
 * @see {createInternationalFundingAccountHandler}
 */
export const createInternationalFundingAccountRoutine = createRoutine(CREATE_INTERNATIONAL_FUNDING_ACCOUNT);

/**
 * Bound routine which we use to submit the form for manually adding an international bank account on the external flow.
 */
export const createInternationalFundingAccountHandler = bindRoutineToReduxForm(
  createInternationalFundingAccountRoutine,
);

/**
 * Routine which is used to submit an international bank account in the dashboard
 */
export const createInternationalBankAccountRoutine = createRoutine(CREATE_INTERNATIONAL_BANK_ACCOUNT);

/**
 * Bound routine which we use to submit the form for adding an interntional bank account in the dashbaord
 */
export const createInternationalBankAccountHandler = bindRoutineToReduxForm<
  UnknownObject,
  { partnership: Partnership; requiredFields: InternationalBankField[] }
>(createInternationalBankAccountRoutine);

/**
 * Routine to fetch supported countries.
 */
export const fetchSupportedCountriesRoutine = createRoutine(FETCH_SUPPORTED_COUNTRIES);
