import PropTypes from 'prop-types';
import React from 'react';

// circular import issues
import { Text, WhiteSpace } from 'components/text';
import TextLinkNewInternalPage from 'components/TextLinkNewInternalPage';

import { TextSize } from 'constants/styles/typography';

import { ternary } from 'helpers/utility';

/**
 * Content for the Restricted tooltip & SWAL if the RCTM does not have adequate permissions to take an action.
 * @param {ComponentProps} props
 * @param {boolean} props.isTooltip
 * @param {function} props.onNavigate
 * @returns {StatelessComponent}
 */
const RestrictedPermissionContent = ({ isTooltip, onNavigate }) => {
  const textSize = ternary(isTooltip, TextSize.LEVEL_100, TextSize.LEVEL_200);
  const actionContext = ternary(isTooltip, 'take this action', 'view this page');

  return (
    <>
      <Text.Regular size={textSize}>
        You do not have permission to
        <WhiteSpace />
        {actionContext}. Review
      </Text.Regular>
      <WhiteSpace />
      <TextLinkNewInternalPage onClick={onNavigate} size={textSize}>
        your permissions
      </TextLinkNewInternalPage>
      <WhiteSpace />
      <Text.Regular size={textSize}>or contact a company administrator</Text.Regular>
      <WhiteSpace />
      <Text.Regular size={textSize}>for access.</Text.Regular>
    </>
  );
};

RestrictedPermissionContent.propTypes = {
  isTooltip: PropTypes.bool,
  onNavigate: PropTypes.func.isRequired,
};

RestrictedPermissionContent.defaultProps = {
  isTooltip: true,
};

export default RestrictedPermissionContent;
