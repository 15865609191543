import { routableApi, RoutableTags } from './routableApi';
import type { RoutesQueryParams, RoutesResponse } from './routesEndpoints.types';

const extendedRoutesApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getRoute: builder.query<RoutesResponse, RoutesQueryParams>({
      query: (queryParams) => ({
        url: '/routes/',
        method: 'GET',
        params: queryParams,
        transformers: [],
      }),
      providesTags: [RoutableTags.Routes],
    }),
  }),
});

export const { useGetRouteQuery } = extendedRoutesApi;
