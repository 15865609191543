/**
 * @fileOverview Utility/functional helpers that rely on other utilities, and
 * that need to mock/stub those utilities when testing.
 * @module helpers/utility/composite
 */

import _debounce from 'lodash/debounce';
import _fromPairs from 'lodash/fromPairs';
import _toPairs from 'lodash/toPairs';

import { uppercaseToUnderscore } from '../stringHelpers';

import * as atomic from './atomic';

// ======================
// Type Utils
// ======================

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Returns a debounced async function
 */
// TODO Add types
export const asyncDebounce = (func, wait) => {
  const debounced = _debounce((resolve, reject, args) => {
    func(...args)
      .then(resolve)
      .catch(reject);
  }, wait);
  return (...args) =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args);
    });
};

/**
 * Returns true if the value given is defined.
 */
export const isDefined = (value: unknown): boolean => !atomic.isUndef(value);

/**
 * Returns true if the value given is NOT an array.
 */
export const isNotArray = (value: unknown): boolean => !atomic.isArray(value);

/**
 * Returns true if the value given is a string or a number.
 */
export const isStringOrNum = (value: unknown): value is number | string =>
  atomic.or(atomic.isString(value), atomic.isNum(value));

// ======================
// Functional Utils
// ======================

/**
 * Returns true if the given value "does not exist" (is falsy);
 */
export const notExists = (value: unknown): boolean => !atomic.exists(value);

/**
 * Check if the value is an array and if the array length matches.
 */
export const isArrayLengthEqual = (value: unknown, expectedLength: number): boolean => {
  const isArray = atomic.isArray(value);
  const matchedExpectedLength = atomic.isEqual(atomic.lengthOf(value), expectedLength);
  return atomic.and(isArray, matchedExpectedLength);
};

/**
 * Returns whether the given object or array DOES NOT shallowly contain
 * the given value (keys will not be matched against, but values will be).
 */
export const isNotIncluded = (
  objOrArray: unknown[] | Record<string, unknown>,
  value: unknown,
  searchIndex?: number,
): boolean => !atomic.isIncluded(objOrArray, value, searchIndex);

/**
 * Returns whether the length property is equal to the length provided.
 */
export const isLastIndex = (array: unknown[], index: number): boolean =>
  atomic.isEqual(index, atomic.lengthOf(array) - 1);

// ======================
// Getter Utils
// ======================

/**
 * Returns whether the length of the array is 1
 */
export const hasLength1 = (array: unknown[]): boolean => atomic.isEqual(array.filter((el) => !!el)?.length, 1);

/**
 * Returns the value for the given object's first key.
 */
export const valueForFirstKey = (object: unknown = {}): unknown => atomic.valueForKey(object, atomic.firstKey(object));

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 * In shared, this function is called `mimicJSNativeNullBehavior`.
 *
 * If the provided argument is null, returns zero. Otherwise, returns the argument value as-is.
 * Note: this does NOT return 0 in the case of an undefined argument.
 */
export const valueOrZeroIfNull = <T>(val?: T | null): 0 | T => atomic.ternary(atomic.isNull(val), 0, val);

/**
 * If the provided value can be found in the array, return true.
 */
export const arrayIncludesValue = (array: unknown[], value: unknown): boolean =>
  atomic.isNotEqual(array.indexOf(value), -1);

/**
 * If the provided value CANNOT be found in the array, return false.
 */
export const arrayDoesNotIncludeValue = (array: unknown[], value: unknown): boolean =>
  !arrayIncludesValue(array, value);

/**
 * Returns true if collection element matches provided id.
 */
export const findById =
  <T extends { id: string }>(id: string) =>
  (i: T): boolean =>
    i.id === id;

export const deCamelCaseObjectKeys = (obj) => {
  const camels = _toPairs(obj);
  const snakes = camels.map(([key, value]) => [uppercaseToUnderscore(key), value]);
  return _fromPairs(snakes);
};
