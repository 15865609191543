import { FieldType, SectionType } from '@routable/tablematic';

import { blockComponents, tableComponents } from 'modules/table/components';

export const fieldComponents = {
  [SectionType.block]: {
    [FieldType.api_lookup]: blockComponents.ApiLookupBlockField,
    [FieldType.boolean]: blockComponents.CheckboxBlockField,
    [FieldType.currency]: blockComponents.CurrencyBlockField,
    [FieldType.currency_code]: blockComponents.CurrencyCodeBlockField,
    [FieldType.date]: blockComponents.DateBlockField,
    [FieldType.lookup]: blockComponents.LookupBlockField,
    [FieldType.number]: blockComponents.NumberBlockField,
    [FieldType.percent]: blockComponents.PercentBlockField,
    [FieldType.phone]: blockComponents.PhoneBlockField,
    [FieldType.static]: blockComponents.StaticBlockField,
    [FieldType.textarea]: blockComponents.TextareaBlockField,
    [FieldType.text]: blockComponents.TextBlockField,
  },
  [SectionType.presentational_block]: {
    [FieldType.api_lookup]: blockComponents.PresentationalField,
    [FieldType.boolean]: blockComponents.PresentationalFieldBoolean,
    [FieldType.currency]: blockComponents.PresentationalField,
    [FieldType.currency_code]: blockComponents.PresentationalField,
    [FieldType.date]: blockComponents.PresentationalField,
    [FieldType.lookup]: blockComponents.PresentationalField,
    [FieldType.number]: blockComponents.PresentationalField,
    [FieldType.percent]: blockComponents.PresentationalField,
    [FieldType.phone]: blockComponents.PresentationalField,
    [FieldType.static]: blockComponents.PresentationalField,
    [FieldType.textarea]: blockComponents.PresentationalField,
    [FieldType.text]: blockComponents.PresentationalField,
  },
  [SectionType.table]: {
    [FieldType.api_lookup]: tableComponents.ApiLookupTableField,
    [FieldType.boolean]: tableComponents.CheckboxTableField,
    [FieldType.currency]: tableComponents.CurrencyTableField,
    [FieldType.date]: tableComponents.DateTableField,
    [FieldType.lookup]: tableComponents.LookupTableField,
    [FieldType.number]: tableComponents.NumberTableField,
    [FieldType.percent]: tableComponents.PercentTableField,
    [FieldType.phone]: tableComponents.PhoneTableField,
    [FieldType.static]: tableComponents.StaticTableField,
    [FieldType.textarea]: tableComponents.TextareaTableField,
    [FieldType.text]: tableComponents.TextareaTableField,
  },
  [SectionType.customizableTable]: {
    [FieldType.api_lookup]: tableComponents.ApiLookupTableField,
    [FieldType.boolean]: tableComponents.CheckboxTableField,
    [FieldType.currency]: tableComponents.CurrencyTableField,
    [FieldType.date]: tableComponents.DateTableField,
    [FieldType.lookup]: tableComponents.LookupTableField,
    [FieldType.number]: tableComponents.NumberTableField,
    [FieldType.percent]: tableComponents.PercentTableField,
    [FieldType.phone]: tableComponents.PhoneTableField,
    [FieldType.static]: tableComponents.StaticTableField,
    [FieldType.textarea]: tableComponents.TextareaTableField,
    [FieldType.text]: tableComponents.TextareaTableField,
  },
  [SectionType.presentationalTable]: {
    [FieldType.api_lookup]: tableComponents.PresentationalTableField,
    [FieldType.boolean]: tableComponents.PresentationalTableField,
    [FieldType.currency]: tableComponents.PresentationalTableField,
    [FieldType.date]: tableComponents.PresentationalTableField,
    [FieldType.lookup]: tableComponents.PresentationalTableField,
    [FieldType.number]: tableComponents.PresentationalTableField,
    [FieldType.percent]: tableComponents.PresentationalTableField,
    [FieldType.phone]: tableComponents.PresentationalTableField,
    [FieldType.static]: tableComponents.PresentationalTableField,
    [FieldType.textarea]: tableComponents.PresentationalTableField,
    [FieldType.text]: tableComponents.PresentationalTableField,
  },
} as const;
