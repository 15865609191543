import { Reducer, ReducerAction } from 'interfaces/reducers';

import { getModalReducerOpenAction } from 'store/redux';

import * as types from 'types/webhooks';

import { StateProps } from './webhookTestModalReducer.types';

export const initialState: StateProps = {
  open: false,
};

const webhookTestModalReducer: Reducer<StateProps, ReducerAction> = (state = initialState, action) => {
  switch (action.type) {
    case types.CLOSE_WEBHOOKS_TEST_MODAL:
      return initialState;

    case types.OPEN_WEBHOOKS_TEST_MODAL:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default webhookTestModalReducer;
