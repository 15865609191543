import { useEffect } from 'react';

import { useLazyGetPartnershipPaymentOptionsQuery } from 'ducks/routableApi/paymentEndpoints';

import useSelectorWithProps from 'hooks/useSelectorWithProps';

import { partnershipPaymentCurrentPartnershipSelector } from 'selectors/partnershipPaymentSelector';

import type { UsePartnershipPaymentCurrencies } from './usePartnershipPaymentCurrencies.types';

export const usePartnershipPaymentCurrencies = (partnershipId: string): UsePartnershipPaymentCurrencies => {
  const data = useSelectorWithProps(partnershipPaymentCurrentPartnershipSelector, partnershipId);
  const [triggerGetPartnershipPayment, { isFetching, isLoading }] = useLazyGetPartnershipPaymentOptionsQuery();

  useEffect(() => {
    // isLoading refers to the first time that the endpoint has been called. We repeat the api call
    // when isLoading changes to false in order to retrieve updated data from backend.
    // This fixes an issue for newly created vendors (on create payable flow) for ledger connected companies due to
    // the /payments response relying on an async call - on backend side - to update the /payments routes
    // TODO: !isLoading condition is meant to be removed on https://warrenpay.atlassian.net/browse/DEV-12329
    if (partnershipId && !isLoading) {
      triggerGetPartnershipPayment({ partnershipId });
    }
  }, [isLoading, partnershipId, triggerGetPartnershipPayment]);

  return { data, isFetching };
};
