import React from 'react';

import { formNamesFunding } from 'constants/forms';

import { useBankAccountReceivingCurrency } from 'hooks';

import VendorReceivingCurrency from 'modules/dashboard/vendorReceivingCurrency/VendorReceivingCurrency';

import { itemCurrencyCodeReceiverFromQuerySelector } from 'queries/itemRouterSelectors';
import { partnershipFromQuerySelector } from 'queries/partnershipCompoundSelectors';

import { type Props } from './AccountCurrencyCodeField.types';

const AccountCurrencyCodeField = ({ availableCurrencies }: Props) => {
  const { fieldLabel, fieldName, isLocked, tooltipText, options } = useBankAccountReceivingCurrency({
    formName: formNamesFunding.ADD_MANUAL_BANK,
    availableCurrencies,
    itemCurrencyCodeSelector: itemCurrencyCodeReceiverFromQuerySelector,
    partnershipSelector: partnershipFromQuerySelector,
  });

  return (
    <VendorReceivingCurrency
      className="margin-top--m-large"
      fieldLabel={fieldLabel}
      fieldName={fieldName}
      isLocked={isLocked}
      options={options}
      tooltipText={tooltipText}
    />
  );
};

export default AccountCurrencyCodeField;
