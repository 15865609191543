import { call, put, spawn, take } from 'redux-saga/effects';

import * as routines from 'actions/routines/purposeCode';

import { parseCaughtError, parseErrorResponse, getRequestErrorAction } from 'helpers/errors';

import { Action } from 'interfaces/actions';
import { FetchServiceResponse } from 'interfaces/fetchService';
import { PurposeCodePayloadType } from 'interfaces/purposeCode';

import * as api from './api';

/**
 * Handle fetching all purposeCode
 * @returns {IterableIterator<*>}
 */
export function* fetchPurposeCode(action: Action): IterableIterator<unknown> {
  let errorData = {};

  yield put(routines.fetchPurposeCodeRoutine.request());

  try {
    const { payload } = action;

    const response: FetchServiceResponse = yield call(api.fetchPurposeCode, payload as PurposeCodePayloadType);

    if (response.ok) {
      yield put(routines.fetchPurposeCodeRoutine.success(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(errorData);
  yield put(errorAction(routines.fetchPurposeCodeRoutine.failure, errorData));
}

/**
 * Listens for redux actions related to purposeCode.
 * @return {IterableIterator<*>}
 */
export function* watch(): IterableIterator<unknown> {
  while (true) {
    const action: Action = yield take([routines.fetchPurposeCodeRoutine.TRIGGER]);

    switch (action.type) {
      case routines.fetchPurposeCodeRoutine.TRIGGER:
        yield spawn(fetchPurposeCode, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root purposeCode saga.
 * @return {IterableIterator<*>}
 */
export default function* purposeCode(): IterableIterator<unknown> {
  yield watch();
}
