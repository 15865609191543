import React from 'react';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderInput } from 'components';

import { requiredValidator } from 'helpers/fieldValidation';

import { InviteTeamMemberFormEmailField } from 'modules/dashboard/settings/account/InviteTeamMember/components/InviteTeamMemberForm/components';

import { Content, Fieldset, Legend, FieldWrapper, Row, LegendHelper } from './InviteTeamMemberContent.styles';

const InviteTeamMemberContent: React.VFC = () => (
  <Content>
    <Fieldset>
      {/* We need this div 'cause legend doesn't support flexbox */}
      <div>
        <Legend>Who would you like to invite?</Legend>
        <LegendHelper>
          Anyone you invite will have access to all of the information you&apos;ve already added, except for other
          representatives&apos; personal data.
        </LegendHelper>
      </div>
      <InviteTeamMemberFormEmailField placeholder="Team member email" />

      <Row>
        <FieldWrapper>
          <Field
            component={ReduxFormFieldRenderInput}
            name="form.firstName"
            placeholder="First name"
            type="text"
            validate={requiredValidator}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Field
            component={ReduxFormFieldRenderInput}
            name="form.lastName"
            placeholder="Last name"
            type="text"
            validate={requiredValidator}
          />
        </FieldWrapper>
      </Row>
    </Fieldset>

    <Fieldset>
      <div>
        <Legend>Provide instructions for your team member</Legend>
      </div>
      <Field
        component={ReduxFormFieldRenderInput}
        isRequired={false}
        name="form.inviteMessage"
        placeholder="Your personalized message"
        type="text"
      />
    </Fieldset>
  </Content>
);

export default InviteTeamMemberContent;
