import * as types from 'types/funding';

export const updateFundingAccountValidStatus = (fundingAccountId) => ({
  payload: { fundingAccountId },
  type: types.UPDATE_FUNDING_ACCOUNT,
});

export const verifyFundingAccountModalOpen = (fundingAccountId, fundingSourceId) => ({
  payload: { fundingAccountId, fundingSourceId },
  type: types.MODAL_VERIFY_FUNDING_ACCOUNT_OPEN,
});

export const verifyFundingAccountModalClose = () => ({
  type: types.MODAL_VERIFY_FUNDING_ACCOUNT_CLOSE,
});

export const bankAccountDetailsModalOpen = (payload) => ({
  payload,
  type: types.MODAL_BANK_ACCOUNT_DETAILS_OPEN,
});

export const bankAccountDetailsModalClose = () => ({
  type: types.MODAL_BANK_ACCOUNT_DETAILS_CLOSE,
});

export const fetchFundingAccountsRequest = () => ({
  type: types.FETCH_FUNDING_ACCOUNTS_REQUEST,
});

export const fetchFundingAccountsSuccess = (payload) => ({
  payload,
  type: types.FETCH_FUNDING_ACCOUNTS_SUCCESS,
});

export const fetchFundingAccountsFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_FUNDING_ACCOUNTS_FAILURE,
});

export const fetchSingleFundingAccountRequest = (accountId, options = {}) => ({
  payload: { accountId, options },
  type: types.FETCH_SINGLE_FUNDING_ACCOUNT_REQUEST,
});

export const fetchSingleFundingAccountSuccess = (payload) => ({
  payload,
  type: types.FETCH_SINGLE_FUNDING_ACCOUNT_SUCCESS,
});

export const fetchSingleFundingAccountFailure = (errors) => ({
  payload: { errors },
  type: types.FETCH_SINGLE_FUNDING_ACCOUNT_FAILURE,
});

/**
 * Opens the AddPartnerFundingAccountModal.
 * If opened within a containing form (e.g. CreateItems), formContext should be provided.
 * @param {object} options
 * @param {string} options.partnershipId
 * @param {String} options.paymentDeliveryMethod
 * @param {StringMaybe} options.formContext
 * @return {ReduxAction}
 */
export const openAddPartnerFundingAccountModal = ({ partnershipId, paymentDeliveryMethod, formContext }) => ({
  payload: { formContext, partnershipId, paymentDeliveryMethod },
  type: types.MODAL_ADD_PARTNER_FUNDING_ACCOUNT_OPEN,
});

export const closeAddPartnerFundingAccountModal = () => ({
  type: types.MODAL_ADD_PARTNER_FUNDING_ACCOUNT_CLOSE,
});

/**
 * International bank validation failure
 * @param {object} options
 * @param {string} options.fieldName
 * @param {Array}  options.errors
 * @returns {ReduxAction}
 */
export const internationalBankValidatorFailure = ({ fieldName, errors }) => ({
  fieldName,
  payload: errors?.fields?.[fieldName],
  type: types.FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_FAILURE,
});

/**
 * International bank validation success
 * @param {object} options
 * @param {string} options.fieldName
 * @param {object} options.bankDetails
 * @param {string} options.bankDetails.bankName
 * @returns {ReduxAction}
 */
export const internationalBankValidatorSuccess = ({ fieldName, bankDetails }) => ({
  fieldName,
  payload: bankDetails,
  type: types.FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_SUCCESS,
});

/**
 * International bank validation reset
 * @param {object} options
 * @param {string} options.fieldName
 */
export const internationalBankValidatorsReset = ({ fieldName }) => ({
  fieldName,
  type: types.FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_RESET,
});
