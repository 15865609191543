import React from 'react';

import PresentationalField from './PresentationalField';
import type { PresentationalFieldProps } from './PresentationalField.types';

const PresentationalFieldBoolean = (props: Omit<PresentationalFieldProps, 'fieldType'>): JSX.Element => (
  <PresentationalField {...props} fieldType="boolean" />
);

export default PresentationalFieldBoolean;
