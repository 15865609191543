import React from 'react';
import { useSelector } from 'react-redux';

import { commonFormFields, partnerFundingAccountFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';

import { useSelectorWithProps } from 'hooks';

import type { CompanyAddress } from 'interfaces/company';
import type { ReduxState } from 'interfaces/redux';

import { partnerCompanyAddressFromPartnershipPropSelector } from 'queries/companyCompoundSelectors';

import { createFieldValueSelector } from 'selectors/createFormSelectors';

import InternationalPartnerFundingAccountAddressDetails from './InternationalPartnerFundingAccountAddressDetails';
import type { ComponentProps } from './InternationalPartnerFundingAccountAddressDetails.types';

const countryFormFieldSelector = createFieldValueSelector(
  formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT,
  commonFormFields.PARTNER_COUNTRY,
);
const applyRegisteredAddressSelector = createFieldValueSelector(
  formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT,
  partnerFundingAccountFormFields.APPLY_REGISTERED_ADDRESS,
);

/**
 * Container component, handling state queries and logic for fetching current tax document
 * and determing if we should allow the RCTM to apply existing registered address or not.
 * @param props - Props passed to the container component
 */
const InternationalPartnerFundingAccountAddressDetailsContainer = ({ partnership }: ComponentProps): JSX.Element => {
  const country = useSelector<ReduxState, string>(countryFormFieldSelector);
  const shouldApplyRegisteredAddress = useSelector<ReduxState, boolean>(applyRegisteredAddressSelector);

  const address = useSelectorWithProps<CompanyAddress>(partnerCompanyAddressFromPartnershipPropSelector, {
    partnership,
  });

  // We want to show the "Apply registered address" checkbox if we have a registered address
  // and the address country matches the partnership's partner country
  const showApplyRegisteredAddress = Boolean(address) && address?.country === partnership?.countryCodePartner;

  return (
    <InternationalPartnerFundingAccountAddressDetails
      address={address}
      country={country}
      partnership={partnership}
      shouldApplyRegisteredAddress={shouldApplyRegisteredAddress}
      showApplyRegisteredAddress={showApplyRegisteredAddress}
    />
  );
};

export default InternationalPartnerFundingAccountAddressDetailsContainer;
