import { StatusIndicator, statusVariants } from '@routable/gross-ds';
import React from 'react';

import { ContactCellsProps } from '../ContactCellsTypes.types';

const contactStatusToVariant = {
  active: statusVariants.success,
  archived: statusVariants.default,
  invalid: statusVariants.error,
};

/**
 * A table cell showing the current status of a PartnershipMember
 */
const ContactStatusCell: React.FC<ContactCellsProps> = ({ rowData }) => {
  return (
    <StatusIndicator className="font-xs" status={contactStatusToVariant[rowData.status]}>
      {rowData.status}
    </StatusIndicator>
  );
};

export default ContactStatusCell;
