import classNames from 'classnames';
import React from 'react';

import { Icon, IconNames } from 'components/icon';
import { LinkNewPage } from 'components/link';

import { PLATFORM_URL } from 'constants/platform';
import { LEGAL, SECURITY } from 'constants/routes';

import { SafeAndSecureBadgeProps } from './SafeAndSecureBadge.types';
import './SafeAndSecureBadge.scss';

/**
 * Renders Safe & secure badge with icon
 */
const SafeAndSecureBadge: React.FC<SafeAndSecureBadgeProps> = ({ className, includeLink }) => {
  const badge = (
    <span
      className={classNames('badge__safe-and-secure', {
        [className]: !!className,
      })}
    >
      <Icon icon={IconNames.BADGE} />
      Safe & secure
    </span>
  );
  if (includeLink) {
    return <LinkNewPage href={`${PLATFORM_URL}${LEGAL}/${SECURITY}`}>{badge}</LinkNewPage>;
  }
  return badge;
};

export default SafeAndSecureBadge;
