import React from 'react';

import ProgressBarStep from './ProgressBarStep';
import Container from './ProgressBarStepperV2.styles';
import { ProgressBarStepperProps } from './ProgressBarStepperV2.types';

const ProgressBarStepperV2: React.VFC<ProgressBarStepperProps> = ({ steps, onClickStep, customStatus }) => (
  <Container>
    {steps.map((step) => (
      <ProgressBarStep customStatus={customStatus} key={`step-${step.id}`} onClickStep={onClickStep} step={step} />
    ))}
  </Container>
);

export default ProgressBarStepperV2;
