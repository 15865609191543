import { Attachment } from 'interfaces/attachment';
import { Item } from 'interfaces/item';

/**
 * Grabs item attachments from attachment map
 */
export const getItemAttachmentsFromMap = (item: Item, attachmentsById: Record<string, Attachment>): Attachment[] =>
  (item?.attachments || []).map((id) => attachmentsById[id]).filter(Boolean);

export const getNextBillIndex = (currentIndex: number, removalIndex: number, bills: unknown[]): number => {
  const removingBillAfter = removalIndex > currentIndex;
  if (removingBillAfter) {
    // Then keep current index
    return currentIndex;
  }

  const removingBillBefore = removalIndex < currentIndex;
  if (removingBillBefore) {
    // Then decreament current index to keep current bill selected
    return currentIndex - 1;
  }

  // Then must be removing the current bill

  const lastBillIndex = bills.length - 1;
  const hasBillsAfterCurrent = lastBillIndex > currentIndex;
  if (hasBillsAfterCurrent) {
    // Then select the next bill, by keeping current index
    return currentIndex;
  }

  // If there aren't any bills after, then select the previous bill.
  // Return -1 even when on the last bill aka index 0.
  return currentIndex - 1;
};
