import { IconNames } from 'components/icon';

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate constant. Until it is removed per the above ticket,
 * changes made to it also need to be made in packages/shared.
 */
// We need to map back to tablematic schemas which are not item.dateDue etc. but are broken
// into sections so depending on their section the mapping might be different
// this is used for setting initital values on the schema
export const fieldMappings = {
  dateDue: 'main.general.date_due',
  dateIssued: 'main.general.date_issued',
  invoiceNumber: 'main.general.invoice_number',
};

/**
 * Statuses for OCR attachments icons
 */
export const AttachmentStatuses = {
  PROCESSING: 'processing',
  SCANNING: 'scanning',
  DUPLICATE: 'duplicate',
  UNKNOWN: 'unknown',
  WARNING: 'warning',
  FINISHED: 'finished',
  DONE: 'done',
  NOT_STARTED: 'not_started',
  UPLOADED: 'uploaded',
  ERROR: 'error',
  SKIPPED: 'skipped',
  NO_LATEST_ANNOTATION: 'no_latest_annotation_status',
} as const;

export const AttachmentScanningStatuses = {
  [AttachmentStatuses.SCANNING]: true,
  [AttachmentStatuses.NOT_STARTED]: true,
  [AttachmentStatuses.PROCESSING]: true,
};

/**
 * Returns the icon title by attachment status
 * @enum {string}
 */
export const AttachmentStatusIconTitles = {
  [AttachmentStatuses.PROCESSING]: 'Scanning attachment',
  [AttachmentStatuses.SCANNING]: 'Scanning attachment',
  [AttachmentStatuses.UPLOADED]: 'Scanning attachment',
  [AttachmentStatuses.NO_LATEST_ANNOTATION]: 'Scanning attachment',
  [AttachmentStatuses.DUPLICATE]: 'Attachment flagged as a duplicate',
  [AttachmentStatuses.UNKNOWN]: 'Unsupported attachment',
  [AttachmentStatuses.WARNING]: 'Issue scanning attachment',
  [AttachmentStatuses.ERROR]: 'Issue scanning attachment',
  [AttachmentStatuses.DONE]: 'Scanning complete',
  [AttachmentStatuses.FINISHED]: 'Scanning complete',
  [AttachmentStatuses.SKIPPED]: 'Scanning complete',
};

/**
 * Returns the icon color by attachment status
 * @enum {string}
 */
export const AttachmentStatusIconColors = {
  [AttachmentStatuses.PROCESSING]: '#3C65F1',
  [AttachmentStatuses.SCANNING]: '#3C65F1',
  [AttachmentStatuses.UPLOADED]: '#3C65F1',
  [AttachmentStatuses.NO_LATEST_ANNOTATION]: '#3C65F1',
  [AttachmentStatuses.DUPLICATE]: '#3C65F1',
  [AttachmentStatuses.UNKNOWN]: '#E49318',
  [AttachmentStatuses.WARNING]: '#E49318',
  [AttachmentStatuses.ERROR]: '#E49318',
  [AttachmentStatuses.DONE]: '#76A86A',
  [AttachmentStatuses.FINISHED]: '#76A86A',
  [AttachmentStatuses.SKIPPED]: '#76A86A',
};

/**
 * Returns the icon name by attachment status
 */
export const AttachmentStatusIconNames = {
  [AttachmentStatuses.PROCESSING]: IconNames.CIRCLE,
  [AttachmentStatuses.SCANNING]: IconNames.CIRCLE,
  [AttachmentStatuses.UPLOADED]: IconNames.CIRCLE,
  [AttachmentStatuses.NO_LATEST_ANNOTATION]: IconNames.CIRCLE,
  [AttachmentStatuses.DUPLICATE]: IconNames.DUPLICATE,
  [AttachmentStatuses.UNKNOWN]: IconNames.HEART_BROKEN,
  [AttachmentStatuses.WARNING]: IconNames.ISSUE,
  [AttachmentStatuses.ERROR]: IconNames.ISSUE,
  [AttachmentStatuses.DONE]: IconNames.CONFIRM,
  [AttachmentStatuses.FINISHED]: IconNames.CONFIRM,
  [AttachmentStatuses.SKIPPED]: IconNames.CONFIRM,
} as const;

/**
 * Attachment default filename
 */
export const ATTACHMENT_DEFAULT_FILENAME = 'email.txt';
