import { RoutableEventsSync, RoutableObject } from '@routable/framework';
import { useAddToast } from '@routable/gross-ds';
import { formNamesItem } from '@routable/shared';
import { useDispatch, useSelector } from 'react-redux';
import { arrayPush, arrayRemove, change } from 'redux-form';

import { LineItemStyles } from 'constants/lineItems';

import { getDefaultLineItem, getLineItemsPathFromStyle } from 'helpers/lineItems';

import { lineItemsSectionsSelector } from 'modules/dashboard/createItems/createItemForm/components/CreateItemForm/lineItemsSectionsSelector';
import {
  isLineItemSimilarToDefault,
  transformLineItemToOtherSectionLineItem,
} from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/summarizeAndTransfer.helpers';

import { createItemFormAccountLineItemsSelector, createItemFormItemLineItemsSelector } from 'selectors/forms';

type TransferLineItemSection = {
  lineItem: LineItem;
  lineItemIndex: number;
  viewModelManager: RoutableObject;
};

export const useTransferSingleLineItem = ({ lineItem, lineItemIndex, viewModelManager }: TransferLineItemSection) => {
  const addToast = useAddToast();
  const dispatch = useDispatch();
  const isStyleItem = lineItem.style === LineItemStyles.ITEM;
  const otherSectionStyle = isStyleItem ? LineItemStyles.ACCOUNT : LineItemStyles.ITEM;
  const currentSectionPath = getLineItemsPathFromStyle(lineItem.style);
  const otherSectionPath = getLineItemsPathFromStyle(otherSectionStyle);

  const accountBillLineItems = useSelector(createItemFormAccountLineItemsSelector);
  const itemBillLineItems = useSelector(createItemFormItemLineItemsSelector);
  const activeSectionLineItems = isStyleItem ? itemBillLineItems : accountBillLineItems;
  const otherSectionLineItems = isStyleItem ? accountBillLineItems : itemBillLineItems;

  const { lineItemsItemSection, lineItemsAccountSection } = useSelector(lineItemsSectionsSelector);
  const lineItemsItemDisplayName = lineItemsItemSection?.display;
  const lineItemsAccountDisplayName = lineItemsAccountSection?.display;
  const destinationSectionDisplayName = !isStyleItem ? lineItemsItemDisplayName : lineItemsAccountDisplayName;

  const transferLineItem = () => {
    const [transformedLineItem] = transformLineItemToOtherSectionLineItem({
      lineItemStyle: lineItem.style,
      lineItems: [lineItem],
      viewModelManager,
    });

    const otherSectionDefaultLineItem = getDefaultLineItem(viewModelManager, otherSectionPath);
    const isOtherSectionEmpty =
      otherSectionLineItems.length === 1 &&
      isLineItemSimilarToDefault(otherSectionLineItems[0], otherSectionDefaultLineItem);
    // move line item to the other table
    if (isOtherSectionEmpty) {
      dispatch(change(formNamesItem.CREATE_ITEM, otherSectionPath, [transformedLineItem]));
    } else {
      dispatch(arrayPush(formNamesItem.CREATE_ITEM, otherSectionPath, transformedLineItem));
    }

    // remove line item from current table
    if (activeSectionLineItems.length === 1) {
      const currentSectionDefaultLineItem = getDefaultLineItem(viewModelManager, currentSectionPath);

      dispatch(change(formNamesItem.CREATE_ITEM, currentSectionPath, [currentSectionDefaultLineItem]));
    } else {
      dispatch(arrayRemove(formNamesItem.CREATE_ITEM, currentSectionPath, lineItemIndex));
    }

    RoutableEventsSync.Publish('line:items:transferred', { transferedTo: !isStyleItem ? 'ITEM' : 'ACCOUNT' });
    addToast({
      id: 'transfer-line-items-success',
      message: 'Line item successfully transferred',
      type: 'success',
    });
  };

  return {
    destinationSectionDisplayName,
    transferLineItem,
  };
};
