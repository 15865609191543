import { TruncatableText } from '@routable/components';
import React from 'react';

import { Icon } from 'components';

import { margin, sizes } from 'constants/styles';

import { PartnerPaymentMethodStatusContentLeftProps } from './PartnerPaymentMethodStatusContentLeft.types';

/**
 * Renders content on the left side for PartnerPaymentMethodStatus hint
 */
const PartnerPaymentMethodStatusContentLeft = ({
  statusIcon,
  statusText,
}: PartnerPaymentMethodStatusContentLeftProps) => (
  <>
    {statusIcon && (
      <Icon
        className="min-w-[14px]"
        icon={statusIcon}
        marginRight={margin.extraMedium}
        size={sizes.iconSizes.LARGE}
        title="Status icon"
      />
    )}

    <TruncatableText className="font-semibold text-grey-70 text-xs" dataFullStory position="top" text={statusText} />
  </>
);

export default PartnerPaymentMethodStatusContentLeft;
