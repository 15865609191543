import { ADMISSION, AdmissionRoutes } from 'constants/routes';

import type { RoutableApiResponse } from 'interfaces/routableApiResponse';

import { routableApi, RoutableTags } from '../routableApi';

import { UpsertRole } from './customRoles.types';

export const rolesEndpoint = `/${ADMISSION}/${AdmissionRoutes.CUSTOM_ROLES}/`;

export const extendedCustomRolesApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    // Get the custom roles list
    getCustomRoles: builder.query<RoutableApiResponse, { page?: number; pageSize?: number }>({
      query: () => ({
        url: rolesEndpoint,
      }),
      providesTags: [RoutableTags.CustomRoles],
    }),
    // Upsert custom role
    upsertCustomRole: builder.mutation<RoutableApiResponse, UpsertRole>({
      query: ({ baseRole, permissions, id, ...payload }) => ({
        url: `${rolesEndpoint}${id ? `${id}/` : ''}`,
        method: id ? 'PUT' : 'POST',
        payload: {
          data: {
            id,
            attributes: {
              ...payload,
              permissions,
              ...(!id && { baseRole }),
            },
            type: 'Role',
          },
        },
      }),
      invalidatesTags: [RoutableTags.CustomRoles],
    }),
    // Delete custom role
    deleteCustomRole: builder.mutation<RoutableApiResponse, { id: string }>({
      query: ({ id }) => ({
        url: `${rolesEndpoint}${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [RoutableTags.CustomRoles],
    }),
  }),
});

export const {
  useGetCustomRolesQuery,
  useUpsertCustomRoleMutation,
  useDeleteCustomRoleMutation,
  endpoints: { getCustomRoles },
} = extendedCustomRolesApi;
