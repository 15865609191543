import React from 'react';
import { useDispatch } from 'react-redux';

import { toggleDeactivateTeamMemberModal } from 'actions/signUpFlow';

import { Modal, IconNames, ModalFooter, ModalFooterButtonCancel, ButtonV2 } from 'components';
import Icon from 'components/icon';
import { Text } from 'components/text';

import { ButtonSize } from 'constants/button';
import { colors, sizes, typography } from 'constants/styles';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';

import { deactivateConfirmation } from 'modules/signup-v3/constants/modal';

import { Container } from './DeactivateTeamMemberModal.styles';
import { DeactivateTeamMemberModalProps } from './DeactivateTeamMemberModal.types';

const DeactivateTeamMemberModal: React.VFC<DeactivateTeamMemberModalProps> = ({
  open,
  firstName,
  lastName,
  deactivateMember,
  membershipId,
}) => {
  const dispatch = useDispatch();
  const onCloseModal = () => {
    dispatch(toggleDeactivateTeamMemberModal(membershipId));
  };
  return (
    <>
      <Modal
        body={
          <>
            <Container>
              <Text.Bold color={TextColor.BLUE_DARK} size={TextSize.LEVEL_400}>
                <Icon
                  className="margin-right--xm"
                  color={colors.colorRedBoldHex}
                  icon={IconNames.WARNING_SIGN}
                  size={sizes.iconSizes.XX_LARGE}
                />
                {deactivateConfirmation.getTitle(firstName, lastName)}
              </Text.Bold>
              <Text.Regular
                color={typography.TextColor.GREY_XX_DARK}
                lineHeight={typography.TextLineHeight.EXTRA_LARGE}
                size={typography.TextSize.LEVEL_200}
              >
                {deactivateConfirmation.text}
              </Text.Regular>
            </Container>
            <ModalFooter
              style={{
                backgroundColor: colors.colorWhiteHex,
              }}
            >
              <div className="content--left">
                <ModalFooterButtonCancel buttonSize={ButtonSize.SMALL} onCloseModal={onCloseModal} />
              </div>
              <div className="content--right">
                <ButtonV2
                  intent={Intent.DANGER}
                  leftIconClassName="margin-right--xm"
                  leftIconName={IconNames.REMOVE}
                  leftIconSize={sizes.iconSizes.MEDIUM}
                  onClick={deactivateMember}
                  rightIconClassName="margin-left--xm"
                  rightIconName={IconNames.ARROW_RIGHT}
                  rightIconSize={sizes.iconSizes.MEDIUM}
                  size={ButtonSize.SMALL}
                  type="button"
                >
                  Deactivate
                </ButtonV2>
              </div>
            </ModalFooter>
          </>
        }
        hasInlineFooter
        hideHeader
        modalState={open}
        onCloseModal={onCloseModal}
        overrideStyle={{
          content: {
            maxWidth: '500px',
            backgroundColor: colors.colorWhiteHex,
          },
          overlay: {
            backgroundColor: 'rgba(60, 72, 88, 0.1)',
          },
        }}
      />
    </>
  );
};

export default DeactivateTeamMemberModal;
