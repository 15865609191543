import PropTypes from 'prop-types';
import React from 'react';

import { SECTION_PATHS } from 'constants/createItem';

import { isEqual } from 'helpers/utility';

import LineItemDelete from './LineItemDelete';

const LineItemDeleteLogic = (props) => {
  const { formValues, getRowCount, index, onDelete, onReset, sectionPath } =
    props;

  const handleClick = React.useCallback(() => {
    const rowCount = getRowCount({ data: formValues, sectionPath });
    if (isEqual(rowCount, 1) && isEqual(index, 0)) {
      // on the last line item, if it's the only line item, we don't want to delete the array element (we
      // just want to reset it to its initial state)
      return onReset(sectionPath);
    }

    // Redux state holds line items both at form values.lineItems and also at form values.item.lineItems
    // this method has sectionPath as values.lineItems so we need to also delete at values.item.lineItems
    onDelete(SECTION_PATHS.ITEM_LINE_ITEMS, index);
    return onDelete(sectionPath, index);
  }, [formValues, getRowCount, index, onDelete, onReset, sectionPath]);

  return <LineItemDelete onClick={handleClick} />;
};

LineItemDeleteLogic.propTypes = {
  formValues: PropTypes.shape().isRequired,
  getRowCount: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  sectionPath: PropTypes.string.isRequired,
};

export default LineItemDeleteLogic;
