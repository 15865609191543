import { useSelector } from 'react-redux';

import { SWIFT_CHARGE_OPTIONS_BILLING_CODES } from 'constants/paymentMethods';

import { formatCurrencyUSD } from 'helpers/currencyFormatter';

import type { ParseCurrencyOptions } from 'interfaces/parseCurrencyOptions';

import { billingDataByCodeSelector } from 'selectors/billingSelectors';

interface SwiftChargeOptionsCostInterface {
  our: string;
  sha: string;
}

const defaultOptions = {
  precision: 0,
  symbol: '$',
};

const useSwiftChargeOptionsCost = (options?: ParseCurrencyOptions): SwiftChargeOptionsCostInterface => {
  const billingDataByCode = useSelector(billingDataByCodeSelector);

  const our = formatCurrencyUSD(billingDataByCode[SWIFT_CHARGE_OPTIONS_BILLING_CODES.our]?.rate, {
    ...defaultOptions,
    ...options,
  });
  const sha = formatCurrencyUSD(billingDataByCode[SWIFT_CHARGE_OPTIONS_BILLING_CODES.sha]?.rate, {
    ...defaultOptions,
    ...options,
  });

  return { our, sha };
};

export default useSwiftChargeOptionsCost;
