import { getCurrentMembershipId } from 'helpers/localStorage';
import { isArrayLengthEqual, isNotEqual } from 'helpers/utility';

import { Membership } from 'types/quickswitch';

import { modalContentHeight, modalContentWidth } from './constants';
import { ModalStyleOverride } from './QuickswitchModal';

/**
 * Given a boolean stating whether the search bar
 * should be shown, returns the modal style object
 * that will be used to override the native style
 *
 * @param {boolaen} showSearchBar
 * @returns {{content}}
 */
export const generateModalStyleOverride = (showSearchBar: boolean): ModalStyleOverride => {
  if (showSearchBar) {
    return {
      maxHeight: modalContentHeight,
      height: modalContentHeight,
      width: modalContentWidth,
    };
  }
  return { maxHeight: modalContentHeight, width: modalContentWidth };
};

/**
 * Given an array of Membership objects, returns true if it's empty
 * @param memberships
 * @returns {boolean} true if the membership list is empty, otherwise false.
 */
export const membershipListIsEmpty = (memberships: Membership[]): boolean => isArrayLengthEqual(memberships, 0);

/**
 * Given an array of Membership objects, remove the object
 * corresponding to the company the user it currently logged into
 * @param {Membership[]} memberships
 * @returns {Membership[]}
 */
export const removeCurrentMembershipFromList = (memberships: Membership[]): Membership[] =>
  memberships.filter((membership) => isNotEqual(membership.id, getCurrentMembershipId()));
