export enum FilterOperator {
  BETWEEN = 'BETWEEN',
  EQUALS = 'EQUALS',
  GREATER_THAN_OR_EQUAL = 'GTE',
  EXACT_MATCH_IN_COMMA_SEPARATED_LIST = 'IS_EXACT_IN_CSL',
  CONTAIN_MATCH_IN_COMMA_SEPARATED_LIST = 'IS_CONTAINS_IN_CSL',
  STARTS_WITH_MATCH_IN_COMMA_SEPARATED_LIST = 'IS_STARTS_WITH_IN_CSL',
  ENDS_WITH_MATCH_IN_COMMA_SEPARATED_LIST = 'IS_ENDS_WITH_IN_CSL',
  IS_IN_SET = 'IS_IN_SET',
  LESS_THAN_OR_EQUAL = 'LTE',
}

export interface ItemFilterOperatorOption {
  label: string;
  operator: FilterOperator;
}
