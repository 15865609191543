import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchFundingAccountsRequest } from 'actions/funding';

import { PROCESSING_HERO } from 'constants/images';
import * as modalNames from 'constants/modals';

import BankAccountSelector from 'modules/connectBank/bankAccountSelector';
import { VerifyFundingAccountFormModal } from 'modules/connectBank/verifyFundingAccount/components';

import { modalsSelector } from 'selectors/modalsSelector';

import { fundingAccountsWhereBankForCurrentCompanyCountSelector } from '../../../queries/fundingCompoundSelectors';
import BankConnections from '../../dashboard/settings/account/bankConnections/presenters/BankConnections';

import {
  Container,
  FormCenter,
  FormContainer,
  FormColumn,
  Heading,
  ImageContainer,
  LeadText,
  SubHeading,
} from './Processing.styles';

const Processing: React.FC = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchFundingAccountsRequest());
  }, [dispatch]);

  const numberOfAccounts = useSelector(fundingAccountsWhereBankForCurrentCompanyCountSelector);
  const modals = useSelector(modalsSelector);

  return (
    <Container>
      <ImageContainer>
        <img alt="Processing" src={PROCESSING_HERO} />
      </ImageContainer>
      <FormContainer>
        <FormCenter>
          <FormColumn>
            <Heading>We&lsquo;re processing your information now</Heading>
            <SubHeading>You can expect an email from us in the next 1-2 business days.</SubHeading>
          </FormColumn>
          <FormColumn>
            {numberOfAccounts === 0 ? (
              <>
                <LeadText>Get a jump start by adding your bank account information now.</LeadText>
                <BankAccountSelector />
              </>
            ) : (
              <>
                <LeadText>Your bank account</LeadText>
                <BankConnections hideTab />
              </>
            )}
          </FormColumn>
          {modals[modalNames.modalNameVerifyFundingAccount]?.open && <VerifyFundingAccountFormModal />}
        </FormCenter>
      </FormContainer>
    </Container>
  );
};

export default Processing;
