import * as routines from 'actions/routines/funding';

import { createErrorReducer } from 'store/redux';

export const failureCases = [routines.fetchSupportedCountriesRoutine.FAILURE];

export const successCases = [
  routines.fetchSupportedCountriesRoutine.REQUEST,
  routines.fetchSupportedCountriesRoutine.SUCCESS,
];

const errorsReducer = createErrorReducer(successCases, failureCases);

export default errorsReducer;
