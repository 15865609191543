import { Item } from 'interfaces/item';
import { UIItemFilter } from 'interfaces/itemFilters';

import {
  singleSelectCheckboxAction,
  multiselectCheckboxAction,
} from 'modules/dashboard/itemListTables/helpers/helpers';

import * as types from 'types/tables';

import { withModifierKeys, withThrottle } from './modifiers';
import { FilterOptions } from './tables.types';

/**
 * Action for applying filters to payables table
 */
export const applyPayablesTableFilters = (filters: UIItemFilter[], opt: FilterOptions = {}): ReduxAction => ({
  payload: { filters, ...opt },
  type: types.APPLY_PAYABLES_TABLE_FILTERS,
});

/**
 * Action for applying filters to receivables table
 */
export const applyReceivablesTableFilters = (filters: UIItemFilter[], opt: FilterOptions = {}): ReduxAction => ({
  payload: { filters, ...opt },
  type: types.APPLY_RECEIVABLES_TABLE_FILTERS,
});

/**
 * Action for applying filters to payables table
 */
export const applyPayablesTableSort = (sortParam: string): ReduxAction => ({
  payload: { sortParam },
  type: types.APPLY_PAYABLES_TABLE_SORT,
});

/**
 * Action for applying filters to receivables table
 */
export const applyReceivablesTableSort = (sortParam: string): ReduxAction => ({
  payload: { sortParam },
  type: types.APPLY_RECEIVABLES_TABLE_SORT,
});

/**
 * Action for applying filters to company payment history table
 */
export const applyCompanyPaymentHistoryTableSort = (sortParam: string): ReduxAction => ({
  payload: { sortParam },
  type: types.APPLY_COMPANY_PAYMENT_HISTORY_TABLE_SORT,
});

/**
 * Action for toggling single table row selection in dashboard tables
 */
export const toggleSingleTableRowSelection = (isSelected: boolean, tab: string, item: Item): ReduxAction =>
  withThrottle({ id: `table-toggle-${item.id}`, timeout: 100 })(
    withModifierKeys(
      singleSelectCheckboxAction({
        isSelect: !isSelected,
        tab,
        payload: { item },
      }),
    ),
  );

/**
 * Action for toggling all table rows selection in dashboard tables
 */
export const toggleAllTableRowsSelection = ({
  areAllSelected,
  areSomeSelected,
  items,
  tab,
}: {
  areAllSelected: boolean;
  areSomeSelected: boolean;
  items: Item[];
  tab: string;
}): ReduxAction =>
  multiselectCheckboxAction({
    isSelect: !(areAllSelected || areSomeSelected),
    tab,
    payload: { items },
  });

/**
 * Action for deselecting all rows
 */
export const deselectAllRows = (): ReduxAction => ({
  type: types.DESELECT_ALL_ROWS,
});
