import styled from 'styled-components';

import { containerWidthMap } from 'modules/signup-v3/constants/container';

export const DescriptionContainer = styled.div`
  display: flex;
  gap: 4rem;
  max-width: ${containerWidthMap.large};
`;

export const DescriptionImage = styled.img`
  height: 95px;
  margin-top: 1rem;
  width: auto;
`;

export const DescriptionTitle = styled.h5`
  color: var(--grey--xx-dark);
  font-weight: 600;
  margin: 0.5rem 0;
`;

export const BulletListContainer = styled.div`
  padding-left: 0.5rem;

  /* Maybe we can update the list later? Or do a styled bullet list? */
  .bullet-list {
    align-items: center;
  }
  .bullet-list--bullet {
    margin-top: 0;
    margin-right: 0.5rem;
  }
  .bullet-list--text {
    font-size: 1rem;
  }
`;

export const BulletItemEmph = styled.span`
  font-weight: 500;
`;

export const BulletItemLink = styled.a`
  color: var(--blue--bold);
  font-weight: 400;
`;
