import React from 'react';

import { ModalFooter, ModalFooterButtonCancel, IconNames, ButtonV2 } from 'components';

import { ButtonSize } from 'constants/button';
import { colors, sizes } from 'constants/styles';

import { InviteTeamMemberFooterProps } from './InviteTeamMemberFooter.types';

const InviteTeamMemberFooter: React.VFC<InviteTeamMemberFooterProps> = ({ handleClose, disabled }) => (
  <ModalFooter
    style={{
      backgroundColor: colors.colorWhiteHex,
    }}
  >
    <div className="content--left">
      <ModalFooterButtonCancel onCloseModal={handleClose} />
    </div>
    <div className="content--right">
      <ButtonV2
        isDisabled={disabled}
        leftIconClassName="margin-right--m"
        leftIconName={IconNames.TICK_CIRCLE}
        leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
        size={ButtonSize.MEDIUM}
        type="submit"
      >
        Send Invite
      </ButtonV2>
    </div>
  </ModalFooter>
);

export default InviteTeamMemberFooter;
