import { Action, AnyAction } from 'redux';
import { UnifiedRoutine } from 'redux-saga-routines';

import { allKeys } from 'helpers/utility';

import { Links, Pagination } from 'interfaces/jsonapi';

type NonErrorStatus = {
  isError: false;
  isLoading: false;
  error: null;
};
type LoadingStatus = {
  isError: false;
  isLoading: true;
  error: null;
};
type ErrorStatus<T> = {
  error: T;
  isError: true;
  isLoading: false;
};

type Status<T> = NonErrorStatus | LoadingStatus | ErrorStatus<T>;

const initialStatus: NonErrorStatus = {
  isLoading: false,
  isError: false,
  error: null,
};

export const generateStatusReducer =
  <T>(routine: Pick<UnifiedRoutine, 'TRIGGER' | 'SUCCESS' | 'FAILURE'>, error: T) =>
  (statusState: Status<T> = initialStatus, action: AnyAction): Status<T> => {
    switch (action.type) {
      case routine.TRIGGER:
        return {
          isLoading: true,
          isError: false,
          error: null,
        };
      case routine.SUCCESS:
        return {
          isLoading: false,
          isError: false,
          error: null,
        };
      case routine.FAILURE:
        return {
          isLoading: false,
          isError: true,
          error,
        };
      default:
        return statusState;
    }
  };

export const generateByIdReducer =
  <T>(actions: string[], fields: string[]) =>
  (
    byIdState: Record<string, T> = {},
    action: Action & { payload: Record<string, Record<string, T>> },
  ): Record<string, T> => {
    if (actions.includes(action.type)) {
      return {
        ...byIdState,
        ...fields.map((field) => action.payload[field]).reduce((acc, curr) => ({ ...curr, ...acc }), {}),
      };
    }
    return byIdState;
  };

export const generateIdReducer =
  <T>(idAction: string, field: string) =>
  (allIdState: string[] = [], action: Action & { payload: Record<string, Record<string, T>> }): string[] => {
    switch (action.type) {
      case idAction:
        return allKeys(action.payload[field]);
      default:
        return allIdState;
    }
  };

export const generateLinksReducer =
  (routine: Pick<UnifiedRoutine, 'SUCCESS'>, initialState = {}) =>
  (
    linkState: Partial<Links> = initialState,
    action: { type: string; payload: { links: Partial<Links> } },
  ): Partial<Links> => {
    switch (action.type) {
      case routine.SUCCESS:
        return action.payload.links;
      default:
        return linkState;
    }
  };

export const generatePaginationReducer =
  (routine: Pick<UnifiedRoutine, 'SUCCESS'>, initialState = {}) =>
  (
    paginationState: Partial<Pagination> = initialState,
    action: {
      type: string;
      payload: { meta: { pagination: Partial<Pagination> } };
    },
  ): Partial<Pagination> => {
    switch (action.type) {
      case routine.SUCCESS:
        return action.payload.meta.pagination;
      default:
        return paginationState;
    }
  };

export type ApiInterface<Data = unknown, Error = string> = {
  apiStatus: Status<Error>;
  byId: Record<string, Data>;
  ids: string[];
  links?: Partial<Links>;
  pagination?: Partial<Pagination>;
};
