export const CLEAR_INVOICES = 'CLEAR_INVOICES';

export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';
export const FETCH_ADDITIONAL_INVOICES_SUCCESS = 'FETCH_ADDITIONAL_INVOICES_SUCCESS';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';

export const FETCH_ITEM_FAILURE = 'FETCH_ITEM_FAILURE';
export const FETCH_ITEM_REQUEST = 'FETCH_ITEM_REQUEST';
export const FETCH_ITEM_SUCCESS = 'FETCH_ITEM_SUCCESS';

export const FETCH_ITEMS = '@item/FETCH_ITEMS';

export const FETCH_ITEM_AND_THREAD_REQUEST = 'FETCH_ITEM_AND_THREAD_REQUEST';

export const DELETE_INBOX_ITEM = '@item/DELETE_INBOX_ITEM';

export const DELETE_ITEM_FILE = '@item/DELETE_ITEM_FILE';

export const FETCH_ITEMS_EXPORT_FAILURE = 'FETCH_ITEMS_EXPORT_FAILURE';
export const FETCH_ITEMS_EXPORT_REQUEST = 'FETCH_ITEMS_EXPORT_REQUEST';
export const FETCH_ITEMS_EXPORT_SUCCESS = 'FETCH_ITEMS_EXPORT_SUCCESS';

export const OPEN_EXISTING_ITEM_SIDE_PANEL = 'OPEN_EXISTING_ITEM_SIDE_PANEL';
export const CLOSE_EXISTING_ITEM_SIDE_PANEL = 'CLOSE_EXISTING_ITEM_SIDE_PANEL';

export const CLEAR_SUBMITTED_ITEM = 'CLEAR_SUBMITTED_ITEM';

export const SUBMIT_EXISTING_ITEMS_FAILURE = 'SUBMIT_EXISTING_ITEMS_FAILURE';
export const SUBMIT_EXISTING_ITEMS_REQUEST = 'SUBMIT_EXISTING_ITEMS_REQUEST';
export const SUBMIT_EXISTING_ITEMS_SUCCESS = 'SUBMIT_EXISTING_ITEMS_SUCCESS';

export const UPDATE_EXISTING_ITEMS_FAILURE = 'UPDATE_EXISTING_ITEMS_FAILURE';
export const UPDATE_EXISTING_ITEMS_REQUEST = 'UPDATE_EXISTING_ITEMS_REQUEST';
export const UPDATE_EXISTING_ITEMS_SUCCESS = 'UPDATE_EXISTING_ITEMS_SUCCESS';

// routine types (request/response handlers)
export const SEND_SUBMIT_BILL_TO_ITEM_REQUEST = '@item/SEND_SUBMIT_BILL_TO_ITEM';
export const SEND_SUBMIT_INVOICE_REQUEST = '@item/SEND_SUBMIT_INVOICE_REQUEST';
export const SEND_SUBMIT_ITEM_REQUEST = '@item/SEND_SUBMIT_ITEM';
export const SEND_SUBMIT_MULTI_INVOICE_REQUEST = '@item/SEND_SUBMIT_MULTI_INVOICE_REQUEST';

// routine types (flow triggers with reducer switch cases)
export const SUBMIT_ITEM = '@item/SUBMIT_ITEM';
export const SUBMIT_ITEM_EDIT = '@item/SUBMIT_ITEM_EDIT';
export const SUBMIT_INVOICES = '@item/SUBMIT_INVOICES';

export const OPEN_ITEM_HAS_BEEN_UPDATED_SWAL = 'OPEN_ITEM_HAS_BEEN_UPDATED_SWAL';
export const RESET_ITEM_HAS_BEEN_UPDATED_SWAL = 'RESET_ITEM_HAS_BEEN_UPDATED_SWAL';

export const OCR_STATUS_ITEM_CHANGE = 'OCR_STATUS_ITEM_CHANGE';

export const EAR_EVALUATED_ITEM = 'EAR_EVALUATED_ITEM';
