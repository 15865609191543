import { AddAlt } from '@carbon/icons-react';
import React from 'react';

import { Tag } from 'components/tag';
import { Text } from 'components/text';

import { PartnershipTypes } from 'constants/partnership';
import { typography } from 'constants/styles';
import { TagType } from 'constants/ui';

import { getCustomerOrVendorTextByPartnership } from 'helpers/partnerships';
import { capitalize } from 'helpers/stringHelpers';

import type { MultiLineOptionForSearchCompaniesProps } from './MultiLineOptionForSearchCompanies.types';
import { SearchCompanyOption } from './SearchCompanyOption';

/**
 * Renders multi-line select option specifically for the search companies select
 */
const MultiLineOptionForSearchCompanies = ({
  data,
  ...rest
}: MultiLineOptionForSearchCompaniesProps): React.ReactElement => {
  // eslint-disable-next-line no-underscore-dangle
  const isCreateNewOption = Boolean(data.__isNew__);

  return (
    <SearchCompanyOption
      {...rest}
      data={{
        ...data,
        leftAddons: isCreateNewOption ? (
          <span className="text-black w-3 blue-on-option-hover">
            <AddAlt />
          </span>
        ) : undefined,
        rightAddons: !isCreateNewOption && (
          <div className="display--flex">
            {data.isCustomer && data.isVendor && (
              <Tag
                className="cursor--inherit--important margin-right--m margin-left--xs padding-left--m padding-right--m"
                hasIcon={false}
                isClosable={false}
                readOnly
                TextComponent={Text.Semibold}
                textSize={typography.TextSize.LEVEL_100}
                type={TagType.SUCCESS}
              >
                {capitalize(PartnershipTypes.VENDOR)}
              </Tag>
            )}
            <Tag
              className="cursor--inherit--important padding-left--m padding-right--m"
              hasIcon={false}
              isClosable={false}
              readOnly
              TextComponent={Text.Semibold}
              textSize={typography.TextSize.LEVEL_100}
              type={TagType.SUCCESS}
            >
              {capitalize(getCustomerOrVendorTextByPartnership(data))}
            </Tag>
          </div>
        ),
      }}
    />
  );
};

export default MultiLineOptionForSearchCompanies;
