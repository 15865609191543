import React from 'react';

import { Text, FlexRow } from 'components';

import { paginationComponent } from 'constants/dataTestId/paginationDataTestId';
import { TextColor, TextSize } from 'constants/styles/typography';
import { PAGE_SIZE_OPTIONS } from 'constants/tables';

import { PageSizeSelectionProps } from './PageSizeSelection.types';
import { Select } from './Select';

import './PageSizeSelection.scss';

export const PageSizeSelection: React.FC<PageSizeSelectionProps> = ({ pageSize, onPageSizeChange, isLoading }) => {
  if (isLoading || !onPageSizeChange) {
    return null;
  }

  return (
    <FlexRow className="tlp--page-size" stackOnMobile={false}>
      <Text.Regular
        className="display"
        color={TextColor.GREY_X_DARK}
        data-testid={paginationComponent.FOOTER_INDEX}
        size={TextSize.LEVEL_100}
      >
        Rows per page
      </Text.Regular>
      <div className="dropdown-container">
        <Select
          isSearchable={false}
          onChange={onPageSizeChange}
          options={PAGE_SIZE_OPTIONS}
          value={pageSize}
          valueKey="label"
          {...{ placeholder: '' }}
        />
      </div>
    </FlexRow>
  );
};
