import { UserFollow } from '@carbon/icons-react';
import React from 'react';
import { Link } from 'react-router-dom';

import { FormFieldDescription, LinkNewPage } from 'components';
import { LabelLink } from 'components/link/LabelLink/LabelLink.styles';

import { UserType } from 'enums/user';

import { filterActiveItemMembersWithEmail } from 'helpers/itemMember';
import { getCompanyRouteByPartnershipId } from 'helpers/routeHelpers';
import { hasLength } from 'helpers/utility';

import { useMountedValue } from 'hooks';

import { GroupedContactDynamicFormFieldDescriptionProps } from './GroupedContactDynamicFormFieldDescription.types';

const GroupedContactDynamicFormFieldDescription: React.FC<GroupedContactDynamicFormFieldDescriptionProps> = ({
  onCreateContact,
  partnershipMembers,
  partnershipType,
  selectedCompany,
  selectedContacts,
}) => {
  const hasContactsOnInitialRender = useMountedValue(hasLength(selectedContacts));
  const hasReachableContacts = filterActiveItemMembersWithEmail(partnershipMembers);

  if (!hasLength(hasReachableContacts)) {
    return selectedCompany?.partner?.companyType === UserType.PERSONAL ? (
      <FormFieldDescription className="mb-3 enable-pointer-events pl-2">
        {`This individual doesn't have a reachable email. You can update their `}
        <Link className="primary no-underline" to={getCompanyRouteByPartnershipId(selectedCompany.id)}>
          company page
        </Link>
        .
      </FormFieldDescription>
    ) : (
      <div className="flex gap-2 pl-2">
        <UserFollow className="fill-grey-70" />
        <FormFieldDescription className="mb-3 enable-pointer-events">
          {`You have no reachable contacts, but you can `}
          <LabelLink onClick={onCreateContact}>add a new contact</LabelLink>
          <span>.</span>
        </FormFieldDescription>
      </div>
    );
  }

  // This should only render if the partnership selected/created had contacts.
  // Won't render if a new contact was created in the same page
  if (hasContactsOnInitialRender) {
    return (
      <FormFieldDescription className="!mb-3 enable-pointer-events pl-2">
        {`The contacts below were autofilled based on the `}
        <LinkNewPage className="primary" href={getCompanyRouteByPartnershipId(selectedCompany.id)}>
          {`${partnershipType}'s contact settings ↗`}
        </LinkNewPage>
        .
      </FormFieldDescription>
    );
  }

  return null;
};

export default GroupedContactDynamicFormFieldDescription;
