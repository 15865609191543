export enum ITEM_SORT_TYPE_UPDATED_DATE {
  LABEL = 'Updated on',
  PARAM = 'thread_modified',
}

export enum ITEM_SORT_TYPE_DUE_DATE {
  LABEL = 'Due on',
  PARAM = 'date_due',
}

export enum ITEM_SORT_TYPE_INVOICE_DATE {
  LABEL = 'Invoice date',
  PARAM = 'date_issued',
}

export enum ITEM_SORT_TYPE_AMOUNT_DUE {
  LABEL = 'Amount due',
  PARAM = 'amount_due',
}

export enum ITEM_SORT_TYPE_AMOUNT_PAID {
  LABEL = 'Amount paid',
  PARAM = 'amount_paid',
}

export enum ITEM_SORT_TYPE_ADDED_DATE {
  LABEL = 'Added date',
  PARAM = 'created',
}
