import { useInternationalBankFields } from '@routable/fund-routes';
import { PaymentDeliveryMethodType } from '@routable/shared';
import React from 'react';
import { useEffectOnce } from 'react-use';

import { createInternationalBankAccountHandler } from 'actions/routines/funding';

import { ContentBlockShade, Form, ModalBodyContent, Text } from 'components';

import { formNamesFunding } from 'constants/forms';
import { UserTypeToInternationalFundingBusinessType } from 'constants/funding';
import { TextColor } from 'constants/styles/typography';

import { getSubmitButtonText } from 'helpers/addPartnerFundingAccount';
import { getCountryNameFromCode } from 'helpers/countries';
import { openAddPartnerFundingAccountSidePanel } from 'helpers/sidePanels';

import { useCountryCodesContext, useFormSelectedCurrency, useSelectorWithProps } from 'hooks';

import {
  AddPartnerFundingAccountFooter,
  AddPartnerFundingAccountOptions,
} from 'modules/fundingAccount/components/AddPartnerFundingAccountForm/components';

import { companyFromPartnershipFromPropsSelector } from 'queries/companyCompoundSelectors';

import type { AddInternationalPartnerFundingAccountFormProps } from './AddInternationalPartnerFundingAccountForm.types';
import {
  InternationalPartnerFundingAccountAddressDetails,
  InternationalPartnerFundingAccountBankDetails,
  NationalityField,
  NonGovermentOrganizationFields,
} from './components';
import { useAvailableCurrenciesForFundingAccounts } from './hooks';

/**
 * Renders form for adding new parter international funding account.
 * Used inside side panel and existing item view in create item flow and vendor info and in modal in existing item flow.
 */
const AddInternationalPartnerFundingAccountForm = ({
  formName = formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT,
  handleSubmit,
  isSubmitting,
  onClose,
  partnership,
}: AddInternationalPartnerFundingAccountFormProps) => {
  const selectedCurrency = useFormSelectedCurrency(formName);
  const company = useSelectorWithProps(companyFromPartnershipFromPropsSelector, { partnershipId: partnership.id });

  const { data: fields, isFetching: isFetchingFields } = useInternationalBankFields({
    currencyCode: selectedCurrency.code,
    countryCode: partnership.countryCodePartner,
    businessType: UserTypeToInternationalFundingBusinessType[company?.companyType],
  });

  useEffectOnce(() => {
    openAddPartnerFundingAccountSidePanel({
      formContext: formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT,
      partnershipId: partnership?.id,
      paymentDeliveryMethod: PaymentDeliveryMethodType.INTERNATIONAL,
    });
  });

  const countryCodeMap = useCountryCodesContext();
  const country = getCountryNameFromCode(countryCodeMap, partnership.countryCodePartner);

  const availableCurrencies = useAvailableCurrenciesForFundingAccounts(partnership);

  const onSubmit = handleSubmit((values, dispatch) =>
    createInternationalBankAccountHandler(values, dispatch, { partnership, requiredFields: fields?.all }),
  );

  return (
    <React.Fragment>
      <Form id={formName} onSubmit={onSubmit}>
        <ModalBodyContent className="add-partner-funding-account--side-panel-body">
          <ContentBlockShade className="height--100-percent" isShady={isFetchingFields}>
            <InternationalPartnerFundingAccountBankDetails
              availableCurrencies={availableCurrencies}
              country={country}
              fields={fields?.bank}
              partnership={partnership}
            />
            <InternationalPartnerFundingAccountAddressDetails partnership={partnership} />

            {!!fields?.beneficiary.length && (
              <div className="flex flex-col mt-7">
                <Text.Semibold color={TextColor.GREY_XX_DARK}>
                  Additional information required to send {selectedCurrency.name} ({selectedCurrency.code})
                </Text.Semibold>
                <Text.Regular color={TextColor.GREY_XX_DARK}>
                  Everything must match the receiving bank&apos;s information
                </Text.Regular>

                {fields.beneficiaryByName.country_of_nationality && (
                  <NationalityField className="mt-5" defaultValue={partnership.countryCodePartner} form={formName} />
                )}

                {fields.beneficiaryByName.in_fcra && (
                  <NonGovermentOrganizationFields className="mt-5" form={formName} />
                )}
              </div>
            )}

            <AddPartnerFundingAccountOptions
              className="margin-top--large"
              formName={formName}
              partnership={partnership}
              paymentDeliveryMethod={PaymentDeliveryMethodType.INTERNATIONAL}
            />
          </ContentBlockShade>
        </ModalBodyContent>
      </Form>

      <AddPartnerFundingAccountFooter
        formId={formName}
        isSubmitting={isSubmitting}
        onCloseModal={onClose}
        submitButtonText={getSubmitButtonText(PaymentDeliveryMethodType.INTERNATIONAL)}
      />
    </React.Fragment>
  );
};

export default AddInternationalPartnerFundingAccountForm;
