import { ItemFilterLabels, ItemFilterUrlParams } from 'enums/itemFilters';

import {
  AmountDueFilter,
  BankAccountFilter,
  BulkActionFilter,
  BulkActionStatusFilter,
  CreatedDateFilter,
  CurrentApproverFilter,
  CurrentApproverStatusFilter,
  DateDueFilter,
  IdFilter,
  InvoiceNumbersFilter,
  ItemStatusFilter,
  PaymentFilter,
} from 'interfaces/itemFilters/filter';
import { FilterOperator } from 'interfaces/itemFilters/filterOperators';

/**
 * Amount Due filter
 */
export const baseAmountDueFilter: AmountDueFilter = {
  urlParam: ItemFilterUrlParams.FILTER_AMOUNT_DUE_URL_PARAM,
  label: ItemFilterLabels.AMOUNT_DUE,
  value: null,
  selectedOperator: FilterOperator.GREATER_THAN_OR_EQUAL,
};

export const createAmountDueFilter = (
  value: AmountDueFilter['value'] = null,
  filterOperator: FilterOperator = FilterOperator.GREATER_THAN_OR_EQUAL,
): AmountDueFilter => ({
  ...baseAmountDueFilter,
  selectedOperator: filterOperator,
  value,
});

/**
 * Bank Account filter
 */
export const baseBankAccountFilter: BankAccountFilter = {
  urlParam: ItemFilterUrlParams.FILTER_BANK_ACCOUNT_URL_PARAM,
  label: ItemFilterLabels.BANK_ACCOUNT,
  selectedOperator: FilterOperator.IS_IN_SET,
  value: null,
};

export const createBankAccountFilter = (value: BankAccountFilter['value'] = null): BankAccountFilter => ({
  ...baseBankAccountFilter,
  selectedOperator: FilterOperator.IS_IN_SET,
  value,
});

/**
 * Bulk Action ID filter
 */
export const baseBulkActionFilter: BulkActionFilter = {
  urlParam: ItemFilterUrlParams.FILTER_BULK_ACTION_URL_PARAM,
  label: ItemFilterLabels.BULK_ACTION,
  selectedOperator: FilterOperator.EQUALS,
  value: null,
};

export const createBulkActionFilter = (value: BulkActionFilter['value'] = null): BulkActionFilter => ({
  ...baseBulkActionFilter,
  value,
});

/**
 * Bulk Action Status Filter
 */
export const baseBulkActionStatusFilter: BulkActionStatusFilter = {
  urlParam: ItemFilterUrlParams.FILTER_BULK_ACTION_STATUS_URL_PARAM,
  label: ItemFilterLabels.BULK_ACTION_STATUS,
  selectedOperator: FilterOperator.IS_IN_SET,
  value: null,
};

export const createBulkActionStatusFilter = (
  value: BulkActionStatusFilter['value'] = null,
): BulkActionStatusFilter => ({
  ...baseBulkActionStatusFilter,
  value,
});

/**
 * Created Date filter
 */
export const baseCreatedDateFilter: CreatedDateFilter = {
  urlParam: ItemFilterUrlParams.FILTER_CREATED_DATE_URL_PARAM,
  label: ItemFilterLabels.CREATED_DATE,
  selectedOperator: FilterOperator.EQUALS,
  value: null,
};

export const createCreatedDateFilter = (
  value: CreatedDateFilter['value'] = null,
  filterOperator: FilterOperator = FilterOperator.EQUALS,
): CreatedDateFilter => ({
  ...baseCreatedDateFilter,
  selectedOperator: filterOperator,
  value,
});

// Current Approver Filter

export const baseCurrentApproverFilter: CurrentApproverFilter = {
  urlParam: ItemFilterUrlParams.FILTER_PAYMENT_APPROVERS_URL_PARAM,
  label: ItemFilterLabels.CURRENT_APPROVER,
  selectedOperator: FilterOperator.IS_IN_SET,
  value: null,
};

export const createCurrentApproverFilter = (value: CurrentApproverFilter['value'] = null): CurrentApproverFilter => ({
  ...baseCurrentApproverFilter,
  value,
});

// Current Approver Status Filter

export const baseCurrentApproverStatusFilter: CurrentApproverStatusFilter = {
  urlParam: ItemFilterUrlParams.FILTER_APPROVER_STATUS_URL_PARAM,
  label: ItemFilterLabels.CURRENT_APPROVER_STATUS,
  selectedOperator: FilterOperator.IS_IN_SET,
  value: null,
};

export const createCurrentApproverStatusFilter = (
  value: CurrentApproverStatusFilter['value'] = null,
): CurrentApproverStatusFilter => ({
  ...baseCurrentApproverStatusFilter,
  value,
});

/**
 * Date Due Filter
 */
export const baseDateDueFilter: DateDueFilter = {
  urlParam: ItemFilterUrlParams.FILTER_DATE_DUE_URL_PARAM,
  label: ItemFilterLabels.DUE_DATE,
  value: null,
  selectedOperator: FilterOperator.EQUALS,
};

export const createDateDueFilter = (
  value: DateDueFilter['value'] = null,
  filterOperator: FilterOperator = FilterOperator.EQUALS,
): DateDueFilter => ({
  ...baseDateDueFilter,
  selectedOperator: filterOperator,
  value,
});

/**
 * Item Status Filter
 */

export const baseItemStatusFilter: ItemStatusFilter = {
  urlParam: ItemFilterUrlParams.FILTER_ITEM_STATUS_URL_PARAM,
  label: ItemFilterLabels.ITEM_STATUS,
  selectedOperator: FilterOperator.EQUALS,
  value: null,
};

export const createItemStatusFilter = (value: ItemStatusFilter['value'] = null): ItemStatusFilter => ({
  ...baseItemStatusFilter,
  value,
});

/**
 * Item Id Filter
 */
export const createIdFilter = (value: IdFilter['value'] = null): IdFilter => ({
  label: ItemFilterLabels.ITEM_ID,
  value,
});

/**
 * Payment Id Filter
 */

export const basePaymentFilter: PaymentFilter = {
  urlParam: ItemFilterUrlParams.FILTER_PAYMENT_ID_URL_PARAM,
  label: ItemFilterLabels.PAYMENT_ID,
  selectedOperator: FilterOperator.EQUALS,
};

export const createPaymentFilter = (value: PaymentFilter['value']): PaymentFilter => ({
  ...basePaymentFilter,
  selectedOperator: Array.isArray(value) ? FilterOperator.IS_IN_SET : FilterOperator.EQUALS,
  value,
});

/**
 * Invoice Numbers Filter
 */
export const baseInvoiceNumbersFilter: InvoiceNumbersFilter = {
  urlParam: ItemFilterUrlParams.FILTER_INVOICE_NUMBERS_URL_PARAM,
  label: ItemFilterLabels.INVOICE_NUMBERS,
  selectedOperator: FilterOperator.EXACT_MATCH_IN_COMMA_SEPARATED_LIST,
  value: null,
};

export const createInvoiceNumbersFilter = (
  value: InvoiceNumbersFilter['value'] = '',
  selectedOperator: InvoiceNumbersFilter['selectedOperator'] = FilterOperator.EXACT_MATCH_IN_COMMA_SEPARATED_LIST,
): InvoiceNumbersFilter => ({
  ...baseInvoiceNumbersFilter,
  value,
  selectedOperator,
});
