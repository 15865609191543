import { RoutableObject } from '@routable/framework';
import React from 'react';
import { components, OptionProps } from 'react-select';

import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';

import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

const { Option } = components;

const Colors = {
  Focused: {
    PrimaryLabelColor: 'var(--color-white)',
    SecondaryLabelColor: 'var(--color-white)',
  },
  Disabled: {
    PrimaryLabelColor: 'var(--color-grey50)',
    SecondaryLabelColor: 'var(--color-grey50)',
  },
  Normal: {
    PrimaryLabelColor: 'var(--color-grey70)',
    SecondaryLabelColor: 'var(--color-grey60)',
  },
};

const getFontColors = ({ isDisabled, isFocused }) => {
  if (isDisabled) {
    return Colors.Disabled;
  }

  if (isFocused) {
    return Colors.Focused;
  }

  return Colors.Normal;
};

const OptionWithTooltip = (props: OptionProps<unknown, false>): JSX.Element => {
  const { data, label } = props;
  const { tooltipText, secondaryLabel } = data as RoutableObject;
  const tooltipProps = tooltipText
    ? {
        arrow: true,
        padding: TooltipPadding.SKINNY,
        placement: TooltipPlacement.RIGHT,
        title: (
          <Text.Regular color="var(--color-grey70)" size={typography.TextSize.LEVEL_100}>
            {tooltipText}
          </Text.Regular>
        ),
      }
    : {};
  const { PrimaryLabelColor, SecondaryLabelColor } = getFontColors(props);

  return (
    <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
      <Option {...props}>
        <Text.Semibold
          className="margin-right--xm"
          color={PrimaryLabelColor}
          lineHeight={typography.TextLineHeight.MEDIUM}
          size={typography.TextSize.LEVEL_100}
        >
          {label}
        </Text.Semibold>

        {secondaryLabel && (
          <Text.Regular
            color={SecondaryLabelColor}
            lineHeight={typography.TextLineHeight.MEDIUM}
            size={typography.TextSize.LEVEL_100}
          >
            {secondaryLabel}
          </Text.Regular>
        )}
      </Option>
    </TooltipMUIConditionalWrapper>
  );
};

export default OptionWithTooltip;
