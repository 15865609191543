import { RoutableObject } from '@routable/framework';
import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

import { FlexCol, FlexRow } from 'components/layout';

import { getClassNames } from 'helpers/ui';

import { DataProps } from './MultiLineOptionForSearchCompanies.types';

export interface SearchCompanyOptionProps extends React.ComponentProps<typeof components.Option> {
  data: DataProps;
}

const MultiLineOptionAddons = styled.div`
  color: var(--color-neutral-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const MultiLineOptionLabel = styled.div`
  color: var(--color-neutral-black);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const MultiLineOptionName = styled.div`
  color: var(--color-neutral-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const MultiLineOptionLegalName = styled.div`
  color: var(--neutrals-grey-60);
  font-feature-settings: 'calt' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const MultiLineOptionDetails = styled.div`
  color: var(--color-neutral-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const SearchCompanyOption: React.FC<SearchCompanyOptionProps> = ({ data, ...rest }) => {
  const { details, leftAddons, rightAddons } = data;
  const selectProps = rest.selectProps as RoutableObject;
  const { dataFullStory } = selectProps;

  // eslint-disable-next-line no-underscore-dangle
  const isCreateNewOption = Boolean(data.__isNew__);

  const name = data.name || data.companyName;
  const legalName = data.partner?.name;

  return (
    <components.Option
      {...rest}
      className={getClassNames(rest, {
        'multi-line-option': true,
      })}
      data={data}
      data-fullstory={dataFullStory}
    >
      <FlexRow className="align-items--center" stackOnMobile={false}>
        <MultiLineOptionAddons className="padding-right--m">{leftAddons}</MultiLineOptionAddons>

        <FlexCol className="flex--1">
          {isCreateNewOption ? (
            <MultiLineOptionLabel className="search-company-option__label" data-fullstory={dataFullStory}>
              {rest.label}
            </MultiLineOptionLabel>
          ) : (
            <MultiLineOptionName className="search-company-option__label" data-fullstory={dataFullStory}>
              {name}
            </MultiLineOptionName>
          )}
          {legalName && (
            <MultiLineOptionLegalName className="search-company-option__details">
              {`Legal name: ${legalName}`}
            </MultiLineOptionLegalName>
          )}

          <MultiLineOptionDetails className="search-company-option__details">{details}</MultiLineOptionDetails>
        </FlexCol>

        <MultiLineOptionAddons className="padding-left--m">{rightAddons}</MultiLineOptionAddons>
      </FlexRow>
    </components.Option>
  );
};
