import React from 'react';

import { hasAuthToken } from 'helpers/auth';
import { getDomainWithOrWithoutCompanyNamespace, isTopLevelDomain } from 'helpers/urls';

/**
 * useRedirectFromTopLevelToBrandedWorkspace
 * Redirects non-authenticated users from app.routable.com/<path>?queryParams -> routablehq.com/<path>?queryParams
 *
 * This hook is used to deprecated the old app domain and force users to always use the new branded domain.
 */
export const useRedirectFromTopLevelToBrandedWorkspace = (): void => {
  React.useEffect(() => {
    if (!hasAuthToken() && isTopLevelDomain()) {
      const includePathname = true;
      const includeSearch = true;
      window.location.replace(getDomainWithOrWithoutCompanyNamespace(null, includePathname, includeSearch));
    }
  }, []);
};
