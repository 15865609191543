import { AxiosRequestHeaders } from 'axios';

import { Options } from 'actions/routines/createApiRoutine';

import { parseFeatureSettings } from 'data/parse';

import { FetchService } from 'services';
import { featureSettingsEndpoint } from 'services/api/configEndpoints';

/**
 * Config for fetching active/enabled feature settings.
 * Used by getFeatureSettingsRoutine
 */
export const getFetchFeatureSettingsApiConfig = (): Options => {
  const responseTransformers = [
    parseFeatureSettings.transform.attachIdToFeatureResponseObjects,
    ...FetchService.getTransformers({}),
    parseFeatureSettings.transform.settingsResponseToEnabledKeys,
  ];

  return {
    endpoint: featureSettingsEndpoint,
    method: 'GET',
    requireAuth: true,
    transformers: responseTransformers,
    headers: {} as AxiosRequestHeaders,
  };
};
