import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2, IconNames } from 'components';

import { colors, sizes } from 'constants/styles';
import { TooltipPlacement } from 'constants/tooltip';
import { Intent } from 'constants/ui';

import { noop } from 'helpers/utility';

import RestrictedPermissionTooltip from 'modules/auth/RestrictedPermissionTooltip/RestrictedPermissionTooltipContainer';

import { ADD_CONTACT_BUTTON_COPY } from './constants';

/**
 * Returns the gated version of the AddContactButtonTarget
 * @param {ComponentProps} props
 * @param {ReactNode} [props.children]
 * @returns {StatelessComponent}
 */
const RestrictedAddContactButton = ({ children }) => (
  <RestrictedPermissionTooltip customization={{ placement: TooltipPlacement.LEFT }}>
    <ButtonV2
      intent={Intent.NEUTRAL}
      // ensure the button is disabled when gated
      isDisabled
      leftIconClassName="margin-right--xm"
      leftIconColor={colors.colorGreyDarkHex}
      leftIconName={IconNames.ADD}
      leftIconSize={sizes.iconSizes.MEDIUM}
      onClick={noop}
    >
      {children}
    </ButtonV2>
  </RestrictedPermissionTooltip>
);

RestrictedAddContactButton.propTypes = {
  children: PropTypes.node,
};

RestrictedAddContactButton.defaultProps = {
  children: ADD_CONTACT_BUTTON_COPY,
};

export default RestrictedAddContactButton;
