import React from 'react';
import { useSelector } from 'react-redux';

import { isFundingAccountEligibleForRTP } from 'helpers/funding';
import { isPaymentDeliveryOptionRTP } from 'helpers/paymentMethods';

import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';

import { useShouldDisableInternationalFundingAccount } from './PartnerPaymentMethodSelectionOption.hooks';
import type { PartnerPaymentMethodSelectionOptionProps as Props } from './PartnerPaymentMethodSelectionOption.types';
import { PartnerPaymentMethodSelectionOptionBankRadio } from './PartnerPaymentMethodSelectionOptionBankRadio';
import { PartnerPaymentMethodSelectionOptionPaymentMethod } from './PartnerPaymentMethodSelectionOptionPaymentMethod';

/**
 * Radio option component to select partner funding account.
 * Also switches to newer PaymentMethodManage component if feature flag is enabled.
 */
const PartnerPaymentMethodSelectionOption: React.FC<Props> = ({
  currencyCodeReceiver,
  partnerFundingAccount,
  fundingAccountsById,
  fundingInfoAddressesById,
  input,
  onSelectChangeToPrimary,
  onSelectViewAccountDetail,
  partnership,
  paymentDeliveryMethod,
  paymentDeliveryOption,
}) => {
  const isDisabledByCurrencyCode = useShouldDisableInternationalFundingAccount(
    partnerFundingAccount.currencyCode,
    currencyCodeReceiver,
  );
  const isInternationalPaymentsEnabled = useSelector(currentCompanySettingsIsInternationalPaymentsEnabledSelector);
  const isInputDisabled =
    isDisabledByCurrencyCode ||
    (isPaymentDeliveryOptionRTP(paymentDeliveryOption) && !isFundingAccountEligibleForRTP(partnerFundingAccount));

  if (isInternationalPaymentsEnabled) {
    return (
      <PartnerPaymentMethodSelectionOptionPaymentMethod
        className="margin-bottom--m"
        fundingAccount={fundingAccountsById[partnerFundingAccount.fundingAccount]}
        input={{
          ...input,
          disabled: isInputDisabled,
        }}
        isSelectable
        partnership={partnership}
        showFundingAccountId
      />
    );
  }

  return (
    <PartnerPaymentMethodSelectionOptionBankRadio
      fundingAccountsById={fundingAccountsById}
      fundingInfoAddressesById={fundingInfoAddressesById}
      input={input}
      onSelectChangeToPrimary={onSelectChangeToPrimary}
      onSelectViewAccountDetail={onSelectViewAccountDetail}
      partnerFundingAccount={partnerFundingAccount}
      partnership={partnership}
      paymentDeliveryMethod={paymentDeliveryMethod}
      paymentDeliveryOption={paymentDeliveryOption}
    />
  );
};

export default PartnerPaymentMethodSelectionOption;
