import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchPartnershipItemsRequest } from 'actions/partnership';

import { UnknownObject } from 'interfaces/global';

import { partnershipIdFromCurrentPartnershipRequestSelector } from 'queries/partnershipCompoundSelectors';

import {
  partnershipItemsExceptCurrentOneSelector,
  werePartnershipItemsFetchedSelector,
} from 'selectors/partnershipItemsSelectors';
import { itemKindFromExternalV2LocationSelector } from 'selectors/routerSelectors';

import { createMergeProps } from 'store/redux';

import ExternalItemsListWrapper from './ExternalItemsListWrapper';
import { DispatchProps, MergedProps, StateProps } from './ExternalItemsListWrapper.types';

const mapStateToProps = createStructuredSelector<UnknownObject, StateProps>({
  itemsKind: itemKindFromExternalV2LocationSelector,
  partnershipId: partnershipIdFromCurrentPartnershipRequestSelector,
  partnershipItems: partnershipItemsExceptCurrentOneSelector,
  wasFetched: werePartnershipItemsFetchedSelector,
});

const mapDispatchToProps: DispatchProps = {
  onFetchPartnershipItems: fetchPartnershipItemsRequest,
};

const mergeProps = createMergeProps(
  (stateProps: StateProps, dispatchProps: DispatchProps): MergedProps => ({
    fetchPartnershipItems: () =>
      dispatchProps.onFetchPartnershipItems({
        partnershipId: stateProps.partnershipId,
        kind: stateProps.itemsKind,
      }),
  }),
);

export { mapDispatchToProps, mapStateToProps, mergeProps };
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ExternalItemsListWrapper);
