import React from 'react';
import ReactDOM from 'react-dom';

type TeleporterOptions = {
  fallbackChild?: React.ReactNode;
  multiSources?: boolean;
};

type TeleporterContext = {
  value?: React.ReactNode;
  set?: {
    current?: (ref: React.ReactNode) => void;
  };
};

type TeleporterSourceContext = {
  value?: React.ReactNode;
  updateTarget?: () => void;
};

interface TargetProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  as?: React.ElementType;
}

type TargetType = (props: TargetProps) => JSX.Element;

type SourceType = (props: React.PropsWithChildren) => React.ReactPortal;

type CreateTeleporter = (options: TeleporterOptions) => {
  Source: SourceType;
  Target: TargetType;
  useTargetRef: () => (element: React.ReactNode) => void;
};

/**
 * Creates a teleporter, which allows us to render a component in one part of the app (the rendered Source component),
 * and commit it to the HTML in a completely different location (the rendered Target component).
 */
const createTeleporter: CreateTeleporter = ({ fallbackChild, multiSources } = {}) => {
  // object to hold a reference to the Target element ref
  const context: TeleporterContext = {};
  // object to hold a reference to a function that the Source can call to show/hide the Target's fallback child,
  // as well as the Source's current children
  const sourceContext: TeleporterSourceContext = {};

  // sets the Target ref
  const setElement = (element) => {
    context.value = element;

    if (context.set && context.set.current) {
      context.set.current(element);
    }
  };

  // track whether the Source is rendering portal children
  const setSourceElement = (element) => {
    sourceContext.value = element;
  };

  const useTargetRef = () => setElement;

  const Target: TargetType = ({ as: As = 'div' }) => {
    const [children, setChildren] = React.useState(fallbackChild);
    const handleRef = useTargetRef();

    React.useLayoutEffect(() => {
      const updateTarget = () => {
        // if the source is not rendering anything, we'll show the fallback child (if set)
        if (!sourceContext.value) {
          setChildren(fallbackChild);
        } else {
          // if the source is rendering stuff, the Target is simply a portal for those children and
          // won't need to render any children of its own
          setChildren(null);
        }
      };

      // if we haven't yet set an updater function for the Source, set it now
      if (!sourceContext.updateTarget) {
        sourceContext.updateTarget = updateTarget;
      }

      // check once for updates
      updateTarget();

      // cleanup on unmount
      return () => {
        sourceContext.updateTarget = undefined;
      };
    }, []);

    return <As ref={handleRef}>{children}</As>;
  };

  const Source: SourceType = ({ children }) => {
    const [elementRef, setElementRef] = React.useState(null);

    React.useLayoutEffect(() => {
      // set a safe object reference to the Target ref, that updates with the react lifecycle
      const setRef = { current: setElementRef };
      // temp value to be used during unmount cleanup
      let previousSet;

      if (context.set) {
        previousSet = context.set;

        if (!multiSources) {
          context.set.current(null);
        }
      }

      context.set = setRef;
      setElementRef(context.value);

      setSourceElement(children);
      sourceContext.updateTarget?.();

      // cleanup on unmount
      return () => {
        setRef.current = null;
        context.set = null;
        sourceContext.value = null;

        // if Target hasn't unmounted, we might just be conditionally hiding the Source content, so
        // we should call update on the Target real quick in case it should now render a fallbackChild
        if (elementRef) {
          sourceContext.updateTarget?.();
        }

        // now that everything has been reset, restore references to the Target element ref
        // and its setter, if they previously existed
        if (previousSet && previousSet.current) {
          context.set = previousSet;
          context.set.current(context.value);
        }
      };
    }, [children, elementRef]);

    // if we have a Source but no Target reference, don't render anything yet
    if (!elementRef) {
      return null;
    }

    // render a portal using the Target element ref
    return ReactDOM.createPortal(children, elementRef);
  };

  // put a bow on it, that's a wrap
  return { Source, Target, useTargetRef };
};

export default createTeleporter;
