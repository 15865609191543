import { PartnershipMemberAccess } from 'constants/partnershipMember';
import { ContactSidePanelType } from 'constants/sidePanels';

import { Item } from 'interfaces/item';
import { Partnership } from 'interfaces/partnerships';

import { CompanyWithPartner } from './GroupedContactSelectsInForm.types';
import type { ContactOptions } from './logic.types';

/**
 * Method to build the onCreateContact options, conditionally add the initialValues if autoFill is present
 */
export const getOnCreateContactOptions = (
  input: string,
  selectedCompany: Partnership | CompanyWithPartner,
  autoFill?: PartnershipMemberAccess,
  item?: Item,
): ContactOptions => {
  const options: ContactOptions = {
    company: selectedCompany,
    input,
    item,
    partnershipId: selectedCompany.id,
    type: ContactSidePanelType.CREATE,
  };

  // If autoFill level is passed, add to initial values
  if (autoFill) {
    options.initialValues = {
      defaultGeneral: autoFill,
      defaultItem: autoFill,
    };
  }

  return options;
};
