import React from 'react';

import StaticPhoneNumberText from 'components/text/StaticPhoneNumberText';

import { anyValues } from 'helpers/utility';

import { ContactCellsProps } from '../ContactCellsTypes.types';

/**
 * The contact's phone number, rendered with country flag and international formatting.
 * @param {ContactCellsProps} props
 * @returns {StatelessComponent}
 */
const ContactPhoneNumberCell: React.FC<ContactCellsProps> = ({ rowData }) => {
  const { phoneNumber } = rowData;

  if (anyValues(phoneNumber)) {
    return <StaticPhoneNumberText hasFlag phoneNumber={phoneNumber} />;
  }

  return null;
};

export default ContactPhoneNumberCell;
