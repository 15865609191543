import React, { FocusEvent } from 'react';
import type { Validator } from 'redux-form';

import { useLazyLookupFundingBankAccountQuery } from 'ducks/routableApi/fundingEndpoints';

import { Text } from 'components';

import { typography } from 'constants/styles';

import { useSelectorWithProps } from 'hooks';

import { internationalBankValidationData } from 'selectors/fundingSelectors';

import { shouldLookupBankAccount } from './helpers';
import type { UseInternationalBankValidator } from './hooks.types';

export const useInternationalBankValidator = (
  fieldName: string,
  isInternational: boolean,
): UseInternationalBankValidator => {
  const [triggerLookupBankAccount, { isFetching: isValidating }] = useLazyLookupFundingBankAccountQuery();

  // Lookup call should occur on onBlur event for fields that can be validated via Currency Cloud
  // No lookup call should be made for non applicable fields
  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    const fieldValue = event.target.value;

    if (!!fieldValue && shouldLookupBankAccount(fieldName, isInternational)) {
      triggerLookupBankAccount({ fieldName, fieldValue });
    }
  };

  // Bank or error data related to the given field
  const bankValidationData = useSelectorWithProps(internationalBankValidationData, fieldName);

  // Sync-ish redux field validator
  const fieldValidator: Validator = React.useMemo(
    () => () => !isValidating && !!bankValidationData.errors ? bankValidationData.errors : undefined,
    [bankValidationData.errors, isValidating],
  );

  // In case data is valid, we include the bank name node to the result, this
  // way the consumer is agnostic on the implementation details
  const bankNameNode = bankValidationData.data?.bankName && (
    <div className="margin-top--xm">
      <Text.Regular size={typography.TextSize.LEVEL_100}>{bankValidationData.data?.bankName}</Text.Regular>
    </div>
  );

  return { bankNameNode, fieldValidator, isValidating, onBlur };
};
