import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'helpers/axiosBaseQuery';

/**
 * Top-level API tag types
 */
export enum RoutableTags {
  Company = 'Company',
  CompanySettings = 'CompanySettings',
  CustomRoles = 'CustomRoles',
  Exporter = 'Exporter',
  Funding = 'Funding',
  Items = 'Items',
  LedgerObjects = 'LedgerObjects',
  Memberships = 'Memberships',
  Partnership = 'Partnership',
  Permissions = 'Permissions',
  Transactions = 'Transactions',
  Routes = 'Routes',
}

/**
 * Ids used in combination with RoutableTags for a specific
 * use-case
 */
export enum RoutableTagIds {
  W8TaxInfo = 'W8TaxInfo',
  W9TaxInfo = 'W9TaxInfo',
}

export const routableApi = createApi({
  reducerPath: 'routableApi',
  baseQuery: axiosBaseQuery(),
  // seconds data is kept once there are no subscribed component(s)
  // this is small because of legacy support for now durring migration
  keepUnusedDataFor: 5,
  tagTypes: Object.values(RoutableTags),
  // handles re-retrieving data if the window was out of focus then came back.
  refetchOnFocus: true,
  endpoints: () => ({}),
});
