import classNames from 'classnames';
import React from 'react';

import { Icon, IconNames } from 'components';

import { margin, sizes } from 'constants/styles';

import type { UndoButtonProps } from './UndoButton.types';

/**
 * UndoButton for unskipping given customer/vendor
 */
const UndoButton = ({ className, isDisabled, onClick }: UndoButtonProps): React.ReactElement => {
  const linkClassName = classNames({
    'align-items--center': true,
    'dark-grey': true,
    'display--flex': true,
    'force-underline': true,
    'hover-primary': true,
    disabled: isDisabled,
    [className]: className,
  });

  return (
    <a className={linkClassName} onClick={onClick} role="presentation">
      <Icon icon={IconNames.UNDO} marginRight={margin.small} size={sizes.iconSizes.LARGE} />
      <p className="font-xs remove-margin-bottom">Undo</p>
    </a>
  );
};

export default UndoButton;
