import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  currentCompanySettingsIntegrationSelector,
  currentCompanySettingsSelector,
} from 'selectors/currentCompanySelectors';
import { createPartnershipFormPartnershipTypeSelector } from 'selectors/forms';
import { fundingSupportedCountriesPaymentOptionsCurrencyList } from 'selectors/fundingSelectors';
import { ledgerIntegrationSelector } from 'selectors/integrationsSelectors';
import { ledgerOptionsLedgerCurrenciesArraySelector } from 'selectors/ledgerInfoSelectors';

import MultiLineOptionWithTooltipForSearchCompanies from './MultiLineOptionWithTooltipForSearchCompanies';
import type { StateProps } from './MultiLineOptionWithTooltipForSearchCompanies.types';

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  companyIntegrationSettings: currentCompanySettingsIntegrationSelector,
  companySettings: currentCompanySettingsSelector,
  formPartnershipType: createPartnershipFormPartnershipTypeSelector,
  ledger: ledgerIntegrationSelector,
  ledgerCurrencies: ledgerOptionsLedgerCurrenciesArraySelector,
  supportedCurrencies: fundingSupportedCountriesPaymentOptionsCurrencyList,
});

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(MultiLineOptionWithTooltipForSearchCompanies);
