import React from 'react';
import { Link } from 'react-router-dom';

import { WhiteSpace } from 'components/text';

import { RESET_PASSWORD } from 'constants/routes';

/**
 * Accept Membership Invite error text conent for
 * when user is part of two companies and is not entering existing password. */
const EnterExistingPasswordErrorContent = (): JSX.Element => (
  <>
    A password is already associated with this email address. In order to proceed, please enter that password.
    <WhiteSpace />
    <Link className="font-color--primary semibold" to={RESET_PASSWORD}>
      Forgot your password?
    </Link>
  </>
);

export default EnterExistingPasswordErrorContent;
