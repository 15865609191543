import React from 'react';
import { connect } from 'react-redux';

import { removeNotFoundError } from 'actions/errors';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { ProtectedRouteWarn } from 'components/routing';

import { DASHBOARD, LOGIN } from 'constants/routes';

import { hasAuthToken } from 'helpers/auth';
import { isExternalPathAny } from 'helpers/external';
import { asPath } from 'helpers/routeHelpers';

import type { NotFound404Props } from './NotFound404.types';

export const NotFound404: React.VFC<NotFound404Props> = ({ location, history, onRemoveNotFoundError }) => {
  const isAuthenticated = hasAuthToken();
  const isExternal = isExternalPathAny();

  React.useEffect(
    () => () => {
      onRemoveNotFoundError();
    },
    [onRemoveNotFoundError],
  );

  const goBack = () => {
    history.goBack();
  };

  const redirectUser = () => {
    const redirectURL = isAuthenticated ? asPath(DASHBOARD) : asPath(LOGIN);

    history.push(redirectURL);
  };

  let actionText;
  if (isExternal) {
    actionText = undefined;
  } else if (isAuthenticated) {
    actionText = 'Go to my dashboard';
  } else {
    actionText = 'Take me to the login page';
  }

  let previousPathname;
  if (location?.state?.from) {
    previousPathname = location.state.from.pathname;
  }

  return (
    <ProtectedRouteWarn
      actionText={actionText}
      message="Oops! The page you're looking for does not exist or you do not have access."
      onPrimaryAction={redirectUser}
      onReturnClick={goBack}
      returnClassName="action-wrapper"
      returnText={
        !previousPathname &&
        !isExternal && (
          <>
            <span className="icon-ic-arrow-back" />
            <span className="margin-left--m">Take me back to the last page</span>
          </>
        )
      }
      title="Error 404"
    />
  );
};

const mapDispatchToProps = {
  onRemoveNotFoundError: removeNotFoundError,
};

export default connect(null, mapDispatchToProps)(NotFound404);
