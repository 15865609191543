import React from 'react';

import type { InputLabelProps } from './InputLabel.types';

/**
 * Renders label for input component
 */
const InputLabel = ({
  hideLabel,
  isRequired,
  label,
  name,
  placeholder,
  value,
}: InputLabelProps): React.ReactElement => {
  if (hideLabel || (!label && !value)) {
    return null;
  }

  const labelValue = label || placeholder;

  return (
    <label htmlFor={name}>
      {labelValue}
      {isRequired && <span>{' *'}</span>}
    </label>
  );
};

export default InputLabel;
