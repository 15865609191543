import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTaxFormOptions } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.context';
import {
  taxCertificationType,
  TaxFormModel,
  taxFormType,
} from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';

import { UserType } from 'enums/user';

import { isCompanyTypeBusiness } from 'helpers/currentCompany';

import RenderRadioGroup from '../RenderRadioGroup';
import RenderSelect from '../RenderSelect';
import { Group, GroupSlot } from '../styles';

/**
 * Tax form field group component. It's a base for any tax form (W-9, W-8BEN and W-8BEN-E).
 * Depending on the selected userType here, we later decide in what format do we want to
 * render the tax form.
 */
const FieldGroupBase = (): JSX.Element => {
  const { watch, setValue, resetField } = useFormContext<TaxFormModel>();
  const { countries, showCompanyTypeField, formType } = useTaxFormOptions();

  const currentCompanyType = watch('companyType');
  const isBusiness = isCompanyTypeBusiness(currentCompanyType);

  const userTypeOptions = [
    {
      optionText: 'I represent a business',
      subText: 'e.g. Sole proprietorship, Corporation',
      value: UserType.BUSINESS,
    },
    {
      optionText: 'I am an individual',
      subText: 'e.g. Contractor or freelancer',
      value: UserType.PERSONAL,
    },
  ];

  const countryFieldName = isBusiness ? 'countryOfIncorporationOrOrganization' : 'countryOfCitizenship';
  const countryPlaceholder = isBusiness
    ? 'Select country of incorporation or organization'
    : 'Select country of citizenship';

  useEffect(() => {
    const subscription = watch(({ companyType }, { name, type }) => {
      // We only want to react if the type of event is change and the affected
      // field is companyType
      if (name === 'companyType' && type === 'change' && formType === taxFormType.Enum.W8) {
        // If the new companyType value is UserType.BUSINESS, we want to statically
        // set the certificationType to "uploadSignedPDF" as that is the only
        // option when dealing with W-8BEN-E forms
        if (isCompanyTypeBusiness(companyType)) {
          setValue('certificationType', taxCertificationType.Enum.uploadSignedPDF);
        } else {
          // Otherwise, if the new companyType is UserType.PERSONAL, we want to reset the
          // certificationType field to its default value
          resetField('certificationType');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, resetField, formType]);

  return (
    <Group>
      {showCompanyTypeField && (
        <GroupSlot size={6}>
          <RenderRadioGroup name="companyType" options={userTypeOptions} />
        </GroupSlot>
      )}
      {formType === taxFormType.Enum.W8 && (
        <GroupSlot size={6}>
          <RenderSelect
            key={countryFieldName}
            label="Country"
            name={countryFieldName}
            options={countries}
            placeholder={countryPlaceholder}
          />
        </GroupSlot>
      )}
    </Group>
  );
};

export default FieldGroupBase;
