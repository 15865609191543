import { AuthTokenResponse } from 'interfaces/auth';

export enum QuickswitchActionType {
  FETCH_MEMBERSHIP_LIST_REQUEST = 'FETCH_MEMBERSHIP_LIST_REQUEST',
  FETCH_MEMBERSHIP_SEARCH_REQUEST = 'FETCH_MEMBERSHIP_SEARCH_REQUEST',
  UPDATE_MEMBERSHIP_LIST = 'UPDATE_MEMBERSHIP_LIST',
  FETCH_MEMBERSHIP_REQUEST_ERROR = 'FETCH_MEMBERSHIP_REQUEST_ERROR',
  SWITCH_TO_BRANDED_SUBDOMAIN = 'SWITCH_TO_BRANDED_SUBDOMAIN',
  SWITCH_MEMBERSHIP_REQUEST = 'SWITCH_MEMBERSHIP_REQUEST',
  OPEN_MODAL = 'OPEN_QUICKSWITCH_MODAL',
  CLOSE_MODAL = 'CLOSE_QUICKSWITCH_MODAL',
  OPEN_ERROR_MODAL = 'OPEN_QUICKSWITCH_ERROR_MODAL',
  CLOSE_ERROR_MODAL = 'CLOSE_QUICKSWITCH_ERROR_MODAL',
  RE_LOGIN = 'RE_LOGIN',
  RE_LOGIN_SUCCESS = 'RE_LOGIN_SUCCESS',
  RE_LOGIN_ERROR = 'RE_LOGIN_ERROR',
  REDIRECT_TO_SSO_LOGIN = 'REDIRECT_TO_SSO_LOGIN',
  SWITCH_RESET_STATE = 'SWITCH_RESET_STATE',
  SET_SEARCH_TERM = 'SET_SEARCH_TERM',
  SET_TOTAL_NUMBER_OF_MEMBERSHIPS = 'SET_TOTAL_NUMBER_OF_MEMBERSHIPS',
}

export interface Membership {
  id: string;
  companyName: string;
  companyNamespace: string;
  isSSORequired: boolean;
}

interface SwitchMembershipPayload {
  membershipId: string;
}

interface SearchMembershipPayload {
  searchTerm: string;
}

interface UpdateMembershipListPayload {
  memberships: Membership[];
}

interface SwitchToBrandedSubdomainPayload {
  meta: AuthTokenResponse;
}

interface SetTotalNumberOfMembershipsPayload {
  totalNumberOfMemberships: number;
}

interface RedirectToSSOLoginPayload {
  companyNamespace: string;
}

type QuickswitchActionPayload =
  | SwitchMembershipPayload
  | SwitchToBrandedSubdomainPayload
  | SearchMembershipPayload
  | UpdateMembershipListPayload
  | SetTotalNumberOfMembershipsPayload
  | RedirectToSSOLoginPayload;

export interface QuickswitchActionCreator {
  type: QuickswitchActionType;
  payload?: QuickswitchActionPayload;
}
