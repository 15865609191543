import classNames from 'classnames';
import React from 'react';

import { Icon, IconNames } from 'components';

import { margin, sizes } from 'constants/styles';

import type { SkipButtonProps } from './SkipButton.types';

/**
 * SkipButton - used for skipping matching/merging of a partnership
 */
const SkipButton = ({ className, isDisabled, onClick }: SkipButtonProps): React.ReactElement => {
  const linkClassName = classNames({
    'align-items--center': true,
    'dark-grey': true,
    'display--flex': true,
    'force-underline': true,
    'hover-primary': true,
    disabled: isDisabled,
    [className]: className,
  });

  return (
    <a className={linkClassName} onClick={onClick} role="presentation">
      <Icon icon={IconNames.STEP_FORWARD} marginRight={margin.small} size={sizes.iconSizes.LARGE} />
      <p className="font-xs remove-margin-bottom">Skip</p>
    </a>
  );
};

export default SkipButton;
