import React from 'react';

import { TableCellTextWithSubtext } from 'complexComponents/Table';

import { TitleWithDetails, TitleWithSubtitle } from 'components';

import { typography } from 'constants/styles';

import { getMembershipFullName, getMembershipPhoneNumber } from 'helpers/memberships';

import type { ContactTitleWithSubtitleProps } from './ContactTitleWithSubtitle.types';

const ContactTitleWithSubtitle = ({
  dataFullStory,
  isContactListItem,
  partnershipMember,
}: ContactTitleWithSubtitleProps): JSX.Element => {
  const { email } = partnershipMember;
  let title = getMembershipFullName(partnershipMember);

  // determine subtitle text
  let subtitleText = email;
  if (!title && !email && isContactListItem) {
    subtitleText = getMembershipPhoneNumber(partnershipMember);
  } else if (title && !email) {
    subtitleText = '(No email added)';
  }

  // determine title
  if (!title && !email) {
    title = '(No name/email added)';
  } else if (!title) {
    title = '(No name added)';
  }

  if (isContactListItem) {
    return (
      <TitleWithSubtitle
        dataFullStory={dataFullStory}
        subtitles={[subtitleText]}
        titles={[<TitleWithDetails dataFullStory={dataFullStory} key="unique" main={title} />]}
      />
    );
  }

  return (
    <TableCellTextWithSubtext
      subtext={subtitleText}
      text={
        <TitleWithDetails
          dataFullStory={dataFullStory}
          main={title}
          mainProps={{ color: typography.TextColor.BLUE_DARK }}
        />
      }
    />
  );
};

export default ContactTitleWithSubtitle;
