export enum SSOActionType {
  CLOSE_DISABLE_MODAL = 'CLOSE_DISABLE_MODAL',
  CLOSE_SETUP_FORM_MODAL = 'CLOSE_SETUP_FORM_MODAL',
  CLOSE_SETUP_SPLASHSCREEN_MODAL = 'CLOSE_SSO_SETUP_SPLASHSCREEN_MODAL',
  DISABLE_SSO = 'DISABLE_SSO',
  FETCH_COMPANY_SETTINGS_SSO = 'FETCH_COMPANY_SETTINGS_SSO',
  FETCH_COMPANY_SETTINGS_SSO_BY_NAMESPACE = 'FETCH_COMPANY_SETTINGS_SSO_BY_NAMESPACE',
  OPEN_DISABLE_MODAL = 'OPEN_DISABLE_MODAL',
  OPEN_SETUP_FORM_MODAL_WITH_PROVIDER = 'OPEN_SETUP_FORM_MODAL_WITH_PROVIDER',
  OPEN_SETUP_SPLASHSCREEN_MODAL = 'OPEN_SSO_SETUP_SPLASHSCREEN_MODAL',
  REMOVE_SSO_SETTINGS = 'REMOVE_SSO_SETTINGS',
  SET_SETTINGS_PROVIDER = 'SET_SETTINGS_PROVIDER',
  SUBMIT_SSO_SETTINGS = 'SUBMIT_SSO_SETTINGS',
  SUBMIT_UPDATE_SSO_SETTINGS = 'SUBMIT_UPDATE_SSO_SETTINGS',
}

export enum SSOProviders {
  INVALID = 'invalid',
  GOOGLE_OAUTH2 = 'google-oauth2',
  SAML = 'saml',
}

export enum SSOSetupOrEdit {
  SETUP = 'SETUP',
  EDIT = 'EDIT',
}
