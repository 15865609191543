import React from 'react';

import ExternalComponents from '../ExternalComponents';

import { ExternalColumnHeaderProps } from './ExternalColumnHeader.types';

/**
 * ExternalColumnHeader displays header along with option components.
 * @param {ExternalColumnHeaderProps} props
 * @returns {StatelessComponent}
 */
const ExternalColumnHeader: React.FC<ExternalColumnHeaderProps> = ({ text, ...rest }) => (
  <div className="external-column--header-container">
    <ExternalComponents className="external-column--header-components" {...rest} />
    {text && <div className="external-column--header-title">{text}</div>}
  </div>
);

ExternalColumnHeader.defaultProps = {
  components: undefined,
  text: undefined,
};

export default ExternalColumnHeader;
