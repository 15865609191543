import React from 'react';
import { useSelector } from 'react-redux';

import { createFieldSubmitErrorSelector } from 'selectors/createFormSelectors';

/**
 * Simple hook for selecting form field submit error(s)
 * @param formName - The name of the Redux-Form
 * @param fieldName - The name of the field for which we want to select the submit errors
 */
const useFieldSubmitError = (formName: string, fieldName: string): string[] | null => {
  /**
   * We must first create a selector for the form field submit error value
   */
  const fieldErrorSelector = React.useMemo(
    () => createFieldSubmitErrorSelector(formName, fieldName),
    [formName, fieldName],
  );

  /**
   * We then use that selector to grab the field submit error (if it exists)
   */
  const error: string[] | null = useSelector(fieldErrorSelector);

  return error;
};

export default useFieldSubmitError;
