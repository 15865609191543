import type { ItemKinds } from 'enums/items';
import type { ItemDateScheduledTypes } from 'enums/temporal';

import type { BillingCodeData } from 'interfaces/billing';
import type { PartnershipFundingAccount } from 'interfaces/partnerFundingAccount';

/**
 * Warning types
 */
export enum WarningTypes {
  AFTER_CUTOFF = 'AFTER_CUTOFF',
  RTP_ELIGIBILITY = 'RTP_ELIGIBILITY',
  BALANCE_TOO_LOW = 'BALANCE_TOO_LOW',
  AMOUNT_ABOVE_LIMIT = 'AMOUNT_ABOVE_LIMIT',
  MAX_FEE = 'MAX_FEE',
}

export type ShowWarning = { [key in WarningTypes]: boolean } | undefined;

export interface Options {
  itemKind: ItemKinds;
  dateScheduledType: ItemDateScheduledTypes;
  partnershipFundingAccounts: PartnershipFundingAccount[];
  paymentTotalAmount: number;
  paymentMaxIndividualAmount: number;
  balanceAmount: number;
  currentBillingData: BillingCodeData;
}
export interface UseItemWarnings {
  hasBlockerWarnings: boolean;
  showWarning: ShowWarning;
}
