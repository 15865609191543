import { createReducer } from '@reduxjs/toolkit';

import {
  setW8FormIsSubmittingState,
  setW8FormIsValidState,
  setW9FormIsSubmittingState,
  setW9FormIsValidState,
} from 'actions/partnershipRequests';

type SimpleFormState = {
  isValid: boolean;
  isSubmitting: boolean;
};

type PartnershipRequestFormState = {
  w8: SimpleFormState;
  w9: SimpleFormState;
};

const initialState: PartnershipRequestFormState = {
  w8: {
    isValid: false,
    isSubmitting: false,
  },
  w9: {
    isValid: false,
    isSubmitting: false,
  },
};

const partnershipRequestFormReducer = createReducer<PartnershipRequestFormState>(initialState, (builder) =>
  builder
    .addCase(setW8FormIsSubmittingState, (draft, { payload }) => {
      draft.w8.isSubmitting = payload;
    })
    .addCase(setW8FormIsValidState, (draft, { payload }) => {
      draft.w8.isValid = payload;
    })
    .addCase(setW9FormIsSubmittingState, (draft, { payload }) => {
      draft.w9.isSubmitting = payload;
    })
    .addCase(setW9FormIsValidState, (draft, { payload }) => {
      draft.w9.isValid = payload;
    })
    .addDefaultCase((state) => state),
);

export { initialState };
export default partnershipRequestFormReducer;
