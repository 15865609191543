import { AnyAction } from 'redux';

import { isTabInbox, isTabPayables } from 'helpers/urls';
import { diffArrays, hasLength, intersection } from 'helpers/utility';

import * as types from 'types/tables';

type GenerateCheckboxAction = <Payload>(actions: { select: string; deselect: string }) => (args: {
  isSelect: boolean;
  payload: Payload;
  tab: string;
  meta?: {
    modifierKeys?: {
      shift: boolean;
      alt: boolean;
    };
    throttle?: {
      id: string;
      timeout: number;
    };
  };
}) => AnyAction;

/**
 * Returns correct action type for de/selecting a all table rows on current page
 */
const generateCheckboxAction: GenerateCheckboxAction =
  ({ select, deselect }) =>
  ({ isSelect, tab, payload, meta }) => {
    const base = isTabPayables(tab) || isTabInbox(tab) ? types.TABLE_PAYABLES : types.TABLE_RECEIVEABLES;
    const actionType = isSelect ? select : deselect;
    const type = `${base}/${actionType}`;

    return {
      type,
      payload,
      meta,
    };
  };

export const multiselectCheckboxAction = generateCheckboxAction({
  select: types.SELECT_MULTIPLE_TABLE_ROWS,
  deselect: types.DESELECT_MULTIPLE_TABLE_ROWS,
});

export const singleSelectCheckboxAction = generateCheckboxAction({
  select: types.SELECT_SINGLE_TABLE_ROW,
  deselect: types.DESELECT_SINGLE_TABLE_ROW,
});

/**
 * Returns true if all ids on the current page are selected
 */
export const areAllIdsSelectedFromCurrentPage = (selectedIds: string[], currentPageIds: string[]): boolean =>
  hasLength(selectedIds) && diffArrays(currentPageIds, selectedIds).length === 0;

/**
 * Returns true if some ids on the current page are selected
 */
export const areSomeIdsSelectedFromCurrentPage = (selectedIds: string[], currentPageIds: string[]): boolean =>
  hasLength(intersection(selectedIds, currentPageIds));
