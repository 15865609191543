import { fetchItemsRoutine, submitItemRoutine } from 'actions/routines/item';

import { PaginationSizes } from 'constants/pagination';

import { getPaginationCountFromMeta } from 'helpers/items';

import { SUBMIT_UPLOAD_BILL_SUCCESS } from 'types/bills';
import { FETCH_PARTNERSHIP_ITEMS_SUCCESS } from 'types/partnership';

export const initialState = {
  current: null,
  first: null,
  last: null,
  next: null,
  prev: null,

  count: 0,
  page: 1,
  pages: 1,
  pageSize: PaginationSizes.ITEM_LIST,
};

const itemsPaginationReducer = (state = initialState, action) => {
  const { meta = {} } = action;

  switch (action.type) {
    case SUBMIT_UPLOAD_BILL_SUCCESS:
      return {
        ...state,
        count: state.count + 1,
        pageSize: state.pageSize + 1,
      };

    case fetchItemsRoutine.SUCCESS:
    case FETCH_PARTNERSHIP_ITEMS_SUCCESS:
      return {
        ...state,
        current: action.payload.links.current,
        first: action.payload.links.first,
        last: action.payload.links.last,
        next: action.payload.links.next,
        prev: action.payload.links.prev,

        count: action.payload.meta.pagination.count,
        page: action.payload.meta.pagination.page,
        pages: action.payload.meta.pagination.pages,
        pageSize: action.payload.meta.pagination.pageSize,
      };

    case submitItemRoutine.FULFILL:
      return {
        ...state,
        count: getPaginationCountFromMeta(meta) || state.count,
      };

    default:
      return state;
  }
};

export default itemsPaginationReducer;
