export enum ClearingAccountTypes {
  MONEY_IN = 'moneyIn',
  MONEY_OUT = 'moneyOut',
}

export enum FundingAccountTypes {
  CHECKING = 'checking',
  SAVINGS = 'savings',
}

export enum FundingAccountDirection {
  RECEIVABLES = 'receivables',
  PAYABLES = 'payables',
  RECEIVABLES_AND_PAYABLES = 'receivables_and_payables',
}

export enum FundingAccountProcessingChannels {
  ACH = 'ach',
  CHECK = 'check',
  NONE = 'none',
  RTP = 'rtp',
}

export enum FundingAccountUsableStates {
  NO_CLEARING_MATCH = 'no_clearing_match',
  NO_DELETED = 'no_deleted',
  NO_DISABLED = 'no_disabled',
  NO_LEDGER_MATCH = 'no_ledger_match',
  NO_NOT_VALID = 'no_not_valid',
  YES = 'yes',
}

export enum FundingSourceProviderClasses {
  ADDRESS = 'address',
  BANK = 'bank',
}

export enum FundingSourceProviderStatuses {
  INVALID = 'invalid',
  INVALID_MICRO_DEPOSITS_FAILED = 'invalid_micro_deposits_failed',
  INVALID_MICRO_DEPOSITS_FAILED_VERIFICATION = 'invalid_micro_deposits_failed_verification',
  INVALID_MICRO_DEPOSITS_PENDING = 'invalid_micro_deposits_pending',
  INVALID_MICRO_DEPOSITS_PROCESSED = 'invalid_micro_deposits_processed',
  VALID = 'valid',
}

export enum FundingSourceProviderSubClasses {
  ACH = 'bank_ach',
  ADDRESS = 'address_mailing',
  BALANCE = 'bank_balance',
  CHECK = 'bank_check',
  EXTERNAL_LEDGER = 'external_ledger',
}

export enum FundingCustomerStatuses {
  CANCELED = 'canceled',
  DOCUMENT = 'document',
  DEACTIVATED = 'deactivated',
  EXEMPT = 'exempt',
  INCOMPLETE = 'incomplete',
  PENDING = 'pending',
  RETRY = 'retry',
  SUSPENDED = 'suspended',
  UNVERIFIED = 'unverified',
  VERIFIED = 'verified',
}

export enum FundingRequirementStatusTypes {
  KYC_BENEFIT = 'kyc_benefit',
  KYC_ISSUE = 'kyc_issue',
  KYC_NEEDED = 'kyc_needed',
  KYC_PENDING = 'kyc_pending',
  OKAY = 'okay',
}

export enum TransactionDirection {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
}

export enum PaymentDeliveryOptionText {
  ACH_EXPEDITED = 'Expedited ACH',
}
