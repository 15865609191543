import React, { Children } from 'react';

const withHiddenWhenChildless = <C extends React.ComponentType>(Component: C): C => {
  const ComponentWithHiddenWhenChildless = (props) => {
    const { children, ...restProps } = props;
    const hasChildren = Children.toArray(children).some(Boolean);

    if (!hasChildren) {
      return null;
    }

    return React.createElement(Component, restProps, children);
  };

  const displayName = Component.displayName || Component.name || 'Component';
  ComponentWithHiddenWhenChildless.displayName = `${displayName}WithHiddenWhenChildless`;

  return ComponentWithHiddenWhenChildless as C;
};

export default withHiddenWhenChildless;
