import React from 'react';

import { taxFormEntryType, taxFormType } from './TaxFormRenderer.models';
import type { TaxFormOptions } from './TaxFormRenderer.types';

export const TaxFormOptionsContext = React.createContext<TaxFormOptions>({
  countries: [],
  entryType: taxFormEntryType.Enum.external,
  formType: taxFormType.Enum.W8, // set something as default to avoid errors, this value is overriden in the TaxFormRenderer component anyhow
  isSignatureAllowed: true,
  showCompanyTypeField: true,
});

export const useTaxFormOptions = (): TaxFormOptions => React.useContext(TaxFormOptionsContext);
