import React from 'react';

import { CurrencyAmountTextPlain, FlexCol } from 'components';

import { getItemPaymentTerms } from 'helpers/items';
import { hasLength } from 'helpers/utility';

import { ExternalBlockItem } from 'modules/external/ExternalBlocksSection';
import {
  ExternalReceiptAttachments,
  ExternalReceiptItemEntry,
} from 'modules/external/externalReceiptV2/ExternalReceiptSingleItem/components';

import { ExternalReceiptPayableItemEntriesProps } from './ExternalReceiptPayableItemEntries.types';

/**
 * Component rendering External Receipt Item entries for item kind payable
 */
const ExternalReceiptPayableItemEntries: React.FC<ExternalReceiptPayableItemEntriesProps> = ({
  amount,
  attachments,
  currencyCode,
  partnerReference,
  paymentTerms,
  reference,
}) => (
  <ExternalBlockItem>
    <ExternalReceiptItemEntry label="Invoice No." value={reference} />
    <ExternalReceiptItemEntry
      label="Amount"
      value={<CurrencyAmountTextPlain amount={amount} currencyCode={currencyCode} />}
    />
    <ExternalReceiptItemEntry label="Reference" value={partnerReference} />
    <ExternalReceiptItemEntry label="Payment terms" value={getItemPaymentTerms(paymentTerms)} />
    {hasLength(attachments) && (
      <FlexCol className="margin-top--m-large">
        <ExternalReceiptAttachments attachments={attachments} />
      </FlexCol>
    )}
  </ExternalBlockItem>
);

export default ExternalReceiptPayableItemEntries;
