import { colors } from 'constants/styles';

import { errorStyles } from '../../../constants/passwordRequirementParameters';

export const getIconColor = (isValid: boolean, styling: string): string => {
  if (isValid) {
    return colors.colorGreenBoldHex;
  }

  return styling === errorStyles.HIGHLIGHTED ? colors.colorRedBoldHex : colors.colorGreyMedHex;
};
