import React from 'react';

import { ButtonV2 } from 'components';

import { ButtonSize } from 'constants/button';

import { SaveButtonProps, KeepWorkingButtonProps } from './ModalBodyButtons.types';

export const ModalSaveButton: React.FC<SaveButtonProps> = ({ onSaveChanges }) => (
  <ButtonV2 id="save-button" onClick={onSaveChanges} size={ButtonSize.MEDIUM} type="button">
    Save changes
  </ButtonV2>
);

export const ModalKeepWorkingButton: React.FC<KeepWorkingButtonProps> = ({ onClose }) => (
  <ButtonV2 id="save-button" onClick={onClose} size={ButtonSize.MEDIUM} type="button">
    Keep working
  </ButtonV2>
);
