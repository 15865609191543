import { createSelector } from 'reselect';

import { QuickswitchState } from 'reducers/quickswitchReducer';

const getState = (state) => state.quickswitch;

export const loadingMembershipsListSelector = createSelector(
  [getState],
  (state: QuickswitchState) => state.loadingMembershipsList,
);

export const membershipsSelector = createSelector([getState], (state: QuickswitchState) => state.memberships);

export const isModalOpenSelector = createSelector([getState], (state: QuickswitchState) => state.isModalOpen);

export const companyNameSelector = createSelector([getState], (state: QuickswitchState) => state.companyName);

export const isErrorModalOpenSelector = createSelector([getState], (state: QuickswitchState) => state.isErrorModalOpen);

export const searchTermSelector = createSelector([getState], (state: QuickswitchState) => state.searchTerm);

export const totalNumberOfMembershipsSelector = createSelector(
  [getState],
  (state: QuickswitchState) => state.totalNumberOfMemberships,
);
