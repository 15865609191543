import { Skeleton, TruncatableText } from '@routable/components';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ExistingItem } from 'context';
import { PurchaseOrderOption } from 'hooks/purchaseOrders/purchaseOrder.models';

import { DiscrepanciesObjectKeyMap } from 'constants/discrepancies';

import { parseCurrency } from 'helpers/numbers';

import { useDiscrepancyContext, useRefreshDiscrepanciesContext } from 'hooks/context';
import { usePurchaseOrderItemData } from 'hooks/purchaseOrders';

import { createItemFormPurchaseOrderSelector } from 'selectors/forms';

const keyMap = {
  unbilled_amount: 'amount',
  unbilled_quantity: 'quantity',
  description: 'description',
  item: 'item.name',
  rate: 'unit_price',
  account: 'account.name',
} as const;

const POLineItemsRow = ({
  CellComponent,
  fieldProps,
  fixCellWidths,
  getHeaderOrBodyCellProps,
  getTableSectionFieldSize,
  headersConfigById,
  isOnDetailsPage,
  row,
  rowValues,
}) => {
  const item = useContext<Item>(ExistingItem);

  const purchaseOrder = useSelector<ReduxFormState, PurchaseOrderOption>(createItemFormPurchaseOrderSelector);
  // if on bill details, get purchaseOrder id from item.purchaseOrders[0].ledgerRef
  const purchaseOrderId = item?.id ? item.purchaseOrders[0].ledgerRef : purchaseOrder?.value;
  const { data: poData, isFetching: isFetchingPoData } = usePurchaseOrderItemData(purchaseOrderId, item?.ledgerRef);
  const poLineItems = poData?.data?.attributes?.line_items || [];

  const { hasDiscrepancyForColumn } = useDiscrepancyContext();
  const { isRefreshingDiscrepancies } = useRefreshDiscrepanciesContext();

  // camelCased is for bill details, because when we fetch the item we camelcase the response
  const purchaseOrderLineItemId = isOnDetailsPage
    ? rowValues?.purchaseOrderLineItemId
    : rowValues?.purchase_order_line_item_id;
  const lineItemId = rowValues?.id || purchaseOrderLineItemId;
  const poLineItem = poLineItems.find((lineItem) => lineItem.purchase_order_line_item_id === purchaseOrderLineItemId);

  if (!isFetchingPoData && (!rowValues || !poLineItem)) {
    return (
      <CellComponent
        className={clsx('w-full', {
          'bg-grey-5': !isOnDetailsPage,
        })}
        isOnDetailsPage={isOnDetailsPage}
      >
        <div className="ledger-form-field w-full">
          <div className="po-cell w-full">
            <div className="py-3 h-full flex align-middle">Non-PO line</div>
          </div>
        </div>
      </CellComponent>
    );
  }

  const poLineItemMapped = Object.keys(fieldProps).reduce((prev, key) => {
    if (key === 'item') {
      return { ...prev, [key]: poLineItem?.[key]?.name };
    }

    if (key === 'account') {
      return {
        ...prev,
        [key]: `${poLineItem?.[key]?.code ? `${poLineItem?.[key]?.code} ` : ''}${poLineItem?.[key]?.name}`,
      };
    }

    if (key === 'rate' || key === 'unbilled_amount') {
      const amount = parseFloat(String(poLineItem?.[keyMap[key]])) < 0 ? 0 : poLineItem?.[keyMap[key]];

      return {
        ...prev,
        [key]: `${parseCurrency(amount, { symbol: '' })} ${purchaseOrder?.currencyCode || item?.currencyCode}`,
      };
    }

    if (key === 'unbilled_quantity') {
      const quantity = parseFloat(String(poLineItem?.[keyMap[key]])) < 0 ? 0 : poLineItem?.[keyMap[key]];

      return {
        ...prev,
        [key]: quantity,
      };
    }

    return { ...prev, [key]: poLineItem?.[keyMap[key]] };
  }, {});

  return (
    <>
      {row.cells.map((cell, cellIdx) => {
        const headerConfig = headersConfigById[cell.column.id];
        if (!headerConfig.isVisible) {
          return null;
        }

        const fieldSize = getTableSectionFieldSize(fieldProps[cell.column.id]);
        const bodyCellProps = fixCellWidths(getHeaderOrBodyCellProps(fieldSize));
        const value = poLineItemMapped[cell.column.id];

        const hasDiscrepancy = hasDiscrepancyForColumn(lineItemId, DiscrepanciesObjectKeyMap[cell.column.id]);
        const showDiscrepancy = !isRefreshingDiscrepancies && hasDiscrepancy;

        return (
          <CellComponent
            key={`table-cell-${cellIdx + 1}`}
            {...cell.getCellProps()}
            {...bodyCellProps}
            className={clsx(bodyCellProps.className, {
              'bg-grey-5': !isOnDetailsPage,
            })}
            isOnDetailsPage={isOnDetailsPage}
          >
            {isFetchingPoData ? (
              <Skeleton className="flex items-center justify-center h-[40px] w-full" shape="round" />
            ) : (
              <div
                className={clsx('ledger-form-field', {
                  'justify-end': fieldProps[cell.column.id]?.minDecimalScale > 0,
                  'justify-center': isOnDetailsPage && cell.column.id === 'unbilled_quantity',
                })}
              >
                {!isOnDetailsPage && showDiscrepancy && (
                  <div
                    className="absolute border border-gold-40 rounded-[13px] inset-y-[6px] inset-x-[4px]"
                    data-testid="discrepancy-border"
                  />
                )}

                <div
                  className={clsx('po-cell min-w-0 relative', {
                    'text-grey-60': value === undefined || value === null,
                    'min-w-[16px] text-center': isOnDetailsPage && cell.column.id === 'unbilled_quantity',
                  })}
                >
                  {isOnDetailsPage && showDiscrepancy && (
                    <div
                      className="absolute border border-gold-40 rounded-[13px] inset-y-[-6px] inset-x-[-8px]"
                      data-testid="discrepancy-border"
                    />
                  )}
                  <TruncatableText position="top" text={value ?? ''} />
                </div>
              </div>
            )}
          </CellComponent>
        );
      })}
    </>
  );
};

export default POLineItemsRow;
