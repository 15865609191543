export enum AddTaxFormButtonDisplayType {
  /**
   * Large, neutral button without any icon
   */
  LARGE = 'large',
  /**
   * Small, neutral button without an icon
   */
  COMPACT = 'compact',
}
