import PropTypes from 'prop-types';
import React from 'react';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import { TooltipMUI, RestrictedPermissionContent } from 'components';

import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { getClassNames } from 'helpers/ui';

/**
 * A tooltip to show if the RCTM does not have adequate permissions to take an action.
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {ObjectMaybe} [props.customization={}]
 * @param {function} props.onNavigate
 * @returns {StatelessComponent}
 * @deprecated use tooltip from `@routable/components`
 */
const RestrictedPermissionTooltip = ({ children, customization, onNavigate, ...props }) => (
  <TooltipMUI
    arrow
    variant="light"
    {...props}
    padding={TooltipPadding.LARGE}
    placement={TooltipPlacement.TOP}
    title={<RestrictedPermissionContent onNavigate={onNavigate} />}
    {...customization}
    className={getClassNames(customization, {
      'restricted-permission--tooltip': true,
    })}
  >
    {children}
  </TooltipMUI>
);

RestrictedPermissionTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  customization: PropTypes.shape(),
  onNavigate: PropTypes.func.isRequired,
};

RestrictedPermissionTooltip.defaultProps = {
  customization: {},
};

export default RestrictedPermissionTooltip;
