import { RoutableObject } from '@routable/framework';
import omit from 'lodash/omit';

import { LineItemStyles } from 'constants/lineItems';

import { getDefaultLineItem, getLineItemsPathFromStyle } from 'helpers/lineItems';

export const isLineItemSimilarToDefault = (targetLineItem: LineItem, defaultLineItem: LineItem): boolean => {
  const comparisonResults = {};

  if (!targetLineItem || !defaultLineItem) {
    return false;
  }

  for (const [defaultLineItemEntryKey, defaultLineItemEntryValue] of Object.entries(defaultLineItem)) {
    const targetLineItemEntryValue = targetLineItem[defaultLineItemEntryKey];

    const isTargetLineItemEntryValueEmptyStringOrZero =
      targetLineItemEntryValue === '' || targetLineItemEntryValue === 0;
    if (defaultLineItemEntryValue === '' && isTargetLineItemEntryValueEmptyStringOrZero) {
      comparisonResults[defaultLineItemEntryKey] = true;
    } else if (defaultLineItemEntryValue === targetLineItemEntryValue) {
      comparisonResults[defaultLineItemEntryKey] = true;
    } else if (defaultLineItemEntryKey === 'id') {
      // Marking the "id" field as valid since it's an internal generated uuid for non-submitted line items
      // and not filled by the user
      comparisonResults[defaultLineItemEntryKey] = true;
    } else {
      comparisonResults[defaultLineItemEntryKey] = false;
    }
  }

  return Object.values(comparisonResults).every((x) => x === true);
};

export const transformLineItemToOtherSectionLineItem = ({
  lineItems,
  lineItemStyle,
  viewModelManager,
}: RoutableObject): RoutableObject => {
  const isStyleItem = lineItemStyle === LineItemStyles.ITEM;
  const transformedLineItems = [];

  const tempLineItems = Array.isArray(lineItems)
    ? JSON.parse(JSON.stringify(lineItems))
    : [JSON.parse(JSON.stringify(lineItems))];

  const currentSectionDefaultLineItem: RoutableObject = getDefaultLineItem(
    viewModelManager,
    getLineItemsPathFromStyle(lineItemStyle),
  );
  const otherSectionDefaultLineItem: RoutableObject = getDefaultLineItem(
    viewModelManager,
    getLineItemsPathFromStyle(isStyleItem ? LineItemStyles.ACCOUNT : LineItemStyles.ITEM),
  );

  for (let rowIdx = 0; rowIdx < tempLineItems.length; rowIdx += 1) {
    const lineItem = tempLineItems[rowIdx];

    // Only set these fields to an empty string if they are already present
    if ('purchase_order_id' in lineItem) {
      // Unlink po line item if linked -> set it as empty string instead of deleting/null to EXACTLY match the default line item object
      lineItem.purchase_order_id = '';
      lineItem.purchase_order_line_item_id = '';
    }

    const isIncomingLineItemAnEmptyRow = isLineItemSimilarToDefault(lineItem, currentSectionDefaultLineItem);

    if (isIncomingLineItemAnEmptyRow) {
      transformedLineItems.push(otherSectionDefaultLineItem);
    } else {
      if (isStyleItem) {
        transformedLineItems.push({
          ...otherSectionDefaultLineItem,
          ...lineItem,
          amount: lineItem.amount != null ? lineItem.amount.toString() : otherSectionDefaultLineItem.amount,
          unit_price:
            lineItem.unit_price != null ? lineItem.unit_price.toString() : otherSectionDefaultLineItem.unit_price,
          style: LineItemStyles.ACCOUNT,
        });
      } else {
        const lineAmount = !Number.isNaN(parseFloat(lineItem.amount)) ? lineItem.amount : 0;
        const lineUnitPrice = !Number.isNaN(parseFloat(lineItem.unit_price)) ? lineItem.unit_price : lineAmount;

        transformedLineItems.push({
          ...otherSectionDefaultLineItem,
          ...lineItem,
          amount: lineAmount,
          amount_pre: lineAmount,
          unit_price: lineUnitPrice,
          quantity: Number.isNaN(parseFloat(lineItem.quantity)) ? 1 : parseFloat(lineItem.quantity),
          amount_tax: lineItem.amount_tax == null ? otherSectionDefaultLineItem.amount_tax : lineItem.amount_tax,
          style: LineItemStyles.ITEM,
        });
      }
    }
  }

  return transformedLineItems;
};

export const handleClone = (data: unknown): unknown => {
  if (Array.isArray(data)) {
    return data
      .filter((item) => {
        if (typeof item === 'object' && item !== null) {
          return Object.entries(item).some(([key, value]) => {
            if (key === 'quantity' || key === 'style') {
              return false;
            }

            return !!value;
          });
        }

        return !!item;
      })
      .map((lineItem) => omit(lineItem, 'style'));
  }

  return data;
};
