import React from 'react';

import CrossBorderPaymentsNotEnabledTooltip from 'complexComponents/tooltipTypes/CrossBorderPaymentsNotEnabledTooltip';
import CurrencyNotEnabledOnLedgerTooltip from 'complexComponents/tooltipTypes/CurrencyNotEnabledOnLedgerTooltip';
import CurrencyNotSupportedByPlatformTooltip from 'complexComponents/tooltipTypes/CurrencyNotSupportedByPlatformTooltip';
import LimitedSendingNonBaseCurrencyPaymentsTooltip from 'complexComponents/tooltipTypes/LimitedSendingNonBaseCurrencyPaymentsTooltip';
import PreventInternationalCustomerMatchTooltip from 'complexComponents/tooltipTypes/PreventInternationalCustomerMatchTooltip';

import { MultiLineOptionForSearchCompanies } from 'components/selectV2/components';

import { getCustomerOrVendorOrBothTextByPartnership } from 'helpers/partnerships';

import { checkPartnershipEligibilityForSearchCompanies } from '../../helpers/logic';
import { getDetailsTextForSearchCompanies } from '../../helpers/text';

import type { MultiLineOptionWithTooltipForSearchCompaniesProps } from './MultiLineOptionWithTooltipForSearchCompanies.types';

/**
 * Renders multi line option for search companies select wrapped in a conditional tooltip
 */
const MultiLineOptionWithTooltipForSearchCompanies = ({
  companyIntegrationSettings,
  companySettings,
  data,
  formPartnershipType,
  ledger,
  ledgerCurrencies,
  location,
  supportedCurrencies,
  ...rest
}: MultiLineOptionWithTooltipForSearchCompaniesProps): React.ReactElement => {
  const {
    isCrossBorderPaymentsNotEnabledTooltipShown,
    isCurrencyNotSupportedByLedgerTooltipShown,
    isCurrencyNotSupportedByPlatformTooltipShown,
    isLimitSendingPaymentsTooltipShown,
    isPreventInternationalCustomerMatchTooltipShown,
  } = checkPartnershipEligibilityForSearchCompanies({
    companyIntegrationSettings,
    companySettings,
    formPartnershipType,
    ledger,
    ledgerCurrencies,
    location,
    partnership: data,
    supportedCurrencies,
  });

  const partnershipType = getCustomerOrVendorOrBothTextByPartnership(data);
  const detailsText = getDetailsTextForSearchCompanies({
    isCrossBorderPaymentsNotEnabledTooltipShown,
    isCurrencyNotSupportedByLedgerTooltipShown,
    isCurrencyNotSupportedByPlatformTooltipShown,
    isLimitSendingPaymentsTooltipShown,
    isPreventInternationalCustomerMatchTooltipShown,
    ledger,
    partnership: data,
  });

  return (
    <LimitedSendingNonBaseCurrencyPaymentsTooltip isShown={isLimitSendingPaymentsTooltipShown} ledger={ledger}>
      <CrossBorderPaymentsNotEnabledTooltip isShown={isCrossBorderPaymentsNotEnabledTooltipShown}>
        <CurrencyNotEnabledOnLedgerTooltip
          currencyCode={data.currencyCodePartner}
          isShown={isCurrencyNotSupportedByLedgerTooltipShown}
          ledgerName={ledger.name}
          partnershipType={partnershipType}
        >
          <CurrencyNotSupportedByPlatformTooltip
            currencies={data.ledgerCurrencies || []}
            isShown={isCurrencyNotSupportedByPlatformTooltipShown}
            partnershipType={partnershipType}
          >
            <PreventInternationalCustomerMatchTooltip isShown={isPreventInternationalCustomerMatchTooltipShown}>
              <MultiLineOptionForSearchCompanies
                {...rest}
                data={{
                  ...data,
                  details: data.isDisabled && detailsText,
                }}
              />
            </PreventInternationalCustomerMatchTooltip>
          </CurrencyNotSupportedByPlatformTooltip>
        </CurrencyNotEnabledOnLedgerTooltip>
      </CrossBorderPaymentsNotEnabledTooltip>
    </LimitedSendingNonBaseCurrencyPaymentsTooltip>
  );
};

export default MultiLineOptionWithTooltipForSearchCompanies;
