import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  openQuickswitchModal,
  closeQuickswitchModal,
  executeQuickswitchSwitchMembership,
  fetchQuickswitchMembershipList,
  fetchQuickswitchMembershipSearch,
  setSearchTerm,
} from 'actions/quickswitchActions';

import { currentCompanyNameSelector } from 'selectors/currentCompanySelectors';
import {
  loadingMembershipsListSelector,
  membershipsSelector,
  isModalOpenSelector,
  isErrorModalOpenSelector,
  searchTermSelector,
  totalNumberOfMembershipsSelector,
} from 'selectors/quickswitchSelectors';

import Quickswitch, { QuickswitchDispatchProps } from './Quickswitch';
import { QuickswitchModalStateProps } from './QuickswitchModal';

export const mapStateToProps = createStructuredSelector({
  currentCompanyName: currentCompanyNameSelector,
  loadingMembershipsList: loadingMembershipsListSelector,
  memberships: membershipsSelector,
  totalNumberOfMemberships: totalNumberOfMembershipsSelector,
  isModalOpen: isModalOpenSelector,
  isErrorModalOpen: isErrorModalOpenSelector,
  searchTerm: searchTermSelector,
});

export const mapDispatchToProps: QuickswitchDispatchProps = {
  openModal: openQuickswitchModal,
  closeModal: closeQuickswitchModal,
  executeSwitchMembership: executeQuickswitchSwitchMembership,
  fetchMembershipList: fetchQuickswitchMembershipList,
  fetchMembershipSearch: fetchQuickswitchMembershipSearch,
  setSearchTerm,
};

export default connect<QuickswitchModalStateProps, QuickswitchDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Quickswitch);
