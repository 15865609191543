import { FetchServiceResponse } from 'interfaces/fetchService';
import { EditSettingsPayload, SetupSettingsPayload, SSOSettingsState } from 'interfaces/sso';

import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import { getSSOSettingsDetailEndpoint, ssoSettingsBaseEndpoint } from 'services/api/ssoEndpoints';

/**
 * API to Disable SSO for a company
 */
export const disableSSO = async (ssoSettingID: SSOSettingsState['id'], options = {}): Promise<FetchServiceResponse> => {
  const config = {
    ...options,
    endpoint: `${getSSOSettingsDetailEndpoint(ssoSettingID)}disable/`,
    method: 'POST',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * API to Enable SSO for a company
 */
export const enableSSO = async (ssoSettingID: SSOSettingsState['id'], options = {}): Promise<FetchServiceResponse> => {
  const config = {
    ...options,
    endpoint: `${getSSOSettingsDetailEndpoint(ssoSettingID)}enable/`,
    method: 'POST',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Create SSO settings
 */
export const submitSSOSettings = async (payload: SetupSettingsPayload, options = {}): Promise<FetchServiceResponse> => {
  const config = {
    ...options,
    endpoint: ssoSettingsBaseEndpoint,
    method: 'POST',
    payload: payloadToJSONAPI(payload, 'CompanySettingsSSO'),
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Update SSO settings
 */
export const submitUpdateSSOSettings = async (
  payload: EditSettingsPayload,
  options = {},
): Promise<FetchServiceResponse> => {
  const config = {
    ...options,
    endpoint: getSSOSettingsDetailEndpoint(payload.id),
    method: 'PATCH',
    payload: payloadToJSONAPI(payload, 'CompanySettingsSSO'),
    requireAuth: true,
  };
  return FetchService.request(config);
};
