import { Tooltip } from '@routable/gross-ds';
import React from 'react';

import { Row } from 'components';

import { useOverflow } from 'hooks';

import { EmptyState, InvoiceButton, InvoiceLink, TextContent } from './components';
import type { InvoiceListTextProps } from './InvoiceListText.types';

import './InvoiceListText.scss';

/**
 * Text-only table cell in the list of invoices imported from ledger.
 */
const InvoiceListText = ({
  buttonLabel,
  children,
  dataKey,
  dataFullStory,
  fallbackText,
  hasLink,
  itemURL,
  rowData,
}: InvoiceListTextProps): JSX.Element => {
  const { isOverflowing, ref: textElementRef } = useOverflow();

  const currentData = rowData[dataKey];
  const currentItemURL = itemURL || rowData.itemURL;
  const hasURL = Boolean(hasLink && currentItemURL);
  const showEmptyState = !currentData && !hasURL;

  return (
    <Tooltip
      data-testid="invoice-list-text-tooltip"
      position="top"
      tooltip={isOverflowing ? (currentData as string) : undefined}
      variant="light"
    >
      <Row className="invoice-list-text" justifyContent="flex-start">
        {showEmptyState ? (
          <EmptyState />
        ) : (
          <div className="flex items-center w-full">
            <InvoiceLink
              fallbackText={fallbackText}
              hasURL={hasURL}
              itemURL={currentItemURL}
              title={currentData as string}
            />
            <InvoiceButton
              buttonLabel={buttonLabel}
              fallbackText={fallbackText}
              hasURL={hasURL}
              itemURL={currentItemURL}
              rowData={rowData}
            />
            <TextContent
              dataFullStory={dataFullStory}
              dataTestId="invoice-list-text-content"
              hasURL={hasURL}
              setRef={textElementRef}
            >
              {currentData as string}
            </TextContent>

            {children}
          </div>
        )}
      </Row>
    </Tooltip>
  );
};

export default InvoiceListText;
