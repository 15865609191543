import { useSelector } from 'react-redux';

import type { CurrencyCode } from 'interfaces/currency';

import { currentCompanySettingsIsInternationalPaymentsEnabledSelector } from 'selectors/currentCompanySelectors';

/**
 * Receives a currencyCode and currencyCodeReceiver and check if they match
 * Used to determine when a funding account should be disabled for selection
 * @param currencyCode
 * @param currencyCodeReceiver
 * @returns boolean
 */
export const useShouldDisableInternationalFundingAccount = (
  currencyCode: CurrencyCode,
  currencyCodeReceiver: CurrencyCode,
): boolean => {
  const isInternationalPaymentsEnabled = useSelector(currentCompanySettingsIsInternationalPaymentsEnabledSelector);

  if (!isInternationalPaymentsEnabled || !currencyCodeReceiver || !currencyCode) {
    return false;
  }

  return currencyCodeReceiver !== currencyCode;
};
