import { businessType } from '@routable/companies-management';

import { commonBusinessTypeLabels } from 'constants/company';
import { TaxFormSectionTitle } from 'constants/taxes';

import { UserType } from 'enums/user';

import type { TaxFormSchema } from './TaxFormRenderer.types';

export const w9FormBusinessTypeLabels = {
  ...commonBusinessTypeLabels,
  [businessType.Enum.trust_estate]: 'Trust / Estate',
  [businessType.Enum.llc_single_member]: 'LLC (Single Member)',
  [businessType.Enum.llc_c_corp]: 'LLC (C-Corporation)',
  [businessType.Enum.llc_s_corp]: 'LLC (S-Corporation)',
  [businessType.Enum.llc_partnership]: 'LLC (Partnership)',
  [businessType.Enum.other]: 'Other',
};

const entityInformationSectionTitleMap = {
  [UserType.BUSINESS]: TaxFormSectionTitle.ENTITY_INFORMATION,
  [UserType.PERSONAL]: TaxFormSectionTitle.PERSONAL_INFORMATION,
} as const;

export const w8FormSchema: TaxFormSchema = [
  {
    getTitle: ({ companyType }) => entityInformationSectionTitleMap[companyType],
    fieldGroups: ['entityInformation'],
  },
  {
    title: TaxFormSectionTitle.PERMANENT_RESIDENCE_ADDRESS,
    fieldGroups: ['permanentResidenceAddress'],
  },
  {
    title: TaxFormSectionTitle.MAILING_ADDRESS,
    fieldGroups: ['mailingAddress'],
  },
  {
    title: TaxFormSectionTitle.TAX_TREATY_BENEFITS,
    fieldGroups: ['taxTreatyBenefits'],
  },
  {
    title: TaxFormSectionTitle.TAXPAYER_IDENTIFICATION_NUMBER,
    fieldGroups: ['taxpayerIdentification'],
  },
  {
    title: TaxFormSectionTitle.FORM_CERTIFICATION,
    fieldGroups: ['formCertification'],
  },
];

export const w9FormSchema: TaxFormSchema = [
  {
    getTitle: ({ companyType }) => entityInformationSectionTitleMap[companyType],
    fieldGroups: ['entityInformation', 'taxExemptions'],
  },
  {
    title: TaxFormSectionTitle.MAILING_ADDRESS,
    fieldGroups: ['genericAddress'],
  },
  {
    title: TaxFormSectionTitle.FORM_CERTIFICATION,
    fieldGroups: ['formCertification'],
  },
];
