/**
 * Available actions that a user can perform on PaymentMethod details
 */
export enum PaymentMethodDetailAction {
  COPY = 'COPY',
  LOCKED = 'LOCKED',
  REVEAL = 'REVEAL',
  TOOLTIP = 'TOOLTIP',
}

/**
 * PaymentMethodDetails action keys
 */
export enum PaymentMethodDetailKey {
  ACCOUNT_NUMBER = 'accountNumber',
  ACCOUNT_TYPE = 'accountType',
  ADDED_BY = 'addedBy',
  ADDRESS = 'address',
  BALANCE_ID = 'balanceId',
  BANK = 'bank',
  BANK_ACCOUNT_ID = 'bankAccountId',
  BANK_CODE = 'bankCode',
  BIC_SWIFT = 'bicSwift',
  BRANCH_CODE = 'branchCode',
  BSB_CODE = 'bsbCode',
  CLABE = 'clabe',
  CNAPS = 'cnaps',
  COUNTRY_CURRENCY = 'countryCurrency',
  IBAN = 'iban',
  IFSC = 'ifsc',
  ROUTING_NUMBER = 'routingNumber',
  SORT_CODE = 'sortCode',
}
