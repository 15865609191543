import { ChatBot, Document, Help as HelpIcon, HelpFilled, Laptop } from '@carbon/icons-react';
import { Button, Popover } from '@routable/components';
import clsx from 'clsx';
import React, { type PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useOverlayTriggerState } from 'react-stately';

import { PrivacyPolicyLink, SystemStatusLink, TermsOfServiceLink } from 'components/link';

import { CONTACT_SUPPORT_LABEL } from 'constants/labels';
import { HELP_DOCS_URL } from 'constants/platform';
import { DASHBOARD } from 'constants/routes';

import { getBaseRoute } from 'helpers/urls';

import { CustomerServiceHelper } from 'modules/customerSupport';

import { currentCompanyNameSelector } from 'selectors/currentCompanySelectors';
import { currentUserEmailSelector, currentUserFirstNameSelector } from 'selectors/currentUserSelectors';

import { helpStyles, helpPopoverStyles, helpPopoverMenuStyles } from './Help.styles';
import type { HelpActionMenuItemProps, HelpActionbarTriggerProps, HelpProps } from './Help.types';

export const Help = ({
  className,
  children,
  offset = 8,
  placement = 'top right',
  variant = 'floating-icon',
}: HelpProps) => {
  const baseRoute = getBaseRoute();
  const currentCompanyName = useSelector(currentCompanyNameSelector);
  const currentUserEmail = useSelector(currentUserEmailSelector);
  const currentUserFirstName = useSelector(currentUserFirstNameSelector);
  const popoverState = useOverlayTriggerState({});

  const suggestFeedbackURL = useMemo(() => {
    const email = encodeURIComponent(currentUserEmail);
    const firstName = encodeURIComponent(currentUserFirstName);
    const companyName = encodeURIComponent(currentCompanyName);
    return `https://routable.typeform.com/to/yi2YxtDE#email=${email}&first_name=${firstName}&company=${companyName}`;
  }, [currentCompanyName, currentUserEmail, currentUserFirstName]);

  const handleChatClick = () => {
    CustomerServiceHelper.show();
    popoverState.close();
  };

  return (
    <Popover
      containerClassName={helpStyles({ variant })}
      content={children || <HelpFilled aria-label="Help menu" className="fill-white" />}
      keepOpen
      offset={offset}
      placement={placement}
      popoverClassName={helpPopoverStyles({ className, variant })}
      state={popoverState}
    >
      <div className={helpPopoverMenuStyles({ variant })}>
        <ul className="px-5 py-4 flex flex-col gap-[6px]" role="menu">
          <Help.ActionMenuItem
            href={HELP_DOCS_URL}
            icon={<Document className="fill-grey-70 group-hover:fill-blue-40" size={14} />}
          >
            Help docs
          </Help.ActionMenuItem>
          <Help.ActionMenuItem
            icon={<ChatBot className="fill-grey-70 group-hover:fill-blue-40" size={14} />}
            onClick={handleChatClick}
          >
            {CONTACT_SUPPORT_LABEL}
          </Help.ActionMenuItem>
          {baseRoute === DASHBOARD && (
            <Help.ActionMenuItem
              href={suggestFeedbackURL}
              icon={<Laptop className="fill-grey-70 group-hover:fill-blue-40" size={14} />}
            >
              Suggest a feature
            </Help.ActionMenuItem>
          )}
        </ul>
        <div className="border-b border-grey-10" />
        <ul className="flex flex-col px-5 pb-4 pt-3 gap-2" role="menu">
          <Help.LegalMenuItem>
            <PrivacyPolicyLink />
          </Help.LegalMenuItem>
          <Help.LegalMenuItem>
            <TermsOfServiceLink />
          </Help.LegalMenuItem>
          <Help.LegalMenuItem>
            <SystemStatusLink />
          </Help.LegalMenuItem>
        </ul>
      </div>
    </Popover>
  );
};

Help.ActionbarTrigger = ({ className, label = 'Support' }: HelpActionbarTriggerProps) => (
  <Button className={clsx('self-center', className)} data-testid="help--trigger" intent="primary" variant="floating">
    <HelpIcon size={16} />
    {label}
  </Button>
);

Help.ActionMenuItem = ({ children, href, icon, onClick }: HelpActionMenuItemProps) => (
  <li role="menuitem">
    <a
      className="inline-flex items-center gap-[6px] text-xs leading-5 text-black group font-medium select-none hover:no-underline hover:text-blue-40"
      href={href}
      onClick={onClick}
      rel="noopener noreferrer"
      role={onClick ? 'button' : 'link'}
      target="_blank"
    >
      {icon}
      {children}
    </a>
  </li>
);

Help.LegalMenuItem = ({ children }: PropsWithChildren) => (
  <li
    className="[&_a]:text-[11px] font-light text-grey-60 py-1 hover:text-blue-40 [&_a]:hover:no-underline"
    role="menuitem"
  >
    {children}
  </li>
);
