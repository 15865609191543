import { combineReducers } from 'redux';

import { generateByIdReducer, generateIdReducer } from 'helpers/jsonapi';

import type {
  PartnershipPaymentState,
  PartnershipPaymentResponse,
} from 'interfaces/redux/partnershipPaymentReducer.types';

import { FETCH_PARTNERSHIP_PAYMENT_SUCCESS } from 'types/payment';

export default combineReducers<PartnershipPaymentState>({
  byId: generateByIdReducer<PartnershipPaymentResponse>([FETCH_PARTNERSHIP_PAYMENT_SUCCESS], ['partnershipPayment']),
  ids: generateIdReducer<PartnershipPaymentResponse>(FETCH_PARTNERSHIP_PAYMENT_SUCCESS, 'partnershipPayment'),
});
