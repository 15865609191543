import debounce from 'lodash/debounce';
import { useEffect, useState, type RefObject } from 'react';

export const useIsTitleOverflowing = (ref: RefObject<HTMLElement>): boolean => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const observerFn = () => {
      if (!ref.current?.hasChildNodes()) {
        return;
      }
      const { width: parentWidth } = ref.current.getBoundingClientRect();

      const { firstChild } = ref.current;
      if (typeof (firstChild as HTMLElement)?.getBoundingClientRect === 'function') {
        const { width: childWidth } = (firstChild as HTMLElement).getBoundingClientRect();
        // >= helps cover for the truncated version of the child where it is already set to truncate and therefor isn't longer
        // if the childWidth is 0, it is because the child is hidden and therefor not overflowing
        setIsOverflowing(childWidth !== 0 && childWidth >= parentWidth);
      }
    };

    const debounceObserverFn = debounce(observerFn, 100);
    const observer = new ResizeObserver(debounceObserverFn);
    observer.observe(ref.current, { box: 'border-box' });

    observerFn();

    return () => {
      debounceObserverFn.cancel();
      observer.disconnect();
    };
  }, [ref]);

  return isOverflowing;
};
