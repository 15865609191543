import pluralize from 'pluralize';
import React from 'react';

import ExternalLink from 'components/ExternalLink';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { Intent } from 'constants/ui';

import helpDocs from 'helpers/helpDocs';
import { getCustomerOrVendorOrBothTextByPartnership, getPartnershipTypeLedgerLink } from 'helpers/partnerships';
import { lengthOf } from 'helpers/utility';

import BaseHint from '../BaseHint';

import type { CurrencyNotSupportedHintProps } from './CurrencyNotSupportedHint.types';

/**
 * Hint notifying the user that only certain countries are supported, used when creating a new vendor
 */
const CurrencyNotSupportedHint: React.FC<CurrencyNotSupportedHintProps> = ({ ledgerName, partnership }) => {
  const partnershipType = getCustomerOrVendorOrBothTextByPartnership(partnership);
  const partnershipCurrencies = partnership.ledgerCurrencies;
  const partnershipCurrenciesLength = lengthOf(partnershipCurrencies);
  const currenciesText =
    partnershipCurrenciesLength > 1
      ? `${partnershipCurrencies.slice(0, -1).join(', ')} and ${partnershipCurrencies.slice(-1)}`
      : partnershipCurrencies[0];

  return (
    <BaseHint
      intent={Intent.DANGER}
      text={
        <>
          {`We cannot add this ${partnershipType} (`}
          <ExternalLink className="primary" href={getPartnershipTypeLedgerLink(partnership, partnershipType)}>
            {partnership.name}
          </ExternalLink>
          {`) to ${PLATFORM_DISPLAY_SHORT_NAME} because they are only configured for ${currenciesText} in ${ledgerName}. `}
          <ExternalLink className="primary" href={helpDocs.PARTNER_COUNTRY_CURRENCY_HINT}>
            Learn more about supported currencies
          </ExternalLink>
          .
        </>
      }
      title={`This ${partnershipType}'s ${pluralize(
        'currency',
        partnershipCurrenciesLength,
      )} (${partnershipCurrencies.join(', ')}) ${pluralize('is', partnershipCurrenciesLength)} currently not supported`}
    />
  );
};

export default CurrencyNotSupportedHint;
