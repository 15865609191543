/**
 * Used to determine payment status in payment method component
 */
export enum PaymentMethodStatusType {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  ERROR = 'ERROR',
  PENDING_MICRO_DEPOSITS = 'PENDING_MICRO_DEPOSITS',
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  PRIMARY = 'PRIMARY',
}

/**
 * Used to determine styles and scss classes in payment method component
 */
export enum PaymentMethodVariant {
  /** Allows external user to add their own account */
  EXTERNAL = 'external',
  /** Allow user to delete and update a funding account */
  MANAGE = 'manage',
  /** Allows user select funding account in Create Item */
  SELECT = 'select',
  /** Only user view details of funding account */
  VIEW = 'view',
}
