import PropTypes from 'prop-types';
import React from 'react';

import { IconNames, ModalFooter, ModalFooterButton, ModalFooterButtonCancel } from 'components';

import { sizes } from 'constants/styles';

/**
 * Renders footer elements of AddPartnerFundingAccount side panel
 * @param {ComponentProps} props
 * @param {string} props.formId
 * @param {boolean} props.isSubmitting
 * @param {Function} props.onCloseModal
 * @param {string} props.submitButtonText
 * @return {StatelessComponent}
 */
const AddPartnerFundingAccountFooter = ({ formId, isSubmitting, onCloseModal, submitButtonText }) => (
  <ModalFooter>
    <div className="modal-footer--container">
      <div className="content--left">
        <ModalFooterButtonCancel onCloseModal={onCloseModal} />
      </div>

      <div className="content--right">
        <ModalFooterButton
          htmlFor={formId}
          isActionButton
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          leftIconName={IconNames.TICK_CIRCLE}
          leftIconSize={sizes.iconSizes.EXTRA_MEDIUM}
          type="submit"
        >
          {submitButtonText}
        </ModalFooterButton>
      </div>
    </div>
  </ModalFooter>
);

AddPartnerFundingAccountFooter.propTypes = {
  formId: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
};

export default AddPartnerFundingAccountFooter;
