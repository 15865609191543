import classNames from 'classnames';
import React from 'react';

import { getProgressBarStepClass } from './helpers/ui';
import type { ProgressBarProps } from './ProgressBar.types';

import './ProgressBar.scss';

/**
 * Progress bar for showing steps in multistep forms
 */
const ProgressBar: React.FC<ProgressBarProps> = ({ currentStepPosition, isExternal, showNum, steps }) => (
  <div
    className={classNames('progress-bar', {
      external: isExternal,
    })}
  >
    <div className="progress-bar--steps">
      {steps.map((step, idx) => {
        const currentStepClass = getProgressBarStepClass(idx, currentStepPosition);
        const stepText = `${showNum ? `${step.num} ` : ' '}${step.description}`;

        return (
          <div className={`progress-bar--step-container ${currentStepClass}`} key={step.num}>
            <div className="progress-bar--step">
              <div className="content--left">
                <div className="circle">
                  <div className="aligner" />
                </div>
              </div>

              <div className="content--right">
                <span className="text">{stepText}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

ProgressBar.defaultProps = {
  showNum: true,
};

export default ProgressBar;
