import React from 'react';

import { FlexCol, Text } from 'components';

import { typography } from 'constants/styles';

import { ExternalReceiptItemEntryProps } from './ExternalReceiptItemEntry.types';

import './ExternalReceiptItemEntry.scss';

/**
 * Component rendering External Receipt Item single data entry
 */
const ExternalReceiptItemEntry: React.FC<ExternalReceiptItemEntryProps> = ({ label, value }) => {
  if (!value) {
    return null;
  }

  return (
    <FlexCol className="external-receipt-item-entry">
      <Text.Regular size={typography.TextSize.LEVEL_200}>{label}</Text.Regular>
      <Text.Semibold color={typography.TextColor.BLACK} size={typography.TextSize.LEVEL_200}>
        {value}
      </Text.Semibold>
    </FlexCol>
  );
};

export default ExternalReceiptItemEntry;
