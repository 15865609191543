import type { RoutableApiResponse } from 'interfaces/routableApiResponse';

import { routableApi } from './routableApi';

const extendedExporterApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    exporter: builder.query<RoutableApiResponse, string>({
      query: (url) => ({ url }),
    }),
  }),
});

export const { useExporterQuery, useLazyExporterQuery } = extendedExporterApi;
