import { createRoutine } from 'redux-saga-routines';

import * as types from 'types/documents';

export const postCompanyDocumentRoutine = createRoutine(types.POST_COMPANY_DOCUMENT);

export const postRepresentativeDocumentRoutine = createRoutine(types.POST_REPRESENTATIVE_DOCUMENT);

export const deleteCompanyDocumentRoutine = createRoutine(types.DELETE_COMPANY_DOCUMENT);

export const deleteRepresentativeDocumentRoutine = createRoutine(types.DELETE_REPRESENTATIVE_DOCUMENT);
