import { Tooltip } from '@routable/gross-ds';
import React from 'react';

import DashboardToDashboardRestrictionTooltipContent from 'components/dashboardToDashboardRestrictionTooltipContent';

type Props = { children: React.ReactNode };

const tooltipContent = (
  <div className="p-3 max-w-[320px]">
    <DashboardToDashboardRestrictionTooltipContent />
  </div>
);

export const PartnerSelfManagedTooltip = ({ children }: Props): JSX.Element => (
  <Tooltip data-testid="partner-self-managed-tooltip" tooltip={tooltipContent} variant="light">
    <span className="font-color--main-blue cursor-pointer">{children}</span>
  </Tooltip>
);
