import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useTaxFormOptions } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.context';
import {
  taxFormEntryType,
  W8FormModel,
  w8LimitationOnBenefits,
} from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';

import { isCompanyTypeBusiness } from 'helpers/currentCompany';

import { getLabelValuePairsFromEnum } from '../helpers';
import RenderCheckbox from '../RenderCheckbox';
import RenderSelect from '../RenderSelect';
import RenderTextInput from '../RenderTextInput';
import { Group, GroupSlot } from '../styles';

const FieldGroupTaxTreatyBenefits = (): JSX.Element => {
  const { watch } = useFormContext<W8FormModel>();
  const { entryType } = useTaxFormOptions();

  const claimTaxTreatyBenefits = watch('claimingTreatyBenefits');
  const companyType = watch('companyType');

  const isBusiness = isCompanyTypeBusiness(companyType);

  return (
    <Group>
      <GroupSlot size={6}>
        <RenderCheckbox
          label={`${
            entryType === taxFormEntryType.Enum.external ? 'I claim' : 'The vendor claims'
          } treaty benefits under an income tax treaty between the United States and the country of residence.`}
          name="claimingTreatyBenefits"
        />
      </GroupSlot>
      {claimTaxTreatyBenefits && (
        <>
          {isBusiness && (
            <GroupSlot size={6}>
              <RenderSelect
                isRequired={false}
                label="Limitation on benefits provision"
                name="treatyBenefitsLimitationOnBenefits"
                options={getLabelValuePairsFromEnum(w8LimitationOnBenefits)}
                placeholder="Select limitation type"
              />
            </GroupSlot>
          )}
          <GroupSlot size={2}>
            <RenderTextInput
              name="treatyBenefitsArticleAndParagraph"
              placeholder="Article & Paragraph"
              shouldUnregister
            />
          </GroupSlot>
          <GroupSlot size={2}>
            <RenderTextInput name="treatyBenefitsWithholdingRate" placeholder="Withholding Rate" shouldUnregister />
          </GroupSlot>
          <GroupSlot size={2}>
            <RenderTextInput name="treatyBenefitsTypeOfIncome" placeholder="Type of Income" shouldUnregister />
          </GroupSlot>
        </>
      )}
    </Group>
  );
};

export default FieldGroupTaxTreatyBenefits;
