import { Middleware } from 'redux';

import { getShiftSelectData, setLastClicked } from 'ducks/reduxShiftSelect';

import { withModifierKeys } from 'actions/modifiers';

import { LogLevel, systemLogger } from 'helpers/systemLogger';

export type ChangeEvent = {
  type: '@@redux-form/CHANGE';
  meta: {
    form: string;
    field: string;
    touch?: boolean;
    persistentSubmitErrors?: boolean;
  };
  payload: boolean;
};

export const reduxShiftSelect: Middleware = (middleware) => (next) => (action: ChangeEvent) => {
  if (action.type === '@@redux-form/CHANGE') {
    const [formField] = action.meta.field.split('.');
    const { lastClickedId, allIds } = getShiftSelectData(middleware.getState(), formField);

    const {
      meta: {
        field,
        modifierKeys: { shift },
      },
    } = withModifierKeys(action);
    if (shift && lastClickedId) {
      const activeIndex = allIds.indexOf(field);
      const lastClicked = allIds.indexOf(lastClickedId);

      const lowIndex = Math.min(activeIndex, lastClicked);
      const highIndex = Math.max(activeIndex, lastClicked);

      const toggleIds = allIds.slice(lowIndex, highIndex + 1);

      systemLogger.log({
        level: LogLevel.DEBUG,
        message: 'Shift toggling the following IDs',
        toggleIds,
      });

      toggleIds.forEach((key) => {
        next({
          ...action,
          meta: {
            ...action.meta,
            field: key,
          },
        });
      });
    }
    next(setLastClicked({ formField, fieldId: field }));
  }

  return next(action);
};
