import React from 'react';
import { useSelector } from 'react-redux';
import { useEffectOnce, useSearchParam, useToggle } from 'react-use';

import {
  UseLazyGetItemByIdQuery,
  useLazyGetItemByIdQuery as usePrivateLazyGetItemByIdQuery,
  UseLazyGetItemListQuery,
  useLazyGetItemListQuery as usePrivateLazyGetItemListQuery,
} from 'ducks/routableApi';

import { ItemFilterUrlParams } from 'enums/itemFilters';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';
import { allValues } from 'helpers/utility';

import { usePayment as usePrivatePayment, UsePayment, useSelectorWithProps } from 'hooks';

import { CountryCode } from 'interfaces/countries';
import { Item } from 'interfaces/item';
import { Pagination } from 'interfaces/jsonapi';

import { partnershipCountryCodeFromCurrentPartnershipRequestSelector } from 'queries/partnershipCompoundSelectors';

import { itemAttachmentsSelector } from 'selectors/itemsSelectors';

import { SetActivePage } from 'types/UseApi';

import {
  ExternalReceiptPaymentFullPageModal,
  ExternalReceiptPaymentLineItemsModal,
} from './components/ExternalReceiptPayment/components';
import ExternalReceiptSingleItem from './ExternalReceiptSingleItem';

const getItems = (itemId, itemDataFromUrl, activePaymentId, paymentDataFromUrl): Item[] => {
  // using items from item_id or items from payment in url query params
  let items: Item[] = [];
  if (itemId && itemDataFromUrl?.item) {
    const itemsById = getObjectsByIdWithRelationships(itemDataFromUrl.item);
    items = allValues(itemsById);
  } else if (activePaymentId && paymentDataFromUrl?.item) {
    const itemsById = getObjectsByIdWithRelationships(paymentDataFromUrl.item);
    items = allValues(itemsById);
  }

  return items;
};

export const generateExternalReceiptSingleItemContainer =
  (
    usePayment: UsePayment,
    useLazyGetItemByIdQuery: UseLazyGetItemByIdQuery,
    useLazyGetItemListQuery: UseLazyGetItemListQuery,
  ): React.VFC =>
  () => {
    const [billModalOpen, setBillModalOpen] = useToggle(false);
    const [activeItem, setActiveItem] = React.useState<Item | undefined>();

    const partnershipCountryCode: CountryCode = useSelector(
      partnershipCountryCodeFromCurrentPartnershipRequestSelector,
    );

    const itemId = useSearchParam('item_id');
    const [triggerGetItemById, { data: itemDataFromUrl }] = useLazyGetItemByIdQuery();

    const activePaymentId = useSearchParam(ItemFilterUrlParams.FILTER_PAYMENT_ID_URL_PARAM);
    const { data: activePayment } = usePayment({
      initialPaymentId: activePaymentId,
    });

    const [triggerGetItemList, { data: paymentDataFromUrl, originalArgs: getItemListQueryArgs, isFetching }] =
      useLazyGetItemListQuery();

    const goToPage: SetActivePage = (destinationPage) => {
      switch (destinationPage) {
        case 'PREV':
          triggerGetItemList({
            params: {
              ...getItemListQueryArgs.params,
              page: {
                size: 1,
                number: getItemListQueryArgs.params.page.number - 1,
              },
            },
          });
          break;
        case 'NEXT':
          triggerGetItemList({
            params: {
              ...getItemListQueryArgs.params,
              page: {
                size: 1,
                number: getItemListQueryArgs.params.page.number + 1,
              },
            },
          });
          break;
        default:
          triggerGetItemList({
            params: {
              ...getItemListQueryArgs.params,
              page: {
                size: 1,
                number: destinationPage,
              },
            },
          });
      }
    };

    useEffectOnce(() => {
      if (itemId) {
        triggerGetItemById({ id: itemId });
      }
      if (activePaymentId) {
        triggerGetItemList({
          params: {
            page: {
              size: 1,
              number: 1,
            },
            payment: activePaymentId,
          },
        });
      }
    });

    const items = getItems(itemId, itemDataFromUrl, activePaymentId, paymentDataFromUrl);

    const pagination: Pagination = paymentDataFromUrl?.meta?.pagination ?? {
      page: 1,
      pageSize: 1,
    };

    const item = items.length ? items[0] : undefined;
    const attachments = useSelectorWithProps(itemAttachmentsSelector, item?.id);

    if (!item) {
      return null;
    }

    // line items are enabled if we have line items. BE controls if we have them or not
    const enableLineItems = item?.lineItems?.length > 0;

    return (
      <>
        <ExternalReceiptSingleItem
          attachments={attachments}
          canNextPage={!isFetching && pagination.page < pagination.pages}
          canPreviousPage={!isFetching && pagination.page > 1}
          enableLineItems={enableLineItems}
          item={item}
          onGoToPage={goToPage}
          onViewBills={(viewBillPayment) => setBillModalOpen(!!viewBillPayment?.id)}
          onViewLineItems={setActiveItem}
          pageCount={pagination.pages}
          pageIndex={pagination.page}
          partnershipCountryCode={partnershipCountryCode}
          payment={activePayment}
        />

        {activePayment && (
          <ExternalReceiptPaymentFullPageModal
            modalState={billModalOpen}
            onCloseModal={() => setBillModalOpen()}
            onSetActiveItem={setActiveItem}
            payment={activePayment}
          />
        )}

        <ExternalReceiptPaymentLineItemsModal
          item={activeItem}
          modalState={!!activeItem}
          onCloseModal={() => setActiveItem(undefined)}
        />
      </>
    );
  };

export const ExternalReceiptSingleItemContainer = generateExternalReceiptSingleItemContainer(
  usePrivatePayment,
  usePrivateLazyGetItemByIdQuery,
  usePrivateLazyGetItemListQuery,
);

export default ExternalReceiptSingleItemContainer;
