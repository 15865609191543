import { DateElement } from '@routable/shared';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { DatePickerForReduxForm } from 'components/reduxForm';

import { DateFormats } from 'constants/date';
import * as propTypes from 'constants/propTypes';
import { PopperPlacements } from 'constants/ui';

import { formatDate, getDayjsObjectIfValue } from 'helpers/date';
import { oneOfValuesFromObject } from 'helpers/propTypes';
import { isIncluded, isNotIncluded } from 'helpers/utility';

import { useMemoizedConditionalValidate } from 'hooks';

import { useDynamicFieldRequiredValidators } from 'modules/table/hooks';

/**
 * Renders a date picker field for date values to be rendered in a block section
 * @param {ComponentProps} props
 * @param {DateElement[]} props.dateElements
 * @param {Object} props.formValues
 * @param {function} props.getIsDisabled
 * @param {string} props.initialValue
 * @param {string} props.name
 * @param {string} props.placeholder
 * @param {Function} props.validate
 * @returns {StatelessComponent}
 */
const DateBlockField = (props) => {
  const { dateElements, dateFormat, formValues, getIsDisabled, initialValue, name, placeholder, timeFormat } = props;

  const { isRequired, validators } = useDynamicFieldRequiredValidators(props);
  const cachedValidators = useMemoizedConditionalValidate(!formValues.ui?.blockDetailsSections, validators);

  return (
    <Field
      component={DatePickerForReduxForm}
      dateFormat={dateFormat}
      format={getDayjsObjectIfValue}
      isDisabled={getIsDisabled(formValues)}
      isRequired={isRequired}
      name={name}
      normalize={(value) => (value ? formatDate(value) : initialValue)}
      placeholderText={placeholder}
      props={{
        popperModifiers: { flip: { enabled: false } },
        popperPlacement: PopperPlacements.BOTTOM,
      }}
      showTimeSelect={isIncluded(dateElements, DateElement.time)}
      showTimeSelectOnly={isNotIncluded(dateElements, DateElement.date)}
      timeFormat={timeFormat}
      validate={cachedValidators}
    />
  );
};

DateBlockField.propTypes = {
  ...propTypes.baseFieldSharedPropTypes,
  dateElements: PropTypes.arrayOf(oneOfValuesFromObject(DateElement)).isRequired,
  dateFormat: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  placeholder: PropTypes.string.isRequired,
  timeFormat: PropTypes.string,
};

DateBlockField.defaultProps = {
  ...propTypes.baseFieldSharedDefaultProps,
  dateFormat: DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR,
  initialValue: undefined,
  timeFormat: undefined,
};

export default DateBlockField;
