import React from 'react';

import { isLessThan } from 'helpers/utility';

/**
 * Custom hook which returns true if element is overflowing it's boundaries on the
 * X axis
 * @return {{ boolean, ({}|Function)} }
 */
const useOverflow = (): {
  isOverflowing: boolean;
  ref: React.RefObject<HTMLSpanElement>;
} => {
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const ref = React.useRef(null);

  // We check if containerRect.width is less than textNode.scrollWidth and set the
  // isOverflown state to the outcome of that compare.
  React.useEffect(() => {
    if (ref.current) {
      const { clientWidth, scrollWidth } = ref.current;
      setIsOverflowing(isLessThan(clientWidth, scrollWidth));
    }
  }, [ref]);

  // We return isOverflown state value and a reference to containerRef, which
  // we need to set to the container element.
  return { isOverflowing, ref };
};

export default useOverflow;
