import clsx from 'clsx';
import React from 'react';

import { ButtonV2 } from 'components';

import { ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';

import type { PaginationBlockButtonProps } from './PaginationBlockButton.types';

export const PaginationBlockButton = ({ buttonContainerClassname, children, ...props }: PaginationBlockButtonProps) => (
  <div className={clsx('pagination-bar-button', { [buttonContainerClassname]: !!buttonContainerClassname })}>
    <ButtonV2 intent={Intent.NEUTRAL} isIconButton={!children} size={ButtonSize.SMALL} type="button" {...props}>
      {children}
    </ButtonV2>
  </div>
);
