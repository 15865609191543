import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { useFormattedCountries } from 'components/form/CountryField/hooks';

import { isCompanyTypeBusiness } from 'helpers/currentCompany';
import { isObject } from 'helpers/utility';

import { useCountryCodesContext } from 'hooks';

import { RenderSection } from './components';
import { w8FormSchema, w9FormSchema } from './TaxFormRenderer.constants';
import { TaxFormOptionsContext } from './TaxFormRenderer.context';
import {
  taxFormEntryType,
  TaxFormFields,
  TaxFormModel,
  taxFormType,
  w8BenEFormBaseModel,
  w8BenFormBaseModel,
  w8FormModel,
  w9FormBaseModel,
  w9FormModel,
} from './TaxFormRenderer.models';
import type { TaxFormRendererProps } from './TaxFormRenderer.types';

/**
 * Top-level Tax form component, rendering the entire form and handling form
 * setup & interactions.
 * @param props - Component props
 */
const TaxFormRenderer = ({
  defaultValues,
  entryType = taxFormEntryType.Enum.external,
  formType,
  formId,
  isSignatureAllowed = true,
  onFormSubmitStateChange,
  onFormValidStateChange,
  onSubmit,
  showCompanyTypeField = true,
}: TaxFormRendererProps): JSX.Element => {
  const schema = useMemo(() => (formType === taxFormType.Enum.W8 ? w8FormSchema : w9FormSchema), [formType]);
  const resolver = useMemo(
    () => (formType === taxFormType.Enum.W8 ? zodResolver(w8FormModel) : zodResolver(w9FormModel)),
    [formType],
  );

  const methods = useForm<TaxFormModel>({
    defaultValues,
    mode: 'onBlur',
    resolver,
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    watch,
  } = methods;

  const countries = useCountryCodesContext();
  const wasSubmitting = usePrevious(isSubmitting);
  const wasValid = usePrevious(isValid);

  const companyType = watch('companyType');
  const shouldRenderBaseSection = formType === taxFormType.Enum.W8 || showCompanyTypeField;

  const { prioritizedCountries } = useFormattedCountries(countries);

  const handleSubmitTaxForm: SubmitHandler<TaxFormModel> = async (values) => {
    const errors = await onSubmit(values);

    if (errors) {
      Object.entries(errors).forEach(([field, error]) => {
        const w8FormBaseModel = isCompanyTypeBusiness(companyType) ? w8BenEFormBaseModel : w8BenFormBaseModel;
        const model = formType === taxFormType.Enum.W8 ? w8FormBaseModel : w9FormBaseModel;

        const parsedField = model.keyof().safeParse(field);

        if (parsedField.success) {
          // Account for scenario where field is a tupple (array), and the
          // errors returned are in this shape:
          // fieldName: {0: ["Error"], 1: ["Error"]}
          if (isObject(error)) {
            Object.values(error).forEach((fieldError, idx) => {
              setError(
                `${parsedField.data}.${idx}` as TaxFormFields,
                { type: 'apiError', message: fieldError as string },
                { shouldFocus: true },
              );
            });
          } else {
            setError(parsedField.data, { type: 'apiError', message: error }, { shouldFocus: true });
          }
        }
      });
    }
  };

  useEffect(() => {
    if (wasSubmitting !== isSubmitting) {
      onFormSubmitStateChange?.(isSubmitting);
    }
  }, [isSubmitting, wasSubmitting, onFormSubmitStateChange]);

  useEffect(() => {
    if (wasValid !== isValid) {
      onFormValidStateChange?.(isValid);
    }
  }, [isValid, wasValid, onFormValidStateChange]);

  return (
    <TaxFormOptionsContext.Provider
      value={{
        countries: prioritizedCountries,
        entryType,
        formType,
        isSignatureAllowed,
        showCompanyTypeField,
      }}
    >
      <FormProvider {...methods}>
        <form id={formId} onSubmit={handleSubmit(handleSubmitTaxForm)}>
          {shouldRenderBaseSection && <RenderSection fieldGroups={['base']} />}
          {!!companyType &&
            schema.map((section) => (
              <RenderSection
                fieldGroups={section.fieldGroups}
                getTitle={section.getTitle}
                key={section.fieldGroups.reduce((acc, val) => `${acc}_${val}`, '')}
                title={section.title}
              />
            ))}
        </form>
      </FormProvider>
    </TaxFormOptionsContext.Provider>
  );
};

export default TaxFormRenderer;
