import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { submitFundingStateRoutine } from 'actions/routines/external';

import { externalPaymentFundingAccountSelector } from 'selectors/forms';
import { partnershipFundingStatesErrorsSelector } from 'selectors/partnershipFundingStatesSelectors';

type ErrorsType = {
  fields?: {
    [key: string]: string[];
  };
};

type ReturnType = string[] | undefined;

/**
 * Gets errors related to the selected funding account from state and clears them
 * when the selected funding account changes
 */
export const useExternalPaymentFundingAccountHint = (): ReturnType => {
  const errors: ErrorsType = useSelector(partnershipFundingStatesErrorsSelector);
  const selectedFundingAccount = useSelector(externalPaymentFundingAccountSelector);
  const dispatch = useDispatch();

  React.useEffect(() => {
    // clear error state when selected funding account changes
    dispatch(submitFundingStateRoutine.failure());
  }, [dispatch, selectedFundingAccount]);

  return errors?.fields?.fundingAccount;
};
