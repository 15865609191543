import React from 'react';

import { SubtitleWithIcon, TitleWithDetails, TitleWithSubtitle } from 'components';

import { ItemMemberProps } from 'data/resources/itemMember';

import { formatEmailForDetailsTitle } from 'helpers/contacts';
import { getItemMemberAccessSubtitleUi } from 'helpers/itemMember';
import { getMembershipFullName } from 'helpers/memberships';

import type { ContactListItemTitleTypeThreadProps } from './ContactListItemTitleTypeThread.types';

/**
 * Returns the title for the ContactListItem specific to item threads
 */
const ContactListItemTitleTypeThread = ({
  dataFullStory,
  item,
  partnershipMember,
}: ContactListItemTitleTypeThreadProps): JSX.Element => {
  const { email } = partnershipMember;
  const title = getMembershipFullName(partnershipMember);

  // For the detail text, we want to apply the following:
  // - if we have a name, we want the formatted email string as a detail.
  // - if we don't have a name we want to use the unformatted email string
  const detail = title ? formatEmailForDetailsTitle(email) : email;

  const {
    color: iconColor,
    name: iconName,
    text: subtitleText,
  } = getItemMemberAccessSubtitleUi(item, partnershipMember, ItemMemberProps.accessItem);

  return (
    <TitleWithSubtitle
      subtitles={[
        <SubtitleWithIcon
          icon={iconName}
          iconProps={{
            color: iconColor,
          }}
          key="subtitle-icon"
          text={subtitleText}
        />,
      ]}
      titles={[
        <TitleWithDetails dataFullStory={dataFullStory} detail={detail} key="title-with-details" main={title} />,
      ]}
    />
  );
};

export default ContactListItemTitleTypeThread;
