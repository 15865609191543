import React from 'react';

import { ContactAutofillIcons } from 'complexComponents/ContactAutofillIcons';

import { statusCanHaveDefaultContactSettings } from 'helpers/contacts';

import { ContactCellsProps } from '../ContactCellsTypes.types';

/**
 * Show autofill icons with tooltips to explain the default contact settings for each contact.
 * @returns {StatelessComponent}
 */
const ContactSettingsCell: React.FC<ContactCellsProps> = ({ rowData }) => {
  const { status } = rowData;

  // If a contact is archived or is awaiting verification
  if (!statusCanHaveDefaultContactSettings(status)) {
    // it doesn't have default contact settings
    return null;
  }

  return (
    <div className="flex-row">
      <ContactAutofillIcons partnershipMember={rowData} />
    </div>
  );
};

export default ContactSettingsCell;
