import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Script from 'react-load-script';

import { InputField, SelectFieldV2 } from 'components';

import { StaticCountryCode } from 'constants/countries';
import { countriesMapping, countryListOptions } from 'constants/i18n';

import { getStatesByCountry } from 'helpers/addressHelpers';

import { useAddressAutocomplete } from './AddressHookForm.hook';
import type { AddressHookFormProps } from './AddressHookForm.types';

export const AddressHookForm = ({
  addressPath = '',
  countryOptions = countryListOptions.ALL,
  onInvalidCountry,
}: AddressHookFormProps): JSX.Element => {
  const { control, setValue, watch } = useFormContext();
  const streetAddressRef = useRef<HTMLInputElement>(null);
  const { onLoad, scriptUrl } = useAddressAutocomplete({
    addressPath,
    countryOptions,
    inputRef: streetAddressRef,
    onInvalidCountry,
    setValue,
  });

  const path = addressPath ? `${addressPath}.` : '';
  const selectedCountry = watch(`${path}country`);
  const stateOrProvince = selectedCountry === StaticCountryCode.CA ? 'Province' : 'State';

  return (
    <>
      <Script onLoad={onLoad} url={scriptUrl} />
      <div className="flex flex-col md:flex-row gap-5">
        <Controller
          control={control}
          name={`${path}streetAddress`}
          render={({ field }) => (
            <InputField
              autoComplete="false"
              className="w-full md:w-2/3"
              isRequired
              isUncontrolled
              name={field.name}
              onBlur={field.onBlur}
              onChange={field.onChange}
              placeholder="Street address"
              setRef={streetAddressRef}
              value={field.value}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name={`${path}streetAddressUnit`}
          render={({ field: { ref, ...fieldProps } }) => (
            <InputField
              {...fieldProps}
              className="w-full md:w-1/3"
              isRequired={false}
              isUncontrolled
              placeholder="Street address unit"
              setRef={ref}
            />
          )}
        />
      </div>
      <Controller
        control={control}
        name={`${path}country`}
        render={({ field }) => (
          <SelectFieldV2
            input={field}
            label="Country"
            name={field.name}
            options={countriesMapping[countryOptions]}
            required
            setRef={field.ref}
            type="text"
          />
        )}
        rules={{ required: true }}
      />
      <div className="flex flex-col md:flex-row gap-5">
        <Controller
          control={control}
          name={`${path}city`}
          render={({ field: { ref, ...fieldProps } }) => (
            <InputField
              {...fieldProps}
              className="w-full md:w-1/3"
              isRequired
              isUncontrolled
              placeholder="City"
              setRef={ref}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name={`${path}state`}
          render={({ field }) => (
            <SelectFieldV2
              input={field}
              label={stateOrProvince}
              name={field.name}
              options={getStatesByCountry({ country: selectedCountry })}
              required
              setRef={field.ref}
              wrapperClassName="w-full md:w-1/3"
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name={`${path}postalcode`}
          render={({ field: { ref, ...fieldProps } }) => (
            <InputField
              {...fieldProps}
              className="w-full md:w-1/3"
              isRequired
              isUncontrolled
              placeholder="Postal code"
              setRef={ref}
            />
          )}
          rules={{ required: true }}
        />
      </div>
    </>
  );
};
