import React from 'react';

import { FormFieldLabel } from 'components';

import { formNamesExternal } from 'constants/forms';
import { GovernmentIdFieldsPerCountry } from 'constants/governmentIdFields';

import { UserType } from 'enums/user';

import { GovernmentIdFieldSet } from 'modules/dashboard/createPartnership/presenters/CreatePartnershipBodyV2/components/CreatePartnershipContactForm/components';

import { PartnerCompanyInternationalTaxFormProps } from './PartnerCompanyInternationalTaxForm.types';

const TaxInfoFormFieldLabel = {
  [UserType.PERSONAL]: 'Personal Information',
  [UserType.BUSINESS]: 'Entity Information',
};

const PartnerCompanyInternationalTaxForm = ({
  companyType,
  countryCode,
  formName,
}: PartnerCompanyInternationalTaxFormProps): JSX.Element => {
  const isCountryDefined = Boolean(GovernmentIdFieldsPerCountry[countryCode]);

  if (!isCountryDefined) {
    return null;
  }

  return (
    <div>
      <FormFieldLabel>{TaxInfoFormFieldLabel[companyType]}</FormFieldLabel>
      <GovernmentIdFieldSet
        companyType={companyType}
        countryCode={countryCode}
        formName={formName || formNamesExternal.PARTNER_COMPANY_GENERAL}
      />
    </div>
  );
};

export default PartnerCompanyInternationalTaxForm;
