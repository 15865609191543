import { connect } from 'react-redux';
import { destroy, hasSubmitSucceeded, reduxForm } from 'redux-form';

import { inviteTeamMemberFormFields } from 'constants/formFields';

import { alreadyInvitedAsyncValidation } from 'modules/signup-v3/helpers/alreadyInvitedAsyncValidation';

import { membershipInvitesErrorsSelector } from 'selectors/membershipInvitesSelectors';
import { rolesByIdSelector } from 'selectors/rolesSelectors';

import { inviteTeamMemberForm } from '../../constants';
import submitInviteTeamMember from '../../helpers/submitInviteTeamMember';

import InviteTeamMemberModal from './InviteTeamMemberModal';

const mapStateToProps = (state) => ({
  errors: membershipInvitesErrorsSelector(state),
  hasSubmitSucceeded: hasSubmitSucceeded(inviteTeamMemberForm)(state),
  roles: rolesByIdSelector(state),
});

const mapDispatchToProps = {
  onDestroyForm: destroy,
};

const InviteTeamMemberModalForm = reduxForm({
  // initial meta is needed so that inviteTeamMemberFormReducer runs
  initialValues: { meta: {} },
  form: inviteTeamMemberForm,
  destroyOnUnmount: true,
  onSubmit: submitInviteTeamMember,
  asyncBlurFields: [inviteTeamMemberFormFields.EMAIL],
  asyncChangeFields: [inviteTeamMemberFormFields.EMAIL],
  asyncValidate: alreadyInvitedAsyncValidation,
})(InviteTeamMemberModal);

export default connect(mapStateToProps, mapDispatchToProps)(InviteTeamMemberModalForm);
