import React from 'react';

import { Text, DocumentV2 } from 'components';

import { typography } from 'constants/styles';

import { ExternalReceiptAttachmentsProps } from './ExternalReceiptAttachments.types';

import './ExternalReceiptAttachments.scss';

/**
 * Component rendering External Receipt attachments
 */
const ExternalReceiptAttachments: React.FC<ExternalReceiptAttachmentsProps> = ({ attachments }) => (
  <div className="external-receipt-attachments">
    <Text.Regular size={typography.TextSize.LEVEL_200}>Attachments</Text.Regular>
    {attachments.map((attachment) => (
      <DocumentV2
        documentName={attachment.filename}
        documentURL={attachment.file}
        isClearable={false}
        isDownloadable
        key={attachment.id}
      />
    ))}
  </div>
);

export default ExternalReceiptAttachments;
