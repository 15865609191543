import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field } from 'redux-form';

import { internationalBankValidatorsReset } from 'actions/funding';

import { ReduxFormFieldRenderInput } from 'components';

import { colors } from 'constants/styles';

import { requiredValidator } from 'helpers/fieldValidation';
import { snakeCaseFundingFieldsToExternalUserCase } from 'helpers/funding';

import { IsLoadingInline } from 'modules/isLoading';

import { useInternationalBankValidator } from '../../hooks';

import { ManuallyAddBankAccountFieldProps } from './ManuallyAddBankAccountField.types';

/**
 * Component rendering single International Add Bank Account Form Field. It receives
 * fieldInfo config object as a prop.
 */
const ManuallyAddBankAccountField = ({ fieldInfo, isInternational }: ManuallyAddBankAccountFieldProps) => {
  const dispatch = useDispatch();
  const fieldName = fieldInfo.field;
  const placeholder = snakeCaseFundingFieldsToExternalUserCase(fieldName);

  const { bankNameNode, fieldValidator, isValidating, onBlur } = useInternationalBankValidator(
    fieldName,
    isInternational,
  );

  useEffect(
    () => () => {
      dispatch(internationalBankValidatorsReset({ fieldName }));
    },
    // leave dependencies empty for unmount behavior
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Field
        addon={isValidating ? <IsLoadingInline color={colors.colorDarkSilverHex} /> : null}
        className="margin-top--m-large"
        component={ReduxFormFieldRenderInput}
        isRequired
        name={fieldName}
        onBlur={onBlur}
        placeholder={placeholder}
        validate={[requiredValidator, fieldValidator]}
      />

      {bankNameNode}
    </>
  );
};

export default ManuallyAddBankAccountField;
