import classNames from 'classnames';
import React from 'react';

import { Row } from 'components/layout';

import { ContentBlockProps } from './ContentBlock.types';

/**
 * Generic content-block container.
 * Usage with ContentBlockHeader and ContentBlockBody for auto-layout, or custom children.
 */
const ContentBlock = ({ children, className }: ContentBlockProps): JSX.Element => (
  <Row
    className={classNames({
      'content-block': true,
      [className]: !!className,
    })}
    flexDirection="column"
  >
    {children}
  </Row>
);

export default ContentBlock;
