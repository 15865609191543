import React from 'react';

import { Hint } from 'components/hint';
import { Text } from 'components/text';

import { TextColor } from 'constants/styles/typography';
import { Intent } from 'constants/ui';

/**
 * Component rendering Cross-border Approvals hint
 */
const CrossBorderApprovalHint: React.VFC = () => (
  <Hint intent={Intent.INFO}>
    <Text.Bold color={TextColor.BLUE_DARK}>
      Approvals for this payment are calculated in USD based on the current conversion rate
    </Text.Bold>
  </Hint>
);

export default CrossBorderApprovalHint;
