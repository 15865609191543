import React from 'react';

import { BaseHint, Image, Text } from 'components';

import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';

import { getStaticResourceFullPath } from 'helpers/assets';
import { convertToLowerCase, listAllElementsInArray } from 'helpers/stringHelpers';
import { lengthOf } from 'helpers/utility';

import type { LedgerSettingsLimitVendorCountriesHintProps } from './LedgerSettingsLimitVendorCountriesHint.types';

/**
 * Hint notifying the user which countries is the vendor limited to based on given
 */
const LedgerSettingsLimitVendorCountriesHint = ({
  companyIntegrationSettings,
  currencies,
  ledger,
  onRefresh,
}: LedgerSettingsLimitVendorCountriesHintProps): React.ReactElement => {
  const image = getStaticResourceFullPath(`/images/_global/integrations/${convertToLowerCase(ledger.application)}.svg`);
  const currenciesText = listAllElementsInArray(currencies);
  const currenciesLength = lengthOf(currencies);

  return (
    <BaseHint
      hideIcon
      intent={Intent.INFO}
      title={
        <div className="display--flex align-items--center">
          <Image
            alt={ledger.name}
            className="margin-right--m"
            src={image}
            style={{
              height: 16,
              width: 16,
            }}
          />

          <Text.Bold
            color={typography.TextColor.BLUE_DARK}
            lineHeight={typography.TextLineHeight.EXTRA_LARGE}
            size={typography.TextSize.LEVEL_200}
          >
            {companyIntegrationSettings?.enableBaseCurrencyMode ? (
              `This vendor will be associated with ${ledger.baseCurrencyCode} in your ${ledger.name} account.`
            ) : (
              <>
                {currenciesLength === 0 && (
                  <>
                    {`${ledger.name} limits this vendor's country. `}
                    <button
                      className="link-button font-weight--bold"
                      onClick={() => onRefresh(ledger.id)}
                      type="button"
                    >
                      Please re-sync
                    </button>
                    {` to ensure you have the latest data.`}
                  </>
                )}

                {currenciesLength > 0 &&
                  currenciesLength <= 10 &&
                  `Your settings in ${ledger.name} limit this vendor to countries associated with ${currenciesText}.`}

                {currenciesLength > 10 &&
                  `Your settings in ${ledger.name} may limit this vendor's available currencies.`}
              </>
            )}
          </Text.Bold>
        </div>
      }
    />
  );
};

export default LedgerSettingsLimitVendorCountriesHint;
