import React from 'react';

import { ModalFooter, ModalFooterButtonCancel } from 'components';

import { colors } from 'constants/styles';

import { ViewTeamMembersFooterProps } from './ViewTeamMembersFooter.types';

const ViewTeamMembersFooter: React.VFC<ViewTeamMembersFooterProps> = ({ handleClose }) => (
  <ModalFooter
    style={{
      backgroundColor: colors.colorWhiteHex,
    }}
  >
    <div className="content--left" />
    <div className="content--right">
      <ModalFooterButtonCancel buttonText="Close" onCloseModal={handleClose} />
    </div>
  </ModalFooter>
);

export default ViewTeamMembersFooter;
