import { useMemo } from 'react';

/**
 * Return validators and isRequired value
 * @param {OptionsArg} options
 * @param {Function} options.getIsRequired
 * @param {Function} options.getRowValues
 * @param {Object} options.formValues
 * @param {Function[]|undefined} options.validate
 * @return {{ isRequired: boolean, validators: Function[]|undefined}}
 */
const useDynamicFieldRequiredValidators = ({ getIsRequired, getRowValues, formValues, validate }) => {
  const rowValues = getRowValues?.(); // getRowValues exists for table fields, but not block fields
  const isRequired = Boolean(getIsRequired(undefined, formValues, rowValues));

  const validators = useMemo(
    // using ternaryOnCall here because this is used for every dynamic table field, and we need all
    // the performance gains we can get, no matter how slight.
    // also, note that the first validator in the `validate` array is ALWAYS the validator for `required`
    () => (isRequired ? validate : validate?.slice?.(1)),
    [isRequired, validate],
  );

  return {
    isRequired,
    validators,
  };
};

export default useDynamicFieldRequiredValidators;
