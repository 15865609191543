import { combineReducers } from 'redux';

import byIdReducer from './byIdReducer';
import isDisablingReducer from './isDisablingReducer';
import isFetchingReducer from './isFetchingReducer';

export default combineReducers({
  byId: byIdReducer,
  isDisabling: isDisablingReducer,
  isFetching: isFetchingReducer,
});
