import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { LOGO } from 'global/images/platform';

import { getTopLevelDomain, isPathLogin } from 'helpers/urls';

import './BrandLogo.scss';
import { GET_STARTED } from '../../constants/routes';

import { BrandLogoProps } from './BrandLogo.types';

const BrandLogo: React.VFC<RouteComponentProps & BrandLogoProps> = ({ imageSrc, path, location }) => {
  const logo = <img alt={PLATFORM_DISPLAY_SHORT_NAME} src={imageSrc} />;

  if (isPathLogin(location)) {
    // when BrandLogo is rendered in /login page we want to
    // ensure we redirect to the home of the marketing site (which doesn't live on app. subdomain)
    const domain = getTopLevelDomain();
    return (
      <a className="logo-container" href={domain}>
        {logo}
      </a>
    );
  }

  let parsedPath = path;
  if (location.pathname.includes(GET_STARTED)) {
    parsedPath = location.pathname;
  }

  return (
    <Link className="logo-container" to={parsedPath}>
      {logo}
    </Link>
  );
};

BrandLogo.defaultProps = {
  imageSrc: LOGO.WHITE,
  path: '/',
};

export default withRouter(BrandLogo);
