import isUndefined from 'lodash/isUndefined';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import './SignupV2.module.scss';

import { getCurrentCompanyRequest } from 'actions/currentCompany';
import { fetchCurrentUserRequest } from 'actions/user';

import { BrandWrapperV2 } from 'components';

import {
  ACCOUNT_CREATION_CREATE_ACCOUNT,
  ACCOUNT_CREATION_CREATE_NAMESPACE,
  ACCOUNT_CREATION_VERIFY_EMAIL,
} from 'constants/routes';

import { getCurrentMembershipId } from 'helpers/localStorage';
import { getCompanyNamespaceFromUrl, getDomainWithOrWithoutCompanyNamespace } from 'helpers/urls';

import Authenticated from 'modules/app/AuthenticatedRoute';
import { Help } from 'modules/app/components';
import NotAuthenticated from 'modules/app/NotAuthenticatedRoute';
import { IsLoadingHasWrapper } from 'modules/isLoading';

import { currentCompanyPlatformTypeSelector } from 'selectors/currentCompanySelectors';
import { currentUserHasVerifiedEmail } from 'selectors/currentUserSelectors';

import { getAccountCreationOrDashboardRedirect } from './helpers/getAccountCreationOrDashboardRedirect';
import { CreateAccountTemplate, CreateNamespaceTemplate, VerifyEmailTemplate } from './templates/Templates';

/**
 * Signup container for all steps
 */
const SignupAccountCreation = () => {
  const dispatch = useDispatch();

  const isUserVerified = useSelector(currentUserHasVerifiedEmail);
  const platformType = useSelector(currentCompanyPlatformTypeSelector);
  const redirectPath = getAccountCreationOrDashboardRedirect(platformType, isUserVerified);
  const membershipId = getCurrentMembershipId();

  React.useEffect(() => {
    if (membershipId) {
      dispatch(getCurrentCompanyRequest());
      dispatch(fetchCurrentUserRequest());
    }
  }, [membershipId, dispatch]);

  const hasAllLoginInformation = !isUndefined(platformType) && !isUndefined(isUserVerified);

  React.useEffect(() => {
    if (getCompanyNamespaceFromUrl()) {
      // redirect to base domain if the `signup-accountCreation` page is visited using a subdomain
      window.location.assign(`${getDomainWithOrWithoutCompanyNamespace()}${ACCOUNT_CREATION_CREATE_ACCOUNT}`);
    }
  }, []);

  React.useEffect(() => {
    if (hasAllLoginInformation && redirectPath !== window.location.pathname) {
      window.location.assign(`${getDomainWithOrWithoutCompanyNamespace()}${redirectPath}`);
    }
  }, [hasAllLoginInformation, redirectPath, membershipId]);

  if (membershipId && !hasAllLoginInformation) {
    return <IsLoadingHasWrapper />;
  }

  return (
    <>
      <Help />
      <BrandWrapperV2 className="has--progress-bar" showFooter={false}>
        <Switch>
          {/* Unauthenticated paths */}
          <NotAuthenticated component={CreateAccountTemplate} exact path={ACCOUNT_CREATION_CREATE_ACCOUNT} />

          {/* Authenticated paths */}
          <Authenticated component={VerifyEmailTemplate} exact path={ACCOUNT_CREATION_VERIFY_EMAIL} />

          <Authenticated component={CreateNamespaceTemplate} exact path={ACCOUNT_CREATION_CREATE_NAMESPACE} />
        </Switch>
      </BrandWrapperV2>
    </>
  );
};

export default SignupAccountCreation;
