import React from 'react';

import * as sideSheetsNames from 'constants/sidePanels';

import ManuallyAddBankAccountSideSheet from 'modules/connectBank/ManuallyAddBankAccountSideSheet';

/**
 * Map of External side sheet components
 */
export const sideSheetsMap: Record<string, React.ElementType> = {
  [sideSheetsNames.sidePanelNameManuallyAddBankAccount]: ManuallyAddBankAccountSideSheet,
};
