import React, { PropsWithChildren } from 'react';

const ModalHeader = ({ children, show = true }: PropsWithChildren<{ show?: boolean }>): JSX.Element | null => {
  if (!show) {
    return null;
  }

  return <div className="modal-header">{children}</div>;
};

export default ModalHeader;
