import styled from 'styled-components';

import { containerWidthMap } from 'modules/signup-v3/constants/container';

export const HeaderContent = styled.p`
  font-size: 0.813rem;
  font-weight: 400;
  line-height: 1.125rem;
  margin-top: 0.313rem;
  max-width: 29.375rem;
`;

export const RepresentativesWrapper = styled.div`
  border: 1px solid var(--blue--light);
  border-radius: 0.5rem;
  max-width: ${containerWidthMap.large};
`;

export const RepresentativesHeader = styled.div`
  align-items: center;
  color: var(--grey--xx-dark);
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  padding: 1.5rem;
`;

export const HelperContainer = styled.div`
  margin-top: 2.188rem;
`;

export const Content = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
`;

export const SubtitleList = styled.ul`
  padding-left: 1.125rem;
  padding-top: 1rem;
`;

export const SubtitleItem = styled.li`
  list-style: disc;
  padding-bottom: 0.75rem;

  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const SubtitleEmph = styled.strong`
  font-weight: 600;
`;
