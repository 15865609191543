import React from 'react';

import { ButtonV2, FlexRow, Text } from 'components';

import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';

import { ExternalBlock, ExternalBlockItem } from 'modules/external/ExternalBlocksSection';

import { ExternalReceiptPaymentViewDetailsProps } from './ExternalReceiptPaymentViewDetails.types';

import './ExternalReceiptPaymentViewDetails.scss';

/**
 * Component rendering item details in External Receipt single item header
 */
const ExternalReceiptPaymentViewDetails: React.FC<ExternalReceiptPaymentViewDetailsProps> = ({ onClick }) => (
  <ExternalBlock>
    <ExternalBlockItem className="external-receipt-payment-view-details__trigger">
      <FlexRow
        className="external-receipt-single-item__details align-items--center justify-content--space-between"
        stackOnMobile={false}
      >
        <Text.Semibold size={typography.TextSize.LEVEL_200}>Invoice</Text.Semibold>
        <ButtonV2 intent={Intent.NEUTRAL} onClick={onClick}>
          View details
        </ButtonV2>
      </FlexRow>
    </ExternalBlockItem>
  </ExternalBlock>
);

export default ExternalReceiptPaymentViewDetails;
