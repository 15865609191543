import { DateFormats } from '@routable/shared';
import { formatMoney, unformat } from 'accounting';
import dayjs from 'dayjs';

import { DateStringLength } from 'constants/temporal';

/**
 * Format micro-deposits
 * Expects dollar sign at the beginning of the string, since micro-deposits
 * value always have this character.
 * @param {string} value
 * @return {string}
 */
export const formatMicroDeposit = (value = '') => {
  /**
   * Initialize the value to be formatted with the received value
   */
  let valueToFormat = value;
  /**
   * Save its value as number, removing format with unformat() helper
   */
  const unformatted = unformat(value);
  /**
   * Match the value for dividing scenario: if 0{d} has been entered,
   * where d is a digit different from 0, then perform the division operation.
   * i.e. $04. was entered, then $0.4 will be the result
   */
  const moveToTenths = value.match(/^(\$)(0[1-9])(\.)/);
  /**
   * Check the expected format: $0.0{d} or $0.{dd}, where d is a digit
   * different from 0
   */
  const hasExpectedFormat = value.match(/^(\$)(0\.(0[1-9]|[1-9]{2}))/);

  if (moveToTenths) {
    valueToFormat = unformatted / 10;
  }

  if (hasExpectedFormat) {
    valueToFormat = unformatted;
  }

  const formattedValue = formatMoney(valueToFormat);

  return formattedValue;
};

/**
 * Format a date
 * @param dateFormat
 * @return {*}
 */
export const formatFieldDate =
  (dateFormat = DateFormats.FULL_NUMERIC_MONTH_DAY_YEAR_SLASHED) =>
  (value) => {
    if (!value) {
      return undefined;
    }

    if (value.length < DateStringLength.BIRTH_DATE) {
      return undefined;
    }

    const date = dayjs(value, Object.values(DateFormats), true);

    if (!date.isValid()) {
      return undefined;
    }

    return date.format(dateFormat);
  };

/**
 * When getting user input in a numeric field, there are three important values.
 * 1. decimalScale - this is the official number of decimal digits on the value; operates as the maximum allowed
 * 2. fixedDecimalScale - a boolean value; whether we should force the decimalScale by adding zeros
 * 3. minDecimalScale - if not using fixedDecimalScale, we will only pad zeros if decimal has fewer digits than this number
 * This function uses these three values, and the value of the field, to output a formatted numeric string.
 * @param {Object} options
 * @param {number} options.decimalScale
 * @param {boolean} options.fixedDecimalScale
 * @param {number} options.minDecimalScale
 * @param {number} options.value
 * @return {string}
 */
export const formatNumberForFlexibleDecimalScale = (options = {}) => {
  const { decimalScale, fixedDecimalScale, minDecimalScale, value } = options;

  let stringValue = String(value);

  // do not format empty value
  if (!stringValue) {
    return stringValue;
  }

  // if we are NOT working with a whole number
  if (stringValue.includes('.')) {
    // pop all the trailing zeros
    while (stringValue.endsWith('0')) {
      stringValue = stringValue.slice(0, -1);
    }
  }

  // if the value is 4.25, decimalString will be "25"; if the value is 4, decimalString will be undefined.
  const [, decimalString] = stringValue.split('.');

  // note that if decimalString is undefined, decimalLength will be 0
  const decimalLength = decimalString?.length || 0;

  // if the decimal length meets or exceeds the max number of digits allowed by decimalScale, or if fixedDecimalScale
  // is true (aka, always max), we'll set precision using the decimalScale value.
  //
  // if the decimal length is less than decimalScale, we'll set precision to either:
  // - a) the decimal's current length ("25" -> precision 2)
  // - b) the value of minDecimalScale, if the decimal's current length does not meet the min length requirement
  const formatPrecision =
    decimalLength < decimalScale && !fixedDecimalScale ? Math.max(decimalLength, minDecimalScale) : decimalScale;

  const valueWithDecimalDot = stringValue.includes('.') ? stringValue : stringValue.concat('.');

  // if the minimum allowed precision is 4, and our decimalString is "25", we'll need to format it
  // by padding on the right with two zeros.
  const numZerosToPad = Math.max(formatPrecision - decimalLength, 0);

  // if numZerosToPad is 0, stringPadding will be an empty string
  // note: it can't be less than zero, since it's set to Math.max(someNumber, 0)
  const stringPadding = '0'.repeat(numZerosToPad);

  // strip trailing dots
  return `${valueWithDecimalDot}${stringPadding}`.replace(/\.$/g, '');
};
