import React from 'react';

import { FlexCol, Text } from 'components';

import { typography } from 'constants/styles';

import type { CompanyRegisteredAddressDisplayValueProps } from './CompanyRegisteredAddressDisplayValue.types';

/**
 * Component rendering the content of the "Registered Address" value in the Company Address
 * Data Card
 * @param props - Component props
 */
const CompanyRegisteredAddressDisplayValue: React.VFC<CompanyRegisteredAddressDisplayValueProps> = ({
  address,
  countryName,
}) => (
  <FlexCol>
    <Text.Regular color={typography.TextColor.BLACK}>{address.streetAddress}</Text.Regular>
    <Text.Regular color={typography.TextColor.BLACK}>
      {address.postalcode} {address.city}
    </Text.Regular>
    <Text.Regular color={typography.TextColor.BLACK}>{countryName}</Text.Regular>
  </FlexCol>
);

export default CompanyRegisteredAddressDisplayValue;
