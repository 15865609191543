import { StatusIndicator } from '@routable/gross-ds';
import React from 'react';

import type { Membership } from 'interfaces/memberships';

import { getMembershipVerificationStatusObj } from './helpers';

const CompanyRepTableStatus = ({ membership }: { membership: Membership & User }): JSX.Element => {
  const { showStatus, text } = getMembershipVerificationStatusObj({
    membership,
  });

  return (
    <StatusIndicator className="text-xs" status={showStatus}>
      {text}
    </StatusIndicator>
  );
};

export default CompanyRepTableStatus;
