import { RoutableEventsSync, useEventSync } from '@routable/framework';
import { useAddToast } from '@routable/gross-ds';
import { useMutation } from '@tanstack/react-query';
import { queryContext } from 'milton/components';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { purchaseOrderService } from 'hooks/purchaseOrders/purchaseOrder.service';
import { useGetBillDiscrepancies } from 'hooks/purchaseOrders/useBillDiscrepancies/useGetBillDiscrepancies.hook';
import { usePurchaseOrder } from 'hooks/purchaseOrders/usePurchaseOrder.hook';
import { usePurchaseOrderItemData } from 'hooks/purchaseOrders/usePurchaseOrderItemData.hook';

import { fetchThreadRequest } from 'actions/thread';

import { FEATURE_FLAG_PO_MATCHING_SHOW_DISCREPANCIES } from 'constants/featureFlags';

import { trackEvent, TrackEventName } from 'helpers/eventTracking';

import useFeatureFlag from 'hooks/useFeatureFlag';
import useSelectorWithProps from 'hooks/useSelectorWithProps';

import { itemSelector } from 'selectors/itemsSelectors';

interface RefreshDiscrepanciesContextProps {
  hasRefreshBeenTriggered: boolean;
  isRefreshingDiscrepancies: boolean;
  onRefreshDiscrepancies: () => void;
}

interface RefreshDiscrepanciesProviderProps {
  children: ReactNode;
  id: string;
}

export const RefreshDiscrepanciesContext = createContext<RefreshDiscrepanciesContextProps | undefined>(undefined);

export const RefreshDiscrepanciesProvider = ({ children, id }: RefreshDiscrepanciesProviderProps): JSX.Element => {
  const [hasRefreshBeenTriggered, setRefreshHasBeenTriggered] = useState(false);
  const [isRefreshingDiscrepancies, setIsRefreshing] = useState(false);

  const isPoDiscrepancyEnabled = useFeatureFlag(FEATURE_FLAG_PO_MATCHING_SHOW_DISCREPANCIES);

  const item = useSelectorWithProps(itemSelector, id);
  const purchaseOrderId = item?.purchaseOrders?.[0]?.ledgerRef;

  const { isFetching: isFetchingDiscrepancies } = useGetBillDiscrepancies({
    id: isPoDiscrepancyEnabled && !!purchaseOrderId && id,
  });

  useEventSync('set-refreshing-discrepancies', ({ isLoading }: { isLoading: boolean }) => {
    setIsRefreshing(isLoading);
  });

  const mutationFn = async () => {
    setIsRefreshing(true);
    setRefreshHasBeenTriggered(true);
    purchaseOrderService.refreshDiscrepancies({ id });
  };

  const { mutate: refreshDiscrepancies } = useMutation({
    context: queryContext,
    mutationFn,
    mutationKey: ['purchase_orders', 'refresh_discrepancies', id],
  });

  const onRefreshDiscrepancies = () => {
    trackEvent(TrackEventName.REFRESH_PO_MATCHING);
    refreshDiscrepancies();
  };

  return (
    <RefreshDiscrepanciesContext.Provider
      value={{
        hasRefreshBeenTriggered,
        isRefreshingDiscrepancies: isFetchingDiscrepancies || isRefreshingDiscrepancies,
        onRefreshDiscrepancies,
      }}
    >
      {children}
    </RefreshDiscrepanciesContext.Provider>
  );
};

export const useRefreshDiscrepanciesContext = () => {
  const refreshDiscrepanciesContext = useContext(RefreshDiscrepanciesContext);
  if (!refreshDiscrepanciesContext) {
    throw new Error('useRefreshDiscrepanciesContext must be used within a RefreshDiscrepanciesProvider');
  }
  return refreshDiscrepanciesContext;
};

export const useHandleRefreshDiscrepancies = ({ id }: { id: string }) => {
  const addToast = useAddToast();
  const dispatch = useDispatch();

  const isPoDiscrepancyEnabled = useFeatureFlag(FEATURE_FLAG_PO_MATCHING_SHOW_DISCREPANCIES);

  const item = useSelectorWithProps(itemSelector, id);
  const purchaseOrderId = item?.purchaseOrders?.[0]?.ledgerRef;

  const { refetch: onRefetchDiscrepancies } = useGetBillDiscrepancies({
    id: isPoDiscrepancyEnabled && !!purchaseOrderId && id,
  });
  const { refetch: onRefetchPoData } = usePurchaseOrderItemData(purchaseOrderId, item?.ledgerRef);
  const { refetch: onRefetchPo } = usePurchaseOrder(purchaseOrderId);

  useEventSync(
    `refresh-po-discrepancies-${id}`,
    ({ isLoading, shouldRefetch }: { isLoading: boolean; shouldRefetch: false }) => {
      if (shouldRefetch) {
        onRefetchPo();
        onRefetchPoData();
        onRefetchDiscrepancies();
        dispatch(fetchThreadRequest(item.thread));

        addToast({
          message: 'PO matching is up to date',
          type: 'success',
        });
      }

      RoutableEventsSync.Publish('set-refreshing-discrepancies', { isLoading });
    },
  );
};
