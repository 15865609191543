import { fetchPartnershipPaymentOptionsSuccess } from 'actions/payments';

import { RoutableApiResponse } from 'interfaces/routableApiResponse';

import { routableApi, RoutableTags } from './routableApi';

const extendedPaymentApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    /** Get data based on the specific partnership id */
    getPartnershipPaymentOptions: builder.query<RoutableApiResponse, { partnershipId: string }>({
      query: ({ partnershipId }) => ({
        url: `/partnerships/${partnershipId}/payment/`,
      }),
      providesTags: [RoutableTags.Partnership],
      async onQueryStarted(_response, api) {
        const payload = await api.queryFulfilled;
        api.dispatch(fetchPartnershipPaymentOptionsSuccess(payload.data));
      },
    }),
  }),
});

export const { useLazyGetPartnershipPaymentOptionsQuery } = extendedPaymentApi;

export type useLazyGetPartnershipPaymentOptionsQuery = typeof useLazyGetPartnershipPaymentOptionsQuery;
