import React from 'react';

import ExternalLink from 'components/ExternalLink';

import { NETSUITE } from 'constants/integration';
import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';
import { COMPANIES_ROUTE } from 'constants/routes';
import { Intent } from 'constants/ui';

import { COMPANIES_LIST_FILTERS } from 'enums/routes';

import { getJoinedPath } from 'helpers/routeHelpers';
import { convertToUpperCase } from 'helpers/stringHelpers';

import BaseHint from '../BaseHint';

import type { OnlyDomesticCustomersSupportedHintProps } from './OnlyDomesticCustomersSupportedHint.types';

/**
 * Hint notifying the user that only domestic customers are supported, used when creating a new customer
 */
const OnlyDomesticCustomersSupportedHint: React.FC<OnlyDomesticCustomersSupportedHintProps> = ({
  ledgerName,
  partnership,
}) => (
  <BaseHint
    intent={Intent.DANGER}
    text={
      <>
        {`In ${ledgerName}, this customer (`}
        <ExternalLink
          className="primary"
          href={getJoinedPath(COMPANIES_ROUTE, COMPANIES_LIST_FILTERS.CUSTOMERS, partnership.id)}
        >
          {partnership.name}
        </ExternalLink>
        {`) is only able to receive ${convertToUpperCase(partnership.currencyCodeCustomer)}. `}
        {`Therefore, we cannot add them to ${PLATFORM_DISPLAY_SHORT_NAME}.`}
      </>
    }
    title="Only domestic customers are supported at this time"
  />
);

OnlyDomesticCustomersSupportedHint.defaultProps = {
  ledgerName: NETSUITE,
};

export default OnlyDomesticCustomersSupportedHint;
