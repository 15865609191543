import type { ControllerFieldState } from 'react-hook-form';
import type { ZodEnum } from 'zod';

/**
 * Given a zod enum, it grabs all of the values from that enum and returns
 * label-value pairs using that values.
 * @param zodEnum
 * @param labelsMap - Optional map of labels associated with each enum value
 * @returns Array of label-value pairs
 */
export const getLabelValuePairsFromEnum = <T extends ZodEnum<[string, ...string[]]>>(
  zodEnum: T,
  labelsMap?: Record<string, string>,
): { label: string; value: string }[] =>
  Object.values(zodEnum.Values).map((value) => ({
    label: labelsMap ? labelsMap[value] : value,
    value,
  }));

/**
 * Given a controlled field state, transforms errors to a shape
 * legacy components understand if an error exist or explicitly
 * returns `undefined` otherwise to ensure we're not setting the `errors`
 * prop when we don't need to
 * @param fieldState - Controlled field state
 * @param fieldName - String represting field name
 */
export const getErrorsFromFieldState = (
  fieldState: ControllerFieldState,
  fieldName: string,
): undefined | Record<string, string[]> =>
  fieldState?.error ? { [fieldName]: [fieldState.error.message || fieldState.error.type] } : undefined;
