import classNames from 'classnames';
import React from 'react';

import { ternary } from 'helpers/utility';

import { useToggle } from 'hooks';

import { PaymentMethodBase, PaymentMethodDetails, PaymentMethodHeader, PaymentMethodStatus } from '..';
import { PaymentMethodVariant } from '../../enums';
import { getPaymentMethodHeader, getPaymentMethodIcon, getPaymentMethodStatus } from '../../helpers';

import type { PaymentMethodViewProps } from './PaymentMethodView.types';

/**
 * Renders a (view-only) payment method's funding account information.
 */
const PaymentMethodView: React.FC<PaymentMethodViewProps> = ({
  isExpanded: isExpandedOverride,
  creator,
  fundingAccount,
  fundingInfoAddress,
  fundingInfoBankAccount,
  fundingInfoInternational,
  fundingSources,
  isPartnerAccountInDashboard,
  partnership,
  partnershipFundingAccount,
  showFundingAccountId,
}) => {
  const [isExpanded, toggleExpanded] = useToggle(isExpandedOverride);
  const { subtitle, title } = getPaymentMethodHeader({
    fundingAccount,
    fundingInfoBankAccount,
    fundingInfoAddress,
  });
  const displaySubtitle = ternary(isExpanded, '', subtitle);
  const status = getPaymentMethodStatus({
    fundingAccount,
    fundingSources,
    isPartnerAccountInDashboard,
    partnershipFundingAccount,
  });

  return (
    <PaymentMethodBase variant={PaymentMethodVariant.VIEW}>
      <PaymentMethodHeader
        currencyCode={fundingAccount.currencyCode}
        fundingIcon={getPaymentMethodIcon(fundingAccount)}
        isExpanded={isExpanded}
        onToggleExpand={toggleExpanded}
        status={status}
        subtitle={displaySubtitle}
        title={title}
      />
      <div
        className={classNames('payment_method__expandable', {
          expanded: isExpanded,
        })}
      >
        <PaymentMethodDetails
          creator={creator}
          fundingAccount={fundingAccount}
          fundingInfoAddress={fundingInfoAddress}
          fundingInfoBankAccount={fundingInfoBankAccount}
          fundingInfoInternational={fundingInfoInternational}
          isPartnerAccountInDashboard={isPartnerAccountInDashboard}
          partnership={partnership}
          partnershipFundingAccount={partnershipFundingAccount}
          showFundingAccountId={showFundingAccountId}
        />
      </div>
      <PaymentMethodStatus currencyCode={fundingAccount.currencyCode} status={status} />
    </PaymentMethodBase>
  );
};

export default PaymentMethodView;
