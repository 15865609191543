import React from 'react';

import { ButtonV2, TooltipMUIConditionalWrapper } from 'components';

import { ButtonSize } from 'constants/button';
import { Intent } from 'constants/ui';

import { AddTaxFormButtonDisplayType } from './AddTaxFormButton.enums';
import type { AddTaxFormButtonProps } from './AddTaxFormButton.types';

const AddTaxFormButton = ({
  displayType,
  isLoading,
  isDisabled,
  onClick,
  tooltipProps,
  label = 'Add tax form',
}: AddTaxFormButtonProps): JSX.Element => {
  const size = displayType === AddTaxFormButtonDisplayType.LARGE ? ButtonSize.MEDIUM : ButtonSize.SMALL;

  return (
    <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
      <ButtonV2 intent={Intent.NEUTRAL} isDisabled={isDisabled} isLoading={isLoading} onClick={onClick} size={size}>
        {label}
      </ButtonV2>
    </TooltipMUIConditionalWrapper>
  );
};

export default AddTaxFormButton;
