import { PaymentDeliveryMethodType, PaymentDeliveryOptionType } from '@routable/shared';
import _reduce from 'lodash/reduce';

import { ExternallyPaidPaymentMethods, ItemPaymentDeliveryOptionText, ItemPaymentMethodsText } from 'constants/item';

import { PaymentSources } from 'enums/billing';
import { ItemKinds } from 'enums/items';

import { getBillingDataForPaymentDeliveryOption, formatting } from 'helpers/billing';
import {
  hasItemExternallyPaidMethod,
  isItemKindReceivable,
  isItemPaymentDeliveryMethodAchOrInternational,
  isItemPaymentDeliveryMethodCheck,
  isKindPayable,
} from 'helpers/items';
import { capitalize } from 'helpers/stringHelpers';

import type { BillingCodeData, BillingCodeDataByCode } from 'interfaces/billing';
import type { CurrencyCode } from 'interfaces/currency';
import type { FundingAccount } from 'interfaces/fundingAccount';
import type { SupportedCountriesWithPaymentOptions, PartnerPaymentOption } from 'interfaces/fundingSupportedCountries';
import type { Item } from 'interfaces/item';
import type { PaymentDeliveryOptionSpeed } from 'interfaces/payments';

import type { PaymentMethodSelectOption } from './paymentMethods.types';

/**
 * Returns a list of Externally Paid payment method options
 */
export const getExternallyPaidPaymentMethods = (): PaymentMethodSelectOption[] =>
  Object.values(ExternallyPaidPaymentMethods).map((option) => ({
    id: option,
    value: option,
    text: capitalize(ItemPaymentMethodsText[option]),
  }));

/**
 * Given an item, returns correct icon class name used in a payment method manage/view components.
 * @param item
 */
export const getPaymentMethodIconClass = (item: Item): string | null => {
  if (isItemPaymentDeliveryMethodAchOrInternational(item)) {
    return 'icon-ic-bank';
  }

  if (isItemPaymentDeliveryMethodCheck(item)) {
    return 'icon-ic-check';
  }

  if (hasItemExternallyPaidMethod(item)) {
    return 'icon-ic-edit';
  }

  return null;
};

/**
 * Returns true if payment delivery method is ACH
 * @param paymentDeliveryMethod
 */
export const isPaymentDeliveryMethodAch = (
  paymentDeliveryMethod: PaymentDeliveryMethodType,
): paymentDeliveryMethod is PaymentDeliveryMethodType.ACH => paymentDeliveryMethod === PaymentDeliveryMethodType.ACH;

/**
 * Returns true if payment delivery method is INTERNATIONAL
 * @param paymentDeliveryMethod
 */
export const isPaymentDeliveryMethodInternational = (
  paymentDeliveryMethod: PaymentDeliveryMethodType,
): paymentDeliveryMethod is PaymentDeliveryMethodType.INTERNATIONAL =>
  paymentDeliveryMethod === PaymentDeliveryMethodType.INTERNATIONAL;

/**
 * Returns true if payment delivery method is either ACH or INTERNATIONAL
 * @param paymentDeliveryMethod
 */
export const isPaymentDeliveryMethodAchOrInternational = (paymentDeliveryMethod: PaymentDeliveryMethodType): boolean =>
  isPaymentDeliveryMethodAch(paymentDeliveryMethod) || isPaymentDeliveryMethodInternational(paymentDeliveryMethod);

/**
 * Returns true if payment delivery method is any
 * @param paymentDeliveryMethod
 */
export const isPaymentDeliveryMethodAny = (
  paymentDeliveryMethod: PaymentDeliveryMethodType,
): paymentDeliveryMethod is PaymentDeliveryMethodType.ANY => paymentDeliveryMethod === PaymentDeliveryMethodType.ANY;

/**
 * Returns true if payment delivery method is CHECK
 * @param paymentDeliveryMethod
 */
export const isPaymentMethodDeliveryCheck = (
  paymentDeliveryMethod: PaymentDeliveryMethodType,
): paymentDeliveryMethod is PaymentDeliveryMethodType.CHECK =>
  paymentDeliveryMethod === PaymentDeliveryMethodType.CHECK;

/**
 * Returns true if payment delivery method is either Check or ACH
 * @param paymentDeliveryMethod
 */
export const isPaymentDeliveryMethodCheckOrACH = (paymentDeliveryMethod: PaymentDeliveryMethodType): boolean =>
  isPaymentDeliveryMethodAch(paymentDeliveryMethod) || isPaymentMethodDeliveryCheck(paymentDeliveryMethod);

/**
 * Returns true if payment delivery option is ACH_SAME_DAY
 * @param paymentDeliveryOption
 */
export const isPaymentDeliveryOptionAchSameDay = (
  paymentDeliveryOption: PaymentDeliveryOptionType,
): paymentDeliveryOption is PaymentDeliveryOptionType.ACH_SAME_DAY =>
  paymentDeliveryOption === PaymentDeliveryOptionType.ACH_SAME_DAY;

/**
 * Returns true if payment delivery option is ACH_NEXT_DAY
 * @param paymentDeliveryOption
 */
export const isPaymentDeliveryOptionAchNextDay = (
  paymentDeliveryOption: PaymentDeliveryOptionType,
): paymentDeliveryOption is PaymentDeliveryOptionType.ACH_NEXT_DAY =>
  paymentDeliveryOption === PaymentDeliveryOptionType.ACH_NEXT_DAY;

/**
 * Returns true if payment delivery option is RTP
 * @param paymentDeliveryOption
 */
export const isPaymentDeliveryOptionRTP = (
  paymentDeliveryOption: PaymentDeliveryOptionType,
): paymentDeliveryOption is PaymentDeliveryOptionType.RTP => paymentDeliveryOption === PaymentDeliveryOptionType.RTP;

/**
 * Returns true if payment delivery option speed is same or next day
 * @param optionSpeed
 */
export const isPaymentDeliveryOptionSpeedSameOrNextDay = (optionSpeed?: PaymentDeliveryOptionSpeed): boolean => {
  if (!optionSpeed) {
    return false;
  }

  return Boolean(optionSpeed.earliest <= 1 && optionSpeed.earliest === optionSpeed.latest);
};

/**
 * Returns list of available payment methods. Meant to be used as options in Select
 * components.
 * @param paymentMethodOptions
 * @param isInInternationalContext
 */
export const getAvailablePaymentMethods = (
  paymentMethodOptions: PaymentDeliveryMethodType[],
  isInInternationalContext = false,
): PaymentMethodSelectOption[] => {
  // Make a copy of passed paymentMethodOptions list
  let targetOptions = [...paymentMethodOptions];

  // In the context of international vendors/items, the Check and ACH
  // options should not be available
  if (isInInternationalContext) {
    targetOptions = targetOptions.filter(
      (paymentDeliveryMethod) => !isPaymentDeliveryMethodCheckOrACH(paymentDeliveryMethod),
    );
  } else {
    // if not operating under the international context, we don't want the INTERNATIONAL
    // option to be available
    targetOptions = targetOptions.filter(
      (paymentDeliveryMethod) => !isPaymentDeliveryMethodInternational(paymentDeliveryMethod),
    );
  }

  // return options mapped to a shape used by select components
  return targetOptions.map((option) => ({
    id: option,
    value: option,
    text: capitalize(ItemPaymentMethodsText[option]),
  }));
};

/**
 * Returns true if paymentDeliveryMethod is included in the list of available payment
 * delivery methods. We need to ensure that available payment delivery methods list is
 * in fact passed as it only exists once the company is verified
 * @param availablePaymentDeliveryMethods - List of available payment delivery methods (usually related to company)
 * @param paymentDeliveryMethod - Payment delivery method we want to check against
 */
export const isPaymentDeliveryMethodAvailable = (
  availablePaymentDeliveryMethods?: PaymentDeliveryMethodType[],
  paymentDeliveryMethod?: PaymentDeliveryMethodType,
): boolean => {
  if (!availablePaymentDeliveryMethods || !paymentDeliveryMethod) {
    return false;
  }

  return availablePaymentDeliveryMethods.includes(paymentDeliveryMethod);
};

/**
 * Returns whether ACH is available, given a list of available payment methods.
 * @param availablePaymentMethods
 */
export const isPaymentMethodAvailableACH = (availablePaymentMethods?: PaymentDeliveryMethodType[]): boolean =>
  isPaymentDeliveryMethodAvailable(availablePaymentMethods, PaymentDeliveryMethodType.ACH);

/**
 * Returns whether International is available, given a list of available payment methods.
 * @param availablePaymentMethods
 */
export const isPaymentMethodAvailableInternational = (availablePaymentMethods?: PaymentDeliveryMethodType[]): boolean =>
  isPaymentDeliveryMethodAvailable(availablePaymentMethods, PaymentDeliveryMethodType.INTERNATIONAL);

/**
 * Returns whether check is available, given a list of available payment methods.
 * @param availablePaymentMethods
 */
export const isPaymentMethodAvailableCheck = (availablePaymentMethods?: PaymentDeliveryMethodType[]): boolean =>
  isPaymentDeliveryMethodAvailable(availablePaymentMethods, PaymentDeliveryMethodType.CHECK);

/**
 * Filters out a given value from a given array of payment delivery options.
 * @param availablePaymentMethods
 * @param removedPaymentDeliveryOption
 */
export const filterPaymentDeliveryOptions = (
  availablePaymentMethods: PaymentDeliveryMethodType[] = [],
  removedPaymentDeliveryOption?: PaymentDeliveryMethodType,
): PaymentDeliveryMethodType[] => availablePaymentMethods.filter((method) => method !== removedPaymentDeliveryOption);

/**
 * Returns whether multiple payment methods are available, given a list of available payment methods.
 * @param availablePaymentMethods
 */
export const areMultiplePaymentMethodsAvailable = (availablePaymentMethods: PaymentDeliveryMethodType[]): boolean =>
  availablePaymentMethods.length > 1;

/**
 * Returns true if given payment delivery option is available
 * @param availablePaymentDeliveryMethods
 * @param paymentDeliveryOption
 * @param billingDataByCode
 * @param itemKind
 */
export const isPaymentDeliveryOptionAvailable = (
  availablePaymentDeliveryMethods: PaymentDeliveryMethodType[],
  paymentDeliveryOption: PaymentDeliveryOptionType,
  billingDataByCode: BillingCodeDataByCode,
  itemKind: ItemKinds,
): ReturnType<typeof isPaymentDeliveryMethodAvailable> => {
  const billingData = getBillingDataForPaymentDeliveryOption({
    billingDataByCode,
    paymentDeliveryOption,
    kind: itemKind,
  });

  if (!billingData) {
    return false;
  }

  return isPaymentDeliveryMethodAvailable(availablePaymentDeliveryMethods, billingData.paymentDeliveryMethod);
};

/**
 * Get check delivery date ranges depending on PaymentDeliveryOptionType
 * @param item
 */
export const getCheckArrivalDateRange = (item: Item): string => {
  switch (item.paymentDeliveryOption) {
    case PaymentDeliveryOptionType.CHECK_EXPEDITED:
      return '1-2 business days';
    case PaymentDeliveryOptionType.CHECK_INTERNATIONAL:
      return '10-14 business days';
    default:
      // PaymentDeliveryOptionType.CHECK_STANDARD
      return '7-10 business days';
  }
};

/**
 * Get delivery options available for the given item data.
 */
export const getAvailablePaymentDeliveryOptionsForItem = (
  item: Partial<Item>,
  billingCodeData: BillingCodeDataByCode,
  paymentDeliveryOptions: string[],
): BillingCodeData[] => {
  const billingCodes: BillingCodeData[] = _reduce(
    billingCodeData,
    (arr: BillingCodeData[], codeData: BillingCodeData) => {
      const paymentKindMatch = codeData.paymentKind === item.kind;
      const paymentOptionExist = paymentDeliveryOptions.find((option) => option === codeData.paymentDeliveryOption);

      if (paymentKindMatch && paymentOptionExist) {
        return [...arr, codeData];
      }

      return arr;
    },
    [],
  );

  return billingCodes;
};

/**
 * Get a filtered delivery options available for the given item kind and delivery method.
 */
const getFilteredDeliveryOptions = (
  item: Item,
  billingCodeData: BillingCodeDataByCode,
  paymentDeliveryOptions: PaymentDeliveryOptionType[],
) => {
  const optionsByBillingData = getAvailablePaymentDeliveryOptionsForItem(item, billingCodeData, paymentDeliveryOptions);

  const filteredOptions = optionsByBillingData.reduce((filteredOptionsArray, currentOption) => {
    const billingCodeOptionExists = paymentDeliveryOptions.find(
      (option) => option === currentOption.paymentDeliveryOption,
    );
    const currentOptionExistsOnFilteredOptions = filteredOptionsArray.find(
      (option) => option.paymentDeliveryOption === currentOption.paymentDeliveryOption,
    );

    if (billingCodeOptionExists && !currentOptionExistsOnFilteredOptions) {
      const newOption = {
        ...currentOption,
        title: formatting.getBillingCodePaymentDeliveryOptionText(currentOption),
      };
      return [...filteredOptionsArray, newOption];
    }

    return filteredOptionsArray;
  }, []);

  return filteredOptions;
};

/**
 * Returns an array with the billing codes used as options for delivery method select
 * @param allBillingCodes
 * @param partnerPaymentOptions
 * @param paymentDeliveryMethodSelected
 * @param paymentFundingSourceSelected
 * @returns [BillingCodeData]
 */
export const getFilteredCodesByPaymentSource = ({
  allBillingCodes,
  partnerPaymentOptions,
  paymentDeliveryMethodSelected,
  paymentFundingSourceSelected,
}: {
  allBillingCodes: BillingCodeData[];
  partnerPaymentOptions: PartnerPaymentOption[];
  paymentDeliveryMethodSelected: PaymentDeliveryMethodType;
  paymentFundingSourceSelected: FundingAccount;
}): BillingCodeData[] => {
  const source = paymentFundingSourceSelected?.balance ? PaymentSources.BALANCE : PaymentSources.BANK;

  if (!partnerPaymentOptions || !paymentDeliveryMethodSelected) {
    return [];
  }

  const { options } = partnerPaymentOptions.find(({ method }) => method === paymentDeliveryMethodSelected);

  return options.reduce((filteredBillingCodes, currentOption) => {
    /**
     * Find all the codes that match the selected deliveryMethod and deliveryOption
     */
    const foundCodes = allBillingCodes.filter(
      ({ paymentKind, paymentDeliveryMethod, paymentDeliveryOption }) =>
        paymentKind === ItemKinds.PAYABLE &&
        paymentDeliveryMethodSelected === paymentDeliveryMethod &&
        paymentDeliveryOption === currentOption,
    );

    /**
     * No matches: no codes found for currentOption. This happens when for example paymentDeliveryMethodSelected
     * is ACH and currentOption is check, or vice versa
     */
    if (foundCodes.length === 0) {
      return filteredBillingCodes;
    }

    /**
     * Several matches: this happens when the paymentDeliveryOption exists for bank and for balance (ach_next_day,
     * ach_same_day). In which case we need to return just one of them, based on the payment source.
     */
    if (foundCodes.length > 1) {
      return [...filteredBillingCodes, foundCodes.find(({ paymentSource }) => paymentSource === source)];
    }

    /**
     * Single match: this happens for example with RTP, then we just append the code to the already filtered ones
     */
    return [...filteredBillingCodes, foundCodes[0]];
  }, []);
};

/**
 * Return the available delivery method options with payment option
 * @param item
 * @param billingCodeData
 * @param partnerPaymentOptions
 */
export const getAvailablePaymentDeliveryOptions = (
  item: Item,
  billingCodeData: BillingCodeDataByCode,
  partnerPaymentOptions: PartnerPaymentOption[],
): ReturnType<typeof getFilteredDeliveryOptions | typeof getAvailablePaymentDeliveryOptionsForItem> => {
  const paymentDeliveryOptions = partnerPaymentOptions?.find(
    (option) => option.method === item.paymentDeliveryMethod,
  )?.options;

  if (!paymentDeliveryOptions?.length) {
    return [];
  }

  const shouldFilterOptions = isPaymentDeliveryMethodInternational(item.paymentDeliveryMethod);
  if (shouldFilterOptions) {
    return getFilteredDeliveryOptions(item, billingCodeData, paymentDeliveryOptions);
  }

  return getAvailablePaymentDeliveryOptionsForItem(item, billingCodeData, paymentDeliveryOptions);
};

/**
 * Returns the result of getAvailablePaymentDeliveryOptions or getFilteredCodesByPaymentSource
 * based on provided arguments.
 * @returns [BillingCodeData]
 */
export const getPaymentDeliveryOptions = ({
  item,
  billingCodeData,
  partnerPaymentOptions,
  paymentFundingSourceSelected,
}: {
  item: Item;
  billingCodeData: BillingCodeDataByCode;
  partnerPaymentOptions: PartnerPaymentOption[];
  paymentFundingSourceSelected: FundingAccount;
}): ReturnType<typeof getAvailablePaymentDeliveryOptions | typeof getFilteredCodesByPaymentSource> => {
  const shouldFilterCodesByPaymentSource =
    isKindPayable(item.kind) && !isPaymentDeliveryMethodInternational(item.paymentDeliveryMethod);

  if (shouldFilterCodesByPaymentSource) {
    return getFilteredCodesByPaymentSource({
      allBillingCodes: Object.values(billingCodeData),
      partnerPaymentOptions,
      paymentDeliveryMethodSelected: item.paymentDeliveryMethod,
      paymentFundingSourceSelected,
    });
  }

  return getAvailablePaymentDeliveryOptions(item, billingCodeData, partnerPaymentOptions);
};

/**
 * Given an item kind and a payment delivery option, return the delivery method to
 * default to, if we don't have one that's prioritized.
 * @param params
 * @param params.billingDataByCode
 * @param params.itemKind
 * @param params.paymentMethod
 */
export const getFallbackDeliveryOptionForPaymentMethod = ({
  billingDataByCode,
  itemKind,
  paymentMethod,
}: {
  billingDataByCode: BillingCodeDataByCode;
  itemKind: ItemKinds;
  paymentMethod: PaymentDeliveryMethodType;
}): PaymentDeliveryOptionType | null => {
  if (isKindPayable(itemKind)) {
    if (isPaymentDeliveryMethodAch(paymentMethod)) {
      const billingData = getBillingDataForPaymentDeliveryOption({
        billingDataByCode,
        paymentDeliveryOption: PaymentDeliveryOptionType.ACH_EXPEDITED,
        kind: itemKind,
      });

      // it's possible that we could turn off expedited ach,
      // as there's a certain amount of risk involved with this option
      if (billingData && +billingData.paymentDeliveryOptionMax > 0) {
        return PaymentDeliveryOptionType.ACH_EXPEDITED;
      }

      // if expedited ach is off, use standard, which should
      // always be available
      return PaymentDeliveryOptionType.ACH_STANDARD;
    }

    if (isPaymentMethodDeliveryCheck(paymentMethod)) {
      return PaymentDeliveryOptionType.CHECK_STANDARD;
    }

    if (isPaymentDeliveryMethodInternational(paymentMethod)) {
      return PaymentDeliveryOptionType.INTERNATIONAL_SWIFT;
    }
  }

  // receivable ach
  if (isPaymentDeliveryMethodAch(paymentMethod)) {
    return PaymentDeliveryOptionType.ACH_STANDARD;
  }

  // delivery method 'any'
  return null;
};

/**
 * Returns the initial value for the item.paymentDeliveryMethod for the createItem form used during item edit
 * @param item
 */
export const getPaymentDeliveryMethodInitialValueForItemEdit = (item: Item): PaymentDeliveryMethodType =>
  // Receivables use ACH
  isItemKindReceivable(item)
    ? PaymentDeliveryMethodType.ACH
    : // Payables should default to 'any'
      item.paymentDeliveryMethod || PaymentDeliveryMethodType.ANY;

/**
 * Returns the initial values for item.paymentMethodsOptions used in createItems (both for the create item and item edit flows)
 * @param paymentMethods
 */
export const getPaymentMethodOptionsInitialValue = ({
  isInternational = false,
  methodsAccepted = [],
}: {
  isInternational: boolean;
  methodsAccepted: PaymentDeliveryMethodType[];
}): PaymentDeliveryMethodType[] => {
  const paymentMethods = [PaymentDeliveryMethodType.ANY];
  if (methodsAccepted.includes(PaymentDeliveryMethodType.INTERNATIONAL) && isInternational) {
    paymentMethods.push(PaymentDeliveryMethodType.INTERNATIONAL);
  }
  if (methodsAccepted.includes(PaymentDeliveryMethodType.ACH) && !isInternational) {
    paymentMethods.push(PaymentDeliveryMethodType.ACH);
  }
  if (methodsAccepted.includes(PaymentDeliveryMethodType.CHECK) && !isInternational) {
    paymentMethods.push(PaymentDeliveryMethodType.CHECK);
  }

  return paymentMethods;
};

/**
 * Returns the initial value for item.paymentDeliveryMethodsAccepted
 * paymentDeliveryMethodsAccepted lives on Item as an array but the form expected an object
 * @param item
 */
export const getPaymentDeliveryMethodsAcceptedInitialValueForItemEdit = ({
  isInternational = false,
  methodsAccepted,
}: {
  isInternational: boolean;
  methodsAccepted: PaymentDeliveryMethodType[];
}): { [x: string]: boolean } => ({
  [PaymentDeliveryMethodType.ACH]: isPaymentMethodAvailableACH(methodsAccepted) && !isInternational,
  [PaymentDeliveryMethodType.CHECK]: isPaymentMethodAvailableCheck(methodsAccepted) && !isInternational,
  [PaymentDeliveryMethodType.INTERNATIONAL]: isPaymentMethodAvailableInternational(methodsAccepted) && isInternational,
});

/**
 * Returns text used in the tooltip when the payment delivery option select is locked
 * @param item
 * @param currencyCode
 */
export const getPaymentDeliveryOptionLockedTooltipText = (
  item: Item,
  currencyCodeReceiver: CurrencyCode,
  companyName: string,
): string => {
  const companyNameCopy = companyName ? `to ${companyName} ` : '';

  return (
    `Bank transfers in ${currencyCodeReceiver} ${companyNameCopy}` +
    `can only be sent via ${ItemPaymentDeliveryOptionText[item.paymentDeliveryOption]}.`
  );
};

/**
 * Returns an array of payment delivery options that are available for a given SupportedCountries object and a Items list
 * @param supportedCountries
 * @param selectedInvoices
 * @returns [string]
 */
export const getPartnerPaymentOptionsByCountryAndCurrencyCode = (
  supportedCountries: SupportedCountriesWithPaymentOptions,
  selectedInvoices: Item[],
): string[] => {
  let paymentOptions = [];
  selectedInvoices.forEach(({ currencyCode, countryCode }) => {
    if (supportedCountries[countryCode]) {
      const country = supportedCountries[countryCode];
      const options = country.paymentOptions[currencyCode.toLowerCase()]?.[0];

      if (options) {
        paymentOptions = paymentOptions.concat(options);
      }
    }
  });

  // remove duplicates
  return Array.from(new Set(paymentOptions));
};
