import React from 'react';

import { FlexCol } from 'components';

import { DateFormats } from 'constants/date';

import { getDate } from 'helpers/date';
import { hasLength } from 'helpers/utility';

import { ExternalBlockItem } from 'modules/external/ExternalBlocksSection';
import {
  ExternalReceiptAttachments,
  ExternalReceiptItemEntry,
} from 'modules/external/externalReceiptV2/ExternalReceiptSingleItem/components';

import { ExternalReceiptReceivableItemEntriesProps } from './ExternalReceiptReceivableItemEntries.types';

/**
 * Component rendering External Receipt Item entries for item kind receivable
 */
const ExternalReceiptReceivableItemEntries: React.FC<ExternalReceiptReceivableItemEntriesProps> = ({
  attachments,
  dateDue,
  reference,
}) => (
  <ExternalBlockItem>
    <ExternalReceiptItemEntry label="Reference" value={reference} />
    <ExternalReceiptItemEntry label="Due date" value={getDate(dateDue, DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR)} />
    {hasLength(attachments) && (
      <FlexCol className="margin-top--m-large">
        <ExternalReceiptAttachments attachments={attachments} />
      </FlexCol>
    )}
  </ExternalBlockItem>
);

export default ExternalReceiptReceivableItemEntries;
