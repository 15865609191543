import React from 'react';

import { Icon, IconNames } from 'components';
import { showErrorIndicator } from 'components/form/helpers/indicators';

import { colors } from 'constants/styles';

import { getBase64 } from 'helpers/fileHelpers';

import { DropzoneContainer, Text, SupportText, EmphasizedText } from './Dropzone.styles';
import type { DropzoneProps } from './Dropzone.types';

export const handleRejectedFile = (errorIndicatorMessage = 'Please try again'): void =>
  showErrorIndicator(errorIndicatorMessage);

const Dropzone = ({
  accept,
  addFile,
  errorMessage,
  helpMessage,
  isDisabled,
  name,
  supportText,
}: DropzoneProps): JSX.Element => {
  const handleAcceptedFiles = (files) => {
    files.forEach(async (file) => {
      const fileObject = {
        ...file,
        documentType: 'other',
        file: await getBase64(file),
        filename: file.name,
        preview: file.preview,
        uploaded: false,
      };
      addFile(fileObject);
    });
  };

  return (
    <DropzoneContainer
      accept={accept}
      disabled={isDisabled}
      name={name}
      onDropAccepted={handleAcceptedFiles}
      onDropRejected={() => handleRejectedFile(errorMessage)}
    >
      <Icon color={colors.colorBlueBoldHex} icon={IconNames.IMPORT} size={24} />
      <Text>
        {helpMessage || 'Drag and drop here'}
        <br />
        or <EmphasizedText>browse files</EmphasizedText>
      </Text>
      <SupportText>{supportText}</SupportText>
    </DropzoneContainer>
  );
};

export default Dropzone;
