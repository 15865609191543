import React from 'react';

import { ButtonV2, FlexRow, IconNames, Tag, Text } from 'components';

import { ButtonSize } from 'constants/button';
import { colors, sizes, typography } from 'constants/styles';
import { Intent, TagType } from 'constants/ui';

import { ExternalBlock, ExternalBlockItem } from 'modules/external/ExternalBlocksSection';

import { ExternalReceiptPaymentPaginatorProps } from './ExternalReceiptPaymentPaginator.types';

import './ExternalReceiptPaymentPaginator.scss';

/**
 * Component rendering pagintion controllers
 */
const ExternalReceiptPaymentPaginator: React.FC<ExternalReceiptPaymentPaginatorProps> = ({
  onGoToPage,
  onViewAll,
  canPrevPage,
  canNextPage,
  page = 1,
  pages = 1,
}) => (
  <ExternalBlock>
    <ExternalBlockItem>
      <FlexRow className="align-items--center justify-content--space-between" stackOnMobile={false}>
        <Text.Semibold size={typography.TextSize.LEVEL_200}>Invoice</Text.Semibold>
        <Tag isClosable={false} type={TagType.INFO}>
          {`${page} of ${pages}`}
        </Tag>
        <FlexRow className="external-receipt-payment-paginator__control" stackOnMobile={false}>
          <ButtonV2
            intent={Intent.NEUTRAL}
            isDisabled={!canPrevPage}
            isIconButton
            leftIconColor={colors.colorGreyDarkHex}
            leftIconName={IconNames.ARROW_LEFT}
            leftIconSize={sizes.iconSizes.LARGE}
            onClick={() => onGoToPage('PREV')}
            size={ButtonSize.SMALL}
            type="button"
          />
          <ButtonV2
            intent={Intent.NEUTRAL}
            isDisabled={!canNextPage}
            isIconButton
            leftIconColor={colors.colorGreyDarkHex}
            leftIconName={IconNames.ARROW_RIGHT}
            leftIconSize={sizes.iconSizes.LARGE}
            onClick={() => onGoToPage('NEXT')}
            size={ButtonSize.SMALL}
            type="button"
          />
          <ButtonV2 intent={Intent.NEUTRAL} onClick={onViewAll}>
            View all
          </ButtonV2>
        </FlexRow>
      </FlexRow>
    </ExternalBlockItem>
  </ExternalBlock>
);

export default ExternalReceiptPaymentPaginator;
