import React from 'react';

import { CustomerServiceHelper } from 'modules/customerSupport/CustomerService';

import { WithChatOnClickProps } from './withChatOnClick.types';

/**
 * Decorates the passed Component with click handlers to open the appropriate chat tool, based on the route.
 */
const withChatOnClick = <P extends object>(
  Component: React.ComponentType<P>,
  clickPropName?: string,
): React.FC<P & WithChatOnClickProps> => {
  const ComponentWithChatOnClick = ({ fallbackEmail, onClick, ...rest }: WithChatOnClickProps): JSX.Element => {
    const handleClick = (evt) => {
      if (onClick) {
        onClick(evt);
      }

      CustomerServiceHelper.show({ fallbackEmail });
    };

    // Initialize clickProps with the default onClick handler
    const clickProps = { onClick: handleClick };

    // If there's a custom propName for the click handler, add it to clickProps
    // Assuming clickPropName is accessible in this scope. If it's a prop, include it in the function's parameters
    if (clickPropName) {
      clickProps[clickPropName] = handleClick;
    }

    return <Component {...(rest as P)} {...clickProps} />;
  };
  return ComponentWithChatOnClick;
};

export default withChatOnClick;
