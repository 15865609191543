import styled from 'styled-components';

export const ModalContentWrapper = styled.div`
  background-color: var(--color-white);
  padding: var(--spacing-7);
`;

export const ModalTitle = styled.h3`
  color: var(--color-neutral-black);
  font-weight: var(--font-weight-semi-bold);
  margin-bottom: var(--spacing-5);
`;

export const ModalText = styled.p`
  color: var(--color-grey70);
  line-height: var(--line-height-3);
  margin-bottom: 0;
`;
