import { ItemDateScheduledTypes } from 'enums/temporal';

export const isDateScheduledTypeToday = (dateScheduledType: string): boolean =>
  dateScheduledType === ItemDateScheduledTypes.TODAY;

export const isDateScheduledTypeFuture = (dateScheduledType: string): boolean =>
  dateScheduledType === ItemDateScheduledTypes.FUTURE;

export const isDateScheduledTypeSkip = (dateScheduledType: string): boolean =>
  dateScheduledType === ItemDateScheduledTypes.SKIP;

export const isItemScheduledToSend = (dateScheduledType: string): boolean =>
  isDateScheduledTypeToday(dateScheduledType) || isDateScheduledTypeFuture(dateScheduledType);
