import { taxInfoStatus } from '@routable/taxes';

export const TinTypes = {
  EIN: 'ein',
  SSN: 'ssn',
};

export const TinTypeLabels = {
  [TinTypes.EIN]: 'Business EIN',
  [TinTypes.SSN]: 'Social Security Number',
};

export const TaxFormSectionTitle = {
  FORM_CERTIFICATION: 'Form certification',
  ENTITY_INFORMATION: 'Entity information',
  PERSONAL_INFORMATION: 'Personal information',
  TAX_TREATY_BENEFITS: 'Tax treaty benefits',
  MAILING_ADDRESS: 'Mailing address',
  PERMANENT_RESIDENCE_ADDRESS: 'Permanent residence address',
  TAXPAYER_IDENTIFICATION_NUMBER: 'Taxpayer Identification number',
};

export const FormCertification = {
  SIGNATURE_WARNING_TEXT:
    'Your typed full name electronically signs and legally certifies an agreement for the person/entity identified on this form.',
};

export const TaxDocType = {
  W8BENE: 'W-8 BEN-E',
  W8BEN: 'W-8 BEN',
  W9: 'W-9',
} as const;

export const TaxInfoDataKey = {
  W8BEN: 'w8BenTaxInfo',
  W8BENE: 'w8BeneTaxInfo',
  W9: 'w9TaxInfo',
} as const;

export const TaxInfoDataKeyToDocType = {
  [TaxInfoDataKey.W8BEN]: TaxDocType.W8BEN,
  [TaxInfoDataKey.W8BENE]: TaxDocType.W8BENE,
  [TaxInfoDataKey.W9]: TaxDocType.W9,
} as const;

export const TaxSingleRequestText = {
  [TaxInfoDataKey.W9]: {
    formUrl: 'w9',
    formName: 'W-9',
  },
  [TaxInfoDataKey.W8BEN]: {
    formUrl: 'w_8ben',
    formName: 'W-8BEN',
  },
  [TaxInfoDataKey.W8BENE]: {
    formUrl: 'w_8ben_e',
    formName: 'W-8BEN-E',
  },
};

export const TaxCutoffMonthDay = '05-01';

export const TaxStatusText = {
  [taxInfoStatus.Enum.completed]: 'Completed',
  [taxInfoStatus.Enum.no_info]: 'No info',
  [taxInfoStatus.Enum.archived]: 'Archived',
  [taxInfoStatus.Enum.expired]: 'Expired',
};
