// import { KeyStorage } from '@routable/core/storage';

import {
  DEV_ONLY_FEATURE_FLAG_NAME_PREFIX,
  FEATURE_FLAG_NAME_PREFIX,
  LOCAL_STORAGE_KEY_FEATURE_FLAGS,
} from 'constants/featureFlags';

import { areDevtoolsEnabled } from 'helpers/env';

import { localStorageGet, localStorageRemove, localStorageSet } from './localStorage';
import { dashTextToCamelCase, uncapitalize } from './stringHelpers';
import { allKeys, isString, reduceKeys } from './utility';

/**
 * Formats a feature flag name. E.g. any of the following--
 *
 * FEATURE_FLAG_NEW_THING
 * feature_flag_new_thing
 * featureFlagNewThing
 * newThing
 *
 * -- will be formatted to simply 'newThing';
 * @param {string} flagName
 * @return {string}
 */
export const getFormattedFeatureFlagName = (flagName) => {
  const prefixes = [
    FEATURE_FLAG_NAME_PREFIX,
    FEATURE_FLAG_NAME_PREFIX.toLowerCase(),
    DEV_ONLY_FEATURE_FLAG_NAME_PREFIX,
    DEV_ONLY_FEATURE_FLAG_NAME_PREFIX.toLowerCase(),
    'featureFlag',
    'devOnly',
  ];

  if (isString(flagName)) {
    const foundPrefix = prefixes.find((prefix) => flagName.includes(prefix));

    if (foundPrefix) {
      const trimmedFlag = flagName.replace(foundPrefix, '');

      if (trimmedFlag.includes('_')) {
        return dashTextToCamelCase(trimmedFlag.toLowerCase());
      }

      return uncapitalize(trimmedFlag);
    }

    return dashTextToCamelCase(flagName);
  }

  return flagName;
};

/**
 * Gets featureFlags from local storage.
 * @return {Object|null}
 */
export const getFeatureFlagsFromLocalStorage = () =>
  JSON.parse(localStorageGet(LOCAL_STORAGE_KEY_FEATURE_FLAGS) || JSON.stringify({}));

/**
 * Sets all feature flags in local storage.
 * @param {Object} flags
 */
export const setFeatureFlagsInLocalStorage = (flags) => {
  // KeyStorage.setRecord('feature-flags', flags);
  localStorageSet(LOCAL_STORAGE_KEY_FEATURE_FLAGS, JSON.stringify(flags || {}));
};

/**
 * Clears all feature flags from local storage.
 * Note: State will then contain flags originating from the backend and/or .env.feature.local.
 */
export const removeFeatureFlagsFromLocalStorage = () => {
  localStorageRemove(LOCAL_STORAGE_KEY_FEATURE_FLAGS);
};

/**
 * Sets a single feature flag in local storage.
 * @param {string} flagName
 * @param {*} flagValue
 */
export const setSingleFeatureFlagInLocalStorage = (flagName, flagValue) => {
  const currentFlags = getFeatureFlagsFromLocalStorage() || {};
  const formattedFlagName = getFormattedFeatureFlagName(flagName);
  const nextFlags = {
    ...currentFlags,
    [formattedFlagName]: flagValue,
  };
  setFeatureFlagsInLocalStorage(nextFlags);
};

/**
 * Sets a single feature flag in local storage.
 * @param {string} flagName
 */
export const removeSingleFeatureFlagFromLocalStorage = (flagName) => {
  const featureFlags = getFeatureFlagsFromLocalStorage() || {};
  delete featureFlags[getFormattedFeatureFlagName(flagName)];
  setFeatureFlagsInLocalStorage(featureFlags);
};

/**
 * Returns all the feature flags set in .env.feature.* that are enabled (set to 1).
 * @return {EnabledKeys}
 */
export const getEnabledEnvFeatureFlags = () => {
  const envKeys = allKeys(process.env);

  return envKeys.reduce((obj, key) => {
    // if it's a feature flag prefixed key, and the value is set to 1, we'll return it as an enabled flag
    if (key.includes(FEATURE_FLAG_NAME_PREFIX) && Boolean(parseInt(process.env[key], 10))) {
      const flagName = getFormattedFeatureFlagName(key);

      return {
        ...obj,
        [flagName]: true,
      };
    }

    return obj;
  }, {});
};

/**
 * Given feature flags from the API response, returns flags from all sources.
 * @param {EnabledKeys} backendFlags
 * @returns {EnabledKeys}
 */
export const getFeatureFlagsFromAllSources = (backendFlags) => {
  let allFlags;
  if (!areDevtoolsEnabled()) {
    allFlags = { ...backendFlags };
  } else {
    const localStorageFlags = getFeatureFlagsFromLocalStorage() || {};
    const envFlags = getEnabledEnvFeatureFlags();
    allFlags = {
      ...envFlags,
      ...localStorageFlags,
      ...backendFlags,
    };
  }

  // always format the keys (camel cased & no prefixing) prior to returning, so we can never get out of sync
  return reduceKeys(allFlags, (obj, flagName) => ({
    ...obj,
    [getFormattedFeatureFlagName(flagName)]: true,
  }));
};
