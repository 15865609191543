import React from 'react';

import { WhiteSpace } from 'components';
import { Text } from 'components/text';

import { QUICKSWITCH_IMAGE } from 'constants/images';
import { typography } from 'constants/styles';

import { IsLoading } from 'modules/isLoading';

export interface QuickswitchSplashscreenProps {
  companyName: string;
}

const QuickswitchSplashscreenBody = ({ companyName }: QuickswitchSplashscreenProps): JSX.Element => (
  <section>
    <div className="splashscreen--content">
      <div className="splashscreen--image">
        <img alt="" src={QUICKSWITCH_IMAGE} />
        <IsLoading color={typography.TextColor.MAIN_BLUE} />
      </div>
      <div className="splashscreen--progress-header">
        <h3>
          <Text.Regular
            color={typography.TextColor.BLACK}
            lineHeight={typography.TextLineHeight.EXTRA_EXTRA_LARGE}
            size={typography.TextSize.LEVEL_500}
          >
            Switching to
            <WhiteSpace />
            {companyName}
            ...
          </Text.Regular>
        </h3>
      </div>
      <div className="splashscreen--progress-text">
        <Text.Regular
          color={typography.TextColor.GREY_XX_DARK}
          lineHeight={typography.TextLineHeight.EXTRA_LARGE}
          size={typography.TextSize.LEVEL_200}
        >
          It may take a few moments to switch between Routable accounts.
        </Text.Regular>
      </div>
    </div>
  </section>
);

export default QuickswitchSplashscreenBody;
