import React from 'react';

import { AddressFormat } from 'enums/addresses';

import { getAddressAsString } from 'helpers/addressHelpers';

import TableCellText from '../TableCellText';

import type { CompanyWithAddressCellProps } from './CompanyWithAddressCell.types';

/**
 * Component rendering Table Cell with Company (partnership) Name and
 * formatted address (country name + country code)
 */
const CompanyWithAddressCell = ({ address, partnershipName }: CompanyWithAddressCellProps) => {
  const partnerAddress = getAddressAsString(address, { format: AddressFormat.CITY_AND_COUNTRY });

  return (
    <div className="display-flex flex-direction--column">
      <TableCellText className="font-medium" dataFullStory>
        {partnershipName}
      </TableCellText>

      {Boolean(partnerAddress) && (
        <TableCellText className="font-color--grey--x-dark" dataFullStory>
          {partnerAddress}
        </TableCellText>
      )}
    </div>
  );
};

export default CompanyWithAddressCell;
