import {
  updateCurrentCompanySettingsCurrency,
  updateCurrentCompanySettingsCollectVendorTaxesSuccess,
  updateCurrentCompanySettingsRequireTaxFormBeforePayment,
  updateCurrentCompanySettingsSuccess,
} from 'actions/companySettings';
import { showErrorUi, showSuccessUi } from 'actions/ui';

import { GENERIC_ERROR } from 'constants/error';

import type { RoutableApiResponse } from 'interfaces/routableApiResponse';

import { payloadToJSONAPI } from 'services/api/formatHelpers';

import { routableApi, RoutableTags } from '../routableApi';

import type {
  CompanySettingsEnableCurrency,
  TaxSettingsEnableTaxCollection,
  TaxSettingsEnableRequireTaxFormBeforePayments,
  CompanySettingsUpdateSettings,
} from './companySettings.types';

const extendedCompanySettingsApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    companySettingsEnableCurrency: builder.query<RoutableApiResponse, CompanySettingsEnableCurrency>({
      query: ({ id, settingsId, payload }) => ({
        url: `/companies/${id}/settings/${settingsId}/enable_currency/`,
        method: 'PATCH',
        payload: payloadToJSONAPI({ ...payload, id: settingsId }, 'CompanySettings'),
      }),
      onQueryStarted: async ({ successMessage, payload }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(updateCurrentCompanySettingsCurrency(payload));
          dispatch(showSuccessUi(successMessage));
        } catch {
          dispatch(showErrorUi(GENERIC_ERROR));
        }
      },
      providesTags: [RoutableTags.CompanySettings],
    }),
    companySettingsUpdateSettings: builder.mutation<RoutableApiResponse, CompanySettingsUpdateSettings>({
      query: ({ companyId, companySettingsId, payload }) => ({
        url: `/companies/${companyId}/settings/${companySettingsId}/`,
        method: 'PATCH',
        payload: payloadToJSONAPI({ ...payload, id: companySettingsId }, 'CompanySettings'),
      }),
      onQueryStarted: async ({ payload, successMessage }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          dispatch(updateCurrentCompanySettingsSuccess(payload));
          dispatch(showSuccessUi(successMessage));
        } catch {
          dispatch(showErrorUi(GENERIC_ERROR));
        }
      },
      invalidatesTags: [RoutableTags.CompanySettings],
    }),
    toggleTaxCollection: builder.mutation<RoutableApiResponse, TaxSettingsEnableTaxCollection>({
      query: ({ companyId, companySettingsId, payload }) => ({
        url: `/companies/${companyId}/settings/${companySettingsId}/enable_collect_vendor_tax_info/`,
        method: 'PATCH',
        payload: payloadToJSONAPI({ ...payload, id: companySettingsId }, 'CompanySettings'),
      }),
      onQueryStarted: async ({ payload }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(updateCurrentCompanySettingsCollectVendorTaxesSuccess(payload));
        } catch {
          // pass
        }
      },
      invalidatesTags: [RoutableTags.CompanySettings],
    }),
    enableRequireTaxFormBeforePayments: builder.mutation<
      RoutableApiResponse,
      TaxSettingsEnableRequireTaxFormBeforePayments
    >({
      query: ({ companyId, companySettingsId, payload }) => ({
        url: `/companies/${companyId}/settings/${companySettingsId}/enable_require_tax_form_before_payments/`,
        method: 'PATCH',
        payload: payloadToJSONAPI({ ...payload, id: companySettingsId }, 'CompanySettings'),
      }),
      onQueryStarted: async ({ payload }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(updateCurrentCompanySettingsRequireTaxFormBeforePayment(payload));
        } catch {
          // pass
        }
      },
      invalidatesTags: [RoutableTags.CompanySettings],
    }),
  }),
});

export const {
  useCompanySettingsUpdateSettingsMutation,
  useLazyCompanySettingsEnableCurrencyQuery,
  useToggleTaxCollectionMutation,
  useEnableRequireTaxFormBeforePaymentsMutation,
} = extendedCompanySettingsApi;
