import { getApiOffsetForPagination } from '@routable/framework';
import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query';
import { queryContext } from 'milton/components';
import { useMemo } from 'react';

import { PurchaseOrderOption, purchaseOrderOptionModel } from './purchaseOrder.models';
import { purchaseOrderService } from './purchaseOrder.service';

// Generate used for testing purposes.
export const generateUsePurchaseOrders =
  ({ getAll }: Pick<typeof purchaseOrderService, 'getAll'>) =>
  ({
    enabled,
    ledgerRefs,
    search,
    vendors,
  }: {
    enabled?: boolean;
    ledgerRefs?: string[];
    search?: string;
    vendors?: string[];
  }): Omit<UseInfiniteQueryResult, 'data'> & { data: PurchaseOrderOption[] } => {
    const { data, ...resp } = useInfiniteQuery({
      enabled,
      queryKey: ['purchaseOrders', search || 'all', vendors],
      queryFn: ({ pageParam = null }: { pageParam?: number }) =>
        getAll({ ledgerRefs, search, offset: pageParam, vendors }),
      getNextPageParam: getApiOffsetForPagination,
      context: queryContext,
    });

    // grouped data into a single response list no matter how many pages?
    const mappedData = useMemo(() => {
      return (
        data?.pages
          .flatMap((p) => p.data?.purchaseOrders)
          .flatMap((purchaseOrder) => {
            const transformed = purchaseOrderOptionModel.safeParse(purchaseOrder);

            if (transformed.success) {
              return transformed.data;
            }

            return null;
          }) || []
      );
    }, [data]);

    return {
      ...resp,
      data: mappedData,
    };
  };

export const usePurchaseOrders = generateUsePurchaseOrders(purchaseOrderService);
