import Flags from 'country-flag-icons/react/3x2';
import React from 'react';

import { getCountryNameFromCode } from 'helpers/countries';

import { useCountryCodesContext } from 'hooks/context';

import { CountryFlagProps } from './CountryFlag.types';

/**
 * Given a country code this will render a 3:2 SVG flag.
 * Country code must be in ISO 3166-1 alpha-2 format.
 * This is the same flag rendered in InternationalPhoneInput
 */
const CountryFlag: React.FC<CountryFlagProps> = ({ countryCode, ...props }) => {
  const countryCodeMap = useCountryCodesContext();
  const Flag = Flags[countryCode];
  const countryName = getCountryNameFromCode(countryCodeMap, countryCode);

  if (!Flag) {
    return null;
  }

  return <Flag title={countryName} {...props} />;
};

export default CountryFlag;
