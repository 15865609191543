import React from 'react';
import { Field, Form } from 'redux-form';

import { changePasswordHandler } from 'actions/routines/auth';

import { FormControl, IconNames, ReduxFormFieldRenderInput, ButtonV2, BoxV2 } from 'components';

import { ButtonSize } from 'constants/button';
import { formNamesAuthV2 } from 'constants/forms';
import { formStyles, sizes } from 'constants/styles';

import { hasAuthToken } from 'helpers/auth';
import { getPasswordMatchingValidator, requiredValidator } from 'helpers/fieldValidation';

import { ResetPasswordCompleteProps } from './ResetPasswordComplete.types';

const passwordMatchingValidator = getPasswordMatchingValidator(['newPassword', 'newPasswordConfirm']);

/**
 * Final step when resetting password
 */
const ResetPasswordComplete: React.VFC<ResetPasswordCompleteProps> = ({
  creatingNewPassword,
  handleSubmit,
  isSubmitting,
  onLogoutCleanup,
}) => {
  React.useEffect(() => {
    if (hasAuthToken()) {
      onLogoutCleanup();
    }
  }, [onLogoutCleanup]);
  return (
    <BoxV2
      className="box-v2--container--reset-password"
      title={creatingNewPassword ? 'Create your password' : 'Reset your password'}
    >
      <Form
        className="form"
        id={formNamesAuthV2.RESET_PASSWORD_COMPLETE}
        onSubmit={handleSubmit(changePasswordHandler)}
      >
        <FormControl>
          <Field
            className={formStyles.field.xl.full}
            component={ReduxFormFieldRenderInput}
            dataFullStory
            name="form.newPassword"
            placeholder="New password"
            type="password"
            validate={[requiredValidator, passwordMatchingValidator]}
          />
        </FormControl>

        <FormControl>
          <Field
            className={formStyles.field.xl.full}
            component={ReduxFormFieldRenderInput}
            dataFullStory
            name="form.newPasswordConfirm"
            placeholder="Confirm new password"
            type="password"
            validate={[requiredValidator, passwordMatchingValidator]}
          />
        </FormControl>

        <FormControl className="remove-margin-bottom">
          <ButtonV2
            htmlFor={formNamesAuthV2.RESET_PASSWORD_COMPLETE}
            id="auth_newPassword_btn"
            isLoading={isSubmitting}
            rightIconName={IconNames.ARROW_RIGHT}
            rightIconProps={{ style: { marginLeft: 'auto' } }}
            rightIconSize={sizes.iconSizes.LARGE}
            size={ButtonSize.LARGE}
            type="submit"
          >
            Save my new password
          </ButtonV2>
        </FormControl>
      </Form>
    </BoxV2>
  );
};

export default ResetPasswordComplete;
