import styled from 'styled-components';

import { SignUpHeaderProps } from './types/SignUpFormHeader.types';

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: space-evenly;
  margin-bottom: 3.75rem;
`;

export const HeaderLogo = styled.div<SignUpHeaderProps>`
  width: 100%;
  align-self: flex-start;
  text-align: left;
`;

export const HeaderLogin = styled.div`
  width: 50%;
  align-self: flex-end;
  text-align: right;
`;
