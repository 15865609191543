import React from 'react';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

interface NoItemDetectedHintProps {
  itemKindInformalText: string;
}

/**
 * Hint to display when no item was detected
 */
const NoItemDetectedHint: React.FC<NoItemDetectedHintProps> = ({ itemKindInformalText }) => (
  <BaseHint
    intent={Intent.DANGER}
    // @ts-ignore
    multiline
    text={`Please update your file to include at least one ${itemKindInformalText} and upload it again.`}
    title={`No ${itemKindInformalText}s were detected in your file`}
  />
);

export default NoItemDetectedHint;
