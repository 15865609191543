import { TruncatableText } from '@routable/components';
import { Checkbox, Skeleton, Tag } from '@routable/gross-ds';
import clsx from 'clsx';
import _sortBy from 'lodash/sortBy';
import React from 'react';

import { LinkNewPage } from 'components';

import { URL } from 'constants/regex';

import { convertToLowerCase } from 'helpers/stringHelpers';

import { useSelectorWithProps } from 'hooks';

import type { Tag as ITag } from 'interfaces/tags';

import { itemTagsSelector } from 'selectors/taggingSelectors';

import type { RenderValueProps } from './RenderValue.types';

/**
 * Renders value for PresentationalField.
 * Might return a checkbox, wrap the value in a link, show a loader or just return the value
 */
const RenderValue = ({ fieldType, fieldPath, item, options, value }: RenderValueProps): JSX.Element => {
  const itemTags: ITag[] = useSelectorWithProps(itemTagsSelector, {
    match: { params: { id: item.id } },
  });

  if (fieldType === 'boolean') {
    return <Checkbox checked={Boolean(value)} className="pointer-events-none" name={fieldPath} readOnly />;
  }

  if (fieldPath === 'ledgerStatus' && item.ledgerLink) {
    return (
      <LinkNewPage className="text-blue-40" href={item.ledgerLink}>
        <TruncatableText position="top" text={`${value} ↗`} variant="dark" />
      </LinkNewPage>
    );
  }

  if (fieldPath === 'tagLinks') {
    // sort tags for better readability
    const sortedTags = _sortBy(itemTags, [(tag) => convertToLowerCase(tag.name)]);

    return (
      <div className="flex flex-wrap gap-2">
        {sortedTags.map((tag) => (
          <Tag data-testid={tag.id} key={tag.id}>
            {tag.name}
          </Tag>
        ))}
      </div>
    );
  }

  if (typeof value === 'string' && URL.test(value)) {
    return (
      <LinkNewPage className="text-blue-40" href={value}>
        <TruncatableText position="top" text={value} variant="dark" />
      </LinkNewPage>
    );
  }

  if (typeof value === 'string' && value) {
    return (
      <TruncatableText
        className={clsx({
          capitalize: fieldPath === 'extended.paymentpriority',
        })}
        position="top"
        text={value}
        variant="dark"
      />
    );
  }

  if (options?.length === 0) {
    return <Skeleton shape="round" />;
  }

  return <>None</>;
};

export default RenderValue;
