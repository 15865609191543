import { Renew } from '@carbon/icons-react';
import { Menu, StatusIndicator, Tooltip } from '@routable/components';
import React from 'react';
import { useSelector } from 'react-redux';

import PermissionResourceAction from 'constants/permissions';

import { useLedgerIntegrationStatus, usePermissions } from 'hooks';

import { isFetchingIntegrationConfigsSelector } from 'selectors/integrationConfigsSelectors';

import { toolbarButtonIndicatorStyles, toolbarButtonStyles } from './ToolbarLedgerButton.styles';
import type { ToolbarLedgerButtonProps } from './ToolbarLedgerButton.types';

/**
 * ToolbarLedgerButton
 */
const ToolbarLedgerButton = ({ className, ledgerName, onResync, resyncEnabled }: ToolbarLedgerButtonProps) => {
  const permissions = usePermissions([PermissionResourceAction.SETTINGS_INTEGRATIONS_RESYNC]);
  const isFetchingIntegrationConfig = useSelector(isFetchingIntegrationConfigsSelector);
  const { statusText, statusVariant } = useLedgerIntegrationStatus(ledgerName);

  return (
    <Tooltip data-testid="toolbar-ledger-button--tooltip" position="bottom" tooltip={permissions.tooltipProps?.title}>
      <Menu
        className={toolbarButtonStyles({ className, disabled: isFetchingIntegrationConfig })}
        disabled={isFetchingIntegrationConfig || !permissions.hasPermission}
        label={
          <StatusIndicator
            className={toolbarButtonIndicatorStyles({ disabled: isFetchingIntegrationConfig })}
            status={statusVariant}
          >
            {statusText}
          </StatusIndicator>
        }
        placement="bottom right"
      >
        <Menu.Item disabled={!resyncEnabled} onAction={onResync} textValue={`Re-sync ${ledgerName}`}>
          <Renew className="fill-grey-70" /> Re-sync {ledgerName}
        </Menu.Item>
      </Menu>
    </Tooltip>
  );
};

export default ToolbarLedgerButton;
