import { WithMeta } from '../meta.types';

import { Notification } from './withNotification.types';

export const withNotification =
  (notification: Notification): WithMeta =>
  (action) => ({
    ...action,
    meta: {
      ...action.meta,
      notification: {
        ...notification,
      },
    },
  });
