import indefinite from 'indefinite';
import React from 'react';

import { ExternalLink } from 'components';
import WhiteSpace from 'components/text/WhiteSpace';

import * as constants from 'constants/fundingProviderMemo';

import { formatCurrencyUSD } from 'helpers/currencyFormatter';

import { BillingCodeData } from '../interfaces/billing';

import { isBillingCodeAchAddenda } from './billing/base';
import {
  AddedOrUpdatedToastMessageType,
  ConfirmDeleteSwalTextType,
  DeletedToastMessageType,
  ModalButtonTextType,
  ModalTitleType,
} from './fundingProviderMemo.types';
import helpDocs from './helpDocs';
import { multiplyFloat } from './math';

/**
 * Returns either the RTP remittance or the ACH addenda billing code
 */
export const getFundingProviderMemoBillingCode = (
  isDeliveryOptionRTP: boolean,
  billingDataAchAddenda: BillingCodeData,
  billingDataRtpRemittance: BillingCodeData,
): BillingCodeData => {
  if (isDeliveryOptionRTP) {
    return billingDataRtpRemittance;
  }

  return billingDataAchAddenda;
};

/**
 * Returns toast message for fundingProviderMemo based on delivery option
 */
export const getFundingProviderMemoAddedOrUpdatedText = (
  currentString: string,
  isDeliveryOptionRTP: boolean,
): AddedOrUpdatedToastMessageType => {
  if (currentString) {
    return isDeliveryOptionRTP
      ? constants.REMITTANCE_INFO_UPDATED_TOAST_MESSAGE
      : constants.ADDENDA_UPDATED_TOAST_MESSAGE;
  }

  return isDeliveryOptionRTP ? constants.REMITTANCE_INFO_ADDED_TOAST_MESSAGE : constants.ADDENDA_ADDED_TOAST_MESSAGE;
};

/**
 * Returns confirmation swal text for deleting fundingProviderMemo
 */
export const getFundingProviderMemoConfirmDeleteSwalText = (isDeliveryOptionRTP: boolean): ConfirmDeleteSwalTextType =>
  isDeliveryOptionRTP ? constants.REMITTANCE_INFO_CONFIRM_DELETE_SWAL_TEXT : constants.ADDENDA_CONFIRM_DELETE_SWAL_TEXT;

/**
 * Returns toast message for deleted fundingProviderMemo
 */
export const getFundingProviderMemoDeleteSuccessMessage = (isDeliveryOptionRTP: boolean): DeletedToastMessageType =>
  isDeliveryOptionRTP ? constants.REMITTANCE_INFO_DELETED_TOAST_MESSAGE : constants.ADDENDA_DELETED_TOAST_MESSAGE;

/**
 * Returns "remittance info" or "addenda record" based on delivery option
 */
export const getRemittanceInfoOrAddendaRecordText = (
  isDeliveryOptionRTP: boolean,
  isPlural?: boolean,
): 'remittance info' | 'addenda record' | 'addenda records' =>
  isDeliveryOptionRTP ? 'remittance info' : `addenda record${isPlural ? 's' : ''}`;

/**
 * Returns input's max char limit based on delivery option
 */
export const getFundingProviderMemoMaxCharLimit = (
  isDeliveryOptionRTP: boolean,
): typeof constants.ADDENDA_MAX_CHAR_LIMIT | typeof constants.REMITTANCE_INFO_MAX_CHAR_LIMIT =>
  isDeliveryOptionRTP ? constants.REMITTANCE_INFO_MAX_CHAR_LIMIT : constants.ADDENDA_MAX_CHAR_LIMIT;

/**
 * Returns remittance info modal button text based on given value and delivery option
 */
export const getFundingProviderMemoModalButtonText = (
  addendaRecord: string,
  isDeliveryOptionRTP: boolean,
): ModalButtonTextType => {
  if (isDeliveryOptionRTP) {
    return addendaRecord
      ? constants.UPDATE_REMITTANCE_INFO_RECORD_MESSAGE
      : constants.ADD_REMITTANCE_INFO_RECORD_MESSAGE;
  }

  return addendaRecord ? constants.UPDATE_ADDENDA_RECORD_MESSAGE : constants.ADD_ADDENDA_RECORD_MESSAGE;
};

/**
 * Returns remittance info modal title based on given value and delivery option
 */
export const getFundingProviderMemoModalTitle = (
  addendaRecord: string,
  isDeliveryOptionRTP: boolean,
): ModalTitleType => {
  if (isDeliveryOptionRTP) {
    return addendaRecord ? constants.EDIT_REMITTANCE_INFO_RECORD_MESSAGE : constants.ADD_REMITTANCE_INFO_RECORD_MESSAGE;
  }

  return addendaRecord ? constants.EDIT_ADDENDA_RECORD_MESSAGE : constants.ADD_ADDENDA_RECORD_MESSAGE;
};

/**
 * Returns title for FundingProviderMemoTransactionCostHint
 * @see {FundingProviderMemoTransactionCostHint}
 */
export const getFundingProviderMemoTransactionFeeText = (billingData: BillingCodeData): string => {
  const recordType = isBillingCodeAchAddenda(billingData.code) ? 'addenda record' : 'remittance info';

  const { minAmount, multiplier } = billingData;
  const bps = multiplyFloat(multiplier, 10000); // multiplier is in decimal form. Need to multiply by 10,000 to get bps.

  const baseText = `Adding ${indefinite(recordType)} will cost`;

  // There are 3 cases to handle:
  // 1. No cost = bps == 0 => should show that it's free nicely.
  // 2. Has cost = bps > 0 and no floor/min cost => should show that the cost is 'x' bps.
  // 3. Has cost = bps > 0 with floor/min cost => should show that the cost is 'x' bps, but at least 'y'.

  // Case 1
  if (bps === 0) {
    return `${baseText} ${formatCurrencyUSD(0)} per transaction.`;
  }

  // Case 2
  if (!minAmount || parseFloat(minAmount) <= 0) {
    return `${baseText} an additional ${bps} basis points per transaction.`;
  }

  // Case 3
  return `${baseText} an additional ${bps} basis points per transaction, with a minimum charge of ${formatCurrencyUSD(
    minAmount,
  )}.`;
};

/**
 * Gets tooltip text for add addenda/remittance info button inside CreateItemForm.
 * @see {AddFundingProviderMemoButton}
 */
export const getAddFundingProviderMemoTooltipText = ({
  areMultipleBillsSelected,
  isCompanyTypePersonal,
  isDeliveryOptionRTP,
}: {
  areMultipleBillsSelected: boolean;
  isCompanyTypePersonal: boolean;
  isDeliveryOptionRTP: boolean;
}): React.ReactNode => {
  if (areMultipleBillsSelected) {
    return isDeliveryOptionRTP
      ? constants.REMITTANCE_INFO_MULTIPLE_PAYMENTS_TEXT
      : constants.ADDENDA_MULTIPLE_PAYMENTS_TEXT;
  }

  if (isCompanyTypePersonal && !isDeliveryOptionRTP) {
    return constants.ADDENDA_COMPANY_TYPE_PERSONAL_TEXT;
  }

  return (
    <>
      {isDeliveryOptionRTP ? 'A remittance info' : 'An ACH addenda'}
      <WhiteSpace />
      is a description field added to a transaction that will show on the bank statement.
      <WhiteSpace />
      The description helps you and your vendor better identify the purpose of this payment.
      <ExternalLink className="display--block margin-top--m bold primary" href={helpDocs.ADDENDA_RECORDS}>
        Learn more about
        <WhiteSpace />
        {getRemittanceInfoOrAddendaRecordText(isDeliveryOptionRTP, true)}
        <WhiteSpace />
      </ExternalLink>
    </>
  );
};
