/**
 * Get class name based on current step
 */
export const getProgressBarStepClass = (
  currentPos: number,
  currentStep: number,
): 'complete' | 'active' | 'incomplete' => {
  if (currentPos < currentStep) {
    return 'complete';
  }

  if (currentPos === currentStep) {
    return 'active';
  }

  return 'incomplete';
};
