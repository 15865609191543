import { CheckmarkFilled } from '@carbon/icons-react';
import React from 'react';

import { CornerDialogCloseOnly } from 'components';

import { getItemToOrFromPlaceholderText, getPaymentOrInvoiceText } from 'helpers/items';

import type { CornerDialogItemAlreadyAcceptedProps } from './CornerDialogItemAlreadyAccepted.types';

/**
 * CornerDialog with close button show let users know that the item they received has already been taken care of.
 */
const CornerDialogItemAlreadyAccepted = ({
  isShown,
  item,
  onClose,
  partnershipName,
}: CornerDialogItemAlreadyAcceptedProps) => {
  const paymentOrInvoice = getPaymentOrInvoiceText(item);
  const toOrFrom = getItemToOrFromPlaceholderText(item);

  return (
    <CornerDialogCloseOnly
      isShown={isShown}
      onClose={onClose}
      title={
        <div className="inline-flex items-center">
          <CheckmarkFilled className="fill-green-40 mr-3" size={16} />
          <span className="font-medium text-grey-70 text-base">No further action is needed at this time.</span>
        </div>
      }
    >
      {`This ${paymentOrInvoice} has already been remitted ${toOrFrom} `}
      <span className="font-bold text-sm text-grey-60">{partnershipName}</span>.
    </CornerDialogCloseOnly>
  );
};

export default CornerDialogItemAlreadyAccepted;
