import Icon from './Icon';
import { IconNames, type IconName } from './IconMapping';
import InfoIcon from './InfoIcon';
import LedgerIcon from './LedgerIcon';
import SecureIcon from './SecureIcon';

export { default as LightningIcon } from './LightningIcon';

export type { IconName };
export { Icon, IconNames, InfoIcon, LedgerIcon, SecureIcon };
export default Icon;
