import { AxiosRequestHeaders } from 'axios';
import { AnyAction } from 'redux';

import { getQueryString } from 'helpers/queryParams';

import { UnknownObject } from 'interfaces/global';

import { createItemEndpoint } from 'services/api/itemEndpoints';
import { getPartnershipItemsEndpoint } from 'services/api/partnershipsEndpoints';
import { paymentsEndpoint, getPaymentsEndpoint, PaymentApiOptions } from 'services/api/paymentsEndpoint';

import { FETCH_PARTNERSHIP_PAYMENT_SUCCESS } from 'types/payment';

import { createApiRoutine, Options } from './routines/createApiRoutine';

export const paymentsRoutine = createApiRoutine(
  'PAYMENTS',
  {
    endpoint: paymentsEndpoint,
    method: 'GET',
    requireAuth: true,
    headers: {} as AxiosRequestHeaders,
  },
  {
    trigger: ({ endpoint, ...options }: Options & PaymentApiOptions) => ({
      endpoint: endpoint || getPaymentsEndpoint(options),
      ...options,
    }),
  },
);

const TRIGGER = `PAYMENT_ITEMS/TRIGGER`;
const SUCCESS = `PAYMENT_ITEMS/SUCCESS`;
const FAILURE = `PAYMENT_ITEMS/FAILURE`;

const success = (data: unknown): AnyAction => ({
  type: SUCCESS,
  payload: data,
});

const failure = (data: unknown): AnyAction => ({ type: FAILURE, error: data });

const getParams = (params?: PaymentApiOptions['params']) => (params ? `?${getQueryString(params)}` : ``);

const getEndpoint = (partnershipId: string, params?: UnknownObject) =>
  partnershipId ? getPartnershipItemsEndpoint(partnershipId, params) : `${createItemEndpoint}${getParams(params)}`;

const trigger = ({
  partnershipId,
  params = {},
  sort,
}: {
  partnershipId: string;
  params?: UnknownObject;
  sort?: string;
}): AnyAction => ({
  type: TRIGGER,
  payload: {
    endpoint: getEndpoint(partnershipId, {
      sort,
      payment__isnull: true,
      ...params,
    }),
    method: 'GET',
    requireAuth: true,
    onSuccess: success,
    onFailure: failure,
  },
});

export const paymentsItemsRoutine = {
  TRIGGER,
  trigger,
  SUCCESS,
  success,
  FAILURE,
  failure,
};

export const fetchPartnershipPaymentOptionsSuccess = (data: unknown): AnyAction => ({
  type: FETCH_PARTNERSHIP_PAYMENT_SUCCESS,
  payload: data,
});
