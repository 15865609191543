export const NOTIFICATION_BAR_OPEN = '@ui/NOTIFICATION_BAR_OPEN';
export const NOTIFICATION_BAR_CLOSE = '@ui/NOTIFICATION_BAR_CLOSE';

export const SHOW_ERROR_UI = '@ui/SHOW_ERROR_UI';
export const SHOW_FORM_ERROR_UI = '@ui/SHOW_FORM_ERROR_UI';
export const SHOW_INFO_UI = '@ui/SHOW_INFO_UI';
export const SHOW_SUCCESS_UI = '@ui/SHOW_SUCCESS_UI';
export const SHOW_WARNING_UI = '@ui/SHOW_WARNING_UI';
export const SHOW_OCR_UI = '@ui/SHOW_OCR_UI';

export const PARTNERSHIP_FUNDING_ACCOUNT_OPTIONS_OPEN = '@ui/PARTNERSHIP_FUNDING_ACCOUNT_OPTIONS_OPEN';
export const PARTNERSHIP_FUNDING_ACCOUNT_OPTIONS_CLOSE = '@ui/PARTNERSHIP_FUNDING_ACCOUNT_OPTIONS_CLOSE';

export const CANCEL_SAGA = '@ui/CANCEL_SAGA';
