import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { UnknownObject } from 'interfaces/global';

import { companyNameSelector } from 'selectors/quickswitchSelectors';

import QuickswitchSplashscreen from './QuickswitchSplashscreen';

interface StateProps {
  companyName: string;
}

const mapStateToProps = createStructuredSelector({
  companyName: companyNameSelector,
});

const mapDispatchToProps = {};

export default connect<StateProps, UnknownObject>(mapStateToProps, mapDispatchToProps)(QuickswitchSplashscreen);
