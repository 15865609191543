import { all, call, put, spawn, take } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import {
  webhooksFetchRoutine,
  webhookSettingsDeleteRoutine,
  webhookSettingsFetchRoutine,
  webhookSettingsSubmitRoutine,
  webhookSettingsTestRoutine,
  webhookSettingsUpdateRoutine,
} from 'actions/routines/webhooks';
import { showErrorUi, showSuccessUi } from 'actions/ui';
import { openWebhookTestModal } from 'actions/webhooks';

import { confirmAlert } from 'helpers/confirmAlert';
import { getRequestErrorAction, parseCaughtError, parseErrorResponse } from 'helpers/errors';

import { Action } from 'interfaces/actions';
import { FetchServiceResponse } from 'interfaces/fetchService';
import { RequestParams } from 'interfaces/request';
import { CreateWebhookSettingsPayloadProps, WebhookSettings } from 'interfaces/webhooks';

import * as api from './api';

// Webhooks
/**
 * Fetch the list of Webhooks for this compunknown
 */
export function* fetchWebhooks(action: Action): IterableIterator<unknown> {
  yield put(webhooksFetchRoutine.request());

  let errorData = {};

  try {
    const { payload: params } = action;

    const response: FetchServiceResponse = yield call(api.fetchWebhooks, params as RequestParams);

    if (response.ok) {
      yield put(webhooksFetchRoutine.success(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(webhooksFetchRoutine.failure, errorData));
}

// Webhook settings
/**
 * Delete webhook settings
 */
export function* deleteWebhookSettings(action: Action): IterableIterator<unknown> {
  yield put(webhookSettingsDeleteRoutine.request());

  let errorData = {};

  try {
    const isAgreed = yield call(confirmAlert, 'Are you sure you want to delete your webhook subscription?');

    if (!isAgreed) {
      return;
    }

    const { payload } = action;

    const response: FetchServiceResponse = yield call(api.deleteWebhookSettings, payload as string);

    if (response.ok) {
      yield all([put(webhookSettingsFetchRoutine.trigger()), put(showSuccessUi('Subscription deleted'))]);
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(webhookSettingsDeleteRoutine.failure, errorData));
}

/**
 * Fetch the Webhook settings/subscriptions for this compunknown
 */
export function* fetchWebhookSettings(): IterableIterator<unknown> {
  yield put(webhookSettingsFetchRoutine.request());

  let errorData = {};

  try {
    const response: FetchServiceResponse = yield call(api.fetchWebhookSettings);

    if (response.ok) {
      yield put(webhookSettingsFetchRoutine.success(response.data));
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield put(handleRequestErrors(webhookSettingsFetchRoutine.failure, errorData));
}

/**
 * Create a new Webhook settings/subscription for this compunknown
 */
export function* submitWebhookSettings(action: Action): IterableIterator<unknown> {
  yield put(webhookSettingsSubmitRoutine.request());

  let submitErrors = {};

  try {
    const { payload } = action;

    const response: FetchServiceResponse = yield call(
      api.submitWebhookSettings,
      payload as CreateWebhookSettingsPayloadProps,
    );

    if (response.ok) {
      yield all([
        // @ts-ignore TS2554: Expected 0 arguments, but got 1.
        put(webhookSettingsSubmitRoutine.success(response.data)),
        put(openWebhookTestModal()),
      ]);
      return;
    }

    submitErrors = parseErrorResponse(response);
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(webhookSettingsSubmitRoutine.failure, submitErrors));
}

/**
 * Test an existing WebhookSetting
 */
export function* testWebhookSettings(action: Action): IterableIterator<unknown> {
  yield put(webhookSettingsTestRoutine.request());

  let submitErrors = {};

  try {
    yield put(openWebhookTestModal());

    const { payload } = action;

    const response: FetchServiceResponse = yield call(api.testWebhookSettings, payload as string);

    if (response.ok) {
      yield put(webhookSettingsTestRoutine.success(response.data));
      return;
    }

    submitErrors = parseErrorResponse(response);
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  const errorAction = getRequestErrorAction(submitErrors);
  yield put(errorAction(webhookSettingsTestRoutine.failure, submitErrors));
}

/**
 * Update webhook settings
 */
export function* updateWebhookSettings(action: Action): IterableIterator<unknown> {
  yield put(webhookSettingsUpdateRoutine.request());

  let errorData = {};

  try {
    const { payload } = action;
    const { isPaused } = payload as Pick<WebhookSettings, 'id' | 'isPaused'>;

    const pausedOrUnpaused = isPaused ? 'paused' : 'unpaused';

    const response: FetchServiceResponse = yield call(
      api.updateWebhookSettings,
      payload as Pick<WebhookSettings, 'id' | 'isPaused'>,
    );

    if (response.ok) {
      yield all([
        put(webhookSettingsUpdateRoutine.success(response.data)),
        put(showSuccessUi(`Subscription ${pausedOrUnpaused}`)),
      ]);
      return;
    }

    errorData = parseErrorResponse(response);
  } catch (error) {
    errorData = parseCaughtError(error);
  }

  yield all([
    put(handleRequestErrors(webhookSettingsUpdateRoutine.failure, errorData)),
    put(showErrorUi('Something went wrong')),
  ]);
}

// Sagas
/**
 * Listens for redux actions related to Webhooks and WebhooksSettings.
 */
export function* watch(): IterableIterator<unknown> {
  while (true) {
    const action: Action = yield take([
      webhookSettingsDeleteRoutine.TRIGGER,
      webhooksFetchRoutine.TRIGGER,
      webhookSettingsFetchRoutine.TRIGGER,
      webhookSettingsSubmitRoutine.TRIGGER,
      webhookSettingsTestRoutine.TRIGGER,
      webhookSettingsUpdateRoutine.TRIGGER,
    ]);

    switch (action.type) {
      case webhooksFetchRoutine.TRIGGER:
        yield spawn(fetchWebhooks, action);
        break;

      case webhookSettingsDeleteRoutine.TRIGGER:
        yield spawn(deleteWebhookSettings, action);
        break;

      case webhookSettingsFetchRoutine.TRIGGER:
        yield spawn(fetchWebhookSettings);
        break;

      case webhookSettingsSubmitRoutine.TRIGGER:
        yield spawn(submitWebhookSettings, action);
        break;

      case webhookSettingsTestRoutine.TRIGGER:
        yield spawn(testWebhookSettings, action);
        break;

      case webhookSettingsUpdateRoutine.TRIGGER:
        yield spawn(updateWebhookSettings, action);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root webhooks saga
 */
export default function* webhooks(): IterableIterator<unknown> {
  yield watch();
}
