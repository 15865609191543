import React from 'react';

import {
  isItemStatusNeedsApproval,
  isItemStatusPending,
  isItemStatusReadyToSend,
  isItemStatusScheduled,
} from 'helpers/items';

import { Item } from 'interfaces/item';

import { CantRemoveVendorIndividualTooltipContent } from '../components';

import { CommonMenuItemProps } from './CommonMenuItemProps.types';

export const getRemoveContactMenuItemProps = (
  item: Item,
  commonMenuItemProps: CommonMenuItemProps,
): CommonMenuItemProps => {
  // Self manage disabled tooltip overrides the disabled remove contact
  if (commonMenuItemProps?.disabled) {
    return commonMenuItemProps;
  }

  const contactRemovalDisallowedConditions = [
    isItemStatusScheduled,
    isItemStatusReadyToSend,
    isItemStatusPending,
    isItemStatusNeedsApproval,
  ];
  const shouldDisableRemoveContact = contactRemovalDisallowedConditions.some((filter) => filter(item));
  const disabledRemoveContactTooltipProps = {
    tooltip: <CantRemoveVendorIndividualTooltipContent partnershipId={item.partnership} />,
  };
  const tooltip = shouldDisableRemoveContact ? disabledRemoveContactTooltipProps : undefined;

  return {
    ...commonMenuItemProps,
    disabled: shouldDisableRemoveContact,
    tooltip,
  };
};
