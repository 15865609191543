import clsx from 'clsx';
import React from 'react';
import Spinner from 'react-spinkit';

import { colors } from 'constants/styles';
import { spacing } from 'constants/styles/sizes';

import ExtWrapper from 'modules/external/extWrapper/ExtWrapper';

import './IsLoading.scss';
import type {
  IsLoadingComponentProps,
  IsLoadingHasWrapperProps,
  IsLoadingInlineProps,
  IsLoadingLedgerProps,
  IsLoadingProps,
  IsLoadingSSOProps,
} from './IsLoading.types';

export const IsLoading: React.FC<IsLoadingProps> = ({
  className,
  color = colors.colorMainJordanHex,
  fadeIn = 'half',
}) => (
  <Spinner
    className={clsx('sk-three-bounce', className)}
    color={color}
    fadeIn={fadeIn}
    height={spacing.LARGE}
    style={{ textAlign: 'center' }}
    width={spacing.EXTRA_MEDIUM}
  />
);

export const IsLoadingInline: React.FC<IsLoadingInlineProps> = ({ color }) => (
  <Spinner className="sk-three-bounce" color={color} fadeIn="half" />
);

/**
 * @deprecated Use SpinnerWithText from gross-ds instead
 * @param {ComponentProps}
 * @returns {StatelessComponent}
 */
export const IsLoadingComponent: React.FC<IsLoadingComponentProps> = ({
  className,
  hasShadow,
  isReversed,
  loaderClassName,
  text,
  textClassName,
}) => (
  <div
    aria-label="loading"
    className={clsx('loading', {
      'has-shadow': !!hasShadow,
      [className]: !!className,
    })}
  >
    <div className="loading--body">
      {isReversed ? (
        <>
          <IsLoading className={loaderClassName} />
          {text && <p className={clsx('text-black', textClassName)}>{text}</p>}
        </>
      ) : (
        <>
          {text && <p className={clsx('text-black', textClassName)}>{text}</p>}
          <IsLoading className={loaderClassName} />
        </>
      )}
    </div>
  </div>
);

export const IsLoadingBrandedPage: React.FC = () => (
  <div className="branded--contents">
    <div className="branded--loading">
      <IsLoading color="white" />
    </div>
  </div>
);

/**
 * Component rendering loading spinner with wrapper
 */
export const IsLoadingHasWrapper: React.FC<IsLoadingHasWrapperProps> = ({ className, color, hasNav }) => (
  <ExtWrapper
    activeClass={clsx('light', {
      'has-nav': hasNav,
      'has-loader': !hasNav,
      [className]: !!className,
    })}
  >
    <IsLoading color={color} />
  </ExtWrapper>
);

/**
 * Loading indicator for syncing between Routable and ledger. Expects base color
 * and ledger name as props.
 */
export const IsLoadingLedger: React.FC<IsLoadingLedgerProps> = ({ color, ledgerName }) => (
  <IsLoading className={`loading-ledger loading-ledger--${ledgerName}`} color={color} fadeIn="full" />
);

/**
 * Loading indicator for connecting between Routable and SSO provider. Expects optional base color
 * and sso integration name as props.
 */
export const IsLoadingSSO: React.FC<IsLoadingSSOProps> = ({ color, ssoName }) => (
  <IsLoading className={`loading-sso loading-sso--${ssoName}`} color={color} fadeIn="full" />
);

export default IsLoading;
