import { AnyAction } from 'redux';

import { itemRelationships } from 'constants/relationships';

import { getObjectsByIdWithRelationships } from 'helpers/reducer';

import { Item } from 'interfaces/item';

import { EAR_EVALUATED_ITEM, FETCH_ITEM_FAILURE, FETCH_ITEM_REQUEST, FETCH_ITEM_SUCCESS } from 'types/item';

export interface SingleItemRequestState {
  byId: Record<string, Item>;

  isFetchingSingleItem?: boolean;
  statusById: Record<
    string,
    {
      isLoading: boolean;
      isError: boolean;
    }
  >;
  evaluatedItemPayload?: object;
}

const initialState: SingleItemRequestState = {
  byId: {},
  statusById: {},
};

export default (state = initialState, action: AnyAction): SingleItemRequestState => {
  switch (action.type) {
    case FETCH_ITEM_REQUEST:
      return {
        ...state,
        isFetchingSingleItem: true,
        statusById: {
          ...state.statusById,
          [action?.payload?.itemId]: {
            isLoading: true,
            isError: false,
          },
        },
      };
    case FETCH_ITEM_SUCCESS:
      return {
        isFetchingSingleItem: false,
        byId: {
          ...state.byId,
          ...getObjectsByIdWithRelationships(action.payload.item, itemRelationships, {
            currentState: state.byId,
          }),
        },
        statusById: {
          ...state.statusById,
          [Object.keys(action.payload.item).find(Boolean)]: {
            isLoading: false,
            isError: false,
          },
        },
      };
    case FETCH_ITEM_FAILURE:
      return {
        ...state,
        isFetchingSingleItem: false,
        statusById: {
          ...state.statusById,
          [action?.payload?.errors?.options?.itemId]: {
            isLoading: false,
            isError: true,
          },
        },
      };
    case EAR_EVALUATED_ITEM:
      return {
        ...state,
        evaluatedItemPayload: action.payload,
      };
    default:
      return state;
  }
};
