import React from 'react';

import { ButtonV2 } from 'components';

import { colors, sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import type { CompanyAddressDataCardActionButtonProps } from './CompanyAddressDataCardActionButton.types';

/**
 * Component rendering single Action Button in the Company Address Data Card
 * actions segment
 * @see {CompanyAddressDataCardActions}
 * @param props - Component props
 */
const CompanyAddressDataCardActionButton = ({
  dataTestId,
  icon,
  isDisabled,
  onClick,
}: CompanyAddressDataCardActionButtonProps) => {
  // If onClick handler is not defined (not passed), we don't want to render anything
  if (!onClick) {
    return null;
  }

  return (
    <ButtonV2
      className="company-address-data-card__expandable__actions__button"
      dataTestId={dataTestId}
      intent={Intent.NEUTRAL}
      isDisabled={isDisabled}
      isIconButton
      leftIconColor={colors.colorGreyXDarkHex}
      leftIconName={icon}
      leftIconSize={sizes.iconSizes.LARGE}
      onClick={onClick}
    />
  );
};

export default CompanyAddressDataCardActionButton;
