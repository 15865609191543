import classNames from 'classnames';
import React from 'react';

import { Icon } from 'components';

import { colors, sizes } from 'constants/styles';

import { isEventKeySpaceOrEnter } from 'helpers/events';

import type { DropdownMenuOptionProps } from './DropdownMenuOption.types';

/**
 * DropdownMenuOption
 * @see {DropdownMenuButton}
 * @param {DropdownMenuOptionProps} props
 * @return {StatelessComponent}
 */
const DropdownMenuOption = ({
  children,
  className,
  icon,
  isDisabled,
  onClick,
  onCloseMenu,
  title,
}: DropdownMenuOptionProps) => {
  const handleOnClick = React.useCallback(
    (evt) => {
      if (isDisabled) {
        return;
      }

      onClick(evt);
      onCloseMenu(evt);
    },
    [isDisabled, onClick, onCloseMenu],
  );

  const handleKeyPress = React.useCallback(
    (evt) => {
      if (isEventKeySpaceOrEnter(evt)) {
        handleOnClick(evt);
      }
    },
    [handleOnClick],
  );

  return (
    <li className={classNames('dropdown-menu-option', className)}>
      <a
        aria-disabled={isDisabled}
        className={classNames('remove-hover-underline', {
          disabled: !!isDisabled,
        })}
        data-testid={`dropdown-menu-option-${title}`}
        onClick={handleOnClick}
        onKeyPress={handleKeyPress}
        role="menuitem"
        tabIndex={0}
        type="button"
      >
        {icon && (
          <Icon className="margin-right--xm" color={colors.colorGreyDarkHex} icon={icon} size={sizes.iconSizes.LARGE} />
        )}

        <p className="link--title semibold">{title}</p>

        {children}
      </a>
    </li>
  );
};

DropdownMenuOption.defaultProps = {
  children: undefined,
  className: undefined,
  icon: undefined,
  isDisabled: undefined,
};

export default DropdownMenuOption;
