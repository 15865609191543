import { ItemKinds, ItemStatuses } from 'enums/items';

import { getItemStatusByFilter } from 'helpers/items';
import { getQueryParamsFromUrl } from 'helpers/queryParams';
import { getDashboardURLParams, isTabInbox, isTabPayables } from 'helpers/urls';

import { UIFilterList } from 'interfaces/itemFilters';
import { ItemsRequest } from 'interfaces/itemsRequest/ItemsRequest';
import { PaginationProps, ValidTablePageSizes } from 'interfaces/table.types';

import { getItemKindByTab } from 'modules/dashboard/itemList/helpers';

import { payloadToCamelCase } from 'services/api/formatHelpers';

export type RootState = Partial<{
  items: {
    pagination: PaginationProps | null;
  };
  table: {
    payablesTable: {
      filters: UIFilterList;
      sort: string;
    };
    receivablesTable: {
      filters: UIFilterList;
      sort: string;
    };
  };
  featureFlags: {
    activeFlags: {
      [key: string]: boolean;
    };
  };
}>;

type ItemsRequestActionParams = {
  page?: {
    number?: number;
    size?: ValidTablePageSizes;
  };
  url?: string;
};

export const itemsRequestDataSelector = (state: RootState, params: ItemsRequestActionParams): ItemsRequest => {
  const method = 'GET';
  const { tab, filter } = getDashboardURLParams();
  const { page, url } = params;
  const payablesFilterDefault = state.featureFlags?.activeFlags?.payablesFilterDefault;

  let status = getItemStatusByFilter(filter);
  let kind = getItemKindByTab(tab);

  const isPayables = payablesFilterDefault ? isTabPayables(tab) || isTabInbox(tab) : isTabPayables(tab);
  let filters = isPayables ? state.table.payablesTable.filters || [] : state.table.receivablesTable.filters || [];

  const sort = isTabPayables(tab) ? state.table.payablesTable.sort || '' : state.table.receivablesTable.sort || '';

  const queryParams = payloadToCamelCase(getQueryParamsFromUrl());

  if (isTabInbox(tab)) {
    filters = payablesFilterDefault ? filters : [];
    kind = ItemKinds.PAYABLE;
    status = [ItemStatuses.CREATED, ItemStatuses.OCR];
  }

  const { pagination } = state.items;
  return {
    ...queryParams,
    method,
    filter,
    filters,
    kind,
    pagination: {
      ...pagination,
      pageSize: page?.size || pagination.pageSize,
      page: page?.number || 1,
    },
    sort,
    status,
    url,
  };
};
