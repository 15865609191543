import { PartnershipMemberAccess } from 'enums/partnerships';

import { isPartnershipMemberAccessNone } from 'helpers/partnershipMembers';

/**
 * Returns icon color - default green60, but returns grey50 if access is None
 * @param access
 */
export const getIconColorForAccess = (access: PartnershipMemberAccess): string => {
  if (isPartnershipMemberAccessNone(access)) {
    return 'var(--color-grey50)';
  }
  return 'var(--color-green60)';
};
