import pluralize from 'pluralize';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { getCustomerOrVendorOrBothTextByPartnership } from 'helpers/partnerships';
import { capitalize, listAllElementsInArray } from 'helpers/stringHelpers';
import { lengthOf } from 'helpers/utility';

import type {
  GetDetailsTextForMatchCompaniesType,
  GetDetailsTextForMergeCompaniesType,
  GetDetailsTextForSearchCompaniesType,
} from './text.types';

/**
 * Get details subtext for SearchCompanies select option
 */
export const getDetailsTextForSearchCompanies: GetDetailsTextForSearchCompaniesType = ({
  isCrossBorderPaymentsNotEnabledTooltipShown,
  isCurrencyNotSupportedByLedgerTooltipShown,
  isCurrencyNotSupportedByPlatformTooltipShown,
  isLimitSendingPaymentsTooltipShown,
  isPreventInternationalCustomerMatchTooltipShown,
  ledger,
  partnership,
}) => {
  if (isLimitSendingPaymentsTooltipShown) {
    return `Vendor isn't configured for '${ledger.baseCurrencyCode}' on ${ledger.name} account`;
  }

  if (isCrossBorderPaymentsNotEnabledTooltipShown) {
    return "You haven't enabled non-USD payments";
  }

  if (isCurrencyNotSupportedByPlatformTooltipShown) {
    const partnershipType = getCustomerOrVendorOrBothTextByPartnership(partnership);
    const currenciesText = listAllElementsInArray(partnership.ledgerCurrencies);
    const currencyOrCurrencies = pluralize('currency', lengthOf(partnership.ledgerCurrencies));

    return `${PLATFORM_DISPLAY_SHORT_NAME} doesn't yet support this ${partnershipType}'s ${currencyOrCurrencies} (${currenciesText})`;
  }

  if (isCurrencyNotSupportedByLedgerTooltipShown) {
    return `${partnership.currencyCodePartner} is not enabled in your ${ledger.name} account`;
  }

  if (isPreventInternationalCustomerMatchTooltipShown) {
    return `${PLATFORM_DISPLAY_SHORT_NAME} doesn't yet support sending invoices to international customers`;
  }

  return '';
};

/**
 * Get details subtext for MatchCompanies select option
 */
export const getDetailsTextForMatchCompanies: GetDetailsTextForMatchCompaniesType = ({
  isCrossBorderDisabledTooltipIsShown,
  isCurrencyMismatchTooltipShown,
  isCurrencyNotSupportedByPlatformTooltipShown,
  isInternationalCustomerTooltipShown,
  isLimitedMatchingTooltipShown,
  isMatchDomesticPartnershipsTooltipShown,
  ledger,
  partnership,
  platformPartnershipCurrency,
}) => {
  if (isLimitedMatchingTooltipShown) {
    return `Vendor isn't configured for '${ledger.baseCurrencyCode}' on ${ledger.name} account`;
  }

  if (isCrossBorderDisabledTooltipIsShown) {
    return "You haven't enabled non-USD payments";
  }

  if (isInternationalCustomerTooltipShown) {
    return 'Matching international customers is not supported yet';
  }

  if (isMatchDomesticPartnershipsTooltipShown) {
    return 'You cannot match an international vendor with a customer';
  }

  if (isCurrencyNotSupportedByPlatformTooltipShown) {
    const partnershipType = getCustomerOrVendorOrBothTextByPartnership(partnership);
    const currenciesText = listAllElementsInArray(partnership.ledgerCurrencies);
    const currencyOrCurrencies = pluralize('currency', lengthOf(partnership.ledgerCurrencies));

    return `${PLATFORM_DISPLAY_SHORT_NAME} doesn't yet support this ${partnershipType}'s ${currencyOrCurrencies} (${currenciesText})`;
  }

  if (isCurrencyMismatchTooltipShown) {
    const partnershipType = getCustomerOrVendorOrBothTextByPartnership(partnership);

    return `${capitalize(partnershipType)} isn't configured for '${platformPartnershipCurrency}' on ${
      ledger.name
    } account`;
  }

  return '';
};

/**
 * Get details subtext for MergeCompanies select option
 */
export const getDetailsTextForMergeCompanies: GetDetailsTextForMergeCompaniesType = ({
  isLimitedMergingTooltipShown,
  isMergeDisabledWithXBFlagOffTooltipShown,
  isMergeDisabledWithXBFlagOnTooltipShown,
  ledger,
  partnershipType,
}) => {
  if (isLimitedMergingTooltipShown) {
    return `${capitalize(partnershipType)} isn't configured for '${ledger.baseCurrencyCode}' on ${ledger.name} account`;
  }

  if (isMergeDisabledWithXBFlagOffTooltipShown || isMergeDisabledWithXBFlagOnTooltipShown) {
    return 'You cannot merge an international vendor or customer';
  }

  return '';
};
