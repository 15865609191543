import React from 'react';

import { Bar, FilledBar } from './ProgressBar.styles';
import { ProgressBarProps } from './ProgressBar.types';

const ProgressBar: React.VFC<ProgressBarProps> = ({ percentage = 0 }) => (
  <Bar>
    <FilledBar percentage={percentage} />
  </Bar>
);

export default ProgressBar;
