import { TruncatableText } from '@routable/components';
import classNames from 'classnames';
import React from 'react';

import { FlexCol, FlexRow, Icon, IconNames, Text } from 'components';

import { colors, typography } from 'constants/styles';

import { getDomainWithOrWithoutCompanyNamespace } from 'helpers/urls';
import { isArrayLengthEqual, isGreaterThanZero } from 'helpers/utility';

import { Auth0Client } from 'services/auth0';

import { Membership } from 'types/quickswitch';

import QuickswitchMembershipListIcon from './QuickswitchMembershipListIcon';

import './QuickswitchMembershipList.scss';

export interface QuickswitchMembershipListProps {
  memberships: Membership[];
  onSwitchMembership: (membershipId) => void;
}

const QuickswitchMembershipList: React.FC<QuickswitchMembershipListProps> = ({ memberships, onSwitchMembership }) => {
  if (isArrayLengthEqual(memberships, 0)) {
    return null;
  }
  const makeClickableProps = (membership, tabIndex) => {
    const onClick = () => onSwitchMembership(membership.id);
    return {
      onClick,
      onKeyDown: onClick,
      role: 'button',
      tabIndex,
    };
  };
  return (
    <section>
      {memberships.map((targetMembership, index) => {
        const clickableProps = makeClickableProps(targetMembership, index);
        const brandedWorskpaceDomain = getDomainWithOrWithoutCompanyNamespace(targetMembership.companyNamespace).split(
          '//',
        )[1];
        const isReauthenticationRequired = Boolean(targetMembership.isSSORequired && !Auth0Client.isAuthenticated);
        return (
          <FlexRow
            className={classNames(
              'cursor--pointer padding--l flex-direction--row justify-content--space-between quickswitch-member',
              {
                'quickswitch-membership-list--border-top': isGreaterThanZero(index),
              },
            )}
            key={targetMembership.id}
            stackOnMobile={false}
            {...clickableProps}
          >
            <FlexCol className="quickswitch-member--container">
              <div className="quickswitch-member--header">
                <TruncatableText
                  className="quickswitch-member--header-company-name font-bold"
                  position="top"
                  text={targetMembership.companyName}
                />
              </div>
              <div className="quickswitch-member--body">
                <div>
                  <QuickswitchMembershipListIcon isReauthenticationRequired={isReauthenticationRequired} />
                </div>
                <Text.Regular
                  className="quickswitch-member--body-company-name-space"
                  color={colors.colorGreyXDarkHex}
                  size={typography.TextSize.LEVEL_200}
                >
                  {brandedWorskpaceDomain}
                </Text.Regular>
              </div>
            </FlexCol>
            <FlexCol className="quickswitch-member--arrow-icon">
              <div>
                <button className="cursor--pointer" type="button">
                  <Icon color={colors.colorGreyXDarkHex} icon={IconNames.ARROW_RIGHT} />
                </button>
              </div>
            </FlexCol>
          </FlexRow>
        );
      })}
    </section>
  );
};

export default QuickswitchMembershipList;
