import React from 'react';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderNumericInput } from 'components';

import { NumericSuffix } from 'constants/numeric';
import * as propTypes from 'constants/propTypes';

import { formatNumberForFlexibleDecimalScale } from 'helpers/fieldFormatters';
import { numberOnly } from 'helpers/fieldNormalizers';

import { useMemoizedConditionalValidate } from 'hooks';

import { useDynamicFieldRequiredValidators, useTableCellFieldIsDisabled } from 'modules/table/hooks';

/**
 * Renders a text field formatted for percents for numbers to be rendered in a table cell.
 * @param {ComponentProps} props
 * @param {number} props.decimalScale
 * @param {boolean} props.fixedDecimalScale
 * @param {Function} props.getIsDisabled
 * @param {Function} props.getIsRequired
 * @param {Function} props.getRowValues
 * @param {number} props.minDecimalScale
 * @param {string} props.name
 * @param {Function|Function[]} props.validate
 * @param {RestOfProps} props.rest
 * @returns {StatelessComponent}
 */
const PercentTableField = (props) => {
  const { decimalScale, fixedDecimalScale, formValues, minDecimalScale, name } = props;

  const { isRequired, validators } = useDynamicFieldRequiredValidators(props);
  const cachedValidators = useMemoizedConditionalValidate(!formValues.ui?.blockDetailsSections, validators);
  const isDisabled = useTableCellFieldIsDisabled(props);

  return (
    <Field
      className="ledger-form-field"
      component={ReduxFormFieldRenderNumericInput}
      decimalScale={decimalScale}
      defaultValue={0}
      fixedDecimalScale={fixedDecimalScale}
      format={(value) =>
        formatNumberForFlexibleDecimalScale({
          decimalScale,
          fixedDecimalScale,
          minDecimalScale,
          value,
        })
      }
      isDebounced
      isDisabled={isDisabled}
      isRequired={isRequired}
      name={name}
      normalize={numberOnly}
      suffix={NumericSuffix.PERCENT}
      type="text"
      validate={cachedValidators}
    />
  );
};

PercentTableField.propTypes = {
  ...propTypes.tableAnyNumericFieldPropTypes,
};

PercentTableField.defaultProps = {
  ...propTypes.tableAnyNumericFieldDefaultProps,
};

export default PercentTableField;
