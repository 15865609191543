import { createTableReducer } from 'store/redux';

import {
  CLOSE_BULK_ACTION_MODAL,
  OPEN_BULK_ACTION_APPROVE_MODAL,
  OPEN_BULK_ACTION_APPROVE_AND_SEND_MODAL,
  OPEN_BULK_ACTION_EDIT_SEND_DATE_MODAL,
  OPEN_BULK_ACTION_SEND_MODAL,
} from 'types/bulkActions';
import { FETCH_PARTNERSHIP_REQUEST } from 'types/partnership';
import * as types from 'types/tables';

export const actionTypes = {
  copyLockedSelection: CLOSE_BULK_ACTION_MODAL,
  deselectAll: types.DESELECT_ALL_ROWS,
  deselectMultipleRows: types.DESELECT_MULTIPLE_PAYABLES_TABLE_ROWS,
  deselectSingle: types.DESELECT_SINGLE_PAYABLES_TABLE_ROW,
  fetchPartnership: FETCH_PARTNERSHIP_REQUEST,
  lockSelection: [
    OPEN_BULK_ACTION_APPROVE_MODAL,
    OPEN_BULK_ACTION_APPROVE_AND_SEND_MODAL,
    OPEN_BULK_ACTION_EDIT_SEND_DATE_MODAL,
    OPEN_BULK_ACTION_SEND_MODAL,
  ],
  selectMultipleRows: types.SELECT_MULTIPLE_PAYABLES_TABLE_ROWS,
  selectSingle: types.SELECT_SINGLE_PAYABLES_TABLE_ROW,
  sortItems: types.APPLY_COMPANY_PAYMENT_HISTORY_TABLE_SORT,
  updateFilters: types.APPLY_PAYABLES_TABLE_FILTERS,
};

const companyPaymentHistoryTableReducer = createTableReducer(actionTypes);

export default companyPaymentHistoryTableReducer;
