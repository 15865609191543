import { ACCOUNT_CREATION_VERIFY_EMAIL, ACCOUNT_CREATION_CREATE_NAMESPACE, GET_STARTED } from 'constants/routes';

export const getAccountCreationOrDashboardRedirect = (platformType: string, userHasVerifiedEmail: boolean): string => {
  const isCompanySignup = platformType === 'signup';

  if (isCompanySignup) {
    return userHasVerifiedEmail ? ACCOUNT_CREATION_CREATE_NAMESPACE : ACCOUNT_CREATION_VERIFY_EMAIL;
  }

  return `/${GET_STARTED}`;
};
