/**
 * Billing payment sources
 * @enum {string}
 */
export enum PaymentSources {
  BALANCE = 'balance',
  BANK = 'bank',
}

/**
 * Billing payment sources label
 * @enum {string}
 */
export enum PaymentSourcesLabel {
  bank = 'Bank account',
  balance = 'Balance',
}
