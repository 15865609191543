import React from 'react';

import { TextLink } from './ExternalLink.styles';
import { ExternalLinkProps } from './ExternalLink.type';

const ExternalLink: React.FC<ExternalLinkProps> = ({ children, color, href, ...rest }) => (
  <TextLink color={color} href={href} rel="noopener noreferrer" target="_blank" {...rest}>
    {children}
    {` ↗`}
  </TextLink>
);

export default ExternalLink;
