import React from 'react';

import ActionNavTabBase from 'components/actionNavTabList/components/ActionNavTab/components/ActionNavTabBase';
import { ui } from 'components/actionNavTabList/components/ActionNavTab/helpers';

import { TabButton, ActionNav } from './TeamMembersNavTab.styles';
import type { TeamMembersNavTabProps } from './TeamMembersNavTab.types';

/**
 * Renders tabs for team members page
 */
const TeamMembersNavTab = ({ tabListItems, activeTab, setActiveTab }: TeamMembersNavTabProps): JSX.Element => (
  <ActionNav className="action-nav no-border">
    <div className="action-nav--main-content">
      <ul className="action-nav-tabs--list">
        {tabListItems.map(({ type, numberOfItems, label }) => (
          <ActionNavTabBase
            Component={TabButton}
            isSelected={type === activeTab}
            key={`${type}_tab`}
            onClick={() => setActiveTab(type)}
          >
            {`${label} ${ui.getFormattedNumberOfItems(numberOfItems)}`}
          </ActionNavTabBase>
        ))}
      </ul>
    </div>
  </ActionNav>
);

export default TeamMembersNavTab;
