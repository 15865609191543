import React from 'react';

import { TwoLineButton } from 'complexComponents';

import { IconNames } from 'components/icon';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import type { AddBankAccountManuallyButtonProps } from './AddBankAccountManuallyButton.types';

/**
 * Component rendering Add Bank Account Manually two-line button
 * @param {AddBankAccountManuallyButtonProps} props
 * @returns {StatelessComponent}
 */
const AddBankAccountManuallyButton: React.FC<AddBankAccountManuallyButtonProps> = ({ onClick }) => (
  <TwoLineButton
    className="width--100-percent"
    description="Add account information"
    intent={Intent.NEUTRAL}
    leftIconClassName="margin-right--m font-size--sm"
    leftIconName="icon-ic-bank"
    leftIconProps={{ isCustomIcon: true }}
    onClick={onClick}
    rightIconClassName="margin-left--m"
    rightIconName={IconNames.ARROW_RIGHT}
    rightIconSize={sizes.iconSizes.LARGE}
    size={ButtonSize.MEDIUM}
    title="Add bank account"
    type="button"
  />
);
export default AddBankAccountManuallyButton;
