import { ArrowsHorizontal, Link, TrashCan, Unlink } from '@carbon/icons-react';
import { ModalLookupTable } from '@routable/components';
import { Button, Dialog, Menu, Tooltip, useModalState } from '@routable/gross-ds';
import { formNamesItem } from '@routable/shared';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayRemove, change } from 'redux-form';

import { PurchaseOrderOption } from 'hooks/purchaseOrders/purchaseOrder.models';

import { LineItemStyles } from 'constants/lineItems';

import { getDefaultLineItem, getLineItemsPathFromStyle } from 'helpers/lineItems';

import { usePurchaseOrderItemData } from 'hooks/purchaseOrders';

import { fillLineItemWithPropertiesFromPoLineItem } from 'modules/dashboard/createItems/createItemForm/helpers/poMatching';
import { isLineItemSimilarToDefault } from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/summarizeAndTransfer.helpers';
import { getLinkBillLineToPoLineTableConfig, PoLineItemsTable } from 'modules/dashboard/createItems/PoLineItemsTable';
import { useTransferSingleLineItem } from 'modules/table/hooks';

import { createItemsTableViewModelManagerForKindSelector } from 'queries/tableCompoundSelectors';

import {
  createItemFormAccountLineItemsSelector,
  createItemFormItemLineItemsSelector,
  createItemFormItemSelector,
  createItemFormPurchaseOrderSelector,
  createItemFormUiIsItemEditSelector,
  createItemFormUiSkipPurchaseOrderSelector,
} from 'selectors/forms';

import type { LineItemActionsProps } from './LineItemActions.types';

/**
 * Renders menu popover with actions for single line item
 */
export const LineItemActions = ({ index: lineItemIndex, sectionPath }: LineItemActionsProps) => {
  const lineItemStyle = sectionPath?.includes('account') ? LineItemStyles.ACCOUNT : LineItemStyles.ITEM;
  const isStyleItem = lineItemStyle === LineItemStyles.ITEM;

  const deleteLineItemModalState = useModalState({});
  const linkToPoLineModalState = useModalState({});
  const unlinkPoLineModalState = useModalState({});
  const transferLineItemDialogState = useModalState({});
  const dispatch = useDispatch();
  const isSkipped = useSelector(createItemFormUiSkipPurchaseOrderSelector);
  const purchaseOrder = useSelector<ReduxFormState, PurchaseOrderOption>(createItemFormPurchaseOrderSelector);
  const item = useSelector(createItemFormItemSelector);
  const isItemEdit = useSelector(createItemFormUiIsItemEditSelector);

  const accountBillLineItems = useSelector(createItemFormAccountLineItemsSelector);
  const itemBillLineItems = useSelector(createItemFormItemLineItemsSelector);
  const activeLineItems = isStyleItem ? itemBillLineItems : accountBillLineItems;
  const activeLineItem = activeLineItems[lineItemIndex] || {};
  const activeSectionPath = getLineItemsPathFromStyle(lineItemStyle);

  const hasBothSections = accountBillLineItems && itemBillLineItems;

  const results = usePurchaseOrderItemData(!isSkipped ? purchaseOrder?.value : undefined, item?.ledgerRef);
  const poLineItems = (results?.data?.data?.attributes?.line_items || []).filter(
    (lineItem) => lineItem.style === lineItemStyle,
  );

  const data = useMemo(() => {
    return poLineItems.map((lineItem) => ({
      ...lineItem,
      isDisabled: activeLineItems.find(
        (billLineItem) => billLineItem.purchase_order_line_item_id === lineItem.purchase_order_line_item_id,
      ),
    }));
  }, [activeLineItems, poLineItems]);

  const tableData = { ...results, data };

  const viewModelManager = useSelector(createItemsTableViewModelManagerForKindSelector);
  const { destinationSectionDisplayName, transferLineItem } = useTransferSingleLineItem({
    lineItem: activeLineItem,
    lineItemIndex,
    viewModelManager,
  });

  const currentSectionDefaultLineItem = getDefaultLineItem(viewModelManager, activeSectionPath);

  const onLinkLineItem = (selectedIds: string[]) => {
    const poLineItem = poLineItems.find((lineItem) => lineItem.purchase_order_line_item_id === selectedIds[0]);
    const lineItemsToUpdate = [...activeLineItems];

    // Update active bill line item with all properties from PO line item, that the active bill line item does not have defined
    lineItemsToUpdate[lineItemIndex] = fillLineItemWithPropertiesFromPoLineItem({
      targetLineItem: lineItemsToUpdate[lineItemIndex],
      poLineItem,
      isOverride: isLineItemSimilarToDefault(lineItemsToUpdate[lineItemIndex], currentSectionDefaultLineItem),
    });

    dispatch(change(formNamesItem.CREATE_ITEM, activeSectionPath, lineItemsToUpdate));
    linkToPoLineModalState.close();
  };

  const onUnlinkLineItem = () => {
    const lineItemsToUpdate =
      lineItemStyle === LineItemStyles.ACCOUNT ? [...accountBillLineItems] : [...itemBillLineItems];

    // Reset PO data to unlink
    lineItemsToUpdate[lineItemIndex] = {
      ...lineItemsToUpdate[lineItemIndex],
      purchase_order_line_item_id: '',
      purchase_order_id: '',
    };

    dispatch(change(formNamesItem.CREATE_ITEM, activeSectionPath, lineItemsToUpdate));
    unlinkPoLineModalState.close();
  };

  const onDeleteLineItem = () => {
    if (activeLineItems.length === 1) {
      dispatch(change(formNamesItem.CREATE_ITEM, activeSectionPath, [currentSectionDefaultLineItem]));
    } else {
      dispatch(arrayRemove(formNamesItem.CREATE_ITEM, activeSectionPath, lineItemIndex));
    }
    deleteLineItemModalState.close();
  };

  const handleTransferLineItem = () => {
    // move line item to other section without prompting user, if not linked to a po line
    if (!activeLineItem.purchase_order_line_item_id) {
      transferLineItem();
    } else {
      // prompt user to confirm
      transferLineItemDialogState.open();
    }
  };

  const onTransferLineItem = () => {
    transferLineItem();
    transferLineItemDialogState.close();
  };

  return (
    <>
      <div className="ledger-form-field">
        <div className="flex items-center justify-center">
          <Menu
            buttonVariant="floating"
            data-testid="line-items-action-menu"
            intent="secondary"
            placement="top left"
            size="small"
          >
            {/* Show only when PO is linked */}
            {purchaseOrder?.value && !activeLineItem.purchase_order_line_item_id && (
              <Menu.Item
                disabled={isItemEdit}
                key="link-line-item"
                onAction={linkToPoLineModalState.open}
                textValue="Link to PO line"
              >
                <Tooltip
                  data-testid="item-edit--link-po-line"
                  position="left"
                  size="small"
                  tooltip={
                    isItemEdit
                      ? "You can't link an existing line item to a PO line while editing. You'll need to delete and recreate this payable to take this action."
                      : undefined
                  }
                  variant="light"
                >
                  <div className="flex items-center text-sm">
                    <Link className="w-5 h-5 mr-4" />
                    Link to PO line
                  </div>
                </Tooltip>
              </Menu.Item>
            )}

            {activeLineItem.purchase_order_line_item_id && (
              <Menu.Item
                key="unlink-line-item"
                onAction={isItemEdit ? unlinkPoLineModalState.open : onUnlinkLineItem}
                textValue="Unlink from PO line"
              >
                <div className="flex items-center">
                  <Unlink className="w-5 h-5 mr-4" />
                  Unlink from PO line
                </div>
              </Menu.Item>
            )}

            {/* Show only when both sections are available */}
            {hasBothSections && (
              <Menu.Item
                key="move-to-expenses-item"
                onAction={handleTransferLineItem}
                textValue={`Move to ${destinationSectionDisplayName}`}
              >
                <div className="flex items-center">
                  <ArrowsHorizontal className="w-5 h-5 mr-4" />
                  {`Move to ${destinationSectionDisplayName}`}
                </div>
              </Menu.Item>
            )}

            <Menu.Item
              key="delete-line-item"
              onAction={
                isItemEdit && activeLineItem.purchase_order_line_item_id
                  ? deleteLineItemModalState.open
                  : onDeleteLineItem
              }
              textValue="Delete line item"
            >
              <div className="flex items-center">
                <TrashCan className="w-5 h-5 mr-4" />
                Delete line item
              </div>
            </Menu.Item>
          </Menu>
        </div>
      </div>

      <ModalLookupTable modalState={linkToPoLineModalState} title="Link bill line to PO line">
        <PoLineItemsTable
          isMultiSelect={false}
          onClose={linkToPoLineModalState.close}
          onSubmit={onLinkLineItem}
          purchaseOrder={purchaseOrder}
          submitText="Link"
          tableConfig={getLinkBillLineToPoLineTableConfig(isStyleItem)}
          tableData={tableData}
        />
      </ModalLookupTable>

      <Dialog
        footer={
          <Dialog.Footer>
            <Button
              data-testid="move-cancel"
              intent="secondary"
              onPress={transferLineItemDialogState.close}
              variant="outline"
            >
              Cancel
            </Button>
            <Button data-testid="move-confirm" onPress={onTransferLineItem}>
              Move line item
            </Button>
          </Dialog.Footer>
        }
        header={<Dialog.Header label="Are you sure you want to move this line item?" />}
        intent="primary"
        state={transferLineItemDialogState}
      >
        {isItemEdit
          ? 'This action will unlink the line item from the PO and is irreversible. You will not be able to relink this PO line.'
          : 'Moving this line item will unlink it from the PO. You can re-link it after it is moved.'}
      </Dialog>

      <Dialog
        footer={
          <Dialog.Footer>
            <Button
              data-testid="move-cancel"
              intent="secondary"
              onPress={unlinkPoLineModalState.close}
              variant="outline"
            >
              Cancel
            </Button>
            <Button data-testid="move-confirm" onPress={onUnlinkLineItem}>
              Unlink PO line
            </Button>
          </Dialog.Footer>
        }
        header={<Dialog.Header label="Are you sure you want to unlink this PO line?" />}
        intent="primary"
        state={unlinkPoLineModalState}
      >
        This action is irreversible. You will not be able to relink this PO line.
      </Dialog>

      <Dialog
        footer={
          <Dialog.Footer>
            <Button
              data-testid="delete-cancel"
              intent="secondary"
              onPress={deleteLineItemModalState.close}
              variant="outline"
            >
              Cancel
            </Button>
            <Button data-testid="delete-confirm" onPress={onDeleteLineItem}>
              Delete line item
            </Button>
          </Dialog.Footer>
        }
        header={<Dialog.Header label="Are you sure you want to delete this line item?" />}
        intent="primary"
        state={deleteLineItemModalState}
      >
        You will not be able to add new line items that are linked to this PO. Any new line items will be processed as
        non-PO lines.
      </Dialog>
    </>
  );
};
