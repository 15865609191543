import React from 'react';

import StatusWithText from 'components/statusWithText';

import { getItemStatusProps, getItemStatusText, getStatusToConsider } from 'helpers/status';

type Props = {
  rowData: Item;
};

/**
 * Table cell for rendering the item status
 */
const ItemStatusList = ({ rowData }: Props): JSX.Element => {
  const { statusVariant, statusText } = getItemStatusProps(rowData);
  const status = getStatusToConsider(rowData);
  const complementaryText = getItemStatusText(rowData, status);

  return (
    <StatusWithText secondaryText={complementaryText} status={statusVariant}>
      {statusText}
    </StatusWithText>
  );
};

export default ItemStatusList;
