import React from 'react';

import { getFundingInfoAddressForPartnershipFundingAccount, isFundingAccountEligibleForRTP } from 'helpers/funding';
import { isPaymentDeliveryOptionRTP } from 'helpers/paymentMethods';
import { getPartnerBankAccountViewTypeForPaymentMethod } from 'helpers/ui';

import BankAccountRadio from 'modules/fundingAccount/bankAccountRadio';

import type { PartnerPaymentMethodSelectionOptionBankRadioProps as Props } from './PartnerPaymentMethodSelectionOptionBankRadio.types';

/**
 * Radio option for the BankAccountRadio
 */
const PartnerPaymentMethodSelectionOption: React.FC<Props> = ({
  partnerFundingAccount,
  fundingAccountsById,
  fundingInfoAddressesById,
  input,
  partnership,
  paymentDeliveryMethod,
  paymentDeliveryOption,
}) => (
  <BankAccountRadio
    displayBody={false}
    fundingAccount={partnerFundingAccount}
    fundingInfoAddress={getFundingInfoAddressForPartnershipFundingAccount(
      partnerFundingAccount,
      fundingAccountsById,
      fundingInfoAddressesById,
    )}
    id={partnerFundingAccount.id}
    input={input}
    isDisabled={
      isPaymentDeliveryOptionRTP(paymentDeliveryOption) && !isFundingAccountEligibleForRTP(partnerFundingAccount)
    }
    key={partnerFundingAccount.id}
    partnership={partnership}
    partnershipFundingAccount={partnerFundingAccount}
    viewType={getPartnerBankAccountViewTypeForPaymentMethod(paymentDeliveryMethod)}
  />
);

export default PartnerPaymentMethodSelectionOption;
