import styled from 'styled-components';

import { containerWidthMap } from 'modules/signup-v3/constants/container';

export const EINContainer = styled.div`
  max-width: ${containerWidthMap.large};
`;

export const Container = styled.div`
  max-width: 292px;
`;

export const HelperLink = styled.span`
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.063rem;
  margin-top: 1.5rem;
`;
