import { setRequirementsAreMet, unsetRequirementsAreMet, fetchPasswordStrengthCompleted } from 'actions/passwordInput';

import { availableRequirementValidators } from '../constants/availableRequirementValidators';

import { passwordRequirements } from './passwordRequirements';

export const checkAllRequirements = (dispatch: (action: { type: string }) => void, password: string): boolean => {
  let allRequirements = {};

  // Goes over each required validation
  // and builds an object with the validation as key, and result as value
  Object.values(availableRequirementValidators).forEach((requirement) => {
    allRequirements = {
      ...allRequirements,
      [requirement]: passwordRequirements[requirement].validator(password),
    };
  });

  // undefined values mean the validations are correct
  const allValidationsAreTrue = Object.values(allRequirements).every((value) => value === undefined);

  // dispatch the success if all validations are correct
  if (allValidationsAreTrue) {
    dispatch(setRequirementsAreMet());
    return true;
  }

  // set requirements are not set and make the strength artificially weak
  // the empty dispatch will make the reducer fallback to initial state
  dispatch(unsetRequirementsAreMet());
  dispatch(fetchPasswordStrengthCompleted({}));

  return false;
};
