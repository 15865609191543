import React from 'react';

import { IconNames } from 'components/icon';
import { TooltipMUIIcon } from 'components/tooltip';

import { TooltipMaxWidth, TooltipPadding } from 'constants/tooltip';

import { PartnershipMemberAccess, PartnershipMemberAccessScope } from 'enums/partnerships';

import { ContactSettingsTooltipText } from './components';
import { ContactAutofillIconsProps } from './ContactAutofillIcons.types';
import { getIconColorForAccess } from './helpers';

/**
 * Show Autofill status icons with tooltips to explain the default contact settings for each contact.
 */
const ContactAutofillIcons = ({ partnershipMember }: ContactAutofillIconsProps): JSX.Element => {
  const { defaultGeneral, defaultItem, managed } = partnershipMember;

  const generalAccess = managed ? PartnershipMemberAccess.SELF_MANAGED : defaultGeneral;
  const itemAccess = managed ? PartnershipMemberAccess.SELF_MANAGED : defaultItem;

  const iconProps = { size: 16 };
  const tooltipProps = {
    arrow: true,
    maxWidth: managed ? undefined : TooltipMaxWidth.NONE,
    padding: TooltipPadding.SKINNY,
  };

  return (
    <>
      <div data-testid="autofill-icon--item">
        <TooltipMUIIcon
          icon={IconNames.SIDEBAR_PAYABLES}
          iconClassName="margin--sm"
          iconColor={getIconColorForAccess(itemAccess)}
          iconProps={iconProps}
          title={<ContactSettingsTooltipText access={itemAccess} scope={PartnershipMemberAccessScope.ITEMS} />}
          tooltipProps={tooltipProps}
        />
      </div>
      <div className="ml-3" data-testid="autofill-icon--company">
        <TooltipMUIIcon
          icon={IconNames.SIDEBAR_COMPANIES}
          iconClassName="margin--sm"
          iconColor={getIconColorForAccess(generalAccess)}
          iconProps={iconProps}
          title={
            <ContactSettingsTooltipText
              access={generalAccess}
              scope={PartnershipMemberAccessScope.COMPANY_MANAGEMENT}
            />
          }
          tooltipProps={tooltipProps}
        />
      </div>
    </>
  );
};

export default ContactAutofillIcons;
