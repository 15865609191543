import debounce from 'es6-promise-debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import './SelectWorkspace.module.scss';

import { getCurrentCompanyRequest } from 'actions/currentCompany';
import { validateNamespace } from 'actions/signUpFlow';

import { ButtonV2, FormFieldLabel, IconNames, ReduxFormFieldRenderInput } from 'components';
import InputValueHighlighter from 'components/input/InputValueHighlighter';

import { ButtonSize } from 'constants/button';
import { Environment } from 'constants/env';
import { formNamesSignup } from 'constants/forms';
import { ENVIRONMENT_DOMAINS } from 'constants/routes';
import { formStyles, sizes } from 'constants/styles';

import { minCharacterLengthValidator, requiredValidator, subdomainValidator } from 'helpers/fieldValidation';

import { currentCompanyNamespaceSelector } from 'selectors/currentCompanySelectors';
import { isNamespaceValidSelector } from 'selectors/signupSelectors';

import { getFeatureFlagsRoutine } from '../../../actions/routines/featureFlags';
import { Intent } from '../../../constants/ui';
import SignUpFormFooter from '../components/SignUpFormFooter';
import SignUpFormHeader from '../components/SignUpFormHeader';
import {
  vanityClassname,
  pClassname,
  pClassnameWithMargin,
  sectionHeaderClassname,
} from '../constants/relevantClassnames';

import NamespaceAvailability from './components/WorkspaceAvailability';
import { VALIDATION_REGEX } from './constants/regex';
import { submit } from './helpers/submit';
import type { SelectWorkspaceProps } from './SelectWorkspace.types';

const minLengthValidator = minCharacterLengthValidator(4);

const formSelector = getFormValues(formNamesSignup.SELECT_NAMESPACE);

type TCompany = {
  namespace: string;
};

type SelectNamespaceFormData = {
  company: TCompany;
  isSubmitting: boolean;
};

/**
 * Allows the user to specify a namespace for their company
 */
const SelectWorkspace: React.FC<SelectWorkspaceProps> = ({ handleSubmit, invalid, ...props }) => {
  const { isSubmitting } = props;
  const dispatch = useDispatch();
  const [isUserTyping, setIsUserTyping] = useState(false);

  const formValues = useSelector(formSelector) as SelectNamespaceFormData;

  const namespace = formValues?.company?.namespace || '';
  const isNamespaceAvailable: boolean = useSelector(isNamespaceValidSelector);
  const currentCompanyNamespace: string = useSelector(currentCompanyNamespaceSelector);

  useEffect(() => {
    dispatch(getCurrentCompanyRequest());
    dispatch(getFeatureFlagsRoutine.trigger());
  }, [dispatch]);

  useEffect(() => {
    if (currentCompanyNamespace?.length) {
      // when the page first loads, we get the current company and prefill the form with the suggested namespace
      // we still want to validate this suggested namespace so be able to show the available/not available hint and any form validations
      dispatch(validateNamespace(namespace));
    }
  }, [currentCompanyNamespace, dispatch, namespace]);

  const handleChange = (event) => {
    // when the namespace field is changed, re-validate that it's valid
    dispatch(validateNamespace(event.target.value));
    setIsUserTyping(false);
  };

  const debouncedChange = useCallback(debounce(handleChange, 500), []);

  const setTypingChange = (event) => {
    setIsUserTyping(true);
    debouncedChange(event);
  };

  const isDisabled = invalid || !namespace || !isNamespaceAvailable;

  return (
    <div className="submodule-container">
      <div className="form-container">
        <SignUpFormHeader hasSignInOption={false} />
        <form
          className="form"
          id={formNamesSignup.SELECT_NAMESPACE}
          onSubmit={handleSubmit((values) => submit(values, props))}
        >
          <h2>Next, name your workspace</h2>
          <FormFieldLabel className={sectionHeaderClassname}>Workspace name</FormFieldLabel>
          <p className={pClassname}>You will use this unique name to log into your Routable account.</p>
          <p className={pClassnameWithMargin}>
            A default workspace name has been created for you based on your company name.
          </p>
          <p className={pClassnameWithMargin}>You can change it now or at a later time.</p>
          <div className="form-control" id="namespace-entry-container">
            <Field
              className={`${formStyles.field.xl.full} namespace`}
              component={ReduxFormFieldRenderInput}
              dataFullStory
              dataTestId="namespace"
              id="password-entry-field"
              isDisabled={Boolean(isSubmitting)}
              name="company.namespace"
              onChange={setTypingChange}
              placeholder="Workspace name"
              type="text"
              validate={[requiredValidator, minLengthValidator, subdomainValidator]}
            >
              <InputValueHighlighter regex={VALIDATION_REGEX} value={namespace} />
            </Field>

            <div className="vanity-container">
              <span className={vanityClassname}>
                {`.${ENVIRONMENT_DOMAINS[Environment.PRODUCTION].brandedWorkspaces}`}
              </span>
            </div>
          </div>

          {namespace && !isUserTyping && !formValues.isSubmitting && !invalid && <NamespaceAvailability />}

          <div className="form-control remove-margin-bottom" id="button-control">
            <ButtonV2
              className="display--table-cell"
              dataTestId="saveAndContinueButton"
              htmlFor={formNamesSignup.SELECT_NAMESPACE}
              intent={Intent.PRIMARY}
              isDisabled={isDisabled}
              isLoading={Boolean(isSubmitting)}
              rightIconClassName="margin-left--m"
              rightIconName={IconNames.ARROW_RIGHT}
              rightIconSize={sizes.iconSizes.SMALL}
              size={ButtonSize.MEDIUM}
              type="submit"
            >
              Save and continue
            </ButtonV2>
          </div>
        </form>
        <SignUpFormFooter />
      </div>
    </div>
  );
};

export default SelectWorkspace;
