import { SSOActionType, SSOProviders } from 'enums/sso';

import { SSOActionCreator } from 'interfaces/sso';

/**
 * Action creator for dispatching Close SSO Setup Form Modal action
 */
export const closeSSODisableModal = (): SSOActionCreator => ({
  type: SSOActionType.CLOSE_DISABLE_MODAL,
});

/**
 * Action creator for dispatching Close SSO Setup Form Modal action
 */
export const closeSSOSetupFormModal = (): SSOActionCreator => ({
  type: SSOActionType.CLOSE_SETUP_FORM_MODAL,
});

/**
 * Action creator for dispatching Close Splashscreen Modal action, which is present after submitting SSOSetupForm
 */
export const closeSSOSetupFormSplashscreenModal = (): SSOActionCreator => ({
  type: SSOActionType.CLOSE_SETUP_SPLASHSCREEN_MODAL,
});

/**
 * Action creator for dispatching Open SSO Disable Modal action
 */
export const openSSODisableModal = (): SSOActionCreator => ({
  type: SSOActionType.OPEN_DISABLE_MODAL,
});

/**
 * Action creator for dispatching Open SSO Setup Form Modal action
 */
export const openSSOSetupFormModalWithProvider = (ssoProvider: SSOProviders): SSOActionCreator => ({
  type: SSOActionType.OPEN_SETUP_FORM_MODAL_WITH_PROVIDER,
  payload: { ssoProvider },
});

/**
 * Action creator for dispatching Open Splashscreen Modal action, which is present after submitting SSOSetupForm
 */
export const openSSOSetupFormSplashscreenModal = (): SSOActionCreator => ({
  type: SSOActionType.OPEN_SETUP_SPLASHSCREEN_MODAL,
});

/**
 * Action creator for removing the current company sso settings.
 */
export const removeSSOCompanySettings = (): SSOActionCreator => ({
  type: SSOActionType.REMOVE_SSO_SETTINGS,
});
