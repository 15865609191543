import { type Status, StatusIndicator } from '@routable/gross-ds';
import React from 'react';

import { TableCellTextWithSubtext } from 'complexComponents/Table/components';

import { IconNames } from 'components/icon';
import { TooltipMUIIcon } from 'components/tooltip';

import { colors, sizes } from 'constants/styles';

type Props = {
  children?: React.ReactNode;
  status: Status;
  className?: string;
  secondaryText?: string;
  tooltipContent?: string;
};

/**
 * Component that shows the status dot and status, with an additional
 * line of text below that can be added via secondaryText prop.
 */
const StatusWithText = ({ children, status, className, secondaryText, tooltipContent }: Props): JSX.Element => (
  <>
    <TableCellTextWithSubtext
      subtext={secondaryText}
      text={<StatusIndicator status={status}>{children}</StatusIndicator>}
      textClassName={className}
    />

    {!!tooltipContent && (
      <div className="right-indicator">
        <TooltipMUIIcon
          icon={IconNames.INFO_SIGN}
          iconClassName="vertical-align--bottom align-self--flex-end"
          iconColor={colors.colorMainJordanHex}
          iconProps={{ size: sizes.iconSizes.LARGE }}
          title={tooltipContent}
        />
      </div>
    )}
  </>
);

export default StatusWithText;
