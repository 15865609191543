import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { Col, Row } from 'components/layout';

import { ContentBlockSimpleTitle } from './components';
import './ContentBlockSimple.scss';

interface ContentBlockSimpleProps {
  children: ReactNode;
  className?: string;
  bodyClassName?: string;
  title?: string;
  titleLight?: string;
}

/**
 * Simplified content-block container.
 * Usage, pass title prop for title, pass children to be contained in Col.
 */
const ContentBlockSimple = ({
  children,
  className,
  bodyClassName,
  title,
  titleLight,
}: ContentBlockSimpleProps): React.ReactElement => (
  <Row className={classNames('content-block-simple', className)}>
    <ContentBlockSimpleTitle title={title} titleLight={titleLight} />
    <Col className={bodyClassName}>{children}</Col>
  </Row>
);

export default ContentBlockSimple;
