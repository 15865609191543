/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

import { ReduxState } from 'interfaces/redux';

export interface SplitBillState {
  isSubmitting?: boolean;
}

const initialState: SplitBillState = { isSubmitting: false };

const reduxSplitBill = createSlice({
  initialState,
  name: 'splitBill',
  reducers: {
    startIsSubmitting(state) {
      state.isSubmitting = true;
    },
    endIsSubmitting(state) {
      state.isSubmitting = false;
    },
  },
});

const getSplitBill = (reduxState: ReduxState) => reduxState.splitBill;

export const splitBillIsSubmittingSelector = createSelector([getSplitBill], (splitBill) => splitBill.isSubmitting);

export const {
  name,
  actions: { startIsSubmitting, endIsSubmitting },
  reducer,
} = reduxSplitBill;
