import React from 'react';

import PaymentMethodsLink from 'components/link/PaymentMethodsLink';

/**
 * Renders helper text with payment methods help link
 */
const FullListOf = ({ listType }: { listType: string }): JSX.Element => (
  <>
    {`You can view the full list of ${listType} on your`}
    &nbsp;
    <PaymentMethodsLink className="primary" />
    &nbsp; page.
  </>
);

export default FullListOf;
