import { apiCall, type ApiCall } from '@routable/framework';

import { OverlaysApiResponse, OverlaysApiResponseModel } from './overlays.models';

type OverlaysApi = {
  getItemOverlays: (itemId: string) => Promise<OverlaysApiResponse>;
};

export const generateOverlaysApi = (api: ApiCall): OverlaysApi => ({
  getItemOverlays: async (itemId) =>
    api({
      camelCaseResponse: true,
      url: `overlays/item/${itemId}/`,
      method: 'get',
      responseModel: OverlaysApiResponseModel,
    }),
});

export const overlaysApi = generateOverlaysApi(apiCall);
