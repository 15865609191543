import { Product } from '@carbon/icons-react';
import { Menu } from '@routable/gross-ds';
import React from 'react';

import { ArchivedContactActionsMenuProps } from './ArchivedContactActionsMenu.types';

/**
 * Actions for an archived contact.
 */
const ArchivedContactActionsMenu = ({ canArchive, onUnarchive }: ArchivedContactActionsMenuProps) => (
  <Menu buttonVariant="floating" placement="bottom">
    <Menu.Item disabled={!canArchive.hasPermission} onAction={() => onUnarchive()} tooltip={canArchive.tooltipProps}>
      <Product />
      Unarchive contact
    </Menu.Item>
  </Menu>
);

export default ArchivedContactActionsMenu;
