import { ssoSetupFormEditSubmitRoutine, ssoSetupFormSubmitRoutine } from 'actions/routines/sso';

import { SSOActionType } from 'enums/sso';

import { SSOSetupFormState } from 'interfaces/sso';

export const initialState: SSOSetupFormState = {
  ssoProvider: undefined,
  ssoRequired: false,
  company: undefined,
};

/**
 * reducer sets the state used in the ssoSetupForm component
 */
const ssoSetupFormReducer = (state = initialState, action = null): SSOSetupFormState => {
  switch (action.type) {
    case SSOActionType.OPEN_SETUP_FORM_MODAL_WITH_PROVIDER:
      // modal is opened in modalSSOReducer.ts
      return {
        ...state,
        ssoProvider: action.payload.ssoProvider,
      };
    case ssoSetupFormSubmitRoutine.SUCCESS:
    case ssoSetupFormEditSubmitRoutine.SUCCESS:
    case ssoSetupFormSubmitRoutine.FAILURE:
    case ssoSetupFormEditSubmitRoutine.FAILURE:
      return initialState;
    default:
      return state;
  }
};

export default ssoSetupFormReducer;
