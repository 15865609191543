import clsx from 'clsx';
import React from 'react';

import type { FormSectionProps } from './FormSection.types';

/**
 * Wrapper component to apply consistent bottom-margin styling of 24px between sections of a form.
 */
const FormSection = ({ className, children, style }: FormSectionProps) => (
  <div className={clsx('mb-7', 'max-w-full', 'form-section', className)} style={style}>
    {children}
  </div>
);

export default FormSection;
