import React from 'react';
import { Redirect } from 'react-router-dom';

import { BrandedError } from 'components';

import { LOGIN, NOT_FOUND } from 'constants/routes';

import {
  acceptingMembershipInviteShouldShowBrandedErrorPage,
  acceptingMembershipInviteShouldShowLoginPage,
  isAcceptingMembershipInvitePageErrorTypeNotFound,
} from 'helpers/authentication';

import { IsLoadingBrandedPage } from 'modules/isLoading/IsLoading';

import AcceptMembershipInviteForm from '../AcceptMembershipInviteForm';

import type { AcceptMembershipInvitePageProps } from './AcceptMembershipInvitePage.types';

/**
 * Shows the AcceptMembershipInviteForm or redirects to appropriate error/404 pages.
 */
const AcceptMembershipInvitePage: React.FC<AcceptMembershipInvitePageProps> = (props) => {
  const { errorType, errors, isFetching, location, membershipInvite } = props;

  if (acceptingMembershipInviteShouldShowLoginPage(errorType, errors)) {
    return (
      <Redirect
        to={{
          pathname: `/${LOGIN}`,
          state: { from: location },
        }}
      />
    );
  }

  if (acceptingMembershipInviteShouldShowBrandedErrorPage(errorType)) {
    return (
      <BrandedError
        errorText="Double-check the URL, and make sure that it matches your original invitation. If the URLs match, your invitation probably expired. You'll need to contact your Routable admin for a new one."
        errorTitle="There's something wrong with your invitation"
        isV2
      />
    );
  }

  if (isAcceptingMembershipInvitePageErrorTypeNotFound(errorType)) {
    return (
      <Redirect
        to={{
          pathname: `/${NOT_FOUND}`,
          state: { from: location },
        }}
      />
    );
  }

  if (isFetching || !membershipInvite) {
    return <IsLoadingBrandedPage />;
  }

  return <AcceptMembershipInviteForm {...props} />;
};

export default AcceptMembershipInvitePage;
