import classnames from 'classnames';
import React from 'react';

import TableCellTextWithSubtext from '../TableCellTextWithSubtext';

import { RenderTextWithSubtextCellProps } from './RenderTextWithSubtextCell.types';

/**
 * Table cell for rendering title and subtitle text
 */
const RenderTextWithSubtextCell: React.FC<RenderTextWithSubtextCellProps> = ({
  className,
  rowData,
  subtitleProp,
  titleProp,
  ...rest
}) => (
  <TableCellTextWithSubtext
    className={classnames('truncate', { [className]: !!className })}
    subtext={rowData[subtitleProp]}
    text={rowData[titleProp]}
    {...rest}
  />
);

RenderTextWithSubtextCell.defaultProps = {
  subtitleProp: 'subtitle',
  titleProp: 'title',
};

export default RenderTextWithSubtextCell;
