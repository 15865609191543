import React from 'react';

type UseMountedValue = <T>(val: T) => T;
/**
 * Simple utility hook that will take initial value when the component
 * is mounted (first rendered) and persist that value througout the entire
 * component's life-cycle
 * @param original - Value when the component is first mounted
 */
const useMountedValue: UseMountedValue = (original) => {
  const [value] = React.useState(original);

  return value;
};

export default useMountedValue;
