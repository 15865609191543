import styled from 'styled-components';

export const TabButton = styled.button``;

export const ActionNav = styled.nav`
  background-color: transparent;
  /* Due to specificity */
  .action-nav--main-content .action-nav-tabs--list ${TabButton} {
    color: var(--grey--xx-dark);
    font-weight: 500;
    font-family: inherit;
    line-height: 1.5rem;

    &.selected {
      top: 0.375rem;

      &::after {
        border-width: 2px;
        margin-top: 0.75rem;
      }
    }
  }
`;
