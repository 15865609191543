import React from 'react';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

/**
 * Hint that notifies the user to fill out all required fields
 */
const RequiredFieldsHint: React.FC<{ className?: string }> = (props) => (
  <BaseHint
    intent={Intent.DANGER}
    text="Please fill out all required fields."
    textProps={{
      className: 'font-color--red--dark--important',
    }}
    {...props}
  />
);

export default RequiredFieldsHint;
