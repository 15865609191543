import { call, put, takeEvery } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import { MetaData } from 'actions/modifiers/meta.types';
import { ApiRequest } from 'actions/routines/createApiRoutine/';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { isFn } from 'helpers/utility';

import { FetchServiceResponse } from 'interfaces/fetchService';

import { FetchService } from 'services';

import { Response } from './api.types';

export const isApiRequest = (act: MetaData<ReduxAction>): act is MetaData<ApiRequest> =>
  !!(act?.payload?.endpoint && act?.payload?.method && isFn(act?.payload?.onSuccess) && isFn(act?.payload?.onFailure));

export function* apiSagaWorker(action: MetaData<ReduxAction>): Generator {
  const {
    payload: { onSuccess, onFailure, ...request },
    meta: { parseErrorResponseOptions } = {},
  } = action;
  let errorData: unknown = null;
  try {
    const results = (yield call<Response>(FetchService.request, request)) as FetchServiceResponse;
    if (results.ok) {
      yield put(onSuccess(results.data));
    } else {
      errorData = parseErrorResponse(results, parseErrorResponseOptions);
    }
  } catch (error: unknown) {
    errorData = parseCaughtError(error);
  }
  if (errorData) {
    yield put(handleRequestErrors(onFailure, errorData));
  }
}

export function* apiSaga(): Generator {
  yield takeEvery(isApiRequest, apiSagaWorker);
}
