import { WithMeta } from '../meta.types';

export const withThrottle =
  ({ id, timeout = 100 }: { id?: unknown; timeout?: number }): WithMeta =>
  (action) => {
    const throttle = {
      id,
      timeout,
    };

    return {
      ...action,
      meta: {
        ...action.meta,
        throttle: {
          ...throttle,
        },
      },
    };
  };
