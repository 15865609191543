import styled from 'styled-components';

export const BodyContainer = styled.div`
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1.5rem;
`;

export const Subtext = styled.p`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
