import React from 'react';

import { Emoji, FlexRow, Text, Icon, IconNames } from 'components';

import { typography, colors } from 'constants/styles';

const { REACT_APP_STATIC_BASE_PATH } = process.env;

export interface QuickswitchModalEmptySearchResultsProps {
  onUndoSearchClick: () => void;
}

const QuickswitchModalEmptySearchResults: React.FC<QuickswitchModalEmptySearchResultsProps> = ({
  onUndoSearchClick,
}) => (
  <div className="quickswitch-modal--empty-state">
    <FlexRow className="justify-content--center align-items--center">
      <img
        alt="No results"
        className="display--block max-width--full border--none"
        src={`${REACT_APP_STATIC_BASE_PATH}/images/quickswitch/table-empty-state.svg`}
      />
    </FlexRow>

    <FlexRow className="justify-content--center align-items--center">
      <Text.Bold
        className="line-height--26--important"
        color={typography.TextColor.GREY_X_DARK}
        size={typography.TextSize.LEVEL_400}
      >
        No accounts found
        <Emoji ariaLabel="detective" text="🕵" />
      </Text.Bold>
    </FlexRow>
    <FlexRow className="justify-content--center align-items--center">
      <Text.Semibold className="margin-bottom--sm line-height--25--important" size={typography.TextSize.LEVEL_200}>
        <a
          className="quickswitch-modal--undo-search"
          onClick={onUndoSearchClick}
          onKeyDown={onUndoSearchClick}
          role="link"
          tabIndex={0}
        >
          <Icon className="margin-right--xm" color={colors.colorBlueBoldHex} icon={IconNames.UNDO} size={12} />
          <span>Undo search</span>
        </a>
      </Text.Semibold>
    </FlexRow>
  </div>
);

export default QuickswitchModalEmptySearchResults;
