import React from 'react';
import { Field } from 'redux-form';

import { SelectFieldV2 } from 'components/selectV2';

import { isLength } from 'helpers/utility';

import { usePartnershipPaymentOptions } from 'hooks';

import CurrencyCodeBlockFieldTooltipText from './components/CurrencyCodeBlockFieldTooltipText';
import { CurrencyCodeBlockFieldProps } from './CurrencyCodeBlockField.types';

/**
 * Create a "fake" validator to get around this field triggering sync
 * errors in the Create Item form. At the point of creating a vendor, this
 * field is not required but was continually being validated against.
 * Note: This function needs to be defined outside of the render or else it
 * will trigger many redux register/unregister events
 */
const skipValidation = () => undefined;

const CurrencyCodeBlockField = ({
  companyIntegrationSettings,
  formValues,
  getIsDisabled,
  ledger,
  partnership,
  ...rest
}: CurrencyCodeBlockFieldProps) => {
  const { currencyCodesBillOptions, isFetching } = usePartnershipPaymentOptions({ partnershipId: partnership?.id });

  const onlyOneOptionAvailable = isLength(currencyCodesBillOptions, 1);

  const isDisabled = getIsDisabled(formValues);

  return (
    <Field
      component={SelectFieldV2}
      options={currencyCodesBillOptions}
      {...rest}
      isDisabled={isDisabled || isFetching || onlyOneOptionAvailable}
      isLoading={isFetching}
      isLocked={onlyOneOptionAvailable}
      lockedTooltipProps={{
        title: (
          <CurrencyCodeBlockFieldTooltipText
            companyIntegrationSettings={companyIntegrationSettings}
            countryCodePartner={partnership?.countryCodePartner}
            currencyCodesBillOptions={currencyCodesBillOptions}
            ledger={ledger}
            onlyOneOptionAvailable={onlyOneOptionAvailable}
          />
        ),
      }}
      validate={[skipValidation]}
    />
  );
};

export default CurrencyCodeBlockField;
