const initialState = {
  passwordInputStrength: {
    score: 0,
    feedback: 'Add another word or two. Uncommon words are better',
  },
  isFetchingPasswordStrength: false,
  areAllRequirementsMet: false,
};

export default initialState;
