import clsx from 'clsx';
import React from 'react';

import { HintIcon } from './components';

import './Hint.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  bodyClassName?: string;
  children: React.ReactNode;
  className?: string;
  hideIcon?: boolean;
  iconProps?: unknown;
  intent: string;
  isCollapsible?: boolean;
  multiline?: boolean;
  shouldRemoveBackgroundColor?: boolean;
}

/**
 * Hint component similar to the Evergreen Alert
 */
const Hint = ({
  bodyClassName,
  children,
  className,
  hideIcon,
  iconProps,
  intent,
  isCollapsible,
  multiline,
  shouldRemoveBackgroundColor,
  ...rest
}: Props) => {
  return (
    <div
      className={clsx('hint', intent, className, {
        'remove-background-color': shouldRemoveBackgroundColor,
        multiline: !!multiline,
      })}
      {...rest}
    >
      {!hideIcon && <HintIcon customization={iconProps} intent={intent} />}

      <div className={clsx('hint--body', bodyClassName, { 'width--100-percent': isCollapsible })}>{children}</div>
    </div>
  );
};

export default Hint;
