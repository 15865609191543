import { DateFormats } from '@routable/shared';
import React from 'react';

import { PLATFORM_DISPLAY_SHORT_NAME } from 'constants/platform';

import { formatDate } from 'helpers/date';
import { parseCurrency } from 'helpers/numbers';
import { getDateTimeObjectForProbableTimezone } from 'helpers/timezone';

import { BillingCodeData } from 'interfaces/billing';

import { BalanceFAQItemUsageAnswer, FullListOf } from '../components/Balance/components/BalanceFAQSection/components';

import type {
  getBalanceCheckListDataReturnType,
  getBalanceFAQItemsReturnType,
  getBalanceFAQItemsStringsReturnType,
} from './data.types';

/**
 * Parses billing data and returns all formatted strings needed for generating the balance faq.
 */
export const getBalanceCheckListData = ({
  nextDayBillingData,
  rtpBillingData,
  sameDayBillingData,
}: {
  nextDayBillingData?: BillingCodeData;
  rtpBillingData?: BillingCodeData;
  sameDayBillingData?: BillingCodeData;
}): getBalanceCheckListDataReturnType => {
  const data = {
    header: 'Current balance',
    items: [],
    listTitle: 'By maintaining a balance your team will be able to:',
  };

  if (!nextDayBillingData || !sameDayBillingData) {
    return data;
  }

  const { paymentDeliveryOptionExternalName: nextDayTitle } = nextDayBillingData;
  const { paymentDeliveryOptionExternalName: sameDayTitle } = sameDayBillingData;
  const { paymentDeliveryOptionExternalName: rtpTitle } = Object(rtpBillingData);

  let availableTransfers = `${sameDayTitle} and ${nextDayTitle}`;

  if (rtpTitle) {
    availableTransfers = rtpTitle;
  }

  data.items = [
    `Accelerate payments to vendors with ${availableTransfers} transfers.`,
    'Quickly fix negative balances caused by failed transactions.',
    'Ensure that funds are reserved for upcoming payments.',
  ];

  return data;
};

/**
 * Parses billing data and returns all formatted strings needed for generating the balance faq.
 */
export const getBalanceFAQItemsStrings = (
  nextDayBillingData: BillingCodeData,
  sameDayBillingData: BillingCodeData,
): getBalanceFAQItemsStringsReturnType => {
  const {
    cutoff: nextDayCutoffString,
    rate: nextDayRate,
    paymentDeliveryOptionExternalName: nextDayTitle,
  } = nextDayBillingData;

  const {
    cutoff: sameDayCutoffString,
    rate: sameDayRate,
    paymentDeliveryOptionExternalName: sameDayTitle,
  } = sameDayBillingData;

  const sameDayCutoff = getDateTimeObjectForProbableTimezone(sameDayCutoffString);
  const sameDayCutoffFormatted = formatDate(sameDayCutoff, DateFormats.LOCAL_TIME_AM_PM_TIMEZONE);

  const nextDayCutoff = getDateTimeObjectForProbableTimezone(nextDayCutoffString);
  const nextDayCutoffFormatted = formatDate(nextDayCutoff, DateFormats.LOCAL_TIME_AM_PM_TIMEZONE);

  const exampleAmount = parseCurrency(5000);
  const nextDayAmount = parseCurrency(nextDayRate);
  const sameDayAmount = parseCurrency(sameDayRate);

  return {
    exampleAmount,
    nextDayAmount,
    nextDayCutoff: nextDayCutoffFormatted,
    nextDayTitle,
    sameDayAmount,
    sameDayCutoff: sameDayCutoffFormatted,
    sameDayTitle,
  };
};

/**
 * FAQ for item balance usage
 */
const getBalanceFAQItemUsage = ({ exampleAmount, nextDayTitle, rtpTitle, sameDayTitle }) => {
  const question = `What is my ${PLATFORM_DISPLAY_SHORT_NAME} balance used for?`;
  const answer = (
    <BalanceFAQItemUsageAnswer
      exampleAmount={exampleAmount}
      nextDayTitle={nextDayTitle}
      rtpTitle={rtpTitle}
      sameDayTitle={sameDayTitle}
    />
  );

  return {
    question,
    answer,
  };
};

/**
 * FAQ for item fees
 */
const getBalanceFAQItemFees = () => {
  const question = `How do transaction fees work for ${PLATFORM_DISPLAY_SHORT_NAME} Balance payments?`;
  const answer = <FullListOf listType="payment fees" />;

  return {
    question,
    answer,
  };
};

/**
 * FAQ for item cutoff time
 */
const getBalanceFAQItemCutoff = () => {
  const question = `What are the cut-off times for ${PLATFORM_DISPLAY_SHORT_NAME} Balance payments?`;
  const answer = <FullListOf listType="cut-off times" />;

  return {
    question,
    answer,
  };
};

/**
 * Generates Q/A items needed for the balance faq.
 */
export const getBalanceFAQItems = ({
  nextDayBillingData,
  rtpBillingData,
  sameDayBillingData,
}: {
  nextDayBillingData?: BillingCodeData;
  rtpBillingData?: BillingCodeData;
  sameDayBillingData?: BillingCodeData;
}): getBalanceFAQItemsReturnType => {
  if (!nextDayBillingData || !sameDayBillingData) {
    return [];
  }

  const { paymentDeliveryOptionExternalName: rtpTitle } = Object(rtpBillingData);

  const { exampleAmount, nextDayTitle, sameDayTitle } = getBalanceFAQItemsStrings(
    nextDayBillingData,
    sameDayBillingData,
  );

  const faqItemUsage = getBalanceFAQItemUsage({
    exampleAmount,
    rtpTitle,
    nextDayTitle,
    sameDayTitle,
  });
  const faqItemFees = getBalanceFAQItemFees();
  const faqItemCutoff = getBalanceFAQItemCutoff();

  return [faqItemUsage, faqItemFees, faqItemCutoff];
};
