import { ApprovalRule } from '@routable/ear/models';
import { AnyAction } from 'redux';

import { approvalsEvaluateItemRoutine } from 'actions/routines/approvals';

export type ApprovalRequirementsState = {
  isLoading: boolean;
  error: Error | null;
  meta: null | {
    hash: string;
  };
  approvalLevels: ApprovalRule[];
};

const initialState: ApprovalRequirementsState = {
  isLoading: false,
  error: null,
  meta: null,
  approvalLevels: [],
};

const approvalRequirementsReducer = (state = initialState, action: AnyAction): ApprovalRequirementsState => {
  const { payload, type } = action;
  const { approvalLevels = [], error = new Error('Unable to get approvals'), meta = null } = Object(payload);

  switch (type) {
    case approvalsEvaluateItemRoutine.REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };

    case approvalsEvaluateItemRoutine.SUCCESS:
      return {
        ...state,
        approvalLevels,
        isLoading: false,
        meta,
      };

    case approvalsEvaluateItemRoutine.FAILURE:
      return {
        approvalLevels: [],
        error,
        isLoading: false,
        meta: null,
      };

    default:
      return state;
  }
};

export default approvalRequirementsReducer;
