import React from 'react';
import { Field } from 'redux-form';

import { CompanyAddressDataCard } from 'complexComponents';

import { Checkbox, FlexCol, Text } from 'components';

import { partnerFundingAccountFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';
import { typography } from 'constants/styles';

import { getCountryNameFromCode } from 'helpers/countries';
import { isInternationalPartnership } from 'helpers/partnerships';

import { useCountryCodesContext } from 'hooks';

import AddressContainer from 'modules/address/containers/AddressContainer';

import type { ManuallyAddBankAccountAddressDetailsProps } from './ManuallyAddBankAccountAddressDetails.types';

/**
 * Component rendering Address Details section in the Manually Add Bank Account
 * Side Sheet
 * @param props - Component props
 */
const ManuallyAddBankAccountAddressDetails: React.VFC<ManuallyAddBankAccountAddressDetailsProps> = ({
  address,
  country,
  partnership,
  partnerCompanyName,
  shouldApplyRegisteredAddress,
}) => {
  const countryCodesMap = useCountryCodesContext();

  const countryName = getCountryNameFromCode(countryCodesMap, country);
  const isInternational = isInternationalPartnership(partnership);
  const lockedCountryReason = `If you need to modify your country, please reach out to ${partnerCompanyName}`;

  // We want to show the "Apply registered address" checkbox if the registered address
  // country matches the partnership's country (countryCodeCompany)
  const showApplyRegisteredAddress = Boolean(address) && address?.country === partnership?.countryCodeCompany;

  return (
    <div className="manually-add-bank-account--bank-details margin-top--x-large">
      <FlexCol>
        <Text.Bold color={typography.TextColor.BLUE_DARK} size={typography.TextSize.LEVEL_475}>
          Address details
        </Text.Bold>
        <Text.Regular>
          Bank accounts in
          {` ${countryName} `}
          must be added with an associated address for compliance purposes.
        </Text.Regular>
      </FlexCol>

      {showApplyRegisteredAddress && (
        <Field
          className="margin-top--m-large"
          component={Checkbox}
          content="Apply registered address"
          name={partnerFundingAccountFormFields.APPLY_REGISTERED_ADDRESS}
        />
      )}

      <div className="margin-top--m-large">
        {shouldApplyRegisteredAddress ? (
          <CompanyAddressDataCard address={address} />
        ) : (
          <AddressContainer
            formId={formNamesFunding.ADD_MANUAL_BANK}
            isInternational={isInternational}
            lockedCountryReason={lockedCountryReason}
            stateKey="partner"
          />
        )}
      </div>
    </div>
  );
};

export default ManuallyAddBankAccountAddressDetails;
