import React from 'react';
import { Field } from 'redux-form';

import { membershipInviteAcceptV2Handler } from 'actions/routines/inviteTeamMember';

import { BoxV2, FormControl, FormFieldLabel } from 'components';

import { formNamesAuth } from 'constants/forms';
import { formStyles } from 'constants/styles';

import { requiredValidator } from 'helpers/fieldValidation';

import type { AcceptMembershipInviteFormProps } from './AcceptMembershipInviteForm.types';
import {
  AcceptMembershipInviteFormActionButtons,
  AcceptMembershipInviteFormContent,
  ReduxFormFieldRenderInputWithUpdatedErrors,
} from './components';

/**
 * Form for accepting invite to join team
 */
const AcceptMembershipInviteForm: React.FC<AcceptMembershipInviteFormProps> = ({
  company,
  companySSOSettings,
  handleSubmit,
  isSubmitting,
  membershipInvite,
}) => {
  const [loginWithPassword, setLoginWithPassword] = React.useState(false);

  const { canManageSsoSettings } = membershipInvite;

  return (
    <div className="branded--contents">
      <BoxV2 title={`Join the ${company.name} team`}>
        <form
          className="form"
          id={formNamesAuth.ACCEPT_MEMBERSHIP_INVITE}
          onSubmit={handleSubmit(membershipInviteAcceptV2Handler)}
        >
          {!loginWithPassword && (
            <AcceptMembershipInviteFormContent
              canManageSsoSettings={canManageSsoSettings}
              companySSOSettings={companySSOSettings}
            />
          )}

          {loginWithPassword && (
            <>
              <FormFieldLabel className="margin-top--m">Add a secure password</FormFieldLabel>
              <FormControl>
                <Field
                  className={formStyles.field.xl.full}
                  component={ReduxFormFieldRenderInputWithUpdatedErrors}
                  name="form.password"
                  placeholder="Password"
                  type="password"
                  validate={requiredValidator}
                />
              </FormControl>
            </>
          )}

          <AcceptMembershipInviteFormActionButtons
            canManageSsoSettings={canManageSsoSettings}
            companySSOSettings={companySSOSettings}
            isSubmitting={isSubmitting}
            loginWithPassword={loginWithPassword}
            setLoginWithPassword={() => setLoginWithPassword(true)}
          />
        </form>
      </BoxV2>
    </div>
  );
};

export default AcceptMembershipInviteForm;
