import { withFormValues } from '@routable/tablematic';

import { formNamesItem } from 'constants/forms';

import ApiLookupTableFieldBasic from './ApiLookupTableField';
import CheckboxTableFieldBasic from './CheckboxTableField';
import CurrencyTableFieldBasic from './CurrencyTableField';
import DateTableFieldBasic from './DateTableField';
import LookupTableFieldBasic from './LookupTableField';
import NumberTableFieldBasic from './NumberTableField';
import PercentTableFieldBasic from './PercentTableField';
import PhoneTableFieldBasic from './PhoneTableField';
import StaticTableFieldBasic from './StaticTableField';
import TextareaTableFieldBasic from './TextareaTableField';
import TextTableFieldBasic from './TextTableField';

const createItem = formNamesItem.CREATE_ITEM;

const ApiLookupTableField = withFormValues(ApiLookupTableFieldBasic, createItem);
const CheckboxTableField = withFormValues(CheckboxTableFieldBasic, createItem);
const CurrencyTableField = withFormValues(CurrencyTableFieldBasic, createItem);
const DateTableField = withFormValues(DateTableFieldBasic, createItem);
const LookupTableField = withFormValues(LookupTableFieldBasic, createItem);
const NumberTableField = withFormValues(NumberTableFieldBasic, createItem);
const PercentTableField = withFormValues(PercentTableFieldBasic, createItem);
const PhoneTableField = withFormValues(PhoneTableFieldBasic, createItem);
const StaticTableField = withFormValues(StaticTableFieldBasic, createItem);
const TextareaTableField = withFormValues(TextareaTableFieldBasic, createItem);
const TextTableField = withFormValues(TextTableFieldBasic, createItem);

export * from './PresentationalTableField';
export {
  ApiLookupTableField,
  CheckboxTableField,
  CurrencyTableField,
  DateTableField,
  LookupTableField,
  NumberTableField,
  PercentTableField,
  PhoneTableField,
  StaticTableField,
  TextareaTableField,
  TextTableField,
};
