import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

import { Discrepancy } from '../../purchaseOrders/useBillDiscrepancies/discrepancies.models';

interface DiscrepancyContextProps {
  discrepancies: Discrepancy[] | null;
  getDiscrepancyByLineItemId: (billLineItemId: string) => Discrepancy;
  hasDiscrepancy: (billLineItemId: string) => boolean;
  hasDiscrepancyForColumn: (billLineItemId: string, column: string) => boolean;
  lineItemIdsInLoading: string[];
  setDiscrepancies: Dispatch<SetStateAction<Discrepancy[]>>;
  setIdsInLoading: (ids: string[]) => void;
}

interface DiscrepancyProviderProps {
  children: ReactNode;
}

export const DiscrepancyContext = createContext<DiscrepancyContextProps | undefined>(undefined);

export const DiscrepancyProvider = ({ children }: DiscrepancyProviderProps) => {
  const [discrepancies, setDiscrepancies] = useState<Discrepancy[] | null>(null);
  const [lineItemIdsInLoading, setIdsInLoading] = useState<string[]>([]);

  const getDiscrepancyByLineItemId = (billLineItemId: string): Discrepancy => {
    return discrepancies?.find((discrepancy) => discrepancy.bill_line_item_id === billLineItemId);
  };

  const hasDiscrepancy = (billLineItemId: string): boolean => {
    return Boolean(discrepancies?.some((discrepancy) => discrepancy.bill_line_item_id === billLineItemId));
  };

  const hasDiscrepancyForColumn = (billLineItemId: string, column: string): boolean => {
    const discrepancy = getDiscrepancyByLineItemId(billLineItemId);
    return Boolean(discrepancy?.[column]);
  };

  return (
    <DiscrepancyContext.Provider
      value={{
        discrepancies,
        getDiscrepancyByLineItemId,
        hasDiscrepancy,
        hasDiscrepancyForColumn,
        lineItemIdsInLoading,
        setDiscrepancies,
        setIdsInLoading,
      }}
    >
      {children}
    </DiscrepancyContext.Provider>
  );
};

export const useDiscrepancyContext = (): DiscrepancyContextProps => {
  const discrepancyContext = useContext(DiscrepancyContext);
  if (!discrepancyContext) {
    throw new Error('useDiscrepancyContext must be used within a DiscrepancyProvider');
  }
  return discrepancyContext;
};
