import { useSelectorWithProps } from 'hooks';

import { Item } from 'interfaces/item';
import { Payment } from 'interfaces/redux';

import { paymentIdByPropSelector } from 'selectors/paymentsSelectors';

export const usePaymentData = (payment: Item['payment']): Payment | null => {
  const paymentDataFromRedux = useSelectorWithProps(
    paymentIdByPropSelector,
    typeof payment === 'string' ? payment : undefined,
  );
  // if a payment object was passed in, let's use it
  if (typeof payment === 'object') {
    return payment;
  }
  // if there is returned redux data, let's use it
  if (paymentDataFromRedux) {
    return paymentDataFromRedux;
  }
  // otherwise returning null
  return null;
};
