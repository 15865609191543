import React from 'react';

import { DateCell, ShowAmountCell } from 'complexComponents/Table';

import { ItemAmountKey, ItemCurrencyCodeKey } from 'enums/items';

import { Payment } from 'interfaces/redux';
import { TableColumnConfig } from 'interfaces/table.types';

import { ExternalItemsListTableItemStatus } from '../externalItemsListV2/components/ExternalItemsList/components';

import { Invoices, AcceptPaymentTableCell } from './components';

export const columns: TableColumnConfig<Payment>[] = [
  {
    accessor: 'reference',
    id: 'reference',
    baseClassName: 'table-row--column accept-payment',
    Header: 'Reference',
    width: 5,
    Cell: AcceptPaymentTableCell,
  },
  {
    accessor: 'dateSent',
    id: 'dateSent',
    baseClassName: 'table-row--column',
    displayName: 'Sent Date',
    Header: 'Sent Date',
    width: 2,
    Cell: DateCell,
  },
  {
    accessor: 'dateExpected',
    id: 'dateExpected',
    baseClassName: 'table-row--column',
    displayName: 'Expected Date',
    Header: 'Expected Date',
    width: 2,
    Cell: DateCell,
  },
  {
    accessor: 'amount',
    id: 'amount',
    baseClassName: 'table-row--column',
    displayName: 'Total Amount',
    Header: 'Total Amount',
    width: 3,
    Cell: ({ row: { original } }) => (
      <ShowAmountCell
        currencyCodeKey={ItemCurrencyCodeKey.RECEIVER}
        dataKey={ItemAmountKey.PARTNER}
        rowData={original}
      />
    ),
  },
  {
    accessor: 'status',
    id: 'status',
    baseClassName: 'table-row--column',
    displayName: 'Status',
    Header: 'Status',
    width: 3,
    Cell: ExternalItemsListTableItemStatus,
  },
  {
    accessor: 'itemCount',
    id: 'itemCount',
    baseClassName: 'table-row--column',
    displayName: 'Invoices',
    Header: 'Invoices',
    width: 2,
    Cell: Invoices,
  },
];
