import { z } from 'zod';

/**
 * Zod model for parsing API field-level error
 */
export const singleFieldErrorModel = z.array(z.string()).transform((val) => val.join(', '));

/**
 * Zod model for parsing API multi-field-level error
 */
export const multiFieldErrorModel = z.record(z.string(), z.array(z.string())).transform((val) => {
  return Object.keys(val).reduce((acc, fieldName) => {
    acc[fieldName] = val[fieldName].join(', ');
    return acc;
  }, {});
});

export const fieldErrorModel = singleFieldErrorModel.or(multiFieldErrorModel);

/**
 * Zod model for parsing API error response
 */
export const apiErrorModel = z
  .object({
    data: z.object({
      errors: z.object({
        general: z.object({ detail: fieldErrorModel }).optional(),
        fields: z.record(z.string(), fieldErrorModel).optional(),
      }),
    }),
  })
  .transform((data) => data.data.errors);

/**
 * Given an unknown error object, it validates it to check if it is a valid
 * API error and returns validated data. If error object is not passed or if
 * it is not valid, it returns null.
 * @param apiError - Unknown error object
 */
export const getDerivedApiFieldErrors = (apiError: unknown): z.infer<typeof apiErrorModel> | null => {
  if (!apiError) {
    return null;
  }

  const parsedApiError = apiErrorModel.safeParse(apiError);

  return parsedApiError.success ? parsedApiError.data : null;
};
