import React from 'react';

import { ReduxFormFieldRenderInput } from 'components/input';

import { FormFieldProps } from 'interfaces/forms';

import { replaceExistingAccountErrorFromProps } from './helpers';

/**
 * Wrapper for ReduxFormFieldRenderInput that updates the props.meta.error messages.
 *
 * This is meant to be used in Accept Membership form.
 * It adds a link element that takes the user to the password reset page.
 */
const ReduxFormFieldRenderInputWithUpdatedErrors = (props: FormFieldProps): JSX.Element => (
  <ReduxFormFieldRenderInput {...replaceExistingAccountErrorFromProps(props)} />
);

export default ReduxFormFieldRenderInputWithUpdatedErrors;
