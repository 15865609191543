import { RoutableIntervals, RoutableObject } from '@routable/framework';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import _cloneDeep from 'lodash/cloneDeep';

import { getAuthToken } from 'helpers/auth';
import { areDevtoolsEnabled } from 'helpers/env';
import { isResponseErrorCodeTimeout } from 'helpers/http';
import { getCurrentCompanyId, localStorageSet } from 'helpers/localStorage';
import { getQueryString } from 'helpers/queryParams';
import { isFilterAll, isFilterNeedsMyApproval, isFilterNeedsOtherApproval } from 'helpers/urls';
import { allKeys, allValues, hasLength, isEqual } from 'helpers/utility';

import { ItemsRequest, ItemRequestURLParams } from 'interfaces/itemsRequest/ItemsRequest';

import { filterToUrlParams } from 'modules/itemFilters/filterToUrlParams';

import { payloadToUnderscore } from './api/formatHelpers';
import FetchService, { instance as axiosInstance } from './fetch';

export type ParsedResponse = AxiosResponse & {
  ok: boolean;
  errors?: string[];
};

export default class ItemsRequestService {
  static buildAxiosConfigFromRequest = async (req: ItemsRequest): Promise<AxiosRequestConfig> => {
    const controller = new AbortController();
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: await getAuthToken(),
        'fe-version': process.env.REACT_APP_VERSION,
        'X-Location': window.location.href,
        'X-Company-Id': getCurrentCompanyId(),
      },
      method: req.method,
      url: '/items/',
      params: {},
      signal: req.signal || controller.signal,
      responseType: 'json',
      paramsSerializer: getQueryString,
    };

    if (areDevtoolsEnabled()) {
      const routableDebugDelayObject = FetchService.buildRoutableDebugDelayObject(req.method);
      const hasRoutableDebugDelayObject = hasLength(allValues(routableDebugDelayObject));

      if (hasRoutableDebugDelayObject) {
        if (isEqual(req.method, 'GET')) {
          config.params = routableDebugDelayObject;
        }
      }
    }

    if (req.url) {
      config.url = req.url;
    } else {
      config.params = payloadToUnderscore({
        ...config.params,
        ...ItemsRequestService.createItemsRequestURLParams(req),
      });
    }

    if (isEqual(req.method, 'GET') && req.signal) {
      config.signal = req.signal || controller.signal;
    }

    config.headers = { ...config.headers, ...req.headers };
    if (req.removeAuthToken) {
      // After conferring with AWS docs, a single space works to remove the authorization
      config.headers.Authorization = ' ';
    }

    if (req.idempotencyKey) {
      config.headers['Idempotency-Key'] = req.idempotencyKey;
    }

    return config;
  };

  // OCR transform is done here instead of at the reducer level
  static transformAttachments = (res: RoutableObject, resOg: RoutableObject) => {
    // Yes we are using "any" here because it is better than "unknown" for what we are doing.
    if (res && res.data && res.data.item) {
      try {
        Object.entries(res.data.item).forEach(([key]) => {
          const item = res.data.item[key];
          if (item?.relationships?.attachments?.data) {
            res.data.item[key].attributes.annotationFiles = resOg.included
              .filter((includedItem: RoutableObject) =>
                res.data.item[key].relationships.attachments.data.find(
                  (attachment: RoutableObject) => attachment.id === includedItem.id,
                ),
              )
              .map((mapItem: RoutableObject) => ({
                id: mapItem.id,
                type: mapItem.type,
                filename: mapItem.attributes.filename,
                status: mapItem.attributes.latest_annotation_status || 'no_latest_annotation_status',
              }));
          }
        });
      } catch (ex: RoutableObject) {
        return res;
      }
    }
    return res;
  };

  static makeItemsRequest = async (req: ItemsRequest): Promise<ParsedResponse | Error> => {
    const originalResponse = await axiosInstance
      .request(await ItemsRequestService.buildAxiosConfigFromRequest(req))
      .catch((rejectionErr) => {
        if (!rejectionErr?.response) {
          if (req.returnRejection) {
            return rejectionErr;
          }

          return undefined;
        }

        return rejectionErr.response;
      });

    // Auto logout code.
    const forceTimeout: RoutableObject =
      originalResponse && originalResponse.headers ? +originalResponse.headers['auto-logout-force'] : 0;
    const inactivity: RoutableObject =
      originalResponse && originalResponse.headers ? +originalResponse.headers['auto-logout-inactivity'] : 0;

    if (inactivity > 0 && forceTimeout > 0) {
      localStorageSet('auto-logout-force', forceTimeout);
      localStorageSet('auto-logout-inactivity', inactivity);

      // Set the servers logout inactivity values here.
      // note we do not need to use local storage for this.
      RoutableIntervals.ForceLogout.setInactivity(forceTimeout);
      RoutableIntervals.AutoLogout.setInactivity(inactivity);
    }

    // Nginx errors won't return a response, so there's nothing we can do more here
    // Timeout errors should also not be parsed any further
    if (!originalResponse || isResponseErrorCodeTimeout(originalResponse.code)) {
      return originalResponse;
    }

    const parsedResponse = _cloneDeep(originalResponse);

    parsedResponse.originalData = originalResponse.data;
    // Add response ok flag
    parsedResponse.ok = FetchService.isResponseOK(parsedResponse);

    // Apply default data transformations to successful response
    if (parsedResponse.ok) {
      FetchService.defaultTransformers.forEach((transformer) => {
        parsedResponse.data = transformer(parsedResponse.data, originalResponse.data, {
          endpoint: parsedResponse.request?.responseURL,
        });
      });

      // Transform the attachments if they are avaiable
      return ItemsRequestService.transformAttachments(parsedResponse, parsedResponse.originalData);
    }

    // Add errors from the backend if they exist on a failed call
    if (originalResponse.data && originalResponse.data.errors) {
      parsedResponse.errors = originalResponse.data.errors;
    }

    // Log errors only in development
    // (next comment excludes the log from test coverage)
    // /* istanbul ignore next */
    if (areDevtoolsEnabled()) {
      // eslint-disable-next-line no-console
      console.log(`Request failed:
      - Id: ${parsedResponse?.headers?.['request-id']}
      - Status: ${parsedResponse?.status}
      - URL: ${req.method} /items/`);
    }

    return parsedResponse;
  };

  static createItemsRequestURLParams(req: ItemsRequest): ItemRequestURLParams {
    const params: ItemRequestURLParams = {
      kind: req.kind,
    };

    if (req.search) {
      params.search = req.search;
    }

    if (req.bulkAction) {
      params.bulkAction = req.bulkAction;
    }

    if (req.bulkActionStatus) {
      params.bulkActionStatus = req.bulkActionStatus;
    }

    if (req.csvUpload) {
      params.csvUpload = req.csvUpload;
    }

    if (isFilterNeedsMyApproval(req.filter)) {
      params.myApprovals = 'true';
    }

    if (isFilterNeedsOtherApproval(req.filter)) {
      params.myApprovals = 'false';
    }

    if (req.status && !isFilterAll(req.status)) {
      params.status = req.status;
    }

    if (req.sort) {
      params.sort = req.sort;
    }

    if (hasLength(req.filters)) {
      const filterParams = req.filters.reduce((agg, f) => ({ ...agg, ...filterToUrlParams(f) }), {});
      allKeys(filterParams).forEach((key) => {
        params[key] = filterParams[key];
      });
    }

    if (req.pagination) {
      params['page[number]'] = req.pagination.page;
      if (req.pagination.pageSize) {
        params['page[size]'] = req.pagination.pageSize;
      }
    }

    return params;
  }
}
