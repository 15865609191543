import React from 'react';

import { EmailAlreadyInvitedHintProps } from 'components/hintTypes/EmailAlreadyInvitedHint/EmailAlreadyInvitedHint.types';
import { EmailAlreadyInvitedHintText } from 'components/hintTypes/EmailAlreadyInvitedHint/EmailAlreadyInvitedHintText';
import { textHelpers } from 'components/hintTypes/helpers';

export const EmailAlreadyInvitedError = ({ member, onNavigate }: EmailAlreadyInvitedHintProps): JSX.Element => (
  <>
    {textHelpers.getEmailAlreadyInvitedHintTitle(member)} <EmailAlreadyInvitedHintText onNavigate={onNavigate} />
  </>
);
