import React from 'react';

import { ButtonV2 } from 'components';

import { ButtonAppearance, ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import { ExternalBackButtonProps } from './ExternalBackButton.types';

import './ExternalBackButton.scss';

/**
 * ExternalBackButton applies some common props to ButtonV2.
 */
const ExternalBackButton: React.FC<ExternalBackButtonProps> = ({ onDecrementStep, ...rest }) => (
  <ButtonV2
    appearance={ButtonAppearance.BORDERLESS}
    className="external-button external-button--back"
    intent={Intent.NEUTRAL}
    leftIconClassName="margin-right--xm"
    leftIconName="arrow-left"
    leftIconSize={sizes.iconSizes.XX_LARGE}
    onClick={onDecrementStep}
    size={ButtonSize.LARGE}
    type="button"
    {...rest}
  >
    <span>Back</span>
  </ButtonV2>
);

export default ExternalBackButton;
