import { Search } from '@carbon/icons-react';
import {
  Clickable,
  getDataAlign,
  HeaderTemplate,
  ModalLookupTable,
  RadioSelect,
  RowSelect,
  Table,
  TableBodyTemplate,
  TableCell,
  TableHeaderCell,
  TableRow,
  Tooltip,
  TruncatableText,
  useDispatchTableEvent,
  useManualData,
  useTable,
} from '@routable/components';
import { RoutableEventsSync } from '@routable/framework';
import React from 'react';
import styled from 'styled-components';

import { LineItemStyles } from 'constants/lineItems';

import { parseCurrency } from 'helpers/numbers';

import type { PoLineItemsTableProps } from './PoLineItemsTable.types';

export const TableCellWrapper = styled(TableCell).withConfig({
  displayName: 'TableCellWrapper',
})<{ truncate?: boolean }>`
  padding: 20px 12px;
  // The global line height is being overwritten by a component in the Table,
  // so we must set it again here.
  line-height: 1.5;
  text-overflow: ${({ truncate = true }) => (truncate ? 'ellipsis' : 'unset')};
`;

export const PoLineItemsTable = ({
  isMultiSelect = true,
  onClose,
  onSubmit,
  purchaseOrder,
  submitText,
  tableConfig,
  tableData,
}: PoLineItemsTableProps) => {
  const { searchData, ...results } = useManualData({
    data: tableData.data,
    tableName: tableConfig.tableName,
  });
  const tableConfigAndData = useTable(tableConfig, results);

  const isCheckAllDisabled =
    !results.data?.length || results.data?.every?.((lineItem: { isDisabled: boolean }) => lineItem.isDisabled);

  const changeSingleSelection = useDispatchTableEvent({
    tableName: tableConfig.tableName,
    event: 'selected:update:single',
  });

  const onSelectRow = (event, id) => {
    // Prevent duplicate onChange when clicking on the checkbox itself
    if (event.target.type === 'checkbox') {
      return;
    }

    changeSingleSelection([id]);
  };

  const onClearData = () => {
    RoutableEventsSync.Publish('clear-modal-lookup-table-search', {});
  };

  return (
    <ModalLookupTable.TableWrapper
      isMultiSelect={isMultiSelect}
      onClose={onClose}
      onSubmit={onSubmit}
      setSearch={searchData}
      submitText={submitText}
      tableName={tableConfig.tableName}
    >
      <Table
        {...tableConfigAndData}
        clearSelectionOnMount={false}
        display="full-width"
        fixedWidth="900px"
        footerType="none"
      >
        <HeaderTemplate
          Template={({ data = [] }) => (
            <TableRow>
              {data.map(({ isSelectObj, columnId, caption, hAlign }) => (
                <TableHeaderCell data-column={columnId} key={columnId} {...getDataAlign(hAlign)}>
                  {isSelectObj && isMultiSelect && (
                    <RowSelect isCheckAll isDisabled={isCheckAllDisabled} valueKey="purchase_order_line_item_id" />
                  )}

                  {!isSelectObj && caption}
                </TableHeaderCell>
              ))}
            </TableRow>
          )}
        />
        <TableBodyTemplate
          noDataComponent={
            <div className="flex items-center justify-center min-h-[160px]">
              <span className="text-lg text-black">No items available</span>
            </div>
          }
          noDataForFiltersComponent={
            <div className="flex flex-col items-center justify-center min-h-[160px]">
              <div className="p-3 mb-3 rounded-lg bg-grey-5">
                <Search size={20} />
              </div>
              <span className="text-lg leading-6 text-black">No items match your search</span>
              <span className="text-sm text-grey-60">
                {`Try changing your search terms or `}
                <Clickable className="text-blue-40" onClick={onClearData}>
                  clear your search
                </Clickable>
              </span>
            </div>
          }
          results={{ ...results }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Template={({ data }: any) => {
            const { isDisabled } = data;

            const amount = parseFloat(String(data.amount)) < 0 ? 0 : data.amount;
            const quantity = parseFloat(String(data.quantity)) < 0 ? 0 : data.quantity;
            const unitPrice = parseFloat(String(data.unit_price)) < 0 ? 0 : data.unit_price;

            return (
              <Tooltip
                data-testid={`link-disabled-tooltip-${data.purchase_order_line_item_id}`}
                tooltip={
                  isDisabled
                    ? "You can't add this PO line because it's already linked to another bill line."
                    : undefined
                }
                variant="light"
              >
                <TableRow
                  isDisabled={isDisabled}
                  key={data.purchase_order_line_item_id}
                  onClick={isDisabled ? undefined : (e) => onSelectRow(e, data.purchase_order_line_item_id)}
                >
                  <TableCellWrapper column="id">
                    {!isMultiSelect ? (
                      <RadioSelect isDisabled={isDisabled} value={data.purchase_order_line_item_id} />
                    ) : (
                      <RowSelect isDisabled={isDisabled} value={data.purchase_order_line_item_id} />
                    )}
                  </TableCellWrapper>
                  <TableCellWrapper column="productName">
                    <TruncatableText className="font-medium" text={data[data.style].name} />
                  </TableCellWrapper>
                  <TableCellWrapper column="description">
                    <TruncatableText text={data.description} />
                  </TableCellWrapper>
                  {data.style === LineItemStyles.ITEM && (
                    <>
                      <TableCellWrapper className="text-right" column="unit_price" truncate={false}>
                        <div>{`${parseCurrency(unitPrice, { symbol: '' })} ${purchaseOrder.currencyCode}`}</div>
                      </TableCellWrapper>
                      <TableCellWrapper className="text-center" column="quantity" truncate={false}>
                        <div>{quantity}</div>
                      </TableCellWrapper>
                    </>
                  )}
                  <TableCellWrapper className="text-right" column="amount" truncate={false}>
                    <div>{`${parseCurrency(amount, { symbol: '' })} ${purchaseOrder.currencyCode}`}</div>
                  </TableCellWrapper>
                </TableRow>
              </Tooltip>
            );
          }}
        />
      </Table>
    </ModalLookupTable.TableWrapper>
  );
};
