import styled from 'styled-components';

import { Icon } from 'components/icon';

export const Item = styled.li`
  border-bottom: 1px solid var(--grey--x-light);
  cursor: pointer;
  list-style: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const Details = styled.details``;

export const Status = styled.span<{ hasSubsteps: boolean }>`
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  gap: 0.25rem;
  line-height: 1.25rem;
  color: ${({ color }) => color || 'inherit'};
  ${({ hasSubsteps }) => !hasSubsteps && `padding-right: 1.125rem`}
`;

export const Label = styled.summary<{ disabled: boolean }>`
  align-items: center;
  color: ${({ color }) => color};
  display: flex;
  justify-content: space-between;
  line-height: 1.5rem;
  ${({ disabled }) => disabled && 'pointer-events: none;'}
`;

export const StatusLabel = styled.span<{ disabled: boolean }>`
  &:hover {
    ${({ disabled }) => !disabled && 'text-decoration: underline;'}
  }
`;

export const ChevronIcon = styled(Icon)`
  ${Details}[open] & {
    transform: rotate(90deg);
  }
`;

export const SubstepsContainer = styled.ul`
  margin-top: 0.5rem;
`;
