import React from 'react';

import { ItemKinds } from 'enums/items';

import { isKindPayable } from 'helpers/items';
import { ternary } from 'helpers/utility';

import { TableConfigColumn } from 'interfaces/table.types';

import {
  ExternalItemsListTableAmount,
  ExternalItemsListTableDate,
  ExternalItemsListTableInvoiceNumber,
  ExternalItemsListTableItemStatus,
} from './components';

type GetExternalItemsListTableDataOptions = {
  itemsKind: ItemKinds;
};

/**
 * Returns prepared PartnershipPaymentItems table columns
 * @param {GetExternalItemsListTableDataOptions} options
 * @returns {TableConfigColumn[]}
 */
export const getExternalItemsListTableData = ({
  itemsKind,
}: GetExternalItemsListTableDataOptions): TableConfigColumn[] => {
  const invoiceReferenceCells = ternary(
    isKindPayable(itemsKind),
    [
      {
        accessor: 'reference',
        id: itemsKind,
        baseClassName: 'table-row--column',
        displayName: 'Reference',
        Header: 'Reference',
        width: 5,
        Cell: (props) => <ExternalItemsListTableInvoiceNumber fallbackText="View payment" {...props} />,
      },
    ],
    [
      {
        accessor: 'reference',
        id: 'reference',
        baseClassName: 'table-row--column',
        displayName: 'Invoice No.',
        Header: 'Invoice No.',
        width: 4,
        Cell: (props) => <ExternalItemsListTableInvoiceNumber fallbackText="View invoice" {...props} />,
      },
      {
        accessor: 'partnerReference',
        id: itemsKind,
        baseClassName: 'table-row--column',
        displayName: 'Reference',
        Header: 'Reference',
        width: 3,
        Cell: (props) => <ExternalItemsListTableInvoiceNumber fallbackText="Not available" hideLink {...props} />,
      },
    ],
  );

  return [
    ...invoiceReferenceCells,
    {
      accessor: 'dateSent',
      id: 'dateSent',
      baseClassName: 'table-row--column',
      displayName: 'Sent Date',
      Header: 'Sent Date',
      width: 2,
      Cell: ExternalItemsListTableDate,
    },
    {
      accessor: 'dateDue',
      id: 'dueDate',
      baseClassName: 'table-row--column',
      displayName: 'Due Date',
      Header: 'Due Date',
      width: 2,
      Cell: ExternalItemsListTableDate,
    },
    {
      accessor: 'amount',
      id: 'amount',
      baseClassName: 'table-row--column',
      displayName: 'Total Amount',
      Header: 'Total Amount',
      width: 2,
      Cell: ExternalItemsListTableAmount,
    },
    {
      accessor: 'status',
      id: 'status',
      baseClassName: 'table-row--column',
      displayName: 'Status',
      Header: 'Status',
      width: 3,
      Cell: ExternalItemsListTableItemStatus,
    },
  ];
};
