import classNames from 'classnames';
import React from 'react';

import withChatOnClick from 'hoc/withChatOnClick';

import { ContactUsProps } from './ContactUs.types';

/**
 * ContactUs link
 */
const ContactUs: React.FC<ContactUsProps> = ({
  children = 'contact support',
  className,
  onClick,
  textAfter,
  textBefore,
  useChatBubble = true,
}) => (
  <>
    <a
      className={classNames({
        primary: true,
        'enable-pointer-events': true,
        [className]: !!className,
      })}
      onClick={onClick}
      role="presentation"
    >
      {!!textBefore && `${textBefore} `}

      {children}

      {!!textAfter && ` ${textAfter}`}
    </a>

    {useChatBubble && (
      <span aria-label="Chat" className="margin-left--sm" role="img">
        💬
      </span>
    )}
  </>
);

export default withChatOnClick(ContactUs);
