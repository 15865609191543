import React from 'react';
import { Switch, useLocation } from 'react-router-dom';

import { BrandWrapperV2, ProgressBar } from 'components';

import {
  SIGNUP_CONNECT_BANK,
  SIGNUP_CREATE_ACCOUNT,
  SIGNUP_CREATE_NAMESPACE,
  SIGNUP_VERIFY_EMAIL,
} from 'constants/routes';

import { getCompanyNamespaceFromUrl, getDomainWithOrWithoutCompanyNamespace } from 'helpers/urls';

import Authenticated from 'modules/app/AuthenticatedRoute';
import { Help } from 'modules/app/components';
import NotAuthenticated from 'modules/app/NotAuthenticatedRoute';
import SignupConnectBankContainer from 'modules/signup/connectBank/containers/SignupConnectBankContainer';
import CreateAccount from 'modules/signup/CreateAccount';
import SelectWorkspace from 'modules/signup/SelectWorkspace';
import VerifyEmail from 'modules/signup/VerifyEmail';

import { signupFlowSteps } from './constants/progressBar';
import { getCurrentStepPositionFromPath } from './helpers/progressBar';

/**
 * Signup container for all steps
 */
const SignupV2 = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (getCompanyNamespaceFromUrl()) {
      // redirect to base domain if the `signup` page is visited using a subdomain
      window.location.assign(`${getDomainWithOrWithoutCompanyNamespace()}${SIGNUP_CREATE_ACCOUNT}`);
    }
  }, []);

  return (
    <>
      <Help />
      <BrandWrapperV2 className="has--progress-bar" showFooter={false}>
        <Switch>
          {/* Unauthenticated paths */}
          <NotAuthenticated component={CreateAccount} exact path={SIGNUP_CREATE_ACCOUNT} />

          {/* Authenticated paths */}
          <Authenticated component={VerifyEmail} exact path={SIGNUP_VERIFY_EMAIL} />

          <Authenticated component={SelectWorkspace} exact path={SIGNUP_CREATE_NAMESPACE} />

          <Authenticated component={SignupConnectBankContainer} exact path={SIGNUP_CONNECT_BANK} />
        </Switch>

        <ProgressBar
          currentStepPosition={getCurrentStepPositionFromPath(location.pathname)}
          showNum={false}
          steps={signupFlowSteps}
        />
      </BrandWrapperV2>
    </>
  );
};

export default SignupV2;
