import { combineReducers } from 'redux';

import byIdReducer from './byIdReducer';
import isFetchingReducer from './isFetchingReducer';
import paginationReducer from './paginationReducer';

export default combineReducers({
  byId: byIdReducer,
  isFetching: isFetchingReducer,
  pagination: paginationReducer,
});
