import clsx from 'clsx';
import React from 'react';

import { formatNumber } from 'helpers/numbers';
import { isNum } from 'helpers/utility';

import TableCellTextWithSubtext from '../TableCellTextWithSubtext';

import { RenderTextCellProps } from './RenderTextCell.types';

/**
 * Renders simple text cell
 */
const RenderTextCell = ({ className, dataKey, rowData }: RenderTextCellProps) => {
  const text = isNum(rowData[dataKey]) ? formatNumber(rowData[dataKey] as number) : rowData[dataKey];

  return <TableCellTextWithSubtext className={clsx(className, { empty: !text })} text={text} />;
};

export default RenderTextCell;
