import classNames from 'classnames';
import React from 'react';
import { Field } from 'redux-form';

import { FormControl, FormFieldLabel, ReduxFormFieldRenderInput } from 'components';

import { PLATFORM_DISPLAY_SHORT_NAME, PLATFORM_DOMAIN } from 'constants/platform';
import { formStyles } from 'constants/styles';

import { requiredValidator } from 'helpers/fieldValidation';
import { getCompanyNamespaceFromUrl } from 'helpers/urls';
import { isValueEmpty } from 'helpers/utility';

import ReduxFormFieldRenderInputWithUpdatedErrors from '../ReduxFormFieldRenderInputWithUpdatedErrors';

import { AcceptMembershipInviteFormContentProps } from './AcceptMembershipInviteFormContent.types';

/**
 * Main form content for AcceptMembershipInviteForm
 */
const AcceptMembershipInviteFormContent: React.FC<AcceptMembershipInviteFormContentProps> = ({
  companySSOSettings,
  canManageSsoSettings,
}) => {
  const isSSOTurnedOn = !isValueEmpty(companySSOSettings);
  const isSSOTurnedOnAndOptional = isSSOTurnedOn && !companySSOSettings.ssoRequired;

  const companyNamespace = getCompanyNamespaceFromUrl();

  return (
    <>
      <div
        className={classNames({
          'display--none': !!companyNamespace,
        })}
      >
        <FormFieldLabel>{`Navigate here to log in to ${PLATFORM_DISPLAY_SHORT_NAME}`}</FormFieldLabel>
        <FormControl>
          <Field
            className={`${formStyles.field.xl.full} namespace`}
            component={ReduxFormFieldRenderInput}
            dataFullStory
            isDisabled
            name="form.namespace"
            placeholder="workspace-name"
            type="text"
          >
            <div className="inline-label inline-right disabled">
              <span>{`.${PLATFORM_DOMAIN}`}</span>
            </div>
          </Field>
        </FormControl>
      </div>

      <FormFieldLabel
        className={classNames({
          'margin-top--m': !companyNamespace,
        })}
      >
        Confirm your info
      </FormFieldLabel>
      <FormControl>
        <Field
          className={formStyles.field.xl.full}
          component={ReduxFormFieldRenderInput}
          isDisabled
          name="form.email"
          placeholder="Email address"
          type="text"
        />
      </FormControl>

      <FormControl>
        <Field
          className={formStyles.field.xl.left}
          component={ReduxFormFieldRenderInput}
          name="form.firstName"
          placeholder="First name"
          type="text"
          validate={requiredValidator}
        />

        <Field
          className={formStyles.field.xl.right}
          component={ReduxFormFieldRenderInput}
          name="form.lastName"
          placeholder="Last name"
          type="text"
          validate={requiredValidator}
        />
      </FormControl>

      {(canManageSsoSettings || !isSSOTurnedOn) && (
        <FormControl>
          <Field
            className={formStyles.field.xl.full}
            component={ReduxFormFieldRenderInputWithUpdatedErrors}
            name="form.password"
            placeholder="Password"
            type="password"
            validate={requiredValidator}
          />
        </FormControl>
      )}

      {!canManageSsoSettings && isSSOTurnedOnAndOptional && (
        <FormFieldLabel className="margin-top--m">
          Access your account
          <ul className="list-disc margin-top--xm padding-left--m">
            <li>If your company uses Gmail for business, log in with Google. You can also add a password later.</li>
            <li>If your company does not use Gmail for business, you&apos;ll need to log in with a password.</li>
          </ul>
        </FormFieldLabel>
      )}
    </>
  );
};

export default AcceptMembershipInviteFormContent;
