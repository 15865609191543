import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Dropzone from 'complexComponents/Dropzone';
import { SelectedFileContainer } from 'complexComponents/Dropzone/components';
import type { W8FormFields } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';

import { ButtonV2, IconNames } from 'components';

import { ButtonAppearance } from 'constants/button';
import { PDF } from 'constants/mimeTypes';
import { colors } from 'constants/styles';

const RenderFileUpload = ({
  name,
  shouldUnregister,
}: {
  name: W8FormFields;
  shouldUnregister?: boolean;
}): JSX.Element => {
  const { control, setValue } = useFormContext();

  const handleAddFile = (file) => {
    setValue(name, file, { shouldValidate: true, shouldTouch: true });
  };

  const handleRemoveSelectedFile = () => {
    setValue(name, null, { shouldValidate: true });
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value }, fieldState }) => {
        if (value) {
          return (
            <SelectedFileContainer>
              {value.filename}
              <ButtonV2
                appearance={ButtonAppearance.BORDERLESS}
                isIconButton
                leftIconColor={colors.colorBlueBoldHex}
                leftIconName={IconNames.TRASH}
                onClick={handleRemoveSelectedFile}
              />
            </SelectedFileContainer>
          );
        }

        return (
          <Dropzone
            accept={[PDF]}
            addFile={handleAddFile}
            errorMessage={fieldState.error?.message}
            name={name}
            supportText="Only PDF files are allowed. Max file size 2 MB"
          />
        );
      }}
      shouldUnregister={shouldUnregister}
    />
  );
};

export default RenderFileUpload;
