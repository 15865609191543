import styled from 'styled-components';

export const ModalContainer = styled.div`
  .base-text {
    /* style inline in the component, added this so we don't need to refactor swal now */
    /* TODO: when we change the swal component to add it in lib, we can remove that style inline */
    color: var(--neutral-black) !important;
  }
`;
export const Title = styled.h3`
  color: var(--grey--xx-dark);
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

export const BulletContainer = styled.div`
  margin-bottom: 1rem;
`;

export const BulletListContainer = styled.div`
  padding-left: 0.5rem;

  /* Maybe we can update the list later? Or do a styled bullet list? */
  .bullet-list {
    align-items: center;
  }
  .bullet-list--bullet {
    margin-top: 0;
    margin-right: 0.5rem;
  }
  .bullet-list--text {
    font-size: 1rem;
  }
`;
