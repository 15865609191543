import React from 'react';

import { ItemKinds } from 'enums/items';

import { ExternalItemsList } from '../components';

import { PartnershipRequestItemsProps } from './PartnershipRequestItems.types';

/**
 * Component rendering external partnership request items list
 * @param {PartnershipRequestItemsProps} props
 * @returns {StatelessComponent}
 */
const PartnershipRequestItems: React.FC<PartnershipRequestItemsProps> = ({ partnershipItems }) => (
  <ExternalItemsList itemsKind={ItemKinds.PAYABLE} partnershipItems={partnershipItems} />
);

PartnershipRequestItems.defaultProps = {
  partnershipItems: undefined,
};

export default PartnershipRequestItems;
