import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 1.5rem;
  width: 21.125rem;

  @media screen and (max-width: 1024px) {
    padding: 1rem;
    width: 100%;
    min-width: 16rem;
  }
`;

export const Links = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 0.875rem;
`;

export const LogoutButton = styled.button`
  border-top: 1px solid var(--grey--x-light);
  color: var(--grey--x-dark);
  cursor: pointer;
  font-weight: 500;
  padding-top: 1rem;
  text-align: left;
  width: 100%;
`;
