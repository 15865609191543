import { useInternationalBankFields } from '@routable/fund-routes';
import React from 'react';

import {
  ButtonV2,
  IconNames,
  Form,
  LockedCountryInput,
  ModalFooter,
  SideBarPanel,
  ContentBlockShade,
  Text,
} from 'components';

import { ButtonSize } from 'constants/button';
import { commonFormFields } from 'constants/formFields';
import { formNamesFunding } from 'constants/forms';
import { UserTypeToInternationalFundingBusinessType } from 'constants/funding';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';

import { PartnershipCountryCodeKey } from 'enums/partnerships';

import { isExternalPathAny, isExternalPathV2Pay } from 'helpers/external';
import { getPartnershipCurrency, isInternationalPartnership } from 'helpers/partnerships';

import { useFormSelectedCurrency } from 'hooks';

import PartnerCompanyInternationalTaxForm from 'modules/createPartnerCompany/presenters/PartnerCompanyInternationalTaxForm';
import {
  NationalityField,
  NonGovermentOrganizationFields,
} from 'modules/fundingAccount/components/AddInternationalPartnerFundingAccountForm/components';

import {
  ManuallyAddBankAccountAddressDetails,
  ManuallyAddBankAccountBankDetails,
  ManuallyAddBankAccountErrorHint,
  ManuallyAddBankAccountHelpText,
} from './components';
import { shouldDisplayErrorHint } from './helpers';
import type { ManuallyAddBankAccountSideSheetProps } from './ManuallyAddBankAccountSideSheet.types';

/**
 * Component rendering Manually Add Bank Account Sidesheet.
 */
const ManuallyAddBankAccountSideSheet = ({
  availableCurrencies,
  company,
  currencyCodeMap,
  errors,
  form,
  fundingAccounts,
  isFormValid,
  onClose,
  partnerCompany,
  partnership,
  sidePanel,
  submitting,
  handleSubmit,
}: ManuallyAddBankAccountSideSheetProps) => {
  const selectedCurrency = useFormSelectedCurrency(form);

  const { data: fields, isFetching: isFetchingFields } = useInternationalBankFields({
    currencyCode: selectedCurrency.code,
    countryCode: partnership.countryCodeCompany,
    businessType: UserTypeToInternationalFundingBusinessType[company.companyType],
  });

  const isInternational = isInternationalPartnership(partnership, PartnershipCountryCodeKey.COMPANY);
  const showSingularCurrencyHelpText = !availableCurrencies || availableCurrencies?.length === 1;
  const isExternal = isExternalPathAny();
  const isCustomerFlow = isExternalPathV2Pay();
  const showCompanyInternationalFields = isInternational && isExternal && !isCustomerFlow && !fundingAccounts.length;

  return (
    <SideBarPanel headerText="Add your bank account information" onClose={onClose} sidePanel={sidePanel}>
      <ContentBlockShade className="height--100-percent" isShady={isFetchingFields}>
        <Form id={form} onSubmit={handleSubmit}>
          {showSingularCurrencyHelpText && (
            <ManuallyAddBankAccountHelpText
              partnerCompany={partnerCompany}
              partnershipCurrency={getPartnershipCurrency(currencyCodeMap, partnership)}
            />
          )}
          {!isInternational && (
            <LockedCountryInput
              className="margin-top--m-large"
              name={commonFormFields.PARTNER_COUNTRY}
              reason={`If you need to modify your country, please reach out to ${partnerCompany.name}`}
            />
          )}
          <ManuallyAddBankAccountBankDetails
            availableCurrencies={availableCurrencies}
            fields={fields?.bank}
            includeMarginTop={showSingularCurrencyHelpText && isInternational}
            isInternational={isInternational}
          />
          {showCompanyInternationalFields && (
            <div className="margin-top--m-large">
              <PartnerCompanyInternationalTaxForm
                companyType={company.companyType}
                countryCode={partnership.countryCodeCompany}
                formName={formNamesFunding.ADD_MANUAL_BANK}
              />
            </div>
          )}

          {isInternational && <ManuallyAddBankAccountAddressDetails partnership={partnership} />}

          {!!fields?.beneficiary.length && (
            <div className="flex flex-col mt-7">
              <Text.Bold color={TextColor.BLUE_DARK} size={TextSize.LEVEL_475}>
                Additional information required
              </Text.Bold>
              <Text.Regular>
                To receive {selectedCurrency.name} ({selectedCurrency.code}), additional information is required.{' '}
                Everything must match the receiving bank&apos;s information.
              </Text.Regular>

              {fields.beneficiaryByName.country_of_nationality && (
                <NationalityField className="mt-5" defaultValue={partnership.countryCodeCompany} form={form} />
              )}

              {fields.beneficiaryByName.in_fcra && (
                <NonGovermentOrganizationFields
                  className="mt-5"
                  form={form}
                  label="My business is an NGO (Non-Government Organization) beneficiary"
                />
              )}
            </div>
          )}
        </Form>

        {shouldDisplayErrorHint(errors, fields?.all) && <ManuallyAddBankAccountErrorHint />}
      </ContentBlockShade>

      <ModalFooter>
        <ButtonV2
          htmlFor={form}
          id="external_internationalFundingAccount_submit_btn"
          intent={Intent.EXTERNAL}
          isDisabled={!isFormValid || isFetchingFields}
          isLoading={submitting}
          leftIconClassName="margin-right--xm"
          leftIconName={IconNames.TICK_CIRCLE}
          rightIconClassName="margin-left--auto"
          rightIconName={IconNames.ARROW_RIGHT}
          size={ButtonSize.LARGE}
          type="submit"
        >
          Add bank account
        </ButtonV2>
      </ModalFooter>
    </SideBarPanel>
  );
};

export default ManuallyAddBankAccountSideSheet;
