import React from 'react';

import { SETTINGS_ACCOUNT_PAYMENT_METHODS_ROUTE } from 'constants/routes';

import LinkNewPage from '../LinkNewPage';

/**
 * Component rendering Payment methods link
 */
const PaymentMethodsLink = ({ className }: { className?: string }): JSX.Element => (
  <LinkNewPage className={className} href={SETTINGS_ACCOUNT_PAYMENT_METHODS_ROUTE}>
    Payment methods
  </LinkNewPage>
);

export default PaymentMethodsLink;
