import { createBaseLogger } from 'milton/logging';

import { areDevtoolsEnabled } from 'helpers/env';

import { createConsoleTransport } from './consoleTransport';
import { RoutableLogMessage, LogLevel } from './systemLogger.types';
import { withLogLevelFilter } from './withLogLevelFilter';

const transports = [
  withLogLevelFilter(createConsoleTransport(), areDevtoolsEnabled() ? LogLevel.TRACE : LogLevel.INFO),
];

export const systemLogger = createBaseLogger<RoutableLogMessage>({
  transports,
});
