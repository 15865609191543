import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';

import { ButtonSize } from 'constants/button';
import { sizes } from 'constants/styles';
import { TooltipPadding } from 'constants/tooltip';
import { Intent } from 'constants/ui';

import { or, ternary } from 'helpers/utility';

// Circular dependencies https://warrenpay.atlassian.net/browse/ARCH-181
// eslint-disable-next-line import/no-cycle
import ConditionalRestrictedPermissionTooltip from 'modules/auth/ConditionalRestrictedPermissionTooltip';
import { text } from 'modules/dashboard/settings/account/balance/constants';

import AutomatedBalanceTooltipText from '../AutomatedBalanceTooltipText';

/**
 * Buttons to show when the balance amount is positive, or at least NOT negative (zero or greater).
 *
 * @param {ComponentProps} props
 * @param {boolean} [props.allowDeposit=true]
 * @param {boolean} [props.allowWithdraw=true]
 * @param {boolean} [props.isDisabled]
 * @param {function} [props.onDeposit]
 * @param {function} [props.onWithdraw]
 * @returns {StatelessComponent}
 */
const PositiveBalanceAmountButtons = (props) => {
  const { allowDeposit, allowWithdraw, hasExpressPayments, isDisabled, onDeposit, onWithdraw } = props;

  const tooltipProps = ternary(
    hasExpressPayments,
    {
      arrow: true,
      padding: TooltipPadding.LARGE,
      title: <AutomatedBalanceTooltipText />,
    },
    undefined,
  );

  return (
    <>
      <ConditionalRestrictedPermissionTooltip useTooltip={isDisabled}>
        <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
          <ButtonV2
            className="margin-right--m"
            id="depositBalanceBtn"
            isDisabled={or(!allowDeposit, isDisabled, hasExpressPayments)}
            leftIconClassName="margin-right--m"
            leftIconName={IconNames.PLUS}
            leftIconSize={sizes.iconSizes.MEDIUM}
            onClick={onDeposit}
            size={ButtonSize.MEDIUM}
          >
            {text.balanceAmountBlock.depositButton}
          </ButtonV2>
        </TooltipMUIConditionalWrapper>
      </ConditionalRestrictedPermissionTooltip>

      <ConditionalRestrictedPermissionTooltip useTooltip={isDisabled}>
        <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
          <ButtonV2
            id="withdrawBalanceBtn"
            intent={Intent.NEUTRAL}
            isDisabled={or(!allowWithdraw, isDisabled, hasExpressPayments)}
            leftIconClassName="margin-right--m"
            leftIconName={IconNames.MINUS}
            leftIconSize={sizes.iconSizes.MEDIUM}
            onClick={onWithdraw}
            size={ButtonSize.MEDIUM}
          >
            {text.balanceAmountBlock.withdrawButton}
          </ButtonV2>
        </TooltipMUIConditionalWrapper>
      </ConditionalRestrictedPermissionTooltip>
    </>
  );
};

PositiveBalanceAmountButtons.propTypes = {
  allowDeposit: PropTypes.bool,
  allowWithdraw: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onDeposit: PropTypes.func,
  onWithdraw: PropTypes.func,
};

PositiveBalanceAmountButtons.defaultProps = {
  allowDeposit: true,
  allowWithdraw: true,
  isDisabled: undefined,
  onDeposit: undefined,
  onWithdraw: undefined,
};

export default PositiveBalanceAmountButtons;
