import React from 'react';
import { Route } from 'react-router-dom';

import { AllowAnyProps } from './AllowAny.types';

const AllowAny: React.FC<AllowAnyProps> = ({ component, location, ...rest }) => (
  <Route {...rest} render={(props) => React.createElement(component, { ...rest, ...props, location })} />
);

AllowAny.defaultProps = {
  location: undefined,
};

export default AllowAny;
