import classNames from 'classnames';
import React from 'react';

import { ButtonV2, IconNames } from 'components';

import { ButtonAppearance } from 'constants/button';
import { colors, sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import { useClipboard } from 'hooks';

import TableCellTextWithSubtext from '../TableCellTextWithSubtext';

import { SaveToClipboardCellProps } from './SaveToClipboardCell.types';

/**
 * Render simple text cell with copy icon
 * @param {SaveToClipboardCellProps} props
 * @returns {StatelessComponent}
 */
const SaveToClipboardCell: React.FC<SaveToClipboardCellProps> = ({ className, rowData, dataFullStory, dataKey }) => {
  const text = rowData[dataKey];

  const ref = useClipboard({ copyValue: text as string });

  return (
    <>
      <div className="left-indicator width--90-percent" data-fullstory={dataFullStory}>
        <TableCellTextWithSubtext
          className={classNames({
            [className]: !!className,
            empty: !text,
          })}
          text={text}
        />
      </div>

      <div className="right-indicator">
        <ButtonV2
          appearance={ButtonAppearance.BORDERLESS}
          innerRef={ref as React.Ref<HTMLButtonElement>}
          intent={Intent.NEUTRAL}
          isIconButton
          leftIconColor={colors.colorGreyDarkHex}
          leftIconName={IconNames.DUPLICATE}
          leftIconSize={sizes.iconSizes.LARGE}
        />
      </div>
    </>
  );
};

export default SaveToClipboardCell;
