import React from 'react';
import { Link } from 'react-router-dom';

import { BoxV2 } from 'components';

import { RESET_PASSWORD } from 'constants/routes';

import { asPath } from 'helpers/routeHelpers';

import ChangeWorkspace from '../ChangeWorkspace';
import EmailPasswordFormContainer from '../EmailPasswordForm';

/**
 * NoSSOLoginForm
 * Used when the company don't have sso.
 */
const NoSSOLoginForm: React.FC = () => (
  <BoxV2 className="box-v2--container--login" subtitle={<ChangeWorkspace />} title="Log in to your workspace">
    <EmailPasswordFormContainer />
    <div className="margin-top--large">
      <Link className="font-sm semibold font-color--steel" to={asPath(RESET_PASSWORD)}>
        Forgot your password?
      </Link>
    </div>
  </BoxV2>
);

export default NoSSOLoginForm;
