import React from 'react';

import { Text } from 'components';

import { typography } from 'constants/styles';

import { ManuallyAddBankAccountField } from 'modules/connectBank/ManuallyAddBankAccountSideSheet/components';

import AccountCurrencyCodeField from './components/AccountCurrencyCodeField';
import type { InternationalPartnerFundingAccountBankDetailsProps } from './InternationalPartnerFundingAccountBankDetails.types';

/**
 * Component rendering the Bank Details section in the Add International
 * Partner Funding Account form
 * @see {AddInternationalPartnerFundingAccountForm}
 */
const InternationalPartnerFundingAccountBankDetails = ({
  country,
  fields,
  availableCurrencies,
  partnership,
}: InternationalPartnerFundingAccountBankDetailsProps) => (
  <div className="international-partner-funding-account__bank-details">
    <Text.Semibold color={typography.TextColor.GREY_XX_DARK} weight={typography.TextWeight.LEVEL_600}>
      Bank account details
    </Text.Semibold>
    <div>
      <Text.Regular color={typography.TextColor.GREY_XX_DARK}>
        {`This bank account must be located in ${country}.`}
      </Text.Regular>
    </div>
    <AccountCurrencyCodeField availableCurrencies={availableCurrencies} partnership={partnership} />
    {fields?.map((fieldInfo) => (
      <ManuallyAddBankAccountField fieldInfo={fieldInfo} isInternational key={fieldInfo.field} />
    ))}
  </div>
);

export default InternationalPartnerFundingAccountBankDetails;
