import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, change, resetSection } from 'redux-form';

import { ReduxFormFieldRenderInput } from 'components';
import { FieldEditButton } from 'components/form/FieldEditWrapper/components';

import { createRegExpValidator, requiredValidator } from 'helpers/fieldValidation';

import { generatePartnerGovernmentIdSelector } from 'selectors/forms';

import { GovernmentIdFieldSetProps } from './GovernmentIdFieldSet.types';
import { getGovernmentIdOptions } from './helpers';

const fieldNames = {
  governmentIdType: 'partner.governmentId.type',
  governmentIdValue: 'partner.governmentId.value',
};

const GovernmentIdFieldSet = ({
  className,
  companyType,
  countryCode,
  formName,
  savedGovernmentId,
}: GovernmentIdFieldSetProps): JSX.Element => {
  const { type: savedType, value: savedValue } = savedGovernmentId || {};
  const hasSavedValue = savedType && savedValue;

  const [isEditing, setIsEditing] = React.useState(false);

  const dispatch = useDispatch();
  const selector = generatePartnerGovernmentIdSelector(formName);

  const governmentId = useSelector(selector);

  const govermentOptions = getGovernmentIdOptions({
    companyType,
    countryCode,
  });

  const isEmpty = govermentOptions?.length === 0;

  const selectedGovernmentId = govermentOptions.find(({ code }) => code === governmentId?.type);

  const governmentIdValidators = React.useMemo(
    () => [requiredValidator, createRegExpValidator(selectedGovernmentId?.regex, selectedGovernmentId?.errorMessage)],
    [selectedGovernmentId],
  );

  React.useEffect(() => {
    /**
     * When options is re-calculated, set the proper value for governmentIdType,
     * or clean governmentIdType and governmentIdValue when options are empty
     */
    if (isEmpty) {
      dispatch(resetSection(formName, fieldNames.governmentIdType, fieldNames.governmentIdValue));
    } else {
      const type = hasSavedValue ? savedType : govermentOptions[0]?.code;
      const value = hasSavedValue ? savedValue : null;
      dispatch(change(formName, fieldNames.governmentIdType, type));
      dispatch(change(formName, fieldNames.governmentIdValue, value));
    }
  }, [dispatch, formName, govermentOptions, hasSavedValue, isEmpty, savedType, savedValue]);

  const onEdit = () => {
    if (!isEditing) {
      setIsEditing(true);
      dispatch(resetSection(formName, fieldNames.governmentIdValue));
    }
  };

  if (isEmpty) {
    return null;
  }

  const isEditProtected = hasSavedValue && !isEditing;

  return (
    <div className={className}>
      <Field
        component={ReduxFormFieldRenderInput}
        editAllowed
        EditButton={FieldEditButton}
        editField
        isEditProtected={isEditProtected}
        isRequired
        name={fieldNames.governmentIdValue}
        onEdit={onEdit}
        placeholder={selectedGovernmentId?.placeholder}
        validate={governmentIdValidators}
      />
    </div>
  );
};

export default GovernmentIdFieldSet;
