import React from 'react';

import { FormFieldLabel, FormFieldDescription } from 'components/form';
import { LinkNewPage } from 'components/link';
import { HorizontalRadios } from 'components/radio';
import { WhiteSpace } from 'components/text';

import { SWIFT_CHARGE_OPTIONS_MESSAGES, SWIFT_CHARGE_OPTIONS_TOOLTIPS } from 'constants/paymentMethods';

import { SwiftChargeOptions } from 'enums/paymentMethods';

import helpDocs from 'helpers/helpDocs';

import useSwiftChargeOptionsCost from 'hooks/useSwiftChargeOptionsCost';

import type { SwiftFeeChargeToggleProps } from './SwiftFeeChargeToggle.types';

/**
 * Component rendering swift fee charge toggle. Meant to be used
 * inside a Redux Form container.
 */
const SwiftFeeChargeToggle: React.VFC<SwiftFeeChargeToggleProps> = ({ name }) => {
  const { our: ourCost, sha: shaCost } = useSwiftChargeOptionsCost();

  return (
    <>
      <FormFieldLabel className="remove-margin-bottom">How will the SWIFT fee be paid?</FormFieldLabel>
      <FormFieldDescription className="enable-pointer-events">
        Your choice could change the amount that your vendor receives.
        <WhiteSpace />
        <LinkNewPage className="primary" href={helpDocs.UNDERSTANDING_ROUTABLE_FEES}>
          Learn more about SWIFT fees ↗
        </LinkNewPage>
      </FormFieldDescription>
      <HorizontalRadios
        leftOptions={{
          id: `swift-fee-charge-toggle-${SwiftChargeOptions.OUR}`,
          tooltipProps: {
            title: SWIFT_CHARGE_OPTIONS_TOOLTIPS.our(ourCost),
          },
          optionText: SWIFT_CHARGE_OPTIONS_MESSAGES.our,
          value: SwiftChargeOptions.OUR,
        }}
        name={name}
        rightOptions={{
          id: `swift-fee-charge-toggle-${SwiftChargeOptions.SHA}`,
          tooltipProps: {
            title: SWIFT_CHARGE_OPTIONS_TOOLTIPS.sha(shaCost),
          },
          optionText: SWIFT_CHARGE_OPTIONS_MESSAGES.sha,
          value: SwiftChargeOptions.SHA,
        }}
      />
    </>
  );
};

export default SwiftFeeChargeToggle;
