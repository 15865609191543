import { deleteBillAttachmentRoutine } from 'actions/routines/itemAttachments';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [deleteBillAttachmentRoutine.FAILURE, deleteBillAttachmentRoutine.SUCCESS];

export const requestCases = [deleteBillAttachmentRoutine.REQUEST];

const isDeletingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isDeletingReducer;
