import React from 'react';

import Spinner from 'components/buttonV2/components/Spinner';
import Icon, { IconNames } from 'components/icon';

import { colors } from 'constants/styles';

export const availabilityStatuses = {
  AVAILABLE: 'available',
  LOADING: 'loading',
  UNAVAILABLE: 'unavailable',
};

export const contentPerStatus = {
  [availabilityStatuses.AVAILABLE]: {
    icon: <Icon color={colors.colorMainGreenHex} icon={IconNames.TICK} />,
    message: 'Workspace name is available',
  },
  [availabilityStatuses.LOADING]: {
    icon: <Spinner useBlueVariant />,
    message: 'Checking availability...',
  },
  [availabilityStatuses.UNAVAILABLE]: {
    icon: <Icon color={colors.colorYellowBoldHex} icon={IconNames.WARNING_SIGN} />,
    message: 'Workspace name is already in use. Please provide a new one.',
  },
};
