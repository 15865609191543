import { companySettingsSSOByNamespaceFetchRoutine, companySettingsSSOFetchRoutine } from 'actions/routines/sso';

import { SSOActionType } from 'enums/sso';

import { createByIdReducer } from 'store/redux';

const byIdReducer = createByIdReducer({
  key: 'companySettingsSso',
  types: [companySettingsSSOFetchRoutine.SUCCESS, companySettingsSSOByNamespaceFetchRoutine.SUCCESS],
  relationships: ['company'],
  replaceState: true,
});

const byIdReducerMiddleware = (state = [], action = null): unknown => {
  switch (action.type) {
    case SSOActionType.REMOVE_SSO_SETTINGS:
      return {};

    default:
      return byIdReducer(state, action);
  }
};

export default byIdReducerMiddleware;
