import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApprovalStatusSummary } from '@routable/ear/models';
import _pick from 'lodash/pick';
import { combineReducers } from 'redux';

export interface EarItemSummaryState {
  [id: string]: {
    canApprove: boolean;
    canApproveAndSend: boolean;
  };
}

export interface ApprovalSummaryState {
  itemSummary: EarItemSummaryState;
}

const earItemSummarySlice = createSlice({
  initialState: null,
  name: 'itemSummary',
  reducers: {
    setApprovalItemSummary(state, action: PayloadAction<ApprovalStatusSummary[]>): EarItemSummaryState {
      return Object.fromEntries(
        action.payload.map((as) => [as.objectId, _pick(as, 'canApprove', 'canApproveAndSend')]),
      );
    },
  },
});

export const {
  name,
  actions: { setApprovalItemSummary },
  reducer,
} = earItemSummarySlice;

const approvalSummaryReducer = combineReducers({
  itemSummary: reducer,
});

export default approvalSummaryReducer;
