/**
 * Types of Items
 */
export enum ItemKinds {
  PAYABLE = 'payable',
  RECEIVABLE = 'receivable',
}

/**
 * Types of item amount keys
 * @enum {string}
 */
export enum ItemAmountKey {
  COMPANY = 'amountCompany',
  DUE = 'amountDue',
  DUE_COMPANY = 'amountDueCompany',
  DUE_PARTNER = 'amountDuePartner',
  PAID = 'amountPaid',
  PARTNER = 'amountPartner',
  TOTAL = 'amount',
}

/**
 * TYpes of item currency code keys
 * @enum {string}
 */
export enum ItemCurrencyCodeKey {
  GENERAL = 'currencyCode',
  COMPANY = 'currencyCodeCompany',
  PARTNER = 'currencyCodePartner',
  RECEIVER = 'currencyCodeReceiver',
}

/**
 * All possible item ledger statuses
 * @enum {string}
 */
export enum ItemLedgerStatuses {
  CANCELED = 'canceled',
  DELETED = 'deleted',
  DRAFT = 'draft',
  ERROR = 'error',
  NEEDS_APPROVAL = 'needs_approval',
  NEEDS_IMPORT = 'needs_import',
  NO_LEDGER = 'no_ledger',
  PAID = 'paid',
  PENDING = 'pending',
}

/**
 * Represents the possibilities when creating an item and selecting payment terms.
 */
export enum ItemPaymentTerms {
  NET30 = 'net30',
  NET60 = 'net60',
  UPON_RECEIPT = 'upon_receipt',
  SEE_INVOICE = 'see_invoice',
}

/**
 * Statuses sorted by flow
 * @enum {string}
 */
export enum ItemStatuses {
  BULK_UPLOAD = 'bulk',
  BULK_UPLOAD_ACTION_CONTINUE = 'action_continue',
  BULK_UPLOAD_ACTION_REQUIRED = 'action_required',
  BULK_UPLOAD_COMPLETE = 'complete',
  BULK_UPLOAD_ERROR = 'error',
  BULK_UPLOAD_STARTING = 'starting',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  COMPLIANCE_HOLD = 'compliance_hold',
  CREATED = 'created',
  DRAFT = 'draft',
  EXTERNALLY_PAID = 'externally_paid',
  FAILED = 'failed',
  IMPORT = 'import',
  INITIATED = 'initiated',
  INVISIBLE = 'invisible',
  ISSUE = 'issue',
  NEEDS_APPROVAL = 'needs_approval',
  NEW = 'new',
  OCR = 'ocr',
  PENDING = 'pending',
  PO_DISCREPANCY = 'po_discrepancy_hold',
  PROCESSING = 'processing',
  READY_TO_SEND = 'ready_to_send',
  SCHEDULED = 'scheduled',
  QUEUED = 'queued',
}

export enum AllowedActions {
  Accept = 'accept',
  AddItemMember = 'add_item_member',
  Approve = 'approve',
  Cancel = 'cancel',
  Edit = 'edit',
  ExternallyPaid = 'externally_paid',
  FinalizeStatus = 'finalize_status',
  Remove = 'remove',
  ScheduleSend = 'schedule_send',
  UnApprove = 'un_approve',
}

export enum ItemTimelineDisplayStatus {
  CREATED = 'Added to Inbox',
  NEEDS_APPROVAL = 'Due by',
}

export enum ItemSource {
  NETSUITE = 'netsuite',
  PLATFORM = 'platform',
  QBO = 'qbo',
  UNKNOWN = 'unknown',
  XERO = 'xero',
}
