import * as FullStory from '@fullstory/browser';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { TrackEventName, TrackPageName } from './constants';
import { IdentityData, PageEventCallerInfo, TrackEventData } from './eventTracking.types';

const analytics = AnalyticsBrowser.load({
  writeKey: process.env.ROUTABLE_FE_SEGMENT_WRITE_KEY,
});

const { REACT_APP_FULLSTORY_ORG_ID } = process.env;

/**
 * Identifies a user by their membership id and adds their identity traits
 * @param membershipId
 * @param identityData
 */
export function identifyUserByMembershipId(membershipId: string, identityData: IdentityData): void {
  analytics.identify(membershipId, identityData);
  if (REACT_APP_FULLSTORY_ORG_ID) {
    FullStory.identify(membershipId, identityData);
  }
}

let latestPageNameSent: PageEventCallerInfo | null = null;

export function trackPage(name: TrackPageName, additionalName?: string): void {
  const pageName = additionalName ? `${name} - ${additionalName}` : (name as string);
  if (latestPageNameSent?.name === pageName && new Date().getTime() - latestPageNameSent?.calledAt < 750) {
    return;
  }

  analytics.track(`Page - ${pageName}`);

  latestPageNameSent = {
    name: pageName,
    calledAt: new Date().getTime(),
  };
}

export function trackEvent(name: TrackEventName, properties?: TrackEventData): void {
  analytics.track(name, properties);
}
