import classNames from 'classnames';
import React from 'react';

import { isFundingAccountEligibleForRTP } from 'helpers/funding';

import { useToggle } from 'hooks';

import { PaymentMethodBase, PaymentMethodDetails, PaymentMethodHeader, PaymentMethodStatus } from '..';
import { PaymentMethodVariant } from '../../enums';
import { getPaymentMethodHeader, getPaymentMethodIcon, getPaymentMethodStatus } from '../../helpers';

import { PaymentMethodManageActions } from './components';
import type { PaymentMethodManageProps } from './PaymentMethodManage.types';

/**
 * Renders payment method's funding account information and allow actions like update and deletion.
 */
const PaymentMethodManage: React.FC<PaymentMethodManageProps> = ({
  className,
  creator,
  disableManageActions,
  fundingAccount,
  fundingInfoAddress,
  fundingInfoBankAccount,
  fundingInfoInternational,
  fundingSources,
  input,
  isExpanded: isExpandedOverride,
  isSelectable,
  isSubmitting,
  isPartnerAccountInDashboard,
  onDelete,
  onPrimary,
  onUpdate,
  partnership,
  partnershipFundingAccount,
  showFundingAccountId,
}) => {
  const [isExpanded, toggleExpanded] = useToggle(isExpandedOverride);
  const { subtitle, title } = getPaymentMethodHeader({
    fundingAccount,
    fundingInfoAddress,
    fundingInfoBankAccount,
  });

  const status = getPaymentMethodStatus({
    fundingAccount,
    fundingSources,
    isPartnerAccountInDashboard,
    partnershipFundingAccount,
  });

  const isRTPEnabled = isFundingAccountEligibleForRTP(fundingAccount);

  return (
    <PaymentMethodBase className={className} input={input} variant={PaymentMethodVariant.MANAGE}>
      <PaymentMethodHeader
        currencyCode={fundingAccount.currencyCode}
        fundingAccountId={fundingAccount.id}
        fundingIcon={getPaymentMethodIcon(fundingAccount)}
        input={input}
        isExpanded={isExpanded}
        isRTPEnabled={isRTPEnabled}
        isSelectable={isSelectable}
        onToggleExpand={toggleExpanded}
        status={status}
        statusEl={
          <div className="margin-left--xm">
            <PaymentMethodStatus currencyCode={fundingAccount.currencyCode} status={status} />
          </div>
        }
        subtitle={subtitle}
        title={title}
      />
      <div
        className={classNames('payment_method__expandable', {
          expanded: isExpanded,
        })}
      >
        <PaymentMethodDetails
          creator={creator}
          fundingAccount={fundingAccount}
          fundingInfoAddress={fundingInfoAddress}
          fundingInfoBankAccount={fundingInfoBankAccount}
          fundingInfoInternational={fundingInfoInternational}
          isPartnerAccountInDashboard={isPartnerAccountInDashboard}
          partnership={partnership}
          partnershipFundingAccount={partnershipFundingAccount}
          showFundingAccountId={showFundingAccountId}
        />
        {!disableManageActions && (
          <PaymentMethodManageActions
            currencyCode={fundingAccount.currencyCode}
            isSubmitting={isSubmitting}
            onDelete={onDelete}
            onPrimary={onPrimary}
            onUpdate={onUpdate}
            status={status}
          />
        )}
      </div>
    </PaymentMethodBase>
  );
};

export default PaymentMethodManage;
