import { RoutableEventsSync, RoutableObject } from '@routable/framework';
import { useAddToast } from '@routable/gross-ds';
import { formNamesItem } from '@routable/shared';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';

import { LINE_ITEMS_DRAWER_TABS_EVENT_NAME, LineItemStyles } from 'constants/lineItems';

import { getDefaultLineItem, getLineItemsPathFromStyle } from 'helpers/lineItems';
import { convertToUpperCase } from 'helpers/stringHelpers';

import { lineItemsSectionsSelector } from 'modules/dashboard/createItems/createItemForm/components/CreateItemForm/lineItemsSectionsSelector';
import {
  LINE_ITEMS_BLOCKS,
  LINE_ITEMS_SUBSECTIONS,
} from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/constants';
import {
  isLineItemSimilarToDefault,
  transformLineItemToOtherSectionLineItem,
} from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/summarizeAndTransfer.helpers';
import type { LineItemSections } from 'modules/dashboard/createItems/invoiceGenerator/components/InvoiceGeneratorView/summarizeAndTransfer.types';

import { createItemFormAccountLineItemsSelector, createItemFormItemLineItemsSelector } from 'selectors/forms';

type TransferLineItemSection = {
  activeTabIsItem?: boolean;
  viewModelManager: RoutableObject;
  onSwap?: (activeTabIsItem: boolean) => void;
};

export const useTransferLineItems = ({ activeTabIsItem, viewModelManager }: TransferLineItemSection) => {
  const dispatch = useDispatch();
  const addToast = useAddToast();
  const [destinationSectionDisplayName, setDestinationSectionDisplayName] = useState('');
  const { lineItemsItemSection, lineItemsAccountSection } = useSelector(lineItemsSectionsSelector);
  const lineItemsItemDisplayName = lineItemsItemSection?.display;
  const lineItemsAccountDisplayName = lineItemsAccountSection?.display;

  const accountLineItems = useSelector(createItemFormAccountLineItemsSelector);
  const itemLineItems = useSelector(createItemFormItemLineItemsSelector);
  const activeLineItems = activeTabIsItem ? itemLineItems : accountLineItems;
  const currentSectionDefaultLineItem = getDefaultLineItem(
    viewModelManager,
    getLineItemsPathFromStyle(activeTabIsItem ? LineItemStyles.ITEM : LineItemStyles.ACCOUNT),
  );
  const hasTransferrableLineItems = activeLineItems.some(
    (lineItem) => !isLineItemSimilarToDefault(lineItem, currentSectionDefaultLineItem),
  );

  const transferLineItems = (lineItems: Partial<LineItemSections>) => {
    const lineItemStyle = activeTabIsItem ? LineItemStyles.ITEM : LineItemStyles.ACCOUNT;
    const otherSectionStyle = activeTabIsItem ? LineItemStyles.ACCOUNT : LineItemStyles.ITEM;

    const sectionPath = getLineItemsPathFromStyle(lineItemStyle);
    const otherSectionPath = getLineItemsPathFromStyle(otherSectionStyle);
    const defaultLineItem = getDefaultLineItem(viewModelManager, sectionPath);
    const otherSectionDefaultItem = getDefaultLineItem(viewModelManager, otherSectionPath);

    const currentSectionLineItems =
      lineItems[LINE_ITEMS_BLOCKS[convertToUpperCase(lineItemStyle)]][
        LINE_ITEMS_SUBSECTIONS[convertToUpperCase(lineItemStyle)]
      ];
    const otherSectionLineItems =
      lineItems[LINE_ITEMS_BLOCKS[convertToUpperCase(otherSectionStyle)]][
        LINE_ITEMS_SUBSECTIONS[convertToUpperCase(otherSectionStyle)]
      ];

    const transformedLineItems = transformLineItemToOtherSectionLineItem({
      lineItemStyle,
      lineItems: currentSectionLineItems,
      viewModelManager,
    });

    dispatch(change(formNamesItem.CREATE_ITEM, sectionPath, [defaultLineItem]));
    // if other section is empty -> update section with transformed items
    if (
      otherSectionLineItems.length === 1 &&
      isLineItemSimilarToDefault(otherSectionLineItems[0], otherSectionDefaultItem)
    ) {
      dispatch(change(formNamesItem.CREATE_ITEM, getLineItemsPathFromStyle(otherSectionStyle), transformedLineItems));
    } else {
      // if other section is not empty -> append transformed items
      dispatch(
        change(formNamesItem.CREATE_ITEM, getLineItemsPathFromStyle(otherSectionStyle), [
          ...otherSectionLineItems,
          ...transformedLineItems,
        ]),
      );
    }

    RoutableEventsSync.Publish('line:items:transferred', { transferedTo: !activeTabIsItem ? 'ITEM' : 'ACCOUNT' });
    addToast({
      id: 'transfer-line-items-success',
      message: 'Line items successfully transferred',
      type: 'success',
    });

    const nextTab = activeTabIsItem ? LineItemStyles.ACCOUNT : LineItemStyles.ITEM;
    RoutableEventsSync.Publish(`tabs:${LINE_ITEMS_DRAWER_TABS_EVENT_NAME}:update`, nextTab);
  };

  useEffect(() => {
    setDestinationSectionDisplayName(!activeTabIsItem ? lineItemsItemDisplayName : lineItemsAccountDisplayName);
  }, [activeTabIsItem, lineItemsAccountDisplayName, lineItemsItemDisplayName]);

  const transferText = `Move all line items to ${destinationSectionDisplayName}`;

  return {
    hasTransferrableLineItems,
    transferLineItems,
    transferText,
  };
};
