import { AxiosRequestHeaders } from 'axios';

import { AnnouncementActionType, AnnouncementTypes } from 'enums/announcement';

import { ANNOUNCEMENT_BASE_ENDPOINT, ANNOUNCEMENT_UPDATE_ENDPOINT } from 'services/api/announcementEndpoints';

import { createApiRoutine } from './createApiRoutine';

/**
 * Defines types and creates actions for all fetch-announcements related side effects.
 * Fetch list of announcements that the user has not seen
 */
export const announcementFetchRoutine = createApiRoutine(AnnouncementActionType.FETCH_ANNOUNCEMENTS, {
  endpoint: ANNOUNCEMENT_BASE_ENDPOINT,
  method: 'GET',
  requireAuth: true,
  headers: {} as AxiosRequestHeaders,
});

/**
 * Defines types and creates actions for all update-announcement related side effects.
 * Update given announcement as seen by the user
 */
export const announcementUpdateRoutine = createApiRoutine(
  AnnouncementActionType.UPDATE_ANNOUNCEMENT,
  {
    endpoint: ANNOUNCEMENT_UPDATE_ENDPOINT,
    method: 'POST',
    requireAuth: true,
    headers: {} as AxiosRequestHeaders,
  },
  {
    trigger: (announcement: AnnouncementTypes) => ({
      payload: {
        data: {
          attributes: {
            feature_announcement: announcement,
          },
          type: 'UserFeatureAnnouncement',
        },
      },
    }),
  },
);
