import styled from 'styled-components';

import { containerWidthMap } from 'modules/signup-v3/constants/container';

export const Container = styled.div`
  margin-top: 1.5rem;
  max-width: ${containerWidthMap.small};
`;

export const LabelLink = styled.a`
  color: var(--blue--bold);
  font-weight: 400;
`;
