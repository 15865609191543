import React from 'react';

import { Text } from 'components/text';

import { DateFormats } from 'constants/date';

import { formatDateString } from 'helpers/date';

import { PaymentMethodFundingAccountAddedOnDateProps } from './PaymentMethodFundingAccountAddedOnDate.types';

/**
 * Component rendering Funding Account added on date tooltip content
 *
 * @param {PaymentMethodFundingAccountAddedOnDateProps} props
 */
const PaymentMethodFundingAccountAddedOnDate: React.FC<PaymentMethodFundingAccountAddedOnDateProps> = ({
  createdDate,
}) => {
  // If we don't have created date, we don't want to return anything here
  if (!createdDate) {
    return null;
  }

  // We return formated date string if we have date available
  return (
    <Text.Regular>{`Added on ${formatDateString(createdDate, DateFormats.SHORT_ALPHA_MONTH_FULL_YEAR)}`}</Text.Regular>
  );
};

export default PaymentMethodFundingAccountAddedOnDate;
