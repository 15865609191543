import React from 'react';

import { Title, Content, Container, List, ListItem, Contents } from './Answer.styles';
import { Answer as AnswerType } from './Answer.type';

const Answer: React.VFC<AnswerType> = ({ title, content, list, contents }) => (
  <Container>
    <Title>{title}</Title>
    <Content>{content}</Content>
    {list && (
      <List>
        {list.map((item, index) => {
          const key = `list-item_${index}`;
          return <ListItem key={key}>{item}</ListItem>;
        })}
      </List>
    )}
    {contents?.length && (
      <Contents>
        {contents.map((subContent, index) => {
          const key = `subcontent_${index}`;
          return <Answer key={key} {...subContent} />;
        })}
      </Contents>
    )}
  </Container>
);

export default Answer;
