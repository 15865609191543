import classNames from 'classnames';
import React from 'react';

import { TableCellEmptyTextProps } from './TableCellEmptyText.types';

const TableCellEmptyText: React.FC<TableCellEmptyTextProps> = ({ children, className }) => (
  <div
    className={classNames({
      'font-color--greyXDark': true,
      'font-size--xs': true,
      [className]: !!className,
    })}
    data-fullstory
  >
    {children}
  </div>
);

export default TableCellEmptyText;
