import { combineReducers } from 'redux';

import { paymentRoutine, paymentCancelRoutine } from 'actions/payment';
import { PaymentTrigger } from 'actions/payment/payment.types';
import { paymentsItemsRoutine, paymentsRoutine } from 'actions/payments';

import {
  generateByIdReducer,
  generateIdReducer,
  generateLinksReducer,
  generatePaginationReducer,
  generateStatusReducer,
} from 'helpers/jsonapi';

import { ItemById, PaymentById, PaymentsState } from 'interfaces/redux/paymentsReducer.types';

import { paymentsEndpoint } from 'services/api/paymentsEndpoint';

import { FETCH_ITEM_SUCCESS } from 'types/item';

const initialLinks = {
  current: paymentsEndpoint,
};

export default combineReducers<PaymentsState>({
  items: combineReducers({
    apiStatus: generateStatusReducer(paymentsItemsRoutine, 'Failed to retrieve payments'),
    byId: generateByIdReducer<ItemById>([paymentsItemsRoutine.SUCCESS], ['item']),
    ids: generateIdReducer<ItemById>(paymentsItemsRoutine.SUCCESS, 'item'),
    links: generateLinksReducer(paymentsItemsRoutine),
    pagination: generatePaginationReducer(paymentsItemsRoutine),
  }),
  payments: combineReducers({
    apiStatus: generateStatusReducer(paymentsRoutine, 'Failed to retrieve payments'),
    byId: generateByIdReducer<PaymentById>(
      [FETCH_ITEM_SUCCESS, paymentsRoutine.SUCCESS, paymentRoutine.SUCCESS],
      ['payment'],
    ),
    ids: generateIdReducer<PaymentById>(paymentsRoutine.SUCCESS, 'payment'),
    links: generateLinksReducer(paymentsRoutine, initialLinks),
    pagination: generatePaginationReducer(paymentsRoutine),
  }),
  payment: combineReducers({
    apiStatus: generateStatusReducer(paymentRoutine, 'Failed to retrieve active payment id'),
    activePaymentId: (state: string | null = null, action: ReturnType<PaymentTrigger>) => {
      switch (action.type) {
        case paymentRoutine.TRIGGER:
          return action.payload.id;
        default:
          return state;
      }
    },
  }),
  cancel: combineReducers({
    apiStatus: generateStatusReducer(paymentCancelRoutine, 'Failed to cancel payment'),
  }),
});
