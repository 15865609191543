import { createAction } from '@reduxjs/toolkit';

import type { CompanySettings } from 'interfaces/partnerships';

import {
  UPDATE_CURRENT_COMPANY_SETTINGS_CURRENCY_SUCCESS,
  UPDATE_CURRENT_COMPANY_SETTINGS_COLLECT_VENDOR_TAXES_SUCCESS,
  UPDATE_CURRENT_COMPANY_SETTINGS_REQUIRE_TAX_FORM_BEFORE_PAYMENT_SUCCESS,
  UPDATE_CURRENT_COMPANY_SETTINGS_SUCCESS,
} from 'types/currentCompany';

export type UpdateCurrentCompanySettingsCurrencyPayload = Pick<
  CompanySettings,
  'additionalGlobalCurrencyEur' | 'additionalGlobalCurrencyGbp'
>;
export type UpdateCurrentCompanySettingsCollectVendorTaxesPayload = Pick<CompanySettings, 'collectVendorTaxInfo'>;
export type UpdateCurrentCompanySettingsRequireTaxFormBeforePaymentsPayload = Pick<
  CompanySettings,
  'requireTaxFormBeforePayments'
>;
export type UpdateCurrentCompanySettingsPayload = {
  [K in keyof CompanySettings]?: CompanySettings[K];
};

export const updateCurrentCompanySettingsCurrency = createAction<UpdateCurrentCompanySettingsCurrencyPayload>(
  UPDATE_CURRENT_COMPANY_SETTINGS_CURRENCY_SUCCESS,
);

export const updateCurrentCompanySettingsSuccess = createAction<UpdateCurrentCompanySettingsPayload>(
  UPDATE_CURRENT_COMPANY_SETTINGS_SUCCESS,
);

export const updateCurrentCompanySettingsCollectVendorTaxesSuccess =
  createAction<UpdateCurrentCompanySettingsCollectVendorTaxesPayload>(
    UPDATE_CURRENT_COMPANY_SETTINGS_COLLECT_VENDOR_TAXES_SUCCESS,
  );

export const updateCurrentCompanySettingsRequireTaxFormBeforePayment =
  createAction<UpdateCurrentCompanySettingsRequireTaxFormBeforePaymentsPayload>(
    UPDATE_CURRENT_COMPANY_SETTINGS_REQUIRE_TAX_FORM_BEFORE_PAYMENT_SUCCESS,
  );
