import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { DebouncedSelectField } from 'components/form';

import { formNamesItem } from 'constants/forms';
import * as propTypes from 'constants/propTypes';
import { field } from 'constants/styles/formStyles';

import { noDefaultEvent } from 'helpers/events';
import { getAtomicValueForOption, normalizeFullObject } from 'helpers/select';

import { useMemoizedConditionalValidate } from 'hooks';

import { useDynamicFieldRequiredValidators } from 'modules/table/hooks';

/**
 * Renders a select field to be rendered in a block section
 * @param {ComponentProps} props
 * @param {Object} props.formValues
 * @param {function} props.getIsDisabled
 * @param {string} props.lookupOptionValueKey - Where is the value located on the object
 * @param {string} props.name
 * @param {*[]} props.options
 * @param {string} props.placeholder
 * @param {Function} props.validate
 * @returns {StatelessComponent}
 */
export const LookupBlockField = (props) => {
  const { formValues, getIsDisabled, isClearable, lookupOptionValueKey, label, name, options, placeholder } = props;
  const { isRequired, validators } = useDynamicFieldRequiredValidators(props);
  const cachedValidators = useMemoizedConditionalValidate(!formValues.ui?.blockDetailsSections, validators);

  return (
    <Field
      className={field.xl.full}
      closeOnSelect
      component={DebouncedSelectField}
      format={(value) =>
        getAtomicValueForOption(options, value, {
          valueKey: lookupOptionValueKey,
        })
      }
      formName={formNamesItem.CREATE_ITEM}
      isClearable={isClearable}
      isDebounced
      isDisabled={getIsDisabled(formValues)}
      isRequired={isRequired}
      label={label}
      name={name}
      normalize={normalizeFullObject(options, lookupOptionValueKey)}
      // validations will run immediately onFocus when the dropdown menu isn't open unless we
      // preventDefault on the focus event
      onFocus={noDefaultEvent}
      options={options}
      placeholder={`Select ${placeholder.toLowerCase()}`}
      validate={cachedValidators}
      valueKey={lookupOptionValueKey}
    />
  );
};

LookupBlockField.propTypes = {
  ...propTypes.baseFieldSharedPropTypes,
  formValues: PropTypes.shape().isRequired,
  lookupOptionValueKey: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  placeholder: PropTypes.string.isRequired,
};

LookupBlockField.defaultProps = {
  ...propTypes.baseFieldSharedDefaultProps,
};

export default LookupBlockField;
