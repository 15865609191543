import classNames from 'classnames';
import React from 'react';

import { ButtonV2, IconNames, TooltipMUIConditionalWrapper } from 'components';

import { ButtonSize } from 'constants/button';
import { colors } from 'constants/styles';
import { Intent } from 'constants/ui';

import { TaxInfoRequestButtonDisplayType } from './TaxInfoRequestButton.enums';
import type { TaxInfoRequestButtonProps } from './TaxInfoRequestButton.types';

import './TaxInfoRequestButton.scss';

/**
 * Component rendering the Tax Info Request button. Used in the Company
 * Tax and Docs tab
 * @param props - Component props
 */
const TaxInfoRequestButton = ({
  className,
  displayType = TaxInfoRequestButtonDisplayType.COMPACT,
  isDisabled,
  isLoading,
  onClick,
  tooltipProps,
  label = 'Request from vendor',
}: TaxInfoRequestButtonProps): JSX.Element => {
  // Infer button props from the displayType
  const size = displayType === TaxInfoRequestButtonDisplayType.LARGE ? ButtonSize.MEDIUM : ButtonSize.SMALL;
  const iconProps =
    displayType === TaxInfoRequestButtonDisplayType.COMPACT_WITH_ICON
      ? {
          leftIconClassName: 'margin-right--xm',
          leftIconColor: colors.colorDarkSilverHex,
          leftIconName: IconNames.ENVELOPE,
        }
      : {};

  return (
    <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
      <ButtonV2
        {...iconProps}
        className={classNames('request-tax-info-button', `request-tax-info-button--${displayType}`, className)}
        intent={Intent.NEUTRAL}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={onClick}
        size={size}
      >
        {label}
      </ButtonV2>
    </TooltipMUIConditionalWrapper>
  );
};

export default TaxInfoRequestButton;
