/* eslint-disable camelcase */
export enum googlePlacesAttrs {
  street_number = 'short_name',
  route = 'long_name', // street name
  locality = 'long_name', // city
  administrative_area_level_1 = 'short_name', // state
  country = 'short_name',
  postal_code = 'short_name', // zip code
}

export enum googlePlacesAttrsToAddressFields {
  // streetAddress in form is street_number + route from API response
  street_number = 'streetAddress',
  route = 'streetAddress',
  locality = 'city',
  administrative_area_level_1 = 'state',
  country = 'country',
  postal_code = 'postalcode',
  // address unit is only present in form not in API response but we need
  // to access it to clean it up
  address_unit = 'streetAddressUnit',
}
