import type { TableHeader, TableSetup } from '@routable/components';

const getHeaders = (isStyleItem?: boolean): TableHeader[] =>
  [
    {
      columnId: 'id',
      caption: '',
      isSelectObj: true,
      initialSettings: {
        width: 'ColumnWidth1',
      },
    },
    {
      columnId: 'productName',
      caption: 'Product/Service',
      initialSettings: {
        width: 'ColumnWidth6',
      },
    },
    {
      columnId: 'description',
      caption: 'Description',
      initialSettings: {
        width: 'ColumnWidth6',
      },
    },
    isStyleItem && {
      columnId: 'unit_price',
      caption: 'Rate',
      hAlign: 'right' as const,
      initialSettings: {
        width: 'ColumnWidth4',
      },
    },
    isStyleItem && {
      columnId: 'quantity',
      caption: 'Unbilled qty',
      hAlign: 'center' as const,
      initialSettings: {
        width: 'ColumnWidth3',
      },
    },
    {
      columnId: 'amount',
      caption: 'Unbilled amt',
      hAlign: 'right' as const,
      initialSettings: {
        width: 'ColumnWidth4',
      },
    },
  ].filter(Boolean);

export const getLinkBillLineToPoLineTableConfig = (isStyleItem?: boolean): TableSetup => ({
  tableName: 'linkBillLineToPoLineTable',
  type: 'linkBillLineToPoLine',
  headers: getHeaders(isStyleItem),
});

export const getAddLineFromPoTableConfig = (isStyleItem?: boolean): TableSetup => ({
  tableName: 'addLineFromPoTableTable',
  type: 'addLineFromPo',
  headers: getHeaders(isStyleItem),
});
