import React from 'react';

import { Intent } from 'constants/ui';

import { isItemStatusExternallyPaid } from 'helpers/items';

import BaseHint from '../BaseHint';

import { getSelectPaymentMethodHintText, getSelectPaymentMethodHintTitle } from './helpers/text';
import type { SelectPaymentMethodHintProps } from './SelectPaymentMethodHint.types';

const SelectPaymentMethodHint = ({
  item,
  partnerFundingAccount,
  partnership,
}: SelectPaymentMethodHintProps): JSX.Element => {
  if (partnerFundingAccount || isItemStatusExternallyPaid(item) || !partnership) {
    return null;
  }

  return (
    <BaseHint
      dataFullStoryTitle
      intent={Intent.WARNING}
      multiline
      text={getSelectPaymentMethodHintTitle(item.kind)}
      title={getSelectPaymentMethodHintText(partnership.name)}
    />
  );
};

export default SelectPaymentMethodHint;
