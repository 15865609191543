import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 1rem;
`;

export const Title = styled.h3`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.375rem;
  margin-bottom: 0.125rem;
`;

export const Content = styled.div`
  font-size: 0.875rem;
`;

export const Contents = styled.div`
  display: flex;
  gap: 2rem;
`;

export const List = styled.ul`
  list-style: disc;
  padding-left: 1.5rem;
`;

export const ListItem = styled.li``;
