import {
  FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_FAILURE,
  FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_RESET,
  FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_SUCCESS,
} from 'types/funding';

const dataReducer = (state = {}, action) => {
  switch (action.type) {
    case FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_SUCCESS:
      return {
        ...state,
        [action.fieldName]: {
          data: action.payload,
          errors: null,
        },
      };

    case FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_FAILURE:
      return {
        ...state,
        [action.fieldName]: {
          data: null,
          errors: action.payload,
        },
      };

    case FUNDING_INFO_INTERNATIONAL_BANK_VALIDATOR_RESET:
      return {
        ...state,
        [action.fieldName]: {
          data: null,
          errors: null,
        },
      };

    default:
      return state;
  }
};

export default dataReducer;
