import React from 'react';
import { useSelector } from 'react-redux';

import { TableDataRow, TableHeaderRow } from 'components/table';

import { currentCompanyRepresentativesSelector } from 'selectors/currentCompanySelectors';

import TeamMemberDeactivateCellContainer from '../ViewTeamMembersModal/ActiveMembersTable/TeamMemberDeactivateCell';

import CompanyRepTableName from './CompanyRepTableName';
import CompanyRepTableStatus from './CompanyRepTableStatus';
import { TableWrapper } from './RepresentativesTable.styles';

export const TableData = {
  name: {
    baseClassName: 'table-row--column',
    className: 'table-cell--width-30',
    displayName: 'Name',
    obj: CompanyRepTableName,
  },
  status: {
    baseClassName: 'table-row--column',
    className: 'table-cell--width-15',
    displayName: 'Status',
    obj: CompanyRepTableStatus,
  },
  action: {
    baseClassName: 'table-row--column',
    className: 'table-cell--width-5',
    displayName: ' ',
    obj: TeamMemberDeactivateCellContainer,
  },
};

const RepresentativesTable = (): JSX.Element => {
  const currentCompanyRepresentatives = useSelector(currentCompanyRepresentativesSelector);

  if (!currentCompanyRepresentatives?.length) {
    return null;
  }

  return (
    <TableWrapper>
      <ul className="platform-table--inline">
        <TableHeaderRow data={TableData} />

        <div>
          {currentCompanyRepresentatives.map((membership) => (
            <TableDataRow
              data={TableData}
              key={membership.id}
              membership={membership}
              rowClassName="table-row--container disable-hover"
              {...membership}
            />
          ))}
        </div>
      </ul>
    </TableWrapper>
  );
};

export default RepresentativesTable;
