import React from 'react';
import { Field } from 'redux-form';

import { InternationalPhoneInput } from 'components';

import * as propTypes from 'constants/propTypes';

import { useMemoizedConditionalValidate } from 'hooks';

import { useDynamicFieldRequiredValidators, useTableCellFieldIsDisabled } from 'modules/table/hooks';

/**
 * Renders a text input field to be rendered in a block section
 * @param {ComponentProps} props
 * @param {Object} props.formValues
 * @param {Function} props.getIsDisabled
 * @param {Function} props.getIsRequired
 * @param {Function} props.getRowValues
 * @param {string} props.name
 * @param {number} props.maxLength
 * @param {Function} props.validate
 * @returns {StatelessComponent}
 */
const PhoneTableField = (props) => {
  const { formValues, name } = props;

  const { isRequired, validators } = useDynamicFieldRequiredValidators(props);
  const cachedValidators = useMemoizedConditionalValidate(!formValues.ui?.blockDetailsSections, validators);
  const isDisabled = useTableCellFieldIsDisabled(props);

  return (
    <Field
      className="ledger-form-field"
      component={InternationalPhoneInput}
      debounceDelay={200}
      isDebounced
      isDisabled={isDisabled}
      isRequired={isRequired}
      name={name}
      validate={cachedValidators}
    />
  );
};

PhoneTableField.propTypes = propTypes.tableFieldSharedPropTypes;

PhoneTableField.defaultProps = propTypes.tableFieldSharedPropTypes;

export default PhoneTableField;
