import React from 'react';

import { IconNames, TooltipMUIConditionalWrapper } from 'components';

import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import ProgressBar from '../../ProgressBar';
import { defaultStatuses, stepStatuses } from '../constants';
import { stepHasShownSubsteps, completedSubsteps } from '../helpers';
import { ProgressBarStepProps } from '../ProgressBarStepperV2.types';
import ProgressBarSubstep from '../ProgressBarSubstep';

import { Item, Label, Status, ChevronIcon, Details, StatusLabel, SubstepsContainer } from './ProgressBarStep.styles';

const ProgressBarStep: React.VFC<ProgressBarStepProps> = ({ step, onClickStep, customStatus }) => {
  const statusConfig = {
    ...defaultStatuses,
    ...customStatus,
  };

  const hasShownSubsteps = stepHasShownSubsteps({ step });
  const completedSubstepsValue = completedSubsteps({ step });
  // we are already starting with the bar filled with 4 percent
  const percentage = completedSubstepsValue === 0 ? 4 : (100 / step.substeps?.length) * completedSubstepsValue;
  const [open, setOpen] = React.useState(step.isActive);
  React.useEffect(() => {
    setOpen(step.isActive);
  }, [step.isActive]);
  const isWaiting = step.status === stepStatuses.waiting;
  const isWaitingColor = isWaiting && step.isActive && statusConfig[stepStatuses.waiting].activeStatusColor;
  const isActiveColor = step.isActive && statusConfig[stepStatuses.active].color;

  const currentColor = statusConfig[step.status].color;
  const currentStatusColor = statusConfig[step.status].statusColor;

  const labelColor = isActiveColor || currentColor;
  const statusColor = isWaitingColor || isActiveColor || currentStatusColor;
  const chevronColor = isActiveColor || currentStatusColor;

  const tooltipProps = step.tooltip?.[step.status] && {
    arrow: true,
    padding: TooltipPadding.LARGE,
    placement: TooltipPlacement.TOP,
    title: step.tooltip?.[step.status],
    style: {
      fontSize: '0.75rem',
    },
  };

  return (
    <Item>
      <Details open={open}>
        <Label
          color={labelColor}
          disabled={step.status === stepStatuses.disabled}
          onClick={(e) => {
            e.preventDefault();
            onClickStep(0, step.id);
            setOpen(step.isActive);
          }}
        >
          <StatusLabel disabled={step.status === stepStatuses.disabled}>{step.label}</StatusLabel>

          <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
            <Status className="step-status" color={statusColor} hasSubsteps={hasShownSubsteps}>
              {!step?.hideStatus && statusConfig[step.status].label}
              {hasShownSubsteps && <ChevronIcon color={chevronColor} icon={IconNames.CHEVRON_RIGHT} />}
            </Status>
          </TooltipMUIConditionalWrapper>
        </Label>
        {hasShownSubsteps && <ProgressBar percentage={percentage} />}
        {hasShownSubsteps && (
          <SubstepsContainer>
            {step.substeps.map((substep) => (
              <ProgressBarSubstep key={`substep-${substep.id}`} onClickStep={onClickStep} step={substep} />
            ))}
          </SubstepsContainer>
        )}
      </Details>
    </Item>
  );
};

export default ProgressBarStep;
