import { AnyAction, Dispatch } from 'redux';

const filter = {};

// does not rerun if the filter is already entered into the system
export const generateThrottleMiddleware =
  (defaultTimeout = 100) =>
  () =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction): AnyAction => {
    if (!action?.meta?.throttle) {
      return next(action);
    }
    const id: string = (action?.meta?.throttle?.id ?? action.type) as string;
    if (id && filter[id]) {
      // return if the filter is already set for this action to skip
      return null;
    }
    const throttleTimeout = action?.meta?.throttle.timeout ?? defaultTimeout;
    // set a timeout to clear the filter
    filter[id] = setTimeout(() => {
      filter[id] = undefined;
    }, throttleTimeout);
    return next(action);
  };
