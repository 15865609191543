import { useQuery } from '@tanstack/react-query';
import { queryContext } from 'milton/components';

import { purchaseOrderService } from './purchaseOrder.service';

export const usePurchaseOrder = (id?: string) =>
  useQuery({
    queryKey: ['purchaseOrders', 'by-id', id],
    queryFn: () => purchaseOrderService.get({ id }),
    enabled: !!id,
    context: queryContext,
  });
