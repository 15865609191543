import React from 'react';

import { Col, Row } from 'components/layout';

import { ContentBlockFooterProps } from './ContentBlockFooter.types';

/**
 * Footer layout component for the ContentBlock.
 */
const ContentBlockFooter = ({ children, colProps, rowProps }: ContentBlockFooterProps): JSX.Element => (
  <Col className="content-block--footer" {...colProps}>
    <Row justifyContent="center" {...rowProps}>
      {children}
    </Row>
  </Col>
);

export default ContentBlockFooter;
