import { combineReducers } from 'redux';

import allCountryCodesReducer from './allCountryCodesReducer';
import byCountryCodeReducer from './byCountryCodeReducer';
import errorsReducer from './errorsReducer';
import isFetchingReducer from './isFetchingReducer';
import lastFetchedReducer from './lastFetchedReducer';

const fundingSupportedCountriesReducer = combineReducers({
  allCountryCodes: allCountryCodesReducer,
  byCountryCode: byCountryCodeReducer,
  errors: errorsReducer,
  isFetching: isFetchingReducer,
  lastFetched: lastFetchedReducer,
});

export default fundingSupportedCountriesReducer;
