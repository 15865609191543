import React from 'react';

import { LinkNewPage } from 'components/link';

import { CONTACT_SUPPORT_LOWERCASE_LABEL } from 'constants/labels';
import { PLATFORM_EMAILS } from 'constants/platform';
import { DASHBOARD } from 'constants/routes';

import { ItemKinds } from 'enums/items';

import { getPaymentOrInvoiceOrInformalText } from 'helpers/items';
import { getJoinedPath } from 'helpers/routeHelpers';
import { getMailtoUrl } from 'helpers/urls';

export type Props = { itemKind: ItemKinds; ledgerName?: string };
/**
 * Default error message that displays in the ServerAlert swal for timed out requests while creating an item.
 */
const ServerErrorDefaultItemTimeoutMessage = ({ itemKind, ledgerName }: Props): JSX.Element => {
  const noun = getPaymentOrInvoiceOrInformalText({ kind: itemKind });

  return (
    <span className="alert-error-text">
      {`Do not try to resubmit or recreate this ${noun} at this time. `}

      {ledgerName ? (
        <>
          {`If you do not see this ${noun} in your `}
          <LinkNewPage className="font-color--primary" href={getJoinedPath(DASHBOARD)}>
            dashboard
          </LinkNewPage>
          {` in the next few minutes, check your ${ledgerName} account to see if it was created.`}
        </>
      ) : (
        <>
          {`Wait a few minutes before checking your `}
          <LinkNewPage className="font-color--primary" href={getJoinedPath(DASHBOARD)}>
            dashboard
          </LinkNewPage>
          {` to see if this ${noun} was created. If you do not see it, you may try again to create it.`}
        </>
      )}
      {` If you have any questions, please `}
      <LinkNewPage className="font-color--primary" href={getMailtoUrl(PLATFORM_EMAILS.SUPPORT)}>
        {CONTACT_SUPPORT_LOWERCASE_LABEL}
      </LinkNewPage>
      {` and we'll be happy to help.`}
    </span>
  );
};

export default ServerErrorDefaultItemTimeoutMessage;
