import React from 'react';

import { text } from 'components/hintTypes/constants';
import { WhiteSpace } from 'components/text';
import TextLinkNewInternalPage from 'components/TextLinkNewInternalPage/TextLinkNewInternalPage';

const { emailAlreadyInvitedHint: hintText } = text;

export const EmailAlreadyInvitedHintText = ({ onNavigate }: { onNavigate: React.MouseEventHandler }): JSX.Element => (
  <>
    {hintText.textBeforeLink}
    <WhiteSpace />
    <TextLinkNewInternalPage onClick={onNavigate}>{hintText.linkText}</TextLinkNewInternalPage>
    {hintText.textAfterLink}
  </>
);
