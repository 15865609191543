import { submitTransactionPaymentConfirmationRoutine } from 'actions/routines/transactions';

import { firstKey } from 'helpers/utility';

import { RoutableApiResponse } from 'interfaces/routableApiResponse';

import { routableApi, RoutableTags } from './routableApi';

/**
 * Method to build the onSuccess payload to mimic the addition of a transactionPaymentConfirmation for the transaction object update.
 * @param transactionId
 * @param data
 */
export const buildOnSuccessPayload = (transactionId: string, data: RoutableApiResponse): RoutableApiResponse => {
  const transactionPaymentConfirmationId = firstKey(data.transactionPaymentConfirmation);
  return {
    ...data,
    transaction: {
      [transactionId]: {
        id: transactionId,
        relationships: {
          transactionPaymentConfirmation: {
            data: {
              id: transactionPaymentConfirmationId,
              type: 'TransactionPaymentConfirmation',
            },
          },
        },
      },
    },
  };
};

const extendedTransactionApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    submitTransactionPaymentConfirmation: builder.mutation<RoutableApiResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/transactions/${id}/payment_confirmation/`,
        method: 'POST',
      }),
      invalidatesTags: [RoutableTags.Transactions],
      async onCacheEntryAdded(args, api) {
        // this code used to loop back and trigger the existing code
        const payload = await api.cacheDataLoaded;
        api.dispatch(submitTransactionPaymentConfirmationRoutine.success(buildOnSuccessPayload(args.id, payload.data)));
      },
    }),
  }),
});

export const { useSubmitTransactionPaymentConfirmationMutation } = extendedTransactionApi;
