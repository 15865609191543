import classNames from 'classnames';
import React from 'react';
import ReactModal from 'react-modal';

import { ZIndexLayers } from 'constants/ui';

import { getZIndex } from 'helpers/zIndex';

import { DashboardNotificationBar } from 'modules/dashboard/components/Dashboard/components';

import EscButton from '../escButton/EscButton';

import ModalBody from './components/body/ModalBody';
import ModalHeader from './components/header/ModalHeader';
import type { FullPageModalProps } from './FullPageModal.types';

import './Modal.scss';

// Set the app element that React Modal expects for tests
ReactModal.setAppElement('body');

/**
 * FullPageModal
 */
const FullPageModal = React.forwardRef<HTMLDivElement, FullPageModalProps>(
  (
    {
      body,
      contentLabel = 'Modal',
      hasInlineFooter = false,
      header,
      modalClassname,
      modalState,
      onCloseModal,
      onRequestClose = onCloseModal,
      overlayClassName,
      overrideStyle = {},
      showCloseButton = true,
      showHeader = true,
      testId,
    },
    ref,
  ): JSX.Element => (
    <ReactModal
      className={{
        afterOpen: classNames('FullPageModal__Content--after-open', {
          'FullPageModal__Content--no-header': !showHeader,
          [modalClassname]: !!modalClassname,
        }),
        base: 'FullPageModal__Content',
        beforeClose: 'FullPageModal__Content--before-close',
      }}
      closeTimeoutMS={300}
      contentLabel={contentLabel}
      isOpen={modalState}
      onRequestClose={onRequestClose}
      overlayClassName={overlayClassName}
      ref={ref}
      style={{
        overlay: {
          zIndex: getZIndex(ZIndexLayers.MODAL.OVERLAY),
        },
        ...overrideStyle,
      }}
      {...(testId ? { data: { testid: testId } } : {})}
    >
      <DashboardNotificationBar />

      <ModalHeader show={showHeader}>
        {header}
        {onCloseModal && showCloseButton && <EscButton onClick={onCloseModal} />}
      </ModalHeader>

      <ModalBody hasInlineFooter={hasInlineFooter}>{body}</ModalBody>
    </ReactModal>
  ),
);

FullPageModal.displayName = 'FullPageModal';

export default FullPageModal;
