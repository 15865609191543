import { StatusIndicator } from '@routable/gross-ds';
import React from 'react';

import { getItemStatusProps } from 'helpers/status';

type Props = {
  item: Item;
};

const ItemStatusBox = ({ item }: Props) => {
  const { statusVariant, statusText } = getItemStatusProps(item);

  return (
    <div className="inline-flex items-center py-1 px-2 border border-grey-20 rounded bg-white">
      <StatusIndicator className="text-xs" status={statusVariant}>
        {statusText}
      </StatusIndicator>
    </div>
  );
};

export default ItemStatusBox;
