import classNames from 'classnames';
import React from 'react';

import { Text, FlexRow } from 'components';

import { paginationDataTestId } from 'constants/dataTestId';

import { PageNavLastPageButton, PageNavNextButton, PageNavPreviousButton } from './components';
import { PageNavProps } from './PageNav.types';
import './PageNav.scss';

/**
 * Renders the pagination used by tables.
 */
export const PageNav = React.forwardRef<HTMLDivElement, PageNavProps>(
  ({ canNextPage, canPreviousPage, className, onGoToPage, pageCount, pageIndex }, ref) => (
    <FlexRow className={classNames('tlp--page-nav', className)} ref={ref} stackOnMobile={false}>
      <div className="tlp--page-nav-wrapper">
        <div className="page-navigation-block">
          <PageNavPreviousButton
            canPreviousPage={canPreviousPage}
            dataTestId={paginationDataTestId.paginationComponent.PAGE_NAV_PREVIOUS}
            onPreviousPage={(ev) => onGoToPage('PREV', ev)}
          />
        </div>
        <Text.Semibold
          className="tlp-page-nav--display current-page page-navigation-block user-select--none"
          data-testid={paginationDataTestId.paginationComponent.PAGE_INDEX}
        >
          {pageIndex}
        </Text.Semibold>
        <Text.Semibold className="tlp-page-nav--display page-navigation-block user-select--none">/</Text.Semibold>
        <div className="page-navigation-block">
          <PageNavLastPageButton
            dataTestId={paginationDataTestId.paginationComponent.PAGE_NAV_LAST_PAGE}
            onGoToPage={onGoToPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
          />
        </div>
        <div className="page-navigation-block">
          <PageNavNextButton
            canNextPage={canNextPage}
            dataTestId={paginationDataTestId.paginationComponent.PAGE_NAV_NEXT}
            onNextPage={(ev) => onGoToPage('NEXT', ev)}
          />
        </div>
      </div>
    </FlexRow>
  ),
);

PageNav.displayName = 'PageNav';
