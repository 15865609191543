import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { externalOnboardingIncrementStep } from 'actions/externalOnboarding';

import { ButtonV2 } from 'components';

import { ButtonSize } from 'constants/button';
import { ExternalButtonTypeValues, ExternalButtonTypes, Intent } from 'constants/ui';

import { noDefaultEvent } from 'helpers/events';

import { currentExternalButtonIsSubmittingSelector } from 'queries/currentWorkflowStepSelector';

import type { ExternalButtonProps } from './ExternalButton.types';

/**
 * ExternalButton applies some common props to ButtonV2.
 */
const ExternalButton = ({ disabled, htmlFor, iconLeft, iconRight, text, type }: ExternalButtonProps) => {
  const isSubmitting = useSelector(currentExternalButtonIsSubmittingSelector);
  const dispatch = useDispatch();

  if (!text) {
    return null;
  }

  const onClick = (ev) => {
    if (type === ExternalButtonTypes.NEXT) {
      noDefaultEvent(ev); // Stop form from submitting
      dispatch(externalOnboardingIncrementStep());
    }
  };

  return (
    <ButtonV2
      className="external-button"
      htmlFor={htmlFor}
      intent={Intent.EXTERNAL}
      isDisabled={disabled}
      isLoading={isSubmitting}
      leftIconClassName="mr-3"
      leftIconName={iconLeft}
      leftIconSize={18}
      onClick={onClick}
      rightIconClassName="ml-3"
      rightIconName={iconRight}
      rightIconSize={18}
      size={ButtonSize.LARGE}
      type={ExternalButtonTypeValues[type]}
    >
      <span>{text}</span>
    </ButtonV2>
  );
};

export default ExternalButton;
