import { isObject } from 'helpers/utility';

import { SSOSetupFormState } from 'interfaces/sso';

import { BASE_API_URL } from './index';

export const membershipInviteEndpoint = (companyId: string, inviteId: string, token: string): string =>
  `${BASE_API_URL}/companies/${companyId}/invites/${inviteId}/?token=${token}`;

export const resendInviteEndpoint = (companyId: string, membershipId: string): string =>
  `${BASE_API_URL}/companies/${companyId}/invites/${membershipId}/resend/`;

export const revokeInviteEndpoint = (companyId: string, membershipId: string): string =>
  `${BASE_API_URL}/companies/${companyId}/invites/${membershipId}/delete/`;

export const acceptMembershipInviteEndpoint = (companyId: string, inviteId: string, token: string): string =>
  `${BASE_API_URL}/companies/${companyId}/invites/${inviteId}/accept/?token=${token}`;

export const acceptMembershipInviteSSOEndpoint = (companyId: string, inviteId: string, token: string): string =>
  `${BASE_API_URL}/companies/${companyId}/invites/${inviteId}/sso_accept/?token=${token}`;

/**
 * Returns accept membership invite endpoint based on sso settings
 */
export const getAcceptMembershipInviteEndpoint = ({
  companyId,
  companySSOSettings,
  data,
  inviteId,
  token,
}: {
  companyId: string;
  companySSOSettings?: SSOSetupFormState;
  data: { password?: string };
  inviteId: string;
  token: string;
}): string =>
  isObject(companySSOSettings) && !data.password
    ? acceptMembershipInviteSSOEndpoint(companyId, inviteId, token)
    : acceptMembershipInviteEndpoint(companyId, inviteId, token);

export const membershipInvitesEndpoint = (companyId: string): string =>
  `${BASE_API_URL}/companies/${companyId}/invites/`;
