import React from 'react';

import { Col, Row } from 'components/layout';

import { ContentBlockHeaderProps } from './ContentBlockHeader.types';

/**
 * Header layout component for the ContentBlock.
 */
const ContentBlockHeader = ({ children }: ContentBlockHeaderProps): JSX.Element => (
  <Col className="content-block--header">
    <Row justifyContent="space-between">{children}</Row>
  </Col>
);

export default ContentBlockHeader;
