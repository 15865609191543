import { connect } from 'react-redux';

import { toggleViewTeamMembersModal } from 'actions/signUpFlow';

import { EmailAlreadyInvitedError } from './EmailAlreadyInvitedError';

const mapDispatchToProps = {
  onNavigate: toggleViewTeamMembersModal,
};

export default connect(null, mapDispatchToProps)(EmailAlreadyInvitedError);
