import styled from 'styled-components';

export const ErrorIndicatorContainer = styled.p`
  color: var(--color-grey70);
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-2);
  margin: 0;
`;
