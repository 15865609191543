import pluralize from 'pluralize';
import React from 'react';

import DropdownMenuButton from 'complexComponents/DropdownMenuButton';

import { IconNames, WhiteSpace } from 'components';

import { sizes } from 'constants/styles';
import { Intent } from 'constants/ui';

import { formatNumber } from 'helpers/numbers';
import { isEqual } from 'helpers/utility';

import { getTableToolbarSelectionManagerOptions } from '../../helpers';

import { TableToolbarSelectionManagerOption } from './components';
import type { TableToolbarSelectionManagerProps } from './TableToolbarSelectionManager.types';

function getTransformedResourceType(resourceType: string) {
  switch (resourceType) {
    case 'inbox':
      return 'bill';
    default:
      return resourceType;
  }
}

/**
 * Renders a dropdown menu button whose options are bulk actions that can be taken.
 * @type {React.FC<TableToolbarSelectionManagerProps>}
 */
const TableToolbarSelectionManager: React.FC<TableToolbarSelectionManagerProps> = (props) => {
  const { resourceType, selectableRowsCount, selectedCount, ...rest } = props;

  const resourceDisplay = pluralize(getTransformedResourceType(resourceType), selectedCount);
  const options = getTableToolbarSelectionManagerOptions(props);
  const areAllSelected = isEqual(selectableRowsCount, selectedCount);

  return (
    <div className="selection-manager-wrapper">
      <DropdownMenuButton
        className="selection-manager"
        intent={Intent.NEUTRAL}
        leftIconClassName="margin-right--xm"
        leftIconName={IconNames.MULTI_SELECT}
        leftIconSize={sizes.iconSizes.LARGE}
        menuAlign="left"
        options={options}
        {...rest}
      >
        <span className="manager--content">
          <span className="bold">
            {areAllSelected && (
              <>
                All
                <WhiteSpace />
              </>
            )}
            {formatNumber(selectedCount)}
          </span>
          <WhiteSpace />
          <span className="regular">{`${resourceDisplay} selected`}</span>
        </span>
      </DropdownMenuButton>
    </div>
  );
};

TableToolbarSelectionManager.defaultProps = {
  isDisabled: undefined,
  onClearSelection: undefined,
  onSelectAll: undefined,
  OptionComponent: TableToolbarSelectionManagerOption,
  selectedCount: 0,
};

export default TableToolbarSelectionManager;
