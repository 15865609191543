import dayjs from 'dayjs';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useTaxFormOptions } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.context';
import {
  W8FormModel,
  taxCertificationType,
  taxFormEntryType,
} from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';

import { PenaltiesOfPerjuryHint } from 'components';

import { FormCertification, TaxDocType } from 'constants/taxes';

import { isCompanyTypePersonal } from 'helpers/currentCompany';

import RenderDatePicker from '../RenderDatePicker';
import RenderFileUpload from '../RenderFileUpload';
import RenderRadioGroup from '../RenderRadioGroup';
import RenderTextInput from '../RenderTextInput';
import { Group, GroupSlot } from '../styles';

import {
  getTaxFormName,
  isEligibleForCertificationTypeSelect,
  translateFormTypeToTaxDoc,
} from './FieldGroupFormCertification.helpers';
import {
  Divider,
  InstructionsTextPdfUpload,
  InstructionsTextSignatureWarning,
} from './FieldGroupFormCertification.styles';

const FieldGroupFormCertification = (): JSX.Element => {
  const { isSignatureAllowed, entryType, formType } = useTaxFormOptions();
  const { watch } = useFormContext<W8FormModel>();

  const selectedCertificationType = watch('certificationType');
  const companyType = watch('companyType');

  const isIndividual = isCompanyTypePersonal(companyType);
  const taxFormName = getTaxFormName(formType, isIndividual);
  const taxDoc = translateFormTypeToTaxDoc(formType);
  const shouldRenderCertificationType = isEligibleForCertificationTypeSelect(
    formType,
    isIndividual,
    isSignatureAllowed,
  );

  const certificationOptions = [
    {
      optionText: 'Sign electronically',
      value: taxCertificationType.Enum.signedElectronically,
    },
    {
      optionText: `Upload signed ${taxFormName} PDF`,
      value: taxCertificationType.Enum.uploadSignedPDF,
    },
  ];

  // We want to restrict the completed form signed date as follows:
  // - max: today
  // - min: today - 120yrs
  const completedFormMaxSignedDate = new Date(Date.now());
  const completedFormMinSignedDate = dayjs().subtract(120, 'year').toDate();

  return (
    <>
      {shouldRenderCertificationType && <RenderRadioGroup name="certificationType" options={certificationOptions} />}
      <Divider />
      {selectedCertificationType === taxCertificationType.Enum.signedElectronically && (
        <Group>
          <GroupSlot size={6}>
            <PenaltiesOfPerjuryHint taxDoc={taxDoc} />
          </GroupSlot>
          <GroupSlot size={6}>
            <RenderTextInput isRequired name="signature" placeholder="Signature" shouldUnregister />
            <InstructionsTextSignatureWarning>
              {FormCertification.SIGNATURE_WARNING_TEXT}
            </InstructionsTextSignatureWarning>
          </GroupSlot>
        </Group>
      )}
      {selectedCertificationType === taxCertificationType.Enum.uploadSignedPDF && (
        <Group>
          <GroupSlot size={6}>
            <InstructionsTextPdfUpload>
              {`Upload ${
                entryType === taxFormEntryType.Enum.external ? 'your' : "the vendor's"
              } completed and signed ${taxFormName}. We can only accept an original IRS form in PDF format.`}
            </InstructionsTextPdfUpload>
          </GroupSlot>
          <GroupSlot size={6}>
            <RenderFileUpload name="completedForm" shouldUnregister />
          </GroupSlot>
          <GroupSlot size={3}>
            <RenderDatePicker
              isRequired
              maxDate={completedFormMaxSignedDate}
              minDate={completedFormMinSignedDate}
              name="completedFormSignedDate"
              placeholder="Date uploaded form was signed"
              shouldUnregister
            />
          </GroupSlot>
        </Group>
      )}
    </>
  );
};

export default FieldGroupFormCertification;
