import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { partnerCompanyFromPartnershipQuerySelector } from 'queries/companyCompoundSelectors';

import ManuallyAddBankAccountErrorHint from './ManuallyAddBankAccountErrorHint';

export const mapStateToProps = createStructuredSelector({
  company: partnerCompanyFromPartnershipQuerySelector,
});

export default connect(mapStateToProps)(ManuallyAddBankAccountErrorHint);
