import { TruncatableText } from '@routable/components';
import classNames from 'classnames';
import React from 'react';
import { useMedia } from 'react-use';

import { ButtonV2, Col, FullPageModal, Modal, ModalBodyContent, ModalFooter, Row, SearchBar, Text } from 'components';

import { ButtonSize } from 'constants/button';
import { mobileMediaQuery } from 'constants/mediaQuery';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent, ZIndexLayers } from 'constants/ui';

import { ternary } from 'helpers/utility';
import { getZIndex } from 'helpers/zIndex';

import { QuickswitchActionCreator } from 'types/quickswitch';

import './QuickswitchModal.scss';
import { generateModalStyleOverride } from './helpers';
import QuickswitchModalContent, {
  QuickswitchModalContentDispatchProps,
  QuickswitchModalContentStateProps,
} from './QuickswitchModalContent';

export interface ModalStyleOverride {
  maxHeight: string;
  height?: string;
  width: string;
}

export interface QuickswitchModalStateProps extends QuickswitchModalContentStateProps {
  currentCompanyName: string;
  isModalOpen: boolean;
  searchTerm: string;
}

export interface QuickswitchModalDispatchProps extends QuickswitchModalContentDispatchProps {
  setSearchTerm: (searchTerm: string) => QuickswitchActionCreator;
  fetchMembershipSearch: (searchTerm: string) => QuickswitchActionCreator;
  closeModal: () => QuickswitchActionCreator;
}

export interface QuickswitchModalOwnProps {
  showSearchBar: boolean;
}

export type QuickswitchModalProps = QuickswitchModalStateProps &
  QuickswitchModalDispatchProps &
  QuickswitchModalOwnProps;

const QuickswitchModal: React.FC<QuickswitchModalProps> = (props) => {
  const {
    searchTerm,
    setSearchTerm,
    loadingMembershipsList,
    memberships,
    currentCompanyName,
    fetchMembershipSearch,
    executeSwitchMembership,
    closeModal,
    isModalOpen,
    showSearchBar,
  } = props;

  const searchBar = showSearchBar && (
    <Row>
      <Col className="quickswitch-modal--search-box-row">
        <SearchBar
          onSearchExecuted={fetchMembershipSearch}
          onTermChange={setSearchTerm}
          placeholderText="Search by company name or URL"
          searchTerm={searchTerm}
        />
      </Col>
    </Row>
  );

  const isMobile = useMedia(mobileMediaQuery);

  const modalStyle = generateModalStyleOverride(showSearchBar);
  const body = (
    <React.Fragment>
      <ModalBodyContent className="quickswitch-modal--content">
        <div className="quickswitch-modal--sticky">
          <Row
            className={classNames('quickswitch-modal--header', {
              'quickswitch-modal--header--extra-bottom-padding': !showSearchBar,
            })}
          >
            <Col>
              <Row className="padding-bottom--m">
                <Col>
                  <Text.ExtraBold color={TextColor.BLACK} size={TextSize.LEVEL_400}>
                    Switch to another Routable account.
                  </Text.ExtraBold>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="flex flex-nowrap items-center overflow-hidden gap-2 w-full">
                    <Text.Semibold
                      className="whitespace-nowrap overflow-hidden"
                      color={TextColor.GREY_XX_DARK}
                      size={TextSize.LEVEL_200}
                    >
                      {`You are currently logged into `}
                    </Text.Semibold>
                    <TruncatableText
                      className="font-sm font-bold text-grey-70 w-[80px] md:w-[135px] lg:w-[400px]"
                      position="top"
                      text={currentCompanyName}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {searchBar}
        </div>
        <Row>
          <Col>
            <QuickswitchModalContent
              executeSwitchMembership={executeSwitchMembership}
              loadingMembershipsList={loadingMembershipsList}
              memberships={memberships}
              onUndoSearchClick={() => setSearchTerm('')}
            />
          </Col>
        </Row>
      </ModalBodyContent>
      <ModalFooter className="quickswitch-modal--footer">
        <div className="modal-footer--button margin-left--auto">
          <ButtonV2 intent={Intent.NEUTRAL} onClick={closeModal} size={ButtonSize.MEDIUM}>
            Cancel
          </ButtonV2>
        </div>
      </ModalFooter>
    </React.Fragment>
  );

  return ternary(
    isMobile,
    <FullPageModal
      body={body}
      hasInlineFooter={false}
      modalClassname="quickswitch-modal"
      modalState={isModalOpen}
      onCloseModal={closeModal}
      overrideStyle={{
        ...modalStyle,
        ...{ overlay: { zIndex: getZIndex(ZIndexLayers.MODAL.OVERLAY, 25) } },
      }}
      showCloseButton={false}
    />,
    <Modal
      body={body}
      hasInlineFooter
      hideHeader
      modalState={isModalOpen}
      onCloseModal={closeModal}
      overrideStyle={modalStyle}
    />,
  );
};

export default QuickswitchModal;
