import { useQuery } from '@tanstack/react-query';
import { queryContext } from 'milton/components';

import { overlaysApi } from './overlays.service';
import { generateKeys } from './queryKeys';

type UseItemOverlaysOptions = {
  enabled?: boolean;
  itemId: string;
};

export const useItemOverlays = ({ enabled = true, itemId }: UseItemOverlaysOptions) => {
  return useQuery({
    enabled,
    context: queryContext,
    queryKey: generateKeys.itemOverlays(itemId),
    queryFn: async () => overlaysApi.getItemOverlays(itemId),
    retry: false,
    staleTime: Infinity,
    select: (response) => response.data,
  });
};
