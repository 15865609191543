import { Action } from 'redux';

import { SET_REQUIREMENTS_ARE_MET, UNSET_REQUIREMENTS_ARE_MET } from 'types/passwordInput';

const areAllRequirementsMetReducer = (state = false, action: Action): boolean => {
  if (action.type === SET_REQUIREMENTS_ARE_MET) {
    return true;
  }

  if (action.type === UNSET_REQUIREMENTS_ARE_MET) {
    return false;
  }

  return state;
};

export default areAllRequirementsMetReducer;
