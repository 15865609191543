import { internationalBankValidatorFailure, internationalBankValidatorSuccess } from 'actions/funding';

import { parseErrorResponse } from 'helpers/errors';
import { allValues, firstValue } from 'helpers/utility';

import { RoutableApiResponse } from 'interfaces/routableApiResponse';

import { payloadToUnderscore } from 'services/api/formatHelpers';

import { routableApi, RoutableTags } from './routableApi';

const extendedFundingApi = routableApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    lookupFundingBankAccount: builder.query<RoutableApiResponse, { fieldName: string; fieldValue: string }>({
      query: ({ fieldName, fieldValue }) => ({
        url: `/data/funding/bank_details_lookup/?${fieldName}=${fieldValue}`,
      }),
      onQueryStarted: async ({ fieldName }, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          const bankDetails = firstValue(allValues(data.bankDetails)).attributes;

          dispatch(
            internationalBankValidatorSuccess({
              bankDetails,
              fieldName,
            }),
          );
        } catch (error) {
          const errorData = parseErrorResponse(error.error?.data);
          // Transform payload back to the format our redux-form fields are structured
          const errorPayload = payloadToUnderscore(errorData);

          dispatch(
            internationalBankValidatorFailure({
              errors: errorPayload,
              fieldName,
            }),
          );
        }
      },
      providesTags: [RoutableTags.Funding],
    }),
  }),
});

export const { useLazyLookupFundingBankAccountQuery } = extendedFundingApi;

export type UseLazyLookupFundingBankAccountQuery = typeof useLazyLookupFundingBankAccountQuery;
