import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { DASHBOARD, SETTINGS_ROUTE } from 'constants/routes';

import { asPath } from 'helpers/routeHelpers';

export const useGoToLastDashboardLocation = (): (() => void) => {
  const history = useHistory();

  // this hook gets returned a few times as this component re-renders
  // it loses track of the state.previous passed when the <Links /> are clicked from DashboardSidebarFooterNavUser
  // or DashboardSidebarFooterNavCompany
  // until we update our router, this is a solution to get the user back to the page they were on when they left
  // /dashboard for /settings, instead of always taking them back to /inbox
  const location = useLocation<{ previous?: string }>();
  const [lastDashboardLocation, setLastDashboardLocation] = React.useState(null);

  const { state: { previous: previousLocation } = {} } = location;

  React.useEffect(() => {
    if (previousLocation && !previousLocation?.includes(SETTINGS_ROUTE)) {
      setLastDashboardLocation(previousLocation);
    }
  }, [previousLocation]);

  return () => {
    if (lastDashboardLocation) {
      history.push(lastDashboardLocation);
      return;
    }

    history.push(asPath(DASHBOARD));
  };
};

export default useGoToLastDashboardLocation;
