import * as types from 'types/passwordInput';

import initialState from '../initialState';
import { Action, PasswordStrengthData } from '../types/passwordInputReducer.types';

const fetchPasswordStrengthCompletedReducer = (
  state: PasswordStrengthData = initialState.passwordInputStrength,
  action: Action,
): PasswordStrengthData => {
  if (action.type === types.FETCH_PASSWORD_STRENGTH_COMPLETED) {
    return action?.payload?.attributes?.strength || initialState.passwordInputStrength;
  }

  return state;
};

export default fetchPasswordStrengthCompletedReducer;
