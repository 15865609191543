import {
  CHECKBOX_COLUMN_ACCESSOR_ID,
  TABLE_HEADER_HEIGHT,
  TABLE_ROW_HEIGHT,
  TABLE_BORDER_HEIGHT,
} from 'constants/tables';

import { isEqual, noop, ternary } from 'helpers/utility';

/**
 * Calculate the height of a table for react-virtualized AutoSizer component.
 */
export const calculateTableHeight = ({
  dataLength,
  tableHeaderHeight = TABLE_HEADER_HEIGHT,
  tableRowHeight = TABLE_ROW_HEIGHT,
}: {
  dataLength: number;
  tableHeaderHeight?: number;
  tableRowHeight?: number;
}): number => dataLength * tableRowHeight + tableHeaderHeight + TABLE_BORDER_HEIGHT;

/**
 * Get original data from row
 */
export const getOriginal = <Type>(props: { row?: { original: Type } }): Type => props?.row?.original;

/**
 * getOnCellClickFunction
 * If table cell is for checkbox -> do nothing on table cell click,
 * otherwise -> navigate to item details
 */
export const getOnCellClickFunction = (
  cell: { column: { id: string }; row: { original: unknown } },
  onRowClick: (row?: unknown) => void,
): unknown =>
  ternary(isEqual(cell?.column?.id, CHECKBOX_COLUMN_ACCESSOR_ID), noop, () => onRowClick(cell.row.original));
