import PropTypes from 'prop-types';
import React from 'react';

import { Text, TooltipConditionalWrapper } from 'components';

import { typography } from 'constants/styles';
import { ZIndexLayers } from 'constants/ui';

import { Position } from 'enums/ui';

import { getClassNames } from 'helpers/ui';
import { ternary } from 'helpers/utility';

import { useOverflow } from 'hooks';

/**
 * Table header cell component for the invoice generator ledger field tables.
 * @param {ComponentProps} props
 * @param {*} props.children
 * @param {RestOfProps} rest
 * @return {StatelessComponent}
 */
const InvoiceGeneratorTableHeaderCell = ({ children, ...rest }) => {
  const { isOverflowing, ref } = useOverflow();

  const tooltipProps = ternary(
    isOverflowing,
    {
      content: (
        <Text.Semibold
          className="line-height--m--important"
          color={typography.TextColor.WHITE}
          size={typography.TextSize.LEVEL_100}
        >
          {children}
        </Text.Semibold>
      ),
      paneProps: { width: '100%' },
      tooltipProps: {
        position: Position.TOP,
        statelessProps: { maxWidth: '100%' },
      },
      zIndex: ZIndexLayers.TOOLTIP,
    },
    {},
  );

  return (
    <div
      {...rest}
      className={getClassNames(rest, {
        'table-row--column': true,
        'remove-padding': true,
      })}
    >
      <div className="column-content padding-left--m padding-right--m">
        <TooltipConditionalWrapper tooltipProps={tooltipProps}>
          <span className="truncate" ref={ref}>
            {children}
          </span>
        </TooltipConditionalWrapper>
      </div>
    </div>
  );
};

InvoiceGeneratorTableHeaderCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

InvoiceGeneratorTableHeaderCell.defaultProps = {
  children: undefined,
  className: 'table-cell--width-8',
};

export default InvoiceGeneratorTableHeaderCell;
