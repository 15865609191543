import React from 'react';

import Avatar from 'components/avatar';
import { FlexRow } from 'components/layout';
import { Text, WhiteSpace } from 'components/text';

import { typography } from 'constants/styles';

import { getCreatorOrCompanyNameText, getTeamRelationText } from './helpers';
import type { PaymentMethodDetailsCreatorFieldProps } from './PaymentMethodDetailsCreatorField.types';

/**
 * Component rendering Payment Method details Creator field. Displays an Avatar and the
 * Creator name.
 *
 * @param {PaymentMethodDetailsCreatorFieldProps} props
 * @returns {StatelessComponent}
 */
const PaymentMethodDetailsCreatorField: React.FC<PaymentMethodDetailsCreatorFieldProps> = ({
  companyName = '',
  creator,
  isYourTeam,
}) => {
  const creatorOrCompanyName = getCreatorOrCompanyNameText({
    companyName,
    creator,
    isYourTeam,
  });
  const teamRelation = getTeamRelationText({
    companyName,
    creator,
    isYourTeam,
  });

  return (
    <FlexRow className="detail-item-creator-field align-items--center" stackOnMobile={false}>
      {!!creator && (
        <Avatar {...creator} sizeClass="avatar--payment-method" styleClass="avatar--dark margin-right--xm" />
      )}

      <Text.Regular className="detail-item-creator-field--creator-name" color={typography.TextColor.BLACK}>
        {creatorOrCompanyName}

        {Boolean(teamRelation) && (
          <>
            <WhiteSpace />
            <Text.Regular className="detail-item-creator-field--team-relation">{`(${teamRelation})`}</Text.Regular>
          </>
        )}
      </Text.Regular>
    </FlexRow>
  );
};

export default PaymentMethodDetailsCreatorField;
