import React from 'react';

import { Hint, Text } from 'components';

import { typography } from 'constants/styles';
import { Intent } from 'constants/ui';

/**
 * Component rendering hint when user is not allowed to update fields in signup flow.
 * @param {CannotUpdateFieldHint} props
 * @return {StatelessComponent}
 */
const CannotUpdateFieldHint: React.FC = () => (
  <Hint className="margin-bottom--large" intent={Intent.WARNING}>
    <Text.Semibold color={typography.TextColor.YELLOW_DARK} size={typography.TextSize.LEVEL_200}>
      You can&apos;t update these fields at this time.
    </Text.Semibold>
  </Hint>
);

export default CannotUpdateFieldHint;
