import type { CurrencyCode } from 'interfaces/currency';
import type { PartnerPaymentOption } from 'interfaces/fundingSupportedCountries';
import type { Nullable } from 'interfaces/global';
import type { PaymentRail } from 'interfaces/payments';

import { GetPartnerInternationalPaymentOptions, GetPartnerPaymentOptions } from './paymentOptions.types';

/**
 * Tranform paymentRail to a Payment Option format
 */
export const paymentRailToPaymentOption = (paymentRail: PaymentRail): PartnerPaymentOption => ({
  method: paymentRail.deliveryMethod,
  options: [paymentRail.deliveryOption],
});

/**
 * Returns true if the paymentRail.currencyCodeReceiver matches the currencyCode
 */
const paymentRailByCurrencyCodeReceiver = (currencyCode: CurrencyCode) => (paymentRail: PaymentRail) =>
  paymentRail.currencyCodeReceiver === currencyCode;

/**
 * Get international payment options based on routesPayable based on currencyCode Receiver
 */
export const getPartnerInternationalPaymentOptions = ({
  billCurrencyCode,
  currencyCodeReceiver,
  routesPayable,
}: GetPartnerInternationalPaymentOptions): Nullable<PartnerPaymentOption[]> => {
  // International payments have only one rail per currency code receiver, so we filter all that are not applicable,
  // by calling paymentRailByCurrencyCodeReceiver
  // And map payment rail into expected format for the Payment Method and Payment Option selectboxes
  const filteredRoutesPayable = (routesPayable?.[billCurrencyCode?.toLowerCase()] || [])
    .filter(paymentRailByCurrencyCodeReceiver(currencyCodeReceiver))
    .map(paymentRailToPaymentOption);

  if (filteredRoutesPayable.length) {
    return filteredRoutesPayable;
  }

  return null;
};

export const getPartnerPaymentOptions = ({
  billCurrency,
  currencyCode,
  currencyCodeReceiver,
  isInternational,
  isInternationalPaymentsEnabled,
  routesPayable,
  selectedCompany,
  supportedCountryCodes,
}: GetPartnerPaymentOptions): PartnerPaymentOption[] => {
  const { countryCode, currencyCodePartner } = selectedCompany || {};
  let currency = billCurrency || currencyCode || currencyCodePartner;

  if (isInternationalPaymentsEnabled && isInternational) {
    const partnerInternationalPaymentOptions = getPartnerInternationalPaymentOptions({
      billCurrencyCode: currency,
      currencyCodeReceiver,
      routesPayable,
    });

    if (partnerInternationalPaymentOptions) {
      return partnerInternationalPaymentOptions;
    }

    // If there are no payment rails available, we use the countryData below as fallback
    // and currencyCodeReceiver should have preference if present
    currency = currencyCodeReceiver || currency;
  }

  const countryData = supportedCountryCodes[countryCode];
  return countryData?.paymentOptions?.[currency?.toLowerCase()];
};
