/**
 * @fileOverview Defines constants related to feature flags.
 * @module constants/featureFlags
 */

// the key under which feature flags are set in local storage
export const LOCAL_STORAGE_KEY_FEATURE_FLAGS = 'featureFlags';

// the name of the "item edit" flag in state
export const FEATURE_FLAG_NAME_ITEM_EDIT = 'itemEdit';

// the name of the "ocr inbox" flag in state
export const FEATURE_FLAG_OCR_INBOX = 'ocrInbox';

// the name of the bulk import for receivables flag in state
export const FEATURE_FLAG_NAME_BULK_RECEIVABLES = 'bulkReceivables';

// the name of the bulk import for partnerships flag in state
export const FEATURE_FLAG_NAME_BULK_PARTNERSHIPS = 'bulkPartnerships';

export const FEATURE_FLAG_NAME_RTP = 'realTimePayments';

export const FEATURE_FLAG_SIDE_NAVIGATION_V2 = 'sideNavigationV2';

export const FEATURE_FLAG_PO_MATCHING_SHOW_DISCREPANCIES = 'poMatchingShowDiscrepancies';

/**
 * We use this flag to determine whether to support approvals for crossborder items or not.
 * When we do not support approvals, we show hints.
 */
export const FEATURE_FLAG_NAME_CROSSBORDER_ITEM_APPROVAL_ENABLED = 'crossborderItemApprovalEnabled';

/**
 * Use this flag to determine whether to display the edit partner general info button and side sheet
 */
export const FEATURE_FLAG_MILTON_TGS = 'whispersDecoupleWhispers';

/**
 * We use this flag to determine whether or not to show the back button in
 * the external flow.
 */
export const FEATURE_FLAG_NAME_GLOBAL_ONLY_EXTERNAL_FLOW_BACK_BUTTON = 'globalOnlyExternalFlowBackButton';

/**
 * We use this flag to set a default filter when listing payables to improve performance.
 */
export const FEATURE_FLAG_PAYABLES_FILTER_DEFAULT = 'payablesFilterDefault';

// the standard prefix for flags used in env and on the backend,
// which we strip out before putting into state or local storage
export const FEATURE_FLAG_NAME_PREFIX = 'FEATURE_FLAG_';

// prefix for development-only feature flags
export const DEV_ONLY_FEATURE_FLAG_NAME_PREFIX = 'DEV_ONLY_';

/**
 * Flag to show/hide enabling international payments in settings or on initial connection to ledger
 */
export const FEATURE_FLAG_CROSSBORDER_SELF_SERVE = 'crossborderSelfServe';

/**
 * Flag to gate the functionalities of new provider integrations, like new fields being
 * collected.
 */
export const FEATURE_FLAG_CROSSBORDER_MULTIPLE_PROVIDERS = 'crossborderMultipleProvidersEnabled';

/**
 * Flag to enable enterprise approval rule configurations.
 */
export const FEATURE_FLAG_ENTERPRISE_APPROVAL_RULES = 'enterpriseApprovalRules';

export const FEATURE_FLAG_ENTERPRISE_APPROVAL_RULES_FORM_ONLY = 'enterpriseApprovalRulesFormOnly';

/**
 * We use this flag to show and hide the custom role pages
 */
export const FEATURE_FLAG_CUSTOM_ROLES_ENABLED = 'customRolesEnabled';

/**
 * Feature flag to allow approve all and approve all and send ready to send.
 */
export const FEATURE_FLAG_CUSTOM_ADESA_BULK_ACTION_ALL = 'customAdesaBulkActionAll';

/**
 * Flag to enable New Vendor Table experience
 */
export const NEW_VENDOR_TABLE = 'vendorsTable';

/**
 * Flag to enable New Tables in item lists.
 */
export const FEATURE_FLAG_ITEM_LIST_OPTIMIZATION = 'itemListOptimization';

/**
 * Flag to enable new inbox table
 */
export const FEATURE_FLAG_INBOX_TABLE = 'inboxTable';
