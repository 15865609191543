import classNames from 'classnames';
import React from 'react';

import { FlexCol } from 'components';

import { ExternalBlocksSectionProps } from './ExternalBlocksSection.types';

import './ExternalBlocksSection.scss';

/**
 * Component rendering External Block Section
 */
const ExternalBlocksSection: React.FC<ExternalBlocksSectionProps> = ({ children, className }) => (
  <FlexCol
    className={classNames('external-blocks-section', {
      [className]: Boolean(className),
    })}
  >
    {children}
  </FlexCol>
);

ExternalBlocksSection.defaultProps = {
  className: undefined,
};

export default ExternalBlocksSection;
