import { Tooltip } from '@routable/components';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import { IconNames } from 'components/icon';

import { colors } from 'constants/styles';
import { Intent } from 'constants/ui';

import { isCurrentCompanyTypePersonal } from 'helpers/currentCompany';
import {
  getAddFundingProviderMemoTooltipText,
  getRemittanceInfoOrAddendaRecordText,
} from 'helpers/fundingProviderMemo';
import { isGreaterThan, lengthOf } from 'helpers/utility';

import { AddFundingProviderMemoButtonProps } from './AddFundingProviderMemoButton.types';

/**
 * Button for opening the FundingProviderMemoModal used in CreateItemForm.
 * @see {CreateItemPaymentMethodSingleVendor} - used here
 * @see {FundingProviderMemoModal} - opens this modal
 */
const AddFundingProviderMemoButton: React.FC<AddFundingProviderMemoButtonProps> = ({
  isDeliveryOptionRTP,
  onOpenFundingProviderMemoModal,
  partnerCompany,
  selectedInvoices,
}) => {
  const areMultipleBillsSelected = isGreaterThan(lengthOf(selectedInvoices), 1);
  const isCompanyTypePersonal = isCurrentCompanyTypePersonal(partnerCompany);

  const tooltipText = getAddFundingProviderMemoTooltipText({
    areMultipleBillsSelected,
    isCompanyTypePersonal,
    isDeliveryOptionRTP,
  });

  const isButtonDisabled = areMultipleBillsSelected || (isCompanyTypePersonal && !isDeliveryOptionRTP);

  return (
    <div className="display--inline-block">
      <Tooltip
        data-testid="funding-provider-memo-button--tooltip"
        onPress={onOpenFundingProviderMemoModal}
        position="top"
        size="medium"
        tooltip={tooltipText}
        variant="light"
      >
        <ButtonV2
          intent={Intent.NEUTRAL}
          isDisabled={isButtonDisabled}
          leftIconClassName="margin-right--xm"
          leftIconColor={colors.colorGreyXDarkHex}
          leftIconName={IconNames.ADD}
          type="button"
        >
          {`Add ${getRemittanceInfoOrAddendaRecordText(isDeliveryOptionRTP)}`}
        </ButtonV2>
      </Tooltip>
    </div>
  );
};

export default AddFundingProviderMemoButton;
