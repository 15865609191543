import classNames from 'classnames';
import React from 'react';

export interface SimpleCardProps extends React.HTMLAttributes<HTMLDivElement> {
  shouldHaveBorder?: boolean;
}

/**
 * Simple card to add rounded corners and other pizazz when wrapping another component.
 */
const SimpleCard = ({ className, shouldHaveBorder = true, ...rest }: SimpleCardProps): JSX.Element => (
  <div
    className={classNames('generic-card', className, {
      'with-border': shouldHaveBorder,
    })}
    {...rest}
  />
);

export default SimpleCard;
