import clsx from 'clsx';
import React from 'react';

import type { TableCellSubtextProps } from './TableCellSubtext.types';

/**
 * Renders subtext for a table cell.
 * @see {TableCellTextWithSubtext}
 */
const TableCellSubtext = ({ children, className, style }: TableCellSubtextProps) => (
  <div className={clsx('font-color--grey--x-dark', 'text-xs', className)} style={style}>
    {children}
  </div>
);

export default TableCellSubtext;
