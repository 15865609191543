import React from 'react';

import { breakpointNames } from 'constants/mediaQuery';

import { getClassNames } from 'helpers/ui';
import { isEqual } from 'helpers/utility';

import DisclosureList from '../DisclosureList';
import { DisclosureListHeadingStyle } from '../enums';

import { HeadingBlockDisclosureListProps } from './HeadingBlockDisclosureList.types';

/**
 * Disclosure list with block for heading.
 * Accepts a headingStyle (e.g. default, thin).
 * @param {ComponentProps} props
 * @return {StatelessComponent}
 * @constructor
 */
const HeadingBlockDisclosureList: React.VFC<HeadingBlockDisclosureListProps> = ({
  bodyRowProps = {},
  className = undefined,
  headerIconName,
  headingStyle = DisclosureListHeadingStyle.DEFAULT,
  headerText,
  items,
  ...rest
}) => (
  <DisclosureList
    {...rest}
    bodyRowProps={{
      breakpointProps: {
        [breakpointNames.tablet]: {
          cols: 1,
        },
      },
      ...bodyRowProps,
    }}
    className={getClassNames(
      { className },
      {
        'heading-block-disclosure': true,
        'thin-heading': isEqual(headingStyle, DisclosureListHeadingStyle.THIN),
      },
    )}
    headerIconName={headerIconName}
    headerText={headerText}
    items={items}
  />
);

export default HeadingBlockDisclosureList;
