import React from 'react';

import PaymentMethodDetailItem from './components/PaymentMethodDetailItem';
import { getItems } from './helpers';
import type { PaymentMethodDetailsProps } from './PaymentMethodDetails.types';

/**
 * Renders list of PaymentMethod details from given funding information
 */
const PaymentMethodDetails: React.FC<PaymentMethodDetailsProps> = (props) => (
  <div className="payment-method-details">
    {getItems(props).map((item, idx) => (
      <PaymentMethodDetailItem key={`payment-method-details-item-${idx + 1}`} {...item} />
    ))}
  </div>
);

export default PaymentMethodDetails;
