import React from 'react';

import Text from 'components/text/Text';

import { typography } from 'constants/styles';
import { TextColor } from 'constants/styles/typography';

import { ContentWithOptionalTextProps } from './ContentWithOptionalText.types';

const ContentWithOptionalText: React.FC<ContentWithOptionalTextProps> = ({ children, optional }) => (
  <React.Fragment>
    {children}
    {optional && (
      <React.Fragment>
        {' '}
        <Text.Bold color={TextColor.GREY_DARK} {...typography.TitleWithSubtitle.textProps}>
          (optional)
        </Text.Bold>
      </React.Fragment>
    )}
  </React.Fragment>
);

export default ContentWithOptionalText;
