import { Dispatch } from 'redux';
import { SubmissionError } from 'redux-form';

import { companySettingsSSOByNamespaceFetchHandler } from 'actions/routines/sso';

import { NamespaceFormValues } from '../NamespaceForm.types';

/**
 * Helper to submit company namespace and receive SSO company settings
 */
const submit = async (payload: NamespaceFormValues, dispatch: Dispatch): Promise<void> => {
  try {
    await companySettingsSSOByNamespaceFetchHandler({ params: { namespace: payload.namespace } }, dispatch);
  } catch (e) {
    if (e?.errors?.detail) {
      const namespaceFormError: unknown = {
        namespace: [e.errors.detail],
      };
      throw new SubmissionError(namespaceFormError);
    }
  }
};

export default submit;
