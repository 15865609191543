import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { change } from 'redux-form';

import { formActionsCreateItem } from 'actions/forms';

import { UI } from 'constants/formFields';
import { formNamesItem } from 'constants/forms';

import { getDefaultLineItem } from 'helpers/lineItems';

import { createItemsTableViewModelManagerForKindSelector } from 'queries/tableCompoundSelectors';

import { createFieldValueSelector } from 'selectors/createFormSelectors';
import { createItemFormAllValuesSelector } from 'selectors/forms';

import LineItemDeleteLogic from './LineItemDeleteLogic';

const form = formNamesItem.CREATE_ITEM;

// using createStructuredSelector here causes an error on hard reload of item edit page
// for some reason, createItemsTableViewModelManagerForKindSelector then returns undefined
export const mapStateToProps = (state, ownProps) => ({
  formValues: createItemFormAllValuesSelector(state, ownProps),
  formUI: createFieldValueSelector(form, UI)(state, ownProps),
  viewModelManager: createItemsTableViewModelManagerForKindSelector(
    state,
    ownProps,
  ),
});

export const mapDispatchToProps = {
  onDelete: (sectionPath, index) =>
    formActionsCreateItem.arraySplice(sectionPath, index, 1),
  onChange: change,
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { viewModelManager = {} } = stateProps;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    onReset: (sectionPath) => {
      const defaultLineItem = getDefaultLineItem(viewModelManager, sectionPath);
      dispatchProps.onChange(form, sectionPath, [defaultLineItem]);
    },
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(LineItemDeleteLogic);
