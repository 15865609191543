import dayjs from 'dayjs';

import { TransactionStatus, TransactionStatusText, transactionStatusToVariant } from 'constants/transactions';

import { ItemStatuses } from 'enums/items';
import { GenericDisplayStatus } from 'enums/ui';

import { transactionHelpers } from 'helpers/transactions';

export const formatDate = (date: string): string => (date ? dayjs(date).format('MMM D, YYYY') : '');

export const statusIntentMap = {
  [GenericDisplayStatus.POST_DANGER]: 'warning', // Used for transaction canceled
  [GenericDisplayStatus.NOT_STARTED]: 'critical', // Used for transaction failed and issue
  [GenericDisplayStatus.STARTED]: 'blue',
  [GenericDisplayStatus.COMPLETE]: 'success',
  [GenericDisplayStatus.POST_STARTED]: 'blue',
  [ItemStatuses.BULK_UPLOAD]: 'default',
  [ItemStatuses.BULK_UPLOAD_ACTION_CONTINUE]: 'blue',
  [ItemStatuses.BULK_UPLOAD_ACTION_REQUIRED]: 'default',
  [ItemStatuses.BULK_UPLOAD_COMPLETE]: 'success',
  [ItemStatuses.BULK_UPLOAD_ERROR]: 'critical',
  [ItemStatuses.BULK_UPLOAD_STARTING]: 'blue',
  [ItemStatuses.CANCELED]: 'warning',
  [ItemStatuses.COMPLETED]: 'success',
  [ItemStatuses.CREATED]: 'default',
  [ItemStatuses.DRAFT]: 'default',
  [ItemStatuses.EXTERNALLY_PAID]: 'success',
  [ItemStatuses.FAILED]: 'critical',
  [ItemStatuses.IMPORT]: 'default',
  [ItemStatuses.INITIATED]: 'blue',
  [ItemStatuses.INVISIBLE]: 'default',
  [ItemStatuses.ISSUE]: 'critical',
  [ItemStatuses.NEEDS_APPROVAL]: 'default',
  [ItemStatuses.NEW]: 'default',
  [ItemStatuses.OCR]: 'default',
  [ItemStatuses.PENDING]: 'blue',
  [ItemStatuses.PROCESSING]: 'blue',
  [ItemStatuses.READY_TO_SEND]: 'default',
  [ItemStatuses.SCHEDULED]: 'purple',
  [ItemStatuses.QUEUED]: 'blue',
} as const;

export const itemStatusTextMap = {
  [ItemStatuses.BULK_UPLOAD]: 'Unscheduled',
  [ItemStatuses.BULK_UPLOAD_ACTION_CONTINUE]: 'Processing',
  [ItemStatuses.BULK_UPLOAD_ACTION_REQUIRED]: 'Unscheduled',
  [ItemStatuses.BULK_UPLOAD_COMPLETE]: 'Completed',
  [ItemStatuses.BULK_UPLOAD_ERROR]: 'Failed',
  [ItemStatuses.BULK_UPLOAD_STARTING]: 'Initiated',
  [ItemStatuses.CANCELED]: 'Canceled',
  [ItemStatuses.COMPLETED]: 'Completed',
  [ItemStatuses.CREATED]: 'Unscheduled',
  [ItemStatuses.DRAFT]: 'Unscheduled',
  [ItemStatuses.EXTERNALLY_PAID]: 'Marked as paid',
  [ItemStatuses.FAILED]: 'Failed',
  [ItemStatuses.IMPORT]: 'Unscheduled',
  [ItemStatuses.INITIATED]: 'Initiated',
  [ItemStatuses.INVISIBLE]: 'Invisible',
  [ItemStatuses.ISSUE]: 'Issue',
  [ItemStatuses.NEEDS_APPROVAL]: 'Unscheduled',
  [ItemStatuses.NEW]: 'Unscheduled',
  [ItemStatuses.OCR]: 'Unscheduled',
  [ItemStatuses.PENDING]: 'Pending',
  [ItemStatuses.PROCESSING]: 'Processing',
  [ItemStatuses.READY_TO_SEND]: 'Unscheduled',
  [ItemStatuses.SCHEDULED]: 'Scheduled',
  [ItemStatuses.QUEUED]: 'Queued',
} as const;

export const canceledStatuses = [GenericDisplayStatus.POST_DANGER.toString(), ItemStatuses.CANCELED.toString()];

export const failedStatuses = [
  GenericDisplayStatus.NOT_STARTED.toString(),
  ItemStatuses.BULK_UPLOAD_ERROR.toString(),
  ItemStatuses.FAILED.toString(),
  ItemStatuses.ISSUE.toString(),
  TransactionStatus.FAILED,
  TransactionStatus.ISSUE,
] as const;

export const completedStatuses = [
  GenericDisplayStatus.COMPLETE.toString(),
  ItemStatuses.COMPLETED.toString(),
  ItemStatuses.BULK_UPLOAD_COMPLETE.toString(),
  TransactionStatus.PROCESSED.toString(),
];

export const getTransactionStatus = (transaction: Transaction) => {
  const status = transactionHelpers.getTransactionStatusToUse(transaction);

  return {
    status,
    text: TransactionStatusText[status],
    variant: transactionStatusToVariant[status],
  } as const;
};

export const getItemStatus = (itemStatus: string) =>
  ({
    status: itemStatus,
    text: itemStatusTextMap[itemStatus],
    variant: statusIntentMap[itemStatus],
  } as const);

export const getTransactionOrItemStatus = (itemStatus: string, transaction?: Transaction) => {
  return transaction?.providerStatus ? getTransactionStatus(transaction) : getItemStatus(itemStatus);
};

export const getFailureReason = (transaction: Transaction): string | undefined => {
  const { providerFailureCode, providerFailureDescription } = transaction;

  if (
    transactionHelpers.shouldTransactionDisplayIssueStatus(transaction) ||
    transactionHelpers.isTransactionFailed(transaction)
  ) {
    return `${providerFailureCode}: ${providerFailureDescription}`;
  }

  return undefined;
};
