import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

import { sharedFormSubmitRoutinePayloadCreators } from 'helpers/routine';

import * as types from 'types/webhooks';

/**
 * @type {ReduxSagaRoutine}
 */
export const webhooksFetchRoutine = createRoutine(types.FETCH_WEBHOOKS);

/**
 * @type {ReduxSagaRoutine}
 */
export const webhookSettingsDeleteRoutine = createRoutine(types.DELETE_WEBHOOK_SETTINGS);

/**
 */
export const webhookSettingsFetchRoutine = createRoutine(types.FETCH_WEBHOOKS_SETTINGS);

/**
 * @type {ReduxSagaRoutine}
 */
export const webhookSettingsTestRoutine = createRoutine(types.TEST_WEBHOOKS_SETTINGS);

/**
 * @type {ReduxSagaRoutine}
 */
export const webhookSettingsSubmitRoutine = createRoutine(
  types.SUBMIT_WEBHOOKS_SETTINGS,
  sharedFormSubmitRoutinePayloadCreators,
);

/**
 * @type {ReduxSagaRoutine}
 */
export const webhookSettingsSubmitHandler = promisifyRoutine(webhookSettingsSubmitRoutine);

/**
 * @type {ReduxSagaRoutine}
 */
export const webhookSettingsUpdateRoutine = createRoutine(types.UPDATE_WEBHOOK_SETTINGS);
