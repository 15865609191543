/**
 * @fileOverview Defines the sub-reducer for active feature settings.
 * @module actions/routines/featureSettings
 */

import { getFetchFeatureSettingsApiConfig } from 'sagas/featureSettings/api';

import { GET_FEATURE_SETTINGS } from 'types/featureSettings';

import { createApiRoutine } from './createApiRoutine';

/**
 * Defines types and creates actions for all getting-feature-settings related side effects.
 * @type {ReduxSagaRoutine}
 */
export const getFeatureSettingsRoutine = createApiRoutine(GET_FEATURE_SETTINGS, getFetchFeatureSettingsApiConfig(), {
  success: (data) => data?.featureSetting,
});
