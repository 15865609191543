import React from 'react';

import { Text } from 'components';

/**
 * Component rendering the content of the disabled Request Tax Info button
 * tooltip when the button is disabled due to missing contact
 */
const VendorWithoutValidContactTooltipContent = (): JSX.Element => (
  <Text.Regular>
    There is no contact email address. To request tax information, go to vendor&apos;s contact info and add a valid
    contact email address.
  </Text.Regular>
);

export default VendorWithoutValidContactTooltipContent;
