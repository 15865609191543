import React, { ChangeEvent, forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputField } from 'components';

import { getErrorsFromFieldState } from '../helpers';

import type { MaskedTextInputProps, RenderMaskedTextInputProps } from './RenderMaskedTextInput.types';

const MaskedInputField = forwardRef<HTMLInputElement, MaskedTextInputProps>(
  ({ errors, formatter, isRequired, name, onBlur, onChange, placeholder, value }, ref) => {
    const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
      const evtCopy = evt;
      evtCopy.target.value = formatter(evtCopy.target.value);
      onChange(evtCopy);
    };

    return (
      <InputField
        errors={errors}
        isRequired={isRequired}
        isUncontrolled
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        placeholder={placeholder}
        setRef={ref}
        value={value}
      />
    );
  },
);

export const RenderMaskedTextInput = ({
  isRequired,
  formatter,
  name,
  placeholder,
  shouldUnregister,
}: RenderMaskedTextInputProps): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <MaskedInputField
          {...field}
          errors={getErrorsFromFieldState(fieldState, field.name)}
          formatter={formatter}
          isRequired={!!isRequired}
          placeholder={placeholder}
        />
      )}
      rules={{ required: isRequired }}
      shouldUnregister={shouldUnregister}
    />
  );
};
