import { companySettingsSSOFetchRoutine } from 'actions/routines/sso';

import { createIsFetchingReducer } from 'store/redux';

export const finishCases = [companySettingsSSOFetchRoutine.FAILURE, companySettingsSSOFetchRoutine.SUCCESS];

export const requestCases = [companySettingsSSOFetchRoutine.TRIGGER];

const isFetchingReducer = createIsFetchingReducer(finishCases, requestCases);

export default isFetchingReducer;
