import { skipToken } from '@reduxjs/toolkit/dist/query';
import { isTaxInfoStatusCompleted } from '@routable/taxes';

import { TaxInfoDataKey } from 'constants/taxes';

import type { Company } from 'interfaces/company';
import type { RoutableApiResponse } from 'interfaces/routableApiResponse';
import { TaxInfoDataKeyValue } from 'interfaces/taxes';

import {
  usePartnershipForIdQuery,
  useW8BenDetailsQuery,
  useW8BenEDetailsQuery,
  useW8BenETaxesQuery,
  useW8BenTaxesQuery,
  useW9DetailsQuery,
  useW9TaxesQuery,
} from './partnership.endpoints';

/**
 * Given a partnership id, it selects the partnership from the state, identifies which
 * tax documents endpoint to call (domestic = w9, international individual = w8Ben) and
 * returns the API state received from the API hook
 * @param partnershipId - The ID of a partnership for which we want to fetch the tax documents
 * @param taxInfoDataKey - Tax doc type key to determine which API to use for partnership.
 */
export const useTaxesQuery = (partnershipId: string, taxInfoDataKey: TaxInfoDataKeyValue) => {
  const isW8Ben = taxInfoDataKey === TaxInfoDataKey.W8BEN;
  const isW8Bene = taxInfoDataKey === TaxInfoDataKey.W8BENE;
  const isW9 = taxInfoDataKey === TaxInfoDataKey.W9;

  const payload = { partnershipId };
  const taxesQueryMap = {
    [TaxInfoDataKey.W8BEN]: useW8BenTaxesQuery(isW8Ben ? payload : skipToken),
    [TaxInfoDataKey.W8BENE]: useW8BenETaxesQuery(isW8Bene ? payload : skipToken),
    [TaxInfoDataKey.W9]: useW9TaxesQuery(isW9 ? payload : skipToken),
  };

  return taxesQueryMap[taxInfoDataKey];
};

/**
 * Given a partnership id, it fetches the list of tax documents for that parntership,
 * grabs the completed document id, and fetches details for the completed tax document.
 * @param partnershipId - The ID of a partnership for which we want to fetch the completed tax document details
 * @param taxInfoDataKey - Tax doc type key to determine which API to use for partnership.
 */
export const useCompletedTaxDocDetails = (partnershipId: string, dataKey: TaxInfoDataKeyValue) => {
  // 1. Grab the vendors tax records.
  const taxesQuery = useTaxesQuery(partnershipId, dataKey);

  // 2. Find the ID of a completed tax document.
  // If we can't find such document or there is no data to work with, then we set the id to undefined.
  // Note: There can never be two completed docs.
  const taxInfoRecords = Object.values(taxesQuery.data?.[dataKey] || {});
  const completedDocId = taxInfoRecords.find((record) => isTaxInfoStatusCompleted(record.attributes.status))?.id;

  // 3. Fetch the completed doc's details
  const payload = { partnershipId, docId: completedDocId };
  // Only fetch if we have a tax doc id. We use skipToken to prevent uneeded request.
  // Based on the taxInfoDataKey we only want to query the right API based for that tax doc type
  const fetchW8Ben = dataKey === TaxInfoDataKey.W8BEN && completedDocId;
  const fetchW8Bene = dataKey === TaxInfoDataKey.W8BENE && completedDocId;
  const fetchW9 = dataKey === TaxInfoDataKey.W9 && completedDocId;
  const taxDetailsQueryMap = {
    [TaxInfoDataKey.W8BEN]: useW8BenDetailsQuery(fetchW8Ben ? payload : skipToken),
    [TaxInfoDataKey.W8BENE]: useW8BenEDetailsQuery(fetchW8Bene ? payload : skipToken),
    [TaxInfoDataKey.W9]: useW9DetailsQuery(fetchW9 ? payload : skipToken),
  };
  const taxDetailsQuery = taxDetailsQueryMap[dataKey];

  // 4. Grab the tax doc details from the attributes
  const taxDetailsMapById = taxDetailsQuery.data?.[dataKey] || {};
  const taxDocDetailsEntity = taxDetailsMapById[completedDocId];
  const taxDocDetails = taxDocDetailsEntity?.attributes;

  // 5. Set the loading state
  // The loading state is stopped once the first request (taxesQuery) is fufilled if there’s no completed document.
  // If there’s a completed document, the loading state is stopped once the second request (taxDetailsQuery) is fufilled.
  const isLoading = completedDocId ? taxDetailsQuery.isLoading : taxesQuery.isLoading;

  return { isLoading, taxDocDetails };
};

/**
 * Given an API response data from the retrieve partnership endpoint and an
 * partnershipId, it returns the attributes belonging to the partner's company
 * @param data - API response data received from the partnershipForId endpoint
 * @param partnershipId - Partnership id for which the request was made
 * @returns Partner's company data (attributes)
 */
export const getPartnerDataFromPartnershipApiResponse = (
  data?: RoutableApiResponse,
  partnershipId?: string,
): Omit<Company, 'id'> | null => {
  if (!data || !data.company || !data.partnership || !partnershipId) {
    return null;
  }

  const { relationships } = data.partnership[partnershipId];
  const { id: partnerId } = relationships.partner.data;

  return data.company[partnerId]?.attributes || null;
};

/**
 * Given a partnershipId it fetches the partnership and returns partnership
 * data along with the partner's company data
 * @param partnershipId - Partnership Id to fetch
 * @returns Partnership and partner's company data + the API state
 */
export const usePartnershipAndPartnerCompanyFromPartnershipIdQuery = (partnershipId?: string) =>
  usePartnershipForIdQuery(partnershipId || skipToken, {
    selectFromResult: ({ data, originalArgs, ...rest }) => ({
      partner: getPartnerDataFromPartnershipApiResponse(data, partnershipId),
      partnership: data?.partnership?.[originalArgs]?.attributes,
      ...rest,
    }),
  });
