import { type InternationalBankField } from '@routable/fund-routes';
import _intersectionWith from 'lodash/intersectionWith';

import { allKeys, anyValues, hasZeroLength, isEqual } from 'helpers/utility';

import { type UnknownObject } from 'interfaces/global';

/**
 * Returns true if passed errors object is not empty and does not have fields key included
 * (since we don't want to show the error hint if field-level errors are present).
 * @param errors
 * @param fields
 * @returns True if the error hint should be shown
 */
export const shouldDisplayErrorHint = (errors: UnknownObject, fields: InternationalBankField[]): boolean => {
  // If any of the errors object key matches fieldInfo.field, we know that we have
  // field errors. intersactionWith returns an array of values that intersect or empty
  // array of no intersaction is found, so we pass the output of that to the hasZeroLength
  // helper to actually check if there are no field errors.
  const hasNoFieldErrors = hasZeroLength(
    _intersectionWith(allKeys(errors), fields, (errorKey, fieldDescription) =>
      isEqual(errorKey, fieldDescription?.field),
    ),
  );

  // If we have field errors, we don't want to display the hint
  if (!hasNoFieldErrors) {
    return false;
  }

  // If we do not have field-level errors, we want to display the hint if the errors
  // object is not empty
  return anyValues(errors);
};

/**
 * Receives a fieldName and check whether this field should be validated via validation endpoint
 * This will return true for fields that Currency Cloud can validate: https://developer.currencycloud.com/api-reference/#get-bank-details
 * @param fieldName
 * @param isInternational
 * @returns True if said field name should be validate under Currency Cloud validation endpoint
 */
export const shouldLookupBankAccount = (fieldName: string, isInternational: boolean): boolean => {
  if (!isInternational) {
    return false;
  }

  const bankFieldsToBeValidated = {
    aba: true,
    bic_swift: true,
    bsb_code: true,
    cnaps: true,
    iban: true,
    ifsc: true,
    routing_number: true,
    sort_code: true,
  };

  return bankFieldsToBeValidated[fieldName] || false;
};
