import { ssoSetupFormEditSubmitRoutine, ssoSetupFormSubmitRoutine } from 'actions/routines/sso';

import { SSOActionType } from 'enums/sso';

import type { Action } from 'interfaces/actions';

import { getModalReducerOpenAction } from 'store/redux';

import type { ModalsReducerProps } from './modalsReducer.types';

export const initialState: ModalsReducerProps = {
  open: false,
};

/**
 * Modal reducer for SSO Setup Form
 */
const modalSetupSSOReducer = (state = initialState, action: Action): ModalsReducerProps => {
  switch (action.type) {
    case SSOActionType.CLOSE_SETUP_FORM_MODAL:
    case ssoSetupFormSubmitRoutine.SUCCESS:
    case ssoSetupFormEditSubmitRoutine.SUCCESS:
      return initialState;

    case SSOActionType.OPEN_SETUP_FORM_MODAL_WITH_PROVIDER:
      return getModalReducerOpenAction(state, action);

    default:
      return state;
  }
};

export default modalSetupSSOReducer;
