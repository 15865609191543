/**
 * Enum representing a reason for the payment being
 * blocked by missing approver(s)
 * @enum
 */
export const PaymentBlockedMissingApproverReason = {
  APPROVAL_LEVEL_NO_REQUIRED_APPROVER: 'approval_level_no_required_approvers',
  NO_MANUAL_APPROVER: 'no_manual_approver',
} as const;

/**
 * Map of hint texts related to reason enum
 */
export const paymentBlockedReasonText: Record<ValueOf<typeof PaymentBlockedMissingApproverReason>, string> = {
  [PaymentBlockedMissingApproverReason.APPROVAL_LEVEL_NO_REQUIRED_APPROVER]:
    "by an approval level that's missing a required approver",
  [PaymentBlockedMissingApproverReason.NO_MANUAL_APPROVER]: 'because a manually added approver is no longer active',
};
