import { Badge } from '@routable/gross-ds';
import React from 'react';

import { DropdownMenuOption } from 'complexComponents/DropdownMenuButton';

import { isGreaterOrEqual } from 'helpers/utility';

import type { TableToolbarActionsMenuOptionProps } from './TableToolbarActionsMenuOption.types';

/**
 * Renders a single menu option in the TableToolbarActionsMenu.
 * @type {React.FC<TableToolbarActionsMenuOptionProps>}
 */
const TableToolbarActionsMenuOption: React.FC<TableToolbarActionsMenuOptionProps> = ({
  actionableCount,
  isDisabled,
  isDisabledWhenNoActionable = true,
  title,
  ...rest
}) => {
  const hasActionableCount = isGreaterOrEqual(actionableCount, 1);

  return (
    <DropdownMenuOption
      {...rest}
      className="table-toolbar--dd-option"
      isDisabled={isDisabled || (isDisabledWhenNoActionable && !hasActionableCount)}
      title={
        <div className="flex justify-between">
          {title}
          {hasActionableCount && (
            <Badge className="action-count" intent="blue" size="small">
              {actionableCount}
            </Badge>
          )}
        </div>
      }
    />
  );
};

export default TableToolbarActionsMenuOption;
