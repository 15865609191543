import { REST } from 'helpers/api';

import type { Company, CompanyAddress } from 'interfaces/company';
import type { FetchServiceResponse } from 'interfaces/fetchService';

import { createCompanyAddressEndpoint, singleCompanyAddressEndpoint } from 'services/api/companyEndpoints';
import { payloadToJSONAPI } from 'services/api/formatHelpers';

/**
 * REST API request that Creates a company mailing address
 */
export const createCompanyMailingAddress = async (
  values: { address: CompanyAddress },
  companyId: string,
): Promise<FetchServiceResponse> =>
  REST.post({
    endpoint: createCompanyAddressEndpoint(companyId),
    requireAuth: true,
    setRequester: true,
    payload: payloadToJSONAPI(values.address, 'address'),
  });

/**
 * REST API request that Updates a Company Mailing Address
 * @param companyId - Id of the company for which we want to update the address
 * @param addressId - Id of the company address that we want to update
 */
export const updateCompanyMailingAddress = async (
  company: Company,
  values: { address: CompanyAddress },
): Promise<FetchServiceResponse> =>
  REST.patch({
    endpoint: singleCompanyAddressEndpoint(company.id, company.mailingAddress),
    requireAuth: true,
    setRequester: true,
    payload: payloadToJSONAPI(values.address, 'address'),
  });
