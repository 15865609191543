import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { createItemBillCurrencySelector } from 'queries/createItemFormSelectors';

import CurrencyTableField from './CurrencyTableField';
import type { StateProps } from './CurrencyTableField.types';

export const mapStateToProps = createStructuredSelector<ReduxState, StateProps>({
  currencyCode: createItemBillCurrencySelector,
});

export default connect(mapStateToProps)(CurrencyTableField);
