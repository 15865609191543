import React from 'react';

import { FormControl } from 'components/form';

import { Intent } from 'constants/ui';

import BaseHint from '../BaseHint';

/**
 * Hint component informing the RCTMs that they need to select a contact with full permission if they want to allow
 * the vendor to select their payment method
 */
const FullPermissionContactRequiredToAllowSelectPaymentMethodHint = (): React.ReactElement => (
  <FormControl>
    <BaseHint
      intent={Intent.WARNING}
      text="There are currently only read-only contacts. To allow a vendor to select their payment method, please include a contact with full permissions above."
      title="A full permissions contact is required"
    />
  </FormControl>
);

export default FullPermissionContactRequiredToAllowSelectPaymentMethodHint;
