import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';

import { paymentRoutine } from 'actions/payment';

import {
  paymentStateSelector,
  paymentActiveIdSelector,
  paymentActivePaymentSelector,
} from 'selectors/paymentsSelectors';

import { UsePayment } from './usePayment.types';

export const usePayment: UsePayment = ({ initialPaymentId }) => {
  const dispatch = useDispatch();
  const activeId = useSelector(paymentActiveIdSelector);
  const data = useSelector(paymentActivePaymentSelector);
  const activePaymentId = useSelector(paymentActiveIdSelector);
  const apiState = useSelector(paymentStateSelector);

  const refresh = () => {
    dispatch(paymentRoutine.trigger({ id: activePaymentId }));
  };
  const setActiveId = (id) => {
    dispatch(paymentRoutine.trigger({ id }));
  };

  useEffectOnce(() => {
    if (initialPaymentId) {
      dispatch(paymentRoutine.trigger({ id: initialPaymentId }));
    }
  });

  return {
    ...apiState,
    activeId,
    data,
    refresh,
    setActiveId,
  };
};
