import { TruncatableText } from '@routable/components';
import { Checkbox } from '@routable/gross-ds';
import clsx from 'clsx';
import React, { useContext } from 'react';

import { ExistingItem } from 'context';

import { DiscrepanciesObjectKeyMap } from 'constants/discrepancies';

import { useDiscrepancyContext, useRefreshDiscrepanciesContext } from 'hooks/context';

import { getFieldProps } from '../../blockComponents/PresentationalField/helpers';

export const PresentationalTableField = (props) => {
  const item = useContext<Item>(ExistingItem);
  const { minDecimalScale, rowValues } = props;

  const { fieldPath, value } = getFieldProps({
    item: rowValues,
    ledger: {},
    minDecimalScale,
    ...props,
  });

  const { hasDiscrepancyForColumn } = useDiscrepancyContext();
  const { isRefreshingDiscrepancies } = useRefreshDiscrepanciesContext();

  const hasDiscrepancy = hasDiscrepancyForColumn(rowValues.id, DiscrepanciesObjectKeyMap[fieldPath]);
  const showDiscrepancy = !isRefreshingDiscrepancies && hasDiscrepancy;

  if (value === undefined) {
    return null;
  }

  if (typeof value === 'boolean') {
    return <Checkbox checked={value} disabled name={fieldPath} />;
  }

  if (minDecimalScale > 0) {
    return (
      <div className="flex justify-end w-full">
        <div className="min-w-0 relative">
          {showDiscrepancy && (
            <div
              className="absolute border border-gold-40 rounded-[13px] inset-y-[-6px] inset-x-[-8px]"
              data-testid="discrepancy-border"
            />
          )}

          <span className="text-xs text-black w-full text-right">
            {`${value} `}
            <span className="!text-grey-60">{item.currencyCode}</span>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx({
        'flex w-full justify-center': fieldPath === 'quantity',
      })}
    >
      <div
        className={clsx('relative', {
          'min-w-[16px] text-center': fieldPath === 'quantity',
        })}
      >
        {showDiscrepancy && (
          <div
            className="absolute border border-gold-40 rounded-[13px] inset-y-[-6px] inset-x-[-8px]"
            data-testid="discrepancy-border"
          />
        )}

        <TruncatableText className="text-xs text-black" position="top" text={value} />
      </div>
    </div>
  );
};
