import React from 'react';

import { Icon, IconNames } from 'components';
import { ButtonV2 } from 'components/buttonV2';
import { Col, Row } from 'components/layout';
import { ModalBodyContent, ModalFooter } from 'components/modal';
import Text from 'components/text/Text/Text';

import { ButtonSize } from 'constants/button';
import { CONTACT_SUPPORT_LOWERCASE_LABEL } from 'constants/labels';
import { colors } from 'constants/styles';
import { TextColor, TextSize } from 'constants/styles/typography';
import { Intent } from 'constants/ui';

import { CustomerServiceHelper } from 'modules/customerSupport/CustomerService';

import { QuickswitchActionCreator } from 'types/quickswitch';

export interface QuickswitchErrorModalContentProps {
  onLoginClick: () => QuickswitchActionCreator;
}

const QuickswitchErrorModalContent = ({ onLoginClick }: QuickswitchErrorModalContentProps): JSX.Element => {
  const onSupportClick = () => {
    CustomerServiceHelper.show();
  };

  return (
    <React.Fragment>
      <ModalBodyContent className="quickswitch-error-modal--content">
        <Row className="quickswitch-error-modal--title quickswitch-error-modal--paragraph">
          <Col>
            <Text.ExtraBold color={TextColor.BLACK} size={TextSize.LEVEL_400}>
              <Icon className="margin-right--sm" color={colors.colorRedBoldHex} icon={IconNames.WARNING_SIGN} />
              Switching accounts failed
            </Text.ExtraBold>
          </Col>
        </Row>
        <Row className="quickswitch-error-modal--paragraph padding-bottom--m-large">
          <Col>
            <Text.Regular color={TextColor.BLACK} size={TextSize.LEVEL_200}>
              Looks like we’re having trouble switching your Routable account. You will need to re-login in order to
              proceed.
            </Text.Regular>
          </Col>
        </Row>
        <Row className="quickswitch-error-modal--paragraph padding-bottom--l">
          <Col>
            <Text.Regular color={TextColor.BLACK} size={TextSize.LEVEL_200}>
              If you have any questions, or need our assistance in solving this issue, please&nbsp;
              <a
                className="quickswitch-error-modal--link"
                onClick={onSupportClick}
                onKeyDown={onSupportClick}
                role="link"
                tabIndex={0}
              >
                {CONTACT_SUPPORT_LOWERCASE_LABEL}
              </a>
              &nbsp;and we&apos;ll be happy to help.
            </Text.Regular>
          </Col>
        </Row>
      </ModalBodyContent>
      <ModalFooter className="quickswitch-error-modal--footer">
        <ButtonV2
          className="hover--neutral"
          id="quickswitch-error-modal-contact-support-btn"
          intent={Intent.NEUTRAL}
          onClick={onSupportClick}
          size={ButtonSize.MEDIUM}
        >
          Contact Support
        </ButtonV2>
        <ButtonV2
          id="quickswitch-error-modal-login-btn"
          intent={Intent.PRIMARY}
          onClick={onLoginClick}
          size={ButtonSize.MEDIUM}
        >
          Login
        </ButtonV2>
      </ModalFooter>
    </React.Fragment>
  );
};

export default QuickswitchErrorModalContent;
