import React from 'react';

import { TooltipMUIConditionalWrapper } from 'components';

import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { stepStatuses, substepDefaultStatuses } from '../constants';
import { ProgressBarStepProps } from '../ProgressBarStepperV2.types';

import { Item, SubstepIcon } from './ProgressBarSubstep.styles';

const ProgressBarSubstep: React.VFC<ProgressBarStepProps> = ({ step, onClickStep }) => {
  const tooltipProps = step.tooltip?.[step.status] && {
    arrow: true,
    padding: TooltipPadding.LARGE,
    placement: TooltipPlacement.RIGHT,
    title: step.tooltip?.[step.status],
    style: {
      fontSize: '0.75rem',
    },
  };
  const isDisabled = step.status === stepStatuses.disabled;
  return (
    <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
      <Item
        color={substepDefaultStatuses[step.status].color}
        disabled={isDisabled}
        isActive={step.isActive}
        onClick={() => onClickStep(0, step.id)}
      >
        {step.label}
        <SubstepIcon icon={substepDefaultStatuses[step.status].icon} />
      </Item>
    </TooltipMUIConditionalWrapper>
  );
};

export default ProgressBarSubstep;
