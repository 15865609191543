import React from 'react';

import { InvitationCodeInputTooltip } from 'complexComponents';

import {
  BoxV2,
  ButtonV2,
  CheckboxTermsOfService,
  FormControl,
  FormFieldLabel,
  IconNames,
  InputField,
  SelectFieldV2,
} from 'components';

import { ButtonSize } from 'constants/button';
import { createCompanyFields } from 'constants/formFields';
import { formNamesSignup } from 'constants/forms';
import { membershipTitles } from 'constants/membership';
import { formStyles, sizes } from 'constants/styles';

import { CreateAccountUserProps } from './CreateAccountUser.types';

const CreateAccountUser: React.FC<CreateAccountUserProps> = ({
  errors,
  formState,
  isSubmitting,
  onCheckboxInputChange,
  onFormSubmit,
  onInputChange,
  onSelectChange,
}) => (
  <div className="branded--contents">
    <BoxV2 title="Create your account">
      <form className="form" id={formNamesSignup.CREATE_ACCOUNT_USER}>
        <FormFieldLabel>Your info</FormFieldLabel>

        <FormControl>
          <InputField
            className={formStyles.field.xl.left}
            errors={errors}
            name={createCompanyFields.USER_FIRST_NAME}
            onChange={(event) => onInputChange(event, 'form.user')}
            placeholder="First name"
            setIsRequired
            type="text"
            value={formState.user[createCompanyFields.USER_FIRST_NAME]}
          />

          <InputField
            className={formStyles.field.xl.right}
            errors={errors}
            name={createCompanyFields.USER_LAST_NAME}
            onChange={(event) => onInputChange(event, 'form.user')}
            placeholder="Last name"
            setIsRequired
            type="text"
            value={formState.user[createCompanyFields.USER_LAST_NAME]}
          />
        </FormControl>

        <FormControl>
          <SelectFieldV2
            errors={errors}
            input={{
              name: createCompanyFields.USER_TITLE,
              onChange: (option) => onSelectChange(createCompanyFields.USER_TITLE, option, 'form'),
              value: formState[createCompanyFields.USER_TITLE],
            }}
            label="Your title"
            options={membershipTitles}
            placeholder="Select title"
          />
        </FormControl>

        <FormControl>
          <InputField
            className={formStyles.field.xl.full}
            errors={errors}
            name={createCompanyFields.USER_EMAIL}
            onChange={(event) => onInputChange(event, 'form.user')}
            placeholder="Company email"
            setIsRequired
            type="email"
            value={formState.user[createCompanyFields.USER_EMAIL]}
          />
        </FormControl>

        <FormControl>
          <InputField
            className={formStyles.field.xl.full}
            errors={errors}
            name={createCompanyFields.USER_PASSWORD}
            onChange={(event) => onInputChange(event, 'form.user')}
            placeholder="Password"
            setIsRequired
            type="password"
            value={formState.user[createCompanyFields.USER_PASSWORD]}
          />
        </FormControl>

        <FormControl>
          <InputField
            addon={<InvitationCodeInputTooltip />}
            className={formStyles.field.xl.full}
            errors={errors}
            name={createCompanyFields.SIGNUP_CODE}
            onChange={(event) => onInputChange(event, 'form.meta')}
            placeholder="Invitation code"
            setIsRequired
            type="text"
            value={formState.meta[createCompanyFields.SIGNUP_CODE]}
          />
        </FormControl>

        <FormControl>
          <CheckboxTermsOfService
            errors={errors}
            formState={formState.meta}
            // In case of keyboard enter press, the first value received is boolean, and the second value is the
            // actual event. In case of mouse click, the first value is event and the second value is undefined.
            onChange={(event, fallback) => onCheckboxInputChange(event, fallback, 'form.meta')}
          />
        </FormControl>

        <FormControl className="remove-margin-bottom">
          <ButtonV2
            isLoading={isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              onFormSubmit();
            }}
            rightIconName={IconNames.ARROW_RIGHT}
            rightIconProps={{ style: { marginLeft: 'auto' } }}
            rightIconSize={sizes.iconSizes.LARGE}
            size={ButtonSize.LARGE}
          >
            Create my account
          </ButtonV2>
        </FormControl>
      </form>
    </BoxV2>
  </div>
);

CreateAccountUser.defaultProps = {
  errors: {},
};

export default CreateAccountUser;
