import { useSelector } from 'react-redux';

import { enablePoMatchingBypassEarSelector } from 'selectors/currentCompanySelectors';
import { featureFlagEnterpriseApprovalRules } from 'selectors/featureFlagsSelectors';

export const useIsPoMatchingBypassEar = () => {
  const enablePoMatchingBypassEar = useSelector(enablePoMatchingBypassEarSelector) || false;
  const enableEnterpriseApprovalRules = useSelector(featureFlagEnterpriseApprovalRules);
  return enablePoMatchingBypassEar && enableEnterpriseApprovalRules;
};
