export enum PAYMENTS_LIST_TABS {
  INBOX = 'inbox',
  PAYABLES = 'payables',
  RECEIVABLES = 'receivables',
  RECENT = 'recent',
}

// Payments list filters routes (/dashboard/payments/[TAB]/{})
export enum PAYMENTS_LIST_FILTERS {
  ALL = 'all',
  COMPLETED = 'completed',
  COMPLETED_AND_EXTERNALLY_PAID = 'completed_and_externally_paid',
  CREATED = 'created',
  FAILED_AND_ISSUE = 'failed_and_issue',
  INITIATED = 'initiated',
  MY_APPROVAL = 'needs_my_approval',
  NEEDS_APPROVAL = 'needs_approval',
  PO_DISCREPANCY = 'po_discrepancy_hold',
  TEAM_APPROVAL = 'needs_team_approval',
  READY_TO_SEND = 'ready_to_send',
  SCHEDULED = 'scheduled',
  WAITING_FOR_ME = 'waiting_for_me',
  WAITING_FOR_OTHERS = 'waiting_for_others',
}

// ThreadDetails filters routes (/dashboard/payments/[TAB]/[TAB_FILTER]/[ID]/[ITEM_DETAILS_FILTER])
export enum ITEM_DETAILS_FILTERS {
  ITEM = 'item',
  PARTNER = 'partner',
  MY_DETAILS = 'my_details',
}

// Contacts tabs (/dashboard/contacts/companies/{})
export enum CONTACTS_TABS {
  COMPANIES = 'companies',
}

// Contacts companies list filters (/dashboard/contacts/companies/{})
export enum COMPANIES_LIST_FILTERS {
  ALL = 'all',
  VENDORS = 'vendors',
  CUSTOMERS = 'customers',
  INVITES = 'invites',
}

// Selected Company tabs (/dashboard/contacts/companies/:id/{})
export enum SELECTED_COMPANY_TABS {
  ABOUT = 'about',
  CUSTOMER_INFO = 'customer_info',
  PAYMENTS = 'payments',
  TAX_INFO = 'tax_info',
  VENDOR_INFO = 'vendor_info',
}

export enum GUIDE_TABS {
  BANK_CONNECTIONS = 'connect_bank',
  COMPANY_BRANDING = 'company_branding',
  COMPANY_INFO = 'company_info',
  COMPANY_LEGAL = 'company_legal',
  COMPANY_REPS = 'company_representatives',
  CONNECT_LEDGER = 'connect_ledger',
  ENABLE_PAYMENTS = 'enable_payments',
  INVITE_TEAM = 'invite_team',
  USER_AVATAR = 'user_avatar',
  VERIFY_ACCOUNT = 'verify_account',
}

export enum SETTINGS_TABS {
  ACCOUNT = 'account',
  USER = 'user',
  BILLING = 'billing',
}

// Dashboard settings account routes (/dashboard/settings/account/{})
export enum SETTINGS_ACCOUNT_FILTERS {
  API_ACCESS = 'api_access',
  BALANCE = 'balance',
  BANK_CONNECTIONS = 'company_bank_connections',
  BULK_UPLOADS = 'bulk_upload_history',
  COMPANY_BRANDING = 'company_branding',
  COMPANY_INFO = 'company_info',
  COMPANY_LEGAL = 'company_legal',
  COMPANY_REP_INFO = 'company_auth_rep_info',
  INACTIVE = 'inactive',
  INACTIVE_TEAM_MEMBER = 'inactive_team_member',
  INTEGRATIONS = 'integrations',
  INVITED = 'invited',
  INVITE_TEAM_MEMBER = 'invite_team_member',
  PAYABLE_CONFIGURATIONS = 'payable_configurations',
  PAYMENT_METHODS = 'payment_methods',
  REACTIVATE_TEAM_MEMBER = 'reactivate',
  TEAM_MEMBERS = 'team_members',
  BULK_UPLOAD_HISTORY = 'bulk_upload_history',
}

// Dashboard settings user routes (/dashboard/settings/account/{})
export enum SETTINGS_USER_FILTERS {
  PROFILE = 'profile',
  REPRESENTATIVE = 'company_representative',
  SECURITY = 'security',
  NOTIFICATIONS = 'notifications',
}

export enum PARAMS {
  PAGE = ':page?',
  TAB = ':tab?',
  FILTER = ':filter?',
  ID = ':id?',
  ITEM_FILTER = ':itemFilter?',
  KIND = ':kind?',
}

export enum MODALS {
  CREATE_PARTNERSHIP = 'create_partnership',
  REP_INFO = 'rep_info',
}

export enum CREATE_ITEM_TABS {
  CREATE_BILL = 'create_bill',
  CREATE_PAYABLE = 'create_payable',
  CREATE_RECEIVABLE = 'create_receivable',
  BATCH = 'batch/import/items',
}

export enum CREATE_ITEM_STATES {
  AWAITING_PAYMENT = 'awaiting_payment',
  BULK_UPLOAD = 'batch',
  EDIT = 'edit',
  NEW = 'new',
}

export enum AdmissionRoutes {
  PERMISSIONS = 'permissions',
  ROLES = 'roles',
}
