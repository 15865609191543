import React from 'react';
import { components, SingleValueProps } from 'react-select';

import WhiteSpace from 'components/text/WhiteSpace';

import { MultiLineOption } from 'interfaces/select';

const { SingleValue } = components;

/**
 * This component renders the <label> (<details>) variation for SingleValue.
 * The SingleValue component exported by react-select is used here like docs recommend.
 */
const SingleValueWithDetails: React.FC<SingleValueProps<MultiLineOption>> = (props) => {
  const { children, data } = props;

  return (
    <SingleValue {...props} data={data}>
      <span>{children}</span>
      <WhiteSpace />
      <span>({data?.details})</span>
    </SingleValue>
  );
};

export default SingleValueWithDetails;
