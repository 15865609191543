import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import RoutableHead from 'components/head/RoutableHead';

import { ACCEPT, EXTERNAL, NOT_FOUND, PAY, TAX, UPDATE_PAYMENT_METHOD } from 'constants/routes';

import { getJoinedPath } from 'helpers/routeHelpers';

import AllowAny from 'modules/app/AllowAny';
import SwitchOr404 from 'modules/app/SwitchOr404';
import ExternalCollectTaxInfo from 'modules/external/ExternalCollectTaxInfo';
import ExternalPaymentContainer from 'modules/external/externalPayment/ExternalPaymentContainer';
import ExternalUpdatePaymentMethodContainer from 'modules/external/externalUpdatePaymentMethod/containers/ExternalUpdatePaymentMethodContainer';
import { ExternalModals } from 'modules/external/global/containers/components';

import { notFoundErrorSelector } from 'selectors/errorsSelectors';

import '../presenters/External.scss';

const ExternalContainer = () => {
  const notFoundError = useSelector(notFoundErrorSelector);

  if (notFoundError) {
    return <Redirect to={`/${NOT_FOUND}`} />;
  }

  return (
    <>
      <RoutableHead noIndex />

      <SwitchOr404>
        <AllowAny component={ExternalPaymentContainer} path={getJoinedPath(EXTERNAL, ACCEPT)} />
        <AllowAny component={ExternalPaymentContainer} path={getJoinedPath(EXTERNAL, PAY)} />
        <AllowAny component={ExternalCollectTaxInfo} path={getJoinedPath(EXTERNAL, TAX)} />
        <AllowAny
          component={ExternalUpdatePaymentMethodContainer}
          path={getJoinedPath(EXTERNAL, UPDATE_PAYMENT_METHOD)}
        />
      </SwitchOr404>

      <AllowAny component={ExternalModals} path="/" />
    </>
  );
};

export default ExternalContainer;
