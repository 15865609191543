import pluralize from 'pluralize';
import React from 'react';

import type {
  ITableToolbarSelectionManagerOptionProps,
  ITableToolbarSelectionManagerProps,
} from 'complexComponents/Table/components/TableToolbar';

import { Icon, IconNames } from 'components';

import { iconSizes } from 'constants/styles/sizes';

import { formatNumber } from 'helpers/numbers';
import { concat, isEqual, isFn, ternary } from 'helpers/utility';

/**
 * Convenience function that adds common menu options to the array, so we don't
 * need to create these from scratch every single time.
 * Adds a "select all" option at the top, and a "clear selection" option at the bottom.
 * If you want these automatically added, just pass the function props for onSelectAll and/or
 * onClearSelection.
 * If you want to provide these manually, or just don't want them added, leave those function
 * props undefined.
 * @param {ITableToolbarSelectionManagerProps} props
 * @return {ITableToolbarSelectionManagerOptionProps[]}
 */
export const getTableToolbarSelectionManagerOptions = (
  props: ITableToolbarSelectionManagerProps,
): ITableToolbarSelectionManagerOptionProps[] => {
  const { onClearSelection, onSelectAll, options = [], resourceType, selectableRowsCount, selectedCount } = props;

  if (isFn(onSelectAll)) {
    const countString = ternary(selectableRowsCount, concat(formatNumber(selectableRowsCount), ' '), '');
    // [DEV-2026] (@sheminusminus): in this option title, we always want the plural form of
    // the resource type, which is why 2 is hard-coded here
    const resourceDisplay = pluralize(resourceType, 2);
    const titleText = `Select all ${countString}${resourceDisplay}`;

    const title = (
      <span>
        <Icon className="margin-right--6" icon={IconNames.ADD_TO_ARTIFACT} size={iconSizes.LARGE} />
        {titleText}
      </span>
    );

    options.unshift({
      isDisabled: isEqual(selectableRowsCount, selectedCount),
      isDisabledWhenNoActionable: false,
      onClick: onSelectAll,
      title,
    });
  }

  if (isFn(onClearSelection)) {
    const title = (
      <span>
        <Icon className="margin-right--6" icon={IconNames.DELETE} size={iconSizes.LARGE} />
        Clear all selected
      </span>
    );

    options.push({
      isDisabledWhenNoActionable: false,
      onClick: onClearSelection,
      title,
    });
  }

  return options;
};
