/**
 * @fileOverview Utility/functional helpers that either:
 * a) do not rely on any other functions defined in this file
 * - or -
 * b) do not need to mock any other functions defined here during testing.
 * @module helpers/utility/atomic
 */
import _cloneDeep from 'lodash/cloneDeep';
import _compact from 'lodash/compact';
import _difference from 'lodash/difference';
import _differenceBy from 'lodash/differenceBy';
import _differenceWith from 'lodash/differenceWith';
import _flatMapDeep from 'lodash/flatMapDeep';
import _flatten from 'lodash/flatten';
import _flattenDeep from 'lodash/flattenDeep';
import _get from 'lodash/get';
import _gt from 'lodash/gt';
import _head from 'lodash/head';
import _includes from 'lodash/includes';
import _intersection from 'lodash/intersection';
import _isArray from 'lodash/isArray';
import _isBoolean from 'lodash/isBoolean';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _isFunction from 'lodash/isFunction';
import _isNull from 'lodash/isNull';
import _isNumber from 'lodash/isNumber';
import _isObject from 'lodash/isObject';
import _last from 'lodash/last';
import _lt from 'lodash/lt';
import _map from 'lodash/map';
import _pullAll from 'lodash/pullAll';
import _pullAllBy from 'lodash/pullAllBy';
import _pullAllWith from 'lodash/pullAllWith';
import _set from 'lodash/set';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';

import { UnknownObject } from 'interfaces/global';

import {
  AllEntries,
  AllValues,
  AsArrayElement,
  DefaultValue,
  ForEachEntry,
  GetArrayValue,
  GetObjectDiff,
  MapSet,
  Operation,
  Predicate,
  PredicateType,
  Ternary,
  TernaryCall,
} from './atomic.types';

// ======================
// Type Utils
// ======================

/**
 * Returns true if the value given is an array.
 */
export const isArray = (value: unknown): value is [] => _isArray(value);

/**
 * Returns true if the value given is a boolean.
 */
export const isBool = (value: unknown): value is boolean => _isBoolean(value);

/**
 * Returns true if the value given is a function.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const isFn = <T extends Function>(value: unknown): value is T => _isFunction(value);

/**
 * Returns true if the value given is NaN.
 */
export const isNaN = (value: unknown): boolean => Number.isNaN(value);

/**
 * Returns true if the value given is NOT NaN.
 */
export const isNotNaN = (value: unknown): boolean => !isNaN(value);

/**
 * Returns true if the value given is null.
 */
export const isNull = (value: unknown): value is null => _isNull(value);

/**
 * Returns true if the value given is a number.
 */
export const isNum = (value: unknown): value is number => _isNumber(value);

/**
 * Returns true if the value given is an object (excluding array).
 */
export const isObject = (value: unknown): value is UnknownObject => _isObject(value) && !isArray(value);

/**
 * Returns true if the value given is NOT an object.
 * Note: will not consider arrays an object; use isArray for array checks.
 */
export const isNotObject = (value: unknown): boolean => !isObject(value);

/**
 * Returns true if the value given is a string.
 */
export const isString = (value: unknown): value is string => typeof value === 'string';

/**
 * Returns true if the value given is undefined.
 */
export const isUndef = (value: unknown): value is undefined => typeof value === 'undefined';

// ======================
// Getter Utils
// ======================

/**
 * Returns all object entries, or empty array if object is undefined/null.
 */
export const allEntries: AllEntries = (obj) => {
  if (!obj || !isObject(obj)) {
    return [];
  }

  return Object.entries(obj);
};

/**
 * Returns all object keys, or empty array if object is undefined/null.
 */
export const allKeys = (obj?: unknown): string[] => {
  if (!obj || !isObject(obj)) {
    return [];
  }

  return Object.keys(obj).filter((key) => !!key);
};

/**
 * Returns true if key exists in object.
 */
export const objectHasKey = (obj: unknown, key: string | number | symbol): boolean => {
  if (!obj || !isObject(obj) || !key || (!isString(key) && !isNum(key))) {
    return false;
  }

  return Object.prototype.hasOwnProperty.call(obj, key);
};

/**
 * Returns all object values, or empty array if object is undefined/null.
 */
export const allValues: AllValues = (obj) => {
  if (!obj || !isObject(obj)) {
    return [];
  }

  return Object.values(obj);
};

/**
 * Returns the first item in an array.
 */
export const firstValue: GetArrayValue = (arr = []) => _head(arr);

/**
 * Returns the last item in an array.
 */
export const lastValue: GetArrayValue = (arr = []) => _last(arr);

/**
 * Returns the first item in an array of all object keys.
 */
export const firstKey = (value = {}): string => firstValue(allKeys(value));

/**
 * Runs a forEach loop on all entries in the provided object.
 */
export const forEachEntry: ForEachEntry = <T>(obj, callback) => allEntries<T>(obj).forEach(callback);

/**
 * When we are looping over an array of objects we use to define a component, we often set a key property to set as the
 * React list key. This helper makes that key selection easier.
 */
export const commonKeyExtractor = <T = unknown>(component?: { key?: T }): T | undefined => component?.key;

/**
 * Returns the value for the key in the given object.
 */
export const valueForKey = _get;

/**
 * Returns first truthy value for a given key in a item of a given array
 * firstValueForKeyInArray([{ k: null }, { k: 'a' }], 'k') // 'a'
 */
export const firstValueForKeyInArray = <T, K extends keyof T>(items: T[], itemProp: K): T[K] => {
  const firstItem = items.find((item) => item?.[itemProp]);

  return valueForKey(firstItem, itemProp);
};

/**
 * If the given arg is undefined or already an array, returns as-is.
 * If it is some other type, inserts it as the first element of an array and returns the array.
 */
export const asArrayElement: AsArrayElement = (val, options = {}) => {
  const { nestArrays = false } = options;

  if (isUndef(val) || (isArray(val) && !nestArrays)) {
    return val;
  }

  return [val];
};

/**
 * Concatenates all arguments that are strings.
 */
export const concat = (...args: unknown[]): string => ''.concat(...args.filter(isString));

/**
 * Returns an object containing only properties where the first and second argument objects differ
 * in the values of said properties.
 */
export const getObjectDiff: GetObjectDiff = (previous, current) =>
  allKeys(previous).reduce((map, key) => {
    if (previous[key] !== current[key]) {
      return {
        ...map,
        [key]: current[key],
      };
    }

    return map;
  }, {});

/**
 * Returns the length property of the given value.
 */
export const lengthOf = (value: { length?: number }): number => value?.length ?? 0;

/**
 * Returns a value or a default value
 */
export const valueOrDefault = <T = unknown>(value: T, defaultValue: T): T => value || defaultValue;

/**
 * Returns the first truthy value in an array, or a default value
 */
export const firstValueOrDefault: DefaultValue = (values, defaultValue) =>
  valueOrDefault(firstValue(values?.filter(Boolean)), defaultValue);

/**
 * Returns the first truthy value in an array, or am empty string
 */
export const firstValueOrEmptyString = <T>(values: T[]): T | string => firstValueOrDefault<T, string>(values, '');

// ======================
// Functional Utils
// ======================

/**
 * Returns true if any item in the array is truthy.
 */
export const any = <T>(array: T[]): boolean => array.some((val) => !!val);

/**
 * Returns true if any item in the object is truthy.
 * @param {ObjectMaybe} obj
 * @return {boolean}
 */
// TODO Add types
export const anyValues = (obj: unknown): boolean => (isObject(obj) ? any(Object.values(obj)) : false);

/**
 * Returns true if all members are equal
 */
export const areAllEqual = (array: unknown[]): boolean => array.every((val) => val === array[0]);

/**
 * Returns true when the args transition from truthy to falsy.
 */
export const becameFalsy: Predicate<unknown> = (prevValue, nextValue) => Boolean(prevValue && !nextValue);

/**
 * Returns true when the args transition from false-y to truth-y.
 */
export const becameTruthy: Predicate<unknown> = (prevValue, nextValue) => Boolean(!prevValue && nextValue);

/**
 * Returns a function that, when called, calls the first argument given, passing
 * in all remaining arguments, plus those given to the result.
 * @example
 * const someFunction = (...allArguments) => console.log(allArguments);
 * <button onClick={call(someFunction, 'givenArg')} />
 * // when the button is clicked, it will log:
 * // ['givenArg', MouseEvent]
 * @param {Function} fn
 * @param {...*} givenArgs
 * @return {Function}
 */
// TODO Add types
export const call =
  (fn, ...givenArgs) =>
  (...callbackArgs) =>
    fn(...givenArgs, ...callbackArgs);

/**
 * Returns true if every item in the array is truthy.
 * @param {Array<*>} array
 * @return {boolean}
 */
export const every = (array: unknown[]): boolean => array.every((val) => !!val);

/**
 * Returns true if the given value "exists" (is truthy);
 * @param {*} value
 * @return {Boolean}
 */
export const exists = (value: unknown): boolean => Boolean(value);

/**
 * Returns the argument
 * @param value
 * @returns {*}
 */
export const identity = <T = unknown>(value: T): T => value;

/**
 * Gets arg.value if arg is an object and value prop exists.
 * Otherwise, returns the arg.
 * Otherwise, returns the arg.
 * @example
 * // returns 3
 * getValuePropOrIdentity({ value: 3 });
 * @example
 * // returns the parameter if there is no value property
 * // returns { x: 3 }
 * // getValuePropOrIdentity({ x: 3 });
 * @param {*} val
 * @return {*}
 */
// TODO Add types
export const getValuePropOrIdentity = (val) => {
  if (isObject(val) && val.value) {
    return val.value;
  }

  return identity(val);
};

/**
 * Returns whether the given value has a numeric length greater than 0.
 */
export const hasLength = (value: unknown): boolean => lengthOf(value) > 0;

/**
 * If the value given is a function, call it. Otherwise, return false.
 */
export const callIfIsFn = (value: unknown): boolean => (isFn(value) ? value() : false);

/**
 * If the value given is a function, call it with supplied arguments. Otherwise, return false.
 */
export const callWithArgsIfIsFn = <T>(value: unknown, ...args: unknown[]): T => (isFn(value) ? value(...args) : false);

/**
 * Flattens nested arrays. Recursive flattening is the default behavior; pass false to options.deep
 * to flatten shallow.
 */
export const flattenArray = <T = unknown>(arr: (T | T[])[], options: { deep?: boolean } = {}): T[] => {
  const { deep = true } = options;

  if (deep) {
    return _flattenDeep(arr);
  }

  return _flatten(arr);
};

/**
 * Creates an array with all falsey values removed.
 * The values false, null, 0, "", undefined, and NaN are falsy
 * @param array The array to compact.
 * @returns Returns the new array of filtered values
 */
export const compact = <T>(array: T[]): T[] => _compact(array);

/**
 * A method to check if a value exists, including zero.
 */
export const hasValueOrZero = (value: unknown): boolean => !!(value || value === 0);

/**
 * Returns whether the given value has a numeric length of 0.
 */
export const hasZeroLength = (value: unknown[]): boolean => value?.length === 0;

/**
 * Creates an array of unique values that are included in all given arrays.
 */
export const intersection = <T = unknown>(...arrays: T[][]): T[] => _intersection(...arrays);

/**
 * Returns true if the value given is an object without any properties.
 */
export const isEmptyObject = (value: unknown): boolean => isObject(value) && Object.keys(value).length === 0;

/**
 * Returns true if the given values are deep-equal.
 */
export const isEqual: PredicateType = (val1, val2) => _isEqual(val1, val2);

/**
 * Returns true if the given value is less or equal the second value
 */
export const isLessOrEqual: Predicate<number> = (val1, val2) => val1 <= val2;

/**
 * Returns true if any array element is equal to the first argument.
 */
export const isEqualToAny = (value: unknown, array: unknown[]): boolean =>
  Boolean(array.find((arrayElement) => isEqual(arrayElement, value)));

/**
 * Returns true if the given values are not deep-equal.
 */
export const isNotEqual: PredicateType = (val1, val2) => !_isEqual(val1, val2);

/**
 * Returns whether the given object or array shallowly contains the given
 * value (keys will not be matched against, but values will be).
 */
export const isIncluded = _includes;

/**
 * Returns whether the length property is equal to the length provided.
 */
export const isLength = (value: unknown[], length: number): boolean => isEqual(lengthOf(value), length);

/**
 * Returns true if the first argument is less than the second argument.
 * @see {@link https://lodash.com/docs/4.17.15#lt|lt}
 */
export const isLessThan: Predicate<number> = (val1, val2) => _lt(val1, val2);

/**
 * Returns true if the first argument is greater than the second argument.
 * @see {@link https://lodash.com/docs/4.17.15#gt}
 */
export const isGreaterThan: Predicate<number> = (val1, val2) => _gt(val1, val2);

/**
 * Returns true if the first argument is >= the second argument.
 */
export const isGreaterOrEqual: Predicate<number> = (val1, val2) => isGreaterThan(val1, val2) || isEqual(val1, val2);

/**
 * Returns true if the first argument is greater than zero.
 * @see {@link https://lodash.com/docs/4.17.15#gt}
 */
export const isGreaterThanZero = (val1: number): boolean => _gt(val1, 0);

/**
 * Returns whether the given index is less than the value of the last array index.
 * @param {number} index
 * @param {*} arr
 * @return {Boolean}
 */
export const isLtLastIndex: Predicate<number, unknown[]> = (index, arr) =>
  Boolean(hasLength(arr) && index < arr.length - 1);

/**
 * Given a list of numbers, get the biggest one. If there are multiples of the biggest number, you'll still get that.
 */
export const getBiggestNumberInList = <T extends number>(list: T[]): number | undefined => {
  if (list.length === 0) {
    return undefined;
  }
  return Math.max(...list);
};

/**
 * @deprecated
 * (this is just an extension of isEqual)
 * Checks whether a given option is the selected option.
 */
export const isOptionSelected = isEqual;

/**
 * Checks whether a given value is empty (null, undefined or an empty string)
 */
export const isValueEmpty = (value: unknown, allowEmptyString = false): boolean => {
  if (typeof value === 'object') {
    return _isEmpty(value);
  }

  if (allowEmptyString && value === '') {
    return false;
  }

  return value === '' || value === undefined || value === null;
};

/**
 * Returns the last numeric index of the given array.
 */
export const lastIndexOf = (arr: unknown[]): number => lengthOf(arr) - 1;

/**
 * Returns the last element of the given array
 */
export const lastElementIn = <T = unknown>(arr: T[]): T | undefined => arr[lastIndexOf(arr)];

/**
 * Returns the second last element of the given array
 */
export const secondLastElementIn = <T = unknown>(arr: T[]): T | undefined => arr[lastIndexOf(arr) - 1];

/**
 * Does a .forEach loop over all object keys, returning nothing.
 *
 * @param {ObjectMaybe} obj
 * @param {function(*, number, Array<*>)} callback
 */
// TODO Add types
export const loopOverKeys = (obj, callback) => allKeys(obj).forEach(callback);

/**
 * Maps all object keys, returning empty array if object is undefined/null.
 * @param {ObjectMaybe} obj
 * @param {function(*, number, Array<*>): *} callback
 * @return {Array<*>}
 */
export const mapOverKeys = <T = unknown>(obj: UnknownObject, callback: (k: string) => T): T[] =>
  allKeys(obj).map(callback);

/**
 * Given an array, returns the elements it contains that are not found in the subsequent array arguments.
 * The last argument can (optionally) be a string, to be used as the property for comparison.
 *
 * @example
 * diffArrays(['cat'], ['dog', 'mouse']); // ['cat']
 * diffArrays(['cat'], ['cat', 'mouse']); // []
 * diffArrays([{ id: 'cat' }], [{ id: 'dog' }], 'id'); // [{ id: 'cat' }]
 */
export const diffArrays = <T = unknown>(checkArray: T[], ...rest: T[][]): T[] => {
  const arrayOrProperty = lastElementIn(rest);

  if (!isArray(arrayOrProperty)) {
    const checkAgainstArrays = rest.slice(0, lastIndexOf(rest));
    return _differenceBy(checkArray, ...checkAgainstArrays, arrayOrProperty);
  }

  return _difference(checkArray, ...rest);
};

/**
 * Deep comparison of two arrays of objects by every key
 */
export const differenceWith = <T = unknown, R = T>(arr1: T[], arr2: R[], comparator = _isEqual): (T | R)[] =>
  _differenceWith(arr1, arr2, comparator);

/**
 * Given a collection, a path, and a value, maps over the collection and sets
 * the property at the given path to the given value for each object. Returns an array.
 *
 * @example
 * mapSet([{ id: 'cat' }, { id: 'dog' }], 'isCute', true);
 * // [{ id: 'cat', isCute: true }, { val: 'dog', isCute: true }]
 */
export const mapSet: MapSet = (collection, setPath, setValue) =>
  _map(collection, (obj) => {
    const objCopy = _cloneDeep(obj);
    _set(objCopy, setPath, setValue);
    return objCopy;
  });

/**
 * Given arrays `checkArray` and `removeValues`, returns `checkArray` without any elements that
 * are also found in `removeValues`.
 * - If a third argument is supplied and is a string, will use it as the property for comparison,
 * for each array element.
 * - If a third argument is supplied and is a function, it will be invoked for comparison for
 * each array element.
 *
 * @example
 * arrayWithout(['cat', 'dog', 'mouse'], ['dog', 'mouse']);
 * // ['cat']
 * arrayWithout([{ id: 'cat' }, { id: 'dog' }], [{ id: 'cat' }], 'id');
 * // [{ id: 'dog' }]
 * arrayWithout([{ id: 'cat' }, { id: 'dog' }], [{ id: 'dog' }], (x, y) => x.id === y.id);
 * // [{ id: 'dog' }]
 *
 * @param {*[]} checkArray
 * @param {*[]} removeValues
 * @param {string|Function} [propOrCallback]
 * @return {*[]}
 */
// TODO Add types
export const arrayWithout = (checkArray, removeValues, propOrCallback) => {
  const mutable = [...checkArray];

  if (isFn(propOrCallback)) {
    _pullAllWith(mutable, removeValues, propOrCallback);
  } else if (isString(propOrCallback)) {
    _pullAllBy(mutable, removeValues, propOrCallback);
  } else {
    _pullAll(mutable, removeValues);
  }

  return mutable;
};

/**
 * Given an array and any number of filters, runs the array through each filter, and returns the result.
 * @param {*[]} arr
 * @param {...*} [filters]
 * @returns {*[]}
 */
// TODO Add types
export const narrow = (arr, ...filters) => {
  const [activeFilter, ...rest] = filters;

  if (activeFilter) {
    return narrow(arr.filter(activeFilter), ...rest);
  }

  return arr;
};

/**
 * Returns true if no item in the array is truthy.
 */
export const none = <T = unknown>(array: T[]): boolean => !any(array);

/**
 * Calls reduce on Object.keys() of the given object.
 * @param {Object} obj
 * @param {Function} accumulator
 * @param {*} initial
 * @return {*}
 */
// TODO Add types
export const reduceKeys = (obj, accumulator, initial) => {
  if (!isObject(obj)) {
    return initial;
  }

  return Object.keys(obj).reduce(accumulator, initial);
};

/**
 * Ternary to check `condition`, returning second arg if truthy, and last arg if falsy.
 */
export const ternary: Ternary = (condition, ifTruthy, ifFalsy) => (condition ? ifTruthy : ifFalsy);

/**
 * Ternary to check `condition`, returning the result of calling the second arg if truthy, and the result
 * of calling the last arg if falsy.
 * Use when you want a functional ternary, but don't want return values to be evaluated inline.
 * (More performant than `ternary`)
 * @template T1, T2
 */
export const ternaryCall: TernaryCall = <T = unknown, F = unknown>(
  condition,
  callIfTruthy: () => T,
  callIfFalsy: () => F,
) => (condition ? callIfTruthy() : callIfFalsy());

/**
 * !!NOTE!! [DEV-15462]
 * This is currently a duplicate function. Until it is removed per the above
 * ticket, changes made to it also need to be made in packages/shared.
 *
 * Given any number of strings, returns as a dot-separated path, e.g. 'first.second.third'.
 */
export const toPathString = (...strings: string[]): string =>
  strings.reduce((path, str) => {
    if (isString(str) && hasLength(str)) {
      if (hasLength(path)) {
        return `${path}.${str}`;
      }

      return str;
    }

    return path;
  }, '');

/**
 * Returns an array containing unique values only.
 */
export const uniqueArray = <T = unknown>(arr: T[] = []): T[] => _uniq(arr);

/**
 * Given an array, returns the unique elements it contains based on the property param
 * @return {*[]}
 */
export const uniqueArrayByProperty = <T = unknown>(arr: T[] = [], property = 'id'): T[] => _uniqBy(arr, property);

// ======================
// Compound functional Utils
// ======================

/**
 * _flatMapDeep callback for each item in doesMemberInclude.
 * @param {Item} item
 * @param {StringMaybe} searchKey
 * @param {Function|undefined} defaultXForm
 * @return {any[]|*}
 */
// TODO Add types
export const handleDoesMemberIncludeItem = (item, searchKey, defaultXForm = undefined) => {
  if (isObject(item)) {
    if (searchKey) {
      return item[searchKey];
    }

    return Object.values(item);
  }

  return defaultXForm?.(item) ?? item;
};

/**
 * Returns whether the given collection (object, array, or array of objects)
 * contains the given value (keys will not be matched against, but values will be).
 */
export const doesMemberInclude = <T = unknown, R = T>(
  collection: T[],
  value: R,
  options: { searchIndex?: number; searchKey?: string } = {},
): boolean => {
  const { searchIndex, searchKey } = options;

  let searchValues;

  if (isArray(collection)) {
    searchValues = _flatMapDeep(collection, (item) => handleDoesMemberIncludeItem(item, searchKey));
  } else {
    searchValues = _flatMapDeep(collection, (item) => handleDoesMemberIncludeItem(item, searchKey, Object.values));
  }

  return isIncluded(searchValues, value, searchIndex);
};

// ======================
// Operators
// ======================

/**
 * Does an "and" comparison on all arguments, ONLY until it reaches one that
 * is falsy, and returns the result.
 * @example
 * // as written with && operators
 * 'hello' && 1 && false && undefined // > false
 * // as written with and()
 * and('hello', 1, false, undefined) // > false
 */
// TODO Add types
export const and: Operation = (firstVal, ...rest) => firstVal && (hasLength(rest) ? and(...rest) : firstVal);

/**
 * Does an "or" comparison on all arguments, ONLY until it reaches one that
 * is truthy (no unnecessary recursion), and returns the result.
 * @example
 * // as written with || operators
 * undefined || false || 'hello' || 1 // > 'hello'
 * // as written with or()
 * or(undefined, false, 'hello', 1) // > 'hello'
 */
export const or: Operation = (firstVal, ...rest) => firstVal || (hasLength(rest) ? or(...rest) : firstVal);

// When TagMultiSelect is used as part of a field it will default to passing onBlur as reduxForm's input.onBlur
// This in turn causes a reduxForm bug where the onBlur de-registers the field: https://github.com/redux-form/redux-form/issues/2768
export const noop: () => void = () => {};

/**
 * Checks if given index is equal to negative 1
 */
export const isIndexNotFound = (index: number): boolean => isEqual(index, -1);

/**
 * Checks if given index is equal to negative 1 (not found) returns defaultIndex if so.
 */
export const defaultToValueIfNotFound = (index: number, defaultIndex = 0): number =>
  ternary<number, number>(isIndexNotFound(index), defaultIndex, index);

/**
 * when passed a desired value and list of valid values, if it is part of the valid set of values, it will
 * return that value, otherwise it will return the default value.
 * example usage: getValidValueOrDefault(paramsArr[2], PAYMENTS_LIST_FILTERS, PAYMENTS_LIST_FILTERS.ALL),
 */
export const getValidValueOrDefault = <T>(value: T, validValues: Record<string, T>, defaultValue: T): T =>
  allValues(validValues).includes(value) ? value : defaultValue;
