import { containerComponents } from './containerComponents';
import { fieldComponents } from './fieldComponents';
import { predefinedComponents } from './predefinedComponents';
import { predefinedSectionComponents, sectionComponents } from './sectionComponents';
import { tabComponents } from './tabComponents';
import { tableComponents } from './tableComponents';

export const tableUi = {
  containerComponents,
  fieldComponents,
  tabComponents,
  tableComponents,
  predefinedComponents,
  predefinedSectionComponents,
  sectionComponents,
};
