import React from 'react';
import { Route } from 'react-router-dom';

import { BrandWrapperV2 } from 'components';

import { RESET_PASSWORD, RESET_PASSWORD_COMPLETE } from 'constants/routes';

import { asPath } from 'helpers/routeHelpers';

import NotAuthenticated from 'modules/app/NotAuthenticatedRoute';
import SwitchOr404 from 'modules/app/SwitchOr404';

import { ResetPasswordComplete, ResetPasswordForm } from './components';

/**
 * Switch between ResetPasswordForm and ResetPasswordComplete
 */
const ResetPassword: React.VFC = () => (
  <BrandWrapperV2>
    <div className="branded--contents remove-margin">
      <SwitchOr404>
        <NotAuthenticated component={ResetPasswordForm} exact path={asPath(RESET_PASSWORD)} />
        <Route component={ResetPasswordComplete} exact path={asPath(RESET_PASSWORD_COMPLETE)} />
      </SwitchOr404>
    </div>
  </BrandWrapperV2>
);

export default ResetPassword;
