import { PaymentDeliveryOptionType } from '@routable/shared';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isKindPayable } from 'helpers/items';
import { isDateScheduledTypeToday } from 'helpers/temporal';

import type { BillingCodeData } from 'interfaces/billing';

import { fundingInfoBalanceIdSelector } from 'queries/fundingCompoundSelectors';

import { fundingAccountsByIdSelector } from 'selectors/fundingSelectors';

import { formatValueToCamelCase } from 'services/api/formatHelpers';

import { APPLICABLE_CUTOFF_WARNING_BILLING_CODES } from './constants';
import type {
  AccumulatedBillingCodes,
  UseBulkActionsCutoffWarningsResult,
  UseBulkActionsCutoffWarningsOptions,
  UseSingleItemCutoffWarningOptions,
} from './useItemCutoffWarning.types';

/**
 * This hook returns a flag indicating whether the cutoff warning is shown,
 * and the current billing data selected, which has a cutoff value as a string:
 * This is the hour of each day when the warning starts being shown.
 */
export const useSingleItemCutoffWarning = ({
  dateScheduledType,
  currentBillingData,
}: UseSingleItemCutoffWarningOptions): boolean => {
  const [hasPassedCutoffTime, setHasPassedCutoffTime] = useState<boolean>(false);

  useEffect(() => {
    setHasPassedCutoffTime(false);

    const timer = setInterval(() => {
      const now = dayjs.utc();
      const cutoff = dayjs.utc(currentBillingData?.cutoff, 'HH:mm:ss');

      setHasPassedCutoffTime(cutoff.isSameOrBefore(now));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [currentBillingData]);

  const isCurrentBillingDataWhitelisted = APPLICABLE_CUTOFF_WARNING_BILLING_CODES.includes(
    currentBillingData?.paymentDeliveryOption,
  );

  const showAfterCutoffTimeWarning =
    hasPassedCutoffTime &&
    isDateScheduledTypeToday(dateScheduledType) &&
    !!currentBillingData &&
    isCurrentBillingDataWhitelisted;

  return showAfterCutoffTimeWarning;
};

/**
 * This hook returns an object containing the billing codes used to determine weather to show
 * or not cutoff warnings for bulk actions, and the count of items having passed cutoff
 * for these specific billing codes.
 */
export const useBulkActionsCutoffWarnings = ({
  items,
  billingDataByCode,
  dateScheduledType,
}: UseBulkActionsCutoffWarningsOptions): UseBulkActionsCutoffWarningsResult => {
  const fundingAccounts = useSelector(fundingAccountsByIdSelector);
  const balanceId = useSelector(fundingInfoBalanceIdSelector);
  const hasPayables = items.some(({ kind }) => isKindPayable(kind));

  /**
   * Early return: if dateScheduledType is NOT today, then do not process the items for getting
   * cutoff times, since scheduling sends in the future and cutoff times do not matter.
   */
  if (!isDateScheduledTypeToday(dateScheduledType) || !hasPayables) {
    return {
      billingData: {},
      cutoffData: { sameDayCount: 0, nextDayCount: 0 },
    };
  }

  const accumulatedBillingCodes: AccumulatedBillingCodes = Object.values(billingDataByCode).reduce(
    (acc: AccumulatedBillingCodes, billingCode: BillingCodeData) => {
      const { paymentDeliveryOption, paymentSource } = billingCode;

      if (APPLICABLE_CUTOFF_WARNING_BILLING_CODES.includes(paymentDeliveryOption)) {
        const snake = `${paymentDeliveryOption}_${paymentSource}`;
        const key = formatValueToCamelCase(snake);

        acc[key] = billingCode;
      }

      return acc;
    },
    {},
  );

  const {
    achSameDayBalance: sameDayBalance,
    achSameDayBank: sameDayBank,
    achNextDayBalance: nextDayBalance,
    achNextDayBank: nextDayBank,
  } = accumulatedBillingCodes;

  const counts = {
    [PaymentDeliveryOptionType.ACH_SAME_DAY]: 0,
    [PaymentDeliveryOptionType.ACH_NEXT_DAY]: 0,
  };

  items.forEach((item) => {
    if (APPLICABLE_CUTOFF_WARNING_BILLING_CODES.includes(item.paymentDeliveryOption)) {
      const fundingAccount = fundingAccounts[item.fundingAccount];
      const isBalance = fundingAccount?.balance === balanceId;

      const cutoffTimes = {
        [PaymentDeliveryOptionType.ACH_SAME_DAY]: isBalance ? sameDayBalance.cutoff : sameDayBank.cutoff,
        [PaymentDeliveryOptionType.ACH_NEXT_DAY]: isBalance ? nextDayBalance.cutoff : nextDayBank.cutoff,
      };

      const cutoff = cutoffTimes[item.paymentDeliveryOption];

      const now = dayjs.utc();
      const cutoffTime = dayjs.utc(cutoff, 'HH:mm:ss');

      if (cutoffTime.isSameOrBefore(now)) {
        counts[item.paymentDeliveryOption] += 1;
      }
    }
  });

  return {
    billingData: { sameDayBalance, sameDayBank, nextDayBalance, nextDayBank },
    cutoffData: {
      sameDayCount: counts[PaymentDeliveryOptionType.ACH_SAME_DAY],
      nextDayCount: counts[PaymentDeliveryOptionType.ACH_NEXT_DAY],
    },
  };
};
