/**
 * Bulk action types that are supported
 * @enum {string}
 */
export enum BulkActionTypes {
  APPROVE = 'Approve',
  APPROVE_AND_SEND = 'Approve and send',
  EDIT_SEND_DATE = 'Edit send date',
  SEND = 'Send',
}

/**
 * Bulk action summary steps
 * @enum {string}
 */
export enum BulkActionSummarySteps {
  INITIAL = 'initial',
  LOADING = 'loading',
  CONFIRMATION = 'confirmation',
}
