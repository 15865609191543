import classNames from 'classnames';
import React from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';

import { TooltipMUIConditionalWrapper } from 'components';

import { callWithArgsIfIsFn, isObject, lengthOf, valueOrDefault } from 'helpers/utility';

import { TableCell, TablePagination, TableHeaderCell, TableRow } from './components';
import { tableDefaultProps, tablePropTypes } from './Table.propTypes';
import TableContext from './TableContext';

import './Table.scss';

/**
 * TODO: Test this component [https://warrenpay.atlassian.net/browse/DEV-2075]
 * Table component based on react-table
 * @param {ComponentProps} props
 * @param {Node} [props.children]
 * @param {String} [props.className]
 * @param {Object[]} props.columns
 * @param {boolean} [props.compact]
 * @param {Object[]} props.data
 * @param {String} [props.dataTestId]
 * @param {Function} [props.getCellProps]
 * @param {Function} [props.getRowProps]
 * @param {Number} [props.pageSize]
 * @param {Number} [props.hiddenColumns]
 * @returns {StatelessComponent}
 */
const Table = ({
  children,
  className,
  columns,
  compact,
  data,
  dataTestId,
  getCellProps,
  getPaginationDetails,
  getRowProps,
  hasPagination,
  pageSize: initialPageSize,
  hiddenColumns,
}) => {
  const [rowData, setRowData] = React.useState([]);
  // We need memoized columns and data, otherwise the whole table will reload
  const memoizedColumns = React.useMemo(() => columns, [columns]);

  React.useEffect(() => {
    setRowData(data?.filter((item) => item !== undefined) || []);
  }, [data]);

  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    prepareRow,
    previousPage,
    rows,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: memoizedColumns,
      data: rowData,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        hiddenColumns,
      },
    },
    useSortBy,
    usePagination,
  );

  const totalResults = React.useMemo(() => lengthOf(rows), [rows]);

  React.useEffect(() => {
    setPageSize(initialPageSize);
  }, [initialPageSize, setPageSize]);

  const onPageSizeChange = (size) => {
    setPageSize(size);
    gotoPage(0);
  };

  const onGoToPage = (destinationPage) => {
    switch (destinationPage) {
      case 'PREV':
        gotoPage(pageIndex - 1);
        break;
      case 'NEXT':
        gotoPage(pageIndex + 1);
        break;
      default:
        gotoPage(destinationPage - 1);
    }
  };

  React.useEffect(() => {
    callWithArgsIfIsFn(getPaginationDetails, {
      canNextPage,
      canPreviousPage,
      currentPage: pageIndex + 1,
      nextPage,
      pageSize,
      previousPage,
      totalResults,
    });

    // eslint-disable-next-line
  }, [getPaginationDetails, pageIndex, pageSize]);
  const rowsData = valueOrDefault(page, rows);

  return (
    <TableContext.Provider
      value={{
        compact: !!compact,
      }}
    >
      <table
        {...getTableProps()}
        className={classNames('table-v2 horizontal-scroll-group', {
          'has-children': !!children,
          compact: !!compact,
          [className]: !!className,
        })}
        data-testid={dataTestId}
      >
        <thead>
          {headerGroups.map((headerGroup, idx) => (
            <tr key={`header-group-${idx + 1}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, headerGroupIdx) => (
                <TableHeaderCell
                  key={`header-cell-${headerGroupIdx + 1}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  {...column.props}
                  className={classNames(`cell-width--${column.width}`, {
                    [column.className]: !!column.className,
                  })}
                >
                  {column.render('Header')}
                </TableHeaderCell>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rowsData.map((row) => {
            prepareRow(row);
            const rowProps = getRowProps(row);

            return (
              <TooltipMUIConditionalWrapper key={row.id} tooltipProps={rowProps?.tooltipProps}>
                <TableRow {...row.getRowProps(rowProps)} tabIndex="0">
                  {row.cells.map((cell, idx) => {
                    let cellProps: {
                      isCellDisabled?: boolean;
                    } = {};
                    if (isObject(cell?.value)) {
                      cellProps = cell.value;
                    }

                    const calculatedCellProps = cell.getCellProps(getCellProps(cell));

                    const { areAnyAttachmentsProcessing, doNotUseCellDisabledStyle, isDisabledByPermissions } =
                      calculatedCellProps;

                    return (
                      <TableCell
                        key={`table-cell-${idx + 1}}`}
                        {...calculatedCellProps}
                        {...cellProps}
                        className={classNames(`cell-width--${cell.column.width}`, {
                          [cell.column.className]: !!cell.column.className,
                          'cell-disabled':
                            (areAnyAttachmentsProcessing && !doNotUseCellDisabledStyle) || isDisabledByPermissions,
                          'attachments-cell-disabled': areAnyAttachmentsProcessing,
                        })}
                      >
                        {cell.render('Cell', {
                          ...getCellProps(),
                          tooltipProps: rowProps?.tooltipProps,
                        })}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TooltipMUIConditionalWrapper>
            );
          })}
        </tbody>

        {children}

        {hasPagination && (
          <tfoot>
            <tr className="block">
              <td className="block">
                <TablePagination
                  count={totalResults}
                  onGoToPage={onGoToPage}
                  onPageSizeChange={onPageSizeChange}
                  page={pageIndex + 1}
                  pages={pageCount}
                  pageSize={pageSize}
                />
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </TableContext.Provider>
  );
};

Table.propTypes = tablePropTypes;

Table.defaultProps = tableDefaultProps;

export default React.memo(Table);
