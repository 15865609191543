import React from 'react';

import BaseHint from 'components/hintTypes/BaseHint';
import { RoutableFeesLink } from 'components/linkTypes';
import { WhiteSpace } from 'components/text';

import { Intent } from 'constants/ui';

import { getFundingProviderMemoTransactionFeeText } from 'helpers/fundingProviderMemo';
import helpDocs from 'helpers/helpDocs';

import { AddendaRecordTransactionCostHintProps } from './FundingProviderMemoTransactionCostHint.types';

/**
 * Hint showing the additional cost per transaction for addenda records.
 * @see {FundingProviderMemoModalBody}
 */
const FundingProviderMemoTransactionCostHint: React.FC<AddendaRecordTransactionCostHintProps> = ({ billingData }) => (
  <BaseHint
    intent={Intent.INFO}
    title={
      <>
        {getFundingProviderMemoTransactionFeeText(billingData)}

        <WhiteSpace />

        <RoutableFeesLink className="primary bold" href={helpDocs.UNDERSTANDING_ROUTABLE_FEES} />
      </>
    }
    titleProps={{ marginBottom: false }}
  />
);

export default FundingProviderMemoTransactionCostHint;
