import React from 'react';

import { IconNames } from 'components/icon';

import { getDisplayName } from 'helpers/hoc';
import { or } from 'helpers/utility';

import useClipboard from 'hooks/useClipboard';

import PaymentMethodDetailItemAction from '../../PaymentMethodDetailItemAction';
import { PaymentMethodDetailAction } from '../enums';
import type { PaymentMethodDetailItemProps } from '../PaymentMethodDetailItem.types';

/**
 * HOC that is meant to add clipboard functionality and icon to PaymentMethodDetailItem
 */
const withCopyAction = (
  PaymentMethodDetailItem: React.FC<PaymentMethodDetailItemProps>,
): React.FC<PaymentMethodDetailItemProps> => {
  const Component = (props: PaymentMethodDetailItemProps) => {
    const newProps = { ...props };
    const { actions, actionsElements = [], clipboardValue, label, revealedValue, value } = props;
    const isCopyable = actions.includes(PaymentMethodDetailAction.COPY);

    const ref = useClipboard({
      copyValue: or<any>(revealedValue, clipboardValue, value),
    });

    if (isCopyable) {
      newProps.actionsElements = [
        ...actionsElements,
        <PaymentMethodDetailItemAction
          icon={IconNames.DUPLICATE}
          key="payment-method-detail-item"
          ref={ref}
          tooltipTitle={`Copy ${label}`}
        />,
      ];
    }

    return <PaymentMethodDetailItem {...newProps} />;
  };

  Component.displayName = `${getDisplayName(PaymentMethodDetailItem)}WithCopyAction`;
  return Component;
};

export default withCopyAction;
