import { useMutation } from '@tanstack/react-query';
import { queryContext } from 'milton/components';

import { purchaseOrderService } from '../purchaseOrder.service';

export const useDiscrepancies = () => {
  return useMutation({
    context: queryContext,
    mutationFn: async (input: unknown) => purchaseOrderService.calculateDiscrepancies(input),
    mutationKey: ['purchase_orders', 'discrepancies'],
  });
};
