import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FlexRowProps } from './FlexRow.types';

const FlexRow = React.forwardRef<HTMLDivElement, FlexRowProps>(
  ({ children, className, dataFullStory, stackOnMobile, style, ...rest }, ref) => (
    <div
      className={classNames('flex-row', className, {
        'stack-on-mobile': stackOnMobile,
      })}
      data-fullstory={dataFullStory}
      ref={ref}
      style={style}
      {...rest}
    >
      {children}
    </div>
  ),
);

FlexRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  stackOnMobile: PropTypes.bool,
  style: PropTypes.shape({}),
};

FlexRow.defaultProps = {
  children: undefined,
  className: undefined,
  stackOnMobile: true,
  style: {},
};

FlexRow.displayName = 'FlexRow';

export default FlexRow;
