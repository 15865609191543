import { LINE_ITEM_EMAIL_DOCUMENT } from 'constants/lineItems';

import { downloadFileFromURL } from 'helpers/fileHelpers';
import { ATTACHMENT_DEFAULT_FILENAME } from 'helpers/ocr/constants';

import { Attachment, AttachmentWithPreview } from 'interfaces/attachment';
import { BillAttachment } from 'interfaces/billAttachment';

export const AttachmentDisplayFileName = {
  [ATTACHMENT_DEFAULT_FILENAME]: LINE_ITEM_EMAIL_DOCUMENT,
};

export const onFileSelectOptionDownloadClick = (billAttachment: BillAttachment): Promise<void> =>
  downloadFileFromURL(billAttachment.file, billAttachment.originalFilename);

export const isPrimary = (attachment: Attachment, primaryAttachmentId: string, attachments: Attachment[]): boolean =>
  (attachment.id && primaryAttachmentId && attachment.id === primaryAttachmentId) ||
  (attachments.length === 1 && attachment.filename === ATTACHMENT_DEFAULT_FILENAME);

export const createTransformAttachmentsToBillAttachment =
  (primaryAttachmentId: string, selectedBillIndex: number) =>
  (attachment: AttachmentWithPreview, index: number, attachments: AttachmentWithPreview[]): BillAttachment => ({
    ...attachment,
    index,
    isRemote: Boolean(attachment.id),
    originalFilename: attachment.filename,
    fileName: AttachmentDisplayFileName[attachment.filename] || attachment.filename,
    label: attachment.filename,
    value: attachment.id || attachment.filename,
    status: attachment.latestAnnotationStatus || 'error',
    preview: attachment.file,
    primary: isPrimary(attachment, primaryAttachmentId, attachments),
    selected: index === selectedBillIndex,
    isDeleting: false,
  });

export const getSelectedBillIndexInitalValue = (billAttachments: BillAttachment[]): number => {
  // If there aren't any bills don't display anything
  if (!billAttachments.length) {
    return -1;
  }

  // Display primary attachment if it exists
  const primaryAttachmentIndex = billAttachments.findIndex((entry) => entry.primary);
  if (primaryAttachmentIndex > -1) {
    return primaryAttachmentIndex;
  }

  // Otherwise, display first attachment
  return 0;
};
