import classNames from 'classnames';
import React, { useState } from 'react';

import FormFieldErrors from 'components/error/components/FormFieldErrors';
import * as inputHelpers from 'components/form/helpers/inputs';
import * as commonProps from 'components/form/NumericInput/commonProps';
import NumericInput from 'components/form/NumericInput/NumericInput';
import { InputAddon, InputLabel, InputPlaceholder } from 'components/input';

import { getFieldErrors } from 'helpers/errors';

import type { NumericInputWrapperProps } from './NumericInputWrapper.types';

/**
 * Renders a wrapped instance of react-number-format's input field.
 * Special handling of change callbacks:
 * - Uses the regular onChange handler if given (for simpler usage with redux-form's Field)
 * - Uses RNF's onValueChange handler if given, passing in the DOM node for validations (stored as
 *   inputRef) and the values object from RNF ({ formattedValue: string, value: string, floatValue: number })
 */
const InputWrapper = (props: NumericInputWrapperProps): JSX.Element => {
  const {
    addon,
    children,
    contentBefore,
    errors,
    hasErrors: hasErrorsProp,
    hideLabel,
    inputClassName,
    isRequired,
    label,
    name,
    placeholder,
    showValidationIcon,
    value,
  } = props;

  const [inputRef, setInputRef] = useState(null);

  const handleChange = (event) => {
    const { onChange } = props;

    if (onChange) {
      onChange(event);
    }
  };

  const handleValueChange = (values) => {
    const { onValueChange } = props;

    if (onValueChange) {
      onValueChange(inputRef, values);
    }
  };

  const hasErrors = hasErrorsProp || getFieldErrors(errors, name);
  const { widthClasses, otherClasses } = inputHelpers.getInputClasses(props, { hasErrors });

  return (
    <div className={classNames(widthClasses)}>
      <div className={classNames('input-container', otherClasses)}>
        {Boolean(contentBefore) && contentBefore}

        <InputLabel hideLabel={hideLabel} label={label} name={name} placeholder={placeholder} value={value} />
        <NumericInput
          {...props}
          className={inputClassName}
          fieldErrors={errors}
          onChange={handleChange}
          onValue={handleValueChange}
          required={isRequired}
          setInputRef={setInputRef}
        />
        <InputPlaceholder isRequired={isRequired} placeholder={placeholder} value={value} />

        {children}

        <InputAddon addon={addon} hasErrors={hasErrors} showValidationIcon={showValidationIcon} value={value} />
      </div>
      <FormFieldErrors errors={errors} fieldName={name} />
    </div>
  );
};

InputWrapper.defaultProps = {
  ...commonProps.defaultProps,
  addon: undefined,
  errors: undefined,
  hasErrors: undefined,
  inputClassName: '',
  onValueChange: null,
  showValidationIcon: undefined,
  value: undefined,
};

export default InputWrapper;
