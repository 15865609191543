import React from 'react';

import { ListEmpty, Text } from 'components';

import { TABLE_EMPTY_STATE_WITHOUT_BORDERS } from 'constants/images';
import { typography } from 'constants/styles';

import { CompanyContactsTableEmptyStateProps } from './CompanyContactsTableEmptyState.types';

/**
 * Empty state for the company contacts table
 */
const CompanyContactsTableEmptyState: React.FC<CompanyContactsTableEmptyStateProps> = ({ content }) => (
  <ListEmpty
    content={content}
    image={TABLE_EMPTY_STATE_WITHOUT_BORDERS}
    message={
      <Text.Regular
        color={typography.TextColor.GREY_XX_DARK}
        lineHeight={typography.TextLineHeight.TWENTY_FOUR}
        size={typography.TextSize.LEVEL_450}
      >
        This company doesn&apos;t have contacts added yet
      </Text.Regular>
    }
  />
);

export default CompanyContactsTableEmptyState;
