import React from 'react';

import { CurrencyAmountTextPlain, Text } from 'components';

import { ItemPaymentStatusText } from 'constants/item';
import { typography } from 'constants/styles';

import { isKindReceivable } from 'helpers/items';

import { ExternalBlock, ExternalBlockItem } from 'modules/external/ExternalBlocksSection';

import { ExternalReceiptPaymentHeaderProps } from './ExternalReceiptPaymentHeader.types';

import './ExternalReceiptPaymentHeader.scss';

/**
 * Component rendering External Receipt Payment Header section
 */
const ExternalReceiptPaymentHeader: React.FC<ExternalReceiptPaymentHeaderProps> = ({
  itemKind,
  amount,
  currencyCode,
}) => {
  const suffix = isKindReceivable(itemKind) ? ItemPaymentStatusText.DUE : ItemPaymentStatusText.PAID;

  return (
    <ExternalBlock className="external-receipt-payment-header">
      <ExternalBlockItem>
        <Text.Regular color={typography.TextColor.GREY_X_DARK} size={typography.TextSize.LEVEL_200}>
          {`Amount ${suffix}`}
        </Text.Regular>

        <Text.Regular
          className="external-receipt-payment-header__price"
          color={typography.TextColor.BLACK}
          size={typography.TextSize.UNSET}
        >
          <CurrencyAmountTextPlain amount={amount} currencyCode={currencyCode} />
        </Text.Regular>
      </ExternalBlockItem>
    </ExternalBlock>
  );
};

export default ExternalReceiptPaymentHeader;
