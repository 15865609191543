import { LedgerSides } from 'constants/ledger';
import { FundingAccount } from 'constants/relationships';

import { REST } from 'helpers/api';

import { FetchServiceResponse } from 'interfaces/fetchService';

import { FetchService } from 'services';
import { payloadToJSONAPI } from 'services/api/formatHelpers';
import {
  addFundingAccountInternationalBankManualEndpoint,
  addInternationalBankAccountEndpoint,
  fundingAccountsEndpoint,
  fundingSupportedCountriesEndpoint,
  getFundingAccountEndpoint,
} from 'services/api/fundingEndpoints';
import { getIntegrationLedgerMatchingEndpoint } from 'services/api/integrationEndpoints';

import { InternationalBankAccountPayloadType, InternationalFundingAccountPayloadType } from './funding.types';

/**
 * Create a single international funding account.
 *
 * @param {InternationalFundingAccountPayloadType} submitPayload
 * @param {OptionsArg} [options={}]
 * @returns {Promise<*>}
 */
export const createInternationalFundingAccount = async (
  submitPayload: InternationalFundingAccountPayloadType,
  options = {},
): Promise<FetchServiceResponse> =>
  REST.post({
    endpoint: addFundingAccountInternationalBankManualEndpoint,
    requireAuth: true,
    setRequester: true,
    payload: payloadToJSONAPI(submitPayload, FundingAccount),
    ...options,
  });

/**
 * Create a single international bank account.
 *
 * @param {InternationalFundingAccountPayloadType} submitPayload
 * @param {OptionsArg} [options={}]
 * @returns {Promise<*>}
 */
export const createInternationalBankAccount = async (
  submitPayload: InternationalBankAccountPayloadType,
  options = {},
): Promise<FetchServiceResponse> =>
  REST.post({
    endpoint: addInternationalBankAccountEndpoint(submitPayload.meta.partnershipId),
    requireAuth: true,
    setRequester: true,
    payload: payloadToJSONAPI(submitPayload, FundingAccount, 'fundingInfoAddress'),
    ...options,
  });

/**
 * Fetch a single funding account's data.
 * @param {string} accountId
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchSingleFundingAccount = async (accountId: string, options = {}): Promise<FetchServiceResponse> => {
  const config = {
    ...options,
    endpoint: getFundingAccountEndpoint(accountId),
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Fetch funding accounts data.
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchFundingAccounts = async (options = {}): Promise<FetchServiceResponse> => {
  const config = {
    ...options,
    endpoint: fundingAccountsEndpoint,
    method: 'GET',
    requireAuth: true,
    pageSize: 100,
  };
  return FetchService.request(config);
};

/**
 * Fetch funding accounts data.
 * @param {string} integrationConfigId
 * @param {Object} filter
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchFundingAccountsForClearingAccountsMatching = async (
  integrationConfigId: string,
  filter: { [queryStringKey: string]: string },
  options = {},
): Promise<FetchServiceResponse> => {
  const endpoint = getIntegrationLedgerMatchingEndpoint(integrationConfigId, 'clearing_accounts', {
    ...filter,
    side: LedgerSides.PLATFORM,
  });
  const config = {
    ...options,
    endpoint,
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

/**
 * Fetch unmatched clearing accounts data for ledger.
 * @param {string} integrationConfigId
 * @param {Object} filter
 * @param {ObjectMaybe} options
 * @return {Promise<*>}
 */
export const fetchUnmatchedLedgerClearingAccounts = async (
  integrationConfigId: string,
  filter: { [queryStringKey: string]: string },
  options = {},
): Promise<FetchServiceResponse> => {
  const endpoint = getIntegrationLedgerMatchingEndpoint(integrationConfigId, 'clearing_accounts', {
    ...filter,
    side: LedgerSides.LEDGER,
  });
  const config = {
    ...options,
    endpoint,
    method: 'GET',
    requireAuth: true,
  };
  return FetchService.request(config);
};

export const fetchSupportedCountries = async (options = {}): Promise<FetchServiceResponse> =>
  REST.get({
    endpoint: fundingSupportedCountriesEndpoint,
    requireAuth: true,
    ...options,
  });
