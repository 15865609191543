import React from 'react';
import { useSelector } from 'react-redux';

import { Dropzone } from 'complexComponents';

import Spinner from 'components/buttonV2/components/Spinner';

import { Intent } from 'constants/ui';

import { documentsIsPosting } from 'selectors/documentsSelectors';

import FilesList from './FilesList';
import FileError from './FilesList/FileError';
import { File } from './FilesList/FileItem/FileItem';
import { Container, UploadFileHint } from './UploadFile.styles';
import { UploadFileProps } from './UploadFile.types';

const UploadFile: React.FC<UploadFileProps> = ({
  fields,
  hintText,
  remove,
  addFile,
  supportText,
  accept,
  helpMessage,
  errorMessage,
  isDisabled,
  isFetching,
  showDropzone = true,
}) => {
  const postingDocs = useSelector(documentsIsPosting);

  const { isPosting, file: documentBeingUploaded = {} } = postingDocs;

  const handleAddFile = (file) => {
    addFile(file, () => fields.push(file));
  };

  return (
    <Container>
      {hintText && <UploadFileHint intent={Intent.INFO} title={hintText} />}
      {showDropzone && (
        <Dropzone
          accept={accept}
          addFile={handleAddFile}
          errorMessage={errorMessage}
          helpMessage={helpMessage}
          isDisabled={isDisabled}
          supportText={supportText}
        />
      )}
      {/* documents added to the dropzone and in form state */}
      {!isFetching && <FilesList files={fields} isDisabled={isDisabled} remove={remove} />}

      {/* the document being uploaded state */}
      {isPosting && (
        <File
          input={{
            value: documentBeingUploaded,
          }}
          isUploading
        />
      )}

      {isFetching && <Spinner useBlueVariant />}

      {/* documents already sent to our API and to Dwolla */}
      {fields.map((field, index) => {
        const key = `${field}_${index}_error`;
        return <FileError file={field} key={key} />;
      })}
    </Container>
  );
};

export default UploadFile;
