export enum AuthActionType {
  CLEAR_LOGIN_ERROR_STATE = 'RESET_LOGIN_ERROR',

  CLEAR_RESET_PASSWORD_STATE = 'CLEAR_RESET_PASSWORD_STATE',

  SUBMIT_CHANGE_PASSWORD = 'SUBMIT_CHANGE_PASSWORD',
  SUBMIT_CHANGE_PASSWORD_FAILURE = 'SUBMIT_CHANGE_PASSWORD_FAILURE',
  SUBMIT_CHANGE_PASSWORD_REQUEST = 'SUBMIT_CHANGE_PASSWORD_REQUEST',
  SUBMIT_CHANGE_PASSWORD_SUCCESS = 'SUBMIT_CHANGE_PASSWORD_SUCCESS',

  SUBMIT_CONVERT_EXTERNAL = 'SUBMIT_CONVERT_EXTERNAL',

  SUBMIT_CREATE_PASSWORD = 'SUBMIT_CREATE_PASSWORD',

  SUBMIT_LOGIN = 'SUBMIT_LOGIN',

  SUBMIT_LOGOUT_REQUEST = 'SUBMIT_LOGOUT_REQUEST',
  SUBMIT_LOGOUT_FAILURE = 'SUBMIT_LOGOUT_FAILURE',
  SUBMIT_LOGOUT_COMPLETE = 'SUBMIT_LOGOUT_COMPLETE',
  SUBMIT_LOGOUT_CLEANUP = 'SUBMIT_LOGOUT_CLEANUP',

  SUBMIT_RESET_PASSWORD = 'SUBMIT_RESET_PASSWORD',

  SUBMIT_SSO_LOGIN = 'SUBMIT_SSO_LOGIN',
  SUBMIT_SSO_HANDLE_REDIRECT = 'SUBMIT_SSO_HANDLE_REDIRECT',
}
