import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderInput } from 'components';

import * as propTypes from 'constants/propTypes';

import { useMemoizedConditionalValidate } from 'hooks';

import { useDynamicFieldRequiredValidators, useTableCellFieldIsDisabled } from 'modules/table/hooks';

/**
 * Renders a text input field to be rendered in a table cell.
 * @param {ComponentProps} props
 * @param {Function} props.getIsDisabled
 * @param {Function} props.getIsRequired
 * @param {Function} props.getRowValues
 * @param {string} props.name
 * @param {number} props.maxLength
 * @param {RestOfProps} props.rest
 * @param {Function|Function[]} props.validate
 * @returns {StatelessComponent}
 */
const TextBlockField = (props) => {
  const { formValues, name, maxLength } = props;

  const { isRequired, validators } = useDynamicFieldRequiredValidators(props);
  const cachedValidators = useMemoizedConditionalValidate(!formValues.ui?.blockDetailsSections, validators);
  const isDisabled = useTableCellFieldIsDisabled(props);

  return (
    <Field
      className="ledger-form-field"
      component={ReduxFormFieldRenderInput}
      isDebounced
      isDisabled={isDisabled}
      isRequired={isRequired}
      maxLength={maxLength}
      name={name}
      props={{ hideLabel: true }}
      type="text"
      validate={cachedValidators}
    />
  );
};

TextBlockField.propTypes = {
  ...propTypes.tableTextFieldPropTypes,
  maxLength: PropTypes.number,
};

TextBlockField.defaultProps = {
  ...propTypes.tableTextFieldDefaultProps,
  maxLength: undefined,
};

export default TextBlockField;
