/* eslint-disable no-console */
import { Transport } from 'milton/logging';

import { RoutableLogMessage } from '../systemLogger.types';

export const createConsoleTransport = <T extends RoutableLogMessage>(): Transport<T> => ({
  name: 'console',
  transport: async (messages) => {
    messages.forEach(({ level, message, ...rest }) => {
      if (Object.keys(rest).length) {
        console.groupCollapsed(level, message);
        Object.entries(rest).forEach(([key, value]) => console.log(key, value));
        console.groupEnd();
      } else {
        console.log(level, message);
      }
    });
  },
});
