import React from 'react';

import { ContactTitleWithSubtitle } from 'complexComponents/ContactTitleWithSubtitle';

import { ContactsInteractionListViewType } from 'constants/ui';

import { isContactsInteractionListViewTypeThread } from 'helpers/ui';

import { useContactsInteractionListViewContext, useExistingItemContext } from 'hooks';

import type { Item } from 'interfaces/item';

import { ContactListItemTitleTypeThread } from './components';
import type { ContactListItemTitleProps } from './ContactListItemTitle.types';

/**
 * The title section of a ContactListItem.
 * The returned component is dependent on the value of the view (which is from context)
 */
const ContactListItemTitle = ({ contact }: ContactListItemTitleProps): JSX.Element => {
  const item: Item = useExistingItemContext();
  const view: ValueOf<typeof ContactsInteractionListViewType> = useContactsInteractionListViewContext();

  if (isContactsInteractionListViewTypeThread(view)) {
    return <ContactListItemTitleTypeThread dataFullStory item={item} partnershipMember={contact} />;
  }

  return <ContactTitleWithSubtitle dataFullStory isContactListItem partnershipMember={contact} />;
};

export default ContactListItemTitle;
