import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchMembershipConfigRequest, fetchSingleMembershipRequest } from 'actions/memberships';

import { currentMembershipOrMembershipFromPartnershipRequestSelector } from 'queries/membershipCompoundSelectors';

import { currentUserSelector } from 'selectors/currentUserSelectors';

import { IntercomInternal } from './IntercomInternal.component';

/**
 * Initializes Intercom which is used for Banners, Marketing, App Tours etc.
 */
export const IntercomWidget = () => {
  const currentMembership = useSelector(currentMembershipOrMembershipFromPartnershipRequestSelector);
  const currentUser = useSelector(currentUserSelector);
  const dispatch = useDispatch();

  const currentMembershipId = currentMembership?.id;
  const { REACT_APP_INTERCOM_APP_ID } = process.env;

  const payload = useMemo(() => {
    // No APP_ID = no config
    if (!REACT_APP_INTERCOM_APP_ID) {
      return null;
    }

    // Unknown membership
    if (!currentMembership || !currentMembership?.id) {
      return {
        hide_default_launcher: true,
      };
    }

    // Unknown user
    if (!currentUser) {
      return null;
    }

    return {
      email: currentUser.email,
      hide_default_launcher: true,
      name: `${currentUser.firstName} ${currentUser.lastName}`.trim(),
      user_hash: currentMembership.intercomHash,
      user_id: currentMembership.id,
    };
  }, [currentMembership, currentUser, REACT_APP_INTERCOM_APP_ID]);

  useEffect(() => {
    if (currentMembershipId && REACT_APP_INTERCOM_APP_ID) {
      dispatch(fetchMembershipConfigRequest(currentMembershipId));
      dispatch(fetchSingleMembershipRequest(currentMembershipId));
    }
  }, [currentMembershipId, dispatch, REACT_APP_INTERCOM_APP_ID]);

  if (!REACT_APP_INTERCOM_APP_ID) {
    return null;
  }

  return <IntercomInternal appId={REACT_APP_INTERCOM_APP_ID} config={payload} />;
};
