import React from 'react';

import { Text } from 'components/text';
import { TooltipMUIConditionalWrapper } from 'components/tooltip';

import { typography } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';

import { LimitedMatchingToBaseCurrencyTooltipProps } from './LimitedMatchingToBaseCurrencyTooltip.types';

/**
 * Tooltip shown when matching is disabled because ledger partnership does not supported enabled base currency code
 */
const LimitedMatchingToBaseCurrencyTooltip = ({
  children,
  isShown,
  ledger,
}: LimitedMatchingToBaseCurrencyTooltipProps): React.ReactElement => (
  <TooltipMUIConditionalWrapper
    tooltipProps={
      isShown
        ? {
            arrow: true,
            padding: TooltipPadding.SKINNY,
            placement: TooltipPlacement.RIGHT,
            title: (
              <Text.Regular
                color={typography.TextColor.GREY_X_DARK}
                lineHeight={typography.TextLineHeight.MEDIUM}
                size={typography.TextSize.LEVEL_100}
              >
                {`Your account settings only allow you to match vendors associated with ${ledger.baseCurrencyCode} in your ${ledger.name} account.`}
              </Text.Regular>
            ),
          }
        : {}
    }
  >
    {children}
  </TooltipMUIConditionalWrapper>
);

export default LimitedMatchingToBaseCurrencyTooltip;
