import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useTaxFormOptions } from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.context';
import {
  type TaxFormModel,
  taxFormEntryType,
  w9ExemptFatcaCode,
  w9ExemptPayeeCode,
} from 'complexComponents/taxes/TaxFormRenderer/TaxFormRenderer.models';

import { LinkNewPage } from 'components';

import { IRS_W9_DOCS_URL } from 'constants/externalLinks';

import { isCompanyTypeBusiness } from 'helpers/currentCompany';

import { getLabelValuePairsFromEnum } from '../helpers';
import RenderCheckbox from '../RenderCheckbox';
import RenderSelect from '../RenderSelect';
import { Group, GroupSlot } from '../styles';

import { w9ExemptPayeeCodeLabelsMap } from './FieldGroupTaxExemptions.constants';

export const FieldGroupTaxExemptions = (): JSX.Element => {
  const { watch } = useFormContext<TaxFormModel>();
  const { entryType } = useTaxFormOptions();

  const companyType = watch('companyType');
  const hasTaxExemptions = watch('hasTaxExemptions');

  const isBusiness = isCompanyTypeBusiness(companyType);

  const hasTaxExemptionsLabel = `${entryType === taxFormEntryType.Enum.rctm ? 'Vendor has' : 'I have'} tax exemptions`;

  if (!isBusiness) {
    return null;
  }

  return (
    <Group>
      <GroupSlot size={6}>
        <RenderCheckbox label={hasTaxExemptionsLabel} name="hasTaxExemptions" />
      </GroupSlot>
      {hasTaxExemptions && (
        <>
          <GroupSlot size={6}>
            <span className="text-xs text-grey-50">
              {'Only applicable to certain entities. You can refer to the IRS rules around this '}
              <LinkNewPage className="font-weight--regular font-color--blue-bold--important" href={IRS_W9_DOCS_URL}>
                here
              </LinkNewPage>
            </span>
          </GroupSlot>
          <GroupSlot size={3}>
            <RenderSelect
              isClearable
              isRequired={false}
              label="Exempt payee code (if any)"
              name="exemptionPayeeCode"
              options={getLabelValuePairsFromEnum(w9ExemptPayeeCode, w9ExemptPayeeCodeLabelsMap)}
              placeholder="Select payee code"
            />
          </GroupSlot>
          <GroupSlot size={3}>
            <RenderSelect
              isClearable
              isRequired={false}
              label="Exemption from FATCA code"
              name="exemptionFatcaCode"
              options={getLabelValuePairsFromEnum(w9ExemptFatcaCode)}
              placeholder="Select FATCA code"
            />
          </GroupSlot>
        </>
      )}
    </Group>
  );
};
