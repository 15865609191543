import React from 'react';

import { formNamesFunding } from 'constants/forms';

import { useBankAccountReceivingCurrency } from 'hooks';

import VendorReceivingCurrency from 'modules/dashboard/vendorReceivingCurrency/VendorReceivingCurrency';

import { itemCurrencyCodeReceiverCompoundSelector } from 'queries/itemCompoundSelectors';

import { type Props } from './AccountCurrencyCodeField.types';

const AccountCurrencyCodeField = ({ availableCurrencies, partnership }: Props) => {
  const { fieldLabel, fieldName, isLocked, tooltipText, options } = useBankAccountReceivingCurrency({
    formName: formNamesFunding.ADD_PARTNER_INTERNATIONAL_FUNDING_ACCOUNT,
    availableCurrencies,
    itemCurrencyCodeSelector: itemCurrencyCodeReceiverCompoundSelector,
    partnershipSelector: () => partnership,
  });

  return (
    <VendorReceivingCurrency
      className="margin-top--m-large"
      fieldLabel={fieldLabel}
      fieldName={fieldName}
      isLocked={isLocked}
      options={options}
      tooltipText={tooltipText}
    />
  );
};

export default AccountCurrencyCodeField;
