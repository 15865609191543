import { cva } from 'class-variance-authority';

export const toolbarButtonStyles = cva(
  ['border-0', 'group', '[&_[role="status"]]:w-[10px]', '[&_[role="status"]]:h-[10px]'],
  {
    variants: {
      disabled: {
        true: [],
        false: ['hover:bg-blue-0'],
      },
    },
    defaultVariants: {
      disabled: false,
    },
  },
);

export const toolbarButtonIndicatorStyles = cva([], {
  variants: {
    disabled: {
      true: ['text-grey-50'],
      false: ['group-hover:text-blue-40'],
    },
  },
  defaultVariants: {
    disabled: false,
  },
});
