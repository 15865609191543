import { PaymentDeliveryMethodType } from '@routable/shared';

import { isFundingAccountEligibleForRTP } from 'helpers/funding';
import { sortArray } from 'helpers/sort';

import type { CurrencyCode } from 'interfaces/currency';
import type { PartnershipFundingAccount } from 'interfaces/partnerFundingAccount';

/**
 * Given a set of fundingAccounts, return the id of the selected one or best fit based on
 * the following precedence:
 *  1. selected account if any
 *  2. primary over preferred
 *  3. preferred
 *  4. first matched account if there are more than 1 on the lists
 */
export const getSelectedOrBestFitFundingAccount = (
  selectedFundingAccountId: string,
  selectedPaymentMethod: PaymentDeliveryMethodType,
  fundingAccounts: PartnershipFundingAccount[] = [],
  currencyCode: CurrencyCode,
): string => {
  const applicableAccounts = [];
  let primaryAccountId: string;
  let preferredAccountId: string;

  fundingAccounts.forEach((fundingAccount) => {
    // Filter funding accounts by currencyCode if currencyCode is not null
    // This is done so we can filter out results based on whether users can
    // select currency code receiver on create item form, i.e. SWIFT USD++ is on
    const currencyCodeMatches = !currencyCode || fundingAccount.currencyCode === currencyCode;
    if (fundingAccount.paymentDeliveryMethod === selectedPaymentMethod && currencyCodeMatches) {
      applicableAccounts.push(fundingAccount.id);
    }

    if (fundingAccount.isPrimary && currencyCodeMatches) {
      primaryAccountId = fundingAccount.id;
    }

    if (fundingAccount.isPreferred && currencyCodeMatches) {
      preferredAccountId = fundingAccount.id;
    }
  });

  if (selectedFundingAccountId && applicableAccounts.includes(selectedFundingAccountId)) {
    return selectedFundingAccountId;
  }

  if (primaryAccountId && applicableAccounts.includes(primaryAccountId)) {
    return primaryAccountId;
  }

  if (preferredAccountId && applicableAccounts.includes(preferredAccountId)) {
    return preferredAccountId;
  }

  if (applicableAccounts.length >= 1) {
    return applicableAccounts[0];
  }

  return undefined;
};

/**
 * Given a set of fundingAccounts, sorts it based on the following precedence:
 *  1. primary
 *  2. preferred
 *  3. real time payment enabled
 *  4. selected
 *  5. rest
 *
 * Also they should be grouped by currencyCode when available
 */
export const sortFundingAccountsForDisplay = (
  fundingAccounts: PartnershipFundingAccount[] = [],
  selectedFundingAccount: string,
  currencyCode: CurrencyCode,
): PartnershipFundingAccount[] => {
  // we want to weight the options like so: isPrimary > isPreferred > isSelected > rest
  // (thus, isPrimary needs to be weighted more heavily than the sum of all other combinations - isPreferred + isRTPEnabled + isSelected)
  const primaryWeight = 8;
  const preferredWeight = 4;
  const rtpWeight = 2;
  const selectedWeight = 1;

  // currencyCodeWeight needs to include all other weights so we can group multiple funding accounts first - when currencyCode is available
  const currencyCodeWeight = 1 + primaryWeight + preferredWeight + rtpWeight + selectedWeight;

  const getTotalWeight = (fundingAccount: PartnershipFundingAccount) =>
    (fundingAccount.isPrimary ? primaryWeight : 0) +
    (fundingAccount.isPreferred ? preferredWeight : 0) +
    (isFundingAccountEligibleForRTP(fundingAccount) ? rtpWeight : 0) +
    (fundingAccount.id === selectedFundingAccount ? selectedWeight : 0) +
    (fundingAccount.currencyCode === currencyCode ? currencyCodeWeight : 0);

  const comparisonFunc = (fundingAccountA: PartnershipFundingAccount, fundingAccountB: PartnershipFundingAccount) => {
    const totalFundingAccountA = getTotalWeight(fundingAccountA);
    const totalFundingAccountB = getTotalWeight(fundingAccountB);

    return totalFundingAccountB - totalFundingAccountA;
  };

  return sortArray(fundingAccounts, comparisonFunc);
};
