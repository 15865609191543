import PropTypes from 'prop-types';
import React from 'react';

import { ButtonV2 } from 'components/buttonV2';
import DocumentStatus from 'components/document/components/DocumentStatus';
import { IconNames } from 'components/icon';

import { Intent } from 'constants/ui';

import { downloadFileFromURL } from 'helpers/fileHelpers';
import { and } from 'helpers/utility';

/**
 * Component rendering right content of DocumentV2 component
 * @see {DocumentV2}
 *
 * @param {ComponentProps} props
 * @param {string} [props.documentName]
 * @param {string} [props.documentStatus]
 * @param {string} [props.documentURL]
 * @param handleRemoveDocument
 * @param hasStatusTag
 * @param isClearable
 * @param isDownloadable
 * @returns {JSX.Element|null}
 * @constructor
 */
const DocumentContentRight = ({
  documentName,
  documentStatus,
  documentURL,
  handleRemoveDocument,
  hasStatusTag,
  isClearable,
  isDownloadable,
}) => {
  if (isClearable) {
    // if user is able to delete the document
    return <span className="icon-ic-x-out general-cursor-hover" onClick={handleRemoveDocument} role="presentation" />;
  }

  // if user cannot delete the document
  if (hasStatusTag) {
    // document has a status, and cannot be downloaded
    return <DocumentStatus documentStatus={documentStatus} />;
  }

  if (and(documentName, documentURL, isDownloadable)) {
    // document has no status, and can be downloaded
    return (
      <ButtonV2
        id="downloadDocument"
        intent={Intent.NEUTRAL}
        leftIconClassName="margin-right--xm"
        leftIconName={IconNames.CIRCLE_ARROW_DOWN}
        onClick={() => downloadFileFromURL(documentURL, documentName)}
      >
        Download
      </ButtonV2>
    );
  }

  return null;
};

DocumentContentRight.propTypes = {
  documentName: PropTypes.string,
  documentStatus: PropTypes.string,
  documentURL: PropTypes.string,
  handleRemoveDocument: PropTypes.func,
  hasStatusTag: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDownloadable: PropTypes.bool,
};

DocumentContentRight.defaultProps = {
  documentName: undefined,
  documentStatus: undefined,
  documentURL: '',
  handleRemoveDocument: () => {},
  hasStatusTag: undefined,
  isClearable: undefined,
  isDownloadable: undefined,
};

export default DocumentContentRight;
