import React from 'react';

import { InternationalItemConversionRateTooltip } from 'complexComponents/tooltipTypes';

import { Icon, IconNames } from 'components';

import { colors } from 'constants/styles';

import { ItemCurrencyCodeKey } from 'enums/items';

import { isExternalPathAny } from 'helpers/external';
import { isInternationalItem, isItemAmountZero } from 'helpers/items';

import ShowAmountCell from '../ShowAmountCell';

import { getAmountProps } from './helpers';
import type { ShowItemAmountCellProps } from './ShowItemAmountCell.types';

/**
 * Renders a read-only cell for displaying item amounts
 */
const ShowItemAmountCell: React.FC<ShowItemAmountCellProps> = ({
  conversionAmountKey,
  currencyCodeKey = ItemCurrencyCodeKey.GENERAL,
  dataKey,
  partnership,
  isInbox = false,
  rowData,
}) => {
  const isInternational = isInternationalItem(rowData);
  const isAmountZero = isItemAmountZero(rowData, dataKey);
  const isExternal = isExternalPathAny();
  // [DEV-3090 - @zigcccc] We only want to show the globe icon + the tooltip if
  // - the item is international
  // - the item amountDue/amountPaid is not 0
  // - we are not in the external flow
  const showConversionsTooltip = partnership && isInternational && !isAmountZero && !isExternal;

  return (
    <ShowAmountCell
      amountProps={getAmountProps(rowData, dataKey)}
      currencyCodeKey={currencyCodeKey}
      dataKey={dataKey}
      rowData={rowData}
    >
      {!showConversionsTooltip && isInternational && !isAmountZero && (
        <Icon className="tooltip-icon" color={colors.colorGreyMedHex} icon={IconNames.GLOBE} />
      )}
      {!isInbox && showConversionsTooltip && (
        <InternationalItemConversionRateTooltip
          conversionAmountKey={conversionAmountKey}
          item={rowData}
          partnership={partnership}
        />
      )}
    </ShowAmountCell>
  );
};

export default ShowItemAmountCell;
