import React from 'react';

import { getClassNames } from 'helpers/ui';
import { hasLength } from 'helpers/utility';

import { ExternalComponentsProps } from './ExternalComponents.types';

/**
 * ExternalComponents convert a list of componentNames to actual elements.
 * @param {ExternalComponentsProps} props
 * @returns {StatelessComponent}
 */
const ExternalComponents: React.FC<ExternalComponentsProps> = ({ components, renderExternalComponent, ...rest }) => {
  if (!hasLength(components)) {
    return null;
  }

  return (
    <div className={getClassNames(rest)}>
      {components.map((componentName) => {
        const ComponentEl = renderExternalComponent(componentName);

        if (!ComponentEl) {
          return null;
        }

        return (
          <div className="external-components--component-wrapper" key={componentName}>
            {ComponentEl}
          </div>
        );
      })}
    </div>
  );
};

ExternalComponents.defaultProps = {
  components: undefined,
};

export default ExternalComponents;
