import { all, call, delay, put, select, spawn, take } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import {
  companySettingsSSOFetchRoutine,
  ssoDisableRoutine,
  ssoSetupFormEditSubmitRoutine,
  ssoSetupFormSubmitRoutine,
} from 'actions/routines/sso';
import { openSSOSetupFormSplashscreenModal } from 'actions/ssoActions';
import { showErrorUi, showSuccessUi } from 'actions/ui';

import {
  SSODisableFailUIText,
  SSODisableSuccessUIText,
  SSOSetupFailUIText,
  SSOSetupFormClickDelayDuration,
  SSOSetupSplashscreenDuration,
  SSOSetupSuccessUIText,
} from 'constants/sso';

import { SSOProviders } from 'enums/sso';

import { parseCaughtError, parseErrorResponse } from 'helpers/errors';
import { trackEvent, TrackEventName } from 'helpers/eventTracking';

import { Action } from 'interfaces/actions';
import { FetchServiceResponse } from 'interfaces/fetchService';
import {
  EditSettingsPayload,
  SetupSettingsPayload,
  SSOActionCreator,
  SSOCurrentCompanySettingsState,
  SSOSettingsState,
} from 'interfaces/sso';

import { currentCompanySelector } from 'selectors/currentCompanySelectors';
import {
  enabledSSOSettingsSelector,
  ssoSettingsByIdSelector,
  ssoSetupFormProviderSelector,
} from 'selectors/ssoSelectors';

import * as api from './api';
import { enableSSO } from './sideEffects';

/**
 * Disable SSO for a company
 */
export function* disableSSO(): IterableIterator<unknown> {
  let submitErrors = {};
  const enabledSSOSettings: SSOSettingsState = yield select(enabledSSOSettingsSelector);
  const { ssoRequired, ssoProvider } = enabledSSOSettings;

  try {
    const response: FetchServiceResponse = yield call(api.disableSSO, enabledSSOSettings.id);

    if (response.ok) {
      const { created }: { created: string } = yield select(currentCompanySelector);
      const eventData = {
        companySignedUpOn: created,
        isSSOLoginRequiredOrEnabled: ssoRequired ? 'required' : 'enabled',
        provider: ssoProvider,
      };
      yield all([
        call(trackEvent, TrackEventName.SSO_DISABLED, eventData),
        put(companySettingsSSOFetchRoutine.trigger()),
        put(ssoDisableRoutine.success()),
        put(showSuccessUi(SSODisableSuccessUIText[enabledSSOSettings.ssoProvider])),
      ]);
      return;
    }

    submitErrors = parseErrorResponse(response);
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  yield all([
    put(handleRequestErrors(ssoDisableRoutine.failure, submitErrors)),
    put(showErrorUi(SSODisableFailUIText[enabledSSOSettings.ssoProvider])),
  ]);
}

/**
 * Setup SSO for a company
 */
export function* submitSSOSettings(action: SSOActionCreator<SetupSettingsPayload>): IterableIterator<unknown> {
  yield all([
    put(ssoSetupFormSubmitRoutine.request()),
    delay(SSOSetupFormClickDelayDuration), // gives time to show buttonV2 load animation
  ]);
  yield put(openSSOSetupFormSplashscreenModal());

  let submitErrors = {};
  const { payload } = action;
  const { ssoProvider, ssoRequired } = payload;

  try {
    const { response }: { response: FetchServiceResponse } = yield all({
      response: call(api.submitSSOSettings, payload),
      delay: delay(SSOSetupSplashscreenDuration),
    });

    if (response.ok) {
      const { created }: { created: string } = yield select(currentCompanySelector);
      const eventData = {
        companySignedUpOn: created,
        isSSOLoginRequiredOrEnabled: ssoRequired === 'true' ? 'required' : 'enabled',
        provider: ssoProvider,
      };
      yield all([
        call(trackEvent, TrackEventName.SSO_ENABLED, eventData),
        put(companySettingsSSOFetchRoutine.trigger()),
        put(ssoSetupFormSubmitRoutine.success()),
        put(showSuccessUi(SSOSetupSuccessUIText[ssoProvider].SETUP)),
      ]);
      return;
    }

    submitErrors = parseErrorResponse(response);
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  yield all([
    put(handleRequestErrors(ssoSetupFormSubmitRoutine.failure, submitErrors)),
    put(showErrorUi(SSOSetupFailUIText[ssoProvider].SETUP)),
  ]);
}

/**
 * Update SSO for a company
 */
export function* submitUpdateSSOSettings(action: SSOActionCreator<EditSettingsPayload>): IterableIterator<unknown> {
  yield put(ssoSetupFormEditSubmitRoutine.request());

  let submitErrors = {};
  let status: 'EDIT' | 'SETUP' = 'EDIT';
  const ssoSetupFormProvider: SSOProviders = yield select(ssoSetupFormProviderSelector);
  const ssoSettingsById: SSOCurrentCompanySettingsState['byId'] = yield select(ssoSettingsByIdSelector);

  try {
    const { payload } = action;
    if (ssoSettingsById[payload.id] && !ssoSettingsById[payload.id].enableSso) {
      status = 'SETUP';
    }
    const { response }: { response: FetchServiceResponse } = yield all({
      response: call(api.submitUpdateSSOSettings, payload),
      delay: delay(SSOSetupFormClickDelayDuration), // gives time to show buttonV2 load animation
    });

    if (response.ok) {
      // enable current provider setting if a company sso setting object already exsits && is not enabled
      if (status === 'SETUP') {
        const isSuccessful = yield call(enableSSO, payload.id, ssoSetupFormProvider);
        const { ssoProvider } = ssoSettingsById[payload.id];
        const { ssoRequired } = payload;
        const { created }: { created: string } = yield select(currentCompanySelector);
        const eventData = {
          companySignedUpOn: created,
          isSSOLoginRequiredOrEnabled: ssoRequired === 'true' ? 'required' : 'enabled',
          provider: ssoProvider,
        };

        if (!isSuccessful) {
          return;
        }

        yield call(trackEvent, TrackEventName.SSO_RE_ENABLED, eventData);
      }

      yield all([
        put(companySettingsSSOFetchRoutine.trigger()),
        put(ssoSetupFormEditSubmitRoutine.success()),
        put(showSuccessUi(SSOSetupSuccessUIText[ssoSetupFormProvider][status])),
      ]);
      return;
    }

    submitErrors = parseErrorResponse(response);
  } catch (error) {
    submitErrors = parseCaughtError(error);
  }

  yield all([
    put(handleRequestErrors(ssoSetupFormEditSubmitRoutine.failure, submitErrors)),
    put(showErrorUi(SSOSetupFailUIText[ssoSetupFormProvider][status])),
  ]);
}

// Sagas
/**
 * Listens for redux actions related to SSO
 */
export function* watch(): IterableIterator<unknown> {
  while (true) {
    const action: Action = yield take([
      ssoDisableRoutine.TRIGGER,
      ssoSetupFormSubmitRoutine.TRIGGER,
      ssoSetupFormEditSubmitRoutine.TRIGGER,
    ]);

    switch (action.type) {
      case ssoDisableRoutine.TRIGGER:
        yield spawn(disableSSO);
        break;
      case ssoSetupFormSubmitRoutine.TRIGGER:
        yield spawn(submitSSOSettings, action as SSOActionCreator<SetupSettingsPayload>);
        break;
      case ssoSetupFormEditSubmitRoutine.TRIGGER:
        yield spawn(submitUpdateSSOSettings, action as SSOActionCreator<EditSettingsPayload>);
        break;

      default:
        yield null;
    }
  }
}

/**
 * Root sso saga
 */
export default function* sso(): IterableIterator<unknown> {
  yield watch();
}
