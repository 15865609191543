import React from 'react';

import { isItemKindPayable, isItemKindReceivable } from 'helpers/items';

import { ExternalBlock } from 'modules/external/ExternalBlocksSection';

import { ExternalReceiptPayableItemEntries, ExternalReceiptReceivableItemEntries } from './components';
import { ExternalReceiptPaymentItemSummaryProps } from './ExternalReceiptPaymentItemSummary.types';

/**
 * Component rendering Payment Item summary in External Receipt
 */
const ExternalReceiptPaymentItemSummary: React.FC<ExternalReceiptPaymentItemSummaryProps> = ({ item, attachments }) => (
  <ExternalBlock>
    {isItemKindPayable(item) && (
      <ExternalReceiptPayableItemEntries
        amount={parseFloat(item.amount)}
        attachments={attachments}
        currencyCode={item.currencyCode}
        partnerReference={item.partnerReference}
        paymentTerms={item.paymentTerms}
        reference={item.reference}
      />
    )}
    {isItemKindReceivable(item) && (
      <ExternalReceiptReceivableItemEntries
        attachments={attachments}
        dateDue={item.dateDue}
        reference={item.reference}
      />
    )}
  </ExternalBlock>
);

export default ExternalReceiptPaymentItemSummary;
