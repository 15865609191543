import React from 'react';

import ModalFooter from 'components/modal/components/footer/ModalFooter';
import ModalFooterButtonCancel from 'components/modal/components/footer/ModalFooterButtonCancel';

import CornerDialog from './CornerDialog';
import type { CornerDialogCloseOnlyProps } from './CornerDialogCloseOnly.types';

/**
 * A CornerDialog with just a close button in the lower right corner.
 */
const CornerDialogCloseOnly = ({ children, isShown, onClose, ...rest }: CornerDialogCloseOnlyProps) => (
  <CornerDialog hasFooter={false} isShown={isShown} onCloseComplete={onClose} {...rest}>
    <p className="text-grey-70 leading-5 mb-12">{children}</p>

    <ModalFooter className="ledger-footer">
      <ModalFooterButtonCancel buttonText="Close" onCloseModal={onClose} />
    </ModalFooter>
  </CornerDialog>
);

export default CornerDialogCloseOnly;
