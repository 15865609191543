import React from 'react';

import { FlexCol, FlexRow, Row } from 'components/layout';
import { Text } from 'components/text';

import { colors } from 'constants/styles';

import './ToggleRow.scss';
import ToggleSwitch, { ToggleSwitchProps } from './ToggleSwitch';

export interface ToggleRowProps extends Pick<ToggleSwitchProps, 'isChecked' | 'isDisabled' | 'name' | 'onChange'> {
  heading: string;
  subheading: string;
}

/**
 * Toggle component with heading and subheading descriptions.
 * https://www.figma.com/file/L6Kg4JvE1DlVTNxMmcqzLY/External%3A-Accept-and-make-Payments?node-id=15597%3A259604
 */
const ToggleRow: React.FC<ToggleRowProps> = ({ heading, isChecked, isDisabled, name, onChange, subheading }) => (
  <div className="w-full toggle-row-container">
    <Row className="justify-content--space-between toggle-row">
      <FlexCol>
        <Text.Semibold color={colors.colorGreyXXDarkHex}>{heading}</Text.Semibold>
        <FlexRow>
          <Text.Regular color={colors.colorGreyXDarkHex}>{subheading}</Text.Regular>
        </FlexRow>
      </FlexCol>
      <ToggleSwitch isChecked={isChecked} isDisabled={isDisabled} name={name} onChange={onChange} />
    </Row>
  </div>
);

export default ToggleRow;
