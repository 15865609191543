export enum ItemFilterLabels {
  AMOUNT_DUE = 'Amount due',
  BULK_ACTION = 'Bulk Action Id',
  BULK_ACTION_STATUS = 'Bulk Action Status',
  BANK_ACCOUNT = 'Bank account',
  CREATED_DATE = 'Created date',
  CURRENT_APPROVER = 'Current approver',
  CURRENT_APPROVER_STATUS = 'Current approver status',
  DUE_DATE = 'Due date',
  INVOICE_NUMBERS = 'Invoice number',
  ITEM_STATUS = 'Item status',
  ITEM_ID = 'Item Id',
  PAYMENT_ID = 'Payment Id',
}

export enum ItemFilterUrlParams {
  FILTER_AMOUNT_DUE_URL_PARAM = 'amount_due',
  FILTER_BANK_ACCOUNT_URL_PARAM = 'funding_account',
  FILTER_BULK_ACTION_URL_PARAM = 'bulk_action',
  FILTER_BULK_ACTION_STATUS_URL_PARAM = 'bulk_action_status',
  FILTER_CREATED_DATE_URL_PARAM = 'created__date',
  FILTER_DATE_DUE_URL_PARAM = 'date_due',
  FILTER_INVOICE_NUMBERS_URL_PARAM = 'invoice_numbers',
  FILTER_ITEM_STATUS_URL_PARAM = 'status',
  FILTER_PAYMENT_ID_URL_PARAM = 'payment',
  FILTER_PAYMENT_APPROVERS_URL_PARAM = 'approver_id',
  FILTER_APPROVER_STATUS_URL_PARAM = 'approver_status',
}
