import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeNotificationBar } from 'actions/ui';

import { NotificationBar } from 'components/notificationBar';

import { callIfIsFn } from 'helpers/utility';

import { notificationSelector } from 'selectors/uiSelectors';

type Props = { className?: string };

export const DashboardNotificationBar = ({ className }: Props) => {
  const dispatch = useDispatch();
  const notification = useSelector(notificationSelector);

  if (!notification?.visible) {
    return null;
  }

  const handleCloseNotification = () => {
    dispatch(closeNotificationBar());
    callIfIsFn(notification.onCloseNotification);
  };

  return (
    <NotificationBar
      className={className}
      hasBackgroundColor
      {...notification}
      onCloseNotification={handleCloseNotification}
    >
      {notification.message}
    </NotificationBar>
  );
};
