import { Badge, Button } from '@routable/gross-ds';
import React from 'react';

import { TooltipMUIConditionalWrapper } from 'components';

import { usePermissions } from 'hooks';

import type { TableToolbarPrimaryActionProps } from './TableToolbarPrimaryAction.types';

/**
 * A primary action within a TableToolbar, meaning the action gets its own standalone button,
 * as opposed to being put inside a menu with others.
 */
const TableToolbarPrimaryAction = ({
  actionableCount = 0,
  children,
  isDisabledWhenNoActionable = true,
  hideWhenNotAllowed,
  onClick,
  requiredPermissions = [],
  ...rest
}: TableToolbarPrimaryActionProps) => {
  const { hasPermission, tooltipProps } = usePermissions(requiredPermissions);
  const hasActionableCount = actionableCount >= 1;

  const isDisabled = !hasPermission || (isDisabledWhenNoActionable && !hasActionableCount);
  if (hideWhenNotAllowed && !hasPermission) {
    return null;
  }

  return (
    <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
      <Button
        data-testid="btn-bulk-action"
        {...rest}
        isDisabled={isDisabled}
        onPress={onClick}
        size="small"
        variant="outline"
      >
        {children}

        {!isDisabled && hasActionableCount && (
          <Badge aria-label="Item count" intent="blue" size="small">
            {actionableCount}
          </Badge>
        )}
      </Button>
    </TooltipMUIConditionalWrapper>
  );
};

export default TableToolbarPrimaryAction;
