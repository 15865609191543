import React from 'react';

import { Table } from 'complexComponents';

import { externalPaymentsClickContext } from './context';
import { ExternalPaymentsListTableProps } from './ExternalPaymentsListTable.types';
import { columns } from './paymentColumns';

export const ExternalPaymentsListTable: React.FC<ExternalPaymentsListTableProps> = ({ onClick, data }) => (
  <externalPaymentsClickContext.Provider value={onClick}>
    <Table columns={columns} data={data} />
  </externalPaymentsClickContext.Provider>
);
