import { Close } from '@carbon/icons-react';
import { Button } from '@routable/components';
import clsx from 'clsx';
import React from 'react';

import type { CloseButtonProps } from './CloseButton.types';

/**
 * Renders a minimal button with close icon.
 */
const CloseButton = ({ onClick, className }: CloseButtonProps) => (
  <Button
    className={clsx('close-btn', className)}
    data-testid="close-button"
    intent="primary"
    onPress={onClick}
    size="small"
    variant="floating"
  >
    <Close className="fill-grey-70" data-testid="close-button--icon" size={16} />
  </Button>
);

export default CloseButton;
