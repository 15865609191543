import React from 'react';
import { Field } from 'redux-form';

import { ReduxFormFieldRenderInput } from 'components';

import * as propTypes from 'constants/propTypes';

/**
 * Renders a static (disabled) text input field to be rendered in a table cell
 * @param {ComponentProps} props
 * @param {string} props.name
 * @returns {StatelessComponent}
 */
const StaticTableField = ({ name }) => (
  <Field
    className="ledger-form-field"
    component={ReduxFormFieldRenderInput}
    isDisabled
    name={name}
    props={{ hideLabel: true }}
    type="text"
  />
);

StaticTableField.propTypes = {
  ...propTypes.tableTextFieldPropTypes,
};

StaticTableField.defaultProps = {
  ...propTypes.tableTextFieldDefaultProps,
};

export default StaticTableField;
