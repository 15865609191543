export enum TimeFrames {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  CURRENT_WEEK = 'currentWeek',
  CURRENT_MONTH = 'currentMonth',
  OLDER = 'older',
}

/**
 * Date scheduled type options DEPRECATED
 * @deprecated This enum is deprecated, please use ItemDateScheduledTypes
 */
export enum DateScheduledTypes {
  TODAY = 'today',
  FUTURE_DATE = 'future_date',
}

/**
 * Item date schedule options
 */
export enum ItemDateScheduledTypes {
  TODAY = 'today',
  FUTURE = 'future',
  SKIP = 'skip',
}

/**
 * Date string length options for various fields
 */
export enum DateStringLength {
  BIRTH_DATE = 10,
}

/**
 * Units of time, compatible with those accepted by moment.
 */
export enum TimeUnit {
  MILLISECONDS = 'milliseconds',
  SECONDS = 'seconds',
  MINUTES = 'minutes',
  HOURS = 'hours',
}

/**
 * Style of formatting for time unit strings, e.g. s (short) versus sec (med) versus seconds (long).
 */
export enum TimeUnitStyle {
  SHORT = 'SHORT',
  MED = 'MED',
  LONG = 'LONG',
}

/**
 * Time zone identifiers.
 */
export enum TimeZoneId {
  UTC = 'UTC',
}
