import { TruncatableText } from '@routable/components';
import classNames from 'classnames';
import React from 'react';

import { Icon, IconNames } from 'components';

import { colors, sizes } from 'constants/styles';

import type { CompanyAddressDataCardHeaderProps } from './CompanyAddressDataCardHeader.types';

/**
 * Component rendering the Header of the Company Address Data Card
 * @see {CompanyAddressDataCard}
 * @param props
 */
const CompanyAddressDataCardHeader: React.FC<CompanyAddressDataCardHeaderProps> = ({
  hasError,
  isExpanded,
  onToggleExpanded,
  title,
}) => (
  <div
    className={classNames('company-address-data-card__header', {
      'company-address-data-card__header--expanded': isExpanded,
    })}
    onClick={onToggleExpanded}
    role="presentation"
  >
    <div className="company-address-data-card__header__left" data-fullstory>
      <Icon
        color={hasError ? colors.colorRedBoldHex : colors.colorGreyXDarkHex}
        icon={hasError ? IconNames.ERROR : IconNames.MAP_MARKER}
        size={sizes.iconSizes.LARGE}
      />
      <TruncatableText className="text-black text-sm font-semibold ml-5" maxNumberOfLines={2} text={title} />
    </div>

    <Icon
      color={colors.colorGreyXDarkHex}
      icon={isExpanded ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
      size={sizes.iconSizes.LARGE}
    />
  </div>
);

export default CompanyAddressDataCardHeader;
