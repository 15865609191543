import React from 'react';

import { allKeys } from 'helpers/utility';

import { sideSheetsMap } from './data';
import { ExternalSideSheetsProps } from './ExternalSideSheets.types';

/**
 * A centralized collection of (self-managed) external side sheet containers.
 * The side sheet containers connect to state to listen for open/close.
 * @returns {StatelessComponent}
 */
const ExternalSideSheets: React.FC<ExternalSideSheetsProps> = ({ sideSheets }) => (
  <React.Fragment>
    {allKeys(sideSheets).map((sideSheetName) => {
      const sideSheet = sideSheets[sideSheetName];

      if (!sideSheet.open) {
        return null;
      }

      const Component = sideSheetsMap[sideSheetName];

      return <Component key={sideSheetName} />;
    })}
  </React.Fragment>
);

export default ExternalSideSheets;
