import React from 'react';

import { LinkNewPage, Text, WhiteSpace } from 'components';

import helpDocs from 'helpers/helpDocs';

import './FeesChargedSeparatelyWarning.scss';

/**
 * Component rendering warning about fees being charged separately
 */
const FeesChargedSeparatelyWarning: React.FC = () => (
  <div className="fees-charged-separately-warning">
    <Text.Regular>Fees are charged separately from the bill amount.</Text.Regular>
    <WhiteSpace />
    <LinkNewPage className="primary" href={helpDocs.UNDERSTANDING_ROUTABLE_FEES}>
      Learn more about transaction fees ↗
    </LinkNewPage>
  </div>
);

export default FeesChargedSeparatelyWarning;
