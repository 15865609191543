import React from 'react';
import { useDispatch } from 'react-redux';

import { toggleInviteTeamMemberModal, toggleViewTeamMembersModal } from 'actions/signUpFlow';

import { ButtonV2, IconNames, TooltipMUIConditionalWrapper } from 'components';

import { ButtonSize } from 'constants/button';
import { colors } from 'constants/styles';
import { TooltipPadding, TooltipPlacement } from 'constants/tooltip';
import { Intent } from 'constants/ui';

import { MEMBERSHIPS_LIMIT } from 'modules/signup-v3/constants/memberships';

import { isInviteDisabled } from '../../helpers/memberships';

import {
  InvitedTeamMembersContainer,
  ButtonContainer,
  Title,
  Description,
  InvitedTeamMembersHeadingContainer,
} from './InvitedTeamMembers.styles';
import { InvitedTeamMembersProps } from './InvitedTeamMembers.types';

const InvitedTeamMembers = ({
  currentCompanyMembers,
  pendingMembershipInvites,
}: InvitedTeamMembersProps): JSX.Element => {
  const dispatch = useDispatch();
  const invitedMembersNumber = currentCompanyMembers.length + pendingMembershipInvites.length;
  const tooltipProps = isInviteDisabled(invitedMembersNumber)
    ? {
        arrow: true,
        padding: TooltipPadding.LARGE,
        placement: TooltipPlacement.TOP,
        style: {
          fontSize: '.75rem',
        },
        title: `You've already invited the maximum of ${
          MEMBERSHIPS_LIMIT - 1
        } people. To invite another member of your team, revoke an existing invite first.`,
      }
    : undefined;

  return (
    <InvitedTeamMembersContainer>
      <InvitedTeamMembersHeadingContainer>
        <Title>Don&apos;t have this information?</Title>
        <Description>Invite team members who can help you fill out the details.</Description>
      </InvitedTeamMembersHeadingContainer>
      <ButtonContainer>
        <ButtonV2
          intent={Intent.NEUTRAL}
          onClick={() => dispatch(toggleViewTeamMembersModal())}
          size={ButtonSize.MEDIUM}
          type="button"
        >
          View team members
        </ButtonV2>
        <TooltipMUIConditionalWrapper tooltipProps={tooltipProps}>
          <ButtonV2
            intent={Intent.NEUTRAL}
            isDisabled={isInviteDisabled(invitedMembersNumber)}
            leftIconClassName="margin-right--xm"
            leftIconColor={colors.colorGreyXDarkHex}
            leftIconName={IconNames.PLUS}
            onClick={() => dispatch(toggleInviteTeamMemberModal())}
            size={ButtonSize.MEDIUM}
            type="button"
          >
            Invite a team member
          </ButtonV2>
        </TooltipMUIConditionalWrapper>
      </ButtonContainer>
    </InvitedTeamMembersContainer>
  );
};

export default InvitedTeamMembers;
