import { useRef } from 'react';

import { countriesMapping, countryListOptions } from 'constants/i18n';

import { formatAddressFromPlace } from 'helpers/addressHelpers';

import type { UseAddressAutocompleteProps, UseAddressAutocompleteReturnType } from './AddressHookForm.types';

export const useAddressAutocomplete = ({
  addressPath,
  countryOptions,
  inputRef,
  onInvalidCountry,
  setValue,
}: UseAddressAutocompleteProps): UseAddressAutocompleteReturnType => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  // As per https://stackoverflow.com/questions/75179573/how-to-fix-loading-the-google-maps-javascript-api-without-a-callback-is-not-sup,
  // Google is enforcing their API scripts to have a callback URL to handle various cases such as
  // successfully loading script, receiving an error, etc. Since we handle that differently within
  // our app and we don't really care about this callback (but we do want to silence it), we're passing
  // `Function.prototype` to it.
  const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=Function.prototype`;
  const autocomplete = useRef<google.maps.places.Autocomplete>(null);
  const countriesList = countriesMapping[countryOptions];

  const handleFillInAddress = () => {
    const place = autocomplete.current.getPlace();

    if (place?.address_components) {
      const countryComponent = place.address_components.find((component) => component.types.includes('country'));
      if (countryComponent) {
        const allowedCountry = countriesList.find(({ value }) => value === countryComponent.short_name);
        if (!allowedCountry) {
          onInvalidCountry?.(countriesList, countryComponent);
          return;
        }
      }

      const newAddress = formatAddressFromPlace(place);

      Object.keys(newAddress).forEach((key) => {
        const path = addressPath ? `${addressPath}.${key}` : key;
        setValue(path, newAddress[key]);
      });
    }
  };

  const handleGoogleMapsLoad = async () => {
    const componentRestrictions =
      countryOptions !== countryListOptions.ALL
        ? {
            country: countriesList.map(({ value: country }) => country.toLowerCase()),
          }
        : undefined;

    const options: google.maps.places.AutocompleteOptions = {
      types: ['geocode'],
      componentRestrictions,
    };

    await google.maps.importLibrary('places');

    autocomplete.current = new google.maps.places.Autocomplete(inputRef.current, options);
    autocomplete.current.addListener('place_changed', handleFillInAddress);
  };

  return { onLoad: handleGoogleMapsLoad, scriptUrl };
};
