import classNames from 'classnames';
import React from 'react';
import './SignUpFormFooter.module.scss';

import { LinkNewPage, SafeAndSecureBadge } from 'components';

import { HELP_DOCS_URL } from 'constants/platform';

import { SignUpFormFooterProps } from './types/SignUpFormFooter.types';

const SignUpFormFooter: React.VFC<SignUpFormFooterProps> = ({ extraBottomPadding = false }) => (
  <div className={classNames('form-footer', extraBottomPadding && 'extra-bottom-padding')}>
    <SafeAndSecureBadge className="font-color--greyXDark" includeLink />
    <LinkNewPage className="font-color--greyXDark" href={HELP_DOCS_URL}>
      Help Docs
    </LinkNewPage>
  </div>
);

export default SignUpFormFooter;
