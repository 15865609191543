import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { getItemEarApprovals } from 'ducks/itemEarApproval';

import * as extHelpers from 'helpers/external';
import { getItemAttachmentsFromMap } from 'helpers/itemAttachments';
import { isItemKindPayable, isItemKindReceivable, isItemStatusNew } from 'helpers/items';
import * as queryHelpers from 'helpers/queryParams';
import { sortObjectArray } from 'helpers/sort';
import { allValues } from 'helpers/utility';

import type { Item } from 'interfaces/item';
import type { ReduxState } from 'interfaces/redux';

import { attachmentsSelector } from 'selectors/attachmentsSelectors';
import { idSelector, propsSelector } from 'selectors/globalSelectors';

import { CREATE_ITEM_TABS, DASHBOARD } from '../constants/routes';

import { locationSelector } from './routerSelectors';

const getItemsState = (state: ReduxState) => state.items;
const getAnnotationState = (state: ReduxState) => state.annotations;
const getAnnotationItemsState = (state: ReduxState) => state.annotationLineItems;

export const itemIdSelector = createSelector([idSelector, locationSelector], (id, location) => {
  const isNewBillPath = location.pathname.includes(`${DASHBOARD}/${CREATE_ITEM_TABS.CREATE_BILL}`);
  const itemId = queryHelpers.getQueryParamValueFromUrl(isNewBillPath ? 'id' : 'item_id', location?.search);
  return (typeof id === 'string' && id) || itemId;
});

/**
 * Selects `item` from props
 */
export const propsItemSelector = createSelector([propsSelector], (props) => props.item);

/*
  Selects singleItemRequests.isFetchingSingleItem
 */
export const isFetchingSingleItemSelector = createSelector(
  [getItemsState],
  (itemsState) => itemsState.singleItemRequests.isFetchingSingleItem,
);

/**
 * Selects `item` from a call used in useBill
 */
export const selectUseBillActiveItem = createSelector([getItemsState, itemIdSelector], (items, id) => ({
  ...(items?.singleItemRequests.statusById[id] || {
    isLoading: false,
    isError: false,
  }),
  data: items.singleItemRequests.byId[id] ?? null,
}));

export const selectUseBillActiveItemKind = createSelector([selectUseBillActiveItem], (item) => item?.data?.kind);

// ************************
// Delete
// ************************

/**
 * Selects the isDeleting bool for items from the state
 */
export const isDeletingItemSelector = createSelector([getItemsState], (items) => items.isDeleting);

// ************************
// Fetch
// ************************

/**
 * Selects the isFetching bool for items from the state
 */
export const isFetchingItemSelector = createSelector([getItemsState], (items) => items.isFetching);

// ************************
// Submit
// ************************

export const itemsSubmitSelector = createSelector([getItemsState], (items) => items.submit);

/**
 * Selects the isSubmitting bool for items from the state
 * @param {object} state - Redux state
 */
export const isSubmittingItemSelector = createSelector([itemsSubmitSelector], (submit) => submit.isSubmitting);

/**
 * Selects the lastSubmitted date for items from the state
 * @param {object} state - Redux state
 */
export const lastSubmittedItemSelector = createSelector([itemsSubmitSelector], (submit) => submit.lastSubmitted);

// ************************
// Update
// ************************

/**
 * Selects the isUpdating bool for items from the state
 */
export const isUpdatingItemSelector = createSelector([getItemsState], (items) => items.isUpdating);

// ************************
// General
// ************************
/**
 * Selects items by id from the state
 */
export const itemsSelector = createSelector([getItemsState], (items) => items?.byId);

/**
 * Selects items.allIds from the state
 * @param {object} state - Redux state
 */
export const itemsAllIdsSelector = createSelector([getItemsState], (items) => items.allIds);

/**
 * Selects items by id from the state and returns only values
 * @param {object} state - Redux state
 */
export const itemsArraySelector = createSelector([itemsSelector, getItemEarApprovals], (byId, approvals) =>
  allValues(byId).map((item) => ({
    ...item,
    approval: approvals?.approvals[item.id],
  })),
);

/**
 * Sorts the items by threadModified
 */
export const itemsSelectorSortedByDate = createSelector([itemsSelector], (items) =>
  sortObjectArray(allValues(items), 'threadModified', {
    comparisonFunc: dayjs,
  }).reduce((obj, item) => ({ ...obj, [item.id]: item }), {}),
);

/**
 * Selects the items errors from the state
 */
export const itemsErrorsSelector = createSelector([getItemsState], (items) => items.errors);

/**
 * Selects the item pagination from the state
 * @param {object} state - Redux state
 */
export const itemsPaginationSelector = createSelector([getItemsState], (items) => items.pagination);

/**
 * Selects the item count for payables
 */
export const itemPaginationCountSelector = createSelector([itemsPaginationSelector], (pagination) => pagination.count);

/**
 * Selects the pagination.current property (url)
 */
export const itemPaginationCurrentSelector = createSelector(
  [itemsPaginationSelector],
  (pagination) => pagination.current,
);

/**
 * Selects a specific item from items by id
 */
export const itemSelector = createSelector([itemsSelector, itemIdSelector], (items, itemId) => items?.[itemId]);

/**
 * Gets the ItemMembers with access on an Item
 */
export const itemMembersForItemSelector = createSelector([itemSelector], (item) => item.itemMembers);

/**
 * Selects partnership items
 */
export const partnershipItemsSelector = createSelector(
  [itemsSelector, idSelector],
  (items, partnershipId) =>
    sortObjectArray(
      allValues(items).filter((item) => item.partnership === partnershipId),
      'created',
      { comparisonFunc: dayjs },
    ) as Item[],
);

/**
 * Selects partnership payable items
 */
export const partnershipPayableItemsSelector = createSelector([partnershipItemsSelector], (partnershipItems) =>
  partnershipItems.filter(isItemKindPayable),
);

/**
 * Selects the partnership receivable items
 */
export const partnershipReceivableItemsSelector = createSelector([partnershipItemsSelector], (partnershipItems) =>
  partnershipItems.filter(isItemKindReceivable),
);

/**
 * Selects the partnership new receivable items
 */
export const partnershipNewReceivableItemsSelector = createSelector([partnershipItemsSelector], (partnershipItems) =>
  partnershipItems.filter((item) => isItemKindReceivable(item) && isItemStatusNew(item)),
);

/**
 * Selects the filtered partnership items
 */
export const partnershipFilteredItemsSelector = createSelector(
  [partnershipPayableItemsSelector, partnershipReceivableItemsSelector],
  (payables, receivables) => {
    const itemId = queryHelpers.getQueryParam('item_id');
    const partnershipItems = extHelpers.isExternalPathPay() ? payables : receivables;
    return partnershipItems.filter((item) => !itemId || item.id !== itemId);
  },
);

export interface CreatePartnershipFilteredItems {
  (itemId: string): (state: ReduxState, props?: unknown) => Item[];
}

export const createPartnershipFilteredItemsSelector: CreatePartnershipFilteredItems = (itemId) =>
  createSelector([partnershipPayableItemsSelector, partnershipReceivableItemsSelector], (payables, receivables) => {
    const partnershipItems = extHelpers.isExternalPathPay() ? payables : receivables;
    return partnershipItems.filter((item) => !itemId || item.id !== itemId);
  });

/**
 * Selects the item attachments filtered by itemId form the state
 */
export const itemAttachmentsSelector = createSelector([itemSelector, attachmentsSelector], getItemAttachmentsFromMap);

/**
 * Selects the item upload bills from the state
 */
export const itemUploadBillsSelector = createSelector([getItemsState], (items) => items.uploadBills);

/**
 * Selects the search results array (items.search)
 */
export const itemSearchSelector = createSelector([getItemsState], (items) => items.search);

/**
 * Selects annotations.allIds from the state
 * @param {object} state - Redux state
 */
export const annotationsAllIdsSelector = createSelector([getAnnotationState], (items) => items.allIds);

/**
 * Selects annotations.allIds from the state
 * @param {object} state - Redux state
 */
export const annotationsByIdsSelector = createSelector([getAnnotationState], (items) => items?.byId);

/**
 * Selects annotations.allIds from the state
 * @param {object} state - Redux state
 */
export const annotationLineItemsByIdSelector = createSelector([getAnnotationItemsState], (items) => items?.byId);

/**
 * Selects consolidated line items from current Item.
 * @param {object} state - Redux state
 */
export const consolidatedLineItemsSelector = createSelector(
  [itemSelector],
  (item) => item?.fieldProvenanceMap?.consolidatedLineItems,
);
