import { withFormValues } from '@routable/tablematic';

import { formNamesItem } from 'constants/forms';

import ApiLookupBlockFieldBasic from './ApiLookupBlockField';
import CheckboxBlockFieldBasic from './CheckboxBlockField';
import CurrencyBlockFieldBasic from './CurrencyBlockField';
import CurrencyCodeBlockFieldBasic from './CurrencyCodeBlockField';
import DateBlockFieldBasic from './DateBlockField';
import LookupBlockFieldBasic from './LookupBlockField';
import NumberBlockFieldBasic from './NumberBlockField';
import PercentBlockFieldBasic from './PercentBlockField';
import PhoneBlockFieldBasic from './PhoneBlockField';
import PresentationalField, { PresentationalFieldBoolean } from './PresentationalField';
import StaticBlockFieldBasic from './StaticBlockField';
import TextareaBlockFieldBasic from './TextareaBlockField';
import TextBlockFieldBasic from './TextBlockField';

const createItem = formNamesItem.CREATE_ITEM;

const ApiLookupBlockField = withFormValues(ApiLookupBlockFieldBasic, createItem);
const CheckboxBlockField = withFormValues(CheckboxBlockFieldBasic, createItem);
const CurrencyBlockField = withFormValues(CurrencyBlockFieldBasic, createItem);
const CurrencyCodeBlockField = withFormValues(CurrencyCodeBlockFieldBasic, createItem);
const DateBlockField = withFormValues(DateBlockFieldBasic, createItem);
const LookupBlockField = withFormValues(LookupBlockFieldBasic, createItem);
const NumberBlockField = withFormValues(NumberBlockFieldBasic, createItem);
const PercentBlockField = withFormValues(PercentBlockFieldBasic, createItem);
const PhoneBlockField = withFormValues(PhoneBlockFieldBasic, createItem);
const StaticBlockField = withFormValues(StaticBlockFieldBasic, createItem);
const TextareaBlockField = withFormValues(TextareaBlockFieldBasic, createItem);
const TextBlockField = withFormValues(TextBlockFieldBasic, createItem);

export {
  ApiLookupBlockField,
  CheckboxBlockField,
  CurrencyBlockField,
  CurrencyCodeBlockField,
  DateBlockField,
  LookupBlockField,
  NumberBlockField,
  PercentBlockField,
  PhoneBlockField,
  PresentationalField,
  PresentationalFieldBoolean,
  StaticBlockField,
  TextareaBlockField,
  TextBlockField,
};
