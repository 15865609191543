import React from 'react';

import { ButtonV2 } from 'components';

import { TooltipPlacement } from 'constants/tooltip';
import { Intent } from 'constants/ui';

import { getPartnershipPermissionResourceForEditAction } from 'helpers/partnerships';

import ConditionalRestrictedPermissionTooltip from 'modules/auth/ConditionalRestrictedPermissionTooltip';
import WithPermissionRestriction from 'modules/auth/WithPermissionRestriction';

import { CompanyContactsTableAddContactButtonProps } from './CompanyContactsTableAddContactButton.types';

/**
 * Button to add a new contact to a partnership.
 */
const CompanyContactsTableAddContactButton = ({
  onClick,
  partnership,
}: CompanyContactsTableAddContactButtonProps): JSX.Element => {
  const permissionForEditAction = getPartnershipPermissionResourceForEditAction(partnership);
  const linkLabel = 'Add a contact';

  return (
    <WithPermissionRestriction
      // @ts-ignore
      requiredPermissions={[permissionForEditAction]}
      WithoutPermissionComponent={
        <ConditionalRestrictedPermissionTooltip customization={{ placement: TooltipPlacement.BOTTOM }} useTooltip>
          <ButtonV2 intent={Intent.NEUTRAL} isDisabled>
            {linkLabel}
          </ButtonV2>
        </ConditionalRestrictedPermissionTooltip>
      }
      WithPermissionComponent={
        <ButtonV2 intent={Intent.NEUTRAL} onClick={onClick}>
          {linkLabel}
        </ButtonV2>
      }
    />
  );
};

export default CompanyContactsTableAddContactButton;
