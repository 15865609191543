import { connect } from 'react-redux';

import { currentCompanyMembersWithRoleSelector } from 'selectors/currentCompanySelectors';
import { pendingMembershipInvitesSelector } from 'selectors/membershipInvitesSelectors';

import InviteTeamMembers from './InvitedTeamMembers';

const mapStateToProps = (state) => ({
  currentCompanyMembers: currentCompanyMembersWithRoleSelector(state),
  pendingMembershipInvites: pendingMembershipInvitesSelector(state),
});

export default connect(mapStateToProps)(InviteTeamMembers);
