import { getQueryParamValueFromUrl } from 'helpers/queryParams';

import { ResetPasswordCompleteInitialState } from '../ResetPasswordComplete.types';

// The token is submitted to the server to validate this call
// therefore we need to make sure the state has it
export const getResetPasswordCompleteInitialValues: ResetPasswordCompleteInitialState = () => ({
  form: {
    token: getQueryParamValueFromUrl('token'),
    newPassword: '',
    newPasswordConfirm: '',
  },
  meta: {
    removeAuthToken: true,
    shouldRedirectOnSuccess: true,
    showIndicatorOnSuccess: false,
    showNotificationBarOnSuccess: true,
  },
});
