import React from 'react';

/**
 * Simple boolean toggle hook. Initial toggle state can be set at init.
 */
const useToggle = (initialState = false): [boolean, () => void] => {
  const [toggleState, setToggle] = React.useState(initialState);
  const toggle = () => setToggle((prev) => !prev);

  return [toggleState, toggle];
};

export default useToggle;
