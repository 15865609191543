import React from 'react';
import { all, call, put, select } from 'redux-saga/effects';

import { handleRequestErrors } from 'actions/errors';
import { resendPartnershipInviteRoutine } from 'actions/routines/partnership';
import { showSuccessUi } from 'actions/ui';

import { PartnershipResendReminderSuccessText } from 'constants/partnership';

import { partnershipMemberSubmitTransformers, partnershipSubmitTransformers } from 'data/submitTransformers';

import { isPartnershipMemberDefaultGeneralAccessSet } from 'helpers/partnershipMembers';
import { showSwal } from 'helpers/swal';

import type { FetchServiceResponse } from 'interfaces/fetchService';
import { Partnership } from 'interfaces/partnerships';

import CompanyMenuLinkResendPartnershipInviteSwal from 'modules/dashboard/company/global/presenters/CompanyActionNav/components/CompanyActionMenu/components/CompanyMenuLinkResendPartnershipInvite/components/CompanyMenuLinkResendPartnershipInviteSwal';

import { partnershipMembersFromPartnershipIdSelector } from 'queries/partnershipMemberCompoundSelectors';

import { sendPartnershipInvite } from 'sagas/shared/api';

/**
 * Shows CompanyMenuLinkResendPartnershipInviteSwal in a SWAL
 */
export const showResendPartnershipInviteSWAL = (partnership: Partnership): void =>
  showSwal({
    Content: <CompanyMenuLinkResendPartnershipInviteSwal partnership={partnership} />,
  });

/**
 * Helper function for the resendPartnershipInvite saga.
 * This selects partnership members, build the payload, and sends the invitation
 */
export function* resendPartnershipInviteSendInviteHelper(
  partnershipId: string,
): Generator<unknown, FetchServiceResponse> {
  // select updated partnership members
  const partnershipMembers = yield select(partnershipMembersFromPartnershipIdSelector, partnershipId);

  // create payload for the invitation
  const invitePayload = yield call<typeof partnershipSubmitTransformers.sendPartnershipInviteData>(
    partnershipSubmitTransformers.sendPartnershipInviteData,
    {
      partnershipId,
      partnershipMembers: (partnershipMembers ?? []) as PartnershipMember[],
    },
    {
      partnershipMemberFilters: [isPartnershipMemberDefaultGeneralAccessSet],
      transformPartnershipMember: partnershipMemberSubmitTransformers.partnershipMemberForSendInvite,
    },
  );

  // @ts-ignore send the invite POST request
  return yield call(sendPartnershipInvite, partnershipId, invitePayload);
}

/**
 * Helper function for the resendPartnershipInvite saga.
 * When the invitation succeeds, this puts all required actions
 */
export function* resendPartnershipInviteHandleSuccessHelper(
  invitationResponse: { ok: boolean; data?: { partnership: Partnership } },
  partnershipId: string,
): Generator<any, { partnership: Partnership }> {
  const payloadWithUpdatedPartnership = {
    id: partnershipId,
    partnership: invitationResponse.data?.partnership,
  };

  yield all([
    put(resendPartnershipInviteRoutine.success(payloadWithUpdatedPartnership)),
    put(showSuccessUi(PartnershipResendReminderSuccessText)),
  ]);

  yield put(resendPartnershipInviteRoutine.fulfill(payloadWithUpdatedPartnership));

  // return invitation data (non null return value means it worked)
  return invitationResponse.data;
}

/**
 * Helper function for the resendPartnershipInvite saga.
 * When the invitation fails, this puts all required actions
 */
export function* resendPartnershipInviteHandleFailureHelper(
  payload: { id: string; partnership: Partnership },
  noMembershipError: boolean,
  errorData: unknown,
  partnership: Partnership,
): Generator<any, void> {
  // tell invite button the request failed
  yield put(resendPartnershipInviteRoutine.failure(payload));

  if (noMembershipError) {
    // show specific message about missing partnership members
    yield call(showResendPartnershipInviteSWAL, partnership);
  } else {
    // shows generic request error SWAL if applicable
    yield put(handleRequestErrors(null, errorData));
  }

  yield put(resendPartnershipInviteRoutine.fulfill(payload));
}
