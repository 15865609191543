import React from 'react';

import helpDocs from 'helpers/helpDocs';
import { getOriginal } from 'helpers/tables';

import { TableConfigColumn } from 'interfaces/table.types';

import {
  ContactActionsCell,
  ContactDetailsCell,
  ContactPhoneNumberCell,
  ContactSettingsCell,
  ContactStatusCell,
} from '../components';

import { InfoIconWithTooltip } from './InfoIconWithTooltip';

/**
 * Table configuration for CompanyContactsTable
 */
export const CompanyContactsTableConfig: TableConfigColumn[] = [
  {
    accessor: 'details',
    Cell: (props) => <ContactDetailsCell rowData={{ ...getOriginal(props) }} />,
    Header: 'Contact Info',
    width: 2,
  },
  {
    accessor: 'settings',
    Cell: (props) => <ContactSettingsCell rowData={{ ...getOriginal(props) }} />,
    Header: 'Autofill Settings',
    props: {
      rightIndicator: (
        <InfoIconWithTooltip
          link={helpDocs.CONTACT_TYPES}
          text="Controls who can act on payment or email communications."
        />
      ),
    },
    width: 1,
  },
  {
    accessor: 'phone',
    Cell: (props) => <ContactPhoneNumberCell rowData={{ ...getOriginal(props) }} />,
    Header: 'Phone Number',
    width: 1,
  },
  {
    accessor: 'status',
    Cell: (props) => <ContactStatusCell rowData={{ ...getOriginal(props) }} />,
    Header: 'Status',
    width: 2,
  },
  {
    accessor: 'action',
    Cell: (props) => <ContactActionsCell rowData={{ ...getOriginal(props) }} />,
    className: 'checkbox-column',
    Header: '',
    width: 1,
  },
];
